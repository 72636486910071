import {
  AttachmentFolder,
  AttachmentId,
  SurveyId,
} from "@cartographerio/types";
import { useQuery } from "@tanstack/react-query";
import { ReactElement } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import GalleryItemModal, { useGallerySelection } from "./GalleryItemModal";
import MetadataGrid from "./GalleryItemModal/MetadataGrid";

export interface AttachmentFolderModalProps {
  isOpen: boolean;
  onClose: () => void;
  surveyId: SurveyId;
  folder: AttachmentFolder;
  attachmentUrl: (id: AttachmentId) => string;
}

export default function AttachmentFolderModal(
  props: AttachmentFolderModalProps
): ReactElement {
  const { isOpen, onClose, folder, surveyId, attachmentUrl } = props;

  const apiParams = useApiParams();

  const { data: items } = useQuery(
    queries.attachment.v3.searchGalleryItems(apiParams, surveyId, folder)
  );

  const { loading, item, handleNextClick, handlePrevClick } =
    useGallerySelection(items);

  return (
    <GalleryItemModal
      isOpen={isOpen}
      onClose={onClose}
      loading={loading}
      item={item}
      onPrevClick={handlePrevClick}
      onNextClick={handleNextClick}
      attachmentUrl={attachmentUrl}
    >
      {item != null && <MetadataGrid item={item} mx="2" />}
    </GalleryItemModal>
  );
}
