import { Image, ImageProps } from "@chakra-ui/react";
import { ReactElement } from "react";

export type CartographerLogoProps = Omit<ImageProps, "src">;

export default function CartographerLogo(
  props: CartographerLogoProps
): ReactElement {
  const { w = "28ch", mx = "auto", ...rest } = props;

  return (
    <Image
      src="/images/logo/cartographer-text-below-dark-on-light.png"
      w={w}
      mx={mx}
      {...rest}
    />
  );
}
