import { Flex, FlexProps } from "@chakra-ui/react";

import Link from "./Link";

interface TermsDocument {
  title: string;
  url: string;
}

const termsDocuments: TermsDocument[] = [
  {
    title: "Website Terms",
    url: "https://cartographer.io/legal/website-terms",
  },
  {
    title: "Platform Terms",
    url: "https://cartographer.io/legal/platform-terms",
  },
  {
    title: "Privacy Policy",
    url: "https://cartographer.io/legal/privacy-policy",
  },
  {
    title: "Cookie Policy",
    url: "https://cartographer.io/legal/cookie-policy",
  },
];

export default function TermsDocuments(props: FlexProps) {
  const {
    justifyContent = "center",
    direction = "column",
    textAlign = "center",
    mb = 3,
    mt = 1,
    ...rest
  } = props;
  return (
    <Flex
      justifyContent={justifyContent}
      direction={direction}
      textAlign={textAlign}
      mb={mb}
      mt={mt}
      {...rest}
    >
      {termsDocuments.map(doc => (
        <Link.External
          key={doc.url}
          to={doc.url}
          color="teal"
          fontSize="sm"
          fontWeight="bold"
        >
          {doc.title}
        </Link.External>
      ))}
    </Flex>
  );
}
