import { isArray } from "@cartographerio/guard";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Icon,
  IconButton,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

interface TreeNodeProps extends FlexProps {
  indentLevel: 0 | 1 | 2;
  initialIsOpen?: boolean;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  children?: ReactNode;
}

export default function TreeNode(props: TreeNodeProps): ReactElement {
  const {
    indentLevel,
    initialIsOpen,
    leftContent,
    rightContent,
    children,
    ...rest
  } = props;

  const hasChildren = useMemo(() => {
    return isArray(children) ? children.some(child => !!child) : !!children;
  }, [children]);

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: initialIsOpen });

  return (
    <>
      <Flex
        mt="0"
        justifyContent="space-between"
        borderBottomColor="gray.100"
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        p="3"
        _last={{ borderBottom: "none" }}
        _hover={hasChildren ? { cursor: "pointer", bg: "gray.50" } : undefined}
        onClick={hasChildren ? onToggle : undefined}
        {...rest}
      >
        <HStack ml={indentLevel * 8}>
          {hasChildren ? (
            <IconButton
              size="sm"
              variant="ghost"
              aria-label={isOpen ? "collapse" : "expand"}
              icon={
                <Icon
                  as={isOpen ? ChevronDownIcon : ChevronRightIcon}
                  w="1.25em"
                  h="1.25em"
                />
              }
              onClick={onToggle}
            />
          ) : (
            <chakra.span w="8" />
          )}
          {leftContent}
        </HStack>
        <Box>{rightContent}</Box>
      </Flex>
      {isOpen && children}
    </>
  );
}
