import { SelectOption } from "@cartographerio/inventory-enums";
import { check, checks } from "@cartographerio/permission";
import {
  QualificationAlias,
  QualificationRegisterEntry,
  QualificationRoleNameEnum,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Flex, HStack, Image, List, ListItem, chakra } from "@chakra-ui/react";
import { css } from "@emotion/react";
import outdent from "outdent";
import { QRCodeCanvas } from "qrcode.react";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { absoluteRouteUrl } from "../../../util";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import Heading from "../../components/Heading";
import HelpPopover from "../../components/HelpPopover";
import PageContainer from "../../components/PageContainer";
import Para from "../../components/Para";
import Select from "../../components/Select";
import Spaced from "../../components/Spaced";
import { useApiParams, useOptCurrentUserRef } from "../../contexts/auth";
import usePermissionCheck from "../../hooks/usePermissionCheck";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

type CertificateView = "full" | "public";

const screenOnly = css`
  @media print {
    display: none;
  }
`;

export default function QualificationCertificatePage(
  props: RouteProps<typeof routes.qualification.certificate>
): ReactElement {
  const {
    path: { registerEntryId },
    query: { view = "full" },
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const entry = useSuspenseQueryData(
    queries.qualification.register.v1.readOption(apiParams, registerEntryId)
  );

  const canToggle = usePermissionCheck(
    entry == null
      ? check.never("No certificate to view")
      : checks.qualification.canViewFullQualificationCertificate(entry)
  );

  const userRef = useOptCurrentUserRef();

  const myAccount = userRef?.userId === entry?.userId;

  const handleChange = useCallback(
    (view: CertificateView) => {
      updateQuery({ view });
    },
    [updateQuery]
  );

  const viewSelect = canToggle && (
    <chakra.div css={screenOnly}>
      <HStack justify="center">
        <Select.Standard
          value={view}
          options={VIEW_OPTIONS}
          onChange={handleChange}
          w="auto"
          marginInline={0}
        />
        <HelpPopover
          text={
            myAccount ? MY_ACCOUNT_VIEW_SELECT_HELP : STANDARD_VIEW_SELECT_HELP
          }
          marginInline={0}
        />
      </HStack>
    </chakra.div>
  );

  return entry == null ? (
    <CertificateNotFoundPage viewSelect={viewSelect} />
  ) : (
    <CertificateFoundPage
      entry={entry}
      view={canToggle ? view : "public"}
      viewSelect={viewSelect}
    />
  );
}

interface CertificateNotFoundPageProps {
  viewSelect: ReactNode;
}

function CertificateNotFoundPage(props: CertificateNotFoundPageProps) {
  const { viewSelect } = props;

  return (
    <PageContainer width="narrow">
      <Flex h="100vh" direction="column" justify="center">
        <Spaced spacing="12">
          {viewSelect}
          <Heading textAlign="center" level="section">
            Certificate Not Found
          </Heading>
          <Para textAlign="center" fontSize="lg">
            The web address may be incorrect
            <br /> or the certificate may be private or have expired.
          </Para>
          <Para></Para>
        </Spaced>
      </Flex>
    </PageContainer>
  );
}

interface CertificateFoundPageProps {
  entry: QualificationRegisterEntry;
  view: CertificateView;
  viewSelect: ReactNode;
}

function CertificateFoundPage(props: CertificateFoundPageProps) {
  const { entry, view, viewSelect } = props;

  const apiParams = useApiParams();

  const qualification = useSuspenseQueryData(
    queries.qualification.v1.readOrFail(apiParams, entry.qualificationId)
  );

  const name = useMemo(() => formatEntryName(entry, view), [entry, view]);
  const email = useMemo(() => formatEntryEmail(entry, view), [entry, view]);
  const level = useMemo(() => formatEntryLevel(entry), [entry]);

  const timestamp = useMemo(
    () =>
      formatTimestamp(entry.lastUpdate.timestamp, {
        format: ddmmyyyy,
      }),
    [entry.lastUpdate.timestamp]
  );

  return (
    <PageContainer width="narrow">
      <Flex h="100vh" direction="column" justify="center">
        <Spaced spacing="12">
          {viewSelect}
          <Card alignSelf="stretch">
            <CardBody>
              <Spaced spacing="6" pt="2" pb="4" px="4">
                <CertificateLogo alias={qualification.alias} />
                <Heading level="section" my="0" size={["md", "lg", "xl"]}>
                  {qualification.name} Certificate
                </Heading>
                <HStack justify="space-between" columnGap="4">
                  <List
                    alignSelf="stretch"
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    fontSize={["sm", "md", "lg"]}
                    flexGrow={1}
                    flexShrink={1}
                  >
                    <ListItem>
                      Name <strong>{name}</strong>
                    </ListItem>
                    {email != null && (
                      <ListItem>
                        Email <strong>{email}</strong>
                      </ListItem>
                    )}
                    <ListItem>
                      Level <strong>{level}</strong>
                    </ListItem>
                  </List>
                  <CertificateQRCode
                    alias={qualification.alias}
                    entryId={entry.id}
                  />
                </HStack>
                <Flex fontSize={["sm", "md", "lg"]}>
                  {qualification.alias === "rca" &&
                  entry.lastUpdate.roleName ===
                    QualificationRoleNameEnum.Qualified ? (
                    <HStack justify="space-between" columnGap="4">
                      <em>
                        Accredited on {timestamp} by the Cartographer RCA Team
                        and RGS for Continuing Professional Development
                      </em>
                      <CPDLogo />
                    </HStack>
                  ) : (
                    <em>Last updated on {timestamp}</em>
                  )}
                </Flex>
              </Spaced>
            </CardBody>
          </Card>
        </Spaced>
      </Flex>
    </PageContainer>
  );
}

export function formatEntryName(
  entry: QualificationRegisterEntry,
  view: CertificateView
): string {
  const formatScreenName = () => entry.screenName ?? "Anonymous";

  const formatName = () =>
    entry.firstName != null && entry.lastName != null
      ? `${entry.firstName} ${entry.lastName}`
      : formatScreenName();

  switch (view) {
    case "full":
      return formatName();
    case "public":
      switch (entry.privacy) {
        case "Email":
        case "Name":
          return formatName();
        case "ScreenName":
          return formatScreenName();
        default:
          return checkExhausted(entry.privacy);
      }
    default:
      return checkExhausted(view);
  }
}

export function formatEntryEmail(
  entry: QualificationRegisterEntry,
  view: CertificateView
): string | null {
  const formatEmail = () => entry.email ?? null;

  switch (view) {
    case "full":
      return formatEmail();
    case "public":
      switch (entry.privacy) {
        case "Email":
          return formatEmail();
        case "Name":
        case "ScreenName":
          return null;
        default:
          return checkExhausted(entry.privacy);
      }
    default:
      return checkExhausted(view);
  }
}

export function formatEntryLevel(entry: QualificationRegisterEntry): ReactNode {
  switch (entry.lastUpdate.roleName) {
    case "Trainee":
      return "Trainee Surveyor";
    case "Qualified":
      return "Qualified Surveyor";
    case "Trainer":
    case "Coordinator":
      return "Qualified Trainer and Surveyor";
    case "Expired":
    case null:
    case undefined:
      return "Not Found";
    default:
      return checkExhausted(entry.lastUpdate.roleName);
  }
}

function CPDLogo() {
  return (
    <Image
      src="/images/logo/RGS-IBG-CPD-Symbol-PROVIDER-BLUE-3025u-300dpi-1024x1024.jpg"
      w="clamp(var(--chakra-sizes-24), 25vw, var(--chakra-sizes-32))"
      h="clamp(var(--chakra-sizes-24), 25vw, var(--chakra-sizes-32))"
      alt="RGS-IBG CPD"
    />
  );
}

function CertificateLogo({ alias }: { alias: QualificationAlias }) {
  switch (alias) {
    case "rca":
      return (
        <Image
          src="/images/logo/morph-text-right-dark-on-light.png"
          maxH="16"
          alt="Modular River Survey"
        />
      );

    default:
      return null;
  }
}

interface CertificateQRCodeProps {
  alias: QualificationAlias;
  entryId: string;
}

function CertificateQRCode(props: CertificateQRCodeProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { alias, entryId } = props;

  // const imageSettings = useMemo(() => {
  //   switch (alias) {
  //     case "rca":
  //       return {
  //         src: "/images/qualifications/mrs-qr-center.png",
  //         height: 128,
  //         width: 128,
  //         excavate: true,
  //       };

  //     default:
  //       return {
  //         src: "/images/qualifications/cartographer-qr-center.png",
  //         height: 128,
  //         width: 128,
  //         excavate: true,
  //       };
  //   }
  // }, [alias]);

  return (
    <QRCodeCanvas
      value={absoluteRouteUrl(routes.qualification.certificate.url([entryId]))}
      size={512}
      style={{
        width: "clamp(var(--chakra-sizes-24), 25vw, var(--chakra-sizes-32))",
        height: "clamp(var(--chakra-sizes-24), 25vw, var(--chakra-sizes-32))",
      }}
      fgColor="#CBD5E0" // var(--chakra-colors-gray-300)
      imageSettings={undefined} // {imageSettings}
    />
  );
}

const VIEW_OPTIONS: SelectOption<CertificateView>[] = [
  {
    value: "public",
    label: "Standard - View the certificate as most people see it",
  },
  {
    value: "full",
    label: "Full - View an unredacted version of the certificate",
  },
];

const MY_ACCOUNT_VIEW_SELECT_HELP = outdent`
  Select which information to display:

  - Standard view - Shows the certificate as it appears to most people.
    Some personal information may be hidden
    depending on the Certificate Appearance setting.

  - Full view - Shows an unredacted version of the certificate.
    This can only be seen by you and qualification coordinators.
`;

const STANDARD_VIEW_SELECT_HELP = outdent`
  Select which information to display:

  - Standard view - Shows the certificate as it appears to most people.
    Some personal information may be hidden
    depending on the Certificate Appearance setting.

  - Full view - Shows an unredacted version of the certificate.
    This can only be seen by the subject of the certificate
    and qualification coordinators.
`;
