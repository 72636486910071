import { useCallback, useState } from "react";

export type UseInputFocusResult = [boolean, () => void, () => void];

export function useInputFocus(initial: boolean = false): UseInputFocusResult {
  const [focused, setFocused] = useState(initial);

  const handleFocus = useCallback(() => setFocused(true), []);
  const handleBlur = useCallback(() => setFocused(false), []);

  return [focused, handleFocus, handleBlur];
}
