import { SelectOption } from "@cartographerio/atlas-form";
import { checks } from "@cartographerio/permission";
import { ProjectAlias, WorkspaceRoleNameEnum } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageTopBar from "../../components/PageTopBar";
import Select from "../../components/Select";
import { useApiParams, useCredentialsV2 } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import BaseInvitationListPage from "../base/BaseInvitationListPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceInvitationListPage(
  props: RouteProps<typeof routes.workspace.invitation.list>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const { identity } = useCredentialsV2();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const projects = useSuspenseSearchResults(
    queries.project.v2.visible(apiParams, identity.userId, workspace.id)
  );

  const selectedProject = useMemo(
    () => projects.find(({ alias }) => query.project === alias),
    [query.project, projects]
  );

  const projectOptions = useMemo<SelectOption<ProjectAlias>[]>(
    () => projects.map(({ name, alias }) => ({ label: name, value: alias })),
    [projects]
  );

  useRequirePermission(checks.workspace.admin(workspace));

  usePageTitle(`Invitations - ${workspace.name}`);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="invitation-single" />
      <WorkspacePageHeader workspace={workspace} selected="invitation-single" />
      <BaseInvitationListPage
        route={routes.workspace.invitation.list}
        query={query}
        updateQuery={updateQuery}
        workspace={workspace}
        project={selectedProject}
        projectOptions={projectOptions}
        onProjectChange={project =>
          updateQuery({ ...query, project: project ?? undefined })
        }
        workspaceRole={query.role}
        roleSelect={
          <Select.Nullable
            value={query.role}
            options={WorkspaceRoleNameEnum.entries}
            onChange={role => updateQuery({ ...query, role, page: 0 })}
            placeholder="All Roles"
            background="transparent"
            w="fit-content"
            maxW="52"
          />
        }
        addButton={
          <ButtonLink.Internal
            colorScheme="blue"
            to={routes.workspace.invitation.create.url([workspace.alias])}
          >
            Add
          </ButtonLink.Internal>
        }
        itemLink={invitation =>
          routes.workspace.invitation.view.url([workspace.alias, invitation.id])
        }
      />
    </>
  );
}
