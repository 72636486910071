import { SurveySummaryV2 } from "@cartographerio/types";
import { chakra } from "@chakra-ui/react";

export interface SurveyDescriptionCellProps {
  survey: SurveySummaryV2;
}

export default function SurveyDescriptionCell(
  props: SurveyDescriptionCellProps
) {
  const { survey } = props;

  return (
    <chakra.span whiteSpace="normal" lineHeight="6">
      {survey.data.description ?? "(No description)"}
    </chakra.span>
  );
}
