import { Message, ProjectEmailSettings } from "@cartographerio/types";
import { useCallback, useState } from "react";

import CheckboxPanel from "../CheckboxPanel";
import { ProjectEmailSettingsEditor } from "./ProjectEmailSettingsEditor";

export interface TeamEmailSettingsEditorProps {
  value: ProjectEmailSettings | null;
  defaultValue: ProjectEmailSettings;
  onChange: (value: ProjectEmailSettings | null) => void;
  messages: Message[];
  disabled?: boolean;
}

export function TeamEmailSettingsEditor(props: TeamEmailSettingsEditorProps) {
  const { value, defaultValue, onChange, messages, disabled } = props;

  const [backup, setBackup] = useState(value ?? defaultValue);

  const handleChange = useCallback(
    (newValue: ProjectEmailSettings | null) => {
      onChange(newValue);
      setBackup(backup => newValue ?? backup);
    },
    [onChange]
  );

  return (
    <CheckboxPanel
      checkboxLabel="Override project email settings"
      value={value != null}
      onChange={override => handleChange(override ? backup : null)}
      disabled={disabled}
    >
      <ProjectEmailSettingsEditor
        group="team"
        value={backup}
        onChange={handleChange}
        messages={messages}
        disabled={disabled}
      />
    </CheckboxPanel>
  );
}
