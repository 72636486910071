import { IO } from "@cartographerio/io";
import { check } from "@cartographerio/permission";
import { unsafeInvitationCodeAlias } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback } from "react";
import { FaUserCog } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import {
  useApiParams,
  useAuthContext,
  useCredentialsV2,
} from "../../contexts/auth";
import { useIOInvitationCodePrompt } from "../../hooks/useInvitationCodePrompt";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import Avatar from "../Avatar";
import MenuHelp from "../MenuHelp";
import MenuItemLink from "../MenuItemLink";
import { useReactQueryDevtoolsOpen } from "../ReactQueryDevtools";
import RequirePermission from "../RequirePermission";
import { SUPERUSER_CONTENT_BACKGROUND } from "../SuperuserContent";
import TestTarget from "../TestTarget";

interface UserMenuProps {
  active?: boolean;
  restoreIdentityGo?: string;
}

export default function UserMenu(props: UserMenuProps): ReactElement {
  const {
    active,
    restoreIdentityGo = `${location.pathname}${location.search}${location.hash}`,
  } = props;

  const {
    state: { accessTokenData },
  } = useAuthContext();

  const credentials = useCredentialsV2();

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const prompt = useIOInvitationCodePrompt();
  const toast = useToast();

  const workspaces = useSuspenseQueryData(
    queries.workspace.v2.registered(apiParams, credentials.identity.userId)
  );

  const [devtoolsOpen, setDevtoolsOpen] = useReactQueryDevtoolsOpen();

  const handleUseCode = useCallback(
    () =>
      prompt()
        .tap(({ confirmed, code }) =>
          confirmed && code.length === 8
            ? queries.invitation.code.signup.v3
                .use(queryClient, apiParams, {
                  invitationCode: unsafeInvitationCodeAlias(code),
                  user: credentials.identity.userId,
                })
                .tap(result => {
                  switch (result.codeChallenge?.type) {
                    case "InvitationCodeChallengeAlreadyUsed":
                      toast({
                        status: "warning",
                        title: "Could Not Use Code",
                        description: "You've already used that code",
                      });
                      break;
                    case "InvitationCodeChallengeNotFound":
                      toast({
                        status: "warning",
                        title: "Could Not Use Code",
                        description: "The invitation code was invalid",
                      });
                      break;
                    case "InvitationCodeChallengeCanceled":
                      toast({
                        status: "warning",
                        title: "Could Not Use Code",
                        description: "That invitation code has been canceled",
                      });
                      break;
                    case "InvitationCodeChallengeExpired":
                      toast({
                        status: "warning",
                        title: "Could Not Use Code",
                        description: "That invitation code has expired",
                      });
                      break;
                    case "InvitationCodeChallengeRolesUnchanged":
                      toast({
                        status: "info",
                        title: "Code Applied",
                        description:
                          "The code didn't grant you any additional access",
                      });
                      break;
                    case undefined:
                      toast({
                        status: "success",
                        title: "Code Applied",
                        description:
                          "You've successfully used the code. You may need to wait for approval from an administrator before you see any changes.",
                      });
                      break;
                    default:
                      checkExhausted(result.codeChallenge);
                  }
                })
                .void()
            : IO.noop()
        )
        .tap(console.log)
        .unsafeRun(),
    [apiParams, credentials.identity.userId, prompt, queryClient, toast]
  );

  return (
    <Menu placement="right-end">
      <MenuButton>
        <TestTarget testId="user-menu">
          <Avatar
            name="User Menu"
            icon={<FaUserCog size="32px" />}
            active={active ?? false}
          />
        </TestTarget>
      </MenuButton>
      <Portal>
        <MenuList fontSize="sm" zIndex="popover" py="0">
          <MenuHelp>
            Hello{" "}
            <strong>
              {credentials.identity.firstName} {credentials.identity.lastName}
            </strong>
            !
          </MenuHelp>
          {workspaces.total > 1 && (
            <MenuItemLink.Internal roundedTop="md" to={routes.home.url([])}>
              My Workspaces
            </MenuItemLink.Internal>
          )}
          <RequirePermission check={check.superuser}>
            <MenuItemLink.Internal
              to={routes.admin.home.url([])}
              bg={SUPERUSER_CONTENT_BACKGROUND}
            >
              Cartographer Admin
            </MenuItemLink.Internal>
          </RequirePermission>
          <MenuItemLink.Internal to={routes.account.url([])}>
            My Account
          </MenuItemLink.Internal>
          <MenuItem onClick={handleUseCode}>Use Invitation Code</MenuItem>
          <MenuItemLink.External to="https://help.cartographer.io/contact">
            Contact Support
          </MenuItemLink.External>
          <MenuItemLink.External to="https://help.cartographer.io/">
            Help
          </MenuItemLink.External>
          {accessTokenData?.real != null && (
            <MenuItem
              onClick={() =>
                navigate(
                  routes.identity.restore.url([], {
                    go: restoreIdentityGo,
                  })
                )
              }
            >
              Restore Identity
            </MenuItem>
          )}
          {process.env.NODE_ENV === "development" && (
            <MenuItem
              title="React Query"
              onClick={() => setDevtoolsOpen(!devtoolsOpen)}
              bg={SUPERUSER_CONTENT_BACKGROUND}
            >
              {devtoolsOpen
                ? "Hide React Query Devtools"
                : "Show React Query Devtools"}
            </MenuItem>
          )}
          <MenuItemLink.Internal roundedBottom="md" to={routes.signout.url([])}>
            Sign Out
          </MenuItemLink.Internal>
        </MenuList>
      </Portal>
    </Menu>
  );
}
