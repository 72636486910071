import { checkExhausted } from "@cartographerio/util";
import { ThemeTypings } from "@chakra-ui/react";
import { useMemo } from "react";

export type Highlight = "default" | "info" | "error";

export interface InputHighlight {
  color?: ThemeTypings["colors"];
  borderColor?: ThemeTypings["colors"];
  borderWidth?: ThemeTypings["borders"];
}

export function useInputHighlight(
  highlight: Highlight | undefined,
  focused: boolean
): InputHighlight {
  return useMemo(() => {
    switch (highlight) {
      case "error":
        return {
          color: "red.600",
          borderColor: focused ? "red.700" : "red.500",
          borderWidth: "2px",
        };

      case "info":
        return {
          color: "cyan.600",
          borderColor: focused ? "cyan.500" : "cyan.400",
          // No box-shadow on a info so we make the border 1px thicker than an error
          borderWidth: "3px",
        };

      case "default":
      case undefined:
        return {
          color: "gray.600",
          borderColor: focused ? "gray.400" : "gray.200",
          borderWidth: "1px",
        };

      default:
        return checkExhausted(highlight);
    }
  }, [highlight, focused]);
}

export const errorBackgroundColor: ThemeTypings["colors"] = "red.50";
export const infoBackgroundColor: ThemeTypings["colors"] = "cyan.50";
export const defaultBackgroundColor: ThemeTypings["colors"] = "gray.50";

export interface BackgroundHighlight {
  bg: ThemeTypings["colors"];
}

export function useBackgroundHighlight(
  highlight?: Highlight
): BackgroundHighlight {
  return useMemo(() => {
    switch (highlight) {
      case "error":
        return { bg: errorBackgroundColor };

      case "info":
        return { bg: infoBackgroundColor };

      case "default":
      case undefined:
        return { bg: defaultBackgroundColor };

      default:
        return checkExhausted(highlight);
    }
  }, [highlight]);
}

export const errorTextColor: ThemeTypings["colors"] = "red.700";
export const infoTextColor: ThemeTypings["colors"] = "cyan.700";
export const defaultTextColor: ThemeTypings["colors"] = "gray.900";

export interface TextHighlight {
  textColor?: ThemeTypings["colors"];
}

export function useTextHighlight(highlight?: Highlight): TextHighlight {
  return useMemo(() => {
    switch (highlight) {
      case "error":
        return { textColor: errorTextColor };

      case "info":
        return { textColor: infoTextColor };

      case "default":
      case undefined:
        return { textColor: defaultTextColor };

      default:
        return checkExhausted(highlight);
    }
  }, [highlight]);
}

export interface CheckboxHighlight {
  colorScheme?: ThemeTypings["colorSchemes"];
  labelColor?: ThemeTypings["colors"];
}

export function useCheckboxHighlight(highlight?: Highlight): CheckboxHighlight {
  return useMemo(() => {
    switch (highlight) {
      case "error":
        return { colorScheme: "red", labelColor: "red.700" };

      case "info":
        return { colorScheme: "cyan", labelColor: "cyan.700" };

      case "default":
      case undefined:
        return { colorScheme: undefined, labelColor: undefined };

      default:
        return checkExhausted(highlight);
    }
  }, [highlight]);
}
