import { IO } from "@cartographerio/io";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";
import { IoCopyOutline } from "react-icons/io5";

import Button from "./Button";

interface ClickToCopyProps extends InputProps, ClickToCopyOptions {
  size?: "sm" | "md";
  copyButtonText?: string;
  value: string;
}

function smaller(size: "sm" | "md"): "xs" | "sm" {
  return size === "md" ? "sm" : "xs";
}

export default function ClickToCopy(props: ClickToCopyProps): ReactElement {
  const {
    size = "md",
    copyButtonText = "Copy",
    value,
    successMessage,
    failureMessage,
    ...rest
  } = props;

  const handleClick = useClickToCopy(value, { successMessage, failureMessage });

  return (
    <InputGroup size={size} rounded="md">
      <Input
        value={value}
        border="none"
        bg="gray.100"
        color="gray.500"
        fontFamily="monospace"
        rounded="lg"
        readOnly={true}
        {...rest}
      />
      <InputRightElement width="fit-content">
        <Button
          size={smaller(size)}
          me="1"
          width="fit-content"
          bg="gray.50"
          title="Copy to Clipboard"
          aria-label="Copy to Clipboard"
          label={copyButtonText}
          leftIcon={<IoCopyOutline />}
          onClick={handleClick}
          responsive={true}
        />
      </InputRightElement>
    </InputGroup>
  );
}

export type ClickToCopyFunc = () => void;
export type ClickToCopyOptions = {
  successMessage?: string;
  failureMessage?: string;
};

export function useClickToCopy(
  text: string,
  options: ClickToCopyOptions = {}
): ClickToCopyFunc {
  const {
    successMessage = "Copied to clipboard",
    failureMessage = "Unable to copy to clipboard",
  } = options;

  const toast = useToast();

  return useCallback(
    () =>
      IO.wrap(() => navigator.clipboard.writeText(text))
        .tap(() =>
          toast({
            title: successMessage,
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(() =>
          toast({
            title: failureMessage,
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        )
        .unsafeRun(),
    [failureMessage, successMessage, text, toast]
  );
}
