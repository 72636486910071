import { Box, SimpleGrid } from "@chakra-ui/react";
import { ReactNode } from "react";

import ClickToCopy from "../../components/ClickToCopy";
import ClickToReveal from "../../components/ClickToReveal";
import Disclosure from "../../components/Disclosure";

interface MetadataDisclosureProps {
  children: ReactNode;
}

function MetadataDisclosure(props: MetadataDisclosureProps) {
  const { children } = props;
  return (
    <Disclosure
      pt="8"
      toggle={<Box fontWeight="bold">Additional Information</Box>}
    >
      <SimpleGrid columns={[1, null, 2]} gap="4">
        {children}
      </SimpleGrid>
    </Disclosure>
  );
}

interface MetadataItemProps {
  label: string;
  value: string;
  hidden?: boolean;
}

function MetadataItem(props: MetadataItemProps) {
  const { label, value, hidden = false } = props;

  return (
    <Box>
      <Box fontWeight="bold">{label}</Box>
      {hidden ? <ClickToReveal value={value} /> : <ClickToCopy value={value} />}
    </Box>
  );
}

export default {
  Disclosure: MetadataDisclosure,
  Item: MetadataItem,
};
