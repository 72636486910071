import {
  BBox4,
  commonLocations,
  isBBox4,
  point,
  toBBox4,
} from "@cartographerio/geometry";
import { Box } from "@chakra-ui/react";
import MapboxDraw, { DrawCreateEvent } from "@mapbox/mapbox-gl-draw";
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, { MapRef, MapboxEvent, useControl } from "react-map-gl";

import {
  DEFAULT_SATELLITE_MAP_STYLE,
  DEFAULT_TERRAIN_MAP_STYLE,
} from "../../../config";
import { useMapboxToken } from "../../contexts/MapboxToken";
import { Highlight } from "../../hooks/highlight";
import useResizeObserver from "../../hooks/useResizeObserver";
import { InitialViewState } from "../../map/helpers";
import { calcViewState, fromBounds } from "../../map/mapHelpers";
import FieldMapControls from "../FieldMapControls";
import { DEFAULT_MAP_CSS, pointToCoordinate } from "../mapFieldHelpers";
import BBoxMode from "./BBoxMode";
import { DEFAULT_BBOX_PADDING } from "./helpers";

interface BBoxFieldProps {
  mapRef?: RefObject<MapRef>;
  value?: BBox4 | null;
  highlight?: Highlight;
  disabled?: boolean;
  defaultBounds?: BBox4;
  maxZoomOnChange?: number | null;
  minZoomOnChange?: number | null;
  onChange?: (value: BBox4 | null) => void;
  children?: ReactNode;
}

export default function BBoxField(props: BBoxFieldProps): ReactElement {
  const {
    mapRef: passedMapRef,
    value,
    highlight,
    disabled,
    defaultBounds = toBBox4(commonLocations.greatBritain.bounds),
    maxZoomOnChange,
    minZoomOnChange,
    onChange,
    children,
  } = props;

  const localMapRef = useRef<MapRef>(null);

  const mapRef = passedMapRef ?? localMapRef;

  const mapboxDraw = useMemo(
    () =>
      new MapboxDraw({
        displayControlsDefault: false,
        touchEnabled: false,
        controls: { trash: true },
        // Can't set it immediately, as it doesn't support passing initial parameters for some reason.
        // defaultMode: "draw_bbox",
        modes: {
          draw_bbox: BBoxMode,
          simple_select: {
            ...MapboxDraw.modes.simple_select,
            onClick: undefined,
            onDrag: undefined,
            onTap: undefined,
            onMouseDown: undefined,
            onMouseUp: undefined,
            onTouchStart: undefined,
            toDisplayFeatures: BBoxMode.toDisplayFeatures,
          },
        },
      }),
    []
  );

  const center = useMemo(
    () =>
      value != null
        ? point((value[0] + value[2]) / 2, (value[1] + value[3]) / 2)
        : null,
    [value]
  );

  const initialViewState = useMemo(
    (): InitialViewState =>
      calcViewState(
        () =>
          value != null && //
          fromBounds(value, DEFAULT_BBOX_PADDING),
        () => fromBounds(defaultBounds, DEFAULT_BBOX_PADDING)
      ),
    [defaultBounds, value]
  );

  const [satelliteView, setSatelliteView] = useState(false);

  const [dragging, setDragging] = useState(false);

  const handleFindValue = useCallback(() => {
    if (mapRef.current != null && value != null) {
      mapRef.current.fitBounds(value, {
        padding: DEFAULT_BBOX_PADDING,
      });
    }
  }, [mapRef, value]);

  useEffect(() => {
    handleFindValue();
    if (mapRef.current?.isStyleLoaded()) {
      mapboxDraw.deleteAll();
      mapboxDraw.changeMode("draw_bbox", { initial: value ?? null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(mapContainerRef, () => mapRef.current?.resize());

  const mapboxToken = useMapboxToken();

  const onLoad = useCallback(
    (evt: MapboxEvent) => {
      mapboxDraw.changeMode("draw_bbox", { initial: value ?? null });
      if (!disabled) {
        evt.target.on("draw.create", (evt: DrawCreateEvent) =>
          "bbox" in evt && isBBox4(evt.bbox) ? onChange?.(evt.bbox) : null
        );
        evt.target.on("draw.delete", () => onChange?.(null));
      }
    },
    [disabled, mapboxDraw, onChange, value]
  );

  return (
    <Box
      ref={mapContainerRef}
      borderRadius="md"
      overflow="hidden"
      __css={{
        "mapbox-gl-draw_ctrl-draw-btn": {
          position: "relative",
        },
        ".active": {
          backgroundColor: "var(--chakra-colors-gray-300)",
        },
        ".active:before": {
          position: "absolute",
          top: "0",
          bottom: "0",
          right: "0",
          left: "0",
          backgroundColor: "inherit",
        },
        ".mode-draw_bbox .bbox-control-button": {
          bg: "gray.300",
        },
      }}
    >
      <ReactMapGL
        ref={mapRef}
        mapboxAccessToken={mapboxToken}
        style={{ ...DEFAULT_MAP_CSS, height: "500px" }}
        cursor={dragging ? "grabbing" : "crosshair"}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        initialViewState={initialViewState}
        scrollZoom={false}
        mapStyle={
          satelliteView
            ? DEFAULT_SATELLITE_MAP_STYLE
            : DEFAULT_TERRAIN_MAP_STYLE
        }
        onLoad={onLoad}
      >
        {children}

        <MapboxDrawControl mapboxDraw={mapboxDraw} />

        <FieldMapControls
          onZoomIn={() => mapRef.current?.zoomIn()}
          onZoomOut={() => mapRef.current?.zoomOut()}
          defaultSearchPoint={center}
          onFindMarker={handleFindValue}
          highlight={highlight}
          showGeolocate={true}
          satelliteView={satelliteView}
          onSatelliteViewChange={setSatelliteView}
          onSearchPoint={point =>
            mapRef.current?.easeTo({
              center: pointToCoordinate(point),
              zoom: Math.max(
                Math.min(
                  mapRef.current?.getZoom() ?? 10,
                  maxZoomOnChange ?? 22
                ),
                minZoomOnChange ?? 0
              ),
              padding: DEFAULT_BBOX_PADDING,
            })
          }
        />
      </ReactMapGL>
    </Box>
  );
}

interface MapboxDrawControlProps {
  mapboxDraw: MapboxDraw;
}

function MapboxDrawControl(props: MapboxDrawControlProps): ReactElement {
  const { mapboxDraw } = props;

  useControl<MapboxDraw>(() => mapboxDraw, { position: "top-left" });

  return <></>;
}
