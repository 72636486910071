export type Entry<O, K extends keyof O> = readonly [K, O[K]];

export function typedToEntries<O extends object>(obj: O): Entry<O, keyof O>[] {
  return Object.entries(obj) as unknown as Entry<O, keyof O>[];
}

export function typedFromEntries<O extends object>(
  entries: Entry<O, keyof O>[]
): O {
  return Object.fromEntries(entries) as O;
}
