import { randomUuid } from "@cartographerio/types";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { ControlPosition, useControl } from "react-map-gl";

export interface CustomControlGroupProps {
  children: ReactNode;
  position: ControlPosition;
}

export default function CustomControlGroup(
  props: CustomControlGroupProps
): ReactElement | null {
  const { children, position } = props;

  const controlId = useMemo(() => randomUuid(), []);

  const [groupDiv, setGroupDiv] = useState<HTMLElement | null>(null);

  useControl(
    () => ({
      onAdd: () => {
        const groupDiv =
          document.querySelector<HTMLDivElement>(`div.crt-${controlId}`) ??
          DOM.create(
            "div",
            `mapboxgl-ctrl mapboxgl-ctrl-group crt-${controlId}`
          );
        setGroupDiv(groupDiv);
        return groupDiv;
      },
      onRemove: () => {
        if (groupDiv != null) {
          groupDiv.remove();
          setGroupDiv(null);
        }
      },
    }),
    { position }
  );

  return groupDiv == null ? null : <>{createPortal(children, groupDiv)}</>;
}

// Adapted from mapbox-gl/src/util/dom.js:

export const DOM = {
  create: <A extends HTMLElement>(
    tagName: string,
    className?: string,
    container?: HTMLElement
  ): A => {
    const el = window.document.createElement(tagName) as A;

    if (className != null) {
      el.className = className;
    }

    if (container != null) {
      container.appendChild(el);
    }

    return el;
  },

  remove: (node: HTMLElement) => {
    if (node.parentNode != null) {
      node.parentNode.removeChild(node);
    }
  },
};
