import { isNil, omitBy } from "lodash";
import { CircleLayout } from "mapbox-gl";
import { useMemo } from "react";

import { expr } from "../../mapbox";

export default function usePointLayerLayout(
  defaultZOrder?: string
): CircleLayout {
  return useMemo(() => {
    return omitBy(
      {
        "circle-sort-key":
          defaultZOrder == null
            ? undefined
            : expr.coalesce(expr.get(defaultZOrder), 0),
      },
      isNil
    );
  }, [defaultZOrder]);
}
