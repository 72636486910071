import { checkExhausted } from "@cartographerio/util";
import { useBreakpoint as useChakraBreakpoint } from "@chakra-ui/react";
import { ReactNode, createContext, useContext } from "react";

export interface UseBreakpointValueProps<A> {
  base: A;
  sm?: A;
  md?: A;
  lg?: A;
  xl?: A;
  ["2xl"]?: A;
}

export type Breakpoint = keyof UseBreakpointValueProps<never>; // "base" | "sm" | "md" | "lg" | "xl" | "2xl";

const BreakpointContext = createContext<Breakpoint>("base");

export function useBreakpoint(): Breakpoint {
  return useContext(BreakpointContext);
}

export interface UseBreakpointValueProps<A> {
  base: A;
  sm?: A;
  md?: A;
  lg?: A;
  xl?: A;
  ["2xl"]?: A;
}

export function useBreakpointValue<A>(values: UseBreakpointValueProps<A>): A {
  const bp = useBreakpoint();

  switch (bp) {
    case "base":
      return values.base;
    case "sm":
      return values.sm ?? values.base;
    case "md":
      return values.md ?? values.sm ?? values.base;
    case "lg":
      return values.lg ?? values.md ?? values.sm ?? values.base;
    case "xl":
      return values.xl ?? values.lg ?? values.md ?? values.sm ?? values.base;
    case "2xl":
      return (
        values["2xl"] ?? values.lg ?? values.md ?? values.sm ?? values.base
      );
    default:
      return checkExhausted(bp);
  }
}

export function useIsNarrow(cutoff: "xs" | "sm" | "md" | "lg" = "md"): boolean {
  return useBreakpointValue({
    base: true,
    sm: cutoff !== "xs" && cutoff !== "sm",
    md: cutoff !== "xs" && cutoff !== "sm" && cutoff !== "md",
    lg:
      cutoff !== "xs" && cutoff !== "sm" && cutoff !== "md" && cutoff !== "lg",
    xl: false,
    ["2xl"]: false,
  });
}

export interface BreakpointProviderProps {
  children: ReactNode;
}

export default function BreakpointProvider(props: BreakpointProviderProps) {
  const { children } = props;

  const bp = useChakraBreakpoint() as Breakpoint;

  return (
    <BreakpointContext.Provider value={bp}>
      {children}
    </BreakpointContext.Provider>
  );
}
