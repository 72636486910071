import { Message } from "@cartographerio/types";
import { FormControl, FormControlProps, SystemProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import FormLabel from "./FormLabel";
import MessageList from "./MessageList";

export interface MessageFormControlProps
  extends Omit<FormControlProps, "isInvalid"> {
  messages: Message[];
  matchExactPathOnly?: boolean;
  messagesStyle?: SystemProps;
  help?: string;
}

export default function MessageFormControl(
  props: MessageFormControlProps
): ReactElement | null {
  const {
    label,
    messages: _messages,
    matchExactPathOnly = false,
    help,
    children,
    messagesStyle,
    ...rest
  } = props;

  const messages = useMemo(
    () =>
      matchExactPathOnly
        ? _messages.filter(msg => msg.path.length === 0)
        : _messages,
    [_messages, matchExactPathOnly]
  );

  const messagesInvalid = useMemo(
    () => messages.some(message => message.level === "error"),
    [messages]
  );

  return (
    <FormControl label={label} isInvalid={messagesInvalid} {...rest}>
      {label != null && <FormLabel text={label} help={help} />}
      {children}
      <MessageList messages={messages} {...messagesStyle} />
    </FormControl>
  );
}
