import {
  Bucket,
  MapLayerId,
  isPropertyAttribute,
} from "@cartographerio/atlas-map";
import { chain } from "lodash";
import { ReactNode, createContext, useContext, useMemo } from "react";

import { attributeBuckets } from "./buckets";
import { useMapLayers } from "./MapSchemaContext";
import {
  useProjectTeamContext,
  useProjectTeamContextGraph,
} from "./ProjectTeamContext";
import { useSelectedAttributesContext } from "./SelectedAttributesContext";
import { useVisibleFeaturesContext } from "./VisibleFeaturesContext";

type LayerBucketsContextValue = Record<MapLayerId, Bucket[]>;

const LayerBucketsContext = createContext<LayerBucketsContextValue>({});

export function useLayerBucketsContext(): LayerBucketsContextValue {
  return useContext(LayerBucketsContext);
}

export function useLayerBuckets(layerId: MapLayerId): Bucket[] {
  const ctx = useLayerBucketsContext();
  return layerId == null ? [] : ctx[layerId] ?? [];
}

export interface LayerBucketsContextProviderProps {
  children: ReactNode;
}

export function LayerBucketsContextProvider(
  props: LayerBucketsContextProviderProps
) {
  const { children } = props;

  const layers = useMapLayers();
  const { visibleFeatures } = useVisibleFeaturesContext();
  const { selectedAttributes } = useSelectedAttributesContext();
  const { project } = useProjectTeamContext();
  const { findTeamsByProjectId } = useProjectTeamContextGraph();

  const layerBuckets = useMemo(
    () =>
      chain(layers)
        .map(layer => {
          const currAttr = selectedAttributes[layer.layerId];

          const currFeats = visibleFeatures[layer.layerId];

          const currTeams =
            currAttr != null ? findTeamsByProjectId(project.id) : [];

          const buckets =
            currAttr != null &&
            currFeats != null &&
            isPropertyAttribute(currAttr)
              ? attributeBuckets(currAttr, currFeats, currTeams)
              : [];

          return [layer.layerId, buckets];
        })
        .fromPairs()
        .value(),
    [
      findTeamsByProjectId,
      layers,
      project.id,
      selectedAttributes,
      visibleFeatures,
    ]
  );

  return (
    <LayerBucketsContext.Provider value={layerBuckets}>
      {children}
    </LayerBucketsContext.Provider>
  );
}
