import { Option } from "@cartographerio/fp";
import { ReactElement } from "react";

import { BaseTextAreaProps } from "./base";
import DefaultTextArea from "./Default";

export type NullableTextAreaProps = BaseTextAreaProps<string | null>;

function format(str: string | null): string {
  return str == null ? "" : str;
}

function validate(str: string): Option<string | null> {
  return Option.some(str.trim()).map(str => (str.length > 0 ? str : null));
}

export default function NullableTextArea(
  props: NullableTextAreaProps
): ReactElement {
  return <DefaultTextArea format={format} validate={validate} {...props} />;
}
