import { checks } from "@cartographerio/permission";
import {
  ProjectV2,
  TeamV2,
  UserId,
  createSearchResults,
} from "@cartographerio/types";
import { partition } from "lodash";
import { useMemo } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import { PartitionedResults } from "./usePartitionedProjects";
import usePermissionCheckRunner from "./usePermissionCheckRunner";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export default function usePartitionedTeams(
  project: ProjectV2,
  userId: UserId,
  _teams?: TeamV2[]
): PartitionedResults<TeamV2> {
  const apiParams = useApiParams();
  const permissionCheckPasses = usePermissionCheckRunner();

  const teams = useSuspenseSearchResults(
    queries.cond(
      _teams == null,
      () => queries.team.v2.visible(apiParams, userId, project.workspaceId),
      () => createSearchResults(_teams ?? [])
    )
  );

  return useMemo(
    () =>
      partition(teams, team => permissionCheckPasses(checks.team.member(team))),
    [permissionCheckPasses, teams]
  );
}
