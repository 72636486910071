import { Option } from "@cartographerio/fp";
import {
  OpenInterval,
  PlainDate,
  dateToPlainDate,
  emptyOpenInterval,
  isValidPlainDate,
  parseAnyPlainDate,
  plainDateToDate,
  plainDateToDdmmyyyy,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import {
  FocusEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import Spaced, { SpacedProps } from "../Spaced";
import TextField from "../TextField";
import BaseCalendar, { BaseCalendarMethods } from "./Base";

interface IntervalCalendarProps extends Omit<SpacedProps, "children"> {
  value: OpenInterval;
  onChange?: (value: OpenInterval) => void;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

export default function IntervalCalendar(
  props: IntervalCalendarProps
): ReactElement {
  const { value, onChange, ...rest } = props;

  const calendarRef = useRef<BaseCalendarMethods>(null);

  const initialDate = useMemo(
    () => (value.from != null ? plainDateToDate(value.from) : undefined),
    [value.from]
  );

  const [firstDate, setFirstDate] = useState<Date | null>(null);
  const [secondDate, setSecondDate] = useState<Date | null>(null);

  const handleChange = useCallback(
    (date: Date) => {
      if (onChange != null) {
        if (firstDate == null) {
          setFirstDate(date);
          setSecondDate(null);
        } else {
          onChange(
            date > firstDate
              ? { from: dateToPlainDate(firstDate), to: dateToPlainDate(date) }
              : { from: dateToPlainDate(date), to: dateToPlainDate(firstDate) }
          );
          setFirstDate(null);
          setSecondDate(null);
        }
      }
    },
    [firstDate, onChange]
  );

  const selected = useMemo(() => {
    if (firstDate != null) {
      return secondDate == null
        ? firstDate
        : firstDate < secondDate
        ? { from: firstDate, to: secondDate }
        : { from: secondDate, to: firstDate };
    }
    const from = value.from != null ? plainDateToDate(value.from) : null;
    const to = value.to != null ? plainDateToDate(value.to) : null;
    if (from == null) {
      return to;
    } else if (to == null) {
      return from;
    } else {
      return { from, to };
    }
  }, [firstDate, secondDate, value]);

  const optScrollTo = useCallback((plainDate?: PlainDate | null) => {
    if (calendarRef.current != null) {
      Option.wrap(plainDate)
        .nullMap(plainDateToDate)
        .tap(calendarRef.current.scrollTo);
    }
  }, []);

  return (
    <Spaced spacing="2">
      <BaseCalendar
        ref={calendarRef}
        selected={selected}
        initialDate={initialDate}
        onDayClick={handleChange}
        onDayHover={firstDate != null ? setSecondDate : undefined}
        {...rest}
      />
      <TextInputInterval
        value={value}
        onChange={onChange}
        optScrollTo={optScrollTo}
      />
    </Spaced>
  );
}

interface TextInputIntervalProps {
  value: OpenInterval;
  onChange?: (value: OpenInterval) => void;
  optScrollTo: (plainDate?: PlainDate | null) => void;
}

function TextInputInterval(props: TextInputIntervalProps): ReactElement {
  const { value, onChange, optScrollTo } = props;

  const [workingValue, setWorkingValue] = useState<{
    from: string;
    to: string;
  }>({
    from: isValidPlainDate(value.from) ? plainDateToDdmmyyyy(value.from) : "",
    to: isValidPlainDate(value.to) ? plainDateToDdmmyyyy(value.to) : "",
  });

  const handleChange = useCallback(
    (key: keyof OpenInterval) => (str: string) => {
      setWorkingValue({ ...workingValue, [key]: str });
      const plainDate = parseAnyPlainDate(str);
      if (plainDate != null) {
        onChange?.({ ...value, [key]: plainDate });
        optScrollTo(plainDate);
      }
    },
    [onChange, optScrollTo, value, workingValue]
  );

  const handleFocus = useCallback(
    (key: keyof OpenInterval) => (evt: FocusEvent<HTMLInputElement>) => {
      evt.target.select();
      optScrollTo(value[key]);
    },
    [optScrollTo, value]
  );

  const workingValueIsEmpty = useMemo(
    () => workingValue.from === "" && workingValue.to === "",
    [workingValue]
  );

  return (
    <HStack>
      <TextField.String
        placeholder="From (dd/mm/yyyy)"
        cursor={workingValueIsEmpty ? "pointer" : undefined}
        onClick={() =>
          workingValueIsEmpty ? onChange?.(emptyOpenInterval) : null
        }
        value={workingValue.from}
        onChange={handleChange("from")}
        onFocus={handleFocus("from")}
      />
      <TextField.String
        placeholder="To (dd/mm/yyyy)"
        cursor={workingValueIsEmpty ? "pointer" : undefined}
        onClick={() =>
          workingValueIsEmpty ? onChange?.(emptyOpenInterval) : null
        }
        value={workingValue.to}
        onChange={handleChange("to")}
        onFocus={handleFocus("to")}
      />
    </HStack>
  );
}
