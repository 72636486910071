import {
  BlockState,
  Columns,
  blockIsVisible,
  isFieldState,
} from "@cartographerio/atlas-form";
import {
  Grid as ChakraGrid,
  GridItem as ChakraGridItem,
  SystemProps,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import BlockView from "./BlockView";
import { DisallowMultiColumn, usePageContext } from "./PageContext";

export const GROUP_SPACING: SystemProps["height"] = "8";

export interface GroupProps {
  columns: Columns;
  parentKey: string;
  blockStates: BlockState[];
  disabled?: boolean;
}

export const templateColumns: Record<Columns, string> = {
  [1]: "minmax(0, 1fr)",
  [2]: "minmax(0, 1fr) minmax(0, 1fr)",
  [3]: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
};

interface CellProps {
  colSpan: Columns;
  blockKey: string;
  blockState: BlockState;
  disabled?: boolean;
}

function Cell(props: CellProps): ReactElement {
  const { colSpan, blockKey, blockState, disabled } = props;

  return (
    <ChakraGridItem colSpan={colSpan}>
      <BlockView
        blockKey={blockKey}
        blockState={blockState}
        disabled={disabled}
      />
    </ChakraGridItem>
  );
}

function blockStateFullWidth(blockState: BlockState) {
  return (
    blockState.type === "FullWidthState" ||
    (isFieldState(blockState) && (blockState.field.fullWidth ?? false))
  );
}

export default function GroupView(props: GroupProps): ReactElement {
  const { columns, parentKey, blockStates, disabled } = props;

  const { allowMultiColumn } = usePageContext();

  const children = blockStates.map((blockState, blockIndex) => {
    const blockKey = `${parentKey}_block${blockIndex}`;

    if (!blockIsVisible(blockState)) {
      return null;
    }

    const colSpan: Columns =
      !allowMultiColumn || blockStateFullWidth(blockState) ? columns : 1;

    return (
      <Cell
        key={blockKey}
        colSpan={colSpan}
        blockKey={blockKey}
        blockState={blockState}
        disabled={disabled}
      />
    );
  });

  return (
    <ChakraGrid
      templateColumns={templateColumns[columns]}
      columnGap="4"
      rowGap={GROUP_SPACING}
    >
      {columns === 1 ? (
        children
      ) : (
        <DisallowMultiColumn>{children}</DisallowMultiColumn>
      )}
    </ChakraGrid>
  );
}
