import { IO } from "@cartographerio/io";
import {
  InvitationCodeAlias,
  unsafeInvitationCodeAlias,
} from "@cartographerio/types";
import { VStack } from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useCallback, useState } from "react";

import { useIOAlert } from "../components/Alert";
import FormLabel from "../components/FormLabel";
import HelpText from "../components/HelpText";
import InvitationCodeField from "../components/InvitationCodeField";

export interface InvitationCodePromptResult {
  confirmed: boolean;
  code: InvitationCodeAlias;
}

export function useIOInvitationCodePrompt(): () => IO<InvitationCodePromptResult> {
  const alert = useIOAlert();

  return useCallback(() => {
    let code: InvitationCodeAlias = unsafeInvitationCodeAlias("");

    return alert({
      title: "Invitation Code",
      message: (
        <VStack align="stretch">
          <HelpText text={HELP_TEXT} />
          <FormLabel text="Enter an invitation code" />
          <InvitationCodePromptField onChange={newCode => (code = newCode)} />
        </VStack>
      ),
      okLabel: "OK",
      cancelLabel: "Cancel",
    }).map(confirmed => ({ confirmed, code }));
  }, [alert]);
}

interface InvitationCodePromptFieldProps {
  onChange: (value: InvitationCodeAlias) => void;
}

function InvitationCodePromptField(
  props: InvitationCodePromptFieldProps
): ReactElement {
  const { onChange } = props;
  const [value, setValue] = useState(unsafeInvitationCodeAlias(""));

  const handleChange = useCallback(
    (value: InvitationCodeAlias) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <InvitationCodeField
      value={value}
      onChange={handleChange}
      justify="space-between"
    />
  );
}

const HELP_TEXT = outdent`
  Enter an invitation code to use it on your account.
  Subject to approval by the code's creator,
  the permissions on your account will be expanded
  and you'll be able to do new things!
`;
