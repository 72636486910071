import { CardState } from "@cartographerio/atlas-form";
import { checkExhausted } from "@cartographerio/util";
import { Box } from "@chakra-ui/layout";
import { ReactElement } from "react";

import Card from "../components/Card";
import CardBody from "../components/CardBody";
import BlockView from "./BlockView";

interface CardViewProps {
  blockKey: string;
  blockState: CardState;
  disabled?: boolean;
}

export default function CardView(props: CardViewProps): ReactElement {
  const { blockKey, blockState, disabled } = props;
  switch (blockState.block.appearance) {
    case "success":
      return (
        <Card my="8" pb="4" bg="green.100" shadow="none">
          <CardBody>
            <BlockView
              blockKey={blockKey}
              blockState={blockState.childState}
              disabled={disabled}
            />
          </CardBody>
        </Card>
      );
    case "warning":
      return (
        <Card my="8" pb="4" bg="orange.100" shadow="none">
          <CardBody>
            <BlockView
              blockKey={blockKey}
              blockState={blockState.childState}
              disabled={disabled}
            />
          </CardBody>
        </Card>
      );
    case "default":
      return (
        <Box my="8">
          <BlockView
            blockKey={blockKey}
            blockState={blockState.childState}
            disabled={disabled}
          />
        </Box>
      );
    default:
      return checkExhausted(blockState.block.appearance);
  }
}
