import { MapLayer } from "@cartographerio/atlas-map";
import { Select } from "@chakra-ui/react";
import { ChangeEvent, ReactElement, useCallback } from "react";

import { useMapLayers, useSelectedLayer } from "../AtlasMapContext";

export default function LayerSelect(): ReactElement {
  const layers = useMapLayers();
  const [selectedLayer, setSelectedLayer] = useSelectedLayer();

  const handleLayerChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const layer: MapLayer | undefined = layers.find(
        layer => layer.layerId === evt.target.value
      );

      if (layer != null) {
        setSelectedLayer(layer);
      }
    },
    [layers, setSelectedLayer]
  );

  return (
    <Select
      size="sm"
      fontSize="xs"
      value={selectedLayer?.layerId}
      onChange={handleLayerChange}
    >
      {layers.flatMap(layer => (
        <option key={layer.layerId} value={layer.layerId}>
          {layer.title}
        </option>
      ))}
    </Select>
  );
}
