import { errorMessage } from "@cartographerio/atlas-core";
import { SurveyTransferRequestV2 } from "@cartographerio/types";

import { SomeNullable, rule } from "./rule";

export type PartialSurveyTransferRequest = SomeNullable<
  SurveyTransferRequestV2,
  "srcModule" | "desProject" | "desModule"
>;

export const {
  rule: surveyTransferRequestRule,
  keys: surveyTransferRequestErrorKeys,
} = rule
  .build<PartialSurveyTransferRequest>()
  .field(
    "srcProject",
    rule.required([errorMessage("You must select a source project")])
  )
  .field(
    "srcModule",
    rule.required([errorMessage("You must select a source module")])
  )
  .field(
    "desProject",
    rule.required([errorMessage("You must select a destination project")])
  )
  .field(
    "desModule",
    rule.required([errorMessage("You must select a destination module")])
  )
  .field(
    "surveys",
    rule.nonEmpty([errorMessage("You must select at least 1 survey")])
  )
  .finish();
