import { Button, ButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";

interface LinkButtonProps extends Omit<ButtonProps, "disabled"> {
  //
}

/** A button that looks like a link! */
export default function LinkButton(props: LinkButtonProps): ReactElement {
  const {
    onClick,
    children,
    display = "inline-flex",
    variant = "link",
    m = "unset",
    p = "unset",
    bg = "unset",
    size = "sm",
    minW = 0,
    fontSize = "unset",
    fontWeight = "unset",
    color = "blue.600",
    ...rest
  } = props;

  return (
    <Button
      onClick={onClick}
      display={display}
      variant={variant}
      m={m}
      p={p}
      bg={bg}
      size={size}
      minW={minW}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      {...rest}
    >
      {children}
    </Button>
  );
}
