import { VStack } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface InspectorMenuProps {
  children: ReactNode;
}

export default function InspectorMenu(props: InspectorMenuProps): ReactElement {
  const { children } = props;

  return (
    <VStack mb="2" spacing="0" w="100%" alignItems="stretch">
      {children}
    </VStack>
  );
}
