import { Icon, ToastId, useToast } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";
import { FaHome } from "react-icons/fa";

import LinkButton from "../../components/LinkButton";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import { useMapSettingsContext } from "../../map/AtlasMapContext/MapSettingsContext";
import CustomControlGroup from "../../map/control/CustomControlGroup";
import MapButton from "../../map/control/MapButton";

export interface ResetViewportBannerProps {
  show: boolean;
}

export default function ResetViewportBanner(
  props: ResetViewportBannerProps
): ReactElement | null {
  const { show } = props;

  const toast = useToast();

  const { zoomToProject } = useMapSettingsContext();

  const handleToastClick = useCallback(
    (toastId?: ToastId) => {
      zoomToProject();
      if (toastId != null) {
        toast.close(toastId);
      }
    },
    [toast, zoomToProject]
  );

  const handleButtonClick = useCallback(() => {
    zoomToProject();
  }, [zoomToProject]);

  useEffectOnce(() => {
    if (show) {
      const toastId = toast({
        status: "info",
        description: (
          <>
            We have loaded your previous map settings.{" "}
            <LinkButton
              size="sm"
              color="white"
              onClick={() => handleToastClick(toastId)}
            >
              Reset
            </LinkButton>
          </>
        ),
        isClosable: true,
      });
    }
  });

  return (
    <CustomControlGroup position="top-right">
      <MapButton
        icon={<Icon as={FaHome} />}
        label="Reset map view"
        onClick={handleButtonClick}
      />
    </CustomControlGroup>
  );
}
