import { Dispatch, SetStateAction, useEffect, useState } from "react";

type Result<S> = [S, Dispatch<SetStateAction<S>>];

/**
 * Like useState but resets the value when dependencies change.
 * Should be used with useCallback as follows:
 *
 * ```
 * const [x, y] = useVolatileState(useCallback(
 *   () => initialValue,
 *   [dependency, list]
 * ))
 * ```
 */
export function useVolatileState<S>(
  func: (oldValue: S | undefined) => S
): Result<S> {
  const [value, setter] = useState(() => func(undefined));

  useEffect(() => setter(func), [func]);

  return [value, setter];
}
