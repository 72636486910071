import { errorMessage } from "@cartographerio/atlas-core";
import { Result } from "@cartographerio/fp";
import {
  InvitationChallengeSignupRequired,
  InvitationId,
  InvitationSignupRequest,
  Message,
  unsafeEmail,
  unsafeScreenName,
  unsafeUnencryptedPassword,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { getUniqueMessages, splitMessages } from "../../../schema/rule/errors";
import {
  signupWithPassErrorKeys,
  signupWithPassRule,
} from "../../../schema/signup";
import CartographerLogo from "../../components/CartographerLogo";
import Checkbox from "../../components/Checkbox";
import FormLabel from "../../components/FormLabel";
import MessageFormControl from "../../components/MessageFormControl";
import Para from "../../components/Para";
import PasswordField from "../../components/PasswordField";
import Spaced from "../../components/Spaced";
import TermsDocuments from "../../components/TermsDocuments";
import { formatScreenName } from "../../components/UserDetailsEditor";
import { useApiConfig } from "../../contexts/apiConfig";
import { completeSigninAction, useAuthContext } from "../../contexts/auth";
import { routes } from "../../routes";

function initialSignup(
  signupRequired: InvitationChallengeSignupRequired
): InvitationSignupRequest {
  return {
    firstName: signupRequired.firstName,
    lastName: signupRequired.lastName,
    email: signupRequired.email,
    screenName: unsafeScreenName(
      `${signupRequired.firstName} ${signupRequired.lastName}`
    ),
    password: unsafeUnencryptedPassword(""),
    acceptTerms: false,
  };
}

interface InvitationSignupProps {
  invitationId: InvitationId;
  signupRequired: InvitationChallengeSignupRequired;
}

export default function InvitationSignup(
  props: InvitationSignupProps
): ReactElement {
  const { invitationId, signupRequired } = props;
  const navigate = useNavigate();
  const toast = useToast();
  const apiConfig = useApiConfig();
  const queryClient = useQueryClient();

  const [signup, setSignup] = useState<InvitationSignupRequest>(() =>
    initialSignup(signupRequired)
  );

  const [screenNameTouched, setScreenNameTouched] = useState(
    () =>
      signup.screenName.length === 0 ||
      formatScreenName(signup.firstName, signup.lastName) !== signup.screenName
  );

  const { dispatch: dispatchAuthAction } = useAuthContext();

  const [messages, setMessages] = useState<Message[]>([]);
  const [responseError, setResponseError] = useState<Message[]>([]);
  const errors = useMemo(
    () => splitMessages(messages, signupWithPassErrorKeys),
    [messages]
  );

  const [needsEmailVerification, setNeedsEmailVerification] = useState(false);

  const handleSubmit = useCallback(() => {
    setMessages(signupWithPassRule(signup));
    if (messages.length === 0) {
      queries.invitation.v3
        .signup(queryClient, apiConfig, invitationId, signup)
        .tap(response => {
          setResponseError([]);
          switch (response.type) {
            case "SignupSuccess":
              toast({
                status: "success",
                title: "Signup Successful",
                description: "We've signed you in to your new account.",
              });
              dispatchAuthAction(
                completeSigninAction(Result.pure(response.credentials.token))
              );
              navigate(routes.home.url([]));
              break;

            case "InvitationSignupChallengeExpired":
              setResponseError([errorMessage("Invitation has expired")]);
              break;

            case "InvitationSignupChallengeAlreadyAccepted":
              setResponseError([
                errorMessage("Invitation has already been accepted"),
              ]);
              break;

            case "InvitationSignupChallengeCanceled":
              setResponseError([errorMessage("Invitation has been canceled")]);
              break;

            case "InvitationSignupChallengeValidationErrors":
              setMessages(response.errors);
              break;

            case "InvitationSignupChallengeEmailVerificationRequired":
              setNeedsEmailVerification(true);
              break;

            default:
              return checkExhausted(response);
          }
        })
        .unsafeRun();
    }
  }, [
    apiConfig,
    dispatchAuthAction,
    invitationId,
    messages.length,
    navigate,
    queryClient,
    signup,
    toast,
  ]);

  return needsEmailVerification ? (
    <Para textAlign="center" fontSize="lg">
      Signup success! Please check your inbox and verify your email
    </Para>
  ) : (
    <chakra.form
      onSubmit={evt => {
        evt.preventDefault();
        handleSubmit();
      }}
    >
      <Spaced spacing="8">
        <Box as="header">
          <CartographerLogo />
        </Box>

        <Para textAlign="center" fontSize="lg">
          Create an account to accept your invitation.
        </Para>

        <MessageFormControl label="First Name" messages={errors.firstName}>
          <Input
            type="text"
            value={signup.firstName}
            onChange={evt =>
              setSignup({
                ...signup,
                screenName: screenNameTouched
                  ? signup.screenName
                  : formatScreenName(evt.currentTarget.value, signup.lastName),
                firstName: evt.currentTarget.value,
              })
            }
          />
        </MessageFormControl>

        <MessageFormControl label="Last Name" messages={errors.lastName}>
          <Input
            type="text"
            value={signup.lastName}
            onChange={evt =>
              setSignup({
                ...signup,
                screenName: screenNameTouched
                  ? signup.screenName
                  : formatScreenName(signup.firstName, evt.currentTarget.value),
                lastName: evt.currentTarget.value,
              })
            }
          />
        </MessageFormControl>

        <MessageFormControl label="Email" messages={errors.email}>
          <Input
            value={signup.email}
            placeholder="jane@example.com"
            onChange={evt =>
              setSignup({
                ...signup,
                email: unsafeEmail(evt.currentTarget.value),
              })
            }
          />

          <FormHelperText>You&apos;ll use this email to log in.</FormHelperText>
        </MessageFormControl>

        <MessageFormControl label="Screen Name" messages={errors.screenName}>
          <Input
            value={signup.screenName}
            onChange={evt => {
              setScreenNameTouched(true);
              setSignup({
                ...signup,
                screenName: unsafeScreenName(evt.currentTarget.value),
              });
            }}
          />

          <FormHelperText>
            A publicly visible handle used to credit you for the data you
            record. Change this to something other than your name if you prefer
            to keep your identity private.
            <br />
            <br />
            Administrators will always have access to your real name and email
            address.
          </FormHelperText>
        </MessageFormControl>

        <MessageFormControl
          label="Choose a Password"
          messages={errors.password}
        >
          <PasswordField
            value={signup.password}
            onChange={password => setSignup({ ...signup, password })}
          />

          <FormHelperText>
            Choose a password and <em>keep it secret</em>. Never disclose it to
            anyone! Our support team will never ask you for it.
          </FormHelperText>
        </MessageFormControl>

        <FormControl id="acceptTerms" isInvalid={errors.acceptTerms.length > 0}>
          <FormLabel text="Terms and conditions" />

          <Para fontSize="sm">
            Use of Cartographer is subject to our terms and conditions:
          </Para>

          <TermsDocuments />

          <Checkbox
            value={signup.acceptTerms}
            onChange={acceptTerms => setSignup({ ...signup, acceptTerms })}
            checkboxLabel="I have read, understood, and agreed to these documents"
          />

          <FormErrorMessage>
            {getUniqueMessages(errors.acceptTerms)}
          </FormErrorMessage>
        </FormControl>

        {(errors._rest_.length > 0 || responseError.length > 0) && (
          <FormHelperText fontWeight="bold" textColor="red.500">
            {getUniqueMessages(errors._rest_)}
            {getUniqueMessages(responseError)}
          </FormHelperText>
        )}

        <Button type="submit" colorScheme="blue" mt="2">
          Sign up
        </Button>

        <Box h="24" />
      </Spaced>
    </chakra.form>
  );
}
