import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function ShortInvitationCodePage(
  props: RouteProps<typeof routes.short.invitationCode.view>
): ReactElement {
  const {
    path: { invitationCodeRef },
    query,
  } = props;

  const apiParams = useApiParams();

  const code = useSuspenseQueryData(
    queries.invitation.code.v3.readOrFail(apiParams, invitationCodeRef)
  );

  const workspace = useSuspenseQueryData(
    queries.optional(code?.workspaceId, workspaceId =>
      queries.workspace.v2.readOrFail(apiParams, workspaceId)
    )
  );

  return workspace != null ? (
    <Navigate
      to={routes.workspace.invitation.code.view.url(
        [workspace.alias, code.alias],
        query
      )}
      replace={true}
    />
  ) : (
    <Navigate to={routes.home.url([])} />
  );
}
