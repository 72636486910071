import { Email, UserV2 } from "@cartographerio/types";
import { ReactElement, useEffect, useState } from "react";

import { FrontContext } from "./context";
import { FrontUserEditor } from "./FrontUserEditor";
import FrontUserList from "./FrontUserList";

export interface FrontUsersTabProps {
  context: FrontContext;
  searchString?: string | null;
  emails?: Email[];
}

export default function FrontUsersTab(props: FrontUsersTabProps): ReactElement {
  const { context, searchString = null, emails = [] } = props;

  const [selectedUser, setSelectedUser] = useState<UserV2 | null>(null);

  useEffect(() => {
    searchString;

    setSelectedUser(null);
  }, [searchString]);

  if (selectedUser == null) {
    return (
      <FrontUserList
        context={context}
        searchString={searchString}
        emails={emails}
        onUserClick={setSelectedUser}
      />
    );
  } else {
    return (
      <FrontUserEditor
        context={context}
        defaultUser={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
    );
  }
}
