import { errorMessage } from "@cartographerio/atlas-core";
import {
  Email,
  ForgotPasswordRequest,
  unsafeEmail,
} from "@cartographerio/types";
import { Box, Container, Flex, Input, VStack, chakra } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo, useState } from "react";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import { rule } from "../../schema/rule";
import { splitMessages } from "../../schema/rule/errors";
import { useIOAlert, useIOErrorAlert } from "../components/Alert";
import Button from "../components/Button";
import CartographerLogo from "../components/CartographerLogo";
import MessageFormControl from "../components/MessageFormControl";
import Para from "../components/Para";
import Spaced from "../components/Spaced";
import { useApiConfig } from "../contexts/apiConfig";
import { useOptCredentialsV2 } from "../contexts/auth";
import { usePageTitle } from "../hooks/usePageTitle";
import useRedirectWhen from "../hooks/useRedirectWhen";
import { routes } from "../routes";

const { rule: forgotPasswordRule, keys: forgotPasswordErrorKeys } = rule
  .build<ForgotPasswordRequest>()
  .field("email", rule.email([errorMessage("Must be a valid email address")]))
  .finish();

export default function ForgotPasswordPage(
  props: RouteProps<typeof routes.password.forgot>
): ReactElement {
  const {
    query: { email: emailParam },
  } = props;

  const apiConfig = useApiConfig();
  const errorAlert = useIOErrorAlert();

  const postRNMessage = useRNPostMessageCallback();

  useRedirectWhen(useOptCredentialsV2() != null, () => routes.home.url([]));

  usePageTitle("Forgot Password");

  const alert = useIOAlert();

  const [email, setEmail] = useState<Email>(
    unsafeEmail(emailParam?.toLowerCase() ?? "")
  );

  const messages = useMemo(() => forgotPasswordRule({ email }), [email]);
  const errors = useMemo(
    () => splitMessages(messages, forgotPasswordErrorKeys),
    [messages]
  );

  const handleSubmit = useCallback(() => {
    if (messages.length === 0) {
      queries.auth.v2
        .forgotPassword(apiConfig, { email })
        .andThen(
          alert({
            title: "Email sent",
            message: "Check your email for the reset link",
          })
        )
        .tap(() => postRNMessage("ForgotPasswordSubmitted"))
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [alert, apiConfig, email, errorAlert, messages.length, postRNMessage]);

  return (
    <Flex
      w="100vw"
      minH="100vh"
      direction="column"
      justify="center"
      align="center"
      bg="gray.50"
    >
      <Container maxW="50ch">
        <chakra.form
          onSubmit={evt => {
            evt.preventDefault();
            handleSubmit();
          }}
        >
          <VStack spacing="8" alignItems="stretch">
            <Box as="header">
              <CartographerLogo />
            </Box>
            <Para textAlign="center" fontSize="lg">
              Please enter your email so we can send you a reset link
            </Para>
            <Box bg="white" p="8" shadow="md" rounded="md">
              <Spaced spacing="8">
                <MessageFormControl
                  label="Enter your email address"
                  messages={errors.email}
                >
                  <Input
                    value={email}
                    placeholder="jane@example.com"
                    onChange={evt =>
                      setEmail(unsafeEmail(evt.currentTarget.value))
                    }
                    autoCapitalize="none"
                    autoCorrect="off"
                  />
                </MessageFormControl>

                <Button
                  type="submit"
                  colorScheme="blue"
                  label="Reset My Password"
                />
              </Spaced>
            </Box>

            <Box h="24" />
          </VStack>
        </chakra.form>
      </Container>
    </Flex>
  );
}

function useRNPostMessageCallback(): (data: unknown) => boolean {
  return useCallback((data: unknown) => {
    if ("ReactNativeWebView" in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).ReactNativeWebView.postMessage(data);
      return true;
    } else {
      return false;
    }
  }, []);
}
