import {
  SelectOption,
  SelectSection,
  SelectValue,
} from "@cartographerio/atlas-form";
import { Option } from "@cartographerio/fp";
import { filterAndMap, findAndMap } from "@cartographerio/util";
import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
  chakra,
} from "@chakra-ui/react";
import { UseComboboxReturnValue, useCombobox } from "downshift";
import { chain } from "lodash";
import {
  FocusEventHandler,
  Fragment,
  ReactElement,
  ReactNode,
  memo,
  useCallback,
  useMemo,
} from "react";
import { IconType } from "react-icons";
import { IoChevronDown, IoClose } from "react-icons/io5";

import { Highlight, useInputHighlight } from "../hooks/highlight";
import useDebouncedTextValue from "../hooks/useDebouncedTextValue";
import { FuseOptions, FuseResult } from "../hooks/useFuse";
import useFuseOptimized from "../hooks/useFuseOptimized";
import { useInputFocus } from "../hooks/useInputFocus";
import { useVolatileState } from "../hooks/useVolatileState";
import { Highlighted } from "./Autocomplete/Default";

const EmptyIcon = chakra.path;

export interface SectionedSelectProps<T extends SelectValue>
  extends Omit<InputGroupProps, "onChange"> {
  value?: T | null;
  sections: SelectSection<T>[];
  fuseOptions: Omit<
    FuseOptions<SelectOption<T>>,
    "getFn" | "keys" | "includeScore"
  >;
  maxLength?: number;
  renderIcon?: (option: T | null) => IconType | null;
  onChange?: (option: T | null) => void;
  highlight?: Highlight;
  disabled?: boolean;
  debounce?: number;
  emptyText?: string;
}

export default function SectionedSelect<T extends SelectValue>(
  props: SectionedSelectProps<T>
): ReactElement {
  const {
    value: _value,
    sections,
    maxLength,
    fuseOptions,
    renderIcon,
    onChange,
    highlight,
    disabled,
    debounce = 200,
    emptyText = "No options found. Please adjust your search",
    ...rest
  } = props;

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const [value, setValue] = useVolatileState(
    useCallback(() => _value, [_value])
  );

  const valueOption = useMemo(
    () =>
      findAndMap(sections, section =>
        section.options.find(option => option.value === value)
      ),
    [sections, value]
  );

  const allItems = useMemo(
    () =>
      chain(
        sections.reduce(
          (all: SelectOption<T>[], { options }): SelectOption<T>[] =>
            all.concat(
              options.filter(({ value }) =>
                all.every(other => other.value !== value)
              )
            ),
          []
        )
      )
        .sortBy(({ label }) => label)
        .value(),
    [sections]
  );

  const [fuseResults, fuseOnChange] = useFuseOptimized(
    allItems,
    { ...fuseOptions, includeScore: true },
    option => option.label,
    maxLength ?? 1000,
    false,
    valueOption?.label
  );

  const {
    textValue,
    localError,
    handleBlur,
    handleFocus,
    handleTextValueChange,
    handleTextChange,
  } = useDebouncedTextValue<string, HTMLInputElement>({
    defaultValue: valueOption?.label,
    format: value => value,
    validate: value => Option.wrap(value),
    onFocus: _handleFocus,
    onBlur: () => {
      _handleBlur();
      if (_value !== value) {
        onChange?.(value ?? null);
      }
    },
    onChange: (newText: string) => fuseOnChange(newText),
    debounce,
  });

  const { headingsIndices, allFilteredItems, allFilteredResults } =
    useMemo(() => {
      const labelToResult = fuseResults.reduce(
        (acc, result) => ({
          ...acc,
          [`${result.item.label}`]: result,
        }),
        {} as Record<string, FuseResult<SelectOption<T>>>
      );
      const filteredSections = filterAndMap(sections, section => {
        const filteredOptions = section.options.filter(
          option => labelToResult[`${option.label}`] != null
        );
        return filteredOptions.length > 0
          ? {
              ...section,
              options:
                textValue.trim().length === 0
                  ? filteredOptions
                  : filteredOptions.sort(
                      (a, b) =>
                        (labelToResult[`${a.label}`].score ?? 1) -
                        (labelToResult[`${b.label}`].score ?? 1)
                    ),
            }
          : null;
      });

      const headingsIndices: Record<number, string> = filteredSections.reduce(
        (acc, section) => ({
          itemsSoFar: acc.itemsSoFar + section.options.length,
          headings:
            section.heading == null
              ? acc.headings
              : { ...acc.headings, [acc.itemsSoFar]: section.heading },
        }),
        { itemsSoFar: 0, headings: {} }
      ).headings;

      const allFilteredItems = filteredSections.flatMap(
        ({ options }) => options
      );
      return {
        headingsIndices,
        allFilteredItems,
        allFilteredResults: allFilteredItems.map<FuseResult<SelectOption<T>>>(
          option => ({
            ...labelToResult[option.label],
            item: option,
          })
        ),
      };
    }, [fuseResults, sections, textValue]);

  const {
    selectedItem,
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox<SelectOption<T> | null>({
    inputValue: textValue,
    items: allFilteredItems,
    itemToString: item => item?.label ?? "Unknown Item",
    defaultHighlightedIndex: 0,
    defaultSelectedItem: valueOption,
    onSelectedItemChange: ({ selectedItem }) => {
      setValue(selectedItem?.value ?? null);
      handleTextValueChange(
        selectedItem?.value != null ? selectedItem.label ?? "" : ""
      );
    },
  });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    evt => {
      handleBlur(evt);
      handleTextValueChange(selectedItem?.label ?? "Unknown Item");
    },
    [handleBlur, handleTextValueChange, selectedItem]
  );

  const inputProps = getInputProps({
    onBlur,
    onChange: handleTextChange,
    onFocus: handleFocus,
    onKeyUp: evt => {
      if (evt.key === "Enter" && _value !== value) {
        onChange?.(value ?? null);
      }
    },
    disabled,
  });

  return (
    <Popover isOpen={isOpen} isLazy={true} autoFocus={false} matchWidth={true}>
      <PopoverTrigger>
        <InputGroup {...rest} {...getComboboxProps()}>
          {renderIcon != null && (
            <InputLeftElement>
              <Icon
                ms="1"
                as={
                  focused
                    ? undefined
                    : renderIcon(selectedItem?.value ?? null) ?? undefined
                }
              >
                <EmptyIcon />
              </Icon>
            </InputLeftElement>
          )}
          <Input
            {...inputProps}
            cursor={focused ? undefined : "default"}
            borderColor={borderColor}
            borderWidth={borderWidth}
          />
          <InputRightElement>
            {!disabled && (
              <Button
                isDisabled={disabled}
                variant="unstyled"
                {...getToggleButtonProps({
                  onClick: () => {
                    handleTextValueChange("");
                    setValue(null);
                  },
                })}
              >
                <Icon as={textValue.length > 0 ? IoClose : IoChevronDown} />
              </Button>
            )}
          </InputRightElement>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent maxH="60vh" overflowY="auto" {...getMenuProps()}>
        <InternalList
          results={allFilteredResults}
          headingsIndices={headingsIndices}
          highlightedIndex={highlightedIndex}
          emptyText={emptyText}
          getItemProps={getItemProps}
        />
      </PopoverContent>
    </Popover>
  );
}

interface InternalListProps<T extends SelectValue> {
  results: FuseResult<SelectOption<T>>[];
  headingsIndices: Record<number, string>;
  highlightedIndex?: number;
  emptyText: string;
  getItemProps: UseComboboxReturnValue<SelectOption<T> | null>["getItemProps"];
}

const _InternalList = function <T extends SelectValue>(
  props: InternalListProps<T>
): ReactNode {
  const {
    results,
    headingsIndices,
    highlightedIndex,
    emptyText,
    getItemProps,
  } = props;

  const optionToText = useCallback(
    (option: SelectOption<T>) => option.label,
    []
  );

  return results.length === 0 ? (
    <Flex py="2" px="3" alignItems="center" fontSize="sm" color="gray.500">
      {emptyText}
    </Flex>
  ) : (
    results.map((result, index) => (
      <Fragment key={result.item.key ?? `${result.item.value}`}>
        {headingsIndices[index] != null && (
          <Flex
            py="2"
            px="3"
            alignItems="center"
            fontSize="sm"
            color="gray.500"
          >
            {headingsIndices[index]}
          </Flex>
        )}
        <Flex
          key={`${result.item.value}`}
          cursor="pointer"
          alignItems="center"
          borderColor="gray.500"
          bg={highlightedIndex === index ? "blue.50" : undefined}
          tabIndex={0}
          py="2"
          px="3"
          {...getItemProps({ item: result.item, index })}
        >
          <Highlighted result={result} toText={optionToText} />
        </Flex>
      </Fragment>
    ))
  );
};

const InternalList = memo(_InternalList) as typeof _InternalList;
