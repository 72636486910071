import { checks } from "@cartographerio/permission";
import {
  Qualification,
  QualificationId,
  QualificationRole,
  QualificationRoleName,
  QualificationRoleNameEnum,
  qualificationRole,
  qualificationRoleName,
  roleQualificationId,
} from "@cartographerio/types";
import { filterAndMap, findAndMap } from "@cartographerio/util";
import { Flex } from "@chakra-ui/layout";
import { ReactElement, useCallback, useMemo } from "react";

import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import FormLabel from "./FormLabel";
import Panel from "./Panel";
import PanelRow from "./PanelRow";
import Select from "./Select";

interface QualificationRoleEditorProps {
  value: QualificationRole[];
  qualifications: Qualification[];
  disabled?: boolean;
  onChange: (value: QualificationRole[]) => void;
}

export default function QualificationRoleEditor(
  props: QualificationRoleEditorProps
): ReactElement {
  const { value, qualifications, disabled, onChange } = props;

  const permissionCheckPasses = usePermissionCheckRunner();

  const roleData = useMemo(
    () =>
      filterAndMap(qualifications, qualification => {
        const roleNames = QualificationRoleNameEnum.entries.filter(
          ({ value }) =>
            permissionCheckPasses(
              checks.qualification.grant(value, qualification.id)
            )
        );
        const current = findAndMap(value, role =>
          roleQualificationId(role) === qualification.id
            ? qualificationRoleName(role)
            : null
        );
        return roleNames.length > 0
          ? { qualification, roleNames, current }
          : null;
      }),
    [permissionCheckPasses, qualifications, value]
  );

  const handleChange = useCallback(
    (roleName: QualificationRoleName | null, id: QualificationId) => {
      const cleanedQualificationRoles = value.filter(
        role => roleQualificationId(role) !== id
      );
      onChange(
        roleName != null
          ? cleanedQualificationRoles.concat(qualificationRole(roleName, id))
          : cleanedQualificationRoles
      );
    },
    [onChange, value]
  );

  return (
    <Panel>
      {roleData.map(({ qualification, roleNames, current }) => (
        <PanelRow key={qualification.id}>
          <Flex
            w="100%"
            direction={["column", "row"]}
            justify="space-between"
            rowGap="2"
            align={["flex-start", "center"]}
          >
            <FormLabel text={qualification.name} fontWeight="normal" mb="0" />
            <Select.Nullable
              placeholder="No Role"
              value={current}
              options={roleNames}
              onChange={roleName => handleChange(roleName, qualification.id)}
              disabled={disabled}
              w={["100%", "20ch"]}
            />
          </Flex>
        </PanelRow>
      ))}
    </Panel>
  );
}
