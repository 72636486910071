import {
  KnownWorkspaceFeatureEnum,
  ProjectV2,
  WorkspaceV2,
} from "@cartographerio/types";
import { useMemo } from "react";

export function usePreferTeams(
  workspace: WorkspaceV2,
  project?: ProjectV2
): boolean {
  return useMemo(
    () =>
      [
        KnownWorkspaceFeatureEnum.EnableTeams,
        KnownWorkspaceFeatureEnum.PreferTeams,
      ].every(feature => workspace.features.includes(feature)) &&
      (project == null || project.teamIds.length > 0),
    [project, workspace.features]
  );
}
