import {
  CheckWorkspaceAccessResult,
  emptySearchResults,
} from "@cartographerio/types";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBoolean,
} from "@chakra-ui/react";
import { useHotkey } from "@react-hook/hotkey";
import { ReactElement, ReactNode, useCallback } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import WorkspaceSelector from "./WorkspaceSelector";

export interface CommandBarProviderProps {
  children: ReactNode;
}

export default function CommandBarProvider(
  props: CommandBarProviderProps
): ReactElement {
  const { children } = props;

  const apiParams = useApiParams();

  // If we're not logged in, don't bother trying to check workspace access (it'll just throw a missing auth exception):
  const access = useSuspenseQueryData(
    queries.optional(
      apiParams.auth,
      () => queries.auth.v2.listWorkspaceAccess(apiParams),
      () => queries.constant(emptySearchResults<CheckWorkspaceAccessResult>())
    )
  );

  const [isOpen, { on: onOpen, off: onClose }] = useBoolean();

  useHotkey(
    window,
    ["mod", "k"],
    useCallback(
      evt => {
        evt.stopPropagation();
        evt.preventDefault();
        onOpen();
      },
      [onOpen]
    )
  );

  return (
    <>
      {children}
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p="2">
          <WorkspaceSelector
            options={access?.results ?? []}
            onSelect={onClose}
            onCancel={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
