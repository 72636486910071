import { toBBox4 } from "@cartographerio/geometry";
import { IO } from "@cartographerio/io";
import {
  ProjectMapSettings,
  TeamId,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { useMap } from "react-map-gl";

export type FetchMapSettings = (
  team: TeamId | null
) => IO<ProjectMapSettings | null>;

export interface MapSettingsContext {
  zoomToProject: () => void;
  zoomToTeam: (team: TeamId | null) => void;
}

export const MapSettingsContext =
  createContext<MapSettingsContext | undefined>(undefined);

export function useMapSettingsContext(): MapSettingsContext {
  return (
    useContext(MapSettingsContext) ??
    raise(internalError("MapSettingsContext not available"))
  );
}

export interface MapSettingsContextProviderProps {
  fetchMapSettings: FetchMapSettings;
  children: ReactNode;
}

export function MapSettingsContextProvider(
  props: MapSettingsContextProviderProps
): ReactElement {
  const { fetchMapSettings, children } = props;

  const map = useMap().default;

  const zoomToTeam = useCallback(
    (teamId: TeamId | null) => {
      if (map != null) {
        fetchMapSettings(teamId)
          .map(settings => (settings == null ? null : settings.defaultBounds))
          .map(bounds => (bounds == null ? null : toBBox4(bounds)))
          .tap(bounds => bounds != null && map.fitBounds(bounds))
          .recover(error => console.warn("error getting map settings", error))
          .unsafeRun();
      }
    },
    [fetchMapSettings, map]
  );

  const zoomToProject = useCallback(() => {
    zoomToTeam(null);
  }, [zoomToTeam]);

  const context: MapSettingsContext = useMemo(
    () => ({ zoomToProject, zoomToTeam }),
    [zoomToProject, zoomToTeam]
  );

  return (
    <MapSettingsContext.Provider value={context}>
      {children}
    </MapSettingsContext.Provider>
  );
}
