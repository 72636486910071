import {
  Attribute,
  MapLayer,
  MarkerStyle,
  NumberAttribute,
  PropertyAttribute,
  attributePropertyName,
  bucketLabel,
  findBucketIndex,
} from "@cartographerio/atlas-map";
import { featureProperty } from "@cartographerio/geometry";
import { Box, Flex, Stack, chakra } from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

import Ellipsise from "../../components/Ellipsise";
import {
  useLayerBuckets,
  useMapLayers,
  useSelectedAttribute,
  useSelectedFeatures,
  useSelectedLayer,
  useSupportedAttributes,
  useVisibleFeatures,
} from "../AtlasMapContext";
import AttributeSelect from "./AttributeSelect";
import AttributeValues from "./AttributeValues";
import InspectorBar from "./InspectorBar";
import InspectorHelp from "./InspectorHelp";
import InspectorMenuItem from "./InspectorMenuItem";
import InspectorPanel, { InspectorPanelProps } from "./InspectorPanel";
import InspectorSection from "./InspectorSection";
import InspectorTitle from "./InspectorTitle";
import LayerSelect from "./LayerSelect";
import TimelineChart from "./TimelineChart";

type OnBackClick = () => void;

export interface LegendPanelProps extends InspectorPanelProps {
  onBackClick: OnBackClick;
}

function isTimelineAttribute(
  attribute: Attribute
): attribute is NumberAttribute {
  return (
    attribute.type === "NumberAttribute" && attribute.showTimeline === true
  );
}

export default function LegendPanel(props: LegendPanelProps): ReactElement {
  const { onBackClick, ...rest } = props;

  const layers = useMapLayers();
  const [layer] = useSelectedLayer();
  const [attribute, setAttribute] = useSelectedAttribute(layer.layerId);
  const { uniqueSelectedFeatures } = useSelectedFeatures(layer.layerId);

  const showLayerSelect = layers.length > 1;

  const attributes = useSupportedAttributes(layer.attributes);
  const showAttributeSelect = attributes.length > 1;

  return (
    <InspectorPanel {...rest}>
      <InspectorTitle title={layer.title} onBackClick={onBackClick} />

      {showLayerSelect && (
        <InspectorSection title="Layer">
          <InspectorMenuItem>
            <LayerSelect />
          </InspectorMenuItem>
        </InspectorSection>
      )}

      {showAttributeSelect && (
        <InspectorSection title="Attribute">
          <InspectorMenuItem>
            <AttributeSelect
              layer={layer}
              options={attributes}
              value={attribute}
              onChange={setAttribute}
            />
          </InspectorMenuItem>
        </InspectorSection>
      )}

      {attribute != null && uniqueSelectedFeatures.length === 0 && (
        <InspectorHelp>Click a map marker to see details.</InspectorHelp>
      )}

      {attribute != null && uniqueSelectedFeatures.length > 0 && (
        <InspectorSection title="Selected Values">
          <InspectorMenuItem>
            <AttributeValues
              attribute={attribute}
              features={uniqueSelectedFeatures}
              fullView={true}
            />
          </InspectorMenuItem>
        </InspectorSection>
      )}

      {attribute != null && (
        <LegendSection layer={layer} attribute={attribute} />
      )}

      {attribute != null && isTimelineAttribute(attribute) && (
        <TimelineChart layer={layer} attribute={attribute} />
      )}
    </InspectorPanel>
  );
}

interface LegendProps<A extends Attribute = Attribute> {
  layer: MapLayer;
  attribute: A;
}

function LegendSection(props: LegendProps): ReactElement | null {
  const { layer, attribute } = props;

  switch (attribute.type) {
    case "FeatureAttribute":
    case "SurveyAttribute":
    case "AttachmentAttribute":
      return null;

    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "TeamAttribute":
      return (
        <InspectorSection title="Legend">
          <Legend layer={layer} attribute={attribute} />
        </InspectorSection>
      );
  }
}

function Legend(props: LegendProps<PropertyAttribute>): ReactElement {
  const { layer, attribute } = props;

  const [features] = useVisibleFeatures(layer.layerId);

  const buckets = useLayerBuckets(layer.layerId);

  const counts: number[] = useMemo(() => {
    const propertyName =
      attribute == null ? null : attributePropertyName(attribute);

    if (propertyName == null) {
      return [];
    } else {
      return features.reduce(
        (counts, feature) => {
          const index = findBucketIndex(
            buckets,
            featureProperty(feature, propertyName)
          );
          if (index >= 0 && index < counts.length) {
            counts[index]++;
          }
          return counts;
        },
        buckets.map(_ => 0)
      );
    }
  }, [attribute, buckets, features]);

  const maxCount = counts.reduce((x, y) => Math.max(x, y), 0);

  return (
    <Stack spacing="3">
      {buckets.map((bucket, index) => (
        <LegendEntry
          key={index}
          layerType={layer.type}
          marker={bucket.marker}
          label={bucketLabel(attribute, bucket) ?? "-"}
          count={counts[index]}
          maxCount={maxCount}
        />
      ))}
    </Stack>
  );
}

type MapLayerType = MapLayer["type"];

interface LegendEntryProps {
  layerType: MapLayerType;
  marker: MarkerStyle;
  label: string;
  count?: number;
  maxCount?: number;
}

function LegendEntry(props: LegendEntryProps): ReactElement {
  const { layerType, marker, label, count, maxCount } = props;

  return (
    <InspectorMenuItem left={<Marker layerType={layerType} marker={marker} />}>
      <Flex
        w="100%"
        direction="column"
        alignSelf="stretch"
        alignItems="stretch"
        className="legend-outer"
      >
        <Flex
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          wrap="nowrap"
        >
          <Ellipsise text={label || "(No value)"} />
          {count != null && maxCount != null && maxCount > 0 && (
            <Flex
              className="legend-count"
              grow={0}
              shrink={0}
              textAlign="right"
              fontSize="xx-small"
              color="gray.500"
              pl="2"
            >
              {count}
            </Flex>
          )}
        </Flex>
        <InspectorBar marker={marker} count={count} maxCount={maxCount} />
      </Flex>
    </InspectorMenuItem>
  );
}

interface MarkerProps {
  layerType: MapLayerType;
  marker: MarkerStyle;
}

function Marker(props: MarkerProps): ReactElement {
  const { layerType, marker } = props;

  const size = 20; // marker.normal.size;
  const strokeWidth = 2; // marker.normal.strokeWidth;
  const strokeColor = marker.normal.strokeColor;
  const fillColor = marker.normal.fillColor;

  const coord = (m: number) => strokeWidth + m * (size - 2 * strokeWidth);

  switch (layerType) {
    case "LineLayer":
      return (
        <Svg width={size} height={size}>
          <polyline
            points={`
              ${coord(1.0)},${coord(0.0)}
              ${coord(0.4)},${coord(0.2)}
              ${coord(0.6)},${coord(0.8)}
              ${coord(0.0)},${coord(1.0)}
            `}
            style={{
              stroke: fillColor,
              strokeWidth: strokeWidth ?? 2,
              fill: "none",
            }}
          />
        </Svg>
      );

    case "PointLayer":
      return (
        <Box>
          <Svg width={size} height={size}>
            <chakra.circle
              cx={coord(0.5)}
              cy={coord(0.5)}
              r={0.5 * (size - 2 * strokeWidth)}
              style={{
                stroke: strokeColor,
                strokeWidth: strokeWidth ?? 2,
                // strokePosition: "inside", // Can't rely on this being supported everywhere
                fill: fillColor,
              }}
            />
          </Svg>
        </Box>
      );

    case "PolygonLayer":
      return (
        <Svg width={size} height={size}>
          <polygon
            points={`
              ${coord(0.905)},${coord(0.794)}
              ${coord(0.345)},${coord(0.976)}
              ${coord(0.0)},${coord(0.5)}
              ${coord(0.345)},${coord(0.0245)}
              ${coord(0.905)},${coord(0.206)}
            `}
            style={{
              stroke: strokeColor,
              strokeWidth: strokeWidth ?? 2,
              // strokePosition: "inside", // Can't rely on this being supported everywhere
              fill: fillColor,
            }}
          />
        </Svg>
      );
  }
}

interface SvgProps {
  width: number;
  height: number;
  children: ReactNode;
}

function Svg(props: SvgProps): ReactElement {
  const { width, height, children } = props;
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      {children}
    </svg>
  );
}
