import { Result } from "@cartographerio/fp";
import { AccessToken, ApiConfig } from "@cartographerio/types";

interface StartInitialiseAction {
  type: "StartInitialise";
  apiConfig: ApiConfig;
}

interface CompleteInitialiseAction {
  type: "CompleteInitialise";
  result: Result<string, AccessToken | null>;
}

interface CompleteSigninAction {
  type: "CompleteSignin";
  result: Result<string, AccessToken>;
}

interface CompleteSignoutAction {
  type: "CompleteSignout";
}

interface ChangeIdentityAction {
  type: "ChangeIdentity";
  accessToken: AccessToken;
}

interface RestoreIdentityAction {
  type: "RestoreIdentity";
}

export type AuthAction =
  | StartInitialiseAction
  | CompleteInitialiseAction
  | CompleteSigninAction
  | CompleteSignoutAction
  | ChangeIdentityAction
  | RestoreIdentityAction;

export function startInitialiseAction(apiConfig: ApiConfig): AuthAction {
  return {
    type: "StartInitialise",
    apiConfig,
  };
}

export function completeInitialiseAction(
  result: Result<string, AccessToken | null>
): AuthAction {
  return {
    type: "CompleteInitialise",
    result,
  };
}

export function completeSigninAction(
  result: Result<string, AccessToken>
): AuthAction {
  return {
    type: "CompleteSignin",
    result,
  };
}

export function completeSignoutAction(): AuthAction {
  return {
    type: "CompleteSignout",
  };
}

export function changeIdentityAction(accessToken: AccessToken): AuthAction {
  return {
    type: "ChangeIdentity",
    accessToken,
  };
}

export function restoreIdentityAction(): AuthAction {
  return {
    type: "RestoreIdentity",
  };
}
