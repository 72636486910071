import { Textarea } from "@chakra-ui/react";
import { ReactElement } from "react";

import { useInputHighlight } from "../../hooks/highlight";
import useDebouncedTextValue from "../../hooks/useDebouncedTextValue";
import { useInputFocus } from "../../hooks/useInputFocus";
import { BaseTextAreaProps, MappedTextAreaProps } from "./base";

export interface DefaultTextAreaProps<A>
  extends BaseTextAreaProps<A>,
    MappedTextAreaProps<A> {}

export default function DefaultTextArea<A>(
  props: DefaultTextAreaProps<A>
): ReactElement {
  const {
    value,
    defaultValue,
    onChange,
    rows = 5,
    format,
    validate,
    highlight,
    debounce,
    bg = "white",
    ...rest
  } = props;

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const { textValue, localError, handleBlur, handleFocus, handleTextChange } =
    useDebouncedTextValue<A, HTMLTextAreaElement>({
      value,
      defaultValue,
      format,
      validate,
      onFocus: _handleFocus,
      onBlur: _handleBlur,
      onChange,
      debounce,
    });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  return (
    <Textarea
      bg={bg}
      value={textValue}
      onChange={handleTextChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      rows={rows}
      borderColor={borderColor}
      borderWidth={borderWidth}
      {...rest}
    />
  );
}
