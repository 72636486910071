import { SelectOption } from "@cartographerio/atlas-form";
import { UserSortKey } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { check } from "@cartographerio/permission";
import {
  ProjectId,
  TeamId,
  TeamV2,
  UserV2,
  WorkspaceId,
  WorkspaceRoleNameEnum,
  isProjectId,
  isTeamId,
  isUserTransferRequestV2,
  roleProjectIdV2,
  roleTeamIdV2,
} from "@cartographerio/types";
import { FormControl, HStack, chakra } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { chain, fromPairs, isEqual } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";

import { DEFAULT_PAGE_SIZE } from "../../../config";
import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { DeriveOutgoingQueryProps } from "../../../routes/derive";
import { splitMessages } from "../../../schema/rule/errors";
import {
  PartialUserTransferRequest,
  userTransferRequestErrorKeys,
  userTransferRequestRule,
} from "../../../schema/UserTransferRequest";
import recordWithId from "../../../util/recordWithId";
import { useIOAlert, useIOErrorAlert } from "../../components/Alert";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import FormLabel from "../../components/FormLabel";
import JsonView from "../../components/JsonView";
import MessageFormControl from "../../components/MessageFormControl";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import Placeholder from "../../components/Placeholder";
import Select from "../../components/Select";
import Spaced from "../../components/Spaced";
import TreeContainer from "../../components/TreeContainer";
import TreeNode from "../../components/TreeNode";
import UserList from "../../components/UserList";
import { emailColumn, nameColumn } from "../../components/UserList/column";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import usePrevious from "../../hooks/usePrevious";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import {
  order,
  pageIndex,
  pageSize,
  projectAlias,
  string,
  workspaceRole,
} from "../../routes/queryParams";
import AdminPageHeader from "./AdminPageHeader";

const userTransferOpts = {
  q: string,
  order: order<UserSortKey>(["created", "email", "name", "screenname"]),
  page: pageIndex,
  count: pageSize,
  role: workspaceRole,
  project: projectAlias,
};
const userTransferColumns = [nameColumn, emailColumn];

export default function AdminUserTransferPage(
  _props: RouteProps<typeof routes.admin.transfer.user>
): ReactElement {
  const apiParams = useApiParams();

  const [value, setValue] = useState<PartialUserTransferRequest>({
    srcWorkspace: null,
    desWorkspace: null,
    transferSurveys: true,
    users: [],
    projectMappings: [],
    teamMappings: [],
  });

  useRequirePermissionRedirect(check.superuser, () =>
    routes.admin.home.url([])
  );

  usePageTitle("User Transfer - Admin");

  const [searchOpts, setSearchOpts] = useState<
    DeriveOutgoingQueryProps<typeof userTransferOpts>
  >({
    q: "",
    order: "name-asc",
    page: 0,
    role: null,
    count: DEFAULT_PAGE_SIZE,
  });

  const projectMappingsLookup = useMemo<Record<ProjectId, ProjectId | null>>(
    () =>
      value.projectMappings.reduce(
        (acc, [from, to]) => ({
          ...acc,
          [from]: to,
        }),
        {}
      ),
    [value.projectMappings]
  );
  const teamMappingsLookup = useMemo<Record<TeamId, TeamId | null>>(
    () =>
      value.teamMappings.reduce(
        (acc, [from, to]) => ({
          ...acc,
          [from]: to,
        }),
        {}
      ),
    [value.teamMappings]
  );

  const [selectedUsers, setSelectedUsers] = useState<UserV2[]>([]);

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const workspaceOptions: SelectOption<WorkspaceId>[] = useMemo(
    () => workspaces.map(({ name, id }) => ({ label: name, value: id })),
    [workspaces]
  );

  const { data: allSrcProjects } = useQuery(
    queries.optional(value.srcWorkspace, workspace =>
      queries.project.v2.forWorkspace(apiParams, workspace)
    )
  );

  const srcProjects = useMemo(() => {
    const userProjects: Set<ProjectId> = new Set(
      selectedUsers.flatMap(({ roles }) =>
        roles.map(roleProjectIdV2).filter(isProjectId)
      )
    );
    return allSrcProjects?.results.filter(({ id }) => userProjects.has(id));
  }, [allSrcProjects?.results, selectedUsers]);

  const { data: allSrcTeams } = useQuery(
    queries.optional(value.srcWorkspace, workspace =>
      queries.team.v2.forWorkspace(apiParams, workspace)
    )
  );

  const srcTeams = useMemo<Record<ProjectId, TeamV2[]>>(() => {
    const userTeams: Set<TeamId> = new Set(
      selectedUsers.flatMap(user =>
        user.roles.map(roleTeamIdV2).filter(isTeamId)
      )
    );

    const filteredTeams = allSrcTeams?.results.filter(({ id }) =>
      userTeams.has(id)
    );

    return (
      srcProjects?.reduce(
        (acc, project) => ({
          ...acc,
          [project.id]: filteredTeams?.filter(({ projectIds }) =>
            projectIds.includes(project.id)
          ),
        }),
        {}
      ) ?? {}
    );
  }, [allSrcTeams?.results, selectedUsers, srcProjects]);

  const handleUserSelectionChange = useCallback(
    (users: UserV2[]) => {
      setSelectedUsers(users);
      setValue({ ...value, users: users.map(recordWithId) });
    },
    [value]
  );

  const { data: allDesProjects } = useQuery(
    queries.optional(value.desWorkspace, workspace =>
      queries.project.v2.forWorkspace(apiParams, workspace)
    )
  );

  const desProjectOptions = useMemo<SelectOption<ProjectId>[]>(
    () =>
      allDesProjects?.results.map(({ id, name }) => ({
        label: name,
        value: id,
      })) ?? [],
    [allDesProjects?.results]
  );

  const { data: allDesTeams } = useQuery(
    queries.optional(value.desWorkspace, workspace =>
      queries.team.v2.forWorkspace(apiParams, workspace)
    )
  );

  const getDesTeamOptions = useCallback(
    (desProject: ProjectId | null): SelectOption<TeamId>[] =>
      desProject != null && allDesTeams != null
        ? allDesTeams.results
            .filter(({ projectIds }) => projectIds.includes(desProject))
            .map(({ id, name }) => ({ label: name, value: id }))
        : [],
    [allDesTeams]
  );

  const handleProjectMappingChange = useCallback(
    (from: ProjectId, to: ProjectId | null) => {
      const mappedFrom = projectMappingsLookup[from];
      const teamsToReset = new Set(
        mappedFrom != null && allDesTeams != null
          ? allDesTeams.results
              .filter(({ projectIds }) => projectIds.includes(mappedFrom))
              .map(recordWithId)
          : []
      );

      setValue({
        ...value,
        projectMappings: [
          ...value.projectMappings.filter(([id]) => id !== from),
          [from, to],
        ],
        teamMappings: value.teamMappings.filter(
          ([_, to]) => to == null || !teamsToReset.has(to)
        ),
      });
    },
    [allDesTeams, projectMappingsLookup, value]
  );

  const prevValue = usePrevious(value);
  const prevSrcProjects = usePrevious(srcProjects);
  const prevSrcTeams = usePrevious(srcTeams);

  useEffect(() => {
    if (
      srcProjects !== prevSrcProjects ||
      srcTeams !== prevSrcTeams ||
      !isEqual(value.projectMappings, prevValue.projectMappings) ||
      !isEqual(value.teamMappings, prevValue.teamMappings)
    ) {
      const currProjectMappings = fromPairs(value.projectMappings);
      const currTeamMappings = fromPairs(value.teamMappings);

      const projectMappings = chain(srcProjects ?? [])
        .map<[ProjectId, ProjectId | null]>(p => [
          p.id,
          currProjectMappings[p.id] ?? null,
        ])
        .value();

      const teamMappings = chain(srcTeams)
        .values()
        .flatten()
        .map<[TeamId, TeamId | null]>(t => [
          t.id,
          currTeamMappings[t.id] ?? null,
        ])
        .value();

      setValue({
        ...value,
        projectMappings: projectMappings,
        teamMappings: teamMappings,
      });
    }
  }, [
    prevSrcProjects,
    prevSrcTeams,
    prevValue.desWorkspace,
    prevValue.projectMappings,
    prevValue.srcWorkspace,
    prevValue.teamMappings,
    srcProjects,
    srcTeams,
    value,
    workspaces,
  ]);

  const messages = useMemo(() => userTransferRequestRule(value), [value]);
  const errors = useMemo(
    () => splitMessages(messages, userTransferRequestErrorKeys),
    [messages]
  );

  const queryClient = useQueryClient();
  const alert = useIOAlert();
  const errorAlert = useIOErrorAlert();
  const [saving, setSaving] = useState(false);

  const onSave = useCallback(() => {
    if (isUserTransferRequestV2(value)) {
      IO.wrap(() => setSaving(true))
        .andThen(queries.transfer.v2.user(queryClient, apiParams, value))
        .tap(result => {
          const anyFailures =
            result.users.failed.length > 0 || result.surveys.failed.length > 0;

          return alert({
            title: anyFailures
              ? "Transfer Partially Complete"
              : "Transfer Complete",
            message: (
              <>
                <chakra.p>The following transfers were successful:</chakra.p>
                <JsonView
                  maxH="20em"
                  overflow="auto"
                  value={{
                    users: result.users.successful,
                    surveys: result.surveys.successful,
                  }}
                />
                {anyFailures && (
                  <>
                    <chakra.p>The following transfers failed:</chakra.p>
                    <JsonView
                      maxH="20em"
                      overflow="auto"
                      value={
                        <JsonView
                          overflow="auto"
                          value={{
                            users: result.users.failed,
                            surveys: result.surveys.failed,
                          }}
                        />
                      }
                    />
                  </>
                )}
              </>
            ),
          });
        })
        .tap(result =>
          setValue({
            ...value,
            users: value.users.filter(
              id => !result.users.successful.includes(id)
            ),
          })
        )
        .tapError(errorAlert)
        .cleanup(() => setSaving(false))
        .unsafeRun();
    } else {
      alert({
        title: "Oops!",
        message: (
          <>
            <chakra.p>The request data wasn&apos;t complete:</chakra.p>
            <JsonView overflow="auto" value={value} />
          </>
        ),
      }).unsafeRun();
    }
  }, [alert, apiParams, errorAlert, queryClient, value]);

  return (
    <>
      <PageTopBar admin={true} basePage="transfer-user" />
      <AdminPageHeader title="User Transfer" width="default" />
      <PageContainer>
        <Spaced>
          <chakra.p fontSize="sm">
            Use this tool to bulk transfer users and (optionally) their surveys
            and attachments from one workspace to another. The users will lose
            their access to their original workspace and will be granted access
            to the new one. You can specify how to map project and team
            memberships below.
          </chakra.p>
          <HStack alignItems="start">
            <MessageFormControl
              label="Source Workspace"
              messages={errors.srcWorkspace}
            >
              <Select.Searchable
                options={workspaceOptions}
                value={value.srcWorkspace ?? null}
                onChange={srcWorkspace => setValue({ ...value, srcWorkspace })}
                minMatchCharLength={0}
                debounce={500}
              />
            </MessageFormControl>
            <MessageFormControl
              label="Destination Workspace"
              messages={errors.desWorkspace}
            >
              <Select.Searchable
                options={workspaceOptions}
                value={value.desWorkspace ?? null}
                onChange={desWorkspace => setValue({ ...value, desWorkspace })}
                minMatchCharLength={0}
                debounce={500}
              />
            </MessageFormControl>
          </HStack>
          <MessageFormControl label="Users" messages={errors.users}>
            {value.srcWorkspace != null ? (
              <UserList
                searchTerm={searchOpts.q}
                page={searchOpts.page}
                count={searchOpts.count}
                order={searchOpts.order}
                columns={userTransferColumns}
                workspace={value.srcWorkspace}
                roleSelect={
                  <Select.Nullable
                    value={searchOpts.role}
                    options={WorkspaceRoleNameEnum.entries}
                    onChange={role =>
                      setSearchOpts({ ...searchOpts, role, page: 0 })
                    }
                    placeholder="All Roles"
                    background="transparent"
                    w="fit-content"
                    maxW="52"
                  />
                }
                onSearchTermChange={q =>
                  setSearchOpts({ ...searchOpts, q: q ?? undefined, page: 0 })
                }
                onPageChange={page => setSearchOpts({ ...searchOpts, page })}
                onOrderChange={order => setSearchOpts({ ...searchOpts, order })}
                selection={selectedUsers}
                onSelectionChange={handleUserSelectionChange}
              />
            ) : (
              <Placeholder text="Please select a source workspace first." />
            )}
          </MessageFormControl>
          <FormControl>
            <FormLabel text="Membership Transfers" />
            {srcProjects != null &&
            srcProjects.length > 0 &&
            desProjectOptions != null ? (
              <TreeContainer>
                {srcProjects.map(project => (
                  <TreeNode
                    key={project.id}
                    indentLevel={0}
                    leftContent={<chakra.span>{project.name}</chakra.span>}
                    rightContent={
                      <Select.Nullable
                        placeholder="Remove access"
                        options={desProjectOptions}
                        value={projectMappingsLookup[project.id] ?? null}
                        onClick={evt => evt.stopPropagation()}
                        onChange={to =>
                          handleProjectMappingChange(project.id, to)
                        }
                        w="24ch"
                        minW="24ch"
                      />
                    }
                  >
                    {srcTeams[project.id]?.map(team => (
                      <TreeNode
                        key={team.id}
                        indentLevel={1}
                        leftContent={
                          <chakra.span
                            color={
                              projectMappingsLookup[project.id] == null
                                ? "gray.300"
                                : undefined
                            }
                          >
                            {team.name}
                          </chakra.span>
                        }
                        rightContent={
                          <Select.Nullable
                            placeholder="Remove access"
                            options={getDesTeamOptions(
                              projectMappingsLookup[project.id]
                            )}
                            value={teamMappingsLookup[team.id] ?? null}
                            onClick={evt => evt.stopPropagation()}
                            onChange={to =>
                              setValue({
                                ...value,
                                teamMappings: [
                                  ...value.teamMappings.filter(
                                    ([from]) => from !== team.id
                                  ),
                                  [team.id, to],
                                ],
                              })
                            }
                            disabled={projectMappingsLookup[project.id] == null}
                            w="24ch"
                            minW="24ch"
                          />
                        }
                      ></TreeNode>
                    ))}
                  </TreeNode>
                ))}
              </TreeContainer>
            ) : (
              <Placeholder
                text={
                  value.srcWorkspace == null || value.desWorkspace == null
                    ? "Please select a source and destination workspace first."
                    : "No projects found for selected users."
                }
              />
            )}
          </FormControl>
          <FormControl>
            <FormLabel text="Transfer Surveys" />
            <Checkbox
              value={value.transferSurveys}
              onChange={transferSurveys =>
                setValue({ ...value, transferSurveys })
              }
              checkboxLabel="Also transfer the selected users' surveys?"
            />
          </FormControl>
          <FormControl>
            <FormLabel text="API Request Body" />
            <JsonView value={value} copyToClipboard={true} maxH="50vh" />
          </FormControl>
          <Button
            label="Transfer!"
            colorScheme="blue"
            onClick={onSave}
            isLoading={saving}
            loadingText="Transferring"
            disabled={saving || messages.length > 0}
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
