import { checks } from "@cartographerio/permission";
import { AccessToken, AttachmentId } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { Box, Flex, Grid, Icon, Image, chakra } from "@chakra-ui/react";
import { Fragment, ReactElement } from "react";
import { AiOutlineFile } from "react-icons/ai";

import {
  galleryItemMetadata,
  galleryItemOriginalUrl,
} from "../../../galleryItem";
import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function EmbedAttachmentPage(
  props: RouteProps<typeof routes.embed.attachment.view>
): ReactElement {
  const {
    query: {
      authorization = raise<AccessToken>(
        new Error("You must specify an access token")
      ),
      attachment: attachmentId = raise<AttachmentId>(
        new Error("You must specify an attachment")
      ),
    },
  } = props;

  const apiParams = useApiParams();

  const galleryItem = useSuspenseQueryData(
    queries.attachment.v3.readGalleryItem(apiParams, attachmentId)
  );

  const survey = useSuspenseQueryData(
    queries.survey.v3.readOrFail(apiParams, galleryItem.attachment.surveyId)
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, survey.projectId)
  );

  useRequirePermission(checks.survey.view(project));

  return (
    <PageContainer width="narrow">
      <Spaced>
        <PageHeader
          title="Attachment"
          right={
            <ButtonLink.Internal
              variant="outline"
              to={routes.short.survey.url([survey.id])}
            >
              View Survey
            </ButtonLink.Internal>
          }
        />
        <Card
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Image
            src={galleryItem && galleryItemOriginalUrl(galleryItem)}
            fit="contain"
            mx="auto"
            fallback={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="100%"
                h="100%"
                color="gray.300"
                py="4"
              >
                <Icon as={AiOutlineFile} w="32" h="32" />
              </Box>
            }
          />
          <CardBody>
            <Spaced>
              <Grid mt="auto" templateColumns="16ex auto" gap="4" fontSize="xs">
                {galleryItemMetadata(galleryItem).map(([key, value], index) => (
                  <Fragment key={index}>
                    <Box>{key}</Box>
                    <chakra.pre fontFamily="body" overflowX="hidden">
                      {value}
                    </chakra.pre>
                  </Fragment>
                ))}
              </Grid>
              <Flex w="100%" justifyContent="space-between">
                <ButtonLink.External
                  variant="outline"
                  to={routes.embed.attachment.folder.url([], {
                    authorization,
                    survey: survey.id,
                    folder: galleryItem.attachment.folder,
                  })}
                >
                  View Gallery
                </ButtonLink.External>
                <ButtonLink.External
                  colorScheme="blue"
                  to={galleryItem.originalUrl}
                >
                  Download
                </ButtonLink.External>
              </Flex>
            </Spaced>
          </CardBody>
        </Card>
      </Spaced>
    </PageContainer>
  );
}
