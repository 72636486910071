import { MemberSortKey } from "@cartographerio/client";
import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import {
  MemberV2,
  ProjectV2,
  Qualification,
  TeamId,
  TeamV2,
} from "@cartographerio/types";

import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
  emailColumn,
  memberTypeColumn,
  nameColumn,
  projectRoleColumn,
  qualificationRoleColumn,
  selectedTeamColumns,
} from "../../../../components/MemberList/column";
import { Column } from "../../../../components/SearchResultsList/column";

interface ProjectMemberListColumnsProps {
  project: ProjectV2;
  teams?: TeamV2[] | null;
  selectedTeam?: TeamId | null;
  qualifications: Qualification[];
  permissionCheckPasses: PermissionCheckRunner;
  onRoleChange?: OnMemberRoleChange | null;
  onQualificationChange?: OnMemberQualificationChange | null;
}

export function projectMemberListColumns(
  props: ProjectMemberListColumnsProps
): Column<MemberV2, MemberSortKey>[] {
  const {
    project,
    teams,
    selectedTeam,
    qualifications,
    permissionCheckPasses,
    onRoleChange,
    onQualificationChange,
  } = props;

  return [
    memberTypeColumn,
    nameColumn,
    emailColumn,
    ...(teams != null
      ? selectedTeamColumns({
          teams,
          selectedTeam,
          onRoleChange,
          permissionCheckPasses,
        })
      : []),
    projectRoleColumn({
      project: project.id,
      onRoleChange: permissionCheckPasses(checks.project.grantAccess(project))
        ? onRoleChange
        : undefined,
    }),
    ...qualifications.map(qualification =>
      qualificationRoleColumn({
        qualification,
        onQualificationChange,
        permissionCheckPasses,
      })
    ),
  ];
}
