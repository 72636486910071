import { PermissionCheck } from "@cartographerio/types";
import { ReactElement, ReactNode, useMemo } from "react";

import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import Link, { ExternalLinkProps, InternalLinkProps } from "./Link";
import RequirePermissionPopover from "./RequirePermissionPopover";

interface RequirePermissionLinkProps {
  check: PermissionCheck;
  to: string;
  disableOnFail?: boolean;
  failMode?: "none" | "popover" | "hidden";
  popoverFailMessage?: ReactNode;
  children: ReactNode | ((passes: boolean) => ReactNode);
}

function ExternalRequirePermissionLink(
  props: RequirePermissionLinkProps & Omit<ExternalLinkProps, "children">
): ReactElement {
  const {
    check,
    to,
    disableOnFail,
    failMode = "none",
    popoverFailMessage,
    flexShrink = 0,
    flexGrow = 0,
    display = "block",
    alignSelf = "center",
    disabled,
    children,
    ...rest
  } = props;

  const passes = usePermissionCheckPasses(check);
  const rendered = useMemo(
    () => (typeof children === "function" ? children(passes) : children),
    [children, passes]
  );

  return failMode === "popover" ? (
    <RequirePermissionPopover check={check} failMessage={popoverFailMessage}>
      {passes => (
        <Link.External
          flexShrink={flexShrink}
          flexGrow={flexGrow}
          display={display}
          alignSelf={alignSelf}
          to={passes && !disabled ? to : undefined}
          disabled={(disableOnFail && !passes) || disabled}
          {...rest}
        >
          {rendered}
        </Link.External>
      )}
    </RequirePermissionPopover>
  ) : (
    <Link.External
      flexShrink={flexShrink}
      flexGrow={flexGrow}
      display={display}
      alignSelf={alignSelf}
      to={passes && !disabled ? to : undefined}
      disabled={(disableOnFail && !passes) || disabled}
      visibility={passes || failMode !== "hidden" ? undefined : "hidden"}
      {...rest}
    >
      {rendered}
    </Link.External>
  );
}

function InternalRequirePermissionLink(
  props: RequirePermissionLinkProps & Omit<InternalLinkProps, "children">
): ReactElement {
  const {
    check,
    to,
    disableOnFail,
    failMode = "none",
    popoverFailMessage,
    flexShrink = 0,
    flexGrow = 0,
    display = "block",
    alignSelf = "center",
    disabled,
    children,
    ...rest
  } = props;

  const passes = usePermissionCheckPasses(check);
  const rendered = useMemo(
    () => (typeof children === "function" ? children(passes) : children),
    [children, passes]
  );

  return failMode === "popover" ? (
    <RequirePermissionPopover check={check} failMessage={popoverFailMessage}>
      {passes => (
        <Link.Internal
          flexShrink={flexShrink}
          flexGrow={flexGrow}
          display={display}
          alignSelf={alignSelf}
          to={passes && !disabled ? to : undefined}
          disabled={(disableOnFail && !passes) || disabled}
          {...rest}
        >
          {rendered}
        </Link.Internal>
      )}
    </RequirePermissionPopover>
  ) : (
    <Link.Internal
      flexShrink={flexShrink}
      flexGrow={flexGrow}
      display={display}
      alignSelf={alignSelf}
      to={passes && !disabled ? to : undefined}
      disabled={(disableOnFail && !passes) || disabled}
      visibility={passes || failMode !== "hidden" ? undefined : "hidden"}
      {...rest}
    >
      {rendered}
    </Link.Internal>
  );
}

export default {
  External: ExternalRequirePermissionLink,
  Internal: InternalRequirePermissionLink,
};
