import { ProjectId, RcaWorkspaceStatus } from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import Container from "../../../components/Container";
import PageTopBar from "../../../components/PageTopBar";
import SearchField from "../../../components/SearchField";
import SearchResultsList from "../../../components/SearchResultsList/List";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { routes } from "../../../routes";
import AdminPageHeader from "../AdminPageHeader";
import { rcaWorkspaceStatusListColumns } from "./column";

export default function AdminRcaWorkspaceStatusListPage(
  props: RouteProps<typeof routes.admin.rcaWorkspaceStatusList>
): ReactElement {
  const { query, updateQuery } = props;

  const apiParams = useApiParams();

  const { q: searchTerm, page, count } = query;

  const { data } = useQuery(
    queries.rca.workspaceStatus.v1.search(apiParams, {
      q: searchTerm,
      skip: page * count,
      limit: count,
    })
  );

  return (
    <>
      <PageTopBar admin={true} basePage="rca-workspace-status" />
      <AdminPageHeader title="RCA Workspace Status" />
      <Container width="wide">
        <Spaced spacing="4">
          <HStack w="100%" justify="stretch">
            <SearchField
              defaultValue={searchTerm ?? ""}
              onChange={q =>
                updateQuery({ ...query, q: q ?? undefined, page: 0 })
              }
              w="auto"
              flexShrink={1}
              flexGrow={1}
            />
          </HStack>

          <SearchResultsList<RcaWorkspaceStatus, string, ProjectId>
            results={data}
            columns={rcaWorkspaceStatusListColumns}
            page={page}
            count={count}
            onPageChange={page => updateQuery({ ...query, page })}
            itemKey={({ projectId }) => projectId}
          />
        </Spaced>
      </Container>
    </>
  );
}
