import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationViewPage from "../base/BaseInvitationViewPage";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamInvitationViewPage(
  props: RouteProps<typeof routes.workspace.team.invitation.view>
): ReactElement {
  const {
    path: { workspaceRef, teamRef, invitationId },
  } = props;

  const apiParams = useApiParams();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const invitation = useSuspenseQueryData(
    queries.invitation.v3.readOrFail(apiParams, invitationId)
  );

  useRequirePermission(checks.team.grantAccess(team));

  usePageTitle(
    useMemo(() => {
      const name = [invitation.firstName, invitation.lastName]
        .filter(x => x)
        .join(" ");

      return [
        name === "" ? "Invitation" : `Inviting ${name}`,
        team.name,
        workspace.name,
      ].join(" - ");
    }, [invitation.firstName, invitation.lastName, team.name, workspace.name])
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="invitation-single"
        invitation={invitation}
      />
      <TeamPageHeader
        workspace={workspace}
        team={team}
        selected="invitation-single"
      />
      <BaseInvitationViewPage
        asTitle={false}
        backRedirect={routes.workspace.team.invitation.list.url([
          workspace.alias,
          team.alias,
        ])}
        defaultInvitation={invitation}
        defaultWorkspace={workspace.id}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.workspace.team.invitation.list.url([
          workspace.alias,
          team.alias,
        ])}
      />
    </>
  );
}
