import { ddmmyyyy, formatTimestamp } from "@cartographerio/types";
import { Box, HStack, StackProps, VStack } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { BsClock } from "react-icons/bs";

import { GalleryItem, isFullAttachmentItem } from "../../../galleryItem";
import { userIdToIcon } from "../UserRefField";

interface MetadataGridProps extends StackProps {
  item: GalleryItem;
}

export default function MetadataGrid(props: MetadataGridProps): ReactElement {
  const { item, ...rest } = props;

  const timestamp = useMemo(
    () =>
      isFullAttachmentItem(item)
        ? formatTimestamp(item.attachment.created, {
            format: ddmmyyyy,
          })
        : undefined,
    [item]
  );

  return (
    <VStack spacing="2" align="start" {...rest}>
      {item.attachment.author?.screenName != null && (
        <HStack w="100%" justify="space-between" align="center">
          <HStack gap="1">
            <Icon as={userIdToIcon(item.attachment.author.userId)} />
            <span>{item.attachment.author.screenName}</span>
          </HStack>
          {timestamp != null && (
            <HStack gap="1">
              <Icon as={BsClock} />
              <span>{timestamp}</span>
            </HStack>
          )}
        </HStack>
      )}
      <Box
        maxH="100%"
        maxW="100%"
        overflow="hidden"
        noOfLines={3}
        wordBreak="break-word"
      >
        {item.attachment.description}
      </Box>
    </VStack>
  );
}
