import { Attribute, AttributeGroup } from "@cartographerio/atlas-map";
import {
  ProjectV2,
  TeamV2,
  WorkspaceV2,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";

import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";

export interface ProjectTeamContext {
  project: ProjectV2;
  graph: WorkspaceGraphV2;
}

export const ProjectTeamContext =
  createContext<ProjectTeamContext | undefined>(undefined);

export function useProjectTeamContext(): ProjectTeamContext {
  return (
    useContext(ProjectTeamContext) ??
    raise(internalError("ProjectTeamContext not available"))
  );
}

export function useProjectTeamContextGraph(): WorkspaceGraphV2 {
  return (
    useProjectTeamContext().graph ??
    raise(internalError("ProjectTeamContextGraph not available"))
  );
}

export function useSupportedAttributes(
  items: (Attribute | AttributeGroup)[]
): Attribute[] {
  const { project } = useProjectTeamContext();

  return useMemo(() => {
    const showTeams = project.teamIds.length > 0;

    return items.flatMap(item => {
      switch (item.type) {
        case "AttributeGroup":
          return showTeams
            ? item.attributes
            : item.attributes.filter(attr => attr.type !== "TeamAttribute");
        case "TeamAttribute":
          return showTeams ? [item] : [];
        default:
          return [item];
      }
    });
  }, [items, project.teamIds.length]);
}

interface ProjectTeamContextProviderProps {
  workspace: WorkspaceV2;
  project: ProjectV2;
  projects: ProjectV2[];
  teams: TeamV2[];
  children: ReactNode;
}

export function ProjectTeamContextProvider(
  props: ProjectTeamContextProviderProps
): ReactElement {
  const { workspace, project, projects, teams, children } = props;

  const graph = useWorkspaceGraph(workspace, projects, teams ?? []);

  const context = useMemo(() => ({ project, graph }), [project, graph]);

  return (
    <ProjectTeamContext.Provider value={context}>
      {children}
    </ProjectTeamContext.Provider>
  );
}
