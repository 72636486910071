import { PageState } from "@cartographerio/atlas-form";
import { Center, Flex } from "@chakra-ui/react";
import { ReactElement } from "react";

import MessageBadge from "../../components/MessageBadge";

interface PageLabelProps {
  pageState: PageState;
}

export default function PageLabel(props: PageLabelProps): ReactElement {
  const { errorCount, page, infoCount } = props.pageState;

  return (
    <Flex gap={2} justifyContent="space-between" width="100%">
      <Center whiteSpace="nowrap">{page.title}</Center>
      <MessageBadge errorCount={errorCount} infoCount={infoCount} />
    </Flex>
  );
}
