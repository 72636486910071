import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function useRedirectWhen(
  test: boolean,
  getUrl: () => string
): void {
  const navigate = useNavigate();

  useEffect(() => {
    if (test) {
      navigate(getUrl(), { replace: true });
    }
  }, [getUrl, navigate, test]);
}
