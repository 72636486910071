import {
  Attribute,
  MapLayer,
  findLayerAttribute,
} from "@cartographerio/atlas-map";
import { Select } from "@chakra-ui/react";
import { ChangeEvent, ReactElement, useCallback } from "react";

export interface AttributeSelectProps {
  layer: MapLayer;
  options: Attribute[];
  value: Attribute;
  onChange: (attr: Attribute) => void;
}

export default function AttributeSelect(
  props: AttributeSelectProps
): ReactElement | null {
  const { layer, options, value, onChange } = props;

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      const attr = findLayerAttribute(layer, evt.target.value);
      if (attr != null) {
        onChange(attr);
      }
    },
    [layer, onChange]
  );

  return layer == null ? null : (
    <Select
      size="sm"
      fontSize="xs"
      value={value?.attributeId}
      onChange={handleChange}
    >
      {options.map(attr => (
        <option key={attr.attributeId} value={attr.attributeId}>
          {attr.label}
        </option>
      ))}
    </Select>
  );
}
