import { Box } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface InspectorHelpProps {
  children?: ReactNode;
}

export default function InspectorHelp(props: InspectorHelpProps): ReactElement {
  const { children } = props;

  return (
    <Box w="100%" fontSize="xs" py="1" px="2">
      {children}
    </Box>
  );
}
