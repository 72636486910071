import { errorMessage } from "@cartographerio/atlas-core";
import { WorkspaceV2 as Workspace } from "@cartographerio/types";

import { aliasRule } from "./helpers";
import { rule } from "./rule";

export const { rule: workspaceRule, keys: workspaceErrorKeys } = rule
  .build<Workspace>()
  .field(
    "name",
    rule.nonEmpty([errorMessage("You must specify a workspace name")])
  )
  .field("alias", rule.nonEmpty([errorMessage("You must specify an alias")]))
  .field("alias", rule.nullable(aliasRule))
  .optionalField(
    "homepage",
    rule.nullable(
      rule.url([
        errorMessage("The homepage must be a fully qualified web address"),
      ])
    )
  )
  .optionalField(
    "logo",
    rule.nullable(
      rule.url([errorMessage("The logo must be a fully qualified web address")])
    )
  )
  .optionalField(
    "privacyPolicy",
    rule.nullable(
      rule.url([
        errorMessage(
          "The privacy policy must be a fully qualified web address"
        ),
      ])
    )
  )
  .finish();
