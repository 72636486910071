import { SelectOption } from "@cartographerio/atlas-form";
import { check } from "@cartographerio/permission";
import { WorkspaceAlias } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import BaseInvitationCodeListPage from "../base/BaseInvitationCodeListPage";
import AdminPageHeader from "./AdminPageHeader";

export default function AdminInvitationCodeListPage(
  props: RouteProps<typeof routes.admin.invitation.code.list>
): ReactElement {
  const { query, updateQuery } = props;

  const apiParams = useApiParams();

  useRequirePermissionRedirect(check.superuser, () =>
    routes.admin.home.url([])
  );

  usePageTitle("Invitation Codes - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const selectedWorkspace = useMemo(
    () => workspaces.find(({ alias }) => query.workspace === alias),
    [query.workspace, workspaces]
  );

  const workspaceOptions = useMemo<SelectOption<WorkspaceAlias>[]>(
    () => workspaces.map(({ name, alias }) => ({ label: name, value: alias })),
    [workspaces]
  );

  return (
    <>
      <PageTopBar admin={true} basePage="invitation-code" />
      <AdminPageHeader title="Invitation Codes" />
      <BaseInvitationCodeListPage
        route={routes.admin.invitation.code.list}
        query={query}
        updateQuery={updateQuery}
        workspace={selectedWorkspace}
        workspaceOptions={workspaceOptions}
        onWorkspaceChange={workspace =>
          updateQuery({ ...query, workspace: workspace ?? undefined })
        }
        addButton={
          <ButtonLink.Internal
            colorScheme="blue"
            to={routes.admin.invitation.code.create.url([])}
          >
            Add
          </ButtonLink.Internal>
        }
        itemLink={invitation =>
          routes.admin.invitation.code.view.url([invitation.alias])
        }
      />
    </>
  );
}
