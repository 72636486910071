import { SelectOption } from "@cartographerio/atlas-form";
import { checks } from "@cartographerio/permission";
import {
  MapLayerId,
  Message,
  isArcgisIntegrationCreate,
  unsafeArcgisLayerUrl,
} from "@cartographerio/types";
import { HStack, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import {
  PartialArcgisIntegrationCreate,
  arcgisCreateKeys,
  arcgisCreateRule,
} from "../../../../schema/arcgis";
import { splitMessages } from "../../../../schema/rule/errors";
import { useIOErrorAlert } from "../../../components/Alert";
import BackButtonHeading from "../../../components/BackButtonHeading";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import FormLabel from "../../../components/FormLabel";
import MessageFormControl from "../../../components/MessageFormControl";
import MessageList from "../../../components/MessageList";
import NamedEmailField from "../../../components/NamedEmailField";
import PageTopBar from "../../../components/PageTopBar";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import TextField from "../../../components/TextField";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useVisibleMaps } from "../../../hooks/useVisibleMaps";
import { routes } from "../../../routes";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ArcgisIntegrationCreatePage(
  props: RouteProps<typeof routes.workspace.project.integrations.arcgis.create>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
  } = props;

  const queryClient = useQueryClient();
  const apiParams = useApiParams();
  const navigate = useNavigate();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  useRequirePermissionRedirect(checks.project.viewSettings(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  usePageTitle(`New ArcGIS Integration - ${project.name} - ${workspace.name}`);

  const [value, setValue] = useState<PartialArcgisIntegrationCreate>({
    name: "",
    projectId: project.id,
    layerId: null,
    arcgisLayerUrl: null,
    exportSimplifiedGeometry: false,
    enableScheduledExports: false,
    notificationEmail: null,
  });

  const [messages, setMessages] = useState<Message[]>(() =>
    arcgisCreateRule(value)
  );

  const errors = useMemo(
    () => splitMessages(messages, arcgisCreateKeys),
    [messages]
  );

  const maps = useVisibleMaps(project);
  const layerOptions = useMemo<SelectOption<MapLayerId>[]>(
    () =>
      maps.flatMap(schema =>
        schema.layers
          .filter(({ source }) => source.type === "CartographerSource")
          .map(layer => ({
            label:
              schema.title === layer.title
                ? layer.title
                : `${schema.title} - ${layer.title}`,
            value: layer.layerId,
          }))
      ),
    [maps]
  );

  const handleChange = useCallback((create: PartialArcgisIntegrationCreate) => {
    setValue(create);
    setMessages(arcgisCreateRule(create));
  }, []);

  const handleSave = useCallback(() => {
    if (isArcgisIntegrationCreate(value)) {
      queries.integration.arcgis.v1
        .create(queryClient, apiParams, value)
        .tap(() =>
          toast({ status: "success", description: "Integration created!" })
        )
        .tap(result =>
          result.fold(setMessages, integration =>
            navigate(
              routes.workspace.project.integrations.arcgis.update.url([
                workspace.alias,
                project.alias,
                integration.id,
              ])
            )
          )
        )
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    apiParams,
    errorAlert,
    navigate,
    project.alias,
    queryClient,
    toast,
    value,
    workspace.alias,
  ]);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="integrations"
        integration="arcgis"
        arcgisIntegration="new"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="integrations"
      />
      <Container width="wide">
        <Spaced spacing="4">
          <BackButtonHeading
            to={routes.workspace.project.integrations.arcgis.list.url([
              workspace.alias,
              project.alias,
            ])}
          >
            New
          </BackButtonHeading>
          <MessageFormControl label="Name" messages={errors.name}>
            <TextField.String
              value={value.name}
              onChange={name => handleChange({ ...value, name })}
            />
          </MessageFormControl>
          <HStack alignItems="start">
            <MessageFormControl
              label="Source Layer on Cartographer"
              messages={errors.layerId}
            >
              <Select.Nullable
                value={value.layerId}
                options={layerOptions}
                onChange={layerId => handleChange({ ...value, layerId })}
              />
            </MessageFormControl>
            <MessageFormControl
              label="Target Layer on ArcGIS"
              messages={errors.arcgisLayerUrl}
            >
              <TextField.NullableString
                value={value.arcgisLayerUrl}
                onChange={arcgisLayerUrl =>
                  handleChange({
                    ...value,
                    arcgisLayerUrl:
                      arcgisLayerUrl != null
                        ? unsafeArcgisLayerUrl(arcgisLayerUrl)
                        : null,
                  })
                }
              />
            </MessageFormControl>
          </HStack>
          <Spaced spacing="2">
            <FormLabel text="Export Options" />
            <Checkbox
              checkboxLabel="Export simplified (point) geometry only"
              value={value.exportSimplifiedGeometry ?? false}
              onChange={exportSimplifiedGeometry =>
                handleChange({ ...value, exportSimplifiedGeometry })
              }
            />
            <Checkbox
              checkboxLabel="Automatically schedule exports at 3am every morning"
              value={value.enableScheduledExports}
              onChange={enableScheduledExports =>
                handleChange({
                  ...value,
                  notificationEmail: enableScheduledExports
                    ? value.notificationEmail
                    : null,
                  enableScheduledExports,
                })
              }
            />
          </Spaced>
          <MessageFormControl
            label="Notification Email"
            messages={errors.notificationEmail}
          >
            <NamedEmailField
              value={value.notificationEmail ?? undefined}
              onChange={notificationEmail =>
                handleChange({ ...value, notificationEmail })
              }
            />
          </MessageFormControl>
          <MessageList messages={errors._rest_} />
          <Button
            colorScheme="blue"
            label="Save"
            onClick={handleSave}
            disabled={messages.length > 0}
          />
        </Spaced>
      </Container>
    </>
  );
}
