import { Box, HStack, IconButton } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { IoChevronBack } from "react-icons/io5";

import Heading, { HeadingProps } from "./Heading";
import Link from "./Link";

export interface BackButtonHeadingProps
  extends Omit<HeadingProps, "level" | "right"> {
  to: string;
  children: ReactNode;
}

export default function BackButtonHeading(
  props: BackButtonHeadingProps
): ReactElement {
  const { to, children, ...rest } = props;

  return (
    <Heading level="subsection" {...rest}>
      <HStack>
        <IconButton
          as={Link.Internal}
          to={to}
          icon={<IoChevronBack size="1.25rem" />}
          aria-label="Back"
          variant="ghost"
          size="sm"
        />
        <Box>{children}</Box>
      </HStack>
    </Heading>
  );
}
