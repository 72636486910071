import { Option } from "@cartographerio/fp";
import { ReactElement } from "react";

import { NullableStringTextFieldProps } from "../TextField";
import DefaultAutocomplete from "./Default";

export interface NullableStringAutocompleteProps
  extends NullableStringTextFieldProps {
  options: string[];
}

function format(str: string | null): string {
  return str == null ? "" : str;
}

function validate(str: string): Option<string | null> {
  return Option.some(str.trim()).map(str => (str.length > 0 ? str : null));
}

export default function NullableStringAutocomplete(
  props: NullableStringAutocompleteProps
): ReactElement {
  return <DefaultAutocomplete {...props} format={format} validate={validate} />;
}
