import { MapSchema } from "@cartographerio/atlas-map";
import { SurveyModule } from "@cartographerio/inventory-surveys";
import { ProjectV2 } from "@cartographerio/types";

export function projectSurveyModuleLabel(
  project: ProjectV2,
  module: SurveyModule
): string {
  return project.moduleIds.length === 1
    ? project.name.indexOf("Sites") > 0
      ? "Sites"
      : "Surveys"
    : `${module.names.shortName} Surveys`;
}

export function projectMapLabel(project: ProjectV2, map: MapSchema): string {
  return project.mapIds.length === 1 ? "Map" : `${map.title} Map`;
}
