import { Box, StyleProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import Container, { ContainerWidth } from "./Container";
import PageFooter from "./PageFooter";

export interface PageContainerProps {
  sticky?: boolean;
  top?: StyleProps["top"];
  width?: ContainerWidth;
  footer?: boolean;
  children: ReactNode;
}

export default function PageContainer(props: PageContainerProps): ReactElement {
  const { sticky, top, width, footer = false, children } = props;

  return (
    <Container sticky={sticky} top={top} width={width}>
      <Box minH="50vh">{children}</Box>

      {footer && <PageFooter />}
    </Container>
  );
}
