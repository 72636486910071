import { chakra } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface SidebarMenuProps {
  children: ReactNode;
}

export function SidebarMenu(props: SidebarMenuProps): ReactElement {
  const { children } = props;

  return (
    <chakra.ul listStyleType="none" w="100%">
      {children}
    </chakra.ul>
  );
}
