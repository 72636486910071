import { useEffect } from "react";

import useRecord, { UseRecordResult } from "./useRecord";

/**
 * Like useState but resets the value when dependencies change.
 * Should be used with useCallback as follows:
 *
 * ```
 * const [x, y] = useVolatileState(useCallback(
 *   () => initialValue,
 *   [dependency, list]
 * ))
 * ```
 */
export function useVolatileRecord<K extends PropertyKey, V>(
  func: () => Record<K, V>
): UseRecordResult<K, V> {
  const [value, setter, allSetter] = useRecord(func);

  useEffect(() => allSetter(func), [allSetter, func]);

  return [value, setter, allSetter];
}
