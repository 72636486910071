import { checks } from "@cartographerio/permission";
import { useDisclosure } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import Button from "../../components/Button";
import InvitationCodeModal from "../../components/InvitationCodeModal";
import PageTopBar from "../../components/PageTopBar";
import WithPermission from "../../components/WithPermission";
import { useApiParams } from "../../contexts/auth";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { routes } from "../../routes";
import BaseInvitationCodeListPage from "../base/BaseInvitationCodeListPage";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamInvitationCodeListPage(
  props: RouteProps<typeof routes.workspace.team.invitation.code.list>
): ReactElement {
  const {
    path: { workspaceRef, teamRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const teamProjects = useSuspenseSearchResults(
    queries.project.v2.search(apiParams, {
      workspace: team.workspaceId,
      team: team.id,
    })
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  useRedirectWhen(!useCodesEnabled(workspace), () =>
    routes.workspace.team.settings.url([workspace.alias, team.alias])
  );

  useRequirePermissionRedirect(checks.team.viewInvitations(team), () =>
    routes.workspace.home.url([workspace.alias])
  );

  usePageTitle(`Invitation Codes - ${team.name}  - ${workspace.name}`);

  const {
    isOpen: isAddModalOpen,
    onOpen: onAddModalOpen,
    onClose: onAddModalClose,
  } = useDisclosure();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="invitation-code"
      />

      <TeamPageHeader
        workspace={workspace}
        team={team}
        selected="invitation-code"
      />
      <WithPermission check={checks.team.grantAccess(team)}>
        {canCreate => (
          <BaseInvitationCodeListPage
            route={routes.workspace.team.invitation.code.list}
            query={query}
            updateQuery={updateQuery}
            team={team}
            addButton={
              canCreate && (
                <Button
                  colorScheme="blue"
                  label="Add"
                  onClick={onAddModalOpen}
                />
              )
            }
            itemLink={invitation =>
              routes.workspace.team.invitation.code.view.url([
                workspace.alias,
                team.alias,
                invitation.alias,
              ])
            }
          />
        )}
      </WithPermission>

      <InvitationCodeModal.Team
        title="Create Invitation Code"
        isOpen={isAddModalOpen}
        onClose={onAddModalClose}
        team={team}
        teamProjects={teamProjects}
      />
    </>
  );
}
