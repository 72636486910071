import { envvar } from "@cartographerio/util";

const nodeEnv = envvar("NODE_ENV");
export const isDevelopmentEnv = nodeEnv === "development";
export const isProductionEnv = nodeEnv === "production";
export const isTestEnv = nodeEnv === "test";

export const fathomSiteId: string = envvar("REACT_APP_FATHOM_SITE_ID");

export const gpsPrecision: number = 6;
export const ngrPrecision: number = 5;
