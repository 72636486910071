import { Box, Flex, HStack, StyleProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface InspectorSectionProps
  extends Omit<StyleProps, "left" | "right"> {
  left?: ReactNode;
  right?: ReactNode;
  title: ReactNode;
  children: ReactNode;
}

export default function InspectorSection(
  props: InspectorSectionProps
): ReactElement {
  const { left, right, title, children, ...rest } = props;

  return (
    <Box {...rest}>
      <HStack mb="2" px="2" spacing="2" fontSize="sm" fontWeight="bold">
        {left && <Flex grow={0}>{left}</Flex>}
        <Flex grow={1}>{title}</Flex>
        {right && <Flex grow={0}>{right}</Flex>}
      </HStack>

      {children}
    </Box>
  );
}
