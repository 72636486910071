import {
  AttachmentFolder,
  AttachmentId,
  AttachmentUpdate,
  SurveyId,
  formatTimestamp,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { ReactElement, ReactNode } from "react";

import Link from "../ui/components/Link";
import { GalleryItem } from "./type";

export interface AttachmentUpdateEntry<K extends keyof AttachmentUpdate> {
  key: K;
  value: AttachmentUpdate[K];
}

export type GalleryItemMetadata<
  K extends keyof AttachmentUpdate | null = keyof AttachmentUpdate | null
> = [
  string,
  ReactNode,
  K extends keyof AttachmentUpdate ? AttachmentUpdateEntry<K> : null
  // Partial<AttachmentUpdate> | null
];

export function galleryItemMetadata(
  item: GalleryItem,
  showPermalinks?: boolean,
  attachmentUrl?: (id: AttachmentId) => string,
  folderUrl?: (surveyId: SurveyId, folder: AttachmentFolder) => string
): GalleryItemMetadata[] {
  function meta(label: string, value: ReactNode): GalleryItemMetadata<null>;
  function meta<K extends keyof AttachmentUpdate>(
    label: string,
    value: ReactNode,
    editData: AttachmentUpdateEntry<K>
  ): GalleryItemMetadata<K>;
  function meta<K extends keyof AttachmentUpdate | null>(
    label: string,
    value: ReactNode,
    editData?: K extends keyof AttachmentUpdate
      ? AttachmentUpdateEntry<K>
      : undefined
  ): GalleryItemMetadata<K> {
    return [label, value, editData ?? null] as GalleryItemMetadata<K>;
  }

  switch (item.type) {
    case "InProgressItem":
      return [
        meta("File Name", item.file.name),
        meta("File Type", item.file.type),
        meta("Status", "Upload in progress"),
      ];

    case "UploadSuccessItem":
      return [
        meta("File Name", item.file.name),
        meta("File Type", item.file.type),
        meta("Status", "Upload successful"),
      ];

    case "UploadFailureItem":
      return [
        meta("File Name", item.file.name),
        meta("File Type", item.file.type),
        meta("Status", "Upload failed"),
        meta("Error", JSON.stringify(item.error, null, 2)),
      ];

    case "AttachmentItem":
      return [
        meta("File Name", item.attachment.filename, {
          key: "title",
          value: item.attachment.filename,
        }),
        meta("File Type", item.attachment.contentType),
        meta(
          "Author",
          item.attachment.author.screenName ??
            item.attachment.author.userId ??
            "Unknown",
          { key: "author", value: item.attachment.author }
        ),
        meta("Uploaded", formatTimestamp(item.attachment.created)),
        ...(showPermalinks && folderUrl != null
          ? [
              meta(
                "Folder Link",
                // eslint-disable-next-line react/jsx-key
                <MetadataLink
                  to={folderUrl(
                    item.attachment.surveyId,
                    item.attachment.folder
                  )}
                />
              ),
            ]
          : []),
        ...(showPermalinks && attachmentUrl != null
          ? [
              meta(
                "Attachment Link",
                // eslint-disable-next-line react/jsx-key
                <MetadataLink to={attachmentUrl(item.attachment.id)} />
              ),
            ]
          : []),
      ];

    default:
      return checkExhausted(item);
  }
}

function MetadataLink(props: { to: string }): ReactElement {
  const { to } = props;

  return <Link.External to={to}>{to}</Link.External>;
}
