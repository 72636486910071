import { PathLike, errorMessage, prefixPath } from "@cartographerio/atlas-core";
import { Optional } from "@cartographerio/guard";
import {
  Email,
  Message,
  Timestamp,
  parseAnyTimestamp,
  timestampEpoch,
} from "@cartographerio/types";
import { Enum } from "@cartographerio/util";
import { flatten, indexOf, isEqual } from "lodash";

export type Rule<A> = (a: A) => Message[];

interface DotLengthable {
  length: number;
}

export function pass<A>(): Rule<A> {
  return (_actual: A) => [];
}

export function fail<A>(messages: Message[]): Rule<A> {
  return (_actual: A) => messages;
}

export function test<A>(func: (a: A) => boolean, messages: Message[]): Rule<A> {
  return (actual: A) => (func(actual) ? [] : messages);
}

export function required<A>(
  messages: Message[],
  rule?: Rule<A>
): Rule<Optional<A>> {
  return (actual: Optional<A>) =>
    actual == null ? messages : rule?.(actual) ?? [];
}

export function nullable<A>(rule: Rule<A>): Rule<Optional<A>> {
  return (actual: Optional<A>) => (actual == null ? [] : rule(actual));
}

export function truthy<A>(rule: Rule<A>): Rule<Optional<A>> {
  return (actual: Optional<A>) => (actual ? rule(actual) : []);
}

export function nonEmpty<T extends DotLengthable>(
  messages: Message[]
): Rule<T> {
  return (actual: T) => (actual.length > 0 ? [] : messages);
}

export function nonNegative(messages: Message[]): Rule<number> {
  return (actual: number) => (actual >= 0 ? [] : messages);
}

export function oneOf<A>(expected: A[], messages: Message[]): Rule<A> {
  return (actual: A) => (indexOf(expected, actual) >= 0 ? [] : messages);
}

export function notOneOf<A>(expected: A[], messages: Message[]): Rule<A> {
  return (actual: A) => (indexOf(expected, actual) < 0 ? [] : messages);
}

export function eq<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (actual === expected ? [] : messages);
}

export function ne<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (actual !== expected ? [] : messages);
}

export function deepEqual<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (isEqual(actual, expected) ? [] : messages);
}

export function notDeepEqual<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (!isEqual(actual, expected) ? [] : messages);
}

export function gt(
  expected: number,
  messages: Message[] = [
    errorMessage(`Value must be greater than ${expected}.`),
  ]
): Rule<number> {
  return (actual: number) => (actual > expected ? [] : messages);
}

export function gte(
  expected: number,
  messages: Message[] = [errorMessage(`Value must be ${expected} or greater.`)]
): Rule<number> {
  return (actual: number) => (actual >= expected ? [] : messages);
}

export function lt(
  expected: number,
  messages: Message[] = [errorMessage(`Value must be less than ${expected}.`)]
): Rule<number> {
  return (actual: number) => (actual < expected ? [] : messages);
}

export function lte(
  expected: number,
  messages: Message[] = [errorMessage(`Value must be ${expected} or less.`)]
): Rule<number> {
  return (actual: number) => (actual <= expected ? [] : messages);
}

export function inclusiveRange(
  min: number,
  max: number,
  messages: Message[]
): Rule<number> {
  return (actual: number) => (actual >= min && actual <= max ? [] : messages);
}

export function enumValue<A extends string>(
  enumeration: Enum<A>,
  messages: Message[]
): Rule<string> {
  return (actual: string) => (enumeration.isValue(actual) ? [] : messages);
}

export function regex(regex: RegExp, messages: Message[]): Rule<string> {
  return (actual: string) => (regex.test(actual) ? [] : messages);
}

export function email(messages: Message[]): Rule<Email> {
  return regex(/^[^@]+@[^@]+$/i, messages);
}

export function password(messages: Message[]): Rule<string> {
  return (actual: string) =>
    [/.{6,}/g, /[a-z]/g, /[A-Z]/g, /[0-9]/g, /[^a-z0-9]/gi].every(regex =>
      regex.test(actual)
    )
      ? []
      : messages;
}

export function lengthLte<T extends DotLengthable>(
  maxLength: number,
  messages: Message[]
): Rule<T> {
  return (actual: T) => (actual.length <= maxLength ? [] : messages);
}

export function lengthGte<T extends DotLengthable>(
  minLength: number,
  messages: Message[]
): Rule<T> {
  return (actual: T) => (actual.length >= minLength ? [] : messages);
}

export function timestampAfter(
  expected: Timestamp,
  messages: Message[]
): Rule<Timestamp> {
  return (actual: Timestamp) =>
    timestampEpoch(actual) >= timestampEpoch(expected) ? [] : messages;
}

export function prefix<A>(rule: Rule<A>, prefix: PathLike): Rule<A> {
  return (actual: A) =>
    rule(actual).map(msg => {
      const path = prefixPath(prefix, msg.path);
      switch (msg.level) {
        case "error":
          return { level: "error", text: msg.text, path };
        case "info":
          return { level: "info", text: msg.text, path };
        default:
          return { level: "error", text: msg.text, path };
      }
    });
}

export function array<A>(rule: Rule<A>): Rule<A[]> {
  return (actual: A[]) =>
    flatten(actual.map((item, index) => prefix(rule, index)(item)));
}

export function and<A>(...rules: Rule<A>[]): Rule<A> {
  return (actual: A) => flatten(rules.map(rule => rule(actual)));
}

export function or<A>(...rules: Rule<A>[]): Rule<A> {
  return (actual: A) =>
    rules.reduce<Message[]>((msgs, rule) => {
      return msgs.length > 0 ? msgs : rule(actual);
    }, []);
}

export function url(messages: Message[]): Rule<string> {
  return regex(/^https?:\/\//i, messages);
}

export function domain(messages: Message[]): Rule<string> {
  return regex(/^[^@]*[^.@]\.[^.@][^@]*$/i, messages);
}

export function formattedTimestamp(messages: Message[]): Rule<string> {
  return (actual: string) =>
    parseAnyTimestamp(actual) == null ? messages : [];
}
