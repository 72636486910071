import { MenuItem, MenuItemProps } from "@chakra-ui/react";
import { ReactElement } from "react";

import Link from "./Link";

interface MenuItemLinkProps extends MenuItemProps {
  to?: string;
}

function ExternalMenuItemLink(props: MenuItemLinkProps): ReactElement {
  return (
    <MenuItem
      as={Link.External}
      color="inherit"
      _hover={{ textDecoration: "inherit", background: "gray.200" }}
      _focus={{ textDecoration: "inherit" }}
      _active={{ textDecoration: "inherit" }}
      {...props}
    />
  );
}

function InternalMenuItemLink(props: MenuItemLinkProps): ReactElement {
  return (
    <MenuItem
      as={Link.Internal}
      color="inherit"
      _hover={{ textDecoration: "inherit", background: "gray.200" }}
      _focus={{ textDecoration: "inherit" }}
      _active={{ textDecoration: "inherit" }}
      {...props}
    />
  );
}

export default {
  External: ExternalMenuItemLink,
  Internal: InternalMenuItemLink,
};
