import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  ProjectTemplate,
  SearchResults,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type TemplateKey = ["project"] | ["project", "template", "v1", "search"];

export function search(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<ProjectTemplate>, TemplateKey> {
  return {
    queryKey: ["project", "template", "v1", "search"],
    queryFn: () => endpoints.project.template.v1.search(apiParams).unsafeRun(),
  };
}
