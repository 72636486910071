import {
  Bucket,
  FeatureAttribute,
  MarkerStyle,
  PropertyAttribute,
  PropertyFormatter,
  PropertyValue,
  lookupBucket,
  marker,
  rangeBucket,
} from "@cartographerio/atlas-map";
import { Feature, uniqueFeatureProperties } from "@cartographerio/geometry";
import { TeamV2 } from "@cartographerio/types";
import { checkExhausted, raise } from "@cartographerio/util";
import lodash, { chain } from "lodash";

// This taken from https://stackoverflow.com/questions/1168260/algorithm-for-generating-unique-colors
const COLOR_SELECTION_PHI = (1 + Math.sqrt(5)) / 2;

export function featureAttributeBuckets(marker: MarkerStyle, label: string) {
  return [rangeBucket(null, null, marker, label, false)];
}

function yearToEpoch(year: number): number {
  return new Date(year, 0, 1, 0, 0, 0, 0).getTime();
}

function epochToYear(epoch: number): number {
  return new Date(epoch).getFullYear();
}

function yearAndMonthToEpoch(year: number, monthIndex: number): number {
  return new Date(year, monthIndex, 1, 0, 0, 0, 0).getTime();
}

function monthLabel(year: number, monthIndex: number): string {
  switch (monthIndex) {
    case 0:
      return `Jan ${year}`;
    case 1:
      return `Feb ${year}`;
    case 2:
      return `Mar ${year}`;
    case 3:
      return `Apr ${year}`;
    case 4:
      return `May ${year}`;
    case 5:
      return `Jun ${year}`;
    case 6:
      return `Jul ${year}`;
    case 7:
      return `Aug ${year}`;
    case 8:
      return `Sep ${year}`;
    case 9:
      return `Oct ${year}`;
    case 10:
      return `Nov ${year}`;
    case 11:
      return `Dec ${year}`;
    default:
      return raise(new Error(`Bad month index: ${monthIndex}`));
  }
}

function autoStringBuckets(
  visibleFeatures: Feature[],
  propertyName: string,
  format: PropertyFormatter,
  ignoreNulls?: boolean
): Bucket[] {
  const uniqueValues = uniqueFeatureProperties(
    visibleFeatures,
    propertyName
  ) as (string | null)[];

  if (uniqueValues.length === 0) {
    return featureAttributeBuckets(marker.standard, "All Values");
  } else {
    const hasNulls = uniqueValues.includes(null);

    const nonNullBuckets = uniqueValues
      .filter(value => value != null)
      .map((value, index) =>
        lookupBucket(
          value,
          value == null ? marker.empty : marker.lookup(index),
          format(value) ?? "???"
        )
      );

    if (hasNulls && !ignoreNulls) {
      return [...nonNullBuckets, lookupBucket(null, marker.empty)];
    } else {
      return nonNullBuckets;
    }
  }
}

function autoNumberBuckets(
  visibleFeatures: Feature[],
  propertyName: string,
  format: PropertyFormatter,
  ignoreNulls?: boolean,
  numBuckets?: number
): Bucket[] {
  const uniqueValues = uniqueFeatureProperties(
    visibleFeatures,
    propertyName
  ) as PropertyValue[];

  if (uniqueValues.length === 0) {
    return featureAttributeBuckets(marker.standard, "All Values");
  } else {
    let hasNulls = false;
    let minValue = Number.POSITIVE_INFINITY;
    let maxValue = Number.NEGATIVE_INFINITY;

    uniqueValues.forEach(value => {
      if (typeof value === "number") {
        minValue = Math.min(minValue, value);
        maxValue = Math.max(maxValue, value);
      } else {
        hasNulls = true;
      }
    });

    if (
      Number.isFinite(minValue) &&
      Number.isFinite(maxValue) &&
      maxValue > minValue
    ) {
      const numSteps = numBuckets ?? Math.min(uniqueValues.length, 10);
      const stepInc = (maxValue - minValue) / numSteps;

      const numericBuckets = lodash.range(0, numSteps).map(index => {
        // The last bucket is inclusive:
        const maxInclusive = index === numSteps - 1;

        return rangeBucket(
          minValue + (index + 0) * stepInc,
          minValue + (index + 1) * stepInc,
          marker.numeric(index / numSteps),
          undefined,
          maxInclusive
        );
      });

      if (hasNulls && !ignoreNulls) {
        return [...numericBuckets, lookupBucket(null, marker.empty)];
      } else {
        return numericBuckets;
      }
    } else {
      return featureAttributeBuckets(marker.standard, "No Visible Data");
    }
  }
}

function autoBooleanBuckets(
  visibleFeatures: Feature[],
  propertyName: string,
  format: PropertyFormatter,
  ignoreNulls?: boolean
): Bucket[] {
  const uniqueValues = uniqueFeatureProperties(
    visibleFeatures,
    propertyName
  ) as (boolean | null)[];

  if (uniqueValues.length === 0) {
    return featureAttributeBuckets(marker.standard, "No Visible Data");
  } else {
    const hasNulls = uniqueValues.includes(null);

    const nonNullBuckets = uniqueValues
      .filter(value => value != null)
      .map((value, index) =>
        lookupBucket(
          value,
          value == null ? marker.empty : marker.lookup(index),
          format(value) ?? "-"
        )
      );

    if (hasNulls && !ignoreNulls) {
      return [...nonNullBuckets, lookupBucket(null, marker.empty)];
    } else {
      return nonNullBuckets;
    }
  }
}

function autoTimestampBuckets(
  visibleFeatures: Feature[],
  propertyName: string,
  ignoreNulls?: boolean
): Bucket[] {
  const uniqueValues = uniqueFeatureProperties(
    visibleFeatures,
    propertyName
  ) as PropertyValue[];

  let hasNulls = false;
  let minValue = Number.POSITIVE_INFINITY;
  let maxValue = Number.NEGATIVE_INFINITY;

  uniqueValues.forEach(value => {
    if (typeof value === "number") {
      minValue = Math.min(minValue, value);
      maxValue = Math.max(maxValue, value);
    } else {
      hasNulls = true;
    }
  });

  if (
    Number.isFinite(minValue) &&
    Number.isFinite(maxValue) &&
    maxValue > minValue
  ) {
    const rawMinYear = epochToYear(minValue);
    const rawMaxYear = epochToYear(maxValue);

    const minYear = rawMinYear < 2000 ? 2000 : rawMinYear;

    const maxYear = rawMaxYear < rawMinYear ? rawMinYear : rawMaxYear;

    const underflowBucket: Bucket[] =
      rawMinYear === minYear
        ? []
        : [
            rangeBucket(
              null,
              new Date(minYear, 0, 1, 0, 0, 0, 0).getTime(),
              marker.hue(10),
              `Before ${minYear}`,
              false
            ),
          ];

    const overflowBucket: Bucket[] =
      rawMaxYear === maxYear
        ? []
        : [
            rangeBucket(
              new Date(maxYear + 1, 0, 1, 0, 0, 0, 0).getTime(),
              null,
              marker.hue(350),
              `After ${maxYear}`,
              false
            ),
          ];

    const emptyBucket =
      hasNulls && !ignoreNulls ? [lookupBucket(null, marker.empty)] : [];

    let numericBuckets: Bucket[];

    if (maxYear > minYear) {
      numericBuckets = lodash.range(minYear, maxYear + 1).map(year => {
        return rangeBucket(
          yearToEpoch(year),
          yearToEpoch(year + 1),
          marker.hue(60 + (240 * (year - minYear)) / (maxYear - minYear)),
          `${year}`,
          year === maxYear // last bucket is inclusive,
        );
      });
    } else {
      const year = minYear;
      numericBuckets = lodash.range(0, 12).map(month => {
        return rangeBucket(
          yearAndMonthToEpoch(year, month),
          yearAndMonthToEpoch(year, month + 1),
          marker.hue(60 + (240 * month) / 12),
          monthLabel(year, month),
          month === 12 // last bucket is inclusive,
        );
      });
    }

    return [
      ...underflowBucket,
      ...numericBuckets,
      ...overflowBucket,
      ...emptyBucket,
    ];
  } else {
    return featureAttributeBuckets(marker.standard, "No Visible Data");
  }
}

export function attributeBuckets(
  attr: PropertyAttribute | FeatureAttribute,
  visibleFeatures?: Feature[],
  teams?: TeamV2[],
  fallbackBuckets: Bucket[] = featureAttributeBuckets(
    marker.standard,
    "No Visible Data"
  )
): Bucket[] {
  switch (attr.type) {
    case "StringAttribute":
      return attr.buckets.type === "AutoBuckets"
        ? visibleFeatures == null
          ? fallbackBuckets
          : autoStringBuckets(
              visibleFeatures,
              attr.propertyName,
              attr.format,
              attr.buckets.ignoreNulls
            )
        : attr.buckets.buckets;

    case "NumberAttribute":
      return attr.buckets.type === "AutoBuckets"
        ? visibleFeatures == null
          ? fallbackBuckets
          : autoNumberBuckets(
              visibleFeatures,
              attr.propertyName,
              attr.format,
              attr.buckets.ignoreNulls,
              attr.buckets.numBuckets
            )
        : attr.buckets.buckets;

    case "BooleanAttribute":
      return attr.buckets.type === "AutoBuckets"
        ? visibleFeatures == null
          ? fallbackBuckets
          : autoBooleanBuckets(
              visibleFeatures,
              attr.propertyName,
              attr.format,
              attr.buckets.ignoreNulls
            )
        : attr.buckets.buckets;

    case "TimestampAttribute":
      return attr.buckets.type === "AutoBuckets"
        ? visibleFeatures == null
          ? fallbackBuckets
          : autoTimestampBuckets(
              visibleFeatures,
              attr.propertyName,
              attr.buckets.ignoreNulls
            )
        : attr.buckets.buckets;

    case "TeamAttribute":
      return teams == null
        ? fallbackBuckets
        : chain(teams)
            .map((team, index) => {
              // This taken from https://stackoverflow.com/questions/1168260/algorithm-for-generating-unique-colors
              const fraction = (index * COLOR_SELECTION_PHI) % 1;

              return lookupBucket(
                team.id,
                marker.hue(fraction * 360),
                team.name
              );
            })
            .value();

    case "SurveyAttribute":
      return fallbackBuckets;

    case "FeatureAttribute":
      return fallbackBuckets;

    default:
      return checkExhausted(attr);
  }
}
