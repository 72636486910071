import { checks } from "@cartographerio/permission";
import { ProjectV2, WorkspaceRef } from "@cartographerio/types";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { partition } from "lodash";
import { useMemo } from "react";

import usePermissionCheckRunner from "./usePermissionCheckRunner";
import { useCurrentWorkspaceGraph } from "./useWorkspaceGraph";

export type PartitionedResults<T> = [T[], T[]];

export default function usePartitionedProjects(
  workspaceRef: WorkspaceRef,
  _graph?: WorkspaceGraphV2
): PartitionedResults<ProjectV2> {
  const currentWorkspaceGraph = useCurrentWorkspaceGraph();
  const graph = _graph ?? currentWorkspaceGraph;

  const permissionCheckPasses = usePermissionCheckRunner(graph);

  return useMemo(
    () =>
      partition(graph.findProjectsByWorkspaceRef(workspaceRef), project =>
        permissionCheckPasses(checks.project.member(project))
      ),
    [graph, permissionCheckPasses, workspaceRef]
  );
}
