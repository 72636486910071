import { isArray } from "@cartographerio/guard";
import { checks } from "@cartographerio/permission";
import { TeamV2, WorkspaceV2 } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import Container from "../../components/Container";
import {
  InvitationTabOption,
  PageBreadcrumbProps,
} from "../../components/PageBreadcrumb";
import PageHeader from "../../components/PageHeader";
import PageTab from "../../components/PageTab";
import PageTabs from "../../components/PageTabs";
import PageTabSelect, { pageTabOption } from "../../components/PageTabSelect";
import RequirePermission from "../../components/RequirePermission";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { routes } from "../../routes";

export interface TeamPageHeaderProps {
  workspace: WorkspaceV2;
  team: TeamV2;
  selected: Exclude<PageBreadcrumbProps["teamPage"], undefined>;
}

export default function TeamPageHeader(
  props: TeamPageHeaderProps
): ReactElement {
  const { workspace, team, selected } = props;

  const selectedTab = useMemo(
    () => (isArray(selected) ? selected[0] : selected),
    [selected]
  );

  const codesEnabled = useCodesEnabled(workspace);

  return (
    <>
      <Container sticky={true} width="wide">
        <PageHeader title={`${team.name} (Team)`}>
          <PageTabs selected={selectedTab}>
            <RequirePermission check={checks.team.viewSettings(team)}>
              <PageTab
                id="settings"
                label="Settings"
                to={routes.workspace.team.settings.url([
                  workspace.alias,
                  team.alias,
                ])}
              />
            </RequirePermission>
            <RequirePermission check={checks.team.viewMembers(team)}>
              <PageTab
                id="members"
                label="Members"
                to={routes.workspace.team.member.list.url([
                  workspace.alias,
                  team.alias,
                ])}
              />
            </RequirePermission>
            <RequirePermission check={checks.team.viewInvitations(team)}>
              <PageTabSelect<InvitationTabOption>
                id="invitation"
                label="Invitations"
                options={[
                  pageTabOption({
                    id: "single",
                    label: "Invitations",
                    to: routes.workspace.team.invitation.list.url([
                      workspace.alias,
                      team.alias,
                    ]),
                  }),
                  ...(codesEnabled
                    ? [
                        pageTabOption({
                          id: "code",
                          label: "Invitation Codes",
                          to: routes.workspace.team.invitation.code.list.url([
                            workspace.alias,
                            team.alias,
                          ]),
                        }),
                      ]
                    : []),
                ]}
              />
            </RequirePermission>
          </PageTabs>
        </PageHeader>
      </Container>
    </>
  );
}
