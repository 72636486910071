import { Option } from "@cartographerio/fp";
import { InputElementProps } from "@chakra-ui/react";
import { ForwardedRef, ReactElement, forwardRef, useMemo } from "react";

import { BaseTextFieldProps } from "./base";
import DefaultTextField from "./Default";
import Units from "./Units";

export interface NullableStringTextFieldProps
  extends BaseTextFieldProps<string | null>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  trim?: boolean;
  units?: string;
}

function format(str: string | null): string {
  return str == null ? "" : str;
}

function createValidate(trim: boolean) {
  return (str: string): Option<string | null> => {
    return Option.some(trim ? str.trim() : str).map(str =>
      str.length > 0 ? str : null
    );
  };
}

export default forwardRef(function NullableStringTextField(
  props: NullableStringTextFieldProps,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const { disabled, trim = true, units, ...rest } = props;

  const rightAddon =
    units == null ? undefined : <Units units={units} disabled={disabled} />;

  const validate = useMemo(() => createValidate(trim), [trim]);

  return (
    <DefaultTextField
      ref={ref}
      format={format}
      validate={validate}
      disabled={disabled}
      rightAddon={rightAddon}
      {...rest}
    />
  );
});
