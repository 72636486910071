import { checkExhausted } from "@cartographerio/util";
import {
  MessageContext,
  MultiConversationsContext,
  NoConversationContext,
  NoConversationPopoverContext,
  SingleConversationContext,
  SingleConversationPopoverContext,
} from "@frontapp/plugin-sdk";
import { ReactElement } from "react";

import Heading from "../components/Heading";
import JsonView from "../components/JsonView";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { FrontContext, contextEmails } from "./context";

interface FrontDebugProps<A extends FrontContext> {
  context: A;
}

export function FrontDebug(props: FrontDebugProps<FrontContext>): ReactElement {
  const { context } = props;

  switch (context.type) {
    case "noConversation":
    case "noConversationPopover":
      return <FrontDebugNoConversation context={context} />;

    case "singleConversation":
    case "singleConversationPopover":
      return <FrontDebugSingleConversation context={context} />;

    case "multiConversations":
      return <FrontDebugMultiConversation context={context} />;

    case "message":
      return <FrontDebugMessage context={context} />;

    default:
      return checkExhausted(context);
  }
}

function FrontDebugNoConversation(
  props: FrontDebugProps<NoConversationContext | NoConversationPopoverContext>
): ReactElement {
  const { context } = props;
  return <JsonView value={context} />;
}

function FrontDebugSingleConversation(
  props: FrontDebugProps<
    SingleConversationContext | SingleConversationPopoverContext
  >
): ReactElement {
  const { context } = props;

  const emails = useSuspenseQueryData({
    queryKey: ["front", "conversation", context.conversation.id],
    queryFn: () => contextEmails(context),
  });

  return (
    <>
      <Heading level="subsection">Emails</Heading>
      <JsonView value={emails} />
      <Heading level="subsection">Conversation</Heading>
      <JsonView value={context} />
    </>
  );
}

function FrontDebugMultiConversation(
  props: FrontDebugProps<MultiConversationsContext>
): ReactElement {
  const { context } = props;
  return <JsonView value={context} />;
}

function FrontDebugMessage(
  props: FrontDebugProps<MessageContext>
): ReactElement {
  const { context } = props;
  return <JsonView value={context} />;
}
