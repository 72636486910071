import { Tag, TagLeftIcon } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaServer } from "react-icons/fa";

import { ApiServerId, apiServerLabel } from "../contexts/server";

export interface ServerPillProps {
  server: ApiServerId;
}

export default function ServerPill(props: ServerPillProps): ReactElement {
  const { server } = props;
  return (
    <Tag
      maxW="unset"
      minW="unset"
      variant="solid"
      // size="sm"
      lineHeight="1"
      colorScheme="orange"
      fontWeight="bold"
      textOverflow="None"
      whiteSpace="nowrap"
      rounded="full"
    >
      <TagLeftIcon as={FaServer} />
      {apiServerLabel(server)}
    </Tag>
  );
}
