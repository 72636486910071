import {
  InvitationCodeAlias,
  unsafeInvitationCodeAlias,
} from "@cartographerio/types";
import { FlexProps, HStack, PinInput, PinInputField } from "@chakra-ui/react";
import { useCallback } from "react";

export interface InvitationCodeFieldProps {
  value: InvitationCodeAlias;
  onChange: (alias: InvitationCodeAlias) => void;
  justify?: FlexProps["justify"];
}

export default function InvitationCodeField(props: InvitationCodeFieldProps) {
  const { value, onChange, justify } = props;

  const handleChange = useCallback(
    (text: string) => {
      onChange?.(unsafeInvitationCodeAlias(text.toUpperCase()));
    },
    [onChange]
  );

  return (
    <HStack justify={justify} align="center">
      <PinInput
        type="alphanumeric"
        placeholder="•"
        value={value}
        onChange={handleChange}
      >
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
        <PinInputField />
      </PinInput>
    </HStack>
  );
}
