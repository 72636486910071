import { Feature, Geometry } from "@cartographerio/geometry";
import { isString } from "@cartographerio/guard";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useCallback } from "react";
import { ControlPosition, useControl } from "react-map-gl";

interface DrawControlProps<G extends Geometry> {
  mapboxDraw: MapboxDraw;
  position?: ControlPosition;
  onSave?: (feature: Feature<G> | null) => void;
}

export default function DrawControl<G extends Geometry>(
  props: DrawControlProps<G>
) {
  const { mapboxDraw, position, onSave } = props;

  const handleCreate = useCallback(
    (evt: { features: Feature<G>[] }) => {
      const features = mapboxDraw.getAll().features;
      const feature = evt.features[0];
      if (features.length > 1) {
        features.forEach(other => {
          if (isString(other.id) && other.id !== feature?.id) {
            mapboxDraw.delete([other.id]);
          }
        });
      }
      onSave?.(feature);
    },
    [mapboxDraw, onSave]
  );

  useControl<MapboxDraw>(
    () => mapboxDraw,
    ({ map }) => {
      map.on("draw.create", handleCreate);
      map.on("draw.update", ({ features }) => onSave?.(features[0]));
      map.on("draw.delete", () => onSave?.(null));
    },
    ({ map }) => {
      map.off("draw.create", handleCreate);
      map.off("draw.update", ({ features }) => onSave?.(features[0]));
      map.off("draw.delete", () => onSave?.(null));
    },
    { position }
  );

  return null;
}
