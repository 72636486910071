import {
  Attribute,
  isAttachmentAttribute,
  isBucketedAttribute,
  isFeatureAttribute,
  isSurveyAttribute,
  isTeamAttribute,
} from "@cartographerio/atlas-map";
import { Feature } from "@cartographerio/geometry";
import { isString } from "@cartographerio/guard";
import {
  unsafeAttachmentFolder,
  unsafeSurveyId,
  unsafeTeamId,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Table, Tbody, Td, Th, Thead, Tr, chakra } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import {
  useLinkContext,
  useProjectTeamContextGraph,
  useSelectedFeatures,
  useSelectedLayer,
  useSupportedAttributes,
} from "../AtlasMapContext";
import { featureKey } from "../layerHelpers";
import InspectorPanel, { InspectorPanelProps } from "./InspectorPanel";

export type FeatureTablePanelProps = InspectorPanelProps;

const MAX_TABLE_ROWS = 50;

interface TableHeading {
  label?: string;
  colSpan: number;
}

export default function FeatureTablePanel(
  props: FeatureTablePanelProps
): ReactElement | null {
  const [layer] = useSelectedLayer();

  const groupHeadings = useMemo((): TableHeading[] | null => {
    const headings = layer.attributes.map(group => ({
      label: group.label,
      colSpan: group.attributes.length,
    }));
    return headings.some(({ label }) => label != null) ? headings : null;
  }, [layer.attributes]);
  const attributes = useSupportedAttributes(layer.attributes);

  const { uniqueSelectedFeatures } = useSelectedFeatures(layer.layerId);

  const listedFeatures = useMemo(
    () =>
      uniqueSelectedFeatures.length > MAX_TABLE_ROWS
        ? uniqueSelectedFeatures.slice(0, MAX_TABLE_ROWS)
        : uniqueSelectedFeatures,
    [uniqueSelectedFeatures]
  );

  return listedFeatures.length === 0 ? null : (
    <InspectorPanel pb="0" {...props}>
      <Table size="sm" mt="1">
        <Thead>
          {groupHeadings != null && (
            <Tr>
              {groupHeadings.map(({ label, colSpan }, i) => (
                <Th key={i} colSpan={colSpan}>
                  {label}
                </Th>
              ))}
            </Tr>
          )}
          <Tr>
            {attributes.map(attr => (
              <Th key={attr.attributeId} whiteSpace="nowrap">
                {attr.label}{" "}
                {"unit" in attr && (
                  <chakra.span fontSize="2xs" textTransform="none">
                    {attr.unit}
                  </chakra.span>
                )}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {listedFeatures.map(feature => (
            <Tr key={featureKey(feature, layer.primaryKey)}>
              {attributes.map(attr => (
                <Td key={attr.attributeId} whiteSpace="nowrap" fontSize="xs">
                  <AttributeValue attribute={attr} feature={feature} />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </InspectorPanel>
  );
}

interface AttributeValueProps {
  attribute: Attribute;
  feature: Feature;
}

function AttributeValue(props: AttributeValueProps): ReactElement | null {
  const { attribute, feature } = props;

  const { optFindTeamById } = useProjectTeamContextGraph();

  const { SurveyLink, TeamLink, AttachmentLink } = useLinkContext();

  if (isFeatureAttribute(attribute)) {
    return feature.id == null ? <>-</> : <>{feature.id}</>;
  } else if (isBucketedAttribute(attribute)) {
    const value = feature.properties?.[attribute.propertyName] ?? null;

    return <>{attribute.format(value) ?? "-"}</>;
  } else if (isSurveyAttribute(attribute)) {
    const value = feature.properties?.[attribute.propertyName] ?? null;
    return isString(value) ? (
      <SurveyLink to={unsafeSurveyId(value)}>View Survey</SurveyLink>
    ) : null;
  } else if (isTeamAttribute(attribute)) {
    const value = feature.properties?.[attribute.propertyName] ?? null;
    const teamId = isString(value) ? unsafeTeamId(value) : null;
    return teamId == null ? null : (
      <TeamLink
        to={teamId}
        project={attribute.project}
        moduleId={attribute.module}
      >
        {optFindTeamById(teamId)?.name ?? "View Team"}
      </TeamLink>
    );
  } else if (isAttachmentAttribute(attribute)) {
    const survey = feature.properties?.[attribute.surveyPropertyName] ?? null;
    const folder = feature.properties?.[attribute.folderPropertyName] ?? null;
    return isString(survey) && isString(folder) ? (
      <AttachmentLink
        to={[unsafeSurveyId(survey), unsafeAttachmentFolder(folder)]}
      >
        View Gallery
      </AttachmentLink>
    ) : null;
  } else {
    return checkExhausted(attribute);
  }
}
