import {
  AttachmentFolder,
  AttachmentId,
  ProjectId,
  ProjectRef,
  SurveyId,
  SurveyModuleId,
  TeamId,
  WorkspaceRef,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { useBoolean, useDisclosure } from "@chakra-ui/react";
import {
  FunctionComponent,
  MouseEvent,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import { absoluteRouteUrl } from "../../../util";
import AttachmentFolderModal from "../../components/AttachmentFolderModal";
import FolderGalleryModal, {
  GalleryPosition,
} from "../../components/FolderGalleryModal";
import Link from "../../components/Link";
import LinkButton from "../../components/LinkButton";
import ThumbnailGallery from "../../components/ThumbnailGallery";
import { routes } from "../../routes";

interface LinkProps<A> {
  to: A;
  children: ReactNode;
}

interface TeamLinkProps extends LinkProps<TeamId> {
  project?: ProjectRef;
  moduleId?: SurveyModuleId;
}

interface AttachmentCarouselProps {
  folders: Array<[SurveyId, AttachmentFolder]>;
  fullView: boolean;
}

export interface LinkContext {
  AttachmentCarousel: FunctionComponent<AttachmentCarouselProps>;
  AttachmentLink: FunctionComponent<LinkProps<[SurveyId, AttachmentFolder]>>;
  ProjectLink: FunctionComponent<LinkProps<ProjectId>>;
  SurveyLink: FunctionComponent<LinkProps<SurveyId>>;
  TeamLink: FunctionComponent<TeamLinkProps>;
  WorkspaceLink: FunctionComponent<LinkProps<WorkspaceRef>>;
}

export const LinkContext = createContext<LinkContext | undefined>(undefined);

export function useLinkContext(): LinkContext {
  return (
    useContext(LinkContext) ?? raise(internalError("LinkContext not available"))
  );
}

export interface MapEmbedLinkContextProviderProps {
  uiEmbed: boolean;
  workspaceRef: WorkspaceRef;
  projectRef: ProjectRef;
  children: ReactNode;
}

export function MapEmbedLinkContextProvider(
  props: MapEmbedLinkContextProviderProps
): ReactElement {
  const { uiEmbed, workspaceRef, projectRef, children } = props;

  const context = useMemo(
    (): LinkContext => ({
      AttachmentCarousel: MapEmbedAttachmentCarousel,
      AttachmentLink: uiEmbed
        ? MapEmbedAttachmentLinkAuthorized
        : MapEmbedAttachmentLinkExternal,
      ProjectLink: uiEmbed
        ? MapEmbedProjectLinkAuthorized
        : MapEmbedProjectLinkExternal,
      SurveyLink: uiEmbed
        ? MapEmbedSurveyLinkAuthorized
        : MapEmbedSurveyLinkExternal,
      TeamLink: uiEmbed
        ? MapEmbedTeamLinkAuthorized(workspaceRef, projectRef)
        : MapEmbedTeamLinkExternal(workspaceRef, projectRef),
      WorkspaceLink: uiEmbed
        ? MapEmbedWorkspaceLinkAuthorized
        : MapEmbedWorkspaceLinkExternal,
    }),
    [projectRef, uiEmbed, workspaceRef]
  );

  return (
    <LinkContext.Provider value={context}>{children}</LinkContext.Provider>
  );
}

interface WebAppLinkContextProviderProps {
  workspaceRef: WorkspaceRef;
  projectRef: ProjectRef;
  children: ReactNode;
}

export function WebAppLinkContextProvider(
  props: WebAppLinkContextProviderProps
): ReactElement {
  const { workspaceRef, projectRef, children } = props;

  const context = useMemo(
    (): LinkContext => ({
      AttachmentCarousel: WebAppAttachmentCarousel,
      AttachmentLink: WebAppAttachmentLink,
      ProjectLink: WebAppProjectLink,
      SurveyLink: WebAppSurveyLink,
      TeamLink: WebAppTeamLink(workspaceRef, projectRef),
      WorkspaceLink: WebAppWorkspaceLink,
    }),
    [projectRef, workspaceRef]
  );

  return (
    <LinkContext.Provider value={context}>{children}</LinkContext.Provider>
  );
}

function MapEmbedAttachmentCarousel(props: AttachmentCarouselProps) {
  const { folders, fullView } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [galleryPosition, setGalleryPosition] =
    useState<GalleryPosition | null>(null);

  const handleOpen = useCallback(
    (galleryPosition: GalleryPosition) => {
      onOpen();
      setGalleryPosition(galleryPosition);
    },
    [onOpen]
  );

  return (
    <>
      <ThumbnailGallery
        folders={folders}
        columns={3}
        defaultRows={fullView ? 10 : 2}
        loadMoreNumRows={fullView ? 4 : undefined}
        w="100%"
        onItemClick={fullView ? handleOpen : undefined}
      />
      {galleryPosition != null && (
        <FolderGalleryModal
          galleryPosition={galleryPosition}
          folders={folders}
          isOpen={isOpen}
          onClose={onClose}
          absoluteUrls={true}
        />
      )}
    </>
  );
}

function MapEmbedAttachmentLinkAuthorized(
  props: LinkProps<[SurveyId, AttachmentFolder]>
): ReactElement {
  const {
    to: [surveyId, folder],
    children,
  } = props;

  const [isOpen, { on: _handleOpen, off: handleClose }] = useBoolean();

  const handleOpen = useCallback(
    (evt: MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      _handleOpen();
    },
    [_handleOpen]
  );

  const attachmentUrl = useCallback(
    (attachment: AttachmentId) =>
      absoluteRouteUrl(routes.attachment.view.url([attachment])),
    []
  );

  return (
    <>
      <LinkButton onClick={handleOpen}>{children}</LinkButton>
      {isOpen && (
        <AttachmentFolderModal
          isOpen={isOpen}
          onClose={handleClose}
          surveyId={surveyId}
          folder={folder}
          attachmentUrl={attachmentUrl}
        />
      )}
    </>
  );
}

function MapEmbedAttachmentLinkExternal(
  props: LinkProps<[SurveyId, AttachmentFolder]>
): ReactElement {
  const {
    to: [surveyId, folder],
    children,
  } = props;

  const to = useMemo(
    () => absoluteRouteUrl(routes.attachment.folder.url([surveyId, folder])),
    [folder, surveyId]
  );

  return <Link.External to={to}>{children}</Link.External>;
}

function MapEmbedSurveyLinkAuthorized(
  props: LinkProps<SurveyId>
): ReactElement {
  const { to, children } = props;

  const handleClick = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      window.parent.postMessage(`ViewSurvey:${to}`, "*");
    },
    [to]
  );

  return <LinkButton onClick={handleClick}>{children}</LinkButton>;
}

function MapEmbedSurveyLinkExternal(props: LinkProps<SurveyId>): ReactElement {
  const { to: survey, children } = props;

  const to = useMemo(
    () => absoluteRouteUrl(routes.short.survey.url([survey])),
    [survey]
  );

  return <Link.External to={to}>{children}</Link.External>;
}

function MapEmbedProjectLinkAuthorized(
  props: LinkProps<ProjectId>
): ReactElement {
  const { to: project, children } = props;

  const to = useMemo(
    () => absoluteRouteUrl(routes.short.project.url([project])),
    [project]
  );

  return <Link.Internal to={to}>{children}</Link.Internal>;
}

function MapEmbedProjectLinkExternal(
  props: LinkProps<ProjectId>
): ReactElement {
  const { to: project, children } = props;

  const to = useMemo(
    () => absoluteRouteUrl(routes.short.project.url([project])),
    [project]
  );

  return <Link.External to={to}>{children}</Link.External>;
}

function MapEmbedTeamLinkAuthorized(
  workspaceRef: WorkspaceRef,
  projectRef: ProjectRef
) {
  return function TeamLink(props: TeamLinkProps): ReactNode {
    const { to: team, project = projectRef, moduleId, children } = props;

    const to = useMemo(
      () =>
        moduleId != null
          ? absoluteRouteUrl(
              routes.workspace.project.survey.list.url(
                [workspaceRef, project, moduleId],
                { team }
              )
            )
          : null,
      [moduleId, project, team]
    );

    return to != null ? (
      <Link.Internal to={to}>{children}</Link.Internal>
    ) : (
      children
    );
  };
}

function MapEmbedTeamLinkExternal(
  workspaceRef: WorkspaceRef,
  projectRef: ProjectRef
) {
  return function TeamLink(props: TeamLinkProps): ReactNode {
    const { to: team, project = projectRef, moduleId, children } = props;

    const to = useMemo(
      () =>
        moduleId != null
          ? absoluteRouteUrl(
              routes.workspace.project.survey.list.url(
                [workspaceRef, project, moduleId],
                { team }
              )
            )
          : null,
      [moduleId, project, team]
    );

    return to != null ? (
      <Link.External to={to}>{children}</Link.External>
    ) : (
      children
    );
  };
}

function MapEmbedWorkspaceLinkAuthorized(
  props: LinkProps<WorkspaceRef>
): ReactElement {
  const { to, children } = props;

  const handleClick = useCallback(
    (evt: MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      window.parent.postMessage(`ViewWorkspace:${to}`, "*");
    },
    [to]
  );

  return <LinkButton onClick={handleClick}>{children}</LinkButton>;
}

function MapEmbedWorkspaceLinkExternal(
  props: LinkProps<WorkspaceRef>
): ReactElement {
  const { to: workspace, children } = props;

  const to = useMemo(
    () => absoluteRouteUrl(routes.short.workspace.url([workspace])),
    [workspace]
  );

  return <Link.External to={to}>{children}</Link.External>;
}

function WebAppSurveyLink(props: LinkProps<SurveyId>): ReactElement {
  const { to, children } = props;
  return (
    <Link.Internal to={routes.short.survey.url([to])}>{children}</Link.Internal>
  );
}

function WebAppTeamLink(workspaceRef: WorkspaceRef, projectRef: ProjectRef) {
  return function TeamLink(props: TeamLinkProps): ReactNode {
    const { to, project = projectRef, moduleId, children } = props;
    return moduleId != null ? (
      <Link.Internal
        to={routes.workspace.project.survey.list.url(
          [workspaceRef, project, moduleId],
          { team: to }
        )}
      >
        {children}
      </Link.Internal>
    ) : (
      children
    );
  };
}

function WebAppProjectLink(props: LinkProps<ProjectId>): ReactElement {
  const { to, children } = props;
  return (
    <Link.Internal to={routes.short.project.url([to])}>
      {children}
    </Link.Internal>
  );
}

function WebAppWorkspaceLink(props: LinkProps<WorkspaceRef>): ReactElement {
  const { to, children } = props;
  return (
    <Link.Internal to={routes.short.workspace.url([to])}>
      {children}
    </Link.Internal>
  );
}

function WebAppAttachmentLink(
  props: LinkProps<[SurveyId, AttachmentFolder]>
): ReactElement {
  const {
    to: [surveyId, folder],
    children,
  } = props;

  const [isOpen, { on: _handleOpen, off: handleClose }] = useBoolean();

  const handleOpen = useCallback(
    (evt: MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      _handleOpen();
    },
    [_handleOpen]
  );

  const attachmentUrl = useCallback(
    (attachment: AttachmentId) =>
      absoluteRouteUrl(routes.attachment.view.url([attachment])),
    []
  );

  return (
    <>
      <LinkButton onClick={handleOpen}>{children}</LinkButton>
      {isOpen && (
        <AttachmentFolderModal
          isOpen={isOpen}
          onClose={handleClose}
          surveyId={surveyId}
          folder={folder}
          attachmentUrl={attachmentUrl}
        />
      )}
    </>
  );
}

function WebAppAttachmentCarousel(
  props: AttachmentCarouselProps
): ReactElement {
  const { folders, fullView } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [galleryPosition, setGalleryPosition] =
    useState<GalleryPosition | null>(null);

  const handleOpen = useCallback(
    (galleryPosition: GalleryPosition) => {
      onOpen();
      setGalleryPosition(galleryPosition);
    },
    [onOpen]
  );

  return (
    <>
      <ThumbnailGallery
        folders={folders}
        columns={4}
        defaultRows={fullView ? 10 : 2}
        loadMoreNumRows={fullView ? 4 : undefined}
        w="100%"
        onItemClick={handleOpen}
      />
      {galleryPosition != null && (
        <FolderGalleryModal
          galleryPosition={galleryPosition}
          folders={folders}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}
