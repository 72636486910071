import { ReactElement, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useOptAccessToken } from "../contexts/auth";

export interface ForbidSigninProps {
  go?: string;
  children: ReactNode;
}

export default function ForbidSignin(props: ForbidSigninProps): ReactElement {
  const { go = "/", children } = props;

  const accessToken = useOptAccessToken();

  return accessToken == null ? <>{children}</> : <Navigate to={go} />;
}
