import { PageState } from "@cartographerio/atlas-form";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

import HelpText from "../components/HelpText";
import JsonView from "../components/JsonView";
import Spaced from "../components/Spaced";
import { useIsNarrow } from "../contexts/breakpoint";
import BlockView from "./BlockView";
import { useFormContext } from "./context/FormContext";
import { GROUP_SPACING } from "./GroupView";
import { PageContextProvider } from "./PageContext";
import PageTurnButtons from "./PageTurnButtons";

export interface PageViewProps {
  canUpdateSurveyor: boolean;
  pageKey: string;
  pageState: PageState;
  prevPage?: number | null;
  nextPage?: number | null;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  // onDoneClick?: () => void;
}

export default function PageView(props: PageViewProps): ReactElement {
  const {
    pageKey,
    pageState,
    prevPage,
    nextPage,
    onPrevClick,
    onNextClick,
    // onDoneClick,
  } = props;

  const { page, blockStates } = pageState;

  const { helpVisible, debugInfoVisible } = useFormContext();
  const allowMultiColumn = !useIsNarrow();

  return (
    <PageContextProvider allowMultiColumn={allowMultiColumn}>
      <Spaced spacing={GROUP_SPACING}>
        {helpVisible && page.help && <HelpText mt="8" text={page.help} />}

        {blockStates.map((state, sectionIndex) => {
          const key = `${pageKey}_block${sectionIndex}`;
          return <BlockView key={key} blockKey={key} blockState={state} />;
        })}

        <PageTurnButtons
          prevPage={prevPage}
          nextPage={nextPage}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          // onDoneClick={onDoneClick}
        />

        {debugInfoVisible && (
          <JsonView fontSize="sm" label={`Page ${pageKey}`} value={pageState} />
        )}

        <Box h="50vh" />
      </Spaced>
    </PageContextProvider>
  );
}
