import { useCallback, useMemo, useState } from "react";

import Checkbox from "../Checkbox";

export interface ListEnabledCheckboxProps<A> {
  checkboxLabel: string;
  value: A[];
  onChange: (value: A[]) => void;
  defaultItem: A;
  disabled: boolean;
}

export function ListEnabledCheckbox<A>(props: ListEnabledCheckboxProps<A>) {
  const { checkboxLabel, value, onChange, defaultItem, disabled } = props;

  const checked = useMemo(() => value.length > 0, [value.length]);

  const [backupValue, setBackupValue] = useState([defaultItem]);

  const handleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        onChange(backupValue);
      } else {
        setBackupValue(value);
        onChange([]);
      }
    },
    [backupValue, onChange, value]
  );

  return (
    <Checkbox
      checkboxLabel={checkboxLabel}
      value={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}
