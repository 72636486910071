import { TextState } from "@cartographerio/atlas-form";
import { checkExhausted } from "@cartographerio/util";
import { ReactElement } from "react";

import HelpText from "../components/HelpText";
import Markdown from "../components/Markdown";
import { useFormContext } from "./context/FormContext";

export interface TextViewProps {
  blockKey: string;
  blockState: TextState;
}

export default function TextView(props: TextViewProps): ReactElement | null {
  const { blockKey, blockState } = props;

  const { helpVisible } = useFormContext();

  if (blockState.visible) {
    switch (blockState.block.appearance) {
      case "help":
        return helpVisible ? (
          <HelpText key={blockKey} mt="-8" text={blockState.block.markdown} />
        ) : null;
      case "default":
      case undefined:
        return <Markdown key={blockKey} text={blockState.block.markdown} />;
      default:
        return checkExhausted(blockState.block.appearance);
    }
  } else {
    return null;
  }
}
