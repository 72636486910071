import {
  InvitationCodeSignupSearchOptionsV3,
  PartialParams,
  endpoints,
} from "@cartographerio/client";
import {
  ApiConfig,
  ApiParams,
  InvitationCodeSignup,
  InvitationCodeSignupApprovalRequest,
  InvitationCodeSignupRequest,
  InvitationCodeUseRequest,
  SearchResults,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../../base";

export type InvitationCodeSignupKey =
  | [
      "invitation",
      "code",
      "signup",
      "v3",
      "search",
      PartialParams<InvitationCodeSignupSearchOptionsV3>
    ];

export function search(
  apiParams: ApiParams,
  options: PartialParams<InvitationCodeSignupSearchOptionsV3> = {}
): UseQueryOpts<SearchResults<InvitationCodeSignup>, InvitationCodeSignupKey> {
  return {
    queryKey: ["invitation", "code", "signup", "v3", "search", options],
    queryFn: () =>
      endpoints.invitation.code.signup.v3
        .search(apiParams, options)
        .unsafeRun(),
  };
}

export function use(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: InvitationCodeUseRequest
) {
  return endpoints.invitation.code.signup.v3
    .use(apiParams, request)
    .tap(_ => queryClient.invalidateQueries());
}

export function signup(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  request: InvitationCodeSignupRequest
) {
  return endpoints.invitation.code.signup.v3
    .signup(apiConfig, request)
    .tap(_ => queryClient.invalidateQueries());
}

export function approve(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: InvitationCodeSignupApprovalRequest
) {
  return endpoints.invitation.code.signup.v3
    .approve(apiParams, request)
    .tap(_ => {
      queryClient.invalidateQueries(["person"]);
    });
}

export function reject(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: InvitationCodeSignupApprovalRequest
) {
  return endpoints.invitation.code.signup.v3
    .reject(apiParams, request)
    .tap(_ => {
      queryClient.invalidateQueries(["person"]);
    });
}
