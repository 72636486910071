import { Flex, Heading, SystemProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import { appBackground } from "../config/theme";
import { useIsNarrow } from "../contexts/breakpoint";
import Spaced from "./Spaced";

interface PageHeaderProps extends Omit<SystemProps, "left" | "right"> {
  title: ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
}

export default function PageHeader(props: PageHeaderProps): ReactElement {
  const { title, left, right, children, ...rest } = props;

  const isNarrow = useIsNarrow("xs");

  return (
    <Spaced
      bg={appBackground}
      mx="-1px"
      px="1px"
      spacing="4"
      pt="16"
      pb="2"
      mb="4"
      {...rest}
    >
      <Flex
        direction={isNarrow ? "column" : "row"}
        alignItems={isNarrow ? "start" : "center"}
        gap={4}
      >
        {left && (
          <Flex shrink={0} grow={0}>
            {left}
          </Flex>
        )}

        <Heading
          flexShrink={1}
          flexGrow={1}
          size="xl"
          mt={0}
          maxW="100%"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {title}
        </Heading>

        {right && (
          <Flex shrink={0} grow={0}>
            {right}
          </Flex>
        )}
      </Flex>

      {children}
    </Spaced>
  );
}
