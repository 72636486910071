import {
  SurveyModule,
  SurveyModuleInventory,
  productionSurveyModules,
} from "@cartographerio/inventory-surveys";
import { SurveyModuleId, notFoundError } from "@cartographerio/types";
import { useEffect, useMemo } from "react";

import { unsafePublishToWindow } from "../../util";

export default function useModuleInventory(): SurveyModuleInventory;
export default function useModuleInventory(
  module: SurveyModuleId
): SurveyModule;
export default function useModuleInventory(
  module?: SurveyModuleId
): SurveyModuleInventory | SurveyModule {
  const modules = useMemo(() => productionSurveyModules(), []);

  useEffect(() => {
    unsafePublishToWindow(modules, "modules", "inventory");
  }, [modules]);

  return useMemo(
    () =>
      module != null
        ? modules
            .surveyModuleById(module)
            .getOrThrow(() => notFoundError("module", module))
        : modules,
    [module, modules]
  );
}
