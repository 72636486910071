import { IO } from "@cartographerio/io";
import { unsafeEmail, unsafeInvitationCodeAlias } from "@cartographerio/types";
import { tuple } from "@cartographerio/util";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputGroup,
  InputRightElement,
  Link,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { Link as ReactLink } from "react-router-dom";

import { splitMessages } from "../../../schema/rule/errors";
import Checkbox from "../../components/Checkbox";
import InvitationCodeField from "../../components/InvitationCodeField";
import MessageFormControl from "../../components/MessageFormControl";
import MessageList from "../../components/MessageList";
import Para from "../../components/Para";
import PasswordField from "../../components/PasswordField";
import TermsDocuments from "../../components/TermsDocuments";
import TextField from "../../components/TextField";
import { routes } from "../../routes";
import {
  Action,
  editEmailAction,
  editSigninAction,
  reenterEmailAction,
} from "./action";
import { State } from "./state";

const signinErrorKeys = tuple("code", "email", "password", "acceptTerms");

interface SigninFormProps {
  state: State;
  isSignup: boolean;
  dispatch: (action: Action | IO<unknown>) => void;
}

const visuallyHiddenStyle = css`
  position: relative;
  top: -1000px;
  pointer-events: none;
  height: 1px;
  margin: calc(-1 * var(--chakra-space-4));
  // padding: 0;
  // overflow: hidden;
  // whitespace: nowrap;
  // position: absolute;
  // input {
  //   border: none;
  //   box-shadow: none;
  // }
`;

export function SigninForm(props: SigninFormProps): ReactElement {
  const {
    state: { type, signin, email },
    isSignup,
    dispatch,
  } = props;

  const errors = useMemo(
    () => splitMessages(signin.messages, signinErrorKeys),
    [signin.messages]
  );

  return (
    <>
      {errors._rest_.length > 0 && (
        <FormControl>
          <FormHelperText fontWeight="bold" textColor="red.500">
            <MessageList messages={errors._rest_} />
          </FormHelperText>
        </FormControl>
      )}

      <MessageFormControl
        id="email"
        label="Enter your email address"
        messages={errors.email}
      >
        <InputGroup>
          <TextField.String
            value={email}
            placeholder="jane@example.com"
            onChange={email => dispatch(editEmailAction(unsafeEmail(email)))}
            autoComplete="username"
            disabled={type !== "EnterEmail"}
          />

          {type !== "EnterEmail" && (
            <InputRightElement>
              <IconButton
                onClick={() => dispatch(reenterEmailAction)}
                borderLeftRadius={0}
                borderWidth="1px"
                borderRightRadius="md"
                variant="ghost"
                aria-label="Clear"
                icon={<IoCloseSharp />}
                tabIndex={-1}
              />
            </InputRightElement>
          )}
        </InputGroup>
      </MessageFormControl>

      <MessageFormControl
        id="password"
        label="Enter your password"
        messages={errors.password}
        position="relative"
        css={type === "EnterPassword" ? undefined : visuallyHiddenStyle}
      >
        <PasswordField
          value={signin.password}
          onChange={password => dispatch(editSigninAction({ password }))}
          tabIndex={type === "EnterPassword" ? undefined : -1}
          autoComplete="current-password"
        />

        <Box position="absolute" top={0} right={0} fontSize="md">
          &nbsp;
          <Link
            as={ReactLink}
            to={routes.password.forgot.url([], { email })}
            color="teal"
            fontSize="small"
            tabIndex={type === "EnterPassword" ? undefined : -1}
          >
            Forgot Password
          </Link>
        </Box>
      </MessageFormControl>

      <MessageFormControl
        id="code"
        hidden={type !== "EnterCode" && (type !== "EnterEmail" || !isSignup)}
        label="Sign up using an invitation code"
        messages={errors.code}
      >
        <InvitationCodeField
          value={signin.code ?? unsafeInvitationCodeAlias("")}
          onChange={code => dispatch(editSigninAction({ code }))}
        />
      </MessageFormControl>

      {signin.reacceptTerms && type === "EnterPassword" && (
        <MessageFormControl
          id="acceptTerms"
          label="Terms and Conditions"
          messages={errors.acceptTerms}
        >
          <Para fontSize="sm">
            We&apos;ve updated our terms and conditions and privacy policy. You
            must read and accept them to access Cartographer:
          </Para>
          <TermsDocuments />
          <Checkbox
            value={signin.acceptTerms ?? false}
            onChange={acceptTerms =>
              dispatch(editSigninAction({ acceptTerms }))
            }
            checkboxLabel="I have read, understood, and agreed to these documents"
          />
        </MessageFormControl>
      )}
    </>
  );
}
