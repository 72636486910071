import {
  Box,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  StyleProps,
  SystemProps,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import { Highlight, useCheckboxHighlight } from "../hooks/highlight";
import HelpPopover from "./HelpPopover";

export interface CheckboxProps extends StyleProps {
  value: boolean;
  onChange?: (newValue: boolean) => void;
  checkboxLabel?: ReactNode;
  checkboxHelp?: string;
  highlight?: Highlight;
  disabled?: boolean;
  size?: ChakraCheckboxProps["size"];
  variant?: ChakraCheckboxProps["variant"];
  labelColor?: SystemProps["color"];
}

export default function Checkbox(props: CheckboxProps): ReactElement {
  const {
    value,
    onChange,
    checkboxLabel,
    checkboxHelp,
    highlight,
    disabled,
    size,
    variant,
    labelColor: labelColorProp,
    ...rest
  } = props;

  const { colorScheme, labelColor } = useCheckboxHighlight(highlight);

  return (
    <Box {...rest}>
      <ChakraCheckbox
        disabled={disabled}
        w="100%"
        isChecked={value}
        onChange={_ => (disabled ? null : onChange?.(!value))}
        size={size}
        variant={variant}
        colorScheme={disabled ? "gray" : colorScheme}
        _focus={{ boxShadow: "none" }}
        _active={{ boxShadow: "none" }}
      >
        <chakra.span color={labelColorProp ?? labelColor} fontSize="sm">
          {checkboxLabel}
          {checkboxHelp && <HelpPopover size="sm" text={checkboxHelp} />}
        </chakra.span>
      </ChakraCheckbox>
    </Box>
  );
}
