export const GLOBAL_ROLE_HELP = `
Cartographer-wide roles used for administrators and service accounts only:

- **Map Viewers** - A special role assigned to temporary user accounts
  created by embedded map widgets. Map viewers can see maps in projects
  with *Public* map visibility, but can't access any other project data.

- **Global Admin** - Can access the "Cartographer Admin" area.

- **Superuser** - A special role that bypasses most permission checks.
  Effectively a Workspace Owner in every workspace,
  plus can access the "Cartographer Admin" area
  and can masquerade as any other user.
`;

export const WORKSPACE_ROLE_HELP = `
Workspace roles affect what a person can do across the whole workspace:

- **Workspace Members** can access the workspace when they sign in.
  Most of their abilities are determined by project and team roles.

- **Workspace Admins** have wide-ranging admin access.
  They are effectively a coordinator of every project in the workspace,
  and they can access workspace settings.

- **Workspace Owners** have additional access to billing settings.
`;

export const PROJECT_ROLE_HELP = `
Assign a person to a project to give them access to it.
What they can do depends on the role you select:

- **Project Members** can view surveys and maps
  but cannot create or modify data.

- **Project Surveyors** can contribute surveys.

- **Project Approvers** can edit and approve surveys
  on behalf of other surveyors.

- **Project Coordinators** can access project settings,
  invite new members, and view members' personal data.

Note: In multi-team projects, project-wide roles
apply across *all* teams. For example, a Project Approver
can approve any survey regardless of which team submitted it.
`;

export const TEAM_ROLE_HELP = `
*Teams* are groups of people contributing to one or more projects.

Assign a person to a team to allow them to act on the team's behalf.
What they can do depends on the role you give them:

- **Team Members** can view surveys and maps in the team's projects
  but cannot create or modify data.

- **Team Surveyors** can contribute surveys on behalf of the team.

- **Team Approvers** can edit and approve surveys
  belonging to other team members.

- **Team Coordinators** can access team settings,
  invite new members, and view members' personal data.

Team roles apply across all the team's associated projects,
but are scoped to the team's members and and surveys.
For example, a Team Approver can approve surveys
in any of the team's associated projects,
but they can only approve surveys
submitted by members of the same team.
`;
