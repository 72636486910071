import {
  PartialParams,
  QualificationSearchOptions,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  Qualification,
  QualificationRef,
  SearchResults,
} from "@cartographerio/types";

import { UseQueryOpts } from "../base";

export type QualificationKey =
  | ["qualification"]
  | ["qualification", "v1", "read", QualificationRef]
  | [
      "qualification",
      "v1",
      "search",
      PartialParams<QualificationSearchOptions>
    ];

export function search(
  apiParams: ApiParams,
  options: PartialParams<QualificationSearchOptions> = {}
): UseQueryOpts<SearchResults<Qualification>, QualificationKey> {
  return {
    queryKey: ["qualification", "v1", "search", options],
    queryFn: () =>
      endpoints.qualification.v1.search(apiParams, options).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  ref: QualificationRef
): UseQueryOpts<Qualification, QualificationKey> {
  return {
    queryKey: ["qualification", "v1", "read", ref],
    queryFn: () =>
      endpoints.qualification.v1.readOrFail(apiParams, ref).unsafeRun(),
  };
}
