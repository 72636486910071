import { Box, BoxProps, useOutsideClick } from "@chakra-ui/react";
import { ReactElement, useRef, useState } from "react";

import ClickToCopy from "./ClickToCopy";
import Placeholder from "./Placeholder";

interface ClickToRevealProps {
  size?: "sm" | "md";
  value: string;
  placeholder?: string;
}

function heightFor(size: "sm" | "md"): BoxProps["height"] {
  return size === "md" ? "10" : "8";
}

export default function ClickToReveal(props: ClickToRevealProps): ReactElement {
  const { size = "md", value, placeholder = "Click to reveal" } = props;

  const [visible, setVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref, handler: () => setVisible(false) });

  return (
    <Box ref={ref}>
      {visible ? (
        <ClickToCopy size={size} value={value} />
      ) : (
        <Placeholder
          text={placeholder}
          height={heightFor(size)}
          fontStyle="italic"
          cursor="pointer"
          onClick={() => setVisible(true)}
          py="4"
        />
      )}
    </Box>
  );
}
