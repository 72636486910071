import { ReactElement } from "react";

import FormLabel from "./FormLabel";
import Spaced from "./Spaced";

interface FormLabelsProps {
  htmlFor?: string;
  primary?: string | null;
  secondary?: string | null;
}

export default function FormLabels(
  props: FormLabelsProps
): ReactElement | null {
  const { htmlFor, primary, secondary } = props;

  return primary == null && secondary == null ? null : (
    <Spaced spacing="0">
      <FormLabel htmlFor={htmlFor} level="primary" text={primary} />
      <FormLabel htmlFor={htmlFor} level="secondary" text={secondary} />
    </Spaced>
  );
}
