import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseUserPage from "../base/BaseUserPage";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamUserPage(
  props: RouteProps<typeof routes.workspace.team.member.update>
): ReactElement {
  const {
    path: { workspaceRef, teamRef, userId },
  } = props;

  const apiParams = useApiParams();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const defaultUser = useSuspenseQueryData(
    queries.user.v2.readOrFail(apiParams, userId)
  );

  useRequirePermission(checks.team.grantAccess(team));

  usePageTitle(
    `${defaultUser.firstName} ${defaultUser.lastName} - ${team.name} - ${workspace.name}`
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="members"
        user={defaultUser}
      />
      <TeamPageHeader workspace={workspace} team={team} selected="members" />
      <BaseUserPage
        defaultUser={defaultUser}
        nextRedirect={routes.workspace.team.member.list.url([
          workspace.alias,
          team.alias,
        ])}
        workspace={workspace}
        workspaceGraph={workspaceGraph}
      />
    </>
  );
}
