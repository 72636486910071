import {
  Feature,
  GeoJsonProperties,
  Geometry,
  Point,
  commonLocations,
  flattenGeometry,
} from "@cartographerio/geometry";
import { centroid, geometryCollection } from "@turf/turf";
import { CSSProperties, useCallback, useState } from "react";
import { ViewStateChangeEvent } from "react-map-gl";

export const MIN_ZOOM = 1;
export const MAX_ZOOM = 20;

export const DEFAULT_MAP_CSS: CSSProperties = {
  width: "100%",
  height: "300px",
};

export interface Viewport {
  longitude: number;
  latitude: number;
  transitionDuration?: number;
  zoom: number;
}

export function pointToCoordinate(point: Point): [number, number] {
  return [point.coordinates[0], point.coordinates[1]];
}

export function featureCentroid<
  G extends Geometry,
  P extends GeoJsonProperties
>(feat: Feature<G, P>): Point {
  return centroid(geometryCollection(flattenGeometry(feat.geometry))).geometry;
}

export function useViewportState(
  center?: Point | null,
  zoom?: number | null
): [Viewport, (event: ViewStateChangeEvent) => void] {
  const { greatBritain } = commonLocations;

  const [viewport, setViewport] = useState<Viewport>({
    longitude: (center ?? greatBritain.center).coordinates[0],
    latitude: (center ?? greatBritain.center).coordinates[1],
    zoom: zoom ?? greatBritain.zoom,
  });

  const onMove = useCallback((event: ViewStateChangeEvent) => {
    setViewport(event.viewState);
  }, []);

  return [viewport, onMove];
}
