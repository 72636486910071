import { Option } from "@cartographerio/fp";
import {
  Box,
  Flex,
  Input,
  List,
  ListItem,
  SystemProps,
} from "@chakra-ui/react";
import { GetItemPropsOptions, useCombobox } from "downshift";
import {
  FunctionComponent,
  Key,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import useDebouncedTextValue from "../hooks/useDebouncedTextValue";

const FILTER_DEBOUNCE = 100;

export interface SearchableListItemProps<A> {
  option: A;
  index: number;
  selected: boolean;
}

export interface SearchableListProps<A> extends SystemProps {
  options: A[];
  optionMatches: (options: A, filter: string) => boolean;
  formatOption: (option: A | null) => string;
  ListItem: FunctionComponent<SearchableListItemProps<A>>;
  itemKey?: (item: A, index: number) => Key;
  onSelect?: (option: A) => void;
  onCancel?: () => void;
  showOptionsByDefault?: boolean;
  normalizeFilter?: (filter: string) => string;
  placeholder?: string;
}

function lowerCaseAndTrim(filter: string): string {
  return filter.toLowerCase().trim();
}

/**
 * Component similar to a <Select>
 * but where the matching options are presented inline in the web page.
 */
export default function SearchableList<A>(
  props: SearchableListProps<A>
): ReactElement {
  const {
    options,
    showOptionsByDefault = false,
    optionMatches,
    formatOption,
    ListItem: Item,
    itemKey = (_, i) => i,
    onSelect,
    onCancel,
    normalizeFilter = lowerCaseAndTrim,
    placeholder = "Type to search the list...",
    ...rest
  } = props;

  const navigate = useNavigate();

  const [filter, setFilter] = useState("");

  const normalizedFilter = useMemo(
    () => normalizeFilter(filter),
    [filter, normalizeFilter]
  );

  const filteredOptions = useMemo<A[]>(
    () =>
      normalizedFilter.length === 0
        ? showOptionsByDefault
          ? options
          : []
        : options.filter(option => optionMatches(option, normalizedFilter)),
    [normalizedFilter, optionMatches, options, showOptionsByDefault]
  );

  const {
    textValue,
    // localError,
    handleBlur,
    handleFocus,
    handleTextValueChange,
    handleTextChange,
  } = useDebouncedTextValue<string, HTMLInputElement>({
    defaultValue: "",
    format: value => value,
    validate: value => Option.wrap(value),
    // onFocus: _handleFocus,
    // onBlur: _handleBlur,
    onChange: setFilter,
    debounce: FILTER_DEBOUNCE,
  });

  const [selected, setSelected] = useState<A | null>(null);

  const {
    // getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps: _getItemProps,
  } = useCombobox({
    isOpen: true,
    inputValue: textValue,
    items: filteredOptions,
    itemToString: formatOption,
    onInputValueChange: ({ inputValue }) =>
      handleTextValueChange(inputValue ?? ""),
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter: {
          if (
            state.highlightedIndex >= 0 &&
            state.highlightedIndex < filteredOptions.length
          ) {
            setSelected(filteredOptions[state.highlightedIndex]);
          }

          const { inputValue: _, ...rest } = changes;
          return rest;
        }

        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          onCancel?.();
          return changes;

        default:
          return changes;
      }
    },
  });

  useEffect(() => {
    if (selected != null) {
      onSelect?.(selected);
    }
  }, [navigate, onSelect, selected]);

  const inputProps = getInputProps({
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleTextChange,
    "aria-label": placeholder,
    placeholder,
  });

  const getItemProps = useCallback(
    ({ item, index }: GetItemPropsOptions<A>) => {
      const { onClick: _, ...rest } = _getItemProps({ item, index });
      return rest;
    },
    [_getItemProps]
  );

  return (
    <Flex direction="column" {...rest}>
      <Box flexShrink={0} flexGrow={0} {...getComboboxProps()}>
        <Input {...inputProps} />
      </Box>

      <List flexShrink={1} flexGrow={1} overflowY="auto" {...getMenuProps()}>
        {filteredOptions.map((item, index) => (
          <ListItem
            key={itemKey(item, index)}
            bg={index === highlightedIndex ? "gray.100" : undefined}
            rounded="md"
            mt="2"
            cursor="pointer"
            onClick={() => onSelect?.(item)}
            {...getItemProps({ item, index })}
          >
            <Item
              key={index}
              option={item}
              index={index}
              selected={index === highlightedIndex}
            />
          </ListItem>
        ))}
      </List>
    </Flex>
  );
}
