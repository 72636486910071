import { SelectOption } from "@cartographerio/atlas-form";
import { check } from "@cartographerio/permission";
import {
  GlobalRoleNameEnum,
  ProjectRoleNameEnum,
  ProjectV2,
  RoleV2,
  TeamRoleNameEnum,
  TeamV2,
  WorkspaceRoleNameEnum,
  WorkspaceV2,
  globalRoleV2,
  projectRoleV2,
  teamRoleV2,
  workspaceRoleV2,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { IconButton } from "@chakra-ui/button";
import { Box, Flex, HStack } from "@chakra-ui/layout";
import { FlexProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import FormLabel from "../FormLabel";
import Select from "../Select";

const globalRoleOptions = GlobalRoleNameEnum.entries.map(
  ({ label, value }) => ({
    label: value === "MapViewer" ? "Map Viewer" : label,
    value: globalRoleV2(value),
  })
);

interface GlobalRoleNodeProps extends Omit<FlexProps, "value" | "onChange"> {
  value: RoleV2 | null;
  disabled?: boolean;
  help?: string;
  onChange?: (value: RoleV2 | null) => void;
}

export function GlobalRoleNode(props: GlobalRoleNodeProps): ReactElement {
  const { value, disabled, help, onChange, ...rest } = props;
  return (
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      rowGap="2"
      align={["flex-start", "center"]}
      {...rest}
    >
      <FormLabel text="Global Role" fontSize="lg" mb="0" help={help} />
      <Select.Nullable
        placeholder="No role"
        value={value}
        options={globalRoleOptions}
        onChange={onChange}
        disabled={disabled}
        w={["100%", "20ch"]}
        mr={["0", "4"]}
      />
    </Flex>
  );
}

interface WorkspaceRoleNodeProps extends Omit<FlexProps, "value" | "onChange"> {
  workspace: WorkspaceV2;
  graph: WorkspaceGraphV2;
  value: RoleV2;
  disabled?: boolean;
  help?: string;
  onChange?: (value: RoleV2) => void;
}

export function WorkspaceRoleNode(props: WorkspaceRoleNodeProps): ReactElement {
  const { workspace, graph, value, disabled, help, onChange, ...rest } = props;

  const permissionCheckPasses = usePermissionCheckRunner(graph);

  const options = useMemo(
    () =>
      filterAndMap(WorkspaceRoleNameEnum.entries, ({ label, value }) =>
        permissionCheckPasses(
          check.canGrantRole(workspaceRoleV2(value, workspace.id))
        )
          ? { label, value: workspaceRoleV2(value, workspace.id) }
          : null
      ),
    [permissionCheckPasses, workspace.id]
  );

  return (
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      rowGap="2"
      align={["flex-start", "center"]}
      {...rest}
    >
      <FormLabel my="0" text="Workspace Role" help={help} />
      <Select.Standard
        value={value}
        options={options}
        onChange={onChange}
        disabled={disabled}
        w={["100%", "20ch"]}
      />
    </Flex>
  );
}

interface ProjectRoleNodeProps extends Omit<FlexProps, "value" | "onChange"> {
  project: ProjectV2;
  graph: WorkspaceGraphV2;
  value: RoleV2 | null;
  disabled?: boolean;
  onChange?: (value: RoleV2 | null) => void;
}

export function ProjectRoleNode(props: ProjectRoleNodeProps): ReactElement {
  const { project, graph, disabled, ...rest } = props;

  const permissionCheckPasses = usePermissionCheckRunner(graph);

  const options = useMemo(
    () =>
      filterAndMap(ProjectRoleNameEnum.entries, ({ label, value }) =>
        permissionCheckPasses(
          check.canGrantRole(projectRoleV2(value, project.id))
        )
          ? { label, value: projectRoleV2(value, project.id) }
          : null
      ),
    [permissionCheckPasses, project.id]
  );

  return (
    <BaseRoleNode
      label={project.name}
      options={options}
      disabled={disabled}
      removable={false}
      {...rest}
    />
  );
}

interface TeamRoleNodeProps extends Omit<FlexProps, "value" | "onChange"> {
  team: TeamV2;
  graph: WorkspaceGraphV2;
  value: RoleV2 | null;
  disabled?: boolean;
  onChange?: (value: RoleV2 | null) => void;
}

export function TeamRoleNode(props: TeamRoleNodeProps): ReactElement {
  const { team, graph, disabled, ...rest } = props;

  const permissionCheckPasses = usePermissionCheckRunner(graph);

  const secondaryLabel = useMemo(() => {
    const names = graph
      .findProjectsByTeamId(team.id)
      .map(project => project.name)
      .sort();

    return names.length === 0 ? undefined : `(${names.join(", ")})`;
  }, [graph, team.id]);

  const options = useMemo(
    () =>
      filterAndMap(TeamRoleNameEnum.entries, ({ label, value }) =>
        permissionCheckPasses(check.canGrantRole(teamRoleV2(value, team.id)))
          ? { label, value: teamRoleV2(value, team.id) }
          : null
      ),
    [permissionCheckPasses, team.id]
  );

  return (
    <BaseRoleNode
      label={team.name}
      secondaryLabel={secondaryLabel}
      options={options}
      disabled={disabled}
      removable={true}
      {...rest}
    />
  );
}

interface BaseRoleNodeProps extends Omit<FlexProps, "value" | "onChange"> {
  label: string;
  secondaryLabel?: string;
  value: RoleV2 | null;
  options: SelectOption<RoleV2>[];
  help?: string;
  disabled?: boolean;
  onChange?: (value: RoleV2 | null) => void;
  removable?: boolean;
}

function BaseRoleNode(props: BaseRoleNodeProps): ReactElement {
  const {
    label,
    secondaryLabel,
    value,
    options,
    help,
    disabled,
    onChange,
    removable = false,
    ...rest
  } = props;

  return (
    <Flex
      direction={["column", "row"]}
      justify="space-between"
      rowGap="2"
      columnGap="4"
      align={["stretch", "center"]}
      {...rest}
    >
      <Box>
        <FormLabel my="0" text={label} help={help} fontWeight="normal" />
        {secondaryLabel != null && (
          <FormLabel my="0" text={secondaryLabel} level="secondary" />
        )}
      </Box>
      <HStack>
        <Select.Nullable
          placeholder="No role"
          value={value}
          options={options}
          onChange={onChange}
          disabled={disabled || options.length === 0}
          w={["100%", "20ch"]}
        />
        {removable && !disabled && (
          <RemoveButton
            onClick={() => onChange?.(null)}
            disabled={disabled || value == null}
          />
        )}
      </HStack>
    </Flex>
  );
}

export interface RemoveButtonProps {
  label?: string;
  onClick: () => void;
  disabled?: boolean;
}

export function RemoveButton(props: RemoveButtonProps) {
  const { label = "Remove Role", onClick, disabled } = props;

  return (
    <IconButton
      variant="outline"
      size="sm"
      title={label}
      aria-label={label}
      icon={<IoCloseSharp size="1.25rem" />}
      onClick={onClick}
      isDisabled={disabled}
    />
  );
}
