import { PermissionCheck } from "@cartographerio/types";
import { ReactElement, ReactNode, useMemo } from "react";

import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";

interface WithPermissionProps {
  check: PermissionCheck;
  children: (passes: boolean) => ReactNode;
}

export default function WithPermission(
  props: WithPermissionProps
): ReactElement {
  const { check, children } = props;

  const permissionCheckPasses = usePermissionCheckRunner();

  const rendered = useMemo(
    () => children(permissionCheckPasses(check)),
    [check, children, permissionCheckPasses]
  );

  return <>{rendered}</>;
}
