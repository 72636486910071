import { SurveyStatus, SurveyStatusEnum } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Badge, BadgeProps } from "@chakra-ui/react";
import { ReactElement } from "react";

interface SurveyStatusBadgeProps extends BadgeProps {
  status: SurveyStatus;
}

export function statusColor(status: SurveyStatus): string {
  switch (status) {
    case SurveyStatusEnum.Approved:
      return "green";
    case SurveyStatusEnum.Complete:
      return "orange";
    case SurveyStatusEnum.Draft:
      return "gray";
    case SurveyStatusEnum.Rejected:
      return "red";
    case SurveyStatusEnum.Review:
      return "orange";
    default: {
      return checkExhausted(status);
    }
  }
}

export default function SurveyStatusBadge(
  props: SurveyStatusBadgeProps
): ReactElement {
  const { status, ...rest } = props;

  return (
    <Badge {...rest} colorScheme={statusColor(status)}>
      {status}
    </Badge>
  );
}
