import { internalError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";

export interface PageContext {
  allowMultiColumn: boolean;
}

const PageContext = createContext<PageContext | undefined>(undefined);

export function usePageContext(): PageContext {
  return (
    useContext(PageContext) ?? raise(internalError("PageContext not available"))
  );
}

interface PageContextProviderProps extends PageContext {
  children: ReactNode;
}

export function PageContextProvider(
  props: PageContextProviderProps
): ReactElement {
  const { allowMultiColumn, children } = props;

  const context: PageContext = useMemo(
    () => ({ allowMultiColumn }),
    [allowMultiColumn]
  );

  return (
    <PageContext.Provider value={context}>{children}</PageContext.Provider>
  );
}

export interface DisallowMultiColumnProps {
  children: ReactNode;
}

export function DisallowMultiColumn(
  props: DisallowMultiColumnProps
): ReactElement {
  const { children } = props;
  const { allowMultiColumn: _, ...rest } = usePageContext();

  return (
    <PageContext.Provider value={{ ...rest, allowMultiColumn: false }}>
      {children}
    </PageContext.Provider>
  );
}
