import { checkExhausted } from "@cartographerio/util";
import type { PropsWithChildren, ReactElement } from "react";
import { ReactNode, isValidElement } from "react";

export default function reactNodeText(children: ReactNode): string {
  let ans = "";

  function loop(child: ReactNode) {
    if (
      typeof child === "string" ||
      typeof child === "number" ||
      typeof child === "boolean"
    ) {
      ans += String(child);
    } else if (child == null) {
      // Do nothing
    } else if (isIterable(child)) {
      for (const item of child) {
        loop(item);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } else if (isValidElement<any>(child)) {
      if (hasChildren(child)) {
        loop(child.props.children);
      } else {
        // Do nothing
      }
    } else {
      checkExhausted(child);
    }
  }

  loop(children);

  return ans;
}

function hasChildren(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elem: ReactElement<any>
): elem is ReactElement<PropsWithChildren<Record<string, never>>> {
  return "children" in elem.props;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isIterable(x: any): x is Iterable<unknown> {
  return Symbol.iterator in x;
}
