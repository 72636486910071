import { Flex } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FiAtSign } from "react-icons/fi";

import MessageFormControl from "../MessageFormControl";
import TextField from "../TextField";
import { ItemEditorProps, ListEditor, ListEditorProps } from "./ListEditor";

export type DomainListEditorProps = Omit<
  ListEditorProps<string>,
  "defaultItem" | "ItemEditor" | "addButtonLabel" | "emptyText"
>;

export function DomainListEditor(props: DomainListEditorProps): ReactElement {
  return (
    <ListEditor
      {...props}
      defaultItem=""
      ItemEditor={DomainField}
      addButtonLabel="Add Domain"
      emptyText="No domains. Click 'Add Domain' to add one."
    />
  );
}

function DomainField(props: ItemEditorProps<string>): ReactElement {
  const { value, onChange, messages, disabled } = props;

  return (
    <MessageFormControl messages={messages} w="100%" maxWidth="50ch">
      <TextField.String
        leftAddon={
          <Flex
            align="center"
            px="2"
            // So the left element doesn't cover the input border - when focused, or has errors
            ml="1px"
            py="11px"
            borderLeftRadius="md"
            bg="gray.100"
          >
            <FiAtSign />
          </Flex>
        }
        placeholder="example.com"
        pl="9"
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </MessageFormControl>
  );
}
