import { EventData, MapEventType } from "mapbox-gl";
import { useEffect } from "react";
import { useMap } from "react-map-gl";

export type MapEvent<T extends keyof MapEventType> = MapEventType[T] &
  EventData;

export type MapEventHandler<T extends keyof MapEventType> = (
  ev: MapEvent<T>
) => void;

export function useMapEvent<T extends keyof MapEventType>(
  type: T,
  handler: MapEventHandler<T>
): void {
  const maps = useMap();

  useEffect(() => {
    const map = maps.current?.getMap();

    if (map != null) {
      map.on(type, handler);

      return () => {
        map.off(type, handler);
      };
    }
  }, [handler, maps, type]);
}
