import { IO } from "@cartographerio/io";
import { check, checks } from "@cartographerio/permission";
import { ProjectV2 } from "@cartographerio/types";
import { Flex, chakra, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { projectEmailSettingsRule } from "../../../../schema/emailSettings";
import { projectRule } from "../../../../schema/project";
import { useIOErrorAlert, useIOPrompt } from "../../../components/Alert";
import Button from "../../../components/Button";
import PageContainer from "../../../components/PageContainer";
import PageTopBar from "../../../components/PageTopBar";
import Pre from "../../../components/Pre";
import ProjectEditor from "../../../components/ProjectEditor";
import RequirePermission from "../../../components/RequirePermission";
import SaveButton from "../../../components/SaveButton";
import Spaced from "../../../components/Spaced";
import WithPermission from "../../../components/WithPermission";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { useVolatileState } from "../../../hooks/useVolatileState";
import { routes } from "../../../routes";
import RecordMetadata from "../../workspace/RecordMetadata";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectSettingsPage(
  props: RouteProps<typeof routes.workspace.project.settings>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();
  const promptAlert = useIOPrompt();
  const toast = useToast();

  const initialProject = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  useRequirePermissionRedirect(
    checks.project.viewSettings(initialProject),
    () => routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, initialProject.workspaceId)
  );

  const initialEmailSettings = useSuspenseQueryData(
    queries.project.emailSettings.v1.readOrDefault(apiParams, initialProject.id)
  );

  const initialMapSettings = useSuspenseQueryData(
    queries.project.mapSettings.v1.readOrDefault(apiParams, initialProject.id)
  );

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const mapSchemas = useSuspenseSearchResults(
    queries.map.schema.v1.search(apiParams)
  );

  const modules = useSuspenseSearchResults(
    queries.survey.module.v1.search(apiParams)
  );

  const projectTemplates = useSuspenseSearchResults(
    queries.project.template.v1.search(apiParams)
  );

  const [project, setProject] = useVolatileState<ProjectV2>(
    useCallback(() => initialProject, [initialProject])
  );

  const [emailSettings, setEmailSettings] = useVolatileState(
    useCallback(() => initialEmailSettings, [initialEmailSettings])
  );

  const [mapSettings, setMapSettings] = useVolatileState(
    useCallback(() => initialMapSettings, [initialMapSettings])
  );

  const projectMessages = useMemo(() => projectRule(project), [project]);

  const emailSettingsMessages = useMemo(
    () => projectEmailSettingsRule(emailSettings),
    [emailSettings]
  );

  const mapSettingsMessages = useMemo(() => [], []);

  const allMessages = useMemo(
    () =>
      projectMessages.concat(emailSettingsMessages).concat(mapSettingsMessages),
    [emailSettingsMessages, mapSettingsMessages, projectMessages]
  );

  usePageTitle(`Settings - ${project.name} - ${workspace.name}`);

  const handleSave = useCallback(() => {
    queries.project.v2
      .save(queryClient, apiParams, project)
      .flatMap(IO.fromResult)
      .tap(({ alias }) =>
        navigate(routes.workspace.project.settings.url([workspaceRef, alias]))
      )
      .tap(project =>
        IO.parForEach([
          queries.project.emailSettings.v1.save(
            queryClient,
            apiParams,
            project.id,
            emailSettings
          ),
          queries.project.mapSettings.v1.save(
            queryClient,
            apiParams,
            project.id,
            mapSettings
          ),
        ])
      )
      .tap(_ => toast({ status: "success", description: "Settings Saved" }))
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    apiParams,
    emailSettings,
    errorAlert,
    mapSettings,
    navigate,
    project,
    queryClient,
    toast,
    workspaceRef,
  ]);

  const handleDelete = useCallback(
    () =>
      promptAlert({
        title: `Delete Project ${project.name}?`,
        message: (
          <Spaced spacing="2">
            <chakra.p>
              All surveys, map data, and uploaded photographs will be
              permanently deleted, and you will no longer be billed for the
              project. There is no undo!
            </chakra.p>
            <chakra.p>
              User accounts will remain in your workspace and will continue to
              have the same access to other projects and teams.
            </chakra.p>
            <chakra.p>
              To confirm your choice, enter{" "}
              <Pre text={project.name} display="inline" py="0" px="1" /> in the
              text field below:
            </chakra.p>
          </Spaced>
        ),
      })
        .flatMap(({ confirmed, text }) =>
          confirmed && text === project.name
            ? queries.project.v2
                .remove(queryClient, apiParams, project.id)
                .tap(() =>
                  toast({ status: "success", description: "Project Deleted" })
                )
                .tap(() =>
                  navigate(routes.workspace.project.list.url([workspace.alias]))
                )
                .tapError(() =>
                  toast({ status: "error", description: "Delete cancelled" })
                )
            : IO.noop()
        )
        .unsafeRun(),
    [
      promptAlert,
      project.name,
      project.id,
      queryClient,
      apiParams,
      toast,
      navigate,
      workspace.alias,
    ]
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={initialProject}
        projectPage="settings"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={initialProject}
        selected="settings"
      />
      <PageContainer width="narrow">
        <WithPermission check={checks.project.editSettings(initialProject)}>
          {passes => (
            <Spaced spacing="4">
              <ProjectEditor
                project={project}
                onProjectChange={setProject}
                projectMessages={projectMessages}
                emailSettings={emailSettings}
                onEmailSettingsChange={setEmailSettings}
                emailSettingsMessages={emailSettingsMessages}
                mapSettings={mapSettings}
                onMapSettingsChange={setMapSettings}
                mapSettingsMessages={mapSettingsMessages}
                qualifications={qualifications}
                mapSchemas={mapSchemas}
                modules={modules}
                projectTemplates={projectTemplates}
                disabled={!passes}
              />
              <Flex justifyContent="space-between">
                <SaveButton
                  onClick={handleSave}
                  messages={allMessages}
                  disabled={!passes}
                />
                <RequirePermission check={check.superuser}>
                  <Button
                    label="Delete Project"
                    colorScheme="red"
                    onClick={handleDelete}
                  />
                </RequirePermission>
              </Flex>

              <RecordMetadata.Disclosure>
                <RecordMetadata.Item label="Project ID" value={project.id} />
                <RecordMetadata.Item
                  label="Workspace ID"
                  value={workspace.id}
                />
              </RecordMetadata.Disclosure>
            </Spaced>
          )}
        </WithPermission>
      </PageContainer>
    </>
  );
}
