import { Box, VStack } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import Button from "../components/Button";
import PageContainer from "../components/PageContainer";
import { useAuthContext } from "../contexts/auth";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { usePageTitle } from "../hooks/usePageTitle";
import { routes } from "../routes";

export default function RestoreIdentityPage(
  props: RouteProps<typeof routes.identity.restore>
): ReactElement {
  const {
    query: { go = "/" },
  } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    state: { accessTokenData },
    dispatch: authDispatch,
  } = useAuthContext();

  usePageTitle("Restore Identity");

  useEffectOnce(() => {
    if (accessTokenData?.real != null) {
      queries.auth.v2.restoreIdentity(queryClient, authDispatch).unsafeRun();
    }
    navigate(go, { replace: true });
  });

  return (
    <PageContainer>
      <VStack h="100vh" justify="center" align="center" gap="4">
        <Box>Redirecting you. Please wait...</Box>
        <Button
          label="Continue"
          variant="outline"
          onClick={() => navigate(routes.home.url([]))}
        />
      </VStack>
    </PageContainer>
  );
}
