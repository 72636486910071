import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { ApiParams, ProjectEmailSettings, TeamId } from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type TeamEmailSettingsKey = ["team", "email-settings", "v1", TeamId];

export function readOrNull(
  apiParams: ApiParams,
  teamId: TeamId
): UseQueryOpts<ProjectEmailSettings | null, TeamEmailSettingsKey> {
  return {
    queryKey: ["team", "email-settings", "v1", teamId],
    queryFn: () =>
      endpoints.team.emailSettings.v1.readOrNull(apiParams, teamId).unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  teamId: TeamId,
  settings: ProjectEmailSettings | null
): IO<void> {
  return (
    settings == null
      ? endpoints.team.emailSettings.v1.remove(apiParams, teamId)
      : endpoints.team.emailSettings.v1.save(apiParams, teamId, settings)
  ).tap(_ => queryClient.invalidateQueries(["team", "email-settings"]));
}
