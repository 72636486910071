import {
  InvitationCodeSortKey,
  InvitationSortKey,
  MemberSortKey,
  ProjectSortKey,
  SortOrder,
  SurveySearchKeyV3,
  TeamSortKey,
  WorkspaceSortKey,
} from "@cartographerio/client";
import { Option, Result } from "@cartographerio/fp";
import {
  BBox4,
  formatLatLng,
  isBBox4,
  parseLatLng,
} from "@cartographerio/geometry";
import {
  GlobalRoleName,
  GlobalRoleNameEnum,
  MapBase,
  MapBaseEnum,
  MapLayerId,
  ProjectRoleName,
  ProjectRoleNameEnum,
  QualificationRoleName,
  QualificationRoleNameEnum,
  SurveyStatus,
  SurveyStatusEnum,
  TeamRoleName,
  TeamRoleNameEnum,
  Timestamp,
  WorkspaceRoleName,
  WorkspaceRoleNameEnum,
  ddmmyyyy as ddmmyyyyFormat,
  formatNamedInterval,
  formatTimestamp,
  formatTimestampIso8601,
  isGlobalRoleName,
  isInvitationCodeStatus,
  isInvitationStatus,
  isMemberType,
  isProjectRoleName,
  isTeamRoleName,
  isWorkspaceRoleName,
  iso8601Timestamp,
  parseNamedInterval,
  parseTimestamp,
  unsafeAccessToken,
  unsafeArcgisAuthCode,
  unsafeAttachmentFolder,
  unsafeAttachmentId,
  unsafeEmail,
  unsafeInvitationCodeAlias,
  unsafeInvitationId,
  unsafeMapId,
  unsafeMapLayerId,
  unsafeProjectAlias,
  unsafeProjectRef,
  unsafeSurveyId,
  unsafeSurveyModuleId,
  unsafeTeamAlias,
  unsafeTeamId,
  unsafeTeamRef,
  unsafeUserId,
  unsafeWorkspaceAlias,
  unsafeWorkspaceRef,
  yyyymmdd as yyyymmddFormat,
} from "@cartographerio/types";
import { identity as id } from "lodash";

import { DEFAULT_PAGE_SIZE } from "../../config";
import { QueryParam, queryParam, simpleQueryParam } from "../../routes/base";
import { parseApiServerId } from "../contexts/server";

export const string = simpleQueryParam<string>(id, id);

export function integer(min?: number, max?: number) {
  return simpleQueryParam(
    param => {
      const num = parseInt(param, 10);
      return num < (min ?? num) || num > (max ?? num) ? undefined : num;
    },
    num => `${num}`
  );
}

export const pageIndex = queryParam<number>(
  param =>
    Option.wrap(param)
      .map(str => parseInt(str, 10))
      .filterNot(isNaN)
      .map(num => num - 1)
      .map(num => Math.max(0, num))
      .getOrElse(() => 0),
  index =>
    Option.wrap(index)
      .filterNot(num => num === 0)
      .map(num => num + 1)
      .map(num => `${num}`)
      .getOrUndefined()
);

export const pageSize = queryParam<number>(
  param =>
    Option.wrap(param)
      .map(str => parseInt(str, 10))
      .filterNot(isNaN)
      .map(num => Math.max(1, num))
      .getOrElse(() => DEFAULT_PAGE_SIZE),
  index =>
    Option.wrap(index)
      .filterNot(num => num === DEFAULT_PAGE_SIZE)
      .map(num => `${num}`)
      .getOrUndefined()
);

export const boolean = simpleQueryParam<boolean>(
  param => {
    const p = param.toLowerCase();
    return p === "true" || p === "yes" || p === "t" || p === "y"
      ? true
      : p === "false" || p === "no" || p === "f" || p === "n"
      ? false
      : undefined;
  },
  bool => `${bool}`
);

export const iso8601 = simpleQueryParam(
  iso8601Timestamp,
  formatTimestampIso8601
);

export function union<A extends string>(values: A[]) {
  return simpleQueryParam<A>(
    param =>
      Option.wrap(param)
        .filter(v => (values as string[]).includes(v))
        .map(value => value as A)
        .getOrUndefined(),
    value => value
  );
}

export function order<A extends string>(bys: A[]) {
  return simpleQueryParam<SortOrder<A>>(
    param =>
      Option.wrap(param)
        .map(str => str.toLowerCase().split("-"))
        .filter(arr => arr.length >= 2)
        .nullMap<SortOrder<A>>(([by, dir]) =>
          (bys as string[]).includes(by) && (dir === "asc" || dir === "desc")
            ? `${by as A}-${dir}`
            : null
        )
        .getOrUndefined(),
    order => order
  );
}

export const email = simpleQueryParam(unsafeEmail, id);
export const accessToken = simpleQueryParam(unsafeAccessToken, id);
export const workspaceRef = simpleQueryParam(unsafeWorkspaceRef, id);
export const projectRef = simpleQueryParam(unsafeProjectRef, id);
export const teamRef = simpleQueryParam(unsafeTeamRef, id);
export const workspaceAlias = simpleQueryParam(unsafeWorkspaceAlias, id);
export const projectAlias = simpleQueryParam(unsafeProjectAlias, id);
export const teamAlias = simpleQueryParam(unsafeTeamAlias, id);
export const teamId = simpleQueryParam(unsafeTeamId, id);
export const userId = simpleQueryParam(unsafeUserId, id);
export const invitationId = simpleQueryParam(unsafeInvitationId, id);
export const surveyId = simpleQueryParam(unsafeSurveyId, id);
export const attachmentId = simpleQueryParam(unsafeAttachmentId, id);
export const moduleId = simpleQueryParam(unsafeSurveyModuleId, id);
export const mapId = simpleQueryParam(unsafeMapId, id);
export const layerId = simpleQueryParam(unsafeMapLayerId, id);
export const arcgisAuthCode = simpleQueryParam(unsafeArcgisAuthCode, id);
export const namedInterval = simpleQueryParam(
  parseNamedInterval,
  formatNamedInterval
);
export const memberType = simpleQueryParam(
  val => (isMemberType(val) ? val : undefined),
  id
);

function guardedQueryParam<A extends string>(
  guard: (value: string) => value is A
): QueryParam<A | null> {
  return {
    decode: opt => (opt != null && guard(opt) ? opt : null),
    encode: opt => opt ?? undefined,
  };
}

export const globalRole = guardedQueryParam(isGlobalRoleName);
export const workspaceRole = guardedQueryParam(isWorkspaceRoleName);
export const projectRole = guardedQueryParam(isProjectRoleName);
export const teamRole = guardedQueryParam(isTeamRoleName);
// const qualificationRole = guardedQueryParam(isQualificationRoleName);

export const invitationCodeAlias = simpleQueryParam(
  unsafeInvitationCodeAlias,
  id
);

export const invitationStatus = guardedQueryParam(isInvitationStatus);
export const invitationCodeStatus = guardedQueryParam(isInvitationCodeStatus);

const parseAnyTimestamp = (param: string) =>
  parseTimestamp(param, yyyymmddFormat) ??
  parseTimestamp(param, ddmmyyyyFormat);

export const bboxFilter = simpleQueryParam<BBox4>(
  param =>
    Result.pure(param.split(",").map(Number))
      .guard((bbox): bbox is BBox4 => isBBox4(bbox) && !bbox.some(isNaN))
      .getOrUndefined(),
  value => value.join(",")
);

export type AnyRoleName =
  | GlobalRoleName
  | WorkspaceRoleName
  | ProjectRoleName
  | TeamRoleName
  | QualificationRoleName;

export const isAnyRoleName = (name: string): name is AnyRoleName =>
  GlobalRoleNameEnum.isValue(name) ||
  WorkspaceRoleNameEnum.isValue(name) ||
  ProjectRoleNameEnum.isValue(name) ||
  TeamRoleNameEnum.isValue(name) ||
  QualificationRoleNameEnum.isValue(name);

export const anyRoleName = guardedQueryParam(isAnyRoleName);

export const attachmentFolder = queryParam(
  folder => (folder == null ? undefined : unsafeAttachmentFolder(folder)),
  folder => (folder == null ? undefined : folder.folder)
);

export const apiServerId = queryParam(
  server => (server == null ? undefined : parseApiServerId(server)),
  server => (server == null ? undefined : server)
);

export const mapBase = simpleQueryParam(
  param =>
    Option.wrap(param)
      .map(str => str.toLowerCase())
      .guard(MapBaseEnum.isValue)
      .getOrElse<MapBase>(() => MapBaseEnum.Terrain),
  style => style
);

export const mapInspector = simpleQueryParam(
  param =>
    !param?.toLowerCase().startsWith("no") &&
    !param?.toLowerCase().startsWith("hide"),
  shown => (shown ? "yes" : "no")
);

export const point = simpleQueryParam(
  param => Option.wrap(param).nullMap(parseLatLng).getOrUndefined(),
  p => formatLatLng(p, 6, ",")
);

export const number = simpleQueryParam(
  param => Option.wrap(param).map(parseFloat).filterNot(isNaN).getOrUndefined(),
  number => `${number}`
);

export const fixedPrecisionNumber = (precision: number) =>
  simpleQueryParam(
    param =>
      Option.wrap(param).map(parseFloat).filterNot(isNaN).getOrUndefined(),
    number => number.toFixed(precision)
  );

// A query param in the form `foo=attribute` or `foo=layer.attribute`:
export type MapAttributeQueryParam = [MapLayerId | null, string];

export const mapAttribute = simpleQueryParam<MapAttributeQueryParam>(
  param => {
    const parts = param.split(".");
    switch (parts.length) {
      case 2:
        return [unsafeMapLayerId(parts[0]), parts.slice(1).join(".")];
      case 1:
        return [null, parts[0]];
      default:
        return undefined;
    }
  },
  pair => (pair[0] == null ? pair[1] : `${pair[0]}.${pair[1]}`)
);

export const timestamp = simpleQueryParam<Timestamp>(
  parseAnyTimestamp,
  timestamp => formatTimestamp(timestamp, { format: yyyymmddFormat })
);

export const certificateView = simpleQueryParam<"full" | "public">(val => {
  const lower = val.toLowerCase();
  return lower === "full" ? "full" : lower === "public" ? "public" : undefined;
}, id);

export const arcgisIntegrationList = {
  q: string,
  layer: layerId,
};

export const workspaceList = {
  q: string,
  order: order<WorkspaceSortKey>(["name", "subdomain"]),
  page: pageIndex,
  count: pageSize,
};

export const projectList = {
  q: string,
  order: order<ProjectSortKey>(["name"]),
  page: pageIndex,
  count: pageSize,
};

export const teamList = {
  q: string,
  order: order<TeamSortKey>(["name"]),
  page: pageIndex,
  count: pageSize,
};

export const workspaceTeamList = {
  ...teamList,
  project: projectAlias,
};

export const surveyList = {
  q: string,
  team: teamRef,
  when: namedInterval,
  where: bboxFilter,
  status: union<SurveyStatus>(SurveyStatusEnum.values),
  order: order<SurveySearchKeyV3>([
    "workspace",
    "project",
    "team",
    "description",
    "status",
    "surveyor",
    "timestamp",
    "created",
    "updated",
  ]),
  page: pageIndex,
  count: pageSize,
};

const baseMemberList = {
  q: string,
  order: order<MemberSortKey>([
    "created",
    "email",
    "name",
    "screenname",
    "membertype",
  ]),
  page: pageIndex,
  count: pageSize,
  type: memberType,
};

export const adminMemberList = {
  ...baseMemberList,
  role: globalRole,
  workspace: workspaceAlias,
};

export const workspaceMemberList = {
  ...baseMemberList,
  role: workspaceRole,
  project: projectAlias,
  team: teamAlias,
};

export const projectMemberList = {
  ...baseMemberList,
  role: projectRole,
  team: teamAlias,
};

export const teamMemberList = {
  ...baseMemberList,
  role: teamRole,
};

export const baseInvitationList = {
  q: string,
  order: order<InvitationSortKey>(["created", "email", "name", "screenname"]),
  status: invitationStatus,
  page: pageIndex,
  count: pageSize,
};

export const baseInvitationCodeList = {
  q: string,
  order: order<InvitationCodeSortKey>(["name", "created"]),
  status: invitationCodeStatus,
  page: pageIndex,
  count: pageSize,
};

export const adminInvitationList = {
  ...baseInvitationList,
  role: globalRole,
  workspace: workspaceAlias,
};

export const adminInvitationCodeList = {
  ...baseInvitationCodeList,
  role: globalRole,
  workspace: workspaceAlias,
};

export const workspaceInvitationList = {
  ...baseInvitationList,
  role: workspaceRole,
  project: projectAlias,
  team: teamAlias,
};

export const workspaceInvitationCodeList = {
  ...baseInvitationCodeList,
  role: workspaceRole,
  project: projectAlias,
  team: teamAlias,
};

export const projectInvitationList = {
  ...baseInvitationList,
  role: projectRole,
  team: teamAlias,
};

export const projectInvitationCodeList = {
  ...baseInvitationCodeList,
  role: projectRole,
  team: teamAlias,
};

export const teamInvitationList = {
  ...baseInvitationList,
  role: teamRole,
  project: projectAlias,
};

export const teamInvitationCodeList = {
  ...baseInvitationCodeList,
  role: teamRole,
  project: projectAlias,
};

export const adminRCASetupList = {
  q: string,
  page: pageIndex,
  count: pageSize,
};
