import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectMapSettings,
  TeamId,
  TeamRef,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type TeamEmailSettingsKey = [
  "team",
  "map-settings",
  "v2",
  TeamRef,
  WorkspaceRef | null
];

export function readOrNull(
  apiParams: ApiParams,
  teamRef: TeamRef,
  workspaceRef?: WorkspaceRef
): UseQueryOpts<ProjectMapSettings | null, TeamEmailSettingsKey> {
  return {
    queryKey: ["team", "map-settings", "v2", teamRef, workspaceRef ?? null],
    queryFn: () =>
      endpoints.team.mapSettings.v2
        .readOrNull(apiParams, teamRef, workspaceRef)
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  teamId: TeamId,
  settings: ProjectMapSettings | null
): IO<void> {
  return (
    settings == null
      ? endpoints.team.mapSettings.v2.remove(apiParams, teamId)
      : endpoints.team.mapSettings.v2.save(apiParams, teamId, settings)
  ).tap(_ => queryClient.invalidateQueries(["team", "map-settings"]));
}
