import {
  ProjectId,
  RoleV2,
  TeamId,
  WorkspaceId,
  addRoleV2,
  isGlobalRoleV2,
  parseRoleV2,
  removeRoleV2,
  roleProjectIdV2,
  roleTeamIdV2,
  roleWorkspaceIdV2,
  workspaceActiveV2,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { useCallback } from "react";

interface RolesProps {
  roles: RoleV2[];
  onChange?: (roles: RoleV2[]) => void;
}

export function useRoles(props: RolesProps) {
  const { roles, onChange } = props;

  const hasRole = useCallback(
    (role: RoleV2): boolean =>
      roles.find(otherRole => role === otherRole) !== undefined,
    [roles]
  );

  const getGlobalRole = useCallback(
    (): RoleV2 | null => roles.find(isGlobalRoleV2) ?? null,
    [roles]
  );

  const getWorkspaceRole = useCallback(
    (workspaceId: WorkspaceId): RoleV2 | null =>
      roles.find(role => roleWorkspaceIdV2(role) === workspaceId) ?? null,
    [roles]
  );

  const getProjectRole = useCallback(
    (projectId: ProjectId): RoleV2 | null =>
      roles.find(role => roleProjectIdV2(role) === projectId) ?? null,
    [roles]
  );

  const getTeamRole = useCallback(
    (teamId: TeamId): RoleV2 | null =>
      roles.find(role => roleTeamIdV2(role) === teamId) ?? null,
    [roles]
  );

  const addRole = useCallback(
    (role: RoleV2) => onChange?.(addRoleV2(roles, role, "replace")),
    [onChange, roles]
  );

  const removeRole = useCallback(
    (role: RoleV2) => onChange?.(removeRoleV2(roles, role)),
    [onChange, roles]
  );

  return {
    hasRole,
    addRole,
    removeRole,
    getGlobalRole,
    getWorkspaceRole,
    getProjectRole,
    getTeamRole,
  };
}

export function useWorkspaceRoles(
  graph: WorkspaceGraphV2,
  roles: RoleV2[],
  onChange?: (roles: RoleV2[]) => void
) {
  const rolesOutput = useRoles({ roles, onChange });
  const { getWorkspaceRole, getProjectRole, getTeamRole } = rolesOutput;

  const addRole = useCallback(
    (role: RoleV2) => {
      let newRoles = addRoleV2(roles, role, "replace");
      const parts = parseRoleV2(role);
      if (parts[0] === "T") {
        const workspace = graph.findWorkspaceByTeamId(parts[2]);
        if (workspace != null && getWorkspaceRole(workspace.id) == null) {
          newRoles = addRoleV2(
            newRoles,
            workspaceActiveV2(workspace.id),
            "keepBest"
          );
        }
      } else if (parts[0] === "P") {
        const workspace = graph.findWorkspaceByProjectId(parts[2]);
        if (workspace != null && getWorkspaceRole(workspace.id) == null) {
          newRoles = addRoleV2(
            newRoles,
            workspaceActiveV2(workspace.id),
            "keepBest"
          );
        }
      }
      onChange?.(newRoles);
    },
    [roles, onChange, graph, getWorkspaceRole]
  );

  const removeRole = useCallback(
    (role: RoleV2) => {
      let newRoles = removeRoleV2(roles, role);
      const workspaceId = roleWorkspaceIdV2(role);

      if (workspaceId != null) {
        const projects = graph.findProjectsByWorkspaceId(workspaceId);
        const teams = graph.findTeamsByWorkspaceId(workspaceId);
        const rolesToRemove = [
          ...filterAndMap(projects, ({ id }) => getProjectRole(id)),
          ...filterAndMap(teams, ({ id }) => getTeamRole(id)),
        ];
        newRoles = rolesToRemove.reduce(
          (acc, role) => removeRoleV2(acc, role),
          newRoles
        );
      }
      onChange?.(newRoles);
    },
    [roles, onChange, graph, getProjectRole, getTeamRole]
  );

  return { ...rolesOutput, addRole, removeRole };
}
