import { RcaWorkspaceStatus } from "@cartographerio/types";
import { Icon, chakra } from "@chakra-ui/react";
import { memo } from "react";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

import Link from "../../../components/Link";
import { Column } from "../../../components/SearchResultsList/column";
import { routes } from "../../../routes";

function col(params: Column<RcaWorkspaceStatus, string>) {
  return params;
}

const CheckIcon = memo(function CheckIcon({ done }: { done?: boolean }) {
  return done ? (
    <Icon
      fill="#ccc"
      color="#ccc"
      as={IoMdCheckmark}
      strokeWidth="50"
      w="1.25rem"
      h="1.25rem"
    />
  ) : (
    <Icon as={IoMdClose} strokeWidth="50" w="1.25rem" h="1.25rem" />
  );
});

const workspaceColumn = col({
  title: "Workspace",
  width: "13ch",
  render: record => (
    <Link.Internal to={routes.workspace.settings.url([record.workspaceAlias])}>
      {record.workspaceName}
    </Link.Internal>
  ),
});

const projectColumn = col({
  title: "Project",
  width: "13ch",
  render: record => (
    <Link.Internal
      to={routes.workspace.project.settings.url([
        record.workspaceAlias,
        record.projectAlias,
      ])}
    >
      {record.projectName}
    </Link.Internal>
  ),
});

const surveyorsColumn = col({
  title: "Trained",
  width: "13ch",
  render: record => (
    <Link.Internal
      {...(record.numTrainedProjectSurveyors === record.numProjectSurveyors
        ? { color: "var(--chakra-colors-gray-400)" }
        : { color: "var(--chakra-colors-gray-600)", fontWeight: "bold" })}
      to={routes.workspace.project.member.list.url([
        record.workspaceAlias,
        record.projectAlias,
      ])}
    >
      {record.numTrainedProjectSurveyors} / {record.numProjectSurveyors}
    </Link.Internal>
  ),
});

const protectedColumn = col({
  title: "Protected?",
  width: "5ch",
  render: record => (
    <chakra.span
      {...(record.hasQualificationRequirement
        ? { color: "var(--chakra-colors-gray-400)" }
        : { color: "var(--chakra-colors-gray-600)", fontWeight: "bold" })}
    >
      <CheckIcon done={record.hasQualificationRequirement} />
    </chakra.span>
  ),
});

const ownerColumn = col({
  title: "Owner?",
  width: "5ch",
  render: record => (
    <chakra.span
      {...(record.numWorkspaceOwners > 0 ? {} : { fontWeight: "bold" })}
    >
      <CheckIcon done={record.numWorkspaceOwners > 0} />
    </chakra.span>
  ),
});

const completeColumn = col({
  title: "Complete?",
  width: "5ch",
  render: record => (
    <CheckIcon
      done={
        record.hasQualificationRequirement &&
        record.numTrainedProjectSurveyors === record.numProjectSurveyors &&
        record.numWorkspaceOwners > 0
      }
    />
  ),
});

export const rcaWorkspaceStatusListColumns = [
  workspaceColumn,
  projectColumn,
  surveyorsColumn,
  protectedColumn,
  ownerColumn,
  completeColumn,
];
