import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { ReactElement } from "react";

export interface InvalidValueViewProps {
  hint: string;
}

export default function InvalidValueView(
  props: InvalidValueViewProps
): ReactElement {
  const { hint } = props;

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Unable to display field</AlertTitle>
      <AlertDescription>
        Could not find a {hint} in the survey document
      </AlertDescription>
    </Alert>
  );
}
