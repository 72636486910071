import {
  ArcgisIntegrationSearchOptionsV1,
  PartialParams,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  ArcgisAuthCode,
  ArcgisIntegration,
  ArcgisIntegrationCreate,
  ArcgisIntegrationId,
  ArcgisIntegrationStatus,
  ArcgisIntegrationUpdate,
  SearchResults,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type ArcgisKey =
  | ["integrations", "arcgis"]
  | ["integrations", "arcgis", "read", ArcgisIntegrationId]
  | ["integrations", "arcgis", "readStatus", ArcgisIntegrationId]
  | [
      "integrations",
      "arcgis",
      "search",
      PartialParams<ArcgisIntegrationSearchOptionsV1>
    ];

export function readOrFail(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): UseQueryOpts<ArcgisIntegration, ArcgisKey> {
  return {
    queryKey: ["integrations", "arcgis", "read", integration],
    queryFn: () =>
      endpoints.integration.arcgis.v1
        .readOrFail(apiParams, integration)
        .unsafeRun(),
  };
}

export function readStatusOrFail(
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
): UseQueryOpts<ArcgisIntegrationStatus, ArcgisKey> {
  return {
    queryKey: ["integrations", "arcgis", "readStatus", integration],
    queryFn: () =>
      endpoints.integration.arcgis.v1
        .readStatusOrFail(apiParams, integration)
        .unsafeRun(),
  };
}

export function search(
  apiParams: ApiParams,
  options: PartialParams<ArcgisIntegrationSearchOptionsV1> = {}
): UseQueryOpts<SearchResults<ArcgisIntegrationStatus>, ArcgisKey> {
  return {
    queryKey: ["integrations", "arcgis", "search", options],
    queryFn: () =>
      endpoints.integration.arcgis.v1.search(apiParams, options).unsafeRun(),
  };
}

export function update(
  queryClient: QueryClient,
  apiParams: ApiParams,
  integration: ArcgisIntegrationId,
  update: ArcgisIntegrationUpdate
) {
  return endpoints.integration.arcgis.v1
    .update(apiParams, integration, update)
    .tap(_ => queryClient.invalidateQueries(["integrations", "arcgis"]));
}

export function create(
  queryClient: QueryClient,
  apiParams: ApiParams,
  create: ArcgisIntegrationCreate
) {
  return endpoints.integration.arcgis.v1
    .create(apiParams, create)
    .tap(_ => queryClient.invalidateQueries(["integrations", "arcgis"]));
}

export function loginUrl(apiParams: ApiParams, redirectUri?: string) {
  return endpoints.integration.arcgis.v1.loginUrl(apiParams, redirectUri);
}

export function authorize(
  queryClient: QueryClient,
  apiParams: ApiParams,
  integration: ArcgisIntegrationId,
  code: ArcgisAuthCode,
  redirectUri: string
) {
  return endpoints.integration.arcgis.v1
    .authorize(apiParams, integration, code, redirectUri)
    .tap(_ => queryClient.invalidateQueries(["integrations", "arcgis"]));
}

export function exportOne(
  queryClient: QueryClient,
  apiParams: ApiParams,
  integration: ArcgisIntegrationId
) {
  return endpoints.integration.arcgis.v1
    .exportOne(apiParams, integration)
    .tap(_ => queryClient.invalidateQueries(["integrations", "arcgis"]));
}
