import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  MapSchemaSummary,
  ProjectId,
  SearchResults,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type SchemaKey =
  | ["map"]
  | ["map", "schema", "v1", "search", ProjectId | null];

export function search(
  apiParams: ApiParams,
  project?: ProjectId
): UseQueryOpts<SearchResults<MapSchemaSummary>, SchemaKey> {
  return {
    queryKey: ["map", "schema", "v1", "search", project ?? null],
    queryFn: () =>
      endpoints.map.schema.v1.search(apiParams, project).unsafeRun(),
  };
}
