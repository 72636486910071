import { UserV2 } from "@cartographerio/types";
import { Box, HStack, IconButton } from "@chakra-ui/react";
import { ReactElement } from "react";
import { IoChevronBack } from "react-icons/io5";

import queries from "../../queries";
import ClickToCopy from "../components/ClickToCopy";
import Fieldset from "../components/Fieldset";
import Heading from "../components/Heading";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../hooks/useWorkspaceGraph";
import { BaseUserEditor } from "../pages/base/BaseUserPage";
import { FrontContext } from "./context";

export interface FrontUserEditorProps {
  context: FrontContext;
  defaultUser: UserV2;
  onClose: () => void;
}

export function FrontUserEditor(props: FrontUserEditorProps): ReactElement {
  const { defaultUser, onClose } = props;

  const apiParams = useApiParams();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );
  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));
  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  return (
    <Spaced spacing="8">
      <Heading level="subsection">
        <HStack gap="2">
          <IconButton
            variant="outline"
            size="sm"
            aria-label="Back to User List"
            icon={<IoChevronBack />}
            onClick={onClose}
          />
          <Box>
            {defaultUser.firstName} {defaultUser.lastName}
          </Box>
        </HStack>
      </Heading>

      <Fieldset legend="User ID">
        <ClickToCopy size="sm" value={defaultUser.id} />
      </Fieldset>

      <BaseUserEditor
        defaultUser={defaultUser}
        workspaceGraph={workspaceGraph}
      />
    </Spaced>
  );
}
