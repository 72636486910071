import {
  Form,
  FormState,
  SelectOption,
  allFormMessages,
  formStateDocument,
  resetAction,
} from "@cartographerio/atlas-form";
import {
  SurveyorTeamProvider,
  injectSurveyorTeamFields,
  useFormPermissionsContext,
} from "@cartographerio/atlas-form-context";
import { Schema } from "@cartographerio/atlas-survey";
import { Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  AttachmentId,
  AttachmentUpdate,
  DataLicense,
  Message,
  ProjectV2,
  SurveyId,
  SurveyModuleId,
  SurveyStatusEnum,
  SurveyV2,
  identityToUserRef,
} from "@cartographerio/types";
import { UserWorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  useBoolean,
} from "@chakra-ui/react";
import { isEqual } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

import { PartialSurveyV2, isPartialSurveyV2 } from "../../util";
import Container from "../components/Container";
import JsonView from "../components/JsonView";
import { useCredentialsV2 } from "../contexts/auth";
import { FormContextProvider } from "./context/FormContext";
import FormOptionsModal from "./FormOptionsModal";
import FormTopBar from "./FormTopBar";
import { SaveProps } from "./FormTopBar/FormSaveButton";
import PageView from "./PageView";
import { useFormReducer } from "./useFormReducer";

export interface FormViewProps {
  title: string;
  formSchema: Form;
  surveySchema: Schema;
  creating: boolean;
  defaultSurvey: SurveyV2;
  project: ProjectV2;
  workspaceGraph: UserWorkspaceGraphV2;
  defaultPageIndex?: number;
  module: SurveyModuleId;
  defaultDataLicense: DataLicense | null;
  saveSurvey: (
    survey: SurveyV2,
    props: SaveProps
  ) => IO<Result<Message[], SurveyV2>>;
  unlockSurvey: (
    surveyId: SurveyId,
    props: SaveProps
  ) => IO<Result<Message[], SurveyV2>>;
  copySurvey: () => IO<void>;
  deleteSurvey: () => IO<void>;
  viewOnMapOptions?: SelectOption<string>[];
  onViewOnMap?: (survey: PartialSurveyV2) => void;
  onAttachmentMetadataUpdate?: (
    id: AttachmentId,
    update: AttachmentUpdate
  ) => void;
  onSurveySaved?: (props: SaveProps, survey: PartialSurveyV2) => void;
  onSurveySaveError?: (error: unknown) => void;
  onSurveyDeleted?: () => void;
  onPageIndexChange?: (index: number) => void;
}

export default function FormView(props: FormViewProps): ReactElement {
  const {
    title,
    creating,
    formSchema,
    surveySchema,
    defaultSurvey,
    project,
    workspaceGraph,
    defaultPageIndex,
    module,
    defaultDataLicense,
    saveSurvey,
    unlockSurvey,
    copySurvey,
    deleteSurvey,
    viewOnMapOptions,
    onViewOnMap,
    onAttachmentMetadataUpdate,
    onSurveySaved,
    onSurveySaveError,
    onSurveyDeleted,
    onPageIndexChange,
  } = props;

  const { identity } = useCredentialsV2();

  const identityAsRef = useMemo(() => identityToUserRef(identity), [identity]);

  const {
    hasQualifications,
    canUpdateData,
    canUpdateStatus,
    canUpdateSurveyor,
  } = useFormPermissionsContext();

  const injectedFormSchema = useMemo(
    () =>
      injectSurveyorTeamFields(
        formSchema,
        identity,
        workspaceGraph,
        defaultSurvey,
        canUpdateData
      ),
    [formSchema, identity, workspaceGraph, defaultSurvey, canUpdateData]
  );

  const [formState, formDispatch] = useFormReducer(
    injectedFormSchema,
    surveySchema,
    defaultSurvey
  );

  const resetForm = useCallback(
    (survey: SurveyV2) =>
      formDispatch(
        resetAction({
          document: survey,
          form: formSchema,
          schema: surveySchema,
        })
      ),
    [formDispatch, formSchema, surveySchema]
  );

  const [saving, setSaving] = useState(false);
  const [autoSaving, setAutoSaving] = useLocalStorage<boolean>(
    "FormAutoSaving",
    true
  );
  const [helpVisible, setHelpVisible] = useLocalStorage<boolean>(
    "FormHelpVisible",
    true
  );
  const [debugInfoVisible, setDebugInfoVisible] = useState(false);

  const [currentPageIndex, _setCurrentPageIndex] = useState(
    defaultPageIndex == null
      ? 0
      : Math.max(Math.min(defaultPageIndex, formState.pageStates.length), 0)
  );
  const [enabledPageIndex, setEnabledPageIndex] = useState(currentPageIndex);

  const survey = formStateDocument<PartialSurveyV2>(
    formState,
    isPartialSurveyV2,
    "PartialSurveyV2"
  ).getOrThrow();

  const { pageStates } = formState;

  const enabledPages = useMemo(
    () =>
      pageStates
        .map((_, index) => index)
        .filter((pageIndex: number) => pageStates[pageIndex].enabled === true),
    [pageStates]
  );

  const currentPageKey = `page${currentPageIndex}`;

  const currentPageState = useMemo(
    () => pageStates[currentPageIndex],
    [pageStates, currentPageIndex]
  );

  // Keep track of the last saved version of the survey:
  const [lastSavedSurvey, setLastSavedSurvey] = useState<PartialSurveyV2>(
    () => survey
  );

  const handleSave = useCallback(
    (props: SaveProps) => {
      const toSave = { ...survey, status: props.status };

      IO.wrap(() => setSaving(true))
        .andThen(saveSurvey(toSave as SurveyV2, props))
        .flatMap(IO.fromResult)
        .tap(survey => resetForm(survey))
        .tap(survey => setLastSavedSurvey(survey))
        .tap(survey =>
          onSurveySaved?.(
            { ...props, page: props.page ?? currentPageIndex },
            survey
          )
        )
        .recover(error => onSurveySaveError?.(error))
        .cleanup(() => setSaving(false))
        .unsafeRun();
    },
    [
      survey,
      saveSurvey,
      resetForm,
      onSurveySaved,
      currentPageIndex,
      onSurveySaveError,
    ]
  );

  const handleUnlock = useCallback(
    (props: SaveProps) => {
      return unlockSurvey(survey.id, props)
        .tap(result => result.forEach(survey => resetForm(survey)))
        .unsafeRun();
    },
    [resetForm, survey.id, unlockSurvey]
  );

  const handlePageChange = useCallback(
    (newIndex: number) => {
      window.scrollTo({ top: 0 });

      // If survey has been updated since last save, auto-save it:
      if (
        formState.errorCount === 0 &&
        autoSaving &&
        !isEqual(lastSavedSurvey, survey)
      ) {
        handleSave({
          status: survey.status,
          redirect: false,
          sendEmailNotifications: false,
          page: newIndex,
        });
      }

      _setCurrentPageIndex(newIndex);
      setEnabledPageIndex(enabledPages.indexOf(newIndex));
      onPageIndexChange?.(newIndex);
    },
    [
      autoSaving,
      enabledPages,
      formState.errorCount,
      handleSave,
      lastSavedSurvey,
      onPageIndexChange,
      survey,
    ]
  );

  const prevPage: number | undefined = useMemo(
    () =>
      enabledPages[enabledPageIndex] !== 0
        ? enabledPages[enabledPageIndex - 1]
        : undefined,
    [enabledPageIndex, enabledPages]
  );

  const nextPage: number | undefined = useMemo(
    () =>
      enabledPageIndex < enabledPages.length
        ? enabledPages[enabledPageIndex + 1]
        : undefined,
    [enabledPageIndex, enabledPages]
  );

  const handlePrevPage = useCallback(() => {
    prevPage != null && handlePageChange(prevPage);
  }, [handlePageChange, prevPage]);

  const handleNextPage = useCallback(() => {
    nextPage != null && handlePageChange(nextPage);
  }, [handlePageChange, nextPage]);

  const approved = survey.status === SurveyStatusEnum.Approved;
  const rejected = survey.status === SurveyStatusEnum.Rejected;

  const editable = canUpdateData && !saving;

  const [optionsOpen, { on: handleOptionsOpen, off: handleOptionsClose }] =
    useBoolean(false);

  const handleCopy = useCallback(() => {
    copySurvey().cleanup(handleOptionsClose).unsafeRun();
  }, [copySurvey, handleOptionsClose]);

  const handleViewOnMap = useMemo(
    () => (onViewOnMap == null ? undefined : () => onViewOnMap(survey)),
    [onViewOnMap, survey]
  );

  return (
    <SurveyorTeamProvider
      initialSurveyor={defaultSurvey.surveyor}
      initialTeamId={defaultSurvey.teamId ?? null}
      primarySurveyor={survey.surveyor ?? null}
      primaryTeamId={survey.teamId ?? null}
      project={project}
      formDispatch={formDispatch}
      workspaceGraph={workspaceGraph}
      currentUser={identityAsRef}
    >
      <FormContextProvider
        formDispatch={formDispatch}
        onAttachmentMetadataUpdate={onAttachmentMetadataUpdate}
        creating={creating}
        editable={editable}
        debugInfoVisible={debugInfoVisible}
        setDebugInfoVisible={setDebugInfoVisible}
        helpVisible={helpVisible ?? true}
        setHelpVisible={setHelpVisible}
        project={project}
        workspaceGraph={workspaceGraph}
        module={module}
        survey={survey.id}
        defaultDataLicense={defaultDataLicense}
      >
        <FormOptionsModal
          isOpen={optionsOpen}
          onClose={handleOptionsClose}
          status={survey.status}
          helpVisible={helpVisible ?? true}
          onHelpVisibleChange={setHelpVisible}
          debugInfoVisible={debugInfoVisible}
          onDebugInfoVisibleChange={setDebugInfoVisible}
          autoSaving={autoSaving}
          setAutoSaving={setAutoSaving}
          deleteSurvey={deleteSurvey}
          onSurveyDeleted={onSurveyDeleted}
        />

        <Container sticky={true}>
          <FormTopBar
            title={title}
            status={survey.status}
            formState={formState}
            saving={saving}
            pageStates={pageStates}
            currentPageIndex={currentPageIndex}
            currentPageState={currentPageState}
            viewOnMapOptions={viewOnMapOptions}
            onPrevPage={handlePrevPage}
            onNextPage={handleNextPage}
            onPageChange={handlePageChange}
            onOptionsClick={handleOptionsOpen}
            onSave={handleSave}
            onUnlock={handleUnlock}
            onCopy={handleCopy}
            onViewOnMap={handleViewOnMap}
          />
        </Container>

        <Container>
          {!canUpdateData && (
            <Alert status="warning" my="4" rounded="md">
              <AlertIcon />
              <AlertDescription>
                {approved
                  ? canUpdateStatus
                    ? "You can't edit this survey because it has been approved. Unapprove it to continue."
                    : "You can't edit this survey because it has been approved."
                  : rejected
                  ? canUpdateStatus
                    ? "You can't edit this survey because it has been rejected. Unreject it to continue."
                    : "You can't edit this survey because it has been rejected."
                  : hasQualifications
                  ? defaultSurvey.surveyor.userId === identity.userId
                    ? "You can't edit this survey."
                    : "You can't edit this survey because it belongs to another user."
                  : "You can't edit this survey because you don't have the required active qualifications."}
              </AlertDescription>
            </Alert>
          )}

          <PageView
            canUpdateSurveyor={canUpdateSurveyor}
            pageKey={currentPageKey}
            pageState={currentPageState}
            prevPage={prevPage}
            nextPage={nextPage}
            onPrevClick={handlePrevPage}
            onNextClick={handleNextPage}
            // onDoneClick={handleSave}
          />

          {debugInfoVisible && <FormDebuggingInfo formState={formState} />}
        </Container>
      </FormContextProvider>
    </SurveyorTeamProvider>
  );
}

interface FormDebuggingInfoProps {
  formState: FormState;
}

function FormDebuggingInfo(props: FormDebuggingInfoProps): ReactElement {
  const { formState } = props;

  return (
    <>
      <JsonView label="Validation Errors" value={allFormMessages(formState)} />
      <JsonView label="Survey Document" value={formState.document} />
    </>
  );
}
