import {
  ButtonGroup,
  Flex,
  LinkBox,
  LinkOverlay,
  SystemProps,
  Td,
  Tr,
  theme,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { Link } from "react-router-dom";

import Checkbox from "../Checkbox";
import { ActionsColumn, Column, defaultActionsCellProps } from "./column";

export interface SearchListRowProps<A, O extends string> {
  item: A;
  selected: boolean;
  selectable: boolean;
  onSelectItem?: (item: A, selected: boolean) => void;
  columns: Column<A, O>[];
  actions?: ActionsColumn<A>;
  rowProps: SystemProps;
  cellProps: SystemProps[];
  rowIndex: number;
  itemLink?: (item: A) => string | null;
}

const hoverStyle: SystemProps = { bg: "gray.50", cursor: "pointer" };
const selectedHoverStyle: SystemProps = { bg: "blue.200", cursor: "pointer" };

export default function SearchListRow<A, O extends string>(
  props: SearchListRowProps<A, O>
): ReactElement {
  const {
    item,
    selected,
    selectable,
    onSelectItem,
    columns,
    actions,
    rowProps,
    cellProps,
    rowIndex,
    itemLink: itemLinkFunc,
  } = props;

  const itemLink = useMemo(
    () => itemLinkFunc?.(item) ?? undefined,
    [item, itemLinkFunc]
  );

  return (
    <Tr
      {...rowProps}
      _hover={selected ? selectedHoverStyle : hoverStyle}
      bg={selected ? "blue.100" : rowProps.bg}
    >
      {selectable && (
        <SelectionCell
          item={item}
          selected={selected}
          itemLink={itemLink}
          onSelectItem={onSelectItem}
          rowIndex={rowIndex}
        />
      )}

      {columns.map((column, colIndex) => (
        <DataCell
          key={colIndex}
          item={item}
          column={column}
          cellProps={cellProps[colIndex]}
          itemLink={itemLink}
          rowIndex={rowIndex}
          colIndex={colIndex}
        />
      ))}

      {actions != null && (
        <ActionsCell
          item={item}
          column={actions}
          itemLink={itemLink}
          rowIndex={rowIndex}
        />
      )}
    </Tr>
  );
}

interface SelectionCellProps<A> {
  item: A;
  selected: boolean;
  itemLink?: string;
  onSelectItem?: (value: A, selected: boolean) => void;
  rowIndex: number;
}

function SelectionCell<A>(props: SelectionCellProps<A>) {
  const { item, selected, itemLink, onSelectItem, rowIndex } = props;

  return (
    <Td
      position="relative"
      gridRow={rowIndex}
      w={["100%", "100%", "fit-content"]}
      display={["block", "block", "table-cell"]}
      minH={["unset", "unset", "12"]}
    >
      <LinkBox>
        {itemLink && <LinkOverlay as={Link} to={itemLink} />}
        <Checkbox
          zIndex={theme.zIndices.sticky - 1}
          value={selected}
          onChange={newSelected => onSelectItem?.(item, newSelected)}
        />
      </LinkBox>
    </Td>
  );
}

interface DataCellProps<A, O extends string> {
  item: A;
  column: Column<A, O>;
  cellProps: SystemProps;
  itemLink?: string;
  rowIndex: number;
  colIndex: number;
}

function DataCell<A, O extends string>(props: DataCellProps<A, O>) {
  const { item, column, cellProps, itemLink, rowIndex, colIndex } = props;
  return (
    <Td
      key={colIndex}
      position="relative"
      {...cellProps}
      {...(column.cellProps ?? {})}
      gridRow={rowIndex}
    >
      <LinkBox>
        {itemLink && <LinkOverlay as={Link} to={itemLink} />}
        {column.render(item, rowIndex - 1)}
      </LinkBox>
    </Td>
  );
}

interface ActionsCellProps<A> {
  item: A;
  column: ActionsColumn<A>;
  itemLink?: string;
  rowIndex: number;
}

function ActionsCell<A>(props: ActionsCellProps<A>) {
  const { item, column, itemLink, rowIndex } = props;

  return (
    <Td {...defaultActionsCellProps} {...column.cellProps} gridRow={rowIndex}>
      <LinkBox>
        {itemLink && <LinkOverlay as={Link} to={itemLink} />}
        <Flex justifyContent="end">
          <ButtonGroup spacing="2" size="sm">
            {column.renderButtons(item)}
          </ButtonGroup>
        </Flex>
      </LinkBox>
    </Td>
  );
}
