import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export interface CardTitleProps extends BoxProps {
  children: ReactNode;
}

export default function CardTitle(props: CardTitleProps) {
  const { children, ...rest } = props;

  return (
    <Box
      px="4"
      py="3"
      fontWeight="bold"
      borderBottomWidth={1}
      borderBottomColor="gray.50"
      {...rest}
    >
      {children}
    </Box>
  );
}
