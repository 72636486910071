import {
  Box,
  BoxProps,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo, useState } from "react";

import LoadingPlaceholder from "./LoadingPlaceholder";

interface ReformModalProps extends Omit<BoxProps, "children"> {
  display?: BoxProps["display"];
  url: string;
  prepopulate?: Record<string, string | null> | null;
  embedHeight?: BoxProps["height"];
  children: (onOpen: () => void) => ReactNode;
}

export default function ReformModal(props: ReformModalProps): ReactElement {
  const {
    display = "block",
    url,
    prepopulate,
    embedHeight,
    children,
    ...rest
  } = props;

  const iframeSrc = useMemo(
    () =>
      prepopulate != null
        ? `${url}?` +
          Object.entries(prepopulate)
            .filter(([_, value]) => value != null)
            .map(([name, value]) => `${name}=${value}`)
            .join("&")
        : url,
    [prepopulate, url]
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loaded, setLoaded] = useState(false);

  return (
    <Box display={display} {...rest}>
      {children(onOpen)}
      <Modal size="lg" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position="relative">
          <ModalCloseButton />
          {!loaded && (
            <Flex
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              justify="center"
              alignItems="center"
            >
              <LoadingPlaceholder />
            </Flex>
          )}
          <chakra.iframe
            src={iframeSrc}
            name={iframeSrc}
            height={embedHeight}
            onLoad={() => setLoaded(true)}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
}
