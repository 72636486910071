import { isString } from "@cartographerio/guard";
import { envvar } from "@cartographerio/util";
import * as Fathom from "fathom-client";
import { ReactElement, ReactNode, useEffect } from "react";
import { createPath, useLocation } from "react-router-dom";

import { isProductionEnv } from "../../env";

const UUID_REGEX =
  /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/gi;

export interface FathomAnalyticsProps {
  siteId?: string;
  children: ReactNode;
}

export default function FathomAnalyticsProvider(
  props: FathomAnalyticsProps
): ReactElement {
  const { siteId = envvar("REACT_APP_FATHOM_SITE_ID"), children } = props;

  useEffect(() => Fathom.load(siteId), [siteId]);

  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    const url = createPath({
      pathname: isString(pathname)
        ? pathname.replace(UUID_REGEX, "__ID__")
        : undefined,
      search,
      hash,
    });

    if (isProductionEnv) {
      Fathom.trackPageview({ url });
    } else {
      console.info("PAGEVIEW", url);
    }
  }, [pathname, hash, search]);

  return <>{children}</>;
}
