import { SelectOption } from "@cartographerio/atlas-form";
import {
  NamedInterval,
  OpenInterval,
  TeamId,
  plainDateToDdmmyyyy,
} from "@cartographerio/types";
import {
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { useOnClickOutside } from "usehooks-ts";

import Calendar from "../../components/Calendar";
import LinkButton from "../../components/LinkButton";
import Select from "../../components/Select";
import { useVolatileState } from "../../hooks/useVolatileState";
import {
  useFilterContext,
  useProjectTeamContextGraph,
} from "../AtlasMapContext";
import { useMapSettingsContext } from "../AtlasMapContext/MapSettingsContext";
import { useProjectTeamContext } from "../AtlasMapContext/ProjectTeamContext";
import InspectorButton from "./InspectorButton";
import InspectorMenuItem from "./InspectorMenuItem";
import InspectorPanel, { InspectorPanelProps } from "./InspectorPanel";
import InspectorSection from "./InspectorSection";

const namedIntervalOptions: { label: string; value: NamedInterval | null }[] = [
  { label: "All", value: null },
  { label: "Week", value: { type: "week" } },
  { label: "Month", value: { type: "month" } },
  { label: "Year", value: { type: "year" } },
  { label: "Custom", value: { type: "custom", from: null, to: null } },
];

export interface FilterPanelProps extends InspectorPanelProps {
  onHideInspectorClick: () => void;
}

export default function FilterPanel(props: FilterPanelProps): ReactElement {
  const { onHideInspectorClick, ...rest } = props;

  const { team, setTeam, hasTeams, namedInterval, setNamedInterval } =
    useFilterContext();
  const { zoomToTeam: zoomToTeam } = useMapSettingsContext();

  const { project } = useProjectTeamContext();
  const { findTeamsByProjectId } = useProjectTeamContextGraph();

  const teams = useMemo(
    () => findTeamsByProjectId(project.id),
    [findTeamsByProjectId, project]
  );

  const teamOptions = useMemo<SelectOption<TeamId>[]>(
    () =>
      teams != null
        ? teams.map(team => ({ label: team.name, value: team.id }))
        : [],
    [teams]
  );

  const handleTeamChange = useCallback(
    (teamId: TeamId | null) => {
      setTeam(teamId);
      zoomToTeam(teamId);
    },
    [zoomToTeam, setTeam]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  const intervalLabel = useMemo(
    () =>
      namedInterval?.type !== "custom"
        ? ""
        : [namedInterval.from, namedInterval.to]
            .map(plainDate =>
              plainDate != null ? plainDateToDdmmyyyy(plainDate) : "…"
            )
            .join(" - "),
    [namedInterval]
  );

  return (
    <InspectorPanel overflow="visible" {...rest}>
      <InspectorSection
        title="Date Filter"
        right={
          <InspectorButton
            as={IoMdClose}
            ariaLabel="Hide Inspector"
            onClick={() => onHideInspectorClick()}
          />
        }
        mt="2"
      >
        <InspectorMenuItem>
          <HStack
            w="100%"
            flexDirection="row"
            justifyContent="space-between"
            spacing="0"
          >
            {namedIntervalOptions.map(({ label, value }) => (
              <FilterButton
                key={label}
                label={label}
                selected={namedInterval?.type === value?.type}
                onClick={() => {
                  setNamedInterval(value);
                  if (value?.type === "custom") {
                    onOpen();
                  }
                }}
              />
            ))}
          </HStack>
        </InspectorMenuItem>

        {namedInterval?.type === "custom" && (
          <Flex justify="center" w="100%" mt="2">
            <Button variant="mapInspector" onClick={onOpen}>
              {intervalLabel}
            </Button>
          </Flex>
        )}
      </InspectorSection>
      {hasTeams && teamOptions.length > 0 && (
        <InspectorSection title="Team Filter" mt="1">
          <InspectorMenuItem>
            <Select.Nullable
              value={team}
              onChange={handleTeamChange}
              options={teamOptions}
              placeholder="All Teams"
              size="xs"
            />
          </InspectorMenuItem>
        </InspectorSection>
      )}
      {namedInterval?.type === "custom" && (
        <CustomIntervalModal
          value={namedInterval}
          isOpen={isOpen}
          onClose={onClose}
          onChange={interval =>
            setNamedInterval({ ...interval, type: "custom" })
          }
        />
      )}
    </InspectorPanel>
  );
}

interface FilterButtonProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

function FilterButton(props: FilterButtonProps) {
  const { label, selected, onClick } = props;
  return (
    <LinkButton
      fontSize="xs"
      rounded="md"
      fontWeight={selected ? "bold" : "normal"}
      onClick={onClick}
    >
      {label}
    </LinkButton>
  );
}

interface CustomIntervalModalProps {
  value: OpenInterval;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (interval: OpenInterval) => void;
}

function CustomIntervalModal(props: CustomIntervalModalProps): ReactElement {
  const { value, isOpen, onClose, onChange } = props;

  const contentRef = useRef<HTMLElement | null>(null);

  const [openInterval, setOpenInterval] = useVolatileState(
    useCallback(() => value, [value])
  );

  useOnClickOutside(contentRef, onClose);

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent ref={contentRef} boxShadow="lg" width="fit-content">
        <ModalBody pt="5">
          <Calendar.Interval value={openInterval} onChange={setOpenInterval} />
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose();
              onChange?.(openInterval);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
