import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function ShortSurveyUrlPage(
  props: RouteProps<typeof routes.short.survey>
): ReactElement {
  const {
    path: { surveyId },
    query,
  } = props;

  const apiParams = useApiParams();

  const survey = useSuspenseQueryData(
    queries.survey.v3.readOrFail(apiParams, surveyId)
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, survey.projectId)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  return (
    <Navigate
      to={routes.workspace.project.survey.view.url(
        [workspace.alias, project.alias, survey.moduleId, survey.id],
        query
      )}
      replace={true}
    />
  );
}
