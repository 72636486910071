import { SelectOption } from "@cartographerio/atlas-form";
import {
  SearchResultsFormat,
  SortOrder,
  TeamSortKey,
  endpoints,
} from "@cartographerio/client";
import {
  ProjectAlias,
  TeamId,
  TeamRoleName,
  TeamV2,
  UserId,
  WorkspaceRef,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../queries";
import { AnyPathPart, AnyQueryParams } from "../../../routes/base";
import {
  Route,
  RouteQueryProps,
  RouteQueryUpdate,
} from "../../../routes/Route";
import recordWithId from "../../../util/recordWithId";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import ButtonLink from "../ButtonLink";
import DownloadMenu from "../DownloadMenu";
import ResetFiltersAlert from "../ResetFiltersAlert";
import SearchField from "../SearchField";
import SearchResultsList from "../SearchResultsList";
import Select from "../Select";
import Spaced from "../Spaced";
import { teamActions, teamColumns } from "./column";

interface TeamListProps<R extends Route<AnyPathPart[], AnyQueryParams>> {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<TeamSortKey>;
  workspace: WorkspaceRef;
  project?: ProjectAlias;
  projectOptions?: SelectOption<ProjectAlias>[] | null;
  user?: UserId | null;
  role?: TeamRoleName | null;
  route: R;
  query: RouteQueryProps<R>;
  updateQuery: RouteQueryUpdate<R>;
  addLink?: string;
  itemLink?: (team: TeamV2) => string;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<TeamSortKey>) => void;
  onDeleteClick?: (team: TeamV2) => void;
  onProjectChange?: (project: ProjectAlias | null) => void;
}

export default function TeamList<
  R extends Route<AnyPathPart[], AnyQueryParams>
>(props: TeamListProps<R>): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order = "name-asc",
    workspace,
    project,
    projectOptions,
    user,
    role,
    route,
    query,
    updateQuery,
    addLink,
    itemLink,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
    onDeleteClick,
    onProjectChange,
  } = props;

  const apiParams = useApiParams();

  const { data, error } = useQuery(
    queries.team.v2.search(apiParams, {
      workspace,
      project,
      user,
      role,
      q: searchTerm,
      order,
      skip: page * count,
      limit: count,
    })
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.team.v2.searchUrl({
          workspace,
          project,
          user,
          role,
          q: searchTerm,
          order,
          format,
        })
      ),
    [formatApiUrl, order, project, role, searchTerm, user, workspace]
  );

  const actions = useMemo(
    () => teamActions({ workspace, onDeleteClick }),
    [onDeleteClick, workspace]
  );

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  const graph = useCurrentWorkspaceGraph();

  const columns = useMemo(() => teamColumns(graph), [graph]);

  return (
    <Spaced spacing="4">
      <HStack w="100%" justify="stretch">
        <SearchField
          defaultValue={searchTerm ?? ""}
          onChange={handleSearchChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        {projectOptions != null && (
          <Select.Nullable
            value={project ?? null}
            placeholder="All Projects"
            options={projectOptions}
            onChange={onProjectChange}
            w="fit-content"
          />
        )}
        <DownloadMenu downloadUrl={downloadUrl} />
        {addLink != null && (
          <ButtonLink.Internal colorScheme="blue" to={addLink}>
            Add
          </ButtonLink.Internal>
        )}
      </HStack>

      <ResetFiltersAlert
        route={route}
        query={query}
        updateQuery={updateQuery}
      />

      <SearchResultsList<TeamV2, TeamSortKey, TeamId>
        results={data}
        error={error}
        columns={columns}
        actions={actions}
        page={page}
        count={count}
        itemLink={itemLink}
        onPageChange={onPageChange}
        order={order}
        onOrderChange={onOrderChange}
        itemKey={recordWithId}
      />
    </Spaced>
  );
}
