import { SystemProps, chakra } from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

// Stolen from chakra-ui: packages/layout/src/stack.utils.tsx
const selector = "& > *:not(style) ~ *:not(style)";

export interface SpacedProps extends SystemProps {
  children: ReactNode;
  spacing?: SystemProps["margin"];
}

export default function Spaced(props: SpacedProps): ReactElement {
  const { children, spacing = "4", ...rest } = props;

  const className = `spaced-${spacing}`;

  const styles = useMemo(
    () => ({ [selector]: { marginTop: spacing } }),
    [spacing]
  );

  return (
    <chakra.div className={className} __css={styles} {...rest}>
      {children}
    </chakra.div>
  );
}
