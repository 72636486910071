import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function ShortWorkspaceUrlPage(
  props: RouteProps<typeof routes.short.workspace>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  return (
    <Navigate
      to={routes.workspace.home.url([workspace.alias], query)}
      replace={true}
    />
  );
}
