import { Message, NamedEmail, unsafeEmail } from "@cartographerio/types";
import { SimpleGrid } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { namedEmailErrorKeys } from "../../schema/emailSettings";
import { splitMessages } from "../../schema/rule/errors";
import MessageFormControl from "./MessageFormControl";
import TextField from "./TextField";

interface NamedEmailFieldProps {
  value?: NamedEmail;
  onChange?: (namedEmail: NamedEmail) => void;
  messages?: Message[];
  disabled?: boolean;
}

export default function NamedEmailField(
  props: NamedEmailFieldProps
): ReactElement {
  const { value, onChange, messages, disabled } = props;

  const errors = useMemo(
    () => splitMessages(messages, namedEmailErrorKeys),
    [messages]
  );

  return (
    <SimpleGrid gridTemplateColumns="40% 1fr" gap="2" w="100%">
      <MessageFormControl messages={errors.name}>
        <TextField.String
          value={value?.name}
          placeholder="Name"
          onChange={name =>
            onChange?.({ ...(value ?? { email: unsafeEmail("") }), name })
          }
          disabled={disabled}
        />
      </MessageFormControl>
      <MessageFormControl messages={errors.email}>
        <TextField.String
          value={value?.email}
          placeholder="Email"
          onChange={email =>
            onChange?.({
              ...(value ?? { name: "" }),
              email: unsafeEmail(email),
            })
          }
          disabled={disabled}
        />
      </MessageFormControl>
    </SimpleGrid>
  );
}
