import { checks } from "@cartographerio/permission";
import {
  ArcgisExportResult,
  ArcgisIntegrationId,
  ArcgisIntegrationUpdate,
  ProjectRef,
  WorkspaceRef,
  ddmmyyyy,
  formatTimestamp,
  nowTimestamp,
  timestampEpoch,
} from "@cartographerio/types";
import { findAndMap } from "@cartographerio/util";
import { Box, FormControl, HStack, chakra, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { IoIosKey } from "react-icons/io";
import { IoPlaySharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { arcgisUpdateKeys, arcgisUpdateRule } from "../../../../schema/arcgis";
import { splitMessages } from "../../../../schema/rule/errors";
import { absoluteRouteUrl } from "../../../../util";
import { useIOErrorAlert } from "../../../components/Alert";
import BackButtonHeading from "../../../components/BackButtonHeading";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import FormLabel from "../../../components/FormLabel";
import Heading from "../../../components/Heading";
import JsonView from "../../../components/JsonView";
import MessageFormControl from "../../../components/MessageFormControl";
import NamedEmailField from "../../../components/NamedEmailField";
import PageTopBar from "../../../components/PageTopBar";
import Panel from "../../../components/Panel";
import Spaced from "../../../components/Spaced";
import TextField from "../../../components/TextField";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useVisibleMaps } from "../../../hooks/useVisibleMaps";
import { routes } from "../../../routes";
import ProjectPageHeader from "./ProjectPageHeader";

export function redirectUrl(
  workspaceRef: WorkspaceRef,
  projectRef: ProjectRef,
  integration: ArcgisIntegrationId
): string {
  return absoluteRouteUrl(
    routes.workspace.project.integrations.arcgis.authorize.url([
      workspaceRef,
      projectRef,
      integration,
    ]),
    false
  );
}

const exportStatusLabels: Record<ArcgisExportResult["type"], string> = {
  Failed: "Failed",
  Skipped: "Skipped",
  Complete: "Complete",
};

export default function ArcgisIntegrationPage(
  props: RouteProps<typeof routes.workspace.project.integrations.arcgis.update>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, arcgisIntegrationId },
  } = props;

  const queryClient = useQueryClient();
  const apiParams = useApiParams();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  useRequirePermissionRedirect(checks.project.viewSettings(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  usePageTitle(`ArcGIS Integration - ${project.name} - ${workspace.name}`);

  const {
    integration,
    authorization,
    export: exportStatus,
  } = useSuspenseQueryData(
    queries.integration.arcgis.v1.readStatusOrFail(
      apiParams,
      arcgisIntegrationId
    )
  );

  const authorizationExpired = useMemo(
    () =>
      authorization?.lastAuthorizationExpires != null &&
      timestampEpoch(authorization.lastAuthorizationExpires) <
        timestampEpoch(nowTimestamp()),
    [authorization?.lastAuthorizationExpires]
  );

  const [value, setValue] = useState(
    (): ArcgisIntegrationUpdate => ({
      name: integration.name,
      enableScheduledExports: integration.enableScheduledExports,
      notificationEmail: integration.notificationEmail,
    })
  );

  const messages = useMemo(() => arcgisUpdateRule(value), [value]);

  const errors = useMemo(
    () => splitMessages(messages, arcgisUpdateKeys),
    [messages]
  );

  const maps = useVisibleMaps(project);
  const layerName = useMemo(
    () =>
      findAndMap(maps, schema =>
        findAndMap(schema.layers, layer =>
          layer.layerId === integration.layerId
            ? `${schema.title} - ${layer.title}`
            : null
        )
      ),
    [integration.layerId, maps]
  );

  const handleSave = useCallback(
    () =>
      queries.integration.arcgis.v1
        .update(queryClient, apiParams, integration.id, value)
        .tap(() =>
          toast({ status: "success", description: "Integration updated!" })
        )
        .tap(() =>
          navigate(
            routes.workspace.project.integrations.arcgis.update.url([
              workspace.alias,
              project.alias,
              integration.id,
            ])
          )
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [
      apiParams,
      errorAlert,
      integration.id,
      navigate,
      project.alias,
      queryClient,
      toast,
      value,
      workspace.alias,
    ]
  );

  const handleAuthorize = useCallback(
    () =>
      queries.integration.arcgis.v1
        .loginUrl(
          apiParams,
          redirectUrl(workspace.alias, project.alias, integration.id)
        )
        .tap(url => (window.location.href = url))
        .unsafeRun(),
    [apiParams, integration.id, project.alias, workspace.alias]
  );

  const handleExport = useCallback(
    () =>
      queries.integration.arcgis.v1
        .exportOne(queryClient, apiParams, integration.id)
        .tap(() =>
          toast({
            status: "success",
            title: "Export Started",
            description: "We'll email you when it's complete.",
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, integration.id, queryClient, toast]
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="integrations"
        integration="arcgis"
        arcgisIntegration={integration}
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="integrations"
      />
      <Container width="wide">
        <Spaced spacing="8">
          <Spaced spacing="4">
            <BackButtonHeading
              to={routes.workspace.project.integrations.arcgis.list.url([
                workspace.alias,
                project.alias,
              ])}
            >
              {value.name}
            </BackButtonHeading>
            <MessageFormControl label="Name" messages={errors.name}>
              <TextField.String
                value={value.name}
                onChange={name => setValue({ ...value, name })}
              />
            </MessageFormControl>
            <HStack>
              <FormControl>
                <FormLabel text="Source Layer on Cartographer" />
                <TextField.String
                  value={layerName ?? integration.layerId}
                  disabled={true}
                />
              </FormControl>
              <FormControl>
                <FormLabel text="Target Layer on ArcGIS" />
                <TextField.String
                  value={integration.arcgisLayerUrl}
                  disabled={true}
                />
              </FormControl>
            </HStack>
            <Spaced spacing="2">
              <FormLabel text="Export Options" />
              <Checkbox
                checkboxLabel="Export simplified (point) geometry only"
                value={integration.exportSimplifiedGeometry}
                disabled={true}
              />
              <Checkbox
                checkboxLabel="Automatically schedule exports at 3am every morning"
                value={value.enableScheduledExports}
                onChange={enableScheduledExports =>
                  setValue({ ...value, enableScheduledExports })
                }
              />
            </Spaced>
            <MessageFormControl
              label="Notification Email"
              messages={errors.notificationEmail}
            >
              <NamedEmailField
                value={value.notificationEmail ?? undefined}
                onChange={notificationEmail =>
                  setValue({
                    ...value,
                    notificationEmail:
                      notificationEmail.name.length === 0 &&
                      notificationEmail.email.length === 0
                        ? null
                        : notificationEmail,
                  })
                }
              />
            </MessageFormControl>
            <Button
              colorScheme="blue"
              label="Save"
              onClick={handleSave}
              disabled={messages.length > 0}
            />
          </Spaced>
          <Panel spacing="4">
            <Heading level="subsubsection">Authorization Status</Heading>
            {authorization?.lastAuthorizationExpires == null ? (
              <chakra.em>Not setup yet</chakra.em>
            ) : (
              <>
                {authorization.lastAuthorizedAs == null ? (
                  <Box>Not Authorized</Box>
                ) : (
                  <Box>
                    Authorized by{" "}
                    <strong>{authorization.lastAuthorizedAs}</strong>
                  </Box>
                )}
                {authorizationExpired ? (
                  <Box>
                    Expired{" "}
                    <strong>
                      {formatTimestamp(authorization.lastAuthorizationExpires, {
                        format: ddmmyyyy,
                      })}
                    </strong>
                  </Box>
                ) : (
                  <Box>
                    Expires{" "}
                    <strong>
                      {formatTimestamp(authorization.lastAuthorizationExpires, {
                        format: ddmmyyyy,
                      })}
                    </strong>
                  </Box>
                )}
              </>
            )}
            <Button
              colorScheme="blue"
              leftIcon={<IoIosKey />}
              label="(Re)Authorise with ArcGIS Online"
              onClick={handleAuthorize}
            />
          </Panel>
          <Panel spacing="4">
            <Heading level="subsubsection">Export Status</Heading>
            <Box>
              {exportStatus == null ? (
                <chakra.em>Not setup yet</chakra.em>
              ) : (
                <>
                  <chakra.span
                    fontStyle={
                      exportStatus.lastExportResult.type === "Complete"
                        ? undefined
                        : "italic"
                    }
                  >
                    Export{" "}
                    {exportStatusLabels[exportStatus.lastExportResult.type]} on{" "}
                    {formatTimestamp(exportStatus.lastExported, {
                      format: ddmmyyyy,
                    })}
                  </chakra.span>
                  <JsonView
                    value={exportStatus.lastExportResult.message}
                    copyToClipboard={true}
                  />
                </>
              )}
            </Box>
            <Button
              colorScheme="blue"
              label="Export"
              leftIcon={<IoPlaySharp />}
              onClick={handleExport}
            />
          </Panel>
        </Spaced>
      </Container>
    </>
  );
}
