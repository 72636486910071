import { endpoints } from "@cartographerio/client";
import { ApiParams, MapboxAccessTokenResponse } from "@cartographerio/types";

import { UseQueryOpts } from "../base";
import { MINUTES } from "../helpers";

export type MapboxKey = ["mapbox"] | ["mapbox", "token", "v1"];

const INITIAL_PAUSE = 250;
const EXPIRY_TIME = 60 * MINUTES; // mapbox tokens can't be more than 1 hour old

export function token(
  apiParams: ApiParams
): UseQueryOpts<MapboxAccessTokenResponse, MapboxKey> {
  return {
    queryKey: ["mapbox", "token", "v1"],
    queryFn: () => endpoints.mapbox.v1.token(apiParams).unsafeRun(),
    // For some reason, embed pages seem to stall until the mapbox token has been fetched twice.
    // This implementation of refetchInterval causes the first refresh to be quick as a workaround.
    // I'd love to know what's going on, though, so I can stop it.
    // The symptoms can be seen if you get react-query-devtools to open at page load:
    //  - page loads, react-query queries appear in devtools;
    //  - devtools suddenly clears... all queries disappear;
    //  - nothing happens from this point on until one of the following:
    //     - a timeout within react-query causes a refetch;
    //     - a state change in react causes a rerender.
    refetchInterval: response =>
      response == null ? INITIAL_PAUSE : EXPIRY_TIME / 2,
    staleTime: EXPIRY_TIME / 2,
    cacheTime: EXPIRY_TIME,
  };
}
