import { chain } from "lodash";
import { MapboxArg as Arg, MapboxExpr as Expr, MapboxExprName } from "./type";

// Expression spec:
// https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions

//   _____
//  |_   _|   _ _ __   ___  ___
//    | || | | | '_ \ / _ \/ __|
//    | || |_| | |_) |  __/\__ \
//    |_| \__, | .__/ \___||___/
//        |___/|_|

export function array(arg: Arg): Expr;
export function array(type: "string" | "number" | "boolean", arg: Arg): Expr;
export function array(
  type: "string" | "number" | "boolean",
  n: number,
  arg: Arg
): Expr;
export function array(...args: Arg[]): Expr {
  return ["array", ...args];
}

export function boolean(...args: Arg[]): Expr {
  return ["boolean", ...args];
}

export interface CollatorOpts {
  ["case-sensitive"]?: boolean;
  ["diacritic-sensitive"]?: boolean;
  ["locale"]?: string;
}

export function collator(opts: CollatorOpts): Expr {
  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["collator", opts] as any) as Expr;
}

export interface FormatOpts {
  ["font-scale"]?: number;
  ["text-font"]?: string[];
  ["text-color"]?: string;
}

export function format(args: [Arg, FormatOpts][]): Expr {
  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["format", ...chain(args).flatten().value()] as any) as Expr;
}

export function literal(
  value: Array<unknown> | { [name: string]: unknown }
): Expr {
  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["literal", value] as any) as Expr;
}

export function number(...args: Arg[]): Expr {
  return ["number", ...args];
}

export interface NumberFormatOpts {
  local?: string;
  currency?: string;
  ["min-fraction-digits"]?: number;
  ["max-fraction-digits"]?: number;
}

export function object(...args: Arg[]): Expr {
  return ["object", ...args];
}

export function string(...args: Arg[]): Expr {
  return ["string", ...args];
}

export function toBoolean(arg: Arg): Expr {
  return ["to-boolean", arg];
}

export function toColor(arg: Arg): Expr {
  return ["to-color", arg];
}

export function toNumber(arg: Arg): Expr {
  return ["to-number", arg];
}

export function toString(arg: Arg): Expr {
  return ["to-string", arg];
}

export function typeOf(arg: Arg): Expr {
  return ["typeof", arg];
}

//   _____          _                    ____        _
//  |  ___|__  __ _| |_ _   _ _ __ ___  |  _ \  __ _| |_ __ _
//  | |_ / _ \/ _` | __| | | | '__/ _ \ | | | |/ _` | __/ _` |
//  |  _|  __/ (_| | |_| |_| | | |  __/ | |_| | (_| | || (_| |
//  |_|  \___|\__,_|\__|\__,_|_|  \___| |____/ \__,_|\__\__,_|

export function geometryType(): Expr {
  return ["geometry-type"];
}

export function id(): Expr {
  return ["id"];
}

export function lineProgress(): Expr {
  return ["line-progress"];
}

export function properties(): Expr {
  return ["properties"];
}

//   _                _
//  | |    ___   ___ | | ___   _ _ __
//  | |   / _ \ / _ \| |/ / | | | '_ \
//  | |__| (_) | (_) |   <| |_| | |_) |
//  |_____\___/ \___/|_|\_\\__,_| .__/
//                              |_|

export function at(array: Arg, index: Arg): Expr {
  return ["at", array, index];
}

export function get(field: Arg, object?: Arg): Expr {
  if (object == null) {
    return ["get", field];
  } else {
    return ["get", field, object];
  }
}

export function has(field: Arg, object?: Arg): Expr {
  if (object == null) {
    return ["has", field];
  } else {
    return ["has", field, object];
  }
}

export function isIn(needle: Arg, haystack: Arg): Expr {
  // "in" was added in Mapbox GL 1.6.0 but does not appear in @types/mapbox-gl yet
  return ["in" as MapboxExprName, needle, haystack];
}

export function length(arg: Arg): Expr {
  return ["length", arg];
}

//   ____            _     _
//  |  _ \  ___  ___(_)___(_) ___  _ __
//  | | | |/ _ \/ __| / __| |/ _ \| '_ \
//  | |_| |  __/ (__| \__ \ | (_) | | | |
//  |____/ \___|\___|_|___/_|\___/|_| |_|

export function not(arg: Arg): Expr {
  return ["!", arg];
}

export function eql(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return ["==", arg1, arg2];
  } else {
    return ["==", arg1, arg2, collator];
  }
}

export function neq(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return ["!=", arg1, arg2];
  } else {
    return ["!=", arg1, arg2, collator];
  }
}

export function lt(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return ["<", arg1, arg2];
  } else {
    return ["<", arg1, arg2, collator];
  }
}

export function lte(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return ["<=", arg1, arg2];
  } else {
    return ["<=", arg1, arg2, collator];
  }
}

export function gt(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return [">", arg1, arg2];
  } else {
    return [">", arg1, arg2, collator];
  }
}

export function gte(arg1: Arg, arg2: Arg, collator?: Expr): Expr {
  if (collator == null) {
    return [">=", arg1, arg2];
  } else {
    return [">=", arg1, arg2, collator];
  }
}

export function all(...args: Arg[]): Expr {
  return ["all", ...args];
}

export function any(...args: Arg[]): Expr {
  return ["any", ...args];
}

interface ChooseArgs {
  cases: [Arg, Arg][];
  fallback: Arg;
}

export function choose({ cases, fallback }: ChooseArgs): Expr {
  const rest: Arg[] = chain(cases).flatten().concat(fallback).value();

  return ["case", ...rest];
}

export function coalesce(...args: Arg[]): Expr {
  return ["coalesce", ...args];
}

interface MatchArgs {
  input: Arg;
  cases: [Arg | Arg[], Arg][];
  fallback: Arg;
}

export function match({ input, cases, fallback }: MatchArgs): Expr {
  const rest: Array<Arg | Arg[]> = chain(cases)
    .flatten()
    .concat([fallback])
    .value();

  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["match", input, ...rest] as any) as Expr;
}

//   ____                                             _
//  |  _ \ __ _ _ __ ___  _ __  ___     ___  ___ __ _| | ___  ___      ___ _   _ _ ____   _____  ___
//  | |_) / _` | '_ ` _ \| '_ \/ __|   / __|/ __/ _` | |/ _ \/ __|    / __| | | | '__\ \ / / _ \/ __|
//  |  _ < (_| | | | | | | |_) \__ \_  \__ \ (_| (_| | |  __/\__ \_  | (__| |_| | |   \ V /  __/\__ \
//  |_| \_\__,_|_| |_| |_| .__/|___( ) |___/\___\__,_|_|\___||___( )  \___|\__,_|_|    \_/ \___||___/
//                       |_|       |/                            |/

type InterpolationFunc =
  | ["linear"]
  | ["exponential", number]
  | ["cubic-bezier", number, number, number, number];

export function linear(): InterpolationFunc {
  return ["linear"];
}

export function exponential(base: number): InterpolationFunc {
  return ["exponential", base];
}

export function cubicBezier(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): InterpolationFunc {
  return ["cubic-bezier", x1, y1, x2, y2];
}

interface InterpolateArgs {
  type: InterpolationFunc;
  input: Arg;
  stops: [Arg, Arg][];
}

export function interpolate({ type, input, stops }: InterpolateArgs): Expr {
  const rest: Arg[] = chain(stops).flatten().value();

  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["interpolate", type, input, ...rest] as any) as Expr;
}

export function interpolateHcl({ type, input, stops }: InterpolateArgs): Expr {
  const rest: Arg[] = chain(stops).flatten().value();

  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["interpolate-hcl", type, input, ...rest] as any) as Expr;
}

export function interpolateLab({ type, input, stops }: InterpolateArgs): Expr {
  const rest: Arg[] = chain(stops).flatten().value();

  // TODO: Retype Expr in TS 4 to handle special arg types so we don't need the cast here:
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (["interpolate-lab", type, input, ...rest] as any) as Expr;
}

interface StepArgs {
  input: Arg;
  stop0: Arg;
  stops: [Arg, Arg][];
}

export function step({ input, stop0, stops }: StepArgs): Expr {
  const rest: Arg[] = chain(stops).flatten().value();

  return ["interpolate-lab", input, stop0, ...rest];
}

interface DefineArgs {
  vars: { [name: string]: Arg };
  body: Arg;
}

//  __     __         _       _     _        _     _           _ _
//  \ \   / /_ _ _ __(_) __ _| |__ | | ___  | |__ (_)_ __   __| (_)_ __   __ _
//   \ \ / / _` | '__| |/ _` | '_ \| |/ _ \ | '_ \| | '_ \ / _` | | '_ \ / _` |
//    \ V / (_| | |  | | (_| | |_) | |  __/ | |_) | | | | | (_| | | | | | (_| |
//     \_/ \__,_|_|  |_|\__,_|_.__/|_|\___| |_.__/|_|_| |_|\__,_|_|_| |_|\__, |
//                                                                       |___/

export function bind({ vars, body }: DefineArgs): Expr {
  const rest: Array<string | Arg> = chain(vars)
    .toPairs()
    .flatten()
    .concat([body])
    .value();

  return ["let", ...rest];
}

export function ref(ident: string): Expr {
  return ["var", ident];
}

//   ____  _        _
//  / ___|| |_ _ __(_)_ __   __ _
//  \___ \| __| '__| | '_ \ / _` |
//   ___) | |_| |  | | | | | (_| |
//  |____/ \__|_|  |_|_| |_|\__, |
//                          |___/

export function concat(...args: Arg[]): Expr {
  return ["concat", ...args];
}

export function downcase(...args: Arg[]): Expr {
  return ["downcase", ...args];
}

export function isSupportedScript(arg: Arg): Expr {
  return ["is-supported-script", arg];
}

export function resolvedLocale(arg: Arg): Expr {
  return ["resolved-locale", arg];
}

export function upcase(...args: Arg[]): Expr {
  return ["upcase", ...args];
}

//    ____      _
//   / ___|___ | | ___  _ __
//  | |   / _ \| |/ _ \| '__|
//  | |__| (_) | | (_) | |
//   \____\___/|_|\___/|_|

export function rgb(r: Arg, g: Arg, b: Arg): Expr {
  return ["rgb", r, g, b];
}

export function rgba(r: Arg, g: Arg, b: Arg, a: Arg): Expr {
  return ["rgba", r, g, b, a];
}

export function toRgba(color: Arg): Expr {
  // TODO: Remove this cast once @types/mapbox-gl is up-to-date:
  return ["to-rgba" as MapboxExprName, color];
}

//   __  __       _   _
//  |  \/  | __ _| |_| |__
//  | |\/| |/ _` | __| '_ \
//  | |  | | (_| | |_| | | |
//  |_|  |_|\__,_|\__|_| |_|

export function sub(a: Arg, b: Arg): Expr {
  return ["-", a, b];
}

export function mul(a: Arg, b: Arg): Expr {
  return ["*", a, b];
}

export function div(a: Arg, b: Arg): Expr {
  return ["/", a, b];
}

export function mod(a: Arg, b: Arg): Expr {
  return ["%", a, b];
}

export function exp(a: Arg, b: Arg): Expr {
  return ["^", a, b];
}

export function add(a: Arg, b: Arg): Expr {
  return ["+", a, b];
}

export function abs(arg: Arg): Expr {
  return ["abs", arg];
}

export function acos(arg: Arg): Expr {
  return ["acos", arg];
}

export function asin(arg: Arg): Expr {
  return ["asin", arg];
}

export function atan(arg: Arg): Expr {
  return ["atan", arg];
}

export function ceil(arg: Arg): Expr {
  return ["ceil", arg];
}

export function cos(arg: Arg): Expr {
  return ["cos", arg];
}

export function e(): Expr {
  return ["e"];
}

export function floor(arg: Arg): Expr {
  return ["floor", arg];
}

export function ln(arg: Arg): Expr {
  return ["ln", arg];
}

export function ln2(): Expr {
  return ["ln2"];
}

export function log10(arg: Arg): Expr {
  return ["log10", arg];
}

export function log2(arg: Arg): Expr {
  return ["log2", arg];
}

export function max(a: Arg, ...bs: Arg[]): Expr {
  return ["max", a, ...bs];
}

export function min(a: Arg, ...bs: Arg[]): Expr {
  return ["min", a, ...bs];
}

export function pi(): Expr {
  return ["pi"];
}

export function round(arg: Arg): Expr {
  return ["round", arg];
}

export function sin(arg: Arg): Expr {
  return ["sin", arg];
}

export function sqrt(arg: Arg): Expr {
  return ["sqrt", arg];
}

export function tan(arg: Arg): Expr {
  return ["tan", arg];
}

//   _____
//  |__  /___   ___  _ __ ___
//    / // _ \ / _ \| '_ ` _ \
//   / /| (_) | (_) | | | | | |
//  /____\___/ \___/|_| |_| |_|

export function zoom(): Expr {
  return ["zoom"];
}

//   _   _            _
//  | | | | ___  __ _| |_ _ __ ___   __ _ _ __
//  | |_| |/ _ \/ _` | __| '_ ` _ \ / _` | '_ \
//  |  _  |  __/ (_| | |_| | | | | | (_| | |_) |
//  |_| |_|\___|\__,_|\__|_| |_| |_|\__,_| .__/
//                                       |_|

export function heatmapDensity(): Expr {
  return ["heatmap-density"];
}

//    ___  _   _                 _____ _ _ _
//   / _ \| |_| |__   ___ _ __  |  ___(_) | |_ ___ _ __ ___
//  | | | | __| '_ \ / _ \ '__| | |_  | | | __/ _ \ '__/ __|
//  | |_| | |_| | | |  __/ |    |  _| | | | ||  __/ |  \__ \
//   \___/ \__|_| |_|\___|_|    |_|   |_|_|\__\___|_|  |___/

// Implementations of some deprecated filters in terms of expressions
// See https://docs.mapbox.com/mapbox-gl-js/style-spec/#other-filter

export function notIn(needle: Arg, haystack: Arg): Expr {
  return not(isIn(needle, haystack));
}

export function none(...args: Arg[]): Expr {
  return not(any(...args));
}
