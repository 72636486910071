import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import PageTopBar from "../../../components/PageTopBar";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { usePermissionCheckPasses } from "../../../hooks/usePermissionCheckPasses";
import useRedirectWhen from "../../../hooks/useRedirectWhen";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useTeamsEnabled } from "../../../hooks/useTeamsEnabled";
import { routes } from "../../../routes";
import BaseTeamListPage from "../../base/BaseTeamListPage";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectTeamListPage(
  props: RouteProps<typeof routes.workspace.project.teams>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  useRequirePermissionRedirect(checks.project.viewTeams(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  usePageTitle(`Teams - ${project.name} - ${workspace.name}`);

  const canAddTeam = usePermissionCheckPasses(
    checks.team.create(workspace, [project])
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="teams"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="teams"
      />
      <BaseTeamListPage
        workspace={workspace}
        project={project.alias}
        enableAddButton={canAddTeam}
        query={query}
        updateQuery={updateQuery}
      />
    </>
  );
}
