import { ReactElement, ReactNode } from "react";

import Container, { ContainerWidth } from "../../components/Container";
import PageHeader from "../../components/PageHeader";
import { PageHeaderHr } from "../../components/PageHeaderHr";

interface AdminPageHeaderProps {
  title: string;
  width?: ContainerWidth;
  right?: ReactNode;
}

export default function AdminPageHeader(
  props: AdminPageHeaderProps
): ReactElement {
  const { title, width = "wide", right } = props;
  return (
    <Container sticky={true} width={width}>
      <PageHeader title={title} right={right}>
        <PageHeaderHr />
      </PageHeader>
    </Container>
  );
}
