import {
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, RefObject } from "react";

import HelpPopover from "./HelpPopover";

interface FormLabelProps extends Omit<ChakraFormLabelProps, "children"> {
  htmlFor?: string;
  level?: "primary" | "secondary";
  text?: ReactNode | null;
  help?: string;
  helpPortalContainerRef?: RefObject<HTMLElement>;
}

export default function FormLabel(props: FormLabelProps): ReactElement | null {
  const { htmlFor, level, text, help, helpPortalContainerRef, ...rest } = props;

  return text == null ? null : (
    <ChakraFormLabel
      htmlFor={htmlFor}
      fontWeight={level === "secondary" ? "regular" : "bold"}
      fontSize={level === "secondary" ? "sm" : undefined}
      mt={level === "secondary" ? "-1" : undefined}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      marginInlineEnd="0"
      {...rest}
    >
      <chakra.span
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace={["normal", "nowrap"]}
      >
        {text}
      </chakra.span>
      {help != null && (
        <HelpPopover text={help} portalContainerRef={helpPortalContainerRef} />
      )}
    </ChakraFormLabel>
  );
}
