import { IO } from "@cartographerio/io";
import {
  InvitationCodeAlias,
  unsafeEmail,
  unsafeInvitationCodeAlias,
  unsafeScreenName,
} from "@cartographerio/types";

import { loadingAction } from "./action";
import { SignupDetails, State, enterEmail } from "./state";

export function manageLoadingFlag<T>(io: IO<T>): IO<T> {
  return IO.put(loadingAction(true))
    .andThen(io.sleepUpTo(500))
    .cleanup(() => IO.put(loadingAction(false)));
}

export function initialSigninState(code?: InvitationCodeAlias): State {
  return enterEmail({
    signin: {
      code:
        code != null
          ? unsafeInvitationCodeAlias(code.toUpperCase())
          : undefined,
      reacceptTerms: false,
    },
    email: unsafeEmail(""),
    loading: false,
  });
}

export function blankSignup(): SignupDetails {
  return {
    firstName: "",
    lastName: "",
    screenName: unsafeScreenName(""),
    acceptTerms: false,
  };
}
