import {
  Attribute,
  MapLayerId,
  defaultLayerAttribute,
  featureAttr,
  findLayerAttribute,
} from "@cartographerio/atlas-map";
import { raise } from "@cartographerio/util";
import { chain } from "lodash";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Setter } from "../../hooks/useRecord";
import { useVolatileRecord } from "../../hooks/useVolatileRecord";
import { MapAttributeQueryParam } from "../../routes/queryParams";
import { useMapLayers } from "./MapSchemaContext";

interface SelectedAttributesContextValue {
  selectedAttributes: Record<MapLayerId, Attribute>;
  setSelectedAttribute: Setter<MapLayerId, Attribute>;
}

const SelectedAttributesContext =
  createContext<SelectedAttributesContextValue | undefined>(undefined);

export function useSelectedAttributesContext(): SelectedAttributesContextValue {
  return (
    useContext(SelectedAttributesContext) ??
    raise(new Error("No current atlas map context"))
  );
}

const defaultAttr = featureAttr({
  attributeId: "default",
  label: "All Features",
});

export function useSelectedAttribute(
  layerId: MapLayerId | undefined
): [Attribute, (attr: Attribute) => void] {
  const { selectedAttributes, setSelectedAttribute } =
    useSelectedAttributesContext();
  const value = layerId == null ? defaultAttr : selectedAttributes[layerId];
  const setter = useCallback(
    (attr: Attribute | undefined) => {
      layerId != null && setSelectedAttribute(layerId, attr);
    },
    [layerId, setSelectedAttribute]
  );
  return [value, setter];
}

export type OnAttributeSelected = (
  layer: MapLayerId,
  attr: Attribute | null
) => void;

interface SelectedAttributesContextProviderProps {
  defaultSelectedAttribute?: MapAttributeQueryParam;
  onAttributeSelected?: OnAttributeSelected;
  children: ReactNode;
}

export function SelectedAttributesContextProvider(
  props: SelectedAttributesContextProviderProps
) {
  const { defaultSelectedAttribute, onAttributeSelected, children } = props;

  const layers = useMapLayers();

  const [selectedAttributes, _setSelectedAttribute] = useVolatileRecord<
    MapLayerId,
    Attribute
  >(
    useCallback(() => {
      const [defaultLayer, defaultAttr] =
        defaultSelectedAttribute == null
          ? [null, null]
          : defaultSelectedAttribute;

      return chain(layers)
        .map(layer => {
          const attr: Attribute =
            defaultAttr != null &&
            (defaultLayer == null || layer.layerId === defaultLayer)
              ? findLayerAttribute(layer, defaultAttr) ??
                defaultLayerAttribute(layer)
              : defaultLayerAttribute(layer);

          return [layer.layerId, attr];
        })
        .fromPairs()
        .value();
    }, [defaultSelectedAttribute, layers])
  );

  const setSelectedAttribute = useCallback<Setter<MapLayerId, Attribute>>(
    (layer: MapLayerId, attribute: Attribute | undefined) => {
      _setSelectedAttribute(layer, attribute);
      onAttributeSelected?.(layer, attribute ?? null);
    },
    [_setSelectedAttribute, onAttributeSelected]
  );

  const value = useMemo<SelectedAttributesContextValue>(
    () => ({ selectedAttributes, setSelectedAttribute }),
    [selectedAttributes, setSelectedAttribute]
  );

  return (
    <SelectedAttributesContext.Provider value={value}>
      {children}
    </SelectedAttributesContext.Provider>
  );
}
