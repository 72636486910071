import { Message } from "@cartographerio/types";
import {
  Box,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  chakra,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import Button, { ButtonProps } from "./Button";
import MessageList from "./MessageList";

interface SaveButtonProps extends Omit<ButtonProps, "label"> {
  messages: Message[];
  label?: string;
  placement?: PlacementWithLogical;
}

export default function SaveButton(props: SaveButtonProps): ReactElement {
  const {
    messages,
    label = "Save",
    colorScheme = "blue",
    placement = "right-end",
    disabled,
    ...rest
  } = props;

  return (
    <Popover
      placement={placement}
      trigger="hover"
      isOpen={messages.length > 0 ? undefined : false}
    >
      <PopoverTrigger>
        <Box w="fit-content">
          <Button
            label={label}
            colorScheme={colorScheme}
            disabled={disabled ?? messages.length > 0}
            {...rest}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent w="fit-content">
        <PopoverArrow />
        <PopoverBody pr="8" fontSize="xs" color="gray.500">
          <chakra.p>
            You must fix the following errors before you can save your changes:
          </chakra.p>
          <MessageList messages={messages} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
