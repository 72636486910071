import assert from "assert";

import { isEqual as deepEqual } from "lodash";
import type { AnyLayer, Layer } from "mapbox-gl";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import type { MapInstance } from "react-map-gl";
import { MapContext } from "react-map-gl/dist/esm/components/map";

// Omiting property from a union type, see
// https://github.com/microsoft/TypeScript/issues/39556#issuecomment-656925230
type OptionalId<T> = T extends { id: string }
  ? Omit<T, "id"> & { id?: string }
  : T;

export type LayerProps = OptionalId<AnyLayer> & {
  /** If set, the layer will be inserted before the specified layer */
  beforeId?: string;
};

/* eslint-disable complexity, max-statements */
function updateLayer(
  map: MapInstance,
  id: string,
  props: LayerProps,
  prevProps: LayerProps | null
) {
  assert(prevProps == null || props.id === prevProps.id, "id changed");
  assert(prevProps == null || props.type === prevProps.type, "type changed");

  if (props.type === "custom" || prevProps?.type === "custom") {
    return;
  }

  const { layout = {}, paint = {}, filter, minzoom, maxzoom, beforeId } = props;

  if (beforeId !== prevProps?.beforeId) {
    map.moveLayer(id, beforeId);
  }

  if (layout !== prevProps?.layout) {
    const prevLayout = prevProps?.layout ?? {};
    for (const key in layout) {
      // @ts-ignore
      if (!deepEqual(layout[key], prevLayout[key])) {
        debugUpdates([id, "layout", key].join("."), "update");
        // @ts-ignore
        map.setLayoutProperty(id, key, layout[key]);
      }
    }

    for (const key in prevLayout) {
      // eslint-disable-next-line no-prototype-builtins
      if (!layout.hasOwnProperty(key)) {
        debugUpdates([id, "layout", key].join("."), "delete");
        map.setLayoutProperty(id, key, undefined);
      }
    }
  }

  if (paint !== prevProps?.paint) {
    const prevPaint = prevProps?.paint ?? {};
    for (const key in paint) {
      // @ts-ignore
      if (!deepEqual(paint[key], prevPaint[key])) {
        debugUpdates([id, "paint", key].join("."), "update");
        // @ts-ignore
        map.setPaintProperty(id, key, paint[key]);
      }
    }

    for (const key in prevPaint) {
      // eslint-disable-next-line no-prototype-builtins
      if (!paint.hasOwnProperty(key)) {
        debugUpdates([id, "paint", key].join("."), "delete");
        map.setPaintProperty(id, key, undefined);
      }
    }
  }

  if (!deepEqual(filter, prevProps?.filter)) {
    debugUpdates([id, "filter"].join("."), "update", filter);
    map.setFilter(id, filter);
  }

  if (minzoom !== prevProps?.minzoom || maxzoom !== prevProps?.maxzoom) {
    map.setLayerZoomRange(id, minzoom ?? 0, maxzoom ?? 22);
  }
}

function createLayer(map: MapInstance, id: string, props: LayerProps) {
  if (
    // @ts-ignore
    map.style?._loaded &&
    // @ts-ignore
    (!("source" in props) || map.getSource(props.source))
  ) {
    const options: LayerProps = { ...props, id };
    delete options.beforeId;

    // @ts-ignore
    map.addLayer(options, props.beforeId);
  }
}

/* eslint-enable complexity, max-statements */

let layerCounter = 0;

export default function UpdateInPlaceLayer(props: LayerProps) {
  const map: MapInstance = useContext(MapContext).map.getMap();
  const propsRef = useRef<LayerProps | null>(null);

  const [created, setCreated] = useState(false);
  const [styleLoaded, setStyleLoaded] = useState(0);

  const id = useMemo(
    () => props.id || `jsx-layer-${layerCounter++}`,
    [props.id]
  );

  useEffect(() => {
    if (map) {
      const onLoad = () => {
        // @ts-ignore
        if (map._loaded) {
          setStyleLoaded(v => v + 1);
        }
      };

      map.on("load", onLoad);
      onLoad();

      return () => {
        map.off("load", onLoad);

        // @ts-ignore
        if (created && map.getStyle()) {
          map.removeLayer(id);
        }
      };
    }
    return undefined;
  }, [created, id, map]);

  const layer: Layer | null =
    // @ts-ignore
    map?.style == null ? null : map.getLayer(id);

  if (styleLoaded > 0) {
    if (layer != null) {
      try {
        updateLayer(map, id, props, propsRef.current);
      } catch (error) {
        // eslint-disable-next-line eslint-comments/no-unlimited-disable
        console.warn(error);
      }
    } else {
      createLayer(map, id, props);
      setCreated(true);
    }

    // Store last rendered props
    propsRef.current = props;
  }

  return null;
}

function debugUpdates(..._args: unknown[]) {
  // console.log(...args);
}
