import { Option } from "@cartographerio/fp";
import { InputElementProps } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { BaseTextFieldProps } from "./base";
import DefaultTextField from "./Default";
import Units from "./Units";

export interface StringTextFieldProps
  extends BaseTextFieldProps<string>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  trim?: boolean;
  units?: string;
}

function format(str: string): string {
  return str;
}

function createValidate(trim: boolean) {
  return (str: string): Option<string> => {
    return Option.some(trim ? str.trim() : str);
  };
}

export default function StringTextField(
  props: StringTextFieldProps
): ReactElement {
  const { disabled, trim = true, units, ...rest } = props;

  const rightAddon =
    units == null ? undefined : <Units units={units} disabled={disabled} />;

  const validate = useMemo(() => createValidate(trim), [trim]);

  return (
    <DefaultTextField
      format={format}
      validate={validate}
      disabled={disabled}
      rightAddon={rightAddon}
      {...rest}
    />
  );
}
