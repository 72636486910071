import { WorkspaceRef } from "@cartographerio/types";
import { ReactElement } from "react";

import { routes } from "../routes";
import Avatar from "./Avatar";

export interface WorkspaceAvatarProps {
  workspaceRef: WorkspaceRef;
  name: string;
  logo?: string;
  active: boolean;
  disabled?: boolean;
}

export default function WorkspaceAvatar(
  props: WorkspaceAvatarProps
): ReactElement {
  const { workspaceRef, name, logo, active, disabled } = props;

  return (
    <Avatar
      to={routes.workspace.home.url([workspaceRef])}
      name={name}
      image={logo}
      active={active}
      disabled={disabled}
    />
  );
}
