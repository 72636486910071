import { SortOrder } from "@cartographerio/client";

export function asc<O extends string>(key: O): SortOrder<O> {
  // Typescript can't infer that this is correct:
  return `${key}-asc` as SortOrder<O>;
}

export function desc<O extends string>(key: O): SortOrder<O> {
  // Typescript can't infer that this is correct:
  return `${key}-desc` as SortOrder<O>;
}

export function rev<O extends string>(
  key: O,
  defaultOrder: "asc" | "desc" = "asc",
  order?: SortOrder<O>
): SortOrder<O> {
  if (order === `${key}-asc`) {
    return `${key}-desc`;
  } else if (order === `${key}-desc`) {
    return `${key}-asc`;
  } else {
    return `${key}-${defaultOrder}`;
  }
}
