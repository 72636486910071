import {
  ProjectBreadcrumb,
  ProjectV2,
  WorkspaceV2,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { SimpleGrid } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { ReactElement, useMemo } from "react";

import { useCredentialsV2 } from "../contexts/auth";
import usePartitionedProjectBreadcrumbs from "../hooks/usePartitionedProjectBreadcrumbs";
import usePartitionedProjects from "../hooks/usePartitionedProjects";
import Heading from "./Heading";
import Placeholder from "./Placeholder";
import ProjectBreadcrumbCard from "./ProjectBreadcrumb";
import ProjectCard from "./ProjectCard";

interface SectionedProjectGridsProps {
  workspace: WorkspaceV2;
  graph: WorkspaceGraphV2;
  fallback?: ReactElement;
}

export default function SectionedProjectGrids(
  props: SectionedProjectGridsProps
): ReactElement {
  const { workspace, graph, fallback } = props;

  const {
    identity: { userId },
  } = useCredentialsV2();

  const [myProjects, otherProjects] = usePartitionedProjects(
    workspace.id,
    graph
  );

  const [myProjectBreadcrumbs, otherProjectBreadcrumbs] =
    usePartitionedProjectBreadcrumbs(workspace.id, userId);

  const numItems =
    myProjects.length +
    otherProjects.length +
    myProjectBreadcrumbs.length +
    otherProjectBreadcrumbs.length;

  return numItems > 0 ? (
    <>
      <Heading level="subsection">My Projects</Heading>
      {myProjects.length > 0 || myProjectBreadcrumbs.length > 0 ? (
        <ProjectGrid
          workspace={workspace}
          projects={myProjects}
          breadcrumbs={myProjectBreadcrumbs}
        />
      ) : (
        <Placeholder text="You aren't a member of any projects. You can still see other projects below." />
      )}

      {otherProjects.length > 0 || otherProjectBreadcrumbs.length > 0 ? (
        <>
          <Heading level="subsection">Other Projects</Heading>
          <ProjectGrid
            workspace={workspace}
            projects={otherProjects}
            breadcrumbs={otherProjectBreadcrumbs}
          />
        </>
      ) : null}
    </>
  ) : (
    <>{fallback}</>
  );
}

interface ProjectGridContentProps {
  workspace: WorkspaceV2;
  projects: ProjectV2[];
  breadcrumbs?: ProjectBreadcrumb[];
}

type GridItem =
  | { type: "Project"; project: ProjectV2 }
  | { type: "Breadcrumb"; breadcrumb: ProjectBreadcrumb };

function projectItem(project: ProjectV2): GridItem {
  return { type: "Project", project };
}

function breadcrumbItem(breadcrumb: ProjectBreadcrumb): GridItem {
  return { type: "Breadcrumb", breadcrumb };
}

function itemTitle(item: GridItem): string {
  switch (item.type) {
    case "Project":
      return item.project.name;
    case "Breadcrumb":
      return item.breadcrumb.title;
    default:
      return checkExhausted(item);
  }
}

function ProjectGrid(props: ProjectGridContentProps): ReactElement {
  const { workspace, projects, breadcrumbs = [] } = props;

  const items = useMemo(
    () =>
      sortBy(
        [...projects.map(projectItem), ...breadcrumbs.map(breadcrumbItem)],
        itemTitle
      ),
    [breadcrumbs, projects]
  );

  return useMemo(
    () => (
      <SimpleGrid columns={[1, null, 2]} gap="2">
        {items.map((item, i) => {
          switch (item.type) {
            case "Project":
              return (
                <ProjectCard
                  key={item.project.id}
                  workspace={workspace}
                  project={item.project}
                />
              );
            case "Breadcrumb":
              return (
                <ProjectBreadcrumbCard
                  key={`breadcrumbProject${i}`}
                  breadcrumb={item.breadcrumb}
                />
              );
            default:
              return checkExhausted(item);
          }
        })}
      </SimpleGrid>
    ),
    [items, workspace]
  );
}
