import { Flex, FlexProps, IconButton } from "@chakra-ui/react";
import { ReactElement } from "react";

interface NavButtonProps extends FlexProps {
  onClick?: () => void;
  isDisabled?: boolean;
  icon?: ReactElement;
  ariaLabel: string;
}

export default function NavButton(props: NavButtonProps): ReactElement {
  const { icon, onClick, isDisabled, ariaLabel, ...rest } = props;
  return (
    <Flex
      justify="center"
      align="center"
      borderBottomWidth={2}
      borderBottomColor="gray.200"
      {...rest}
    >
      <IconButton
        icon={icon}
        onClick={onClick}
        cursor="pointer"
        aria-label={ariaLabel}
        size="sm"
        variant="ghost"
        isDisabled={isDisabled}
      />
    </Flex>
  );
}
