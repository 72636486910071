import { Option } from "@cartographerio/fp";
import { check } from "@cartographerio/permission";
import {
  Message,
  RoleV2,
  WorkspaceId,
  WorkspaceV2,
  isWorkspaceRoleV2,
  roleWorkspaceIdV2,
  workspaceActiveV2,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import {
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { chain } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";

import { usePermissionCheckPasses } from "../../hooks/usePermissionCheckPasses";
import Button from "../Button";
import MessageList from "../MessageList";
import Placeholder from "../Placeholder";
import SearchField from "../SearchField";
import Spaced, { SpacedProps } from "../Spaced";
import TestTarget from "../TestTarget";
import { GLOBAL_ROLE_HELP } from "./helpText";
import { GlobalRoleNode } from "./RoleNode";
import { useWorkspaceRoles } from "./useRoles";
import WorkspacePanel from "./WorkspacePanel";

interface RoleEditorProps extends Omit<SpacedProps, "children"> {
  value: RoleV2[];
  onChange?: (value: RoleV2[]) => void;
  messages?: Message[];
  workspaceGraph: WorkspaceGraphV2;
  defaultWorkspace?: WorkspaceId;
  singleWorkspace?: boolean;
  disabled?: boolean;
}

export default function RoleEditor(props: RoleEditorProps): ReactElement {
  const {
    value: roles,
    onChange,
    messages,
    workspaceGraph,
    defaultWorkspace: _defaultWorkspace,
    singleWorkspace: _singleWorkspace,
    disabled,
    ...rest
  } = props;

  const isSuperuser = usePermissionCheckPasses(check.superuser);

  const singleWorkspace = _singleWorkspace ?? !isSuperuser;

  const firstWorkspace = useMemo(
    () => workspaceGraph.allWorkspaces()[0],
    [workspaceGraph]
  );

  const defaultWorkspace = useMemo(
    () =>
      _defaultWorkspace != null
        ? workspaceGraph.optFindWorkspaceById(_defaultWorkspace)
        : null,
    [_defaultWorkspace, workspaceGraph]
  );

  const registeredWorkspaces = useMemo(
    () =>
      chain(roles)
        .filter(isWorkspaceRoleV2)
        .flatMap(role => Option.wrap(roleWorkspaceIdV2(role)).toArray())
        .flatMap(id =>
          Option.wrap(workspaceGraph.optFindWorkspaceById(id)).toArray()
        )
        .sortBy(ws => ws.name)
        .value(),
    [roles, workspaceGraph]
  );

  const remainingWorkspaces = useMemo(
    () =>
      workspaceGraph
        .allWorkspaces()
        .filter(ws => !registeredWorkspaces.includes(ws)),
    [registeredWorkspaces, workspaceGraph]
  );

  const shownWorkspaces = useMemo(
    () =>
      (singleWorkspace
        ? [defaultWorkspace ?? firstWorkspace]
        : defaultWorkspace != null
        ? [
            defaultWorkspace,
            ...registeredWorkspaces.filter(
              workspace => workspace.id !== defaultWorkspace.id
            ),
          ]
        : registeredWorkspaces
      ).filter(
        ws => roles.find(role => roleWorkspaceIdV2(role) === ws.id) != null
      ),
    [
      defaultWorkspace,
      firstWorkspace,
      registeredWorkspaces,
      roles,
      singleWorkspace,
    ]
  );

  const { addRole, removeRole, getGlobalRole } = useWorkspaceRoles(
    workspaceGraph,
    roles,
    onChange
  );

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [workspaceSearchText, setWorkspaceSearchText] = useState("");
  const workspaceSearch = useMemo(
    () =>
      remainingWorkspaces.filter(
        ({ name }) =>
          name
            .toLocaleLowerCase()
            .indexOf(workspaceSearchText.toLocaleLowerCase()) >= 0
      ),
    [remainingWorkspaces, workspaceSearchText]
  );
  const onWorkspaceSelected = useCallback(
    (workspace: WorkspaceV2) => {
      addRole(workspaceActiveV2(workspace.id));
      setWorkspaceSearchText("");
      onClose();
    },
    [addRole, onClose]
  );

  const globalRole = useMemo(() => getGlobalRole(), [getGlobalRole]);

  return (
    <Spaced spacing="4" {...rest}>
      {singleWorkspace && <TestTarget testId="single-workspace-roles" />}
      {isSuperuser && (
        <GlobalRoleNode
          value={globalRole}
          help={GLOBAL_ROLE_HELP}
          onChange={value =>
            value != null
              ? addRole(value)
              : globalRole != null
              ? removeRole(globalRole)
              : raise("Could not find global role to remove")
          }
          disabled={disabled}
        />
      )}
      {shownWorkspaces.length > 0 ? (
        shownWorkspaces.map(workspace => (
          <WorkspacePanel
            key={workspace.id}
            value={roles}
            workspace={workspace}
            graph={workspaceGraph}
            collapsible={!singleWorkspace}
            canRemoveWorkspace={isSuperuser}
            initialIsOpen={
              shownWorkspaces.length === 1 || workspace.id === _defaultWorkspace
            }
            disabled={disabled}
            onChange={onChange}
          />
        ))
      ) : (
        <Placeholder
          text={
            singleWorkspace
              ? "No assigned roles in this workspace"
              : "No assigned roles in workspaces"
          }
        />
      )}

      {!disabled && !singleWorkspace && (
        <Button label="Add Workspace" variant="outline" onClick={onOpen} />
      )}

      <MessageList messages={messages} />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Select a Workspace
            <SearchField
              placeholder="Search for a workspace"
              onChange={val => setWorkspaceSearchText(val ?? "")}
              mt={3}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0} px={0}>
            <List>
              {workspaceSearch.map(workspace => (
                <ListItem
                  py="2"
                  px={6}
                  _hover={{ bg: "teal.100" }}
                  cursor="pointer"
                  key={workspace.id}
                  onClick={() => onWorkspaceSelected(workspace)}
                >
                  {workspace.name}
                </ListItem>
              ))}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Spaced>
  );
}
