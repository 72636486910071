import {
  BlockState,
  Repeat,
  RepeatState,
  blockIsVisible,
  deleteAction,
  initialiseBlock,
  insertAction,
} from "@cartographerio/atlas-form";
import { ArraySchema, schemaBlankValue } from "@cartographerio/atlas-survey";
import { checkExhausted } from "@cartographerio/util";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  VStack,
} from "@chakra-ui/react";
import { range, take } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { useIOConfirm } from "../components/Alert";
import JsonView from "../components/JsonView";
import TextField from "../components/TextField";
import BlockView from "./BlockView";
import { useFormContext } from "./context/FormContext";

export interface RepeatViewProps {
  parentKey: string;
  state: RepeatState;
  disabled?: boolean;
}

function blankValue(repeat: Repeat, arraySchema: ArraySchema): unknown {
  const doc: unknown = schemaBlankValue(arraySchema.param);

  const result = initialiseBlock(repeat.block, arraySchema.param, [], doc);

  return result.getOrNull();
}

export default function RepeatView(props: RepeatViewProps): ReactElement {
  const confirm = useIOConfirm();

  const { parentKey, state, disabled: _disabled } = props;

  const { block, arrayPath, arraySchema, childStates } = state;

  const {
    confirmOnDelete = true,
    addButtonLabel = "Add",
    emptyPrompt = 'Click "Add" to add an item.',
    deletePrompt = "Delete Item",
  } = block;

  const [addMultiple, setAddMultiple] = useState(block.addMultiple);

  const { formDispatch, debugInfoVisible, editable } = useFormContext();

  const disabled = _disabled || !editable;

  const handleAddClick = useCallback(() => {
    formDispatch(
      insertAction(
        arrayPath,
        ...range(0, addMultiple ?? 1).map(_ => blankValue(block, arraySchema))
      )
    );
  }, [formDispatch, arrayPath, addMultiple, block, arraySchema]);

  const handleDeleteClick = useCallback(
    (index: number) => {
      const actuallyDelete = () => formDispatch(deleteAction(arrayPath, index));

      confirmOnDelete
        ? confirm({
            title: deletePrompt,
            message: "Are you sure? There is no undo!",
            okLabel: "OK",
            okColorScheme: "red",
          })
            .tap(ok => ok && actuallyDelete())
            .unsafeRun()
        : actuallyDelete();
    },
    [arrayPath, confirm, confirmOnDelete, deletePrompt, formDispatch]
  );

  return (
    <VStack spacing="4" alignItems="stretch">
      {childStates.length === 0 ? (
        <EmptyRepeatItems prompt={emptyPrompt} editable={editable} />
      ) : (
        childStates.map((state, index) => {
          const childKey = `${parentKey}_block${index}`;

          if (!blockIsVisible(state)) {
            return null;
          }

          switch (block.appearance) {
            case "compact":
              return (
                <CompactRepeatItem
                  key={index}
                  blockKey={childKey}
                  state={state}
                  onDeleteClick={() => handleDeleteClick(index)}
                  editable={editable}
                  disabled={_disabled}
                />
              );

            case "card":
            case undefined:
              return (
                <CardRepeatItem
                  key={index}
                  blockKey={childKey}
                  state={state}
                  editable={editable}
                  onDeleteClick={() => handleDeleteClick(index)}
                  disabled={_disabled}
                />
              );

            default:
              return checkExhausted(block.appearance);
          }
        })
      )}

      {!disabled && (
        <HStack justify="flex-start">
          <Button
            onClick={handleAddClick}
            variant="outline"
            alignSelf="flex-start"
          >
            {addButtonLabel}
          </Button>

          {addMultiple != null && (
            <TextField.Number
              width="12ch"
              value={addMultiple}
              onChange={setAddMultiple}
              decimalPlaces={0}
              units="items"
            />
          )}
        </HStack>
      )}

      {debugInfoVisible && <JsonView label={parentKey} value={state} />}
    </VStack>
  );
}

interface RepeatItemProps {
  blockKey: string;
  state: BlockState;
  editable: boolean;
  onDeleteClick: () => void;
  disabled?: boolean;
}

function CardRepeatItem(props: RepeatItemProps) {
  const { blockKey, state, editable, onDeleteClick, disabled } = props;

  return (
    <VStack
      align="stretch"
      borderWidth={1}
      borderRadius="lg"
      p={3}
      alignItems="stretch"
      shadow="sm"
      my="4"
      spacing="4"
      bg="white"
    >
      {editable && (
        <Flex direction="row" justify="flex-end">
          <IconButton
            flexGrow={0}
            flexShrink={0}
            aria-label="Delete"
            variant="outline"
            icon={<Icon as={IoCloseSharp} color="gray.400" />}
            onClick={onDeleteClick}
          />
        </Flex>
      )}

      <BlockView blockKey={blockKey} blockState={state} disabled={disabled} />
    </VStack>
  );
}

function shouldHaveFakeLabel(state: BlockState): boolean {
  switch (state.type) {
    case "FieldState":
      return state.field.label != null;
    case "DynamicValueState":
      return state.block.label != null;
    case "DynamicGridState":
      return state.block.label != null;
    case "DynamicImageState":
    case "HeadingState":
    case "TextState":
      return false;
    case "GroupState":
      // TODO: Doesn't take fullWidth into account:
      return take(state.blockStates, state.block.columns).some(
        shouldHaveFakeLabel
      );
    case "RepeatState":
      // TODO: Doesn't take empty repeats into account:
      return (
        state.childStates.length > 0 &&
        shouldHaveFakeLabel(state.childStates[0])
      );
    case "CardState":
    case "FullWidthState":
    case "RequireRoleState":
    case "ScopeState":
    case "VisibilityState":
      return shouldHaveFakeLabel(state.childState);
    case "PrimarySurveyorFieldState":
    case "PrimaryTeamFieldState":
      return true;
    default:
      return checkExhausted(state);
  }
}

function shouldHaveFakeSecondaryLabel(state: BlockState): boolean {
  switch (state.type) {
    case "FieldState":
      return state.field.secondaryLabel != null;
    case "DynamicValueState":
      return state.block.secondaryLabel != null;
    case "DynamicGridState":
      return state.block.secondaryLabel != null;
    case "DynamicImageState":
    case "HeadingState":
    case "TextState":
      return false;
    case "GroupState":
      // TODO: Doesn't take fullWidth into account:
      return take(state.blockStates, state.block.columns).some(
        shouldHaveFakeSecondaryLabel
      );
    case "RepeatState":
      // TODO: Doesn't take empty repeats into account:
      return (
        state.childStates.length > 0 &&
        shouldHaveFakeSecondaryLabel(state.childStates[0])
      );
    case "CardState":
    case "FullWidthState":
    case "RequireRoleState":
    case "ScopeState":
    case "VisibilityState":
      return shouldHaveFakeSecondaryLabel(state.childState);
    case "PrimarySurveyorFieldState":
    case "PrimaryTeamFieldState":
      return false;
    default:
      return checkExhausted(state);
  }
}

function CompactRepeatItem(props: RepeatItemProps) {
  const { blockKey, state, editable, onDeleteClick, disabled } = props;

  const fakeLabel = useMemo(() => shouldHaveFakeLabel(state), [state]);
  const fakeSecondaryLabel = useMemo(
    () => shouldHaveFakeSecondaryLabel(state),
    [state]
  );

  return (
    <HStack w="100%" spacing="4" align="flex-start">
      <Box flexGrow={1} flexShrink={1}>
        <BlockView blockKey={blockKey} blockState={state} disabled={disabled} />
      </Box>

      {editable && (
        <VStack flexGrow={0} flexShrink={0} spacing="2">
          {fakeLabel && <FormLabel>&nbsp;</FormLabel>}
          {fakeSecondaryLabel && <FormLabel>&nbsp;</FormLabel>}
          <IconButton
            aria-label="Delete"
            variant="outline"
            icon={<Icon as={IoCloseSharp} color="gray.400" />}
            onClick={onDeleteClick}
          />
        </VStack>
      )}
    </HStack>
  );
}

interface EmptyRepeatItemsProps {
  prompt: string;
  editable: boolean;
}

function EmptyRepeatItems(props: EmptyRepeatItemsProps) {
  const { prompt, editable } = props;
  return (
    <Box
      w="100%"
      px="6"
      py="8"
      bg="gray.100"
      color="GrayText"
      fontSize="sm"
      rounded="md"
      textAlign="center"
    >
      {editable ? prompt : "Nothing to display"}
    </Box>
  );
}
