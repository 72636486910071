import { Message } from "@cartographerio/types";

import { RuleBuilder } from "./RuleBuilder";
import { pass } from "./rules";
import * as allRules from "./rules";

export type Rule<A> = (a: A) => Message[];

export type SomeNullable<T, K extends keyof T> = Omit<T, K> &
  { [A in keyof Pick<T, K>]: T[A] | null };

export function build<A, S extends Exclude<keyof A, symbol> = never>(
  rule: Rule<A> = pass(),
  keys: S[] = []
): RuleBuilder<A, S> {
  return new RuleBuilder<A, S>(rule, keys);
}

export const rule = {
  ...allRules,
  build,
};
