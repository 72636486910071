import {
  BBox4,
  Coord2,
  Feature,
  Polygon,
  feature,
  lineString,
  polygon,
  toBBox4,
  toCoord2,
} from "@cartographerio/geometry";
import { isArrayOf, isNumber } from "@cartographerio/guard";
import { randomUuid } from "@cartographerio/types";
import {
  DrawCustomMode,
  DrawCustomModeThis,
  DrawFeature,
  DrawPolygon,
} from "@mapbox/mapbox-gl-draw";
import bbox from "@turf/bbox";
import { isEqual } from "lodash";
import { LngLat } from "mapbox-gl";

import { DrawProperties, bboxToCoords } from "./helpers";

interface BBoxModeState {
  bboxFeature: DrawPolygon | null;
  firstPos: Coord2 | null;
}

interface BBoxModeOptions {
  initial: BBox4 | null;
}

const BBOX_FEATURE_ID = randomUuid();

const initFeature = function (
  this: DrawCustomModeThis & DrawCustomMode<BBoxModeState, BBoxModeOptions>,
  initial?: BBox4 | null
): DrawPolygon {
  let bboxFeature = this.getFeature(BBOX_FEATURE_ID) as DrawPolygon | undefined;

  if (initial != null) {
    if (bboxFeature != null) {
      this.deleteFeature(BBOX_FEATURE_ID);
    }
    bboxFeature = this.newFeature(
      feature<Polygon>({
        id: BBOX_FEATURE_ID,
        geometry: polygon([bboxToCoords(initial)]),
        properties: { bbox: initial },
      })
    ) as DrawPolygon;
    this.addFeature(bboxFeature);
  } else if (bboxFeature == null) {
    bboxFeature = this.newFeature(
      feature<Polygon>({
        id: BBOX_FEATURE_ID,
        geometry: polygon([[]]),
        properties: { bbox: null },
      })
    ) as DrawPolygon;
    this.addFeature(bboxFeature);
  }

  return bboxFeature;
};

const onClick = function (
  this: DrawCustomModeThis & DrawCustomMode<BBoxModeState, BBoxModeOptions>,
  state: BBoxModeState,
  evt: { featureTarget: DrawFeature; lngLat: LngLat }
) {
  const clickPos = toCoord2([evt.lngLat.lng, evt.lngLat.lat]);

  if (state.firstPos == null || !isEqual(state.firstPos, clickPos)) {
    this.updateUIClasses({ mouse: "add" });

    if (state.firstPos == null) {
      if (state.bboxFeature == null) {
        state.bboxFeature = initFeature.apply(this);
      }
      state.bboxFeature.setProperty("bbox", null);
      state.bboxFeature.setCoordinates([[clickPos]]);
      state.firstPos = clickPos;
      this.map?.fire("draw.update", { firstPos: clickPos });
    } else if (state.bboxFeature != null) {
      const featureBBox = toBBox4(bbox(lineString([state.firstPos, clickPos])));
      state.bboxFeature.setProperty("bbox", featureBBox);
      state.bboxFeature.setCoordinates([bboxToCoords(featureBBox)]);
      state.firstPos = null;
      this.map?.fire("draw.create", { bbox: featureBBox });
    }
  }
};

const BBoxMode: DrawCustomMode<BBoxModeState, BBoxModeOptions> = {
  onClick,
  onTap: onClick,

  onMouseMove: function (state, evt) {
    if (state.bboxFeature != null && state.firstPos != null) {
      const hoverPos: Coord2 = [evt.lngLat.lng, evt.lngLat.lat];
      state.bboxFeature.updateCoordinate("0.1", ...hoverPos);
      state.bboxFeature.setProperty(
        "bbox",
        toBBox4(bbox(lineString([state.firstPos, hoverPos])))
      );
    }
  },

  onSetup: function (opts) {
    const { initial } = opts;

    const bboxFeature = initFeature.apply(this, [initial]);

    this.clearSelectedFeatures();
    if (this.map?.doubleClickZoom) {
      this.map.doubleClickZoom.disable();
    }
    this.updateUIClasses({ mouse: "add" });
    this.activateUIButton("polygon");
    this.setActionableState({
      trash: true,
      combineFeatures: false,
      uncombineFeatures: false,
    });

    return { bboxFeature, firstPos: null };
  },

  onTrash: function (state) {
    if (state.bboxFeature != null) {
      this.deleteFeature(BBOX_FEATURE_ID);
      state.bboxFeature = null;
    }
  },

  toDisplayFeatures: function (
    _state,
    geojson: Feature<Polygon, DrawProperties>,
    display
  ) {
    if (
      isArrayOf(isArrayOf(isArrayOf(isNumber)))(geojson.geometry.coordinates)
    ) {
      const coords = bboxToCoords(bbox(geojson.geometry));
      display(
        feature({
          geometry: polygon([[...coords, coords[0]]]),
          properties: { ...geojson.properties, active: "true" },
        })
      );
    }
  },
};

export default BBoxMode;
