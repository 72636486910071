import { RadioGroup } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import Spaced, { SpacedProps } from "./Spaced";

interface CardRadioGroupProps extends SpacedProps {
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  children: ReactNode;
}

export default function CardRadioGroup(
  props: CardRadioGroupProps
): ReactElement {
  const { name, value, onChange, children, ...rest } = props;

  return (
    <RadioGroup name={name} value={value} onChange={onChange}>
      <Spaced {...rest}>{children}</Spaced>
    </RadioGroup>
  );
}
