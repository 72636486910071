import { useCallback, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

/** Hook that manages sidebar visibility in wide and narrow page form factors.
 * If the user hides the sidebar on a narrow page, it stays visible on a wide page and vice versa.
 * This allows the sidebar to toggle automatically in an intuitive fashion as the page gets wider or narrower.
 */
export function useSidebarVisibility(narrow: boolean): [boolean, () => void] {
  const [showWide, setShowWide] = useLocalStorage<boolean>(
    "ShowSidebarWide",
    true
  );

  const [showNarrow, setShowNarrow] = useLocalStorage<boolean>(
    "ShowSidebarNarrow",
    false
  );

  const visible = useMemo(
    () => (narrow ? showNarrow : showWide),
    [narrow, showNarrow, showWide]
  );

  const toggle = useCallback(() => {
    if (visible) {
      // Hiding
      if (narrow) {
        setShowNarrow(false);
      } else {
        setShowNarrow(false);
        setShowWide(false);
      }
    } else {
      // Showing
      if (narrow) {
        setShowNarrow(true);
        setShowWide(true);
      } else {
        setShowWide(true);
      }
    }
  }, [narrow, setShowNarrow, setShowWide, visible]);

  return [visible, toggle];
}
