export function unsafePublishToWindow(
  value: unknown,
  key: string,
  namespace?: string
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;

  if (namespace != null && !(namespace in win)) {
    win[namespace] = {};
    win[namespace][key] = value;
  } else {
    win[key] = value;
  }
}
