import { AttachmentId, isNetworkError } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  ButtonGroup,
  Collapse,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { IoMdLink } from "react-icons/io";
import { IoDownloadOutline } from "react-icons/io5";

import {
  GalleryItem,
  UploadFailureGalleryItem,
  galleryItemOriginalUrl,
  galleryItemPreviewUrl,
  isFullAttachmentItem,
} from "../../../galleryItem";
import { OverlayIcon } from "../../form/AttachmentField/Thumbnail";
import Button from "../Button";
import IconButtonLink from "../IconButtonLink";
import JsonView from "../JsonView";
import LinkButton from "../LinkButton";
import Placeholder from "../Placeholder";
import PreviewCarousel from "./PreviewCarousel";

export interface GalleryItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  disabled?: boolean;
  loading?: boolean;
  item?: GalleryItem | null;
  isCreating?: boolean;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  onDeleteClick?: () => void;
  onSaveClick?: () => void;
  attachmentUrl?: (id: AttachmentId) => string;
  children?: ReactNode;
}

export { useGallerySelection } from "./useGallerySelection";

export default function GalleryItemModal(
  props: GalleryItemModalProps
): ReactElement {
  const {
    isOpen,
    onClose,
    disabled,
    loading = false,
    item,
    isCreating,
    onPrevClick,
    onNextClick,
    onDeleteClick,
    onSaveClick,
    attachmentUrl,
    children,
  } = props;

  const title = useMemo(() => {
    if (item == null) {
      return "Untitled Attachment";
    } else {
      switch (item.type) {
        case "AttachmentItem":
        case "UploadSuccessItem":
          return item.attachment.title ?? item.attachment.filename;
        case "InProgressItem":
          return item.attachment.title ?? "Uploading...";
        case "UploadFailureItem":
          return "Upload Failed";
      }
    }
  }, [item]);

  const previewUrl = useMemo(
    () => (item == null ? undefined : galleryItemPreviewUrl(item)),
    [item]
  );

  const viewUrl = useMemo(() => {
    switch (item?.type) {
      case "AttachmentItem":
      case "UploadSuccessItem":
        return attachmentUrl?.(item.attachment.id);

      case "InProgressItem":
      case "UploadFailureItem":
      case undefined:
        return undefined;

      default:
        return checkExhausted(item);
    }
  }, [attachmentUrl, item]);

  const downloadUrl = useMemo(
    () => (item == null ? undefined : galleryItemOriginalUrl(item)),
    [item]
  );

  const deleteLabel = useMemo(() => {
    if (onDeleteClick == null) {
      return undefined;
    } else {
      switch (item?.type) {
        case "AttachmentItem":
        case "UploadSuccessItem":
          return "Delete";

        case "InProgressItem":
        case "UploadFailureItem":
          return "Cancel Upload";

        case undefined:
          return undefined;

        default:
          return checkExhausted(item);
      }
    }
  }, [item, onDeleteClick]);

  const showFooter = onSaveClick != null || onDeleteClick != null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="2xl"
        // This compensates for the prev/next buttons,
        // which overhang the left/right of the modal:
        marginInline="6"
      >
        <ModalHeader
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          pr="16"
        >
          {title}
        </ModalHeader>

        <ModalCloseButton />

        {!loading && item == null ? (
          <Placeholder
            text="Could not find any files"
            py="10"
            w="unset"
            m="4"
          />
        ) : (
          <>
            <Box position="relative">
              <PreviewCarousel
                previewUrl={previewUrl}
                title={item?.attachment.title ?? ""}
                loading={loading || item == null}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
              />

              {item != null && (
                <Box position="absolute" bottom="2" left="2" lineHeight="0">
                  <OverlayIcon item={item} />
                </Box>
              )}

              <ButtonGroup
                position="absolute"
                bottom="2"
                right="2"
                spacing="2"
                size="sm"
              >
                {viewUrl != null && (
                  <IconButtonLink.External
                    label="Permalink"
                    icon={<IoMdLink size="1.25rem" />}
                    variant="outline"
                    bg="whiteAlpha.800"
                    size="sm"
                    rounded="lg"
                    to={viewUrl}
                  />
                )}
                {downloadUrl != null && (
                  <IconButtonLink.Internal
                    label="Download"
                    icon={<IoDownloadOutline size="1.25rem" />}
                    variant="outline"
                    bg="whiteAlpha.800"
                    size="sm"
                    rounded="lg"
                    to={downloadUrl}
                  />
                )}
              </ButtonGroup>
            </Box>

            <ModalBody
              bg="gray.50"
              px="3"
              roundedBottom={showFooter ? undefined : "md"}
            >
              {item?.type === "UploadFailureItem" && (
                <FailureItemErrors item={item} />
              )}
              {children}
            </ModalBody>

            {showFooter && (
              <ModalFooter bg="gray.50" roundedBottom="md">
                <HStack justify="space-between" w="100%">
                  {deleteLabel != null && onDeleteClick != null && (
                    <Button
                      label={deleteLabel}
                      colorScheme="red"
                      onClick={() => {
                        onDeleteClick();
                        onClose();
                      }}
                      disabled={disabled}
                    />
                  )}
                  <ButtonGroup
                    w={
                      deleteLabel == null || onDeleteClick == null
                        ? "100%"
                        : undefined
                    }
                    justifyContent={
                      deleteLabel == null || onDeleteClick == null
                        ? "space-between"
                        : undefined
                    }
                  >
                    <Button
                      label="Cancel"
                      variant="outline"
                      onClick={onClose}
                    />
                    {onSaveClick != null && (
                      <Button
                        label="Save"
                        colorScheme="blue"
                        isLoading={
                          !isCreating &&
                          (item == null || !isFullAttachmentItem(item))
                        }
                        disabled={
                          !isCreating &&
                          (disabled ||
                            item == null ||
                            !isFullAttachmentItem(item) ||
                            onSaveClick == null)
                        }
                        loadingText={
                          item?.type === "InProgressItem"
                            ? "Uploading"
                            : item?.type === "UploadFailureItem"
                            ? "Upload Failed!"
                            : undefined
                        }
                        onClick={() => {
                          onSaveClick?.();
                          onClose();
                        }}
                      />
                    )}
                  </ButtonGroup>
                </HStack>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
}

interface FailureItemErrorsProps {
  item: UploadFailureGalleryItem;
}

function FailureItemErrors(props: FailureItemErrorsProps): ReactElement {
  const { item } = props;

  const [collapsed, setCollapsed] = useState(true);

  if (isNetworkError(item.error)) {
    return (
      <Alert status="error" mb="4">
        <AlertIcon />
        <AlertDescription>
          There was a network error when uploading. Please check your
          connection. {item.error.message}
        </AlertDescription>
      </Alert>
    );
  } else {
    return (
      <Box mt="2" mb="4 ">
        <Alert status="error" mb="4">
          <AlertIcon />
          <AlertDescription
            display="flex"
            justifyContent="space-between"
            w="100%"
          >
            <chakra.span>Error uploading your image.</chakra.span>
            <LinkButton
              fontStyle="italic"
              onClick={() => setCollapsed(!collapsed)}
            >
              Details
            </LinkButton>
          </AlertDescription>
        </Alert>
        <Collapse in={!collapsed}>
          <JsonView value={item.error} />
        </Collapse>
      </Box>
    );
  }
}
