import { isString } from "@cartographerio/guard";
import { internalError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import Fuse from "fuse.js";
import { chain, take } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useEffectOnce } from "./useEffectOnce";

export type FuseOptions<T = string> = Fuse.IFuseOptions<T>;
export type FuseResult<T = string> = Fuse.FuseResult<T>;

function useFuseOptimized<T extends string>(
  haystack: T[],
  options: Fuse.IFuseOptions<string>,
  _itemToString: undefined,
  maxResults?: number,
  ignoreEmpty?: boolean,
  initialSearch?: string
): [Fuse.FuseResult<T>[], (text: string | null) => void];
function useFuseOptimized<T = string>(
  haystack: T[],
  options: Fuse.IFuseOptions<string>,
  _itemToString: (item: T) => string,
  maxResults?: number,
  ignoreEmpty?: boolean,
  initialSearch?: string
): [Fuse.FuseResult<T>[], (text: string | null) => void];
function useFuseOptimized<T = string>(
  haystack: T[],
  options: Fuse.IFuseOptions<string>,
  _itemToString?: (item: T) => string,
  maxResults?: number,
  ignoreEmpty: boolean = true,
  initialSearch: string = ""
): [Fuse.FuseResult<T>[], (text: string | null) => void] {
  const [results, setResults] = useState<Fuse.FuseResult<T>[]>([]);

  const itemToString = useMemo(
    () =>
      _itemToString != null
        ? _itemToString
        : haystack.every(isString)
        ? (item: T): string => item as string
        : raise(
            internalError(
              "useFuseOptimized must be given a getFn, or have strings as the haystack."
            )
          ),
    [_itemToString, haystack]
  );

  const stringToResultMap = useMemo(
    () =>
      haystack.reduce(
        (map: Record<string, FuseResult<T>[]>, item, refIndex) => {
          const itemString = itemToString(item);
          return {
            ...map,
            [itemString]:
              map[itemString] != null
                ? [...map[itemString], { item, refIndex }]
                : [{ item, refIndex }],
          };
        },
        {}
      ),
    [haystack, itemToString]
  );

  const stringHaystack = useMemo(
    () => Object.keys(stringToResultMap),
    [stringToResultMap]
  );

  const fuse = useMemo(
    () => new Fuse(stringHaystack, options),
    [options, stringHaystack]
  );

  const handleChange = useCallback(
    (text: string | null) => {
      if (ignoreEmpty && (text == null || text.length === 0)) {
        setResults([]);
      } else {
        if ((text?.length ?? 0) === 0) {
          setResults(haystack.map((item, refIndex) => ({ item, refIndex })));
        } else {
          const stringResults =
            maxResults == null
              ? fuse.search(text ?? "")
              : take(fuse.search(text ?? ""), maxResults);
          setResults(
            chain(stringResults)
              .flatMap(fuseResult =>
                stringToResultMap[fuseResult.item].map(cachedResult => ({
                  ...fuseResult,
                  ...cachedResult,
                }))
              )
              .sortBy(({ refIndex }) => refIndex)
              .value()
          );
        }
      }
    },
    [fuse, haystack, ignoreEmpty, maxResults, stringToResultMap]
  );

  useEffectOnce(() => handleChange(initialSearch));

  return [results, handleChange];
}

export default useFuseOptimized;
