import { Message, SurveyApprovedEmailSettings } from "@cartographerio/types";
import { FormControl, chakra } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";

import { surveyApprovedEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import Card from "../Card";
import CardBody from "../CardBody";
import CardTitle from "../CardTitle";
import Checkbox from "../Checkbox";
import ListEditor from "../ListEditor";
import MessageFormControl from "../MessageFormControl";
import NamedEmailField from "../NamedEmailField";
import RadioGroup from "../RadioGroup";
import Spaced from "../Spaced";

export interface SurveyApprovedEmailSettingsEditorProps {
  group: "project" | "team";
  value: SurveyApprovedEmailSettings;
  onChange: (value: SurveyApprovedEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function SurveyApprovedEmailSettingsEditor(
  props: SurveyApprovedEmailSettingsEditorProps
): ReactElement {
  const { value, onChange, messages, disabled = false } = props;

  const errors = useMemo(
    () => splitMessages(messages, surveyApprovedEmailSettingsErrorKeys),
    [messages]
  );

  const handleChange = useCallback(
    (value: SurveyApprovedEmailSettings) =>
      onChange({
        ...value,
        fromAddress: value.fromApprover ? null : value.fromAddress,
      }),
    [onChange]
  );

  return (
    <Card>
      <CardTitle>Survey Approved Emails</CardTitle>
      <CardBody>
        <Spaced maxW="72ch">
          <chakra.p>
            When an approver marks a survey as &quot;approved&quot;:
          </chakra.p>
          <Spaced spacing="2">
            <MessageFormControl messages={[]}>
              <Checkbox
                checkboxLabel="Send an email to the surveyor"
                value={value.toSurveyors}
                onChange={toSurveyors =>
                  handleChange({ ...value, toSurveyors })
                }
                disabled={disabled}
              />
            </MessageFormControl>
            <FormControl>
              <ListEditor.NamedEmailCheckbox
                checkboxLabel="Send an email to the following email addresses:"
                value={value.toAddresses}
                onChange={toAddresses =>
                  handleChange({ ...value, toAddresses })
                }
                disabled={disabled}
              />
            </FormControl>
          </Spaced>
          <Spaced spacing="4" ml="6">
            <ListEditor.NamedEmail
              value={value.toAddresses}
              onChange={toAddresses => handleChange({ ...value, toAddresses })}
              messages={errors.toAddresses}
              disabled={disabled}
            />
          </Spaced>
          <chakra.p>
            Survey approval notifications should be sent from:
          </chakra.p>
          <MessageFormControl messages={[]}>
            <RadioGroup
              value={value.fromApprover}
              onChange={fromApprover =>
                handleChange({ ...value, fromApprover })
              }
              options={[
                { label: "the email address of the approver", value: true },
                { label: "the following email address:", value: false },
              ]}
              disabled={disabled}
            />
          </MessageFormControl>
          <Spaced ml="6">
            <NamedEmailField
              value={value.fromAddress ?? undefined}
              onChange={fromAddress => handleChange({ ...value, fromAddress })}
              messages={errors.fromAddress}
              disabled={disabled || value.fromApprover}
            />
          </Spaced>
        </Spaced>
      </CardBody>
    </Card>
  );
}
