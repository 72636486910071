import { Alert, AlertDescription, HStack } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

import { AnyPathPart, AnyQueryParams } from "../../routes/base";
import {
  DeriveIncomingQueryProps,
  DeriveQueryUpdate,
} from "../../routes/derive";
import { Route } from "../../routes/Route";
import { typedToEntries } from "../../util/typedEntries";
import Button from "./Button";

export interface ResetFiltersAlertProps<
  P extends AnyPathPart[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  Q extends AnyQueryParams = {}
> {
  route: Route<P, Q>;
  query: DeriveIncomingQueryProps<Q>;
  updateQuery: DeriveQueryUpdate<Q>;
}

export default function ResetFiltersAlert<
  P extends AnyPathPart[],
  // eslint-disable-next-line @typescript-eslint/ban-types
  Q extends AnyQueryParams = {}
>(props: ResetFiltersAlertProps<P, Q>) {
  const { route, query, updateQuery } = props;

  const defaults = useMemo(() => route.defaultQueryProps(), [route]);

  const show = useMemo(() => {
    return (
      defaults != null &&
      typedToEntries(defaults).some(([key, value]) => query[key] !== value)
    );
  }, [query, defaults]);

  const handleResetClick = useCallback(() => {
    defaults != null && updateQuery(defaults);
  }, [defaults, updateQuery]);

  return show ? (
    <Alert status="warning" rounded="md">
      <HStack w="100%" justify="center">
        <AlertDescription>
          You have applied custom filters/orderings to this list.
        </AlertDescription>
        <Button
          size="sm"
          colorScheme="yellow"
          onClick={handleResetClick}
          label="Clear All"
        />
      </HStack>
    </Alert>
  ) : null;
}
