import { permissionCheckPasses } from "@cartographerio/permission";
import {
  PermissionCheck,
  forbiddenError,
  identityToCheckIdentity,
  nowTimestamp,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { ReactElement, ReactNode, useMemo } from "react";
import { Navigate } from "react-router-dom";

import { useCredentialsV2 } from "../contexts/auth";

export type RequirePermissionProps = {
  check: PermissionCheck;
  graph?: WorkspaceGraphV2;
  children: ReactNode;
} & (
  | { failMode: "forbid" }
  | {
      failMode?: "fallback";
      go?: string;
      replace?: boolean;
      fallback?: ReactNode;
    }
);

export default function RequirePermission(
  props: RequirePermissionProps
): ReactElement {
  const { check, graph, children } = props;

  const { identity } = useCredentialsV2();

  const passes = useMemo(
    () =>
      permissionCheckPasses(check, identityToCheckIdentity(identity), graph),
    [check, graph, identity]
  );

  return useMemo(() => {
    if (props.failMode === "forbid") {
      return passes ? (
        <>{children}</>
      ) : (
        raise(
          forbiddenError(
            check,
            identityToCheckIdentity(identity),
            nowTimestamp()
          )
        )
      );
    } else {
      const {
        go,
        replace = true,
        fallback = go != null ? <Navigate to={go} replace={replace} /> : null,
      } = props;
      return <>{passes ? children : fallback}</>;
    }
  }, [check, children, identity, passes, props]);
}
