import { EffectCallback, useEffect, useRef } from "react";

export function useEffectOnce(effect: EffectCallback) {
  const called = useRef(false);
  const effectDestructor = useRef<ReturnType<EffectCallback>>(undefined);

  return useEffect(() => {
    if (!called.current) {
      effectDestructor.current = effect();
      called.current = true;
    }
    return effectDestructor.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
