import { ReactElement } from "react";

import TabLink from "./TabLink";

interface PageTabProps {
  id: string;
  label: string;
  to: string;
}

export default function PageTab(props: PageTabProps): ReactElement {
  const { label, to } = props;

  return (
    <TabLink to={to} mb={[2, "-2px", "-2px"]}>
      {label}
    </TabLink>
  );
}
