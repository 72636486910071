import { SelectOption } from "@cartographerio/atlas-form";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  InvitationCodeCreateV2,
  ProjectRoleName,
  ProjectRoleNameEnum,
  ProjectV2,
  QualificationRoleName,
  QualificationRoleNameEnum,
  TeamId,
  TeamRoleName,
  TeamRoleNameEnum,
  TeamV2,
  WorkspaceV2,
  projectRoleV2,
  qualificationRole,
  teamRoleV2,
} from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import queries from "../../queries";
import {
  invitationCodeErrorKeys,
  invitationCodeRule,
} from "../../schema/invitationCode";
import { splitMessages } from "../../schema/rule/errors";
import { useApiParams } from "../contexts/auth";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../hooks/useProjectHasTeams";
import { blankInvitationCode } from "../pages/workspace/WorkspaceInvitationCodeCreatePage";
import { useIOErrorAlert } from "./Alert";
import Fieldset from "./Fieldset";
import FormLabel from "./FormLabel";
import InvitationCodeSettingsEditor from "./InvitationCodeSettingsEditor";
import MessageFormControl from "./MessageFormControl";
import Select from "./Select";
import Spaced from "./Spaced";
import TextField from "./TextField";
import TimestampField from "./TimestampField";
import {
  PROJECT_ROLE_HELP,
  QUALIFICATION_ROLE_HELP,
  TEAM_HELP,
  TEAM_ROLE_HELP,
} from "./UserAddModal";

interface ProjectInviteModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  workspace: WorkspaceV2;
  project: ProjectV2;
  teams?: TeamV2[] | null;
  defaultTeam?: TeamId | null;
}

function ProjectInviteModal(props: ProjectInviteModalProps): ReactElement {
  const { title, isOpen, onClose, workspace, project, teams, defaultTeam } =
    props;

  const multiTeam = useProjectHasTeams(workspace, project);
  const hasPermission = usePermissionCheckRunner();

  const [value, setValue] = useState<InvitationCodeCreateV2>(() =>
    blankInvitationCode(project.workspaceId)
  );
  const [team, setTeam] = useState<TeamId | null>(defaultTeam ?? null);

  const canEditProjectRole = useMemo(
    () => hasPermission(checks.project.grantAccess(project)),
    [hasPermission, project]
  );

  const teamOptions = useMemo<SelectOption<TeamId>[] | null>(
    () =>
      teams == null || !multiTeam
        ? null
        : filterAndMap(teams, team =>
            hasPermission(checks.team.grantAccess(team))
              ? { label: team.name, value: team.id }
              : null
          ),
    [hasPermission, multiTeam, teams]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      project.qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            hasPermission(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [hasPermission, project.qualificationIds]
  );

  const [projectRole, setProjectRole] = useState<ProjectRoleName>("Member");
  const [teamRole, setTeamRole] = useState<TeamRoleName>("Member");
  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const messages = useMemo(() => invitationCodeRule(value), [value]);

  const errors = useMemo(
    () => splitMessages(messages, invitationCodeErrorKeys),
    [messages]
  );

  const saveCode = useInvitationCodeSaveCallback();

  const handleSubmit = useCallback(
    () =>
      saveCode({
        ...value,
        roles: [
          projectRoleV2(projectRole, project.id),
          ...(team != null ? [teamRoleV2(teamRole, team)] : []),
        ],
        qualificationRoles:
          qualificationRoleName != null
            ? project.qualificationIds.map(id =>
                qualificationRole(qualificationRoleName, id)
              )
            : [],
      })
        .cleanup(() => {
          onClose();
          setValue(blankInvitationCode(project.workspaceId));
        })
        .unsafeRun(),
    [
      saveCode,
      value,
      projectRole,
      project,
      team,
      teamRole,
      qualificationRoleName,
      onClose,
    ]
  );

  useEffect(() => {
    setTeam(defaultTeam ?? null);
    setTeamRole("Member");
  }, [defaultTeam]);

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced>
            <InvitationCodeSettingsEditor
              value={value}
              onChange={setValue}
              errors={errors}
              disabled={false}
            />

            {canEditProjectRole && (
              <FormControl>
                <FormLabel
                  text="Project Role"
                  help={
                    multiTeam
                      ? PROJECT_ROLE_HELP.MULTI_TEAM
                      : PROJECT_ROLE_HELP.SINGLE_TEAM
                  }
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={projectRole}
                  onChange={setProjectRole}
                  options={ProjectRoleNameEnum.entries}
                />
              </FormControl>
            )}

            {teamOptions != null && (
              <>
                <FormControl>
                  <FormLabel
                    text={canEditProjectRole ? "Team (Optional)" : "Team"}
                    help={
                      canEditProjectRole
                        ? TEAM_HELP.PROJECT_COORDINATOR
                        : TEAM_HELP.TEAM_COORDINATOR
                    }
                    helpPortalContainerRef={modalRef}
                  />
                  {canEditProjectRole ? (
                    <Select.Nullable
                      placeholder="No Team"
                      value={team ?? null}
                      onChange={setTeam}
                      options={teamOptions}
                    />
                  ) : (
                    <Select.Standard
                      placeholder="Select a Team"
                      value={team ?? null}
                      onChange={setTeam}
                      options={teamOptions}
                    />
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel
                    text="Team Role"
                    help={TEAM_ROLE_HELP}
                    helpPortalContainerRef={modalRef}
                  />
                  <Select.Standard
                    value={teamRole}
                    onChange={setTeamRole}
                    options={TeamRoleNameEnum.entries}
                    disabled={team == null}
                  />
                </FormControl>
              </>
            )}

            {qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={messages.length > 0}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

interface TeamInviteModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  team: TeamV2;
  teamProjects: ProjectV2[];
}

function TeamInviteModal(props: TeamInviteModalProps): ReactElement {
  const { title, isOpen, onClose, team, teamProjects } = props;

  const hasPermission = usePermissionCheckRunner();

  const qualificationIds = useMemo(
    () => [
      ...new Set(
        teamProjects.flatMap(({ qualificationIds }) => qualificationIds)
      ),
    ],
    [teamProjects]
  );

  const qualificationRoleNameOptions = useMemo(
    () =>
      qualificationIds
        .map(id =>
          QualificationRoleNameEnum.entries.filter(({ value }) =>
            hasPermission(checks.qualification.grant(value, id))
          )
        )
        .reduce(
          (acc: SelectOption<QualificationRoleName>[] | null, curr) =>
            acc == null
              ? curr
              : acc.filter(
                  ({ value }) =>
                    curr.find(other => value === other.value) != null
                ),
          null
        ) ?? [],
    [hasPermission, qualificationIds]
  );

  const [value, setValue] = useState<InvitationCodeCreateV2>(() =>
    blankInvitationCode(team.workspaceId)
  );
  const [teamRole, setTeamRole] = useState<TeamRoleName>("Member");
  const [qualificationRoleName, setQualificationRoleName] =
    useState<QualificationRoleName | null>(null);

  const messages = useMemo(() => invitationCodeRule(value), [value]);

  const errors = useMemo(
    () => splitMessages(messages, invitationCodeErrorKeys),
    [messages]
  );

  const saveCode = useInvitationCodeSaveCallback();

  const handleSubmit = useCallback(
    () =>
      saveCode({
        ...value,
        roles: [teamRoleV2(teamRole, team.id)],
        qualificationRoles:
          qualificationRoleName != null
            ? qualificationIds.map(id =>
                qualificationRole(qualificationRoleName, id)
              )
            : [],
      })
        .cleanup(() => {
          setValue(blankInvitationCode(team.workspaceId));
          onClose();
        })
        .unsafeRun(),
    [
      onClose,
      qualificationIds,
      qualificationRoleName,
      saveCode,
      team,
      teamRole,
      value,
    ]
  );

  const canEditTeamRole = useMemo(
    () => hasPermission(checks.team.grantAccess(team)),
    [hasPermission, team]
  );

  const modalRef = useRef<HTMLElement>(null);

  return (
    <Modal size="lg" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="2" ref={modalRef}>
        <ModalCloseButton />
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <Spaced spacing="4">
            <MessageFormControl label="Name" messages={errors.name}>
              <TextField.String
                value={value.name}
                onChange={name => setValue(value => ({ ...value, name }))}
              />
            </MessageFormControl>

            {canEditTeamRole && (
              <FormControl>
                <FormLabel
                  text="Team Role"
                  help={TEAM_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Standard
                  value={teamRole}
                  onChange={setTeamRole}
                  options={TeamRoleNameEnum.entries}
                />
              </FormControl>
            )}

            {qualificationRoleNameOptions.length > 0 && (
              <FormControl>
                <FormLabel
                  text="Qualification Role (Optional)"
                  help={QUALIFICATION_ROLE_HELP}
                  helpPortalContainerRef={modalRef}
                />
                <Select.Nullable
                  value={qualificationRoleName}
                  onChange={setQualificationRoleName}
                  options={qualificationRoleNameOptions}
                />
              </FormControl>
            )}

            <Fieldset legend="Optional Settings">
              <MessageFormControl label="Optional Expiry Date" messages={[]}>
                <TimestampField
                  label="Expiry Date"
                  value={value.expires ?? null}
                  onChange={expires => setValue({ ...value, expires })}
                  nullable={true}
                />
              </MessageFormControl>

              <InvitationCodeSettingsEditor
                value={value}
                onChange={setValue}
                errors={errors}
                disabled={false}
              />
            </Fieldset>
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            isDisabled={messages.length > 0}
            onClick={handleSubmit}
          >
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function useInvitationCodeSaveCallback() {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback(
    (invite: InvitationCodeCreateV2) =>
      queries.invitation.code.v3
        .create(queryClient, apiParams, invite)
        .flatMap(IO.fromResult)
        .tap(_ =>
          toast({ title: "Invitation code created", status: "success" })
        )
        .tapError(errorAlert),
    [apiParams, errorAlert, queryClient, toast]
  );
}

export default {
  Project: ProjectInviteModal,
  Team: TeamInviteModal,
};
