import { Message } from "@cartographerio/types";
import { List, ListItem, ListProps } from "@chakra-ui/react";
import { ReactElement } from "react";

import MessageLabel from "./MessageLabel";

interface MessageListProps extends ListProps {
  messages?: Message[];
}

export default function MessageList(
  props: MessageListProps
): ReactElement | null {
  const { messages, ...rest } = props;

  return messages == null || messages.length === 0 ? null : (
    <List {...rest}>
      {messages.map(({ level, text }, index) => (
        <ListItem key={index}>
          <MessageLabel level={level} text={text} />
        </ListItem>
      ))}
    </List>
  );
}
