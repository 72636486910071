import { Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import { check } from "@cartographerio/permission";
import {
  KnownUserFeatureEnum,
  KnownWorkspaceFeatureEnum,
  Message,
  RoleV2,
  UserV2,
  WorkspaceV2,
  nowTimestamp,
  randomUserId,
  unsafeEmail,
  unsafeScreenName,
} from "@cartographerio/types";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { ButtonGroup, Flex, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { FaMask } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { userRule } from "../../../schema/user";
import { useIOErrorAlert } from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import Button from "../../components/Button";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import RequirePermission from "../../components/RequirePermission";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import UserEditor from "../../components/UserEditor";
import WelcomeEmailButton from "../../components/WelcomeEmailButton";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import RecordMetadata from "../workspace/RecordMetadata";

interface BaseUserPageProps {
  asTitle?: boolean;
  defaultUser?: UserV2;
  nextRedirect: string;
  workspace?: WorkspaceV2;
  workspaceGraph: WorkspaceGraphV2;
  blankUserRoles?: RoleV2[];
}

export default function BaseUserPage(props: BaseUserPageProps): ReactElement {
  const {
    asTitle = false,
    defaultUser,
    nextRedirect,
    workspace,
    workspaceGraph,
    blankUserRoles,
  } = props;

  const title = useMemo(
    () =>
      defaultUser != null
        ? `${defaultUser.firstName} ${defaultUser.lastName}`
        : "New User",
    [defaultUser]
  );

  return (
    <PageContainer width="narrow">
      <Spaced spacing="8">
        <Spaced spacing="4">
          {asTitle ? (
            <PageHeader title={title} />
          ) : (
            <BackButtonHeading my="0" to={nextRedirect}>
              {title}
            </BackButtonHeading>
          )}

          {defaultUser != null && (
            <Flex justify="flex-end">
              <UserButtons user={defaultUser} workspace={workspace} />
            </Flex>
          )}
        </Spaced>

        <BaseUserEditor
          defaultUser={defaultUser}
          blankUserRoles={blankUserRoles}
          workspace={workspace}
          workspaceGraph={workspaceGraph}
        />
      </Spaced>
    </PageContainer>
  );
}

interface BaseUserEditorProps {
  defaultUser?: UserV2;
  blankUserRoles?: RoleV2[];
  workspace?: WorkspaceV2;
  workspaceGraph: WorkspaceGraphV2;
}

export function BaseUserEditor(props: BaseUserEditorProps): ReactElement {
  const { defaultUser, blankUserRoles, workspace, workspaceGraph } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const [user, setUser] = useState<UserV2>(
    () => defaultUser ?? blankUser(blankUserRoles)
  );

  const codesEnabled = useMemo(
    () =>
      workspace?.features.includes(
        KnownWorkspaceFeatureEnum.EnableInvitationCodes
      ) ?? true,
    [workspace?.features]
  );

  const invitationCodes = useSuspenseSearchResults(
    queries.optional(codesEnabled ? defaultUser?.id : null, user =>
      queries.invitation.code.v3.search(apiParams, {
        workspace: workspace?.id,
        user,
      })
    )
  );

  const invitationCodeSignups = useSuspenseSearchResults(
    queries.optional(codesEnabled ? defaultUser?.id : null, user =>
      queries.invitation.code.signup.v3.search(apiParams, {
        workspace: workspace?.id,
        user,
      })
    )
  );

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const defaultQualificationHistories =
    useSuspenseQueryData(
      queries.optional(defaultUser?.id, user =>
        queries.qualification.history.v1.readAll(apiParams, user)
      )
    ) ?? {};

  const [qualificationHistories, setQualificationHistories] = useState(
    defaultQualificationHistories ?? {}
  );

  const defaultQualificationRegisterSettings = useSuspenseQueryData(
    queries.optional(defaultUser?.id, user =>
      queries.qualification.register.settings.v1.readAll(apiParams, user)
    )
  );

  const [qualificationRegisterSettings, setQualificationRegisterSettings] =
    useState(defaultQualificationRegisterSettings ?? {});

  const qualificationRegisterEntries =
    useSuspenseQueryData(
      queries.optional(defaultUser?.id, user =>
        queries.qualification.register.v1.userSearch(apiParams, user)
      )
    ) ?? {};

  const messages = useMemo(() => userRule(user), [user]);

  const handleSave = useCallback(
    () =>
      (messages.length === 0
        ? IO.tupled([
            queries.user.v2.save(queryClient, apiParams, user.id, {
              ...user,
              qualificationRoles: null,
            }),
            queries.qualification.history.v1.saveAll(
              queryClient,
              apiParams,
              user.id,
              qualificationHistories
            ),
            queries.qualification.register.settings.v1.saveAll(
              queryClient,
              apiParams,
              user.id,
              qualificationRegisterSettings
            ),
          ])
        : IO.pure(Result.fail<Message[], UserV2>(messages))
      )
        .tap(() =>
          toast({
            title: "Saved user",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [
      apiParams,
      errorAlert,
      qualificationHistories,
      messages,
      queryClient,
      qualificationRegisterSettings,
      toast,
      user,
    ]
  );

  return (
    <Spaced spacing="8" pb="32">
      <UserEditor
        myAccount={false}
        value={user}
        invitationCodes={invitationCodes}
        invitationCodeSignups={invitationCodeSignups}
        qualifications={qualifications}
        defaultQualificationHistories={defaultQualificationHistories}
        qualificationHistories={qualificationHistories}
        qualificationRegisterSettings={qualificationRegisterSettings}
        qualificationRegisterEntries={qualificationRegisterEntries}
        onChange={setUser}
        onQualificationHistoryChange={setQualificationHistories}
        onQualificationRegisterSettingsChange={setQualificationRegisterSettings}
        messages={messages}
        workspaceGraph={workspaceGraph}
        defaultWorkspace={workspace?.id}
      />

      <SaveButton onClick={handleSave} messages={messages} />

      <RecordMetadata.Disclosure>
        <RecordMetadata.Item label="User ID" value={user.id} />
      </RecordMetadata.Disclosure>
    </Spaced>
  );
}

function blankUser(roles: RoleV2[] = []): UserV2 {
  return {
    id: randomUserId(),
    firstName: "",
    lastName: "",
    screenName: unsafeScreenName(""),
    email: unsafeEmail(""),
    roles,
    qualificationRoles: [],
    features: [
      KnownUserFeatureEnum.Mobile2Preview,
      KnownUserFeatureEnum.Mobile2Default,
    ],
    created: nowTimestamp(),
    updated: nowTimestamp(),
  };
}

interface UserButtonsProps {
  user: UserV2;
  workspace?: WorkspaceV2;
}

function UserButtons(props: UserButtonsProps): ReactElement {
  const { user, workspace } = props;

  const navigate = useNavigate();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const onWelcomeEmailSend = useCallback(
    (error?: unknown) =>
      error == null
        ? toast({
            title: "Sent welcome email",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        : errorAlert(error),
    [errorAlert, toast]
  );

  return (
    <ButtonGroup size="sm">
      <WelcomeEmailButton
        email={user.email}
        workspace={workspace?.alias}
        onSend={onWelcomeEmailSend}
      />
      <RequirePermission check={check.superuser}>
        <Button
          variant="outline"
          label="Impersonate"
          leftIcon={<FaMask />}
          onClick={() =>
            navigate(
              routes.identity.change.url([user.id], {
                go:
                  workspace != null
                    ? routes.workspace.home.url([workspace.alias])
                    : routes.home.url([]),
              })
            )
          }
        />
      </RequirePermission>
    </ButtonGroup>
  );
}
