import { BoxProps, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

export type ParaProps = BoxProps;

export default function Para(props: ParaProps): ReactElement {
  const { children, my = "2", ...rest } = props;

  return (
    <chakra.p my={my} {...rest}>
      {children}
    </chakra.p>
  );
}
