import { Message, WorkspaceEmailSettings } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import { workspaceEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import Spaced from "../Spaced";
import { InvitationEmailSettingsEditor } from "./InvitationEmailSettingsEditor";
import { WorkspaceJoinerEmailSettingsEditor } from "./WorkspaceJoinerEmailSettingsEditor";

export interface WorkspaceEmailSettingsEditorProps {
  value: WorkspaceEmailSettings;
  onChange?: (value: WorkspaceEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function WorkspaceEmailSettingsEditor(
  props: WorkspaceEmailSettingsEditorProps
): ReactElement {
  const { value, onChange, messages, disabled } = props;

  const errors = useMemo(
    () => splitMessages(messages, workspaceEmailSettingsErrorKeys),
    [messages]
  );

  return (
    <Spaced spacing="4">
      <InvitationEmailSettingsEditor
        group="workspace"
        value={value.invitation}
        onChange={invitation => onChange?.({ ...value, invitation })}
        messages={errors.invitation}
        disabled={disabled}
      />

      <WorkspaceJoinerEmailSettingsEditor
        value={value.joiner}
        onChange={joiner => onChange?.({ ...value, joiner })}
        messages={errors.joiner}
        disabled={disabled}
      />
    </Spaced>
  );
}
