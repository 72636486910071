import { SystemProps, Td, Tr } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { ActionsColumn, Column } from "./column";

interface EmptyRowsProps<A, O extends string> {
  columns: Column<A, O>[];
  rowProps?: SystemProps;
  actions?: ActionsColumn<A>;
  selectable?: boolean;
}

export function EmptyRows<A, O extends string>(
  props: EmptyRowsProps<A, O>
): ReactElement {
  const { columns, rowProps, actions, selectable } = props;

  const colSpan = useMemo(
    () => columns.length + Number(actions != null) + Number(selectable),
    [actions, columns.length, selectable]
  );

  return (
    <Tr {...rowProps}>
      <Td
        display={["block", "block", "table-cell"]}
        colSpan={colSpan}
        py="8"
        bg="gray.100"
        color="GrayText"
        fontSize="sm"
        textAlign="center"
      >
        There are no results to display
      </Td>
    </Tr>
  );
}
