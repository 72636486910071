import {
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  chakra,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

import usePagination, {
  PaginationPage,
  PaginationParams,
} from "../hooks/usePagination";

interface PaginationProps extends PaginationParams {
  onClick?: (page: PaginationPage) => void;
}

export default function Pagination(
  props: PaginationProps
): ReactElement | null {
  const { onClick, ...params } = props;
  const pagination = usePagination(params);

  if (pagination == null) {
    return null;
  }

  const { ellipsis, first, last, next, prev, nearby, showing } = pagination;

  return pagination == null ? null : (
    <Stack spacing="2" alignItems="center">
      <HStack>
        <IconButton
          aria-label="First page"
          icon={<Icon as={FiChevronsLeft} />}
          size="sm"
          isDisabled={first.active}
          onClick={() => onClick?.(first)}
        />
        <IconButton
          aria-label="Previous page"
          icon={<Icon as={FiChevronLeft} />}
          size="sm"
          isDisabled={prev.active}
          onClick={() => onClick?.(prev)}
        />
        {ellipsis && (
          <Icon
            as={IoEllipsisHorizontalSharp}
            color={ellipsis.left ? "gray.400" : "transparent"}
          />
        )}
        {nearby.map(page => (
          <Button
            size="sm"
            key={page.pageIndex}
            isDisabled={page.active}
            onClick={() => onClick?.(page)}
          >
            {page.pageIndex + 1}
          </Button>
        ))}
        {ellipsis && (
          <Icon
            as={IoEllipsisHorizontalSharp}
            color={ellipsis.right ? "gray.400" : "transparent"}
          />
        )}
        <IconButton
          aria-label="next page"
          icon={<Icon as={FiChevronRight} />}
          size="sm"
          isDisabled={next.active}
          onClick={() => onClick?.(next)}
        />
        <IconButton
          aria-label="Last page"
          icon={<Icon as={FiChevronsRight} />}
          size="sm"
          isDisabled={last.active}
          onClick={() => onClick?.(last)}
        />
      </HStack>
      {showing && (
        <chakra.p>
          Showing items {showing.from + 1} to {showing.to} of {showing.of}
        </chakra.p>
      )}
    </Stack>
  );
}
