import {
  Timestamp,
  TimestampFormat,
  ddmmyyyy,
  formatTimestamp,
  parseAnyTimestamp,
} from "@cartographerio/types";
import { InputElementProps } from "@chakra-ui/input";
import {
  FocusEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { BaseTextFieldProps } from "./base";
import NullableString from "./NullableString";

export interface TimestampTextFieldProps
  extends BaseTextFieldProps<Timestamp>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  format?: TimestampFormat;
}

export default function TimestampTextField(
  props: TimestampTextFieldProps
): ReactElement {
  const {
    defaultValue,
    value,
    onChange,
    onBlur,
    disabled,
    format = ddmmyyyy,
    ...rest
  } = props;

  const [stringTimestamp, setStringTimestamp] = useState<string | null>(() =>
    defaultValue != null ? formatTimestamp(defaultValue, { format }) : null
  );

  useEffect(() => {
    setStringTimestamp(
      value != null ? formatTimestamp(value, { format }) : null
    );
  }, [format, value]);

  const handleBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
    evt => {
      onBlur?.(evt);
      if (onChange != null) {
        const parsed =
          stringTimestamp != null
            ? parseAnyTimestamp(stringTimestamp) ?? null
            : null;
        if (parsed != null) {
          onChange(parsed);
        }
      }
    },
    [onBlur, onChange, stringTimestamp]
  );

  return (
    <NullableString
      value={stringTimestamp}
      onChange={setStringTimestamp}
      disabled={disabled}
      onBlur={handleBlur}
      {...rest}
    />
  );
}
