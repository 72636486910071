import { AttachmentCategory } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, Icon, Input, chakra } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import * as uuid from "uuid";

import { useAlert } from "../../components/Alert";

const MAX_UPLOAD_SIZE_MB = 20;
const ONE_MB = 1024 ** 2;

function getAcceptArray(category: AttachmentCategory): string[] {
  switch (category) {
    case "image":
      return [".png", ".jpg", ".jpeg", ".gif"];
    case "document":
      return [".pdf", ".doc", ".docx", ".odt"];
    case "spreadsheet":
      return [".csv", ".xls", ".xlsx", ".ods"];
    case "presentation":
      return [".ppt", ".pptx"];
    case "archive":
      return [".zip"];
    case "unknown":
      return ["*/*"];
    case "text":
      return [".txt", ".md", ".tex"];
    default:
      return checkExhausted(category);
  }
}

export interface UploadButtonProps {
  disabled: boolean;
  onSelectFile: (file: File) => void;
  suggestedCategories?: AttachmentCategory[];
}

export default function UploadButton(props: UploadButtonProps): ReactElement {
  const { disabled, onSelectFile, suggestedCategories } = props;

  const id = useMemo(() => `file-upload-${uuid.v4()}`, []);

  const alert = useAlert();

  const accept = useMemo(
    () =>
      suggestedCategories == null
        ? getAcceptArray("image").join(",")
        : suggestedCategories.flatMap(getAcceptArray).join(","),
    [suggestedCategories]
  );

  const handleSelectFile = useCallback(
    (file: File) => {
      if (file != null) {
        if (file.size < MAX_UPLOAD_SIZE_MB * ONE_MB) {
          onSelectFile(file);
        } else {
          alert({
            title: "Uploaded file is too large",
            message: `Maximum accepted size is ${MAX_UPLOAD_SIZE_MB} MB.`,
          });
        }
      }
    },
    [alert, onSelectFile]
  );

  return (
    <Box
      m="2"
      w="40"
      h="40"
      rounded="md"
      borderWidth="1px"
      borderStyle="dashed"
      borderColor="gray.300"
      shadow="sm"
      _hover={{ bg: "gray.100" }}
    >
      <chakra.label
        w="100%"
        h="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        htmlFor={id}
        cursor="pointer"
        title="Upload a File"
      >
        <Icon w="8" h="8" as={AiOutlineCloudUpload} />
        <chakra.span>Upload</chakra.span>
        <chakra.span color="gray.400" fontSize="sm">
          (max {MAX_UPLOAD_SIZE_MB} MB)
        </chakra.span>
        <Input
          display="none"
          id={id}
          disabled={disabled}
          accept={accept}
          type="file"
          onChange={event => {
            event.preventDefault();
            handleSelectFile((event.target.files as FileList)[0]);
          }}
        />
      </chakra.label>
    </Box>
  );
}
