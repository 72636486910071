import { FeatureFieldMapOptions } from "@cartographerio/atlas-form";
import {
  Feature,
  GeoJsonProperties,
  Geometry,
  Picked,
  point as makePoint,
  picked,
} from "@cartographerio/geometry";
import { Box, VStack } from "@chakra-ui/react";
import { defaults } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";

import { Highlight, useInputHighlight } from "../../hooks/highlight";
import AttributeGrid from "./AttributeGrid";
import FeatureFieldMap from "./FeatureFieldMap";

export const DEFAULT_MAP_OPTIONS: Partial<FeatureFieldMapOptions> = {
  selectMinZoom: 10,
  selectTolerancePixels: 10,
};

export interface DefaultFeatureFieldProps<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
> {
  value: Feature<G, P> | null;
  onChange: (newValue: Feature<G, P> | null) => void;
  highlight?: Highlight;
  disabled?: boolean;
  mapOptions: FeatureFieldMapOptions;
}

export default function Default<
  G extends Geometry = Geometry,
  P extends GeoJsonProperties = GeoJsonProperties
>(props: DefaultFeatureFieldProps<G, P>): ReactElement {
  const {
    value,
    onChange,
    disabled,
    highlight,
    mapOptions: _mapOptions,
  } = props;

  const mapOptions = useMemo(
    () => defaults(_mapOptions, DEFAULT_MAP_OPTIONS),
    [_mapOptions]
  );

  const [feature, setFeature] = useState<Feature<G, P> | null>(value);

  const handleChange = useCallback(
    (newValue: Picked<G, P> | null) => {
      setFeature(newValue?.feature ?? null);
      onChange(newValue?.feature ?? null);
    },
    [onChange]
  );

  const { borderColor, borderWidth } = useInputHighlight(highlight, false);

  return (
    <VStack spacing="2" alignItems="stretch">
      <Box
        borderRadius="md"
        borderColor={borderColor}
        borderWidth={borderWidth}
        rounded="md"
      >
        <FeatureFieldMap<G, P>
          defaultValue={
            feature != null
              ? // point is ignored
                picked({ feature, point: makePoint(0, 0) })
              : null
          }
          onChange={handleChange}
          highlight={highlight}
          disabled={disabled}
          mapOptions={mapOptions}
        />

        {mapOptions.attributes.length > 0 && (
          <AttributeGrid
            roundedTop="none"
            roundedBottom="md"
            columns={mapOptions.attributeColumns}
            attributes={mapOptions.attributes}
            properties={feature?.properties}
          />
        )}
      </Box>
    </VStack>
  );
}
