import { Attribution } from "@cartographerio/atlas-map";
import { Box, Icon, VStack } from "@chakra-ui/react";
import lodash from "lodash";
import { TransformRequestFunction } from "mapbox-gl";
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { IoLayersSharp } from "react-icons/io5";
import { RiSideBarFill } from "react-icons/ri";
import {
  AttributionControl,
  GeolocateControl,
  MapProps,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";

import LegendPanel from "../map/panel/LegendPanel";
import AtlasMap from "./AtlasMap";
import {
  useBaseStyleId,
  useMapSchema,
  useSelectedFeatures,
  useSelectedLayer,
} from "./AtlasMapContext";
import CustomControlGroup from "./control/CustomControlGroup";
import MapButton from "./control/MapButton";
import {
  DownloadUrlFunction,
  InitialViewState,
  TileUrlFunction,
} from "./helpers";
import { AttributeMenuPanel, FeatureTablePanel, LayerMenuPanel } from "./panel";
import FilterPanel from "./panel/FilterPanel";
import InspectorPanel from "./panel/InspectorPanel";

const INSPECTOR_WIDTH = 240;
const INSPECTOR_WIDTH_PX = `${INSPECTOR_WIDTH}px`;

const NAVIGATION_CONTROL_CSS_STYLE: CSSProperties = {
  marginTop: 50,
};

export type OnShowInspectorChange = (shown: boolean) => void;

interface AtlasMapWithInspectorProps extends MapProps {
  defaultViewport?: InitialViewState;
  defaultShowInspector?: boolean;
  transformRequest?: TransformRequestFunction;
  cartographerDownloadUrl: DownloadUrlFunction;
  cartographerTileUrl: TileUrlFunction;
  scrollZoom?: boolean;
  onShowInspectorChange?: OnShowInspectorChange;
  children?: ReactNode;
}

export function mapboxAttributionHtml(attr: Attribution): string {
  function link(url: string | undefined, label: string): string {
    return url == null
      ? label
      : `<a href="${url}" target="_blank">${label}</a>`;
  }

  switch (attr.type) {
    case "StandardAttribution": {
      const { who, url } = attr;

      return link(url, `&copy; ${who}`);
    }

    case "OsAttribution": {
      const { url } = attr;

      return link(url, `Crown Copyright`);
    }
  }
}

export default function AtlasMapWithInspector(
  props: AtlasMapWithInspectorProps
): ReactElement {
  const {
    defaultViewport,
    defaultShowInspector = true,
    transformRequest,
    cartographerDownloadUrl,
    cartographerTileUrl,
    scrollZoom,
    onShowInspectorChange = () => undefined,
    children,
    ...rest
  } = props;

  const schema = useMapSchema();

  const [inspectorMode, setInspectorMode] = useState<InspectorMode>(
    schema.layers.length === 1 ? "AttributeMenu" : "LayerMenu"
  );

  const [base, setBase] = useBaseStyleId();

  const [showInspector, setShowInspector] = useState(defaultShowInspector);

  const SCALE_CONTROL_CSS_STYLE = useMemo(
    () => ({
      backgroundColor: "transparent",
      color: base === "satellite" ? "white" : "black",
      borderColor: base === "satellite" ? "white" : "black",
    }),
    [base]
  );

  const attribution = useMemo(
    () =>
      lodash
        .chain(schema.layers)
        .flatMap(layer => layer.source.attribution)
        .map(mapboxAttributionHtml)
        .sort()
        .sortedUniq()
        .value(),
    [schema.layers]
  );

  const handleBaseClick = useCallback(() => {
    setBase(base === "satellite" ? "terrain" : "satellite");
  }, [base, setBase]);

  const handleShowInspectorChange = useCallback(
    (shown: boolean) => {
      setShowInspector(shown);
      onShowInspectorChange?.(shown);
    },
    [onShowInspectorChange]
  );

  return (
    <Box w="100%" h="100vh">
      <AtlasMap
        defaultViewport={defaultViewport}
        transformRequest={transformRequest}
        cartographerTileUrl={cartographerTileUrl}
        scrollZoom={scrollZoom}
        {...rest}
      >
        <NavigationControl
          style={NAVIGATION_CONTROL_CSS_STYLE}
          visualizePitch={true}
          position="top-right"
        />

        <GeolocateControl position="top-right" />

        <CustomControlGroup position="top-right">
          <MapButton
            icon={<Icon as={IoLayersSharp} />}
            label="Toggle Background"
            onClick={handleBaseClick}
          />
        </CustomControlGroup>

        <AttributionControl
          customAttribution={attribution}
          position="bottom-right"
        />

        <ScaleControl style={SCALE_CONTROL_CSS_STYLE} position="bottom-right" />

        <Inspector
          show={showInspector}
          mode={inspectorMode}
          cartographerDownloadUrl={cartographerDownloadUrl}
          onShowChange={handleShowInspectorChange}
          onModeChange={setInspectorMode}
        />

        {children}
      </AtlasMap>
    </Box>
  );
}

type InspectorMode = "LayerMenu" | "AttributeMenu" | "Legend";

interface InspectorProps {
  show: boolean;
  mode: InspectorMode;
  cartographerDownloadUrl: DownloadUrlFunction;
  onShowChange: OnShowInspectorChange;
  onModeChange: (mode: InspectorMode) => void;
}

function Inspector(props: InspectorProps): ReactElement {
  const { show, mode, onModeChange, onShowChange, cartographerDownloadUrl } =
    props;

  const [selectedLayer] = useSelectedLayer();
  const { selectedFeatures } = useSelectedFeatures(selectedLayer.layerId);

  return (
    <VStack
      className="atlas-map-overlay-container"
      position="absolute"
      top="12"
      bottom="39px"
      left="10px"
      right="10px"
      pointerEvents="none"
      spacing="2"
    >
      <VStack
        position="relative"
        className="atlas-map-inspector-container"
        flexShrink={1}
        flexGrow={1}
        w={INSPECTOR_WIDTH_PX}
        mt="2px"
        spacing="2"
        alignSelf="flex-start"
        alignItems="stretch"
        minH="0"
      >
        {!show && (
          <InspectorPanel alignSelf="flex-start" pb="0">
            <MapButton
              icon={<Icon as={RiSideBarFill} />}
              label="Show Inspector"
              onClick={() => onShowChange(true)}
            />
          </InspectorPanel>
        )}

        {show && (
          <>
            <FilterPanel
              pointerEvents="all"
              onHideInspectorClick={() => onShowChange(false)}
            />

            {mode === "LayerMenu" && (
              <LayerMenuPanel
                pointerEvents="all"
                onLayerClick={() => onModeChange("AttributeMenu")}
              />
            )}

            {mode === "AttributeMenu" && (
              <AttributeMenuPanel
                pointerEvents="all"
                onBackClick={() => onModeChange("LayerMenu")}
                onAttributeClick={() => onModeChange("Legend")}
                cartographerDownloadUrl={cartographerDownloadUrl}
              />
            )}

            {mode === "Legend" && (
              <LegendPanel
                pointerEvents="all"
                onBackClick={() => onModeChange("AttributeMenu")}
              />
            )}
          </>
        )}
      </VStack>

      {selectedFeatures.length > 0 && (
        <FeatureTablePanel
          pointerEvents="all"
          className="atlas-map-feature-table-panel"
          flexShrink={0}
          flexGrow={0}
          alignSelf="stretch"
          maxH="25vh"
        />
      )}
    </VStack>
  );
}
