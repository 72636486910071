import { Point } from "@cartographerio/geometry";
import { randomUuid } from "@cartographerio/types";
import { useMemo, useRef } from "react";
import { MapRef } from "react-map-gl";

import PointField from "../components/PointField";
import { Highlight } from "../hooks/highlight";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useFormContext } from "./context/FormContext";
import { useMapFieldContext } from "./FeatureField";

export const DEFAULT_SELECT_MIN_ZOOM = 14;

export interface FormPointFieldProps {
  value: Point | null;
  onChange: (newValue: Point | null) => void;
  highlight?: Highlight;
  disabled?: boolean;
  selectMinZoom?: number;
}

export default function FormPointField(props: FormPointFieldProps) {
  const {
    value,
    onChange,
    highlight,
    disabled,
    selectMinZoom = DEFAULT_SELECT_MIN_ZOOM,
  } = props;

  const map = useRef<MapRef>(null);

  const { helpVisible } = useFormContext();
  const { registerSyncMap, syncEaseTo, handleMoveEnd, defaultBounds } =
    useMapFieldContext();

  const internalId = useMemo(() => randomUuid(), []);
  useEffectOnce(() => registerSyncMap(internalId, map));
  const onMoveEnd = useMemo(
    () => handleMoveEnd(internalId),
    [handleMoveEnd, internalId]
  );

  return (
    <PointField
      value={value}
      highlight={highlight}
      disabled={disabled}
      enableHelp={helpVisible}
      defaultBounds={defaultBounds}
      minZoomOnChange={selectMinZoom}
      onChange={onChange}
      mapRef={map}
      onMove={({ target }) =>
        syncEaseTo(internalId, {
          center: target.getCenter(),
          zoom: target.getZoom(),
          animate: false,
        })
      }
      onMoveEnd={onMoveEnd}
      easeTo={(...args) => {
        map.current?.easeTo(...args);
        syncEaseTo(internalId, ...args);
      }}
    />
  );
}
