import { errorMessage } from "@cartographerio/atlas-core";
import { Option } from "@cartographerio/fp";
import {
  Message,
  Timestamp,
  dateToTimestamp,
  ddmmyyyy,
  defaultTimestampFormat,
  formatTimestamp,
  timestampToDate,
  yyyymmdd,
  yyyymmddhhmm,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { setHours, setMinutes } from "date-fns";
import { FocusEvent, ReactElement, useCallback, useMemo, useRef } from "react";

import { useVolatileState } from "../../hooks/useVolatileState";
import MessageList from "../MessageList";
import Spaced, { SpacedProps } from "../Spaced";
import TextField from "../TextField";
import BaseCalendar, { BaseCalendarMethods } from "./Base";

interface TimestampCalendarProps extends Omit<SpacedProps, "children"> {
  value: Timestamp | null;
  minValue?: Timestamp | null;
  maxValue?: Timestamp | null;
  onChange?: (value: Timestamp | null) => void;
  showTime?: boolean;
  validateTime?: boolean;
}

const TIME_REGEX = /(?<hours>\d+)\D*(?<minutes>\d+)?/;

type Time = [number, number];

function timeToString(time: Time): string {
  return time.map(n => String(n).padStart(2, "0")).join(":");
}

function dateToTime(date: Date): Time {
  return [date.getHours(), date.getMinutes()];
}

export default function TimestampCalendar(
  props: TimestampCalendarProps
): ReactElement {
  const {
    value,
    minValue,
    maxValue,
    onChange,
    showTime = true,
    validateTime = showTime,
    ...rest
  } = props;

  const calendarRef = useRef<BaseCalendarMethods>(null);

  const minDate = useMemo(
    () => (minValue != null ? timestampToDate(minValue) : undefined),
    [minValue]
  );
  const maxDate = useMemo(
    () => (maxValue != null ? timestampToDate(maxValue) : undefined),
    [maxValue]
  );

  const [dateValue, setDateValue] = useVolatileState(
    useCallback(() => (value != null ? timestampToDate(value) : null), [value])
  );

  const dateValueAsTimestamp = useMemo(
    () => (dateValue != null ? dateToTimestamp(dateValue) : null),
    [dateValue]
  );

  const [timeValue, setTimeValue] = useVolatileState(
    useCallback(
      () => (value != null ? dateToTime(timestampToDate(value)) : null),
      [value]
    )
  );

  const [timeString, setTimeString] = useVolatileState(
    useCallback(
      () => (timeValue != null ? timeToString(timeValue) : ""),
      [timeValue]
    )
  );

  const workingTimestampValue = useMemo(
    () =>
      dateValue != null && timeValue != null
        ? dateToTimestamp(
            setMinutes(setHours(dateValue, timeValue[0]), timeValue[1])
          )
        : null,
    [dateValue, timeValue]
  );

  const timeMessages = useMemo((): Message[] => {
    const formatCmp = (ts: Timestamp) =>
      formatTimestamp(ts, {
        format: validateTime ? yyyymmddhhmm : yyyymmdd,
      });

    const formatStr = (ts: Timestamp) =>
      formatTimestamp(ts, {
        format: validateTime ? defaultTimestampFormat : ddmmyyyy,
      });

    return Option.wrap(workingTimestampValue)
      .nullMap(dateVal =>
        (minValue == null || formatCmp(dateVal) >= formatCmp(minValue)) &&
        (maxValue == null || formatCmp(dateVal) <= formatCmp(maxValue))
          ? null
          : minValue != null && maxValue != null
          ? errorMessage(
              [
                "The",
                validateTime ? "date/time" : "date",
                "must be between",
                formatStr(minValue),
                "and",
                formatStr(maxValue),
              ].join(" ")
            )
          : minValue != null
          ? errorMessage(
              [
                "The",
                validateTime ? "date/time" : "date",
                "must be",
                formatStr(minValue),
                "or later",
              ].join(" ")
            )
          : // Max value must be defined to go through this case
          maxValue != null
          ? errorMessage(
              [
                "The",
                validateTime ? "date/time" : "date",
                "must be",
                formatStr(maxValue),
                "or earlier",
              ].join(" ")
            )
          : null
      )
      .fold(
        () => [],
        msg => (msg == null ? [] : [msg])
      );
  }, [maxValue, minValue, validateTime, workingTimestampValue]);

  const handleChange = useCallback(
    (date: Date | null, time: Time | null) => {
      const [hour, minute] = time ?? [12, 0];
      onChange?.(
        date == null
          ? null
          : dateToTimestamp(setMinutes(setHours(date, hour), minute))
      );
    },
    [onChange]
  );

  const handleDayClick = useCallback(
    (date: Date) => {
      setDateValue(date);
      if (timeValue == null) {
        setTimeValue([12, 0]);
      }
      handleChange(date, timeValue);
    },
    [handleChange, setDateValue, setTimeValue, timeValue]
  );

  const handleDateChange = useCallback(
    (ts: Timestamp | null) => {
      if (ts == null) {
        setDateValue(null);
        setTimeValue(null);
      } else {
        const date = timestampToDate(ts);
        setDateValue(date);
        setTimeValue(timeValue ?? [12, 0]);
        calendarRef.current?.scrollTo(date);
      }
    },
    [setDateValue, setTimeValue, timeValue]
  );

  const handleInputFocus = useCallback((evt: FocusEvent<HTMLInputElement>) => {
    evt.target.select();
  }, []);

  const handleTimeBlur = useCallback(() => {
    // Optional minutes, so defaulting them to on the hour.
    const match = TIME_REGEX.exec(timeString);
    const time: Time | null =
      match?.groups?.hours != null
        ? [
            Math.min(Number(match.groups.hours), 23),
            Math.min(Number(match.groups.minutes ?? 0), 59),
          ]
        : null;

    if (time == null) {
      setTimeString(timeValue == null ? "" : timeToString(timeValue));
    } else {
      setTimeValue(time);
      setTimeString(timeToString(time));
      handleChange(dateValue, time);
    }
  }, [
    dateValue,
    handleChange,
    setTimeString,
    setTimeValue,
    timeString,
    timeValue,
  ]);

  return (
    <Spaced spacing="2">
      <BaseCalendar
        ref={calendarRef}
        selected={dateValue}
        initialDate={dateValue ?? undefined}
        onDayClick={handleDayClick}
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
      <HStack>
        <TextField.Timestamp
          placeholder="dd/mm/yyyy"
          cursor={dateValue == null ? "pointer" : undefined}
          value={dateValueAsTimestamp ?? undefined}
          onChange={handleDateChange}
          onFocus={handleInputFocus}
          highlight={timeMessages.length > 0 ? "error" : undefined}
        />
        {showTime && (
          <TextField.String
            value={timeString}
            placeholder="hh:mm (24h)"
            onChange={setTimeString}
            onFocus={handleInputFocus}
            onBlur={handleTimeBlur}
            highlight={timeMessages.length > 0 ? "error" : undefined}
            w="20ch"
          />
        )}
      </HStack>
      <MessageList messages={timeMessages} />
    </Spaced>
  );
}
