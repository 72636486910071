import { Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import { Message, UserV2 } from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import { userRule } from "../../schema/user";
import { useIOErrorAlert } from "../components/Alert";
import PageContainer from "../components/PageContainer";
import PageHeader from "../components/PageHeader";
import PageTopBar from "../components/PageTopBar";
import SaveButton from "../components/SaveButton";
import Spaced from "../components/Spaced";
import UserEditor from "../components/UserEditor";
import {
  useAccessToken,
  useApiParams,
  useCredentialsV2,
} from "../contexts/auth";
import { usePageTitle } from "../hooks/usePageTitle";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { useCurrentWorkspaceGraph } from "../hooks/useWorkspaceGraph";
import { routes } from "../routes";
import RecordMetadata from "./workspace/RecordMetadata";

export default function AccountPage(
  _props: RouteProps<typeof routes.account>
): ReactElement {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  const {
    identity: { userId },
  } = useCredentialsV2();

  usePageTitle("My Account");

  const defaultUser = useSuspenseQueryData(
    queries.user.v2.readOrFail(apiParams, userId)
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  const invitationCodes = useSuspenseSearchResults(
    queries.invitation.code.v3.search(apiParams, { user: userId })
  );

  const invitationCodeSignups = useSuspenseSearchResults(
    queries.invitation.code.signup.v3.search(apiParams, { user: userId })
  );

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const defaultQualificationHistories = useSuspenseQueryData(
    queries.qualification.history.v1.readAll(apiParams, defaultUser.id)
  );

  const defaultQualificationRegisterSettings = useSuspenseQueryData(
    queries.qualification.register.settings.v1.readAll(
      apiParams,
      defaultUser.id
    )
  );

  const [value, setValue] = useState(defaultUser);
  const [qualificationHistories, setQualificationHistories] = useState(
    defaultQualificationHistories
  );

  const [qualificationRegisterSettings, setQualificationRegisterSettings] =
    useState(defaultQualificationRegisterSettings);

  const qualificationRegisterEntries = useSuspenseQueryData(
    queries.qualification.register.v1.userSearch(apiParams, value.id)
  );

  const accessToken = useAccessToken();

  const messages = useMemo(() => userRule(value), [value]);

  const handleSave = useCallback(
    () =>
      (messages.length === 0
        ? IO.tupled([
            queries.user.v2.save(queryClient, apiParams, value.id, {
              ...value,
              qualificationRoles: null,
            }),
            queries.qualification.history.v1.saveAll(
              queryClient,
              apiParams,
              defaultUser.id,
              qualificationHistories
            ),
            queries.qualification.register.settings.v1.saveAll(
              queryClient,
              apiParams,
              defaultUser.id,
              qualificationRegisterSettings
            ),
          ]).map(([result]) => result)
        : IO.pure(Result.fail<Message[], UserV2>(messages))
      )
        .tap(() =>
          toast({
            title: "Successfully updated your details",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [
      apiParams,
      errorAlert,
      qualificationHistories,
      defaultUser.id,
      messages,
      queryClient,
      qualificationRegisterSettings,
      toast,
      value,
    ]
  );

  return (
    <>
      <PageTopBar basePage="account" />
      <PageContainer width="narrow">
        <Spaced spacing="8">
          <PageHeader title="My Account" />

          <UserEditor
            myAccount={true}
            value={value}
            invitationCodes={invitationCodes}
            invitationCodeSignups={invitationCodeSignups}
            qualifications={qualifications}
            defaultQualificationHistories={defaultQualificationHistories}
            qualificationHistories={qualificationHistories}
            qualificationRegisterSettings={qualificationRegisterSettings}
            qualificationRegisterEntries={qualificationRegisterEntries}
            onChange={setValue}
            onQualificationHistoryChange={setQualificationHistories}
            onQualificationRegisterSettingsChange={
              setQualificationRegisterSettings
            }
            messages={messages}
            workspaceGraph={workspaceGraph}
            roleEditorSingleWorkspace={false}
          />

          <SaveButton onClick={handleSave} messages={messages} />

          <RecordMetadata.Disclosure>
            <RecordMetadata.Item label="User ID" value={value.id} />
            <RecordMetadata.Item
              label="Access Token"
              value={accessToken}
              hidden={true}
            />
          </RecordMetadata.Disclosure>
        </Spaced>
      </PageContainer>
    </>
  );
}
