import { PrimarySurveyorFieldState } from "@cartographerio/atlas-form";
import {
  useFormPermissionsContext,
  useSurveyorTeamContext,
} from "@cartographerio/atlas-form-context";
import { ReactElement } from "react";

import SectionedSelect from "../components/SectionedSelect";
import { userIdToIcon } from "../components/UserRefField";

export interface PrimarySurveyorFieldProps {
  state: PrimarySurveyorFieldState;
  disabled?: boolean;
}

export function PrimarySurveyorField(
  props: PrimarySurveyorFieldProps
): ReactElement {
  const { state, disabled } = props;

  const { primarySurveyorOptions, onPrimarySurveyorChange } =
    useSurveyorTeamContext();

  const { canUpdateSurveyor } = useFormPermissionsContext();

  return (
    <SectionedSelect
      value={state.surveyor?.userId}
      sections={primarySurveyorOptions}
      fuseOptions={{
        includeMatches: true,
        minMatchCharLength: 3,
      }}
      renderIcon={userIdToIcon}
      onChange={value =>
        value != null ? onPrimarySurveyorChange(value) : null
      }
      highlight={state.highlight}
      disabled={disabled || !canUpdateSurveyor}
      debounce={200}
      emptyText="No users found. Please adjust your search."
    />
  );
}
