import { WorkspaceRef } from "@cartographerio/types";
import { Box, Flex, theme } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import queries from "../../../queries";
import { useApiParams } from "../../contexts/auth";
import { useIsNarrow } from "../../contexts/breakpoint";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import Sidebar from "../Sidebar";
import UserMenu from "../Sidebar/UserMenu";
import SuspenseBoundary from "../SuspenseBoundary";
import {
  SIDEBAR_ANIMATION_DURATION,
  SIDEBAR_BUTTON_SIZE,
  SIDEBAR_WIDTH,
  SWITCHER_ONLY_WIDTH,
} from "./base";
import Footer from "./Footer";
import SidebarButton from "./SidebarButton";
import SidebarOverlay from "./SidebarOverlay";
import { useSidebarVisibility } from "./useSidebarVisibility";

export interface PageLayoutProps {
  workspaceRef: WorkspaceRef | null;
  admin?: boolean;
  showSwitcher?: boolean;
  showFooter?: boolean;
  children?: ReactNode;
}

export default function PageLayout(props: PageLayoutProps): ReactElement {
  const {
    workspaceRef,
    admin,
    showSwitcher = true,
    showFooter = true,
    children,
  } = props;

  const apiParams = useApiParams();
  const narrow = useIsNarrow("lg");

  const [sidebarVisible, toggleSidebar] = useSidebarVisibility(narrow);

  const workspace = useSuspenseQueryData(
    queries.optional(workspaceRef, workspaceRef =>
      queries.workspace.v2.readOrNull(apiParams, workspaceRef)
    )
  );

  const hasSidebar = admin || workspace != null;
  const sidebarWidth = hasSidebar ? SIDEBAR_WIDTH : SWITCHER_ONLY_WIDTH;
  const contentXStart = sidebarVisible ? sidebarWidth : 0;

  const menus = showSwitcher ? (
    <>
      <Box position="relative" zIndex="overlay">
        <Flex
          position="fixed"
          top="2"
          left={`min(calc(${contentXStart}px + ${theme.space[2]}), calc(100vw - ${SIDEBAR_BUTTON_SIZE} - ${theme.space[2]}))`}
          direction="row"
          justify="flex-end"
          transitionProperty="left"
          transitionDuration={SIDEBAR_ANIMATION_DURATION}
          width={SIDEBAR_BUTTON_SIZE}
          height={SIDEBAR_BUTTON_SIZE}
          maxWidth={`calc(100vw - ${theme.space[2]})`}
        >
          <SidebarButton
            sidebarVisible={sidebarVisible}
            toggleSidebar={toggleSidebar}
          />
        </Flex>
      </Box>
      <Box
        position="fixed"
        top={0}
        left={sidebarVisible ? 0 : -sidebarWidth}
        bottom={0}
        w={sidebarWidth}
        zIndex="overlay"
        transitionProperty="all"
        transitionDuration={SIDEBAR_ANIMATION_DURATION}
        shadow="md"
      >
        <Flex maxH="100vh" overflowX="hidden" overflowY="auto">
          <Flex
            direction="row"
            grow={0}
            shrink={0}
            w="100%"
            h="fit-content"
            bg="white"
          >
            <Box
              minH="100vh"
              w={showSwitcher ? undefined : "100%"}
              bg="gray.200"
            >
              <Flex h="100%" direction="column" justifyContent="stretch">
                {showSwitcher && (
                  <Sidebar.Switcher admin={admin} workspace={workspace} />
                )}
              </Flex>
            </Box>

            {hasSidebar && (
              <Box minW={0} flexGrow={1} flexShrink={1} minH="100vh">
                <Flex direction="column">
                  {admin ? (
                    <Sidebar.Admin />
                  ) : (
                    workspace != null && (
                      <Sidebar.Workspace workspace={workspace} />
                    )
                  )}
                </Flex>
              </Box>
            )}
          </Flex>
        </Flex>
        <Box position="absolute" bottom="0" left="0">
          <Box
            bg="linear-gradient(0deg, var(--chakra-colors-gray-200) 0%, rgba(0,0,0,0) 100%)"
            h="4"
          />
          <Box bg="gray.200" pb="2" px="2">
            <UserMenu />
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <Box position="fixed" bottom="2" left="2" zIndex="banner">
      <UserMenu />
    </Box>
  );

  const overlay =
    narrow && sidebarVisible ? (
      <SidebarOverlay toggleSidebar={toggleSidebar} />
    ) : null;

  return (
    <Box h="100vh" className="PageLayout_MenuContainer">
      {menus}
      <Box
        position="relative"
        ml={narrow ? 0 : sidebarVisible ? sidebarWidth : 0}
        minH="100vh"
        transitionProperty="all"
        transitionDuration={SIDEBAR_ANIMATION_DURATION}
      >
        {overlay}
        <SuspenseBoundary
          // These next few attributes mimic PageContainer:
          mx="auto"
          px={["2", null, "8"]}
          pt="8"
          pb="16"
          maxW="50ch"
        >
          <Box minH="50vh">{children}</Box>
          {showFooter && <Footer workspace={workspace} mt="12" />}
        </SuspenseBoundary>
      </Box>
    </Box>
  );
}
