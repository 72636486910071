import {
  AnonymisedUserV2,
  ProjectV2,
  TeamV2,
  WorkspaceRef,
  WorkspaceV2,
  createSearchResults,
} from "@cartographerio/types";
import {
  UserWorkspaceGraphV2,
  WorkspaceGraphV2,
  userWorkspaceGraphV2,
  workspaceGraphV2,
} from "@cartographerio/workspace-graph";
import { useMemo } from "react";

import queries from "../../queries";
import { useApiParams, useCredentialsV2 } from "../contexts/auth";
import { useSuspenseQueryData } from "./useSuspenseQueryData";
import { useSuspenseSearchResults } from "./useSuspenseSearchResults";

export function useWorkspaceGraph(
  workspaces: WorkspaceV2 | WorkspaceV2[],
  projects: ProjectV2[],
  teams: TeamV2[]
): WorkspaceGraphV2<WorkspaceV2, ProjectV2, TeamV2> {
  return useMemo(
    () => workspaceGraphV2(workspaces, projects, teams),
    [workspaces, projects, teams]
  );
}

export function useUserWorkspaceGraph(
  workspaces: WorkspaceV2 | WorkspaceV2[],
  projects: ProjectV2[],
  teams: TeamV2[],
  users: AnonymisedUserV2[]
): UserWorkspaceGraphV2<WorkspaceV2, ProjectV2, TeamV2, AnonymisedUserV2> {
  return useMemo(
    () => userWorkspaceGraphV2(workspaces, projects, teams, users),
    [workspaces, projects, teams, users]
  );
}

export function useCurrentWorkspaceGraph(
  workspaceRef?: WorkspaceRef
): WorkspaceGraphV2 {
  const apiParams = useApiParams();
  const {
    identity: { userId },
  } = useCredentialsV2();

  const workspace = useSuspenseQueryData(
    queries.optional(workspaceRef, workspace =>
      queries.workspace.v2.readOrFail(apiParams, workspace)
    )
  );

  return useWorkspaceGraph(
    useSuspenseSearchResults(
      queries.optional(
        workspace,
        workspace => queries.constant(createSearchResults([workspace])),
        () => queries.workspace.v2.registered(apiParams, userId)
      )
    ),
    useSuspenseSearchResults(
      queries.project.v2.visible(apiParams, userId, workspace?.id)
    ),
    useSuspenseSearchResults(
      queries.team.v2.visible(apiParams, userId, workspace?.id)
    )
  );
}
