import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  Email,
  QualificationAlias,
  QualificationId,
  QualificationRegisterSettings,
  UserId,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../../base";

export type QualificationRegisterSettingsKey =
  | ["qualification", "register", "settings"]
  | [
      "qualification",
      "register",
      "settings",
      "v1",
      "read",
      QualificationId | QualificationAlias,
      UserId | Email
    ]
  | ["qualification", "register", "settings", "v1", "readAll", UserId | Email];

export function qualificationRegisterSettingsKey(
  key: QualificationRegisterSettingsKey
): QualificationRegisterSettingsKey {
  return key;
}

export function readOrFail(
  apiParams: ApiParams,
  qualification: QualificationId | QualificationAlias,
  user: UserId | Email
): UseQueryOpts<
  QualificationRegisterSettings,
  QualificationRegisterSettingsKey
> {
  return {
    queryKey: [
      "qualification",
      "register",
      "settings",
      "v1",
      "read",
      qualification,
      user,
    ],
    queryFn: () =>
      endpoints.qualification.register.settings.v1
        .readOrFail(apiParams, user, qualification)
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  qualification: QualificationId | QualificationAlias,
  user: UserId | Email,
  settings: QualificationRegisterSettings
) {
  return endpoints.qualification.register.settings.v1
    .save(apiParams, user, qualification, settings)
    .tap(() =>
      queryClient.invalidateQueries(["qualification", "register", "settings"])
    );
}

export function readAll(
  apiParams: ApiParams,
  user: UserId | Email
): UseQueryOpts<
  Record<QualificationId, QualificationRegisterSettings>,
  QualificationRegisterSettingsKey
> {
  return {
    queryKey: ["qualification", "register", "settings", "v1", "readAll", user],
    queryFn: () =>
      endpoints.qualification.register.settings.v1
        .readAll(apiParams, user)
        .unsafeRun(),
  };
}

export function saveAll(
  queryClient: QueryClient,
  apiParams: ApiParams,
  user: UserId | Email,
  settings: Record<QualificationId, QualificationRegisterSettings>
) {
  return endpoints.qualification.register.settings.v1
    .saveAll(apiParams, user, settings)
    .tap(() =>
      queryClient.invalidateQueries(["qualification", "register", "settings"])
    );
}
