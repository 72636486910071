import { errorMessage, infoMessage } from "@cartographerio/atlas-core";
import {
  Email,
  Path,
  ProjectV2,
  ProjectVisibility,
  WorkspaceV2,
} from "@cartographerio/types";
import { Tagged } from "@cartographerio/util";

import { rule } from "../../../../schema/rule";
import { workspaceRule } from "../../../../schema/workspace";

export type LocalId = Tagged<"_LocalId_">;

export interface Person {
  localId: LocalId;
  firstName: string;
  lastName: string;
  email: Email;
}

export const { rule: personRule, keys: personErrorKeys } = rule
  .build<Person>()
  .field(
    "firstName",
    rule.nonEmpty([errorMessage("Must specify a first name")])
  )
  .field("lastName", rule.nonEmpty([errorMessage("Must specify a last name")]))
  .field("email", rule.email([errorMessage("Must specify an email")]))
  .finish();

export interface NewWorkspaceDetails {
  workspace: WorkspaceV2;
  projectVisibility: ProjectVisibility;
}

export const {
  rule: newWorkspaceDetailsRule,
  keys: newWorkspaceDetailsErrorKeys,
} = rule
  .build<NewWorkspaceDetails>()
  .field("workspace", workspaceRule)
  .finish();

export interface ExistingWorkspaceDetails {
  workspace: WorkspaceV2 | null;
  project: ProjectV2 | "new";
  projectVisibility: ProjectVisibility;
}

export const {
  rule: existingWorkspaceDetailsRule,
  keys: existingWorkspaceDetailsErrorKeys,
} = rule
  .build<ExistingWorkspaceDetails>()
  .field(
    "workspace",
    rule.required([errorMessage("You must select a workspace")])
  )
  .field("project", rule.required([errorMessage("You must select a project")]))
  .finish();

interface SeparateBillingContact {
  type: "Separate";
  contact: Person;
}

interface TraineeBillingContact {
  type: "Trainee";
  traineeId: LocalId;
}

type BillingContact = SeparateBillingContact | TraineeBillingContact;

export const { rule: billingContactRule, keys: billingContactErrorKeys } = rule
  .build<BillingContact>()
  .union(
    (value): value is SeparateBillingContact => value.type === "Separate",
    rule.build<SeparateBillingContact>().field("contact", personRule).finish()
  )
  .finish();

export interface NewRcaWorkspace {
  type: "NewRcaWorkspace";
  workspaceDetails: NewWorkspaceDetails;
  trainees: Person[];
  billingContact?: BillingContact | null;
}

const newRcaWorkspaceFinished = rule
  .build<NewRcaWorkspace>()
  .field("workspaceDetails", newWorkspaceDetailsRule)
  .field("trainees", rule.array(personRule))
  .optionalField("billingContact", rule.nullable(billingContactRule))
  .finish();

export const { rule: newRcaWorkspaceRule, keys: newRcaWorkspaceErrorKeys } =
  newRcaWorkspaceFinished;

export interface ExistingRcaWorkspace {
  type: "ExistingRcaWorkspace";
  workspaceDetails: ExistingWorkspaceDetails;
  trainees: Person[];
}

const existingRcaWorkspaceFinished = rule
  .build<ExistingRcaWorkspace>()
  .field("workspaceDetails", existingWorkspaceDetailsRule)
  .field("trainees", rule.array(personRule))
  .finish();

export const {
  rule: existingRcaWorkspaceRule,
  keys: existingRcaWorkspaceErrorKeys,
} = existingRcaWorkspaceFinished;

export type RcaWorkspace = NewRcaWorkspace | ExistingRcaWorkspace;

const { rule: rcaWorkspaceRule } = rule
  .build<RcaWorkspace>()
  .union(
    (ws): ws is NewRcaWorkspace => ws.type === "NewRcaWorkspace",
    newRcaWorkspaceFinished
  )
  .union(
    (ws): ws is ExistingRcaWorkspace => ws.type === "ExistingRcaWorkspace",
    existingRcaWorkspaceFinished
  )
  .finish();

export type RcaSetupData = RcaWorkspace[];

interface TotalWithPaths {
  total: number;
  paths: Path[];
}

export const rcaSetupDataRule = rule.and(rule.array(rcaWorkspaceRule), data => {
  const emailTotals: Record<Email, TotalWithPaths> = data
    .flatMap(({ trainees }, workspaceIndex) =>
      trainees.map((item, traineeIndex) => ({
        ...item,
        path: [workspaceIndex, "trainees", traineeIndex, "email"],
      }))
    )
    .filter(({ email, path: _ }) => email.length > 0)
    .reduce(
      (acc: Record<Email, TotalWithPaths>, { email, path }) => ({
        ...acc,
        [email]:
          acc[email] != null
            ? {
                total: acc[email].total + 1,
                paths: acc[email].paths.concat([path]),
              }
            : { total: 1, paths: [path] },
      }),
      {}
    );

  return Object.entries(emailTotals)
    .filter(([_email, { total }]) => total > 1)
    .flatMap(([_email, { paths }]) =>
      paths.map(path => infoMessage("Duplicate email address", path))
    );
});
