import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  AspectRatio,
  Box,
  Flex,
  Icon,
  IconButton,
  Image,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { AiOutlineFile } from "react-icons/ai";

import LoadingPlaceholder from "../LoadingPlaceholder";

export interface PreviewCarouselProps {
  title: string;
  previewUrl?: string | null;
  loading?: boolean;
  onPrevClick?: () => void;
  onNextClick?: () => void;
}

export default function PreviewCarousel(
  props: PreviewCarouselProps
): ReactElement {
  const {
    title,
    previewUrl,
    loading = false,
    onPrevClick,
    onNextClick,
  } = props;

  return (
    <Box position="relative">
      {loading ? (
        <AspectRatio width="100%" ratio={4 / 3}>
          <LoadingPlaceholder />
        </AspectRatio>
      ) : previewUrl != null ? (
        <Image
          src={previewUrl}
          fallback={
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              w="100%"
              h="100%"
              color="gray.300"
              py="2"
            >
              <Icon as={AiOutlineFile} w="32" h="32" />
            </Box>
          }
          title={title}
          fit="cover"
          width="100%"
          maxWidth="100%"
          height="auto"
        />
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="100%"
          h="100%"
          color="gray.300"
          py="2"
        >
          <Icon as={AiOutlineFile} w="32" h="32" />
        </Box>
      )}

      {onPrevClick != null && (
        <CarouselButton
          icon={<ChevronLeftIcon />}
          onClick={onPrevClick}
          label="Previous"
          position="left"
        />
      )}

      {onNextClick != null && (
        <CarouselButton
          icon={<ChevronRightIcon />}
          onClick={onNextClick}
          label="Next"
          position="right"
        />
      )}
    </Box>
  );
}

interface CarouselButtonProps {
  icon: ReactElement;
  label: string;
  onClick: () => void;
  position: "left" | "right";
}

function CarouselButton(props: CarouselButtonProps) {
  const { icon, label, onClick, position } = props;
  return (
    <Flex
      position="absolute"
      top="0"
      bottom="0"
      width="12"
      left={position === "left" ? "-6" : undefined}
      right={position === "right" ? "-6" : undefined}
      zIndex="popover"
      direction="column"
      justify="center"
      align="center"
      pointerEvents="none"
    >
      <IconButton
        icon={icon}
        aria-label={label}
        onClick={onClick}
        variant="ghost"
        bg="white"
        rounded="full"
        shadow="md"
        pointerEvents="all"
      />
    </Flex>
  );
}
