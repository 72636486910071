import { PartialParams, endpoints } from "@cartographerio/client";
import { QualificationRegisterSearchOptions } from "@cartographerio/client/dist/endpoints/qualification";
import {
  ApiParams,
  Email,
  QualificationId,
  QualificationRef,
  QualificationRegisterEntry,
  SearchResults,
  UserId,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type QualificationRegisterKey =
  | ["qualification", "register"]
  | [
      "qualification",
      "register",
      "v1",
      "qualification",
      QualificationRef,
      PartialParams<QualificationRegisterSearchOptions> | undefined
    ]
  | ["qualification", "register", "v1", "user", UserId | Email]
  | ["qualification", "register", "v1", "entry", string];

export function qualificationSearch(
  apiParams: ApiParams,
  ref: QualificationRef,
  options?: PartialParams<QualificationRegisterSearchOptions>
): UseQueryOpts<
  SearchResults<QualificationRegisterEntry>,
  QualificationRegisterKey
> {
  return {
    queryKey: [
      "qualification",
      "register",
      "v1",
      "qualification",
      ref,
      options,
    ],
    queryFn: () =>
      endpoints.qualification.register.v1
        .qualificationSearch(apiParams, ref, options)
        .unsafeRun(),
  };
}

export function userSearch(
  apiParams: ApiParams,
  userIdOrEmail: UserId | Email
): UseQueryOpts<
  Record<QualificationId, QualificationRegisterEntry>,
  QualificationRegisterKey
> {
  return {
    queryKey: ["qualification", "register", "v1", "user", userIdOrEmail],
    queryFn: () =>
      endpoints.qualification.register.v1
        .userSearch(apiParams, userIdOrEmail)
        .map(entries => {
          const ans: Record<QualificationId, QualificationRegisterEntry> = {};
          for (const entry of entries.results) {
            ans[entry.qualificationId] = entry;
          }
          return ans;
        })
        .unsafeRun(),
  };
}

export function readOption(
  apiParams: ApiParams,
  entryId: string
): UseQueryOpts<QualificationRegisterEntry | null, QualificationRegisterKey> {
  return {
    queryKey: ["qualification", "register", "v1", "entry", entryId],
    queryFn: () =>
      endpoints.qualification.register.v1
        .readOption(apiParams, entryId)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}
