import { Dispatch, createContext } from "react";

import { AuthAction } from "./action";
import { AuthState, initialAuthState } from "./state";

export interface AuthContextValue {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
}

export const AuthContext = createContext<AuthContextValue>({
  state: initialAuthState(),
  dispatch: () => console.error("Auth dispatch not initialised"),
});
