import { BlockState, isFieldState } from "@cartographerio/atlas-form";
import { ReactElement } from "react";

import ContentView from "./ContentView";
import FieldView from "./FieldView";

export interface BlockViewProps {
  blockKey: string;
  blockState: BlockState;
  disabled?: boolean;
}

export default function BlockView(props: BlockViewProps): ReactElement {
  const { blockKey, blockState, disabled } = props;

  if (isFieldState(blockState)) {
    return (
      <FieldView
        fieldKey={blockKey}
        fieldState={blockState}
        disabled={disabled}
      />
    );
  } else {
    return (
      <ContentView
        blockKey={blockKey}
        blockState={blockState}
        disabled={disabled}
      />
    );
  }
}
