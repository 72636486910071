import { BoxProps, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

interface EllipsiseProps {
  textAlign?: BoxProps["textAlign"];
  fontSize?: BoxProps["fontSize"];
  color?: BoxProps["color"];
  text?: string;
}

export default function Ellipsise(props: EllipsiseProps): ReactElement {
  const { text, textAlign, fontSize, color } = props;

  return (
    <chakra.span
      display="inline-block"
      maxW="100%"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      title={text}
      textAlign={textAlign}
      fontSize={fontSize}
      color={color}
    >
      {text}
    </chakra.span>
  );
}
