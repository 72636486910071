import { Option } from "@cartographerio/fp";
import { InputElementProps } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { BaseTextFieldProps } from "./base";
import DefaultTextField from "./Default";
import { formatDecimalPlaces, validateDecimalPlaces } from "./Number";
import Units from "./Units";

export interface NullableNumberTextFieldProps
  extends BaseTextFieldProps<number | null>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  decimalPlaces?: number;
  units?: string;
}

export default function NullableNumberTextField(
  props: NullableNumberTextFieldProps
): ReactElement | null {
  const { decimalPlaces, units, highlight, disabled, ...rest } = props;
  const format = useCallback(
    (num: number | null): string => {
      return num == null ? "" : formatDecimalPlaces(num, decimalPlaces);
    },
    [decimalPlaces]
  );

  const validate = useCallback(
    (str: string): Option<number | null> => {
      const trimmed = str.trim();
      return trimmed === ""
        ? Option.some<number | null>(null)
        : validateDecimalPlaces(str, decimalPlaces);
    },
    [decimalPlaces]
  );

  const rightAddon =
    units == null ? undefined : <Units units={units} disabled={disabled} />;

  return (
    <DefaultTextField
      format={format}
      validate={validate}
      highlight={highlight}
      rightAddon={rightAddon}
      disabled={disabled}
      {...rest}
    />
  );
}
