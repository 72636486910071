import {
  FeatureFieldAttribute,
  FeatureFieldCartographerMapStyle,
  FeatureFieldExternalMapStyle,
  FeatureFieldMapOptions,
  FeatureFieldMapStyle,
  FeatureFieldLayer as FeatureFieldSelectable,
  featureFieldSelectedLayers,
} from "@cartographerio/atlas-form";
import {
  MapLayer,
  PropertyValue,
  featureAttr,
  isTeamAttribute,
  marker,
} from "@cartographerio/atlas-map";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  featureCollection,
} from "@cartographerio/geometry";
import { isObject } from "@cartographerio/guard";
import { checkExhausted } from "@cartographerio/util";
import { Layout } from "mapbox-gl";
import { ReactElement, useMemo } from "react";
import { Source } from "react-map-gl";

import { MapboxArg, MapboxExpr, expr } from "../../../mapbox";
import { useProjectHasTeams } from "../../hooks/useProjectHasTeams";
import {
  attributeBuckets,
  featureAttributeBuckets,
} from "../../map/AtlasMapContext/buckets";
import LayerView from "../../map/LayerView";
import UpdateInPlaceLayer from "../../map/UpdateInPlaceLayer";
import { useFormContext } from "../context/FormContext";

interface FeatureFieldLayersProps<
  A extends FeatureFieldMapStyle = FeatureFieldMapStyle
> {
  mapStyle: A;
  mapOptions: FeatureFieldMapOptions;
  selection: Feature | null;
  selectableLayer: FeatureFieldSelectable;
}

/**
 * React Map GL sources and layers for FeatureFields and NearestFeatureFields.
 *
 * Note the approach to creating selectable/selected layers is different
 * for Cartographer and External feature field map styles:
 *
 * - For Cartographer map styles, the style file defines a selectable layer and
 *   we insert a selected layer synthetically.
 *   We also update the layer styles in-place to style by team or a specific attribute.
 *
 * - For External map styles, the selectable and selected layers are baked into the style.
 *   We don't apply our own stylings at all.
 */
export default function FeatureFieldLayers(
  props: FeatureFieldLayersProps
): ReactElement {
  const { mapStyle, mapOptions, selection, selectableLayer } = props;

  switch (mapStyle.type) {
    case "Cartographer":
      return (
        <CartographerFeatureFieldLayer
          mapStyle={mapStyle}
          mapOptions={mapOptions}
          selection={selection}
          selectableLayer={selectableLayer}
        />
      );

    case "External":
      return (
        <ExternalFeatureFieldLayer mapStyle={mapStyle} selection={selection} />
      );

    default:
      return checkExhausted(mapStyle);
  }
}

interface CartographerFeatureFieldLayerProps {
  mapStyle: FeatureFieldCartographerMapStyle;
  mapOptions: FeatureFieldMapOptions;
  selection: Feature | FeatureCollection | null;
  selectableLayer: FeatureFieldSelectable;
}

const SOURCE_ID = "CRT-SELECTED-FEATURES-SOURCE";
const LAYER_ID = "CRT-SELECTED-FEATURES-LAYER";
const emptyFeatureCollection = featureCollection({ features: [] });
const visibilityNone: Layout = { visibility: "none" };

function CartographerFeatureFieldLayer(
  props: CartographerFeatureFieldLayerProps
) {
  const { mapStyle, mapOptions, selection, selectableLayer } = props;

  const { layer: layerId } = mapStyle;

  const { workspaceGraph, project } = useFormContext();

  const multiTeam = useProjectHasTeams(
    workspaceGraph.findWorkspaceById(project.workspaceId),
    project
  );

  // Select an attribute to use to override the default selectable layer style:
  const attribute: FeatureFieldAttribute = useMemo(
    () =>
      // Use the first team attribute in the list...
      (multiTeam ? mapOptions.attributes.find(isTeamAttribute) : undefined) ??
      // ...or first attribute in the list...
      (mapOptions.attributes.length > 0
        ? mapOptions.attributes[0]
        : undefined) ??
      // ...or provide a default
      featureAttr({
        attributeId: "default",
        label: "Selectable Features",
      }),
    [multiTeam, mapOptions.attributes]
  );

  const teams = useMemo(
    () =>
      attribute.type === "TeamAttribute"
        ? workspaceGraph.findTeamsByWorkspaceId(project.workspaceId)
        : [],
    [attribute.type, project.workspaceId, workspaceGraph]
  );

  const buckets = useMemo(
    () =>
      attributeBuckets(
        attribute,
        undefined,
        teams,
        featureAttributeBuckets(
          marker.fromColors("blue", "white"),
          "Selectable Features"
        )
      ),
    [attribute, teams]
  );

  const selectedGeometry = useMemo(
    () =>
      selection == null
        ? null
        : selection.type === "FeatureCollection"
        ? selection.features[0].geometry
        : selection.geometry,
    [selection]
  );

  const selectedLayerType = useMemo<MapLayer["type"] | null>(() => {
    switch (selectedGeometry?.type) {
      case "Point":
      case "MultiPoint":
        return "PointLayer";
      case "LineString":
      case "MultiLineString":
        return "LineLayer";
      case "Polygon":
      case "MultiPolygon":
        return "PolygonLayer";
      case "GeometryCollection":
      case undefined:
        return null;
      default:
        return checkExhausted(selectedGeometry);
    }
  }, [selectedGeometry]);

  // TODO: This is redundant code. It's here because the map styles from the Cartographer API
  // contain baked-in "selected layers" that we need to disable.
  // The path to removing this is [1] update the mobile app to use a synthetic layer
  // to manage selected features, [2] remove the baked-in selected layers generated by the API,
  // and [3] delete this code.
  const bakedInSelectedLayers = useMemo(
    () => featureFieldSelectedLayers(mapStyle),
    [mapStyle]
  );

  return (
    <>
      <LayerView
        id={selectableLayer.id}
        type="PointLayer" // TODO: get the layer type from selectableLayer
        attribute={attribute}
        buckets={buckets}
        sourceId={layerId}
        sourceLayerId="default"
        visible={true}
        simplify={false}
        filter={undefined}
        markerMode="normal"
      />
      {bakedInSelectedLayers.map(selectedLayer => (
        <UpdateInPlaceLayer
          key={selectedLayer.id}
          id={selectedLayer.id}
          type={selectableLayer.type}
          layout={visibilityNone}
        />
      ))}
      <Source
        id={SOURCE_ID}
        type="geojson"
        data={selection ?? emptyFeatureCollection}
      >
        {selectedLayerType != null && (
          <LayerView
            id={LAYER_ID}
            type={selectedLayerType}
            attribute={attribute}
            buckets={buckets}
            sourceId={SOURCE_ID}
            visible={true}
            simplify={false}
            markerMode="selected"
          />
        )}
      </Source>
    </>
  );
}

interface ExternalFeatureFieldLayersProps {
  mapStyle: FeatureFieldExternalMapStyle;
  selection: Feature | null;
}

function ExternalFeatureFieldLayer(props: ExternalFeatureFieldLayersProps) {
  const { mapStyle, selection } = props;

  const { primaryKey } = mapStyle;

  const selectionFilter = useMemo<MapboxExpr>(() => {
    const properties: GeoJsonProperties | null = selection?.properties ?? null;

    const getExpr = primaryKey == null ? expr.id() : expr.get(primaryKey);

    const valueExpr: MapboxArg | null =
      primaryKey == null
        ? selection?.id ?? null
        : isObject(properties)
        ? (properties?.[primaryKey] as PropertyValue)
        : null;

    const ans =
      valueExpr == null ? expr.boolean(false) : expr.eql(getExpr, valueExpr);

    return ans;
  }, [primaryKey, selection?.id, selection?.properties]);

  return (
    <>
      <UpdateInPlaceLayer
        id={mapStyle.selectableLayer.id}
        type={mapStyle.selectableLayer.type}
        filter={undefined}
      />
      {mapStyle.selectedLayers.map(selectedLayer => (
        <UpdateInPlaceLayer
          key={selectedLayer.id}
          id={selectedLayer.id}
          type={selectedLayer.type}
          filter={selectionFilter}
        />
      ))}
    </>
  );
}
