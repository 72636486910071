import {
  FeatureFieldMapStyle,
  featureFieldStyleUrlV2,
} from "@cartographerio/atlas-form";
import { BBox4, toBBox4 } from "@cartographerio/geometry";
import {
  ApiConfig,
  MapBase,
  ProjectMapSettings,
  ProjectV2,
  WorkspaceV2,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactElement,
  ReactNode,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { MapRef, ViewStateChangeEvent } from "react-map-gl";

type MapFunctionName = keyof {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K in keyof MapRef as MapRef[K] extends (...args: any) => any
    ? K
    : never]: MapRef[K];
};

type StyleUrlFunc = (mapStyle: FeatureFieldMapStyle, base: MapBase) => string;
type RegisterSyncMapFunc = (id: string, map: RefObject<MapRef>) => void;
type SyncMapFunc<F extends MapFunctionName> = (
  originId: string,
  ...args: Parameters<MapRef[F]>
) => void;

export interface MapFieldContext {
  getStyleUrl: StyleUrlFunc;
  registerSyncMap: RegisterSyncMapFunc;
  syncFitBounds: SyncMapFunc<"fitBounds">;
  syncEaseTo: SyncMapFunc<"easeTo">;
  handleMoveEnd: (originId: string) => (_evt: ViewStateChangeEvent) => void;
  defaultBounds?: BBox4;
}

export const MapFieldContext =
  createContext<MapFieldContext | undefined>(undefined);

export function useMapFieldContext(): MapFieldContext {
  return (
    useContext(MapFieldContext) ??
    raise(internalError("MapFieldContext not available"))
  );
}

interface MapFieldContextProviderProps {
  apiConfig: ApiConfig;
  workspace: WorkspaceV2;
  project: ProjectV2;
  mapSettings: ProjectMapSettings;
  children: ReactNode;
}

export function MapFieldContextProvider(
  props: MapFieldContextProviderProps
): ReactElement {
  const { apiConfig, workspace, project, mapSettings, children } = props;

  const getStyleUrl = useCallback<StyleUrlFunc>(
    (mapStyle, base) =>
      featureFieldStyleUrlV2(apiConfig, mapStyle, {
        project: project.alias,
        workspace: workspace.alias,
        base,
      }),
    [apiConfig, project.alias, workspace.alias]
  );

  const [mapsToSync, setMapsToSync] = useState<
    Record<string, RefObject<MapRef>>
  >({});

  const registerSyncMap = useCallback<RegisterSyncMapFunc>(
    (id, map) => setMapsToSync(mapsToSync => ({ ...mapsToSync, [id]: map })),
    []
  );

  const syncFitBounds = useCallback<SyncMapFunc<"fitBounds">>(
    (originId, ...args) =>
      Object.entries(mapsToSync).forEach(
        ([id, map]) =>
          id !== originId &&
          map.current != null &&
          map.current.fitBounds(...args)
      ),
    [mapsToSync]
  );

  const [_mapSyncInProgress, setMapSyncInProgress] =
    useState<string | null>(null);

  const syncEaseTo = useCallback<SyncMapFunc<"easeTo">>(
    (originId, ...args) =>
      setMapSyncInProgress(mapSyncInProgress => {
        Object.entries(mapsToSync).forEach(
          ([id, map]) =>
            id !== originId &&
            originId === mapSyncInProgress &&
            map.current != null &&
            map.current.easeTo(...args)
        );
        return mapSyncInProgress ?? originId;
      }),
    [mapsToSync]
  );

  const handleMoveEnd = useCallback(
    (originId: string) => (_evt: ViewStateChangeEvent) => {
      setMapSyncInProgress(id => (id === originId ? null : id));
    },
    []
  );

  const context: MapFieldContext = useMemo(() => {
    const defaultMapCenter = mapSettings.fallbackCenter;
    const defaultMapZoom = mapSettings.fallbackZoom;
    const defaultBounds = toBBox4(mapSettings.defaultBounds);

    return {
      getStyleUrl,
      registerSyncMap,
      syncFitBounds,
      syncEaseTo,
      handleMoveEnd,
      defaultMapCenter,
      defaultMapZoom: defaultMapZoom - 1,
      defaultBounds,
    };
  }, [
    mapSettings.fallbackCenter,
    mapSettings.fallbackZoom,
    mapSettings.defaultBounds,
    getStyleUrl,
    registerSyncMap,
    syncFitBounds,
    syncEaseTo,
    handleMoveEnd,
  ]);

  return (
    <MapFieldContext.Provider value={context}>
      {children}
    </MapFieldContext.Provider>
  );
}
