import { SelectOption } from "@cartographerio/atlas-form";
import {
  FormPermissionsContext,
  useFormPermissions,
} from "@cartographerio/atlas-form-context";
import { SurveyModule } from "@cartographerio/inventory-surveys";
import { IO } from "@cartographerio/io";
import {
  AnonymisedUserV2,
  AttachmentFolder,
  AttachmentId,
  AttachmentUpdate,
  ProjectV2,
  SurveyId,
  SurveyV2,
  TeamV2,
  WorkspaceV2,
  emptySearchResults,
} from "@cartographerio/types";
import { prettyPrintJson } from "@cartographerio/util";
import { chakra, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { useIOAlert } from "../../components/Alert";
import PageTopBar from "../../components/PageTopBar";
import Pre from "../../components/Pre";
import { useApiParams } from "../../contexts/auth";
import { AttachmentFieldContextProvider } from "../../form/AttachmentField";
import { MapFieldContextProvider } from "../../form/FeatureField";
import { SaveProps } from "../../form/FormTopBar/FormSaveButton";
import FormView from "../../form/FormView";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../../hooks/useProjectHasTeams";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useUserWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";

export interface ProjectSurveyBasePageProps {
  creating: boolean;
  defaultSurvey: SurveyV2;
  module: SurveyModule;
  workspace: WorkspaceV2;
  project: ProjectV2;
  defaultPageIndex?: number;
  onPageIndexChange: (index: number) => void;
}

export function ProjectSurveyBasePage(
  props: ProjectSurveyBasePageProps
): ReactElement {
  const {
    creating,
    defaultSurvey,
    module,
    workspace,
    project,
    defaultPageIndex,
    onPageIndexChange,
  } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  const toast = useToast();
  const alert = useIOAlert();
  const multiTeam = useProjectHasTeams(workspace, project);

  const title = useMemo(() => module?.names.shortName, [module]);
  const formSchema = useMemo(() => module?.formSchema, [module]);
  const surveySchema = useMemo(() => module?.surveySchemaV2(), [module]);

  const projectMapSettings = useSuspenseQueryData(
    queries.project.mapSettings.v1.readOrDefault(apiParams, project.id)
  );

  const teamMapSettings = useSuspenseQueryData(
    queries.optional(multiTeam ? defaultSurvey.teamId : null, teamId =>
      queries.team.mapSettings.v1.readOrNull(apiParams, teamId)
    )
  );

  const workspaceProjects: ProjectV2[] = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id)
  );

  const workspaceTeams = useSuspenseSearchResults(
    queries.cond(
      multiTeam,
      () => queries.team.v2.forWorkspace(apiParams, workspace.id),
      () => emptySearchResults<TeamV2>()
    )
  );

  const workspaceUsers: AnonymisedUserV2[] = useSuspenseSearchResults(
    queries.user.v2.searchAnonymised(apiParams, {
      workspace: project.workspaceId,
    })
  );

  const graph = useUserWorkspaceGraph(
    workspace,
    workspaceProjects,
    workspaceTeams,
    workspaceUsers
  );

  const runPermissionCheck = usePermissionCheckRunner(graph);

  const permissions = useFormPermissions({
    runPermissionCheck,
    project,
    defaultSurvey,
    creating,
  });

  const queryClient = useQueryClient();

  const saveSurvey = useCallback(
    (survey: SurveyV2, { sendEmailNotifications }: SaveProps) =>
      queries.survey.v3.save(
        queryClient,
        apiParams,
        survey,
        sendEmailNotifications
      ),
    [apiParams, queryClient]
  );

  const unlockSurvey = useCallback(
    (surveyId: SurveyId, props: SaveProps) => {
      return queries.survey.v3.saveStatus(
        queryClient,
        apiParams,
        surveyId,
        props.status
      );
    },
    [apiParams, queryClient]
  );

  const copySurvey = useCallback((): IO<void> => {
    return IO.wrap(() =>
      navigate(
        routes.workspace.project.survey.create.url(
          [workspace.alias, project.alias, module.moduleId],
          { template: defaultSurvey.id }
        )
      )
    ).tap(_ =>
      toast({
        title: "Survey Duplicated",
        description: "You are now editing the copy",
        status: "success",
        duration: null,
        isClosable: true,
      })
    );
  }, [
    module.moduleId,
    navigate,
    project.alias,
    defaultSurvey.id,
    toast,
    workspace.alias,
  ]);

  const handleDelete = useCallback(
    () =>
      queries.survey.v3.remove(queryClient, apiParams, defaultSurvey.id, () =>
        navigate(
          routes.workspace.project.survey.list.url([
            workspace.alias,
            project.alias,
            module.moduleId,
          ])
        )
      ),
    [
      apiParams,
      module.moduleId,
      navigate,
      project.alias,
      queryClient,
      defaultSurvey.id,
      workspace.alias,
    ]
  );

  const viewOnMapOptions = useMemo(
    () =>
      module.mapLinks.map(
        ({ label, mapId }): SelectOption<string> => ({
          label,
          value: routes.workspace.project.map.url(
            [workspace.alias, project.alias, mapId],
            { survey: defaultSurvey.id }
          ),
        })
      ),
    [module.mapLinks, project.alias, defaultSurvey.id, workspace.alias]
  );

  const handleSurveySaved = useCallback(
    ({ redirect, page }: SaveProps) => {
      if (redirect) {
        navigate(
          routes.workspace.project.survey.list.url([
            workspace.alias,
            project.alias,
            module.moduleId,
          ])
        );
      } else if (creating) {
        navigate(
          routes.workspace.project.survey.view.url(
            [workspace.alias, project.alias, module.moduleId, defaultSurvey.id],
            { page }
          ),
          { replace: true }
        );
      }
      toast({
        title: "Saved Changes",
        description: "Successfully saved your changes",
        status: "success",
        isClosable: true,
      });
    },
    [
      creating,
      module.moduleId,
      navigate,
      project.alias,
      defaultSurvey.id,
      toast,
      workspace.alias,
    ]
  );

  const onAttachmentMetadataUpdate = useCallback(
    (id: AttachmentId, update: AttachmentUpdate) =>
      queries.attachment.v3
        .update(queryClient, apiParams, id, update)
        .tap(() =>
          toast({
            title: "Saved Changes",
            description: "Successfully saved your changes",
            status: "success",
            isClosable: true,
          })
        )
        .unsafeRun(),
    [apiParams, queryClient, toast]
  );

  const handleSurveySaveError = useCallback(
    (error: unknown) => {
      console.error("Could not save survey", error);
      alert({
        title: "Oop! Something Went Wrong!",
        message: (
          <>
            <chakra.p mb="4">An error occurred saving the survey:</chakra.p>
            <Pre h="64" overflow="auto" text={prettyPrintJson(error)} />
          </>
        ),
      }).unsafeRun();
    },
    [alert]
  );

  const attachmentUrl = useCallback(
    (attachment: AttachmentId) => routes.attachment.view.url([attachment]),
    []
  );

  const attachmentFolderUrl = useCallback(
    (survey: SurveyId, folder: AttachmentFolder) =>
      routes.attachment.folder.url([survey, folder]),
    []
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        module={module}
        survey={creating ? "new" : defaultSurvey}
      />
      <AttachmentFieldContextProvider
        apiParams={apiParams}
        surveyId={defaultSurvey.id}
        attachmentUrl={attachmentUrl}
        attachmentFolderUrl={attachmentFolderUrl}
      >
        <MapFieldContextProvider
          apiConfig={apiParams.apiConfig}
          workspace={workspace}
          project={project}
          mapSettings={teamMapSettings ?? projectMapSettings}
        >
          <FormPermissionsContext.Provider value={permissions}>
            <FormView
              title={title}
              project={project}
              workspaceGraph={graph}
              formSchema={formSchema}
              surveySchema={surveySchema}
              creating={creating}
              defaultSurvey={defaultSurvey}
              defaultDataLicense={project.dataLicense}
              defaultPageIndex={defaultPageIndex}
              module={defaultSurvey.moduleId}
              saveSurvey={saveSurvey}
              unlockSurvey={unlockSurvey}
              copySurvey={copySurvey}
              deleteSurvey={handleDelete}
              viewOnMapOptions={viewOnMapOptions}
              onAttachmentMetadataUpdate={onAttachmentMetadataUpdate}
              onSurveySaved={handleSurveySaved}
              onSurveySaveError={handleSurveySaveError}
              onPageIndexChange={onPageIndexChange}
            />
          </FormPermissionsContext.Provider>
        </MapFieldContextProvider>
      </AttachmentFieldContextProvider>
    </>
  );
}
