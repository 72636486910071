import { checkExhausted } from "@cartographerio/util";

import {
  AttachmentGalleryItem,
  GalleryItem,
  UploadSuccessGalleryItem,
} from "./type";

export function galleryItemIsUploading(item: GalleryItem): boolean {
  switch (item.type) {
    case "InProgressItem":
      return true;

    case "AttachmentItem":
    case "UploadSuccessItem":
    case "UploadFailureItem":
      return false;

    default:
      return checkExhausted(item);
  }
}

export function galleryItemThumbnailUrl(item: GalleryItem): string | null {
  switch (item.type) {
    case "InProgressItem":
    case "UploadSuccessItem":
    case "UploadFailureItem":
      return item.dataUrl;

    case "AttachmentItem":
      return item.thumbnailUrl;

    default:
      return checkExhausted(item);
  }
}

export function galleryItemPreviewUrl(item: GalleryItem): string | null {
  switch (item.type) {
    case "InProgressItem":
    case "UploadSuccessItem":
    case "UploadFailureItem":
      return item.dataUrl;

    case "AttachmentItem":
      return item.previewUrl;

    default:
      return checkExhausted(item);
  }
}

export function galleryItemOriginalUrl(item: GalleryItem): string | undefined {
  switch (item.type) {
    case "InProgressItem":
    case "UploadSuccessItem":
    case "UploadFailureItem":
      return undefined;

    case "AttachmentItem":
      return item.originalUrl;

    default:
      return checkExhausted(item);
  }
}

export function isFullAttachmentItem(
  item: GalleryItem
): item is AttachmentGalleryItem | UploadSuccessGalleryItem {
  return item.type === "AttachmentItem" || item.type === "UploadSuccessItem";
}
