import { BoxProps, Flex, HStack, Link, chakra } from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { Link as ReactLink } from "react-router-dom";

import { useWindowLocation } from "../../hooks/useWindowLocation";

interface SidebarMenuItemProps {
  icon?: ReactNode;
  text: string;
  linkTo?: string;
  isExternal?: boolean;
  right?: ReactNode;
  addLink?: string;
  color?: BoxProps["color"];
  isHighlighted?: boolean;
}

export default function SidebarMenuItem(
  props: SidebarMenuItemProps
): ReactElement {
  const {
    icon,
    text,
    linkTo,
    isExternal,
    right,
    addLink,
    color,
    isHighlighted: _isHighlighted,
  } = props;

  const location = useWindowLocation();

  const isHighlighted = useMemo(
    () =>
      _isHighlighted ??
      (linkTo != null &&
        // Either you're at the same path, or it's a sub path
        (location.pathname === linkTo ||
          location.pathname.startsWith(
            linkTo.endsWith("/") ? linkTo : `${linkTo}/`
          ))),
    [_isHighlighted, linkTo, location.pathname]
  );

  const [showAddLink, setShowAddLink] = useState(false);

  return (
    <HStack
      as="li"
      w="100%"
      minH="8"
      px="3"
      py="1"
      alignItems="flex-start"
      justifyContent="space-between"
      onMouseEnter={() => setShowAddLink(true)}
      onMouseLeave={() => setShowAddLink(false)}
      bg={isHighlighted ? "gray.100" : undefined}
    >
      <Flex
        maxW={
          (right != null || addLink != null) && showAddLink ? "85%" : "100%"
        }
      >
        {icon != null && (
          <chakra.span color="gray.400" mr="2">
            {icon}
          </chakra.span>
        )}
        <chakra.span
          display="inline-block"
          fontSize="sm"
          color={color}
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {linkTo == null ? (
            text
          ) : (
            <Link
              display="block"
              as={isExternal ? undefined : ReactLink}
              to={linkTo}
              href={linkTo}
              isExternal={isExternal}
              borderRadius="sm"
              color={color}
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
              title={text}
            >
              {text}
            </Link>
          )}
        </chakra.span>
      </Flex>
      {right != null ? (
        <Flex
          alignSelf="center"
          visibility={showAddLink ? undefined : "hidden"}
          direction="column"
          justifyContent="center"
        >
          {right}
        </Flex>
      ) : (
        addLink != null && (
          <Link
            flexShrink={0}
            flexGrow={0}
            display="block"
            as={ReactLink}
            to={addLink}
            alignSelf="center"
            visibility={showAddLink ? undefined : "hidden"}
          >
            <BiPlus size="1.25em" />
          </Link>
        )
      )}
    </HStack>
  );
}
