import { AccessToken, isAccessToken } from "@cartographerio/types";

export type AccessTokenData = {
  effective?: AccessToken | null;
  real?: AccessToken | null;
};

export interface AuthState {
  initialised: boolean;
  loading: boolean;
  accessTokenData: AccessTokenData | null;
  errorMessage: string | null;
}

export const initialAuthState = (
  initialToken?: AccessTokenData
): AuthState => ({
  initialised: false,
  loading: false,
  accessTokenData: initialToken ?? null,
  errorMessage: null,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAccessTokenData = (v: any): v is AccessTokenData =>
  typeof v === "object" &&
  v != null &&
  (!("effective" in v) || isAccessToken(v.effective) || v.effective === null) &&
  (!("real" in v) || isAccessToken(v.real) || v.real === null);
