import { check } from "@cartographerio/permission";
import { ReactElement } from "react";

import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageContainer from "../../components/PageContainer";
import PageTopBar from "../../components/PageTopBar";
import Spaced from "../../components/Spaced";
import WorkspaceList from "../../components/WorkspaceList";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { routes } from "../../routes";
import AdminPageHeader from "./AdminPageHeader";

export default function AdminWorkspaceListPage(
  props: RouteProps<typeof routes.admin.workspace.list>
): ReactElement {
  const { query, updateQuery } = props;

  const { q: searchTerm, order = "name-asc", page, count } = query;

  useRequirePermissionRedirect(check.superuser, () =>
    routes.admin.home.url([])
  );

  usePageTitle("Workspaces - Admin");

  return (
    <>
      <PageTopBar admin={true} basePage="workspaces" />
      <AdminPageHeader
        title="Workspaces"
        right={
          <ButtonLink.Internal
            to={routes.admin.workspace.create.url([])}
            colorScheme="blue"
          >
            Add
          </ButtonLink.Internal>
        }
      />
      <PageContainer width="wide">
        <Spaced>
          <WorkspaceList
            searchTerm={searchTerm}
            page={page}
            count={count}
            order={order}
            onSearchTermChange={q =>
              updateQuery({ ...query, q: q ?? undefined, page: 0 })
            }
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
            showFeaturesColumn={true}
            itemLink={workspace =>
              routes.workspace.settings.url([workspace.alias])
            }
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
