import { checks } from "@cartographerio/permission";
import { AccessToken, AttachmentFolder, SurveyId } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  Box,
  Flex,
  Grid,
  Icon,
  Image,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import { Fragment, ReactElement } from "react";
import { AiOutlineFile } from "react-icons/ai";

import {
  galleryItemMetadata,
  galleryItemOriginalUrl,
} from "../../../galleryItem";
import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import Card from "../../components/Card";
import CardBody from "../../components/CardBody";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function EmbedAttachmentFolderPage(
  props: RouteProps<typeof routes.embed.attachment.folder>
): ReactElement {
  const {
    query: {
      authorization = raise<AccessToken>(
        new Error("You must specify an access token")
      ),
      survey: surveyId = raise<SurveyId>(
        new Error("You must specify a survey")
      ),
      folder: attachmentFolder = raise<AttachmentFolder>(
        new Error("You must specify a folder")
      ),
    },
  } = props;

  const apiParams = useApiParams();

  const galleryItems = useSuspenseQueryData(
    queries.attachment.v3.searchGalleryItems(
      apiParams,
      surveyId,
      attachmentFolder
    )
  );

  const survey = useSuspenseQueryData(
    queries.survey.v3.readOrFail(apiParams, surveyId)
  );

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, survey.projectId)
  );

  useRequirePermission(checks.survey.view(project));

  return (
    <PageContainer width="wide">
      <Spaced>
        <PageHeader
          title="Attachment Gallery"
          right={
            <ButtonLink.Internal
              variant="outline"
              to={routes.short.survey.url([survey.id])}
            >
              View Survey
            </ButtonLink.Internal>
          }
        />
        <SimpleGrid gap="4" columns={[1, 3, 3]}>
          {galleryItems.map(galleryItem => (
            <Card
              key={galleryItem.id}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Image
                h="100%"
                w="100%"
                bg="gray.800"
                src={galleryItemOriginalUrl(galleryItem)}
                fallback={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    w="100%"
                    h="100%"
                    color="gray.300"
                  >
                    <Icon as={AiOutlineFile} w="32" h="32" />
                  </Box>
                }
                fit="contain"
                mx="auto"
                roundedTop="md"
                overflow="hidden"
              />
              <CardBody>
                <Spaced>
                  <Grid
                    mt="auto"
                    templateColumns="16ex auto"
                    gap="4"
                    fontSize="xs"
                  >
                    {galleryItemMetadata(galleryItem).map(
                      ([key, value], index) => (
                        <Fragment key={index}>
                          <Box>{key}</Box>
                          <chakra.pre fontFamily="body" overflowX="hidden">
                            {value}
                          </chakra.pre>
                        </Fragment>
                      )
                    )}
                  </Grid>
                  {galleryItem.type === "AttachmentItem" && (
                    <Flex w="100%" justifyContent="space-between">
                      <ButtonLink.External
                        variant="outline"
                        to={routes.embed.attachment.view.url([], {
                          authorization,
                          attachment: galleryItem.attachment.id,
                        })}
                      >
                        View
                      </ButtonLink.External>
                      <ButtonLink.External
                        colorScheme="blue"
                        to={galleryItem.originalUrl}
                      >
                        Download
                      </ButtonLink.External>
                    </Flex>
                  )}
                </Spaced>
              </CardBody>
            </Card>
          ))}
        </SimpleGrid>
      </Spaced>
    </PageContainer>
  );
}
