import { checkExhausted } from "@cartographerio/util";
import {
  Box,
  BoxProps,
  Flex,
  HStack,
  Icon,
  Image,
  Spinner,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { Fragment, ReactElement, useMemo, useState } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { IoCheckmarkCircle, IoCloseSharp } from "react-icons/io5";

import { GalleryItem, galleryItemThumbnailUrl } from "../../../galleryItem";
import Heading from "../../components/Heading";
import { userIdToIcon } from "../../components/UserRefField";
import { useAttachmentView } from "../../contexts/attachmentView";

function overlayColor(
  item: GalleryItem,
  hover: boolean = false
): BoxProps["bg"] {
  switch (item.type) {
    case "InProgressItem":
    case "UploadFailureItem":
      return !hover ? "whiteAlpha.500" : "whiteAlpha.900";
    case "AttachmentItem":
    case "UploadSuccessItem":
      return !hover ? "transparent" : "whiteAlpha.500";
    default:
      return checkExhausted(item);
  }
}

interface OverlayIconProps {
  item: GalleryItem;
}

export function OverlayIcon(props: OverlayIconProps): ReactElement {
  const { item } = props;

  switch (item.type) {
    case "AttachmentItem":
      return <Fragment />;

    case "InProgressItem":
      return (
        <Spinner width="8" height="8" colorScheme="gray" color="gray.500" />
      );

    case "UploadSuccessItem":
      return (
        <Icon
          as={IoCheckmarkCircle}
          w="8"
          h="8"
          background="green.500"
          rounded="full"
          color="#fff"
        />
      );

    case "UploadFailureItem":
      return (
        <Icon
          as={IoCloseSharp}
          w="8"
          h="8"
          background="red.500"
          rounded="full"
          color="#fff"
        />
      );

    default:
      return checkExhausted(item);
  }
}

export interface ThumbnailProps {
  item: GalleryItem;
  onClick: () => void;
}

export default function Thumbnail(props: ThumbnailProps): ReactElement {
  const { item, onClick } = props;

  const url = useMemo(() => galleryItemThumbnailUrl(item), [item]);
  const [attachmentView] = useAttachmentView();

  const [hovering, setHovering] = useState(false);

  return (
    <Flex
      w={attachmentView === "grid" ? undefined : "100%"}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      direction={attachmentView === "grid" ? "column" : "row"}
      onClick={onClick}
      p="2"
      rounded="lg"
      _hover={{
        bg: "blackAlpha.100",
        cursor: "pointer",
      }}
    >
      <Box
        position="relative"
        minW="40"
        maxW="40"
        h="40"
        rounded="md"
        shadow="sm"
      >
        {url != null ? (
          <Image
            src={url}
            fallback={
              <Box
                position="absolute"
                display="flex"
                justifyContent="center"
                alignItems="center"
                w="100%"
                h="100%"
                color="gray.300"
              >
                <Icon as={AiOutlineFile} w="32" h="32" />
              </Box>
            }
            position="absolute"
            width="100%"
            height="100%"
            background="gray.100"
            rounded="md"
            fit="cover"
          />
        ) : (
          <Box
            position="absolute"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            h="100%"
            color="gray.300"
          >
            <Icon as={AiOutlineFile} w="32" h="32" />
          </Box>
        )}
        <Flex
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          zIndex={2}
          rounded="md"
          justify="center"
          align="center"
          bg={overlayColor(item, hovering)}
        >
          <OverlayIcon item={item} />
        </Flex>
      </Box>

      {attachmentView === "grid" ? (
        <Box
          w="40"
          mt="1"
          textAlign="center"
          fontSize="xs"
          color="gray.400"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {item.attachment.title}
        </Box>
      ) : (
        <VStack ms="2" spacing="2" align="start">
          <Heading
            level="subsubsection"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.attachment.title}
          </Heading>
          {item.attachment.author?.screenName != null && (
            <HStack gap="1">
              <Icon as={userIdToIcon(item.attachment.author.userId)} />
              <chakra.span>{item.attachment.author.screenName}</chakra.span>
            </HStack>
          )}
          <Box
            maxH="100%"
            maxW="100%"
            overflow="hidden"
            noOfLines={3}
            wordBreak="break-word"
          >
            {item.attachment.description}
          </Box>
        </VStack>
      )}
    </Flex>
  );
}
