import { SelectOption } from "@cartographerio/atlas-form";
import { SearchResultsFormat } from "@cartographerio/client";
import {
  KnownWorkspaceFeatureEnum,
  MemberType,
  WorkspaceV2,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";

import DownloadMenu from "./DownloadMenu";
import SearchField from "./SearchField";
import Select from "./Select";

export * from "./UserList/role";

export interface MemberListToolbarProps<G extends string = string> {
  searchTerm?: string;
  workspace?: WorkspaceV2 | null;
  memberType?: MemberType | null;
  enableOptionsModal?: boolean;
  roleSelect?: ReactNode;
  defaultGroupLabel?: string;
  inGroup?: G;
  groupOptions?: SelectOption<G>[];
  onGroupChange?: (group: G | null) => void;
  addButton?: ReactNode;
  downloadUrl?: (format: SearchResultsFormat) => string;
  onSearchTermChange?: (q: string | null) => void;
  onMemberTypeChange?: (memberType: MemberType | undefined) => void;
}

export default function MemberListToolbar<G extends string = string>(
  props: MemberListToolbarProps<G>
): ReactElement {
  const {
    searchTerm,
    workspace,
    memberType,
    roleSelect,
    inGroup,
    defaultGroupLabel = "All Members",
    groupOptions,
    onGroupChange,
    addButton,
    downloadUrl,
    onSearchTermChange,
    onMemberTypeChange,
  } = props;

  const codesEnabled = useMemo(
    () =>
      workspace?.features.includes(
        KnownWorkspaceFeatureEnum.EnableInvitationCodes
      ) ?? true,
    [workspace?.features]
  );

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  return (
    <HStack w="100%" justifyContent="stretch" wrap="wrap">
      <SearchField
        defaultValue={searchTerm ?? ""}
        onChange={handleSearchChange}
        w="auto"
        flexShrink={1}
        flexGrow={1}
      />
      {onMemberTypeChange != null && (
        <Select.Nullable
          value={memberType ?? null}
          placeholder="All Members"
          options={
            codesEnabled
              ? [
                  { label: "Current Members", value: "Current" },
                  { label: "Awaiting Approval", value: "Pending" },
                  { label: "Invited Members", value: "Invited" },
                ]
              : [
                  { label: "Current Members", value: "Current" },
                  { label: "Invited Members", value: "Invited" },
                ]
          }
          onChange={type => onMemberTypeChange(type ?? undefined)}
          background="transparent"
          w="fit-content"
          maxW="52"
        />
      )}
      {groupOptions != null && (
        <Select.Nullable
          value={inGroup ?? null}
          options={groupOptions}
          onChange={onGroupChange}
          placeholder={defaultGroupLabel}
          background="transparent"
          w="fit-content"
          maxW="52"
        />
      )}
      {roleSelect}
      {downloadUrl != null && <DownloadMenu downloadUrl={downloadUrl} />}
      {addButton}
    </HStack>
  );
}
