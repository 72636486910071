import { checks } from "@cartographerio/permission";
import { ReactElement } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import PageTopBar from "../../../components/PageTopBar";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermission from "../../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../../hooks/useWorkspaceGraph";
import { routes } from "../../../routes";
import BaseUserPage from "../../base/BaseUserPage";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectUserPage(
  props: RouteProps<typeof routes.workspace.project.member.update>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, userId },
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const defaultUser = useSuspenseQueryData(
    queries.user.v2.readOrFail(apiParams, userId)
  );

  useRequirePermission(checks.project.grantAccess(project));

  usePageTitle(
    `${defaultUser.firstName} ${defaultUser.lastName} - ${project.name} - ${workspace.name}`
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="members"
        user={defaultUser}
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="members"
      />
      <BaseUserPage
        defaultUser={defaultUser}
        nextRedirect={routes.workspace.project.member.list.url([
          workspace.alias,
          project.alias,
        ])}
        workspace={workspace}
        workspaceGraph={workspaceGraph}
      />
    </>
  );
}
