import { ReactElement } from "react";

import { RouteProps } from "../../../routes";
import { routes } from "../../routes";
import { InvitationCodeSignupActionPage } from "./InvitationCodeSignupActionPage";

export default function InvitationCodeSignupApprovePage(
  props: RouteProps<typeof routes.short.invitationCode.signup.approve>
): ReactElement {
  const {
    path: { invitationCodeRef, userIdOrEmail },
  } = props;

  return (
    <InvitationCodeSignupActionPage
      invitationCodeRef={invitationCodeRef}
      userIdOrEmail={userIdOrEmail}
      action="approve"
    />
  );
}
