import { Box, SystemProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export interface CardBodyProps extends SystemProps {
  children: ReactNode;
}

export default function CardBody(props: CardBodyProps) {
  const { px = "4", py = "3", children, ...rest } = props;

  return (
    <Box px={px} py={py} {...rest}>
      {children}
    </Box>
  );
}
