import { SelectOption } from "@cartographerio/atlas-form";
import {
  Button,
  HStack,
  IconButton,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import {
  addDays,
  addYears,
  differenceInDays,
  endOfMonth,
  getMonth,
  getYear,
  isSameDay,
  isSaturday,
  isSunday,
  nextSaturday,
  previousSunday,
  set,
  setMonth,
  setYear,
  subDays,
} from "date-fns";
import { isDate, range } from "lodash";
import {
  ForwardedRef,
  ReactElement,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";

import Select from "../Select";
import Spaced, { SpacedProps } from "../Spaced";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const monthOptions = monthNames.map((label, value) => ({ label, value }));
const weekdayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

interface DayContext {
  date: Date;
  today: boolean;
  selected: boolean;
  nextSelected: boolean;
  prevSelected: boolean;
  inCurrentMonth: boolean;
  disabled: boolean;
}

export interface BaseCalendarProps extends Omit<SpacedProps, "children"> {
  selected?: { from: Date; to: Date } | Date | null;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onDayClick?: (date: Date) => void;
  onDayHover?: (date: Date) => void;
}

export interface BaseCalendarMethods {
  scrollTo: (date: Date) => void;
}

export default forwardRef(function BaseCalendar(
  props: BaseCalendarProps,
  ref: ForwardedRef<BaseCalendarMethods>
): ReactElement {
  const {
    selected,
    initialDate,
    minDate = new Date("1970/1/1"),
    maxDate: _maxDate,
    disabled,
    onDayClick,
    onDayHover,
    ...rest
  } = props;

  const maxDate = useMemo(
    () => _maxDate ?? addYears(new Date(), 10),
    [_maxDate]
  );
  const minYear = useMemo(() => getYear(minDate), [minDate]);
  const maxYear = useMemo(() => getYear(maxDate), [maxDate]);

  const [dateInView, setDateInView] = useState(() => initialDate ?? new Date());

  const handleDateInViewChange = useCallback(
    (month?: number, year?: number) => {
      const rightMonth =
        month != null ? setMonth(dateInView, month) : dateInView;
      setDateInView(year != null ? setYear(rightMonth, year) : rightMonth);
    },
    [dateInView]
  );

  const scrollTo = useCallback(
    (date: Date) => {
      const month = getMonth(date);
      const year = getYear(date);
      handleDateInViewChange(month, year);
    },
    [handleDateInViewChange]
  );

  useImperativeHandle(
    ref,
    () => ({
      scrollTo,
    }),
    [scrollTo]
  );

  const isSelected = useCallback(
    (date: Date) =>
      selected == null
        ? false
        : isDate(selected)
        ? isSameDay(selected, date)
        : selected.from <= date && date <= selected.to,
    [selected]
  );

  const days = useMemo((): DayContext[] => {
    const currMonthStart = discardTime(set(dateInView, { date: 1 }));
    const currMonthEnd = endOfMonth(currMonthStart);

    const now = new Date();
    const calendarStart = isSunday(currMonthStart)
      ? currMonthStart
      : previousSunday(currMonthStart);
    const calendarEnd = isSaturday(currMonthEnd)
      ? currMonthEnd
      : nextSaturday(currMonthEnd);

    return range(0, differenceInDays(calendarEnd, calendarStart) + 1).map(
      offset => {
        const date = addDays(calendarStart, offset);
        return {
          date,
          disabled: disabled || date < minDate || date > maxDate,
          today: isSameDay(date, now),
          selected: isSelected(date),
          prevSelected: isSelected(subDays(date, 1)),
          nextSelected: isSelected(addDays(date, 1)),
          inCurrentMonth: date.getMonth() === dateInView.getMonth(),
        };
      }
    );
  }, [dateInView, disabled, isSelected, maxDate, minDate]);

  const yearOptions = useMemo(
    (): SelectOption<number>[] =>
      range(getYear(minDate), getYear(maxDate) + 1)
        .reverse()
        .map(year => ({
          label: `${year}`,
          value: year,
        })),
    [maxDate, minDate]
  );

  return (
    <Spaced spacing="4" {...rest}>
      <HStack justify="space-between" w="100%" px="3">
        <IconButton
          size="sm"
          variant="outline"
          icon={<IoMdArrowDropleft size="1.25rem" />}
          aria-label="Previous Month"
          onClick={() => handleDateInViewChange(dateInView.getMonth() - 1)}
          isDisabled={
            disabled ||
            (dateInView.getMonth() === 0 && dateInView.getFullYear() <= minYear)
          }
        />

        <HStack>
          <Select.Standard
            options={monthOptions}
            value={dateInView.getMonth()}
            onChange={month => handleDateInViewChange(month)}
            rounded="md"
            size="sm"
            w="20"
          />
          <Select.Standard
            options={yearOptions}
            value={dateInView.getFullYear()}
            onChange={year => handleDateInViewChange(undefined, year)}
            rounded="md"
            size="sm"
            w="20"
            disabled={disabled}
          />
        </HStack>

        <IconButton
          size="sm"
          variant="outline"
          icon={<IoMdArrowDropright size="1.25rem" />}
          aria-label="Next Month"
          onClick={() => handleDateInViewChange(dateInView.getMonth() + 1)}
          isDisabled={
            disabled ||
            (dateInView.getMonth() === 11 &&
              dateInView.getFullYear() >= maxYear)
          }
        />
      </HStack>

      <SimpleGrid columns={7} borderWidth="1px" rounded="md" pt="1">
        {weekdayNamesShort.map((name, i) => (
          <chakra.span
            key={name}
            borderBottomWidth="1px"
            ps={i === 0 ? "2" : "1.5"}
            pe={i === weekdayNamesShort.length - 1 ? "2" : "1.5"}
            pb="1.5"
          >
            {name}
          </chakra.span>
        ))}
        {days.map((day, dayIndex) => (
          <Button
            key={`${dateInView.getFullYear()}${dateInView.getMonth()}${dayIndex}`}
            size="sm"
            height="unset"
            variant="unstyled"
            rounded="0"
            color={
              day.inCurrentMonth || day.selected || day.disabled
                ? undefined
                : "gray.300"
            }
            pt="2"
            pb="2"
            px="0"
            bg={
              !day.disabled
                ? undefined
                : day.inCurrentMonth
                ? "gray.100"
                : "gray.200"
            }
            _hover={{ bg: undefined }}
            _focusVisible={{ boxShadow: "none" }}
            isDisabled={day.disabled}
            disabled={day.disabled}
            onClick={() => onDayClick?.(day.date)}
            onMouseOver={() => onDayHover?.(day.date)}
          >
            <chakra.span
              display="block"
              bg={day.selected ? "blue.100" : undefined}
              color="inherit"
              w="100%"
              py="1"
              borderLeftRadius={day.selected && day.prevSelected ? 0 : "full"}
              borderRightRadius={day.selected && day.nextSelected ? 0 : "full"}
              borderWidth={day.today ? 1 : undefined}
              borderColor={day.today ? "blue.500" : undefined}
            >
              {day.date.getDate()}
            </chakra.span>
          </Button>
        ))}
      </SimpleGrid>
    </Spaced>
  );
});

export function discardTime(date: Date): Date {
  return set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
}
