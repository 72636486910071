import { checks } from "@cartographerio/permission";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import useModuleInventory from "../../hooks/useModuleInventory";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";
import { ProjectSurveyBasePage } from "./ProjectSurveyBasePage";

export default function ProjectSurveyUpdatePage(
  props: RouteProps<typeof routes.workspace.project.survey.view>
): ReactElement {
  const {
    path: { surveyId, moduleId },
    query,
    updateQuery,
  } = props;

  const { page: defaultPageIndex = 0 } = query;

  const apiParams = useApiParams();

  const handlePageIndexChange = useCallback(
    (page: number) => {
      updateQuery({ ...query, page });
    },
    [query, updateQuery]
  );

  const defaultSurvey = useSuspenseQueryData(
    queries.survey.v3.readOrFail(apiParams, surveyId)
  );

  const module = useModuleInventory(defaultSurvey.moduleId);

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, defaultSurvey.projectId)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  useRedirectWhen(!project.moduleIds.includes(moduleId), () =>
    routes.workspace.project.home.url([workspace.alias, project.alias])
  );

  useRequirePermission(checks.survey.view(project));

  const pageTitle = useMemo(
    () =>
      [
        module
          .description(defaultSurvey)
          .getOrElse(() => `${module.names.shortName} Survey`),
        project.name,
        workspace.name,
      ].join(" - "),
    [module, defaultSurvey, project.name, workspace.name]
  );

  usePageTitle(pageTitle);

  return (
    <ProjectSurveyBasePage
      creating={false}
      defaultSurvey={defaultSurvey}
      module={module}
      project={project}
      workspace={workspace}
      defaultPageIndex={defaultPageIndex}
      onPageIndexChange={handlePageIndexChange}
    />
  );
}
