import { Box, Container, Icon, VStack, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import { IconType } from "react-icons/lib";

import Link from "./Link";

const year = new Date().getFullYear();
const years = year > 2021 ? `2021-${year}` : `${year}`;
const version = process.env.REACT_APP_VERSION;

interface FooterLinkProps {
  to: string;
  text: string;
  icon?: IconType;
}

function FooterLink(props: FooterLinkProps): ReactElement {
  const { to, text, icon } = props;
  return (
    <Link.External variant="plain" to={to} mx="1">
      {icon && <Icon as={icon} h="3" mr="1" />}
      {text}
    </Link.External>
  );
}

export default function PageFooter(): ReactElement {
  return (
    <Box as="footer" w="100%">
      <Container
        mx="auto"
        my="20"
        maxW="100ch"
        textAlign="center"
        color="gray.500"
        fontSize="sm"
      >
        <Box
          mx="auto"
          maxW="75%"
          borderTopWidth={1}
          borderTopColor="gray.100"
        />
        <VStack spacing="2" py="4">
          <chakra.p>
            Cartographer Web {version} | &copy; {years} Cartographer Studios Ltd
          </chakra.p>
          <chakra.p>
            <FooterLink
              to="call:442030955339"
              text="020 309 55339"
              icon={FaPhone}
            />
            {" | "}
            <FooterLink
              to="mailto:hello@cartographer.io"
              text="hello@cartographer.io"
              icon={FaEnvelope}
            />
          </chakra.p>
          <chakra.p>
            <FooterLink
              to="https://cartographer.io/legal/website-terms"
              text="Website Terms"
            />
            {" | "}
            <FooterLink
              to="https://cartographer.io/legal/platform-terms"
              text="Platform Terms"
            />
            {" | "}
            <FooterLink
              to="https://cartographer.io/legal/privacy-policy"
              text="Privacy Policy"
            />
            {" | "}
            <FooterLink
              to="https://cartographer.io/legal/cookie-policy"
              text="Cookie Policy"
            />
            {" | "}
            <FooterLink
              to="https://cartographer.io/legal/acknowledgements"
              text="Acknowledgements"
            />
          </chakra.p>
        </VStack>
      </Container>
    </Box>
  );
}
