import { InvitationCodeAlias } from "@cartographerio/types";
import { SystemProps } from "@chakra-ui/react";

import { useClickToCopy } from "./ClickToCopy";
import Pre from "./Pre";

interface InvitationCodeLabelProps {
  alias: InvitationCodeAlias;
}

export function InvitationCodeLabel({ alias }: InvitationCodeLabelProps) {
  const handleClick = useClickToCopy(alias, {
    successMessage: "Invitation code copied",
    failureMessage: "Unable to copy code",
  });

  return (
    <Pre
      text={alias}
      w="fit-content"
      onClick={handleClick}
      _hover={codeLabelHoverStyle}
    />
  );
}

const codeLabelHoverStyle: SystemProps["_hover"] = {
  borderColor: "gray.300",
  bg: "gray.200",
  cursor: "pointer",
};
