import {
  ArcgisAuthorizationStatus,
  ArcgisExportStatus,
  ArcgisIntegrationStatus,
  ddmmyyyy,
  formatTimestamp,
  nowTimestamp,
  timestampEpoch,
} from "@cartographerio/types";
import { Badge, BadgeProps, Icon, IconButton } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { IoIosKey } from "react-icons/io";
import { IoPlaySharp } from "react-icons/io5";

import {
  ActionsColumn,
  Column,
} from "../../../../components/SearchResultsList/column";

export function col(params: Column<ArcgisIntegrationStatus, never>) {
  return params;
}

interface IntegrationStatusBadgeProps {
  authorization?: ArcgisAuthorizationStatus | null;
}

function AuthorizationStatusBadge(
  props: IntegrationStatusBadgeProps
): ReactElement {
  const { authorization } = props;
  const { colorScheme, text } = useMemo<{
    colorScheme: BadgeProps["colorScheme"];
    text: string;
  }>(() => {
    if (authorization?.lastAuthorizationExpires == null) {
      return {
        colorScheme: "orange",
        text: "Not setup yet",
      };
    } else if (
      timestampEpoch(authorization.lastAuthorizationExpires) <
      timestampEpoch(nowTimestamp())
    ) {
      return {
        colorScheme: "red",
        text: "Expired",
      };
    } else {
      return {
        colorScheme: "green",
        text: `Expires on ${formatTimestamp(
          authorization.lastAuthorizationExpires,
          {
            format: ddmmyyyy,
          }
        )}`,
      };
    }
  }, [authorization?.lastAuthorizationExpires]);
  return (
    <Badge
      colorScheme={colorScheme}
      variant="outline"
      textAlign="center"
      textTransform="none"
      px="3"
      py="2"
      w="100%"
      rounded="md"
      fontSize="sm"
      fontWeight="semibold"
    >
      {text}
    </Badge>
  );
}
interface ExportStatusBadgeProps {
  exportStatus?: ArcgisExportStatus | null;
}

function ExportStatusBadge(props: ExportStatusBadgeProps): ReactElement {
  const { exportStatus } = props;

  const { colorScheme, text } = useMemo<{
    colorScheme: BadgeProps["colorScheme"];
    text: string;
  }>(() => {
    if (exportStatus == null) {
      return {
        colorScheme: "gray",
        text: "Not setup yet",
      };
    } else if (exportStatus.lastExportResult.type === "Failed") {
      return {
        colorScheme: "red",
        text: exportStatus.lastExportResult.message,
      };
    } else if (exportStatus.lastExportResult.type === "Skipped") {
      return {
        colorScheme: "orange",
        text: exportStatus.lastExportResult.message,
      };
    } else {
      return {
        colorScheme: "green",
        text: `Last exported ${formatTimestamp(exportStatus.lastExported, {
          format: ddmmyyyy,
        })}`,
      };
    }
  }, [exportStatus]);

  return (
    <Badge
      colorScheme={colorScheme}
      variant="outline"
      textAlign="center"
      textTransform="none"
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      px="3"
      py="2"
      w="21ch"
      rounded="md"
      fontSize="sm"
      fontWeight="semibold"
    >
      {text}
    </Badge>
  );
}

const nameColumn = col({
  title: "Name",
  width: "auto",
  render: status => status.integration.name,
});

const authorizationStatus = col({
  title: "Authorization Status",
  width: "13ch",
  render: status => (
    <AuthorizationStatusBadge authorization={status.authorization} />
  ),
});

const exportStatus = col({
  title: "Export Status",
  width: "13ch",
  render: status => <ExportStatusBadge exportStatus={status.export} />,
});

export const columns = [nameColumn, exportStatus, authorizationStatus];

interface ArcgisActionsColumnProps {
  onAuthorize: (status: ArcgisIntegrationStatus) => void;
  onExport: (status: ArcgisIntegrationStatus) => void;
}

export function arcgisActionsColumn(
  props: ArcgisActionsColumnProps
): ActionsColumn<ArcgisIntegrationStatus> {
  const { onAuthorize, onExport } = props;

  return {
    renderButtons: status => [
      <IconButton
        key="resend"
        variant="outline"
        icon={<Icon as={IoIosKey} />}
        title="Reauthorise"
        aria-label="Reauthorise"
        onClick={evt => {
          evt.preventDefault();
          evt.stopPropagation();
          onAuthorize(status);
        }}
      />,
      <IconButton
        key="resend"
        variant="outline"
        icon={<Icon as={IoPlaySharp} />}
        title="Export Now"
        aria-label="Export Now"
        onClick={evt => {
          evt.preventDefault();
          evt.stopPropagation();
          onExport(status);
        }}
      />,
    ],
  };
}
