import { BoxProps, extendTheme } from "@chakra-ui/react";

export const appBackground: BoxProps["bg"] = "gray.50";

export const appTheme = extendTheme({
  styles: {
    global: {
      body: {
        bg: appBackground,
        color: "gray.600",
      },
    },
  },
  colors: {
    //
  },
  fonts: {
    // heading: "Lato, sans-serif",
    // body: "Lato, sans-serif",
    mono: "Fira Code, monospace",
  },
  components: {
    Button: {
      baseStyle: {
        //
      },
      variants: {
        outline: {
          border: "2px solid",
          _hover: {
            bg: "gray.200",
          },
        },
        // These styles are supposed to compose with
        // the CSS selector `.mapbox-gtrl-group button`:
        map: {
          minWidth: 29,
          display: "flex !important",
          justifyContent: "center",
          alignItems: "center",
          padding: 0,
        },
        menu: {
          bg: "white",
          color: "blue.400",
          _hover: {
            bg: "gray.50",
          },
        },
        transparentMenu: {
          bg: "transparent",
          color: "white",
          boxShadow: "none",
          _hover: {
            boxShadow: "none",
          },
          _focus: {
            boxShadow: "none",
          },
        },
        plain: {
          bg: "white",
          _hover: {
            bg: "gray.50",
          },
          _focus: {
            boxShadow: "none",
            outline: "none",
            border: "none",
          },
        },
        helpPopover: {
          display: "inline-block",
          lineHeight: "auto",
        },
        mapInspector: {
          fontWeight: "normal",
          fontSize: "xs",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "gray.200",
          width: "100%",
          mx: "2",
          px: "2",
          py: "0",
          rounded: "sm",
          height: "6",
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: "white",
        },
        label: {
          _disabled: {
            opacity: 1.0,
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          bg: "white",
        },
        label: {
          _disabled: {
            opacity: 1.0,
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        color: "gray.800",
      },
    },
    Link: {
      baseStyle: {
        color: "blue.600",
        textDecoration: "none",
        _focus: {
          boxShadow: "none",
          textDecoration: "underline",
        },
        _hover: {
          color: "blue.700",
          textDecoration: "underline",
        },
      },
      variants: {
        plain: {
          color: "gray.500",
          textDecoration: "none",
        },
        _hover: {
          color: "gray.500",
          textDecoration: "underline",
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: "white",
            _disabled: {
              opacity: 1.0,
              bg: "gray.100",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: "white",
            _disabled: {
              opacity: 1.0,
              bg: "gray.100",
            },
          },
        },
      },
    },
    Tabs: {
      variants: {
        vertical: {
          root: {
            border: "1px solid",
            borderColor: "gray.100",
            borderRadius: "md",
            overflow: "hidden",
            boxShadow: "sm",
          },
          tablist: {
            bg: "gray.100",
            overflow: "hidden",
          },
          tab: {
            _selected: {
              bg: "white",
              boxShadow: "sm",
            },
          },
          tabpanels: {
            minWidth: "0",
            bg: "white",
            borderRightRadius: "md",
          },
          tabpanel: {
            px: "4",
            py: "4",
          },
          indicator: {
            //
          },
        },
        markdownEditor: {
          root: {
            borderRadius: "md",
            overflow: "hidden",
            boxShadow: "sm",
          },
          tablist: {
            overflow: "hidden",
          },
          tab: {
            borderTopRadius: "md",
            borderTopWidth: "1px",
            borderWidth: "1px",
            marginRight: "1",
            _selected: {
              bg: "white",
              boxShadow: "sm",
              borderBottomColor: "white",
            },
          },
          tabpanels: {
            minWidth: "0",
            bg: "white",
            borderWidth: "1px",
            borderRightRadius: "md",
            marginTop: "-1px",
          },
          tabpanel: {
            //
          },
          indicator: {
            //
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          bg: "white",
          _disabled: {
            opacity: 1.0,
            bg: "gray.100",
          },
        },
      },
    },
  },
});
