import { omit } from "lodash";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

export type Setter<K extends PropertyKey, V> = (
  key: K,
  value: V | undefined
) => void;

export type UseRecordResult<K extends PropertyKey, V> = [
  Record<K, V>,
  Setter<K, V>,
  Dispatch<SetStateAction<Record<K, V>>>
];

export default function useRecord<K extends PropertyKey, V>(
  initial: Record<K, V> | (() => Record<K, V>) = {} as Record<K, V>
): UseRecordResult<K, V> {
  const [record, setRecord] = useState<Record<K, V>>(initial);

  const setter = useCallback(
    (key: K, value: V | undefined) => {
      setRecord(record => {
        if (value === undefined) {
          return omit(record, key) as Record<K, V>;
        } else {
          return { ...record, [key]: value };
        }
      });
    },
    [setRecord]
  );

  return [record, setter, setRecord];
}
