import { SelectOption } from "@cartographerio/atlas-form";
import {
  InvitationSortKey,
  SearchResultsFormat,
  SortOrder,
  endpoints,
} from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  GlobalRoleName,
  InvitationStatus,
  InvitationStatusEnum,
  InvitationV2,
  ProjectAlias,
  ProjectRoleName,
  ProjectV2,
  SearchResults,
  TeamAlias,
  TeamRoleName,
  TeamV2,
  WorkspaceAlias,
  WorkspaceRoleName,
  WorkspaceV2,
} from "@cartographerio/types";
import { HStack, useToast } from "@chakra-ui/react";
import {
  UseQueryResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ReactElement, ReactNode, useCallback, useMemo } from "react";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import SearchField from "../../components/SearchField";
import SearchResultsList from "../../components/SearchResultsList";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import { useIOConfirm, useIOErrorAlert } from "../Alert";
import DownloadMenu from "../DownloadMenu";
import { ActionsColumn } from "../SearchResultsList/column";
import Select from "../Select";
import Spaced from "../Spaced";
import { invitationListActions, invitationListColumns } from "./column";

interface InvitationListProps {
  searchTerm?: string | null;
  page: number;
  count: number;
  order?: SortOrder<InvitationSortKey>;
  workspace?: WorkspaceV2;
  project?: ProjectV2;
  team?: TeamV2;
  status?: InvitationStatus | null;
  globalRole?: GlobalRoleName | null;
  workspaceRole?: WorkspaceRoleName | null;
  projectRole?: ProjectRoleName | null;
  teamRole?: TeamRoleName | null;
  roleSelect?: ReactNode;
  workspaceOptions?: SelectOption<WorkspaceAlias>[];
  projectOptions?: SelectOption<ProjectAlias>[];
  teamOptions?: SelectOption<TeamAlias>[];
  resetFilters?: ReactNode;
  addButton?: ReactNode;
  itemLink?: (item: InvitationV2) => string;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<InvitationSortKey>) => void;
  onWorkspaceChange?: (workspace: WorkspaceAlias | null) => void;
  onProjectChange?: (project: ProjectAlias | null) => void;
  onTeamChange?: (team: TeamAlias | null) => void;
  onStatusChange: (team: InvitationStatus | null) => void;
}

export default function InvitationList(
  props: InvitationListProps
): ReactElement {
  const {
    searchTerm = null,
    order = "created-desc",
    page,
    count,
    workspace,
    project,
    team,
    status,
    globalRole,
    workspaceRole,
    projectRole,
    teamRole,
    roleSelect,
    workspaceOptions,
    projectOptions,
    teamOptions,
    resetFilters,
    addButton,
    itemLink,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
    onWorkspaceChange,
    onProjectChange,
    onTeamChange,
    onStatusChange,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();
  const confirm = useIOConfirm();

  const hasPermission = usePermissionCheckRunner();

  const canDelete = useCallback(
    (invite: InvitationV2) => hasPermission(checks.invitation.remove(invite)),
    [hasPermission]
  );

  const handleResend = useCallback(
    (invitation: InvitationV2) =>
      queries.invitation.v3
        .resend(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation Resent",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast]
  );

  const handleCancel = useCallback(
    (invitation: InvitationV2) =>
      queries.invitation.v3
        .cancel(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation Cancelled",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, queryClient, toast]
  );

  const handleRemove = useCallback(
    (invitation: InvitationV2) => {
      confirm({
        title: "Delete Invitation",
        message: "Are you sure?",
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.invitation.v3
                .remove(queryClient, apiParams, invitation.id)
                .tap(() =>
                  toast({
                    title: "Invitation Deleted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
            : IO.noop()
        )
        .tapError(errorAlert)
        .unsafeRun();
    },
    [apiParams, confirm, errorAlert, queryClient, toast]
  );

  const actions: ActionsColumn<InvitationV2> = useMemo(
    () =>
      invitationListActions({
        onCancel: handleCancel,
        onResend: handleResend,
        canRemove: canDelete,
        onRemove: handleRemove,
      }),
    [canDelete, handleCancel, handleRemove, handleResend]
  );

  const { data, error } = useQuery(
    queries.invitation.v3.search(apiParams, {
      workspace: workspace?.id,
      project: project?.id,
      team: team?.id,
      q: searchTerm,
      globalRole,
      workspaceRole,
      projectRole,
      teamRole,
      status,
      order,
      skip: page * count,
      limit: count,
    })
  ) as UseQueryResult<SearchResults<InvitationV2> | null, unknown>;

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.invitation.v3.searchUrl({
          workspace: workspace?.id,
          project: project?.id,
          team: team?.id,
          q: searchTerm,
          globalRole,
          workspaceRole,
          projectRole,
          teamRole,
          order,
          format,
        })
      ),
    [
      formatApiUrl,
      globalRole,
      order,
      project?.id,
      projectRole,
      searchTerm,
      team?.id,
      teamRole,
      workspace?.id,
      workspaceRole,
    ]
  );

  const columns = useMemo(
    () => invitationListColumns({ link: itemLink }),
    [itemLink]
  );

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  return (
    <Spaced spacing="4">
      <HStack w="100%" justifyContent="stretch" wrap="wrap">
        <SearchField
          defaultValue={searchTerm}
          onChange={handleSearchChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        {workspaceOptions != null && (
          <Select.Searchable
            placeholder="All Workspaces"
            value={workspace?.alias ?? null}
            options={workspaceOptions}
            onChange={onWorkspaceChange}
            w="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        {projectOptions != null && (
          <Select.Nullable
            placeholder="All Projects"
            value={project?.alias ?? null}
            options={projectOptions}
            onChange={onProjectChange}
            w="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        {teamOptions != null && (
          <Select.Nullable
            placeholder="All Teams"
            value={team?.alias ?? null}
            options={teamOptions}
            onChange={onTeamChange}
            w="fit-content"
            background="transparent"
            maxW="52"
          />
        )}
        <Select.Nullable
          placeholder="All Statuses"
          value={status ?? null}
          options={InvitationStatusEnum.entries}
          onChange={onStatusChange}
          w="fit-content"
          background="transparent"
          maxW="52"
        />
        {roleSelect}
        <DownloadMenu downloadUrl={downloadUrl} />
        {addButton}
      </HStack>

      {resetFilters}

      <SearchResultsList
        page={page}
        count={count}
        order={order}
        results={data}
        error={error}
        columns={columns}
        actions={actions}
        onPageChange={onPageChange}
        onOrderChange={onOrderChange}
        itemKey={recordWithId}
      />
    </Spaced>
  );
}
