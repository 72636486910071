import * as attachment from "./attachment";
import * as auth from "./auth";
import { UseQueryOpts } from "./base";
import * as integration from "./integration";
import * as invitation from "./invitation";
import * as map from "./map";
import * as mapbox from "./mapbox";
import * as member from "./member";
import { cond, constant, empty, optional, unless, when } from "./optional";
import * as project from "./project";
import * as qualification from "./qualification";
import * as rca from "./rca";
import * as survey from "./survey";
import * as team from "./team";
import * as transfer from "./transfer";
import * as user from "./user";
import * as workspace from "./workspace";

export type { UseQueryOpts };
export default {
  attachment,
  auth,
  cond,
  constant,
  integration,
  invitation,
  map,
  mapbox,
  member,
  project,
  qualification,
  rca,
  survey,
  team,
  transfer,
  user,
  workspace,
  optional,
  when,
  unless,
  empty,
};
