import { BoxProps, Flex } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface InspectorItemProps {
  left?: ReactNode;
  right?: ReactNode;
  selectable?: boolean;
  children?: ReactNode;
  onClick?: BoxProps["onClick"];
}

const selectableStyle: BoxProps["_hover"] = {
  cursor: "pointer",
  bg: "gray.100",
};

export default function InspectorMenuItem(
  props: InspectorItemProps
): ReactElement {
  const { left, right, onClick, selectable, children } = props;

  return (
    <Flex
      w="100%"
      direction="row"
      align="center"
      fontSize="10pt"
      onClick={onClick}
      py="1"
      cursor={selectable ? "pointer" : undefined}
      _hover={selectable ? selectableStyle : undefined}
    >
      {left && (
        <Flex pl="2" grow={0} shrink={0}>
          {left}
        </Flex>
      )}
      <Flex px="2" grow={1} shrink={1} minW="0">
        {children}
      </Flex>
      {right && (
        <Flex pr="2" grow={0} shrink={0}>
          {right}
        </Flex>
      )}
    </Flex>
  );
}
