import { errorMessage } from "@cartographerio/atlas-core";
import {
  InvitationEmailSettings,
  NamedEmail,
  ProjectEmailSettings,
  SurveyApprovedEmailSettings,
  SurveyCompleteEmailSettings,
  WorkspaceEmailSettings,
  WorkspaceJoinerEmailSettings,
} from "@cartographerio/types";

import { rule } from "./rule";

export const { rule: namedEmailRule, keys: namedEmailErrorKeys } = rule
  .build<NamedEmail>()
  .field(
    "name",
    rule.and(
      rule.nonEmpty([errorMessage("Enter a name")]),
      rule.lengthLte(50, [
        errorMessage("Names can't be longer than 50 characters"),
      ])
    )
  )
  .field("email", rule.email([errorMessage("Enter an email address")]))
  .finish();

export const {
  rule: invitationEmailSettingsRule,
  keys: invitationEmailSettingsErrorKeys,
} = rule
  .build<InvitationEmailSettings>()
  .conditionalField(
    ({ fromCoordinator }) => !fromCoordinator,
    "fromAddress",
    rule.required(
      [errorMessage("You must provide a name and email")],
      namedEmailRule
    )
  )
  .field("toAddresses", rule.array(namedEmailRule))
  .finish();

export const {
  rule: workspaceJoinerEmailSettingsRule,
  keys: workspaceJoinerEmailSettingsErrorKeys,
} = rule
  .build<WorkspaceJoinerEmailSettings>()
  .field("toAddresses", rule.array(namedEmailRule))
  .finish();

export const {
  rule: surveyCompleteEmailSettingsRule,
  keys: surveyCompleteEmailSettingsErrorKeys,
} = rule
  .build<SurveyCompleteEmailSettings>()
  .field("toAddresses", rule.array(namedEmailRule))
  .finish();

export const {
  rule: surveyApprovedEmailSettingsRule,
  keys: surveyApprovedEmailSettingsErrorKeys,
} = rule
  .build<SurveyApprovedEmailSettings>()
  .conditionalField(
    ({ fromApprover }) => !fromApprover,
    "fromAddress",
    rule.required(
      [errorMessage("You must provide a name and email")],
      namedEmailRule
    )
  )
  .field("toAddresses", rule.array(namedEmailRule))
  .finish();

export const {
  rule: workspaceEmailSettingsRule,
  keys: workspaceEmailSettingsErrorKeys,
} = rule
  .build<WorkspaceEmailSettings>()
  .field("invitation", invitationEmailSettingsRule)
  .field("joiner", workspaceJoinerEmailSettingsRule)
  .finish();

export const {
  rule: projectEmailSettingsRule,
  keys: projectEmailSettingsErrorKeys,
} = rule
  .build<ProjectEmailSettings>()
  .field("invitation", invitationEmailSettingsRule)
  .field("complete", surveyCompleteEmailSettingsRule)
  .field("approved", surveyApprovedEmailSettingsRule)
  .finish();
