import { range } from "lodash";

export type NearbyPageCount = 0 | 1 | 3 | 5 | 7 | 9;

export interface PaginationParams {
  pageSize: number;
  page: number;
  total: number;
  showNearby?: NearbyPageCount | false;
  showShowing?: boolean;
}

const defaultNearby: NearbyPageCount = 5;

export interface PaginationPage {
  pageIndex: number;
  itemIndex: number;
  active: boolean;
}

export interface PaginationEllipses {
  left: boolean;
  right: boolean;
}

export interface PaginationShowing {
  from: number;
  to: number;
  of: number;
}

export interface PaginationData {
  first: PaginationPage;
  last: PaginationPage;
  prev: PaginationPage;
  next: PaginationPage;
  nearby: PaginationPage[];
  ellipsis?: PaginationEllipses;
  showing?: PaginationShowing;
}

export default function usePagination(
  params: PaginationParams
): PaginationData | null {
  const { page, pageSize, total, showNearby, showShowing } = params;

  const nearby = showNearby === false ? 0 : showNearby ?? defaultNearby;
  const showing = showShowing ?? true;

  const first = 0;
  const last = total <= 0 ? 0 : Math.floor((total - 1) / pageSize);
  const current = Math.max(first, Math.min(last, page));

  if (first === current && last === current) {
    return null;
  } else {
    const prev = Math.max(first, current - 1);
    const next = Math.min(last, current + 1);

    let nearbyLeft = Math.max(first, current - Math.floor(nearby / 2));
    const nearbyRight = Math.min(last + 1, nearbyLeft + nearby);
    nearbyLeft = Math.max(first, nearbyRight - nearby);

    const page = (number: number): PaginationPage => ({
      pageIndex: number,
      itemIndex: number * pageSize,
      active: current === number,
    });

    return {
      first: page(first),
      last: page(last),
      prev: page(prev),
      next: page(next),
      nearby: range(nearbyLeft, nearbyRight).map(page),
      ellipsis:
        nearby === 0
          ? undefined
          : {
              left: nearbyLeft > first,
              right: nearbyRight < last,
            },
      showing: showing
        ? {
            from: Math.max(0, Math.min(current * pageSize, total)),
            to: Math.max(0, Math.min((current + 1) * pageSize, total)),
            of: total,
          }
        : undefined,
    };
  }
}
