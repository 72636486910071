import { MemberSortKey, SortOrder } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  CurrentMemberV2,
  InvitationId,
  MemberV2,
  PendingMemberV2,
  RoleV2,
  SearchResults,
  UserId,
  WorkspaceV2,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { memberId } from "../../../util";
import { useApiParams, useCredentialsV2 } from "../../contexts/auth";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import { routes } from "../../routes";
import { useIOAlert, useIOErrorAlert } from "../Alert";
import SearchResultsList from "../SearchResultsList";
import { Column } from "../SearchResultsList/column";
import { ApproveRejectCallback, OnMemberAction, actionsColumn } from "./column";

export * from "../UserList/role";

export interface MemberListProps {
  data: SearchResults<MemberV2> | null;
  error: unknown;
  page: number;
  count: number;
  workspace?: WorkspaceV2 | null;
  order?: SortOrder<MemberSortKey>;
  columns: Column<MemberV2, MemberSortKey>[];
  enableOptionsModal?: boolean;
  changeIdentityRedirect?: string;
  onRemove?: OnMemberAction;
  onApprove?: ApproveRejectCallback;
  onReject?: ApproveRejectCallback;
  searchAll?: () => IO<MemberV2[]>;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<MemberSortKey>) => void;
  itemLink?: (member: MemberV2) => string;
  selection?: MemberV2[];
  onSelectionChange?: (selection: MemberV2[]) => void;
}

export default function MemberList(props: MemberListProps): ReactElement {
  const {
    data,
    error,
    page,
    count,
    workspace,
    order = "name-asc",
    columns,
    changeIdentityRedirect,
    onRemove,
    onApprove,
    onReject,
    searchAll,
    onPageChange,
    onOrderChange,
    itemLink,
    selection = [],
    onSelectionChange,
  } = props;

  const permissionCheckPasses = usePermissionCheckRunner();
  const navigate = useNavigate();
  const { identity } = useCredentialsV2();

  const handleImpersonate = useCallback<
    OnMemberAction<CurrentMemberV2 | PendingMemberV2>
  >(
    member => {
      navigate(
        routes.identity.change.url([member.userId], {
          go: changeIdentityRedirect,
        })
      );
    },
    [changeIdentityRedirect, navigate]
  );

  const canApproveOrReject = useCallback(
    (member: PendingMemberV2) =>
      permissionCheckPasses(
        checks.invitationCode.approveOrRejectSignup(
          workspace?.id ?? null,
          member.invitationCodeId,
          member.invitationCodeRoles,
          member.invitationCodeQualificationRoles
        )
      ),
    [permissionCheckPasses, workspace?.id]
  );

  const actions = useMemo(
    () =>
      actionsColumn(
        identity.userId,
        canApproveOrReject,
        permissionCheckPasses(checks.auth.changeIdentity()) &&
          changeIdentityRedirect != null
          ? handleImpersonate
          : null,
        onRemove,
        onApprove,
        onReject
      ),
    [
      identity.userId,
      canApproveOrReject,
      permissionCheckPasses,
      changeIdentityRedirect,
      handleImpersonate,
      onRemove,
      onApprove,
      onReject,
    ]
  );

  const [allSelected, setAllSelected] = useState(false);
  const [allResults, setAllResults] = useState<MemberV2[] | null>(null);

  useEffect(() => {
    setAllSelected(false);
    setAllResults(null);
  }, []);

  const onSelectItem = useCallback(
    (member: MemberV2) => {
      if (onSelectionChange != null) {
        const filtered = selection.filter(
          other => memberId(member) === memberId(other)
        );
        onSelectionChange(
          selection?.length === filtered.length
            ? [...selection, member]
            : filtered
        );
        setAllSelected(false);
      }
    },
    [onSelectionChange, selection]
  );

  const onSelectAll = useCallback(() => {
    if (onSelectionChange != null) {
      if (allSelected) {
        onSelectionChange([]);
        setAllSelected(false);
      } else if (allResults != null) {
        onSelectionChange(allResults);
        setAllSelected(true);
      } else {
        searchAll?.()
          .tap(members => {
            onSelectionChange(members);
            setAllResults(members);
            setAllSelected(true);
          })
          .unsafeRun();
      }
    }
  }, [allResults, allSelected, onSelectionChange, searchAll]);

  return (
    <SearchResultsList<MemberV2, MemberSortKey, UserId | InvitationId>
      results={data}
      error={error}
      columns={columns}
      actions={actions}
      page={page}
      count={count}
      itemLink={itemLink}
      onPageChange={onPageChange}
      order={order}
      onOrderChange={onOrderChange}
      itemKey={member =>
        member.type === "InvitedMember" ? member.invitationId : member.userId
      }
      selection={onSelectionChange != null ? selection : undefined}
      onSelectItem={onSelectionChange != null ? onSelectItem : undefined}
      allSelected={onSelectionChange != null ? allSelected : undefined}
      onSelectAll={onSelectionChange != null ? onSelectAll : undefined}
    />
  );
}

export function useApproveCallback(): ApproveRejectCallback {
  const ioAlert = useIOAlert();
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback(
    request => {
      ioAlert({
        message: "Are you sure you want to approve this signup?",
        okColorScheme: "blue",
        cancelLabel: "Cancel",
      })
        .chain(confirmation =>
          confirmation
            ? queries.invitation.code.signup.v3
                .approve(queryClient, apiParams, request)
                .tap(() =>
                  toast({
                    title: "Signup approved",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tapError(errorAlert)
                .void()
            : IO.noop()
        )
        .unsafeRun();
    },
    [apiParams, errorAlert, ioAlert, queryClient, toast]
  );
}

export function useRejectCallback(): ApproveRejectCallback {
  const ioAlert = useIOAlert();
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback(
    request => {
      ioAlert({
        message: "Are you sure you want to reject this signup?",
        okColorScheme: "red",
        cancelLabel: "Cancel",
      })
        .chain(confirmation =>
          confirmation
            ? queries.invitation.code.signup.v3
                .reject(queryClient, apiParams, request)
                .tap(() =>
                  toast({
                    title: "Signup rejected",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tapError(errorAlert)
                .void()
            : IO.noop()
        )
        .unsafeRun();
    },
    [apiParams, errorAlert, ioAlert, queryClient, toast]
  );
}

export function useRemoveCallback(
  successMessage: string,
  updateRoles: (role: RoleV2[]) => RoleV2[]
) {
  const ioAlert = useIOAlert();
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();

  return useCallback<OnMemberAction>(
    member => {
      ioAlert({
        message: "Are you sure you want to remove this member?",
        okColorScheme: "blue",
        cancelLabel: "Cancel",
      })
        .flatMap(confirmation =>
          confirmation
            ? (member.type === "InvitedMember"
                ? queries.invitation.v3.cancel(
                    queryClient,
                    apiParams,
                    member.invitationId
                  )
                : queries.user.v2.save(queryClient, apiParams, member.userId, {
                    ...member,
                    roles: updateRoles(member.roles),
                  })
              )
                .tap(() =>
                  toast({
                    title: successMessage,
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tapError(errorAlert)
                .void()
            : IO.noop()
        )

        .unsafeRun();
    },
    [
      apiParams,
      errorAlert,
      ioAlert,
      queryClient,
      successMessage,
      toast,
      updateRoles,
    ]
  );
}
