import { PermissionCheck } from "@cartographerio/types";
import { useMemo } from "react";

import usePermissionCheckRunner from "./usePermissionCheckRunner";

export function usePermissionCheckPasses(check: PermissionCheck): boolean {
  const permissionCheckPasses = usePermissionCheckRunner();
  return useMemo(
    () => permissionCheckPasses(check),
    [check, permissionCheckPasses]
  );
}
