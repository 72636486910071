import { SelectOption } from "@cartographerio/atlas-form";
import { IO } from "@cartographerio/io";
import {
  GlobalRoleName,
  InvitationCodeCreateV2,
  InvitationCodeV2,
  InvitationCreateV2,
  InvitationV2,
  ProjectAlias,
  ProjectRoleName,
  ProjectV2,
  TeamAlias,
  TeamRoleName,
  TeamV2,
  WorkspaceAlias,
  WorkspaceRoleName,
  WorkspaceV2,
} from "@cartographerio/types";
import { ReactElement, ReactNode } from "react";

import { AnyPathPart, AnyQueryParams } from "../../../routes/base";
import {
  Route,
  RouteQueryProps,
  RouteQueryUpdate,
} from "../../../routes/Route";
import Container from "../../components/Container";
import InvitationList from "../../components/InvitationList";
import ResetFiltersAlert from "../../components/ResetFiltersAlert";

export type BaseInviteButtonCallback = (onSubmit: {
  onCodeSubmit: (invite: InvitationCodeCreateV2) => IO<InvitationCodeV2>;
  onSingleSubmit: (invite: InvitationCreateV2) => IO<InvitationV2>;
}) => ReactNode;

export interface GraphItemInvitationListProps {
  workspace?: WorkspaceV2;
  project?: ProjectV2;
  team?: TeamV2;
  globalRole?: GlobalRoleName | null;
  workspaceRole?: WorkspaceRoleName | null;
  projectRole?: ProjectRoleName | null;
  teamRole?: TeamRoleName | null;
  roleSelect?: ReactNode;
  workspaceOptions?: SelectOption<WorkspaceAlias>[];
  projectOptions?: SelectOption<ProjectAlias>[];
  teamOptions?: SelectOption<TeamAlias>[];
  addButton?: ReactNode;
  itemLink?: (item: InvitationV2) => string;
  onWorkspaceChange?: (workspace: WorkspaceAlias | null) => void;
  onProjectChange?: (project: ProjectAlias | null) => void;
  onTeamChange?: (team: TeamAlias | null) => void;
}

interface BaseInvitationListPageProps<
  R extends Route<AnyPathPart[], AnyQueryParams>
> extends GraphItemInvitationListProps {
  route: R;
  query: RouteQueryProps<R>;
  updateQuery: RouteQueryUpdate<R>;
}

export default function BaseInvitationListPage<
  R extends Route<AnyPathPart[], AnyQueryParams>
>(props: BaseInvitationListPageProps<R>): ReactElement {
  const { route, query, updateQuery, ...listProps } = props;
  const { order = "created-desc", page, status, count, q: searchTerm } = query;

  return (
    <Container width="wide">
      <InvitationList
        order={order}
        page={page}
        count={count}
        searchTerm={searchTerm}
        status={status}
        onOrderChange={order => updateQuery({ ...query, order })}
        onPageChange={page => updateQuery({ ...query, page })}
        onSearchTermChange={q =>
          updateQuery({ ...query, q: q ?? undefined, page: 0 })
        }
        onStatusChange={status => updateQuery({ ...query, status })}
        resetFilters={
          <ResetFiltersAlert
            route={route}
            query={query}
            updateQuery={updateQuery}
          />
        }
        {...listProps}
      />
    </Container>
  );
}
