import { IconButton } from "@chakra-ui/react";
import { FaBars } from "react-icons/fa";

import { SIDEBAR_ANIMATION_DURATION, SIDEBAR_BUTTON_SIZE } from "./base";

export interface SidebarButtonProps {
  sidebarVisible: boolean;
  toggleSidebar: () => void;
}

export default function SidebarButton(props: SidebarButtonProps) {
  const { sidebarVisible, toggleSidebar } = props;

  return (
    <IconButton
      zIndex="banner"
      variant="menu"
      minW="unset"
      w={SIDEBAR_BUTTON_SIZE}
      h={SIDEBAR_BUTTON_SIZE}
      icon={<FaBars />}
      aria-label={sidebarVisible ? "Hide Sidebar Menu" : "Show Sidebar Menu"}
      title={sidebarVisible ? "Hide Sidebar Menu" : "Show Sidebar Menu"}
      onClick={toggleSidebar}
      transitionProperty="all"
      transitionDuration={SIDEBAR_ANIMATION_DURATION}
      boxShadow="sm"
    />
  );
}
