import { endpoints } from "@cartographerio/client";
import { AccessToken, ApiConfig } from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type EmbedKey = ["map"] | ["map", "embed", "v1", "authorize"];

export function authorize(
  apiConfig: ApiConfig
): UseQueryOpts<AccessToken, EmbedKey> {
  return {
    queryKey: ["map", "embed", "v1", "authorize"],
    queryFn: () => endpoints.map.embed.v1.authorize(apiConfig).unsafeRun(),
  };
}
