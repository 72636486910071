import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import Link from "./Link";
import Markdown from "./Markdown";
import TextArea from "./TextArea";

interface MarkdownEditorProps {
  value: string;
  onChange?: (value: string) => void;
}

export default function MarkdownEditor(
  props: MarkdownEditorProps
): ReactElement {
  const { value, onChange } = props;
  return (
    <>
      <Tabs variant="markdownEditor" isLazy={true}>
        <TabList>
          <Tab>Edit</Tab>
          <Tab>Preview</Tab>
        </TabList>
        <TabPanels>
          <TabPanel p="0">
            <TextArea.String
              value={value}
              onChange={text => onChange?.(text)}
              rows={6}
              border="none"
              rounded="md"
            />
          </TabPanel>
          <TabPanel p="0">
            <Markdown
              text={value ?? ""}
              py="2"
              px="4"
              minH="10rem"
              bg="white"
              rounded="md"
              border="none"
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <chakra.span ps="1" fontStyle="italic" fontSize="sm">
        <Link.External to="https://commonmark.org/help/">
          Markdown formatting supported
        </Link.External>
      </chakra.span>
    </>
  );
}
