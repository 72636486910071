import { SortOrder } from "@cartographerio/client";
import { Icon } from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  RiArrowDownLine,
  RiArrowUpDownLine,
  RiArrowUpLine,
} from "react-icons/ri";

import { asc, desc } from "./sort";

export interface SortIconProps<O extends string> {
  sortId?: O;
  sortOrder?: SortOrder<O>;
}

export default function SortIcon<O extends string>(
  props: SortIconProps<O>
): ReactElement | null {
  const { sortId, sortOrder } = props;

  if (sortId == null || sortOrder == null) {
    return null;
  } else if (sortOrder === asc(sortId)) {
    return <Icon ml="1" as={RiArrowUpLine} color="gray.500" />;
  } else if (sortOrder === desc(sortId)) {
    return <Icon ml="1" as={RiArrowDownLine} color="gray.500" />;
  } else {
    return <Icon ml="1" as={RiArrowUpDownLine} color="gray.500" />;
  }
}
