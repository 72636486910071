import { useEffect, useRef } from "react";

// Adapted from https://usehooks.com/usePrevious/

export default function usePrevious<A>(value: A, initial?: A): A {
  const ref = useRef<A>(initial === undefined ? value : initial);

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  return ref.current;
}
