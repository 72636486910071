import { MarkerStyle } from "@cartographerio/atlas-map";
import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface InspectorBarProps {
  marker: MarkerStyle;
  count: number | undefined;
  maxCount: number | undefined;
}

export default function InspectorBar(props: InspectorBarProps): ReactElement {
  const { marker, count, maxCount } = props;

  return count != null && maxCount != null && maxCount > 0 ? (
    <Box w="100%">
      <Box rounded="full" bg="gray.100">
        <Box
          w={`${(100 * count) / maxCount}%`}
          h="1"
          rounded="full"
          bg={marker.normal.fillColor}
        ></Box>
      </Box>
    </Box>
  ) : (
    <Box h="1" />
  );
}
