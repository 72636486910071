import { DomainListEditor, DomainListEditorProps } from "./DomainListEditor";
import {
  DomainListEnabledCheckbox,
  DomainListEnabledCheckboxProps,
} from "./DomainListEnabledCheckbox";
import { ListEditor, ListEditorProps } from "./ListEditor";
import {
  ListEnabledCheckbox,
  ListEnabledCheckboxProps,
} from "./ListEnabledCheckbox";
import {
  NamedEmailListEditor,
  NamedEmailListEditorProps,
} from "./NamedEmailListEditor";
import {
  NamedEmailListEnabledCheckbox,
  NamedEmailListEnabledCheckboxProps,
} from "./NamedEmailListEnabledCheckbox";

export type {
  ListEditorProps,
  ListEnabledCheckboxProps,
  NamedEmailListEditorProps,
  NamedEmailListEnabledCheckboxProps,
  DomainListEditorProps,
  DomainListEnabledCheckboxProps,
};

export default {
  Default: ListEditor,
  Checkbox: ListEnabledCheckbox,
  NamedEmail: NamedEmailListEditor,
  NamedEmailCheckbox: NamedEmailListEnabledCheckbox,
  Domain: DomainListEditor,
  DomainCheckbox: DomainListEnabledCheckbox,
};
