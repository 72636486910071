import { theme } from "@chakra-ui/react";

export const SIDEBAR_BUTTON_SIZE = "2rem";
export const TOPBAR_HEIGHT = 48;
export const SWITCHER_ONLY_WIDTH = 74;

export const SIDEBAR_WIDTH = 320;

export const SIDEBAR_OVERLAY_ZINDEX = theme.zIndices.overlay - 1;
export const SIDEBAR_ANIMATION_DURATION = "200ms";
