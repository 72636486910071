import Fuse from "fuse.js";
import { take } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { useEffectOnce } from "./useEffectOnce";

export type FuseOptions<T = string> = Fuse.IFuseOptions<T>;
export type FuseResult<T = string> = Fuse.FuseResult<T>;

export default function useFuse<T = string>(
  haystack: T[],
  options: Fuse.IFuseOptions<T>,
  maxResults?: number,
  ignoreEmpty: boolean = true,
  initialSearch: string = ""
): [Fuse.FuseResult<T>[], (text: string | null) => void] {
  const [results, setResults] = useState<Fuse.FuseResult<T>[]>([]);

  const fuse = useMemo(() => new Fuse(haystack, options), [haystack, options]);

  const handleChange = useCallback(
    (text: string | null) => {
      if (ignoreEmpty && (text == null || text.length === 0)) {
        setResults([]);
      } else {
        setResults(
          (text?.length ?? 0) === 0
            ? haystack.map((item, refIndex) => ({ item, refIndex }))
            : maxResults == null
            ? fuse.search(text ?? "")
            : take(fuse.search(text ?? ""), maxResults)
        );
      }
    },
    [fuse, haystack, ignoreEmpty, maxResults]
  );

  useEffectOnce(() => handleChange(initialSearch));

  return [results, handleChange];
}
