import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { ApiParams, ProjectMapSettings, TeamId } from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type TeamMapSettingsKey = ["team", "map-settings", "v1", TeamId];

export function readOrNull(
  apiParams: ApiParams,
  teamId: TeamId
): UseQueryOpts<ProjectMapSettings | null, TeamMapSettingsKey> {
  return {
    queryKey: ["team", "map-settings", "v1", teamId],
    queryFn: () =>
      endpoints.team.mapSettings.v1.readOrNull(apiParams, teamId).unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  teamId: TeamId,
  settings: ProjectMapSettings | null
): IO<void> {
  return (
    settings == null
      ? endpoints.team.mapSettings.v1.remove(apiParams, teamId)
      : endpoints.team.mapSettings.v1.save(apiParams, teamId, settings)
  ).tap(_ => queryClient.invalidateQueries(["team", "map-settings"]));
}
