import {
  ProjectVisibility,
  ProjectVisibilityEnum,
} from "@cartographerio/types";
import { Icon } from "@chakra-ui/react";
import { HiLockClosed } from "react-icons/hi";

export interface ProjectVisibilityIconProps {
  visibility: ProjectVisibility;
}

export default function ProjectVisibilityIcon(
  props: ProjectVisibilityIconProps
) {
  const { visibility } = props;

  return visibility === ProjectVisibilityEnum.Private ? (
    <Icon
      as={HiLockClosed}
      display="inline-block"
      verticalAlign="baseline"
      color="gray.300"
      title="Private Project"
    />
  ) : null;
}
