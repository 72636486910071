import { SelectOption } from "@cartographerio/atlas-form";
import { check } from "@cartographerio/permission";
import {
  NonNullableValues,
  Qualification,
  QualificationHistoryEvent,
  QualificationRoleNameEnum,
  UserId,
  UserRef,
  identityToUserRef,
  nowTimestamp,
  userToUserRef,
} from "@cartographerio/types";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import { Fragment, ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import queries from "../../queries";
import { useApiParams, useCredentialsV2 } from "../contexts/auth";
import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import Button from "./Button";
import Placeholder from "./Placeholder";
import Select from "./Select";
import Spaced from "./Spaced";
import TimestampField from "./TimestampField";

interface QualificationHistoryModalProps {
  qualification: Qualification;
  value: QualificationHistoryEvent[];
  disabled?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onChange?: (history: QualificationHistoryEvent[]) => void;
}

export default function QualificationHistoryModal(
  props: QualificationHistoryModalProps
): ReactElement {
  const { qualification, value, disabled, isOpen, onClose, onChange } = props;

  const apiParams = useApiParams();
  const { identity } = useCredentialsV2();
  const canSetUpdatedBy = usePermissionCheckPasses(check.superuser);

  const _updatedByResults = useSuspenseSearchResults(
    queries.user.v2.searchAnonymised(apiParams, {
      qualification: qualification.id,
      qualificationRole: QualificationRoleNameEnum.Trainer,
    })
  );

  const updatedByResults = useMemo(
    () =>
      _updatedByResults
        .sort((a, b) => (a.screenName > b.screenName ? 1 : -1))
        .map(userToUserRef)
        .concat(identityToUserRef(identity)),
    [_updatedByResults, identity]
  );

  const updatedByLookup = useMemo(
    (): Record<UserId, NonNullableValues<UserRef>> | null =>
      updatedByResults?.reduce(
        (acc, ref) =>
          ref.userId != null ? { ...acc, [ref.userId]: ref } : acc,
        {}
      ) ?? null,
    [updatedByResults]
  );

  const updatedByOptions = useMemo(
    (): SelectOption<UserId>[] =>
      updatedByResults?.map(({ userId, screenName }) => ({
        label: screenName,
        value: userId,
      })) ?? [],
    [updatedByResults]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{qualification.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Spaced>
            {value.length > 0 ? (
              <SimpleGrid
                gridTemplateColumns={
                  disabled ? "1fr 1fr 1fr" : "1fr 1fr 1fr var(--chakra-space-8)"
                }
                gap="2"
              >
                <chakra.span textAlign="center">Date/Time</chakra.span>
                <chakra.span textAlign="center">
                  Qualification Level
                </chakra.span>
                <chakra.span textAlign="center">Updated By</chakra.span>
                {!disabled && <chakra.span />}

                {value.map((event, index) => (
                  <Fragment key={index}>
                    <TimestampField
                      value={event.timestamp}
                      size="sm"
                      onChange={timestamp => {
                        if (timestamp != null) {
                          onChange?.(
                            value.toSpliced(index, 1, { ...event, timestamp })
                          );
                        }
                      }}
                      nullable={false}
                      disabled={disabled}
                    />
                    <Select.Nullable
                      value={event.roleName ?? null}
                      size="sm"
                      placeholder="No Role"
                      onChange={roleName =>
                        onChange?.(
                          value.toSpliced(index, 1, {
                            ...event,
                            updatedBy: updatedByLookup?.[identity.userId],
                            roleName,
                          })
                        )
                      }
                      options={QualificationRoleNameEnum.entries}
                      disabled={disabled}
                    />
                    <Select.Searchable
                      value={event.updatedBy?.userId}
                      size="sm"
                      options={updatedByOptions}
                      disabled={disabled || !canSetUpdatedBy}
                      onChange={userId =>
                        onChange?.(
                          value.toSpliced(index, 1, {
                            ...event,
                            updatedBy:
                              userId != null ? updatedByLookup?.[userId] : null,
                          })
                        )
                      }
                    />
                    {!disabled && (
                      <IconButton
                        variant="outline"
                        size="sm"
                        title="Remove event"
                        aria-label="Remove event"
                        icon={<IoCloseSharp size="1.25rem" />}
                        alignSelf="center"
                        disabled={onChange == null}
                        onClick={() => onChange?.(value.toSpliced(index, 1))}
                      />
                    )}
                  </Fragment>
                ))}
              </SimpleGrid>
            ) : (
              <Placeholder text="No qualification history" />
            )}
          </Spaced>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="space-between" gap="2">
          {!disabled && (
            <Button
              label="Add Row"
              size="sm"
              variant="outline"
              onClick={() =>
                onChange?.(
                  value.concat({
                    timestamp: nowTimestamp(),
                    updatedBy: identityToUserRef(identity),
                  })
                )
              }
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
