import { InvitationSortKey } from "@cartographerio/client";
import {
  InvitationV2,
  canCancelInvitation,
  canResendInvitation,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { IconButton, LinkOverlay } from "@chakra-ui/react";
import {
  IoCloseSharp,
  IoSendSharp,
  IoStopCircleOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

import { InvitationStatusBadge } from "../InvitationStatusBadge";
import { ActionsColumn, Column } from "../SearchResultsList/column";

export function col(params: Column<InvitationV2, InvitationSortKey>) {
  return params;
}

export interface InvitationListColumnsProps {
  link?: (invite: InvitationV2) => string;
}

function nameColumn(props: InvitationListColumnsProps) {
  const { link } = props;

  return col({
    title: "Name",
    orderBy: "name",
    width: "auto",
    render: invitation => {
      const label = `${invitation.firstName} ${invitation.lastName}`;
      return link == null ? (
        label
      ) : (
        <LinkOverlay as={Link} to={link(invitation)}>
          {label}
        </LinkOverlay>
      );
    },
  });
}

const emailColumn = col({
  title: "Email",
  orderBy: "email",
  width: "13ch",
  render: invitation => invitation.email,
});

const createdColumn = col({
  title: "Created",
  orderBy: "created",
  defaultOrder: "desc",
  width: "10ch",
  render: invitation =>
    formatTimestamp(invitation.created, { format: ddmmyyyy }),
});

const invitedByColumn = col({
  title: "Invited By",
  width: "10ch",
  render: invitation => invitation.createdBy.screenName,
});

const statusColumn = col({
  title: "Status",
  width: "13ch",
  render: invitation => (
    <InvitationStatusBadge invitation={invitation} w="100%" />
  ),
});

export function invitationListColumns(props: InvitationListColumnsProps) {
  return [
    nameColumn(props),
    emailColumn,
    createdColumn,
    invitedByColumn,
    statusColumn,
  ];
}

interface InvitationListActionsOptions {
  onCancel: (invitation: InvitationV2) => void;
  onResend: (invitation: InvitationV2) => void;
  canRemove: (invitation: InvitationV2) => boolean;
  onRemove: (invitation: InvitationV2) => void;
}

export function invitationListActions(
  options: InvitationListActionsOptions
): ActionsColumn<InvitationV2> {
  const { onCancel, onResend, canRemove, onRemove } = options;

  return {
    renderButtons: invitation => [
      canResendInvitation(invitation) && (
        <IconButton
          key="resend"
          variant="outline"
          icon={<IoSendSharp />}
          title="Resend"
          aria-label="Resend"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onResend(invitation);
          }}
        />
      ),
      canCancelInvitation(invitation) && (
        <IconButton
          key="cancel"
          variant="outline"
          icon={<IoStopCircleOutline size="1.25rem" />}
          title="Cancel"
          aria-label="Cancel"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onCancel(invitation);
          }}
        />
      ),
      canRemove(invitation) && (
        <IconButton
          key="delete"
          variant="outline"
          icon={<IoCloseSharp size="1.25rem" />}
          title="Delete"
          aria-label="Delete"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onRemove(invitation);
          }}
        />
      ),
    ],
  };
}
