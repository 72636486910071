import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import { isProjectTransferRequestV2 } from "@cartographerio/types";
import { Button, FormControl, chakra } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import {
  PartialProjectTransferRequest,
  projectTransferRequestErrorKeys,
  projectTransferRequestRule,
} from "../../../../schema/ProjectTransferRequest";
import { splitMessages } from "../../../../schema/rule/errors";
import { useIOAlert, useIOErrorAlert } from "../../../components/Alert";
import Checkbox from "../../../components/Checkbox";
import Container from "../../../components/Container";
import FormLabel from "../../../components/FormLabel";
import JsonView from "../../../components/JsonView";
import MessageFormControl from "../../../components/MessageFormControl";
import PageTopBar from "../../../components/PageTopBar";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectTransferPage(
  props: RouteProps<typeof routes.workspace.project.transfer.project>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const alert = useIOAlert();
  const errorAlert = useIOErrorAlert();
  const navigate = useNavigate();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  useRequirePermissionRedirect(checks.project.viewSettings(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  usePageTitle(`Project Transfer - ${project.name} - ${workspace.name}`);

  const [value, setValue] = useState<PartialProjectTransferRequest>({
    srcProject: project.id,
    transferMembers: false,
    leaveBreadcrumb: true,
    desWorkspace: null,
  });

  const messages = useMemo(() => projectTransferRequestRule(value), [value]);
  const errors = useMemo(
    () => splitMessages(messages, projectTransferRequestErrorKeys),
    [messages]
  );

  const allWorkspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const workspaceOptions = useMemo(
    () =>
      allWorkspaces
        .filter(other => other.id !== workspace.id)
        .map(({ name, id }) => ({ label: name, value: id })),
    [allWorkspaces, workspace.id]
  );

  const [submitting, setSubmitting] = useState(false);
  const submittable = useMemo(
    () =>
      isProjectTransferRequestV2(value) && !submitting && messages.length === 0,
    [messages.length, submitting, value]
  );

  const onSave = useCallback(() => {
    if (isProjectTransferRequestV2(value)) {
      setSubmitting(true);

      const desWorkspace = workspaceOptions.find(
        option => option.value === value.desWorkspace
      );

      queries.transfer.v2
        .project(queryClient, apiParams, value)
        .flatMap(result =>
          result.projects.failed.length > 0
            ? alert({
                title: "Transfer Failed",
                message: "The project could not be transferred.",
              })
            : IO.wrap(() =>
                navigate(routes.workspace.home.url([workspace.alias]))
              ).andThen(
                alert({
                  title: "Transfer Complete",
                  message: `The project was successfully transferred to the ${desWorkspace?.label} workspace.`,
                })
              )
        )
        .tapError(errorAlert)
        .cleanup(() => setSubmitting(false))
        .unsafeRun();
    } else {
      alert({
        title: "Oops!",
        message: (
          <>
            <chakra.p>The request data wasn&apos;t complete:</chakra.p>
            <JsonView overflow="auto" value={value} />
          </>
        ),
      }).unsafeRun();
    }
  }, [
    alert,
    apiParams,
    errorAlert,
    navigate,
    queryClient,
    value,
    workspace.alias,
    workspaceOptions,
  ]);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="transfer-project"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="transfer-project"
      />
      <Container>
        <Spaced spacing="4">
          <chakra.p fontSize="sm">
            Use this tool to transfer this project to another workspace. All
            teams, surveys, and attachments will move with the project.
          </chakra.p>
          <chakra.p fontSize="sm">
            You can optionally transfer project members, granting them
            additional access to the new workspace/project (they will retain
            their existing access to this workspace as well). You can also
            optionally leave a “breadcrumb” in the current workspace - a card on
            the workspace homepage directing members to the project’s new home.
          </chakra.p>
          <MessageFormControl
            label="Destination Workspace"
            messages={errors.desWorkspace}
          >
            <Select.Searchable
              value={value.desWorkspace}
              options={workspaceOptions}
              onChange={desWorkspace => setValue({ ...value, desWorkspace })}
              minMatchCharLength={0}
              debounce={500}
            />
          </MessageFormControl>
          <FormControl>
            <FormLabel text="Transfer Options" />
            <Checkbox
              checkboxLabel="Transfer members"
              value={value.transferMembers}
              onChange={transferMembers =>
                setValue({ ...value, transferMembers })
              }
            />
            <Checkbox
              checkboxLabel="Leave breadcrumb"
              value={value.leaveBreadcrumb}
              onChange={leaveBreadcrumb =>
                setValue({ ...value, leaveBreadcrumb })
              }
            />
          </FormControl>
          <FormControl>
            <FormLabel text="API Request Body" />
            <JsonView value={value} copyToClipboard={true} maxH="50vh" />
          </FormControl>
          <Button
            colorScheme="blue"
            onClick={onSave}
            isLoading={submitting}
            loadingText="Transferring"
            isDisabled={!submittable}
          >
            Transfer!
          </Button>
        </Spaced>
      </Container>
    </>
  );
}
