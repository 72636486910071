import { UrlQuery, formatUrl } from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import { isArray } from "@cartographerio/guard";
import { ReactElement } from "react";
import {
  RouteObject as ReactRouterObject,
  Params as ReactRouterParams,
} from "react-router-dom";

import {
  AnyPathPart,
  AnyQueryParams,
  BaseQueryUpdate,
  QueryOptions,
  REST_PARAM_KEY,
} from "./base";
import {
  DeriveIncomingQueryProps,
  DeriveOutgoingQueryProps,
  DerivePathParams,
  DerivePathProps,
  DeriveProps,
  DeriveQueryUpdate,
} from "./derive";
import { RouteAdapter as ReactRouteAdapter } from "./RouteAdapter";

// eslint-disable-next-line @typescript-eslint/ban-types
export class Route<P extends AnyPathPart[], Q extends AnyQueryParams = {}> {
  private readonly _path: P;
  private readonly _query: Q;
  private readonly _queryOptions: QueryOptions;

  get queryOptions(): QueryOptions {
    return this._queryOptions;
  }

  constructor(path: P, query: Q, queryOptions: QueryOptions = {}) {
    this._path = path;
    this._query = query;
    this._queryOptions = queryOptions;
  }

  /** Extend this route with additional path parts. */
  extendPath = <P2 extends AnyPathPart[]>(
    ...parts: P2
  ): Route<[...P, ...P2], Q> =>
    new Route([...this._path, ...parts], this._query, this._queryOptions);

  withQuery = <Q2 extends AnyQueryParams>(
    query: Q2,
    queryOptions: QueryOptions = {}
  ): Route<P, Q & Q2> =>
    new Route(
      this._path,
      { ...this._query, ...query },
      { ...this._queryOptions, ...queryOptions }
    );

  pathPropsToParams = (props: DerivePathProps<P>): DerivePathParams<P> => {
    const params: unknown[] = [];

    this._path.forEach(part => {
      if (typeof part !== "string") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params.push((props as any)[part.key]);
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return params as any;
  };

  url = (
    pathParamsOrProps: DerivePathParams<P> | DerivePathProps<P>,
    queryParams?: DeriveIncomingQueryProps<Q>,
    fragment?: string
  ): string => {
    const pathParams = isArray(pathParamsOrProps)
      ? pathParamsOrProps
      : this.pathPropsToParams(pathParamsOrProps);

    let path: string = "";
    let i = 0;
    let j = 0;

    while (i < this._path.length) {
      const part = this._path[i];

      if (typeof part === "string") {
        path = path + "/" + encodeURIComponent(part);
        i++;
      } else {
        path =
          path +
          "/" +
          (part.key === REST_PARAM_KEY
            ? encodeURI(part.encode(pathParams.slice(j).join("/")))
            : encodeURIComponent(part.encode(pathParams[j])));
        i++;
        j++;
      }
    }

    if (path.length === 0) {
      path = "/";
    }

    const query: UrlQuery = {};
    for (const key in this._query) {
      const param = this._query[key];
      const decoded = queryParams?.[key];
      const encoded = decoded == null ? undefined : param.encode(decoded);
      if (encoded != null) query[key] = encoded;
    }

    return formatUrl("", { path, query, fragment });
  };

  isParent = (
    pathParamsOrProps: DerivePathParams<P> | DerivePathProps<P>,
    longerUrl: string = window.location.pathname
  ) => {
    const longerItems = longerUrl.split("/");
    return this.url(pathParamsOrProps)
      .split("/")
      .every((item, i) => item === longerItems[i]);
  };

  isEqual = (
    pathParamsOrProps: DerivePathParams<P> | DerivePathProps<P>,
    otherUrl: string = window.location.pathname
  ) => otherUrl === this.url(pathParamsOrProps);

  /** Return a React Router compatible path pattern. */
  reactRouterPattern = (): string =>
    "/" +
    this._path
      .map(part =>
        typeof part === "string"
          ? part
          : part.key === REST_PARAM_KEY
          ? "*"
          : `:${part.key}`
      )
      .join("/");

  /** Extract a set of typed props from the supplied React Router params. */
  pathProps = (path: ReactRouterParams): DerivePathProps<P> | undefined => {
    const ans: Record<PropertyKey, unknown> = {};

    this._path.forEach(part => {
      if (typeof part === "string") {
        // Do nothing
      } else {
        const param = path[part.key === REST_PARAM_KEY ? "*" : part.key];
        if (param == null) {
          throw new Error(`Missing parameter: ${part.key}`);
        } else {
          ans[part.key] = part.decode(param);
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ans as any;
  };

  /** Extract a set of typed props from the supplied React Router params. */
  queryProps = (
    query: URLSearchParams
  ): DeriveOutgoingQueryProps<Q> | undefined => {
    const ans: Record<PropertyKey, unknown> = {};

    for (const key in this._query) {
      const mapping = this._query[key];
      ans[key] = mapping.decode(query.get(key) ?? undefined);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ans as any;
  };

  defaultQueryProps = (): DeriveOutgoingQueryProps<Q> | undefined => {
    return this.queryProps(new URLSearchParams());
  };

  queryUpdate = (update: BaseQueryUpdate): DeriveQueryUpdate<Q> => {
    return (query, navigateOptions = { replace: true }): void => {
      const ans: Record<PropertyKey, unknown> = {};

      for (const key in this._query) {
        const param = this._query[key];
        Option.wrap(param.encode(query[key])).forEach(str => (ans[key] = str));
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      update(ans as any, navigateOptions);
    };
  };

  to = (
    Component: (props: DeriveProps<P, Q>) => ReactElement
  ): ReactRouterObject => {
    return {
      path: this.reactRouterPattern(),
      element: <ReactRouteAdapter builder={this} Component={Component} />,
    };
  };
}

export type RoutePathParams<R> = R extends Route<infer P>
  ? DerivePathParams<P>
  : never;

export type RouteProps<R> = R extends Route<infer P, infer Q>
  ? DeriveProps<P, Q>
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouteQueryProps<R> = R extends Route<any, infer Q>
  ? DeriveOutgoingQueryProps<Q>
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RouteQueryUpdate<R> = R extends Route<any, infer Q>
  ? DeriveQueryUpdate<Q>
  : never;
