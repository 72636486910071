import { useEffect } from "react";

export function usePageTitle(title: string): void {
  useEffect(() => {
    document.title = `${title} - Cartographer`;

    return () => {
      document.title = `Cartographer`;
    };
  }, [title]);
}
