import { NamedEmail } from "@cartographerio/types";

import { ListEnabledCheckbox } from "./ListEnabledCheckbox";
import { blankNamedEmail } from "./NamedEmailListEditor";

export interface NamedEmailListEnabledCheckboxProps {
  checkboxLabel: string;
  value: NamedEmail[];
  onChange: (value: NamedEmail[]) => void;
  disabled: boolean;
}

export function NamedEmailListEnabledCheckbox(
  props: NamedEmailListEnabledCheckboxProps
) {
  return <ListEnabledCheckbox {...props} defaultItem={blankNamedEmail} />;
}
