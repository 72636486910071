import { envvar } from "@cartographerio/util";
import * as Sentry from "@sentry/react";

export const REACT_APP_NAME = envvar("REACT_APP_NAME");
export const REACT_APP_VERSION = envvar("REACT_APP_VERSION");
const REACT_APP_SENTRY_DSN = envvar("REACT_APP_SENTRY_DSN");

export function initSentry(): void {
  Sentry.init({
    release: [REACT_APP_NAME, REACT_APP_VERSION].join("@"),
    dsn:
      process.env.NODE_ENV === "production" ? REACT_APP_SENTRY_DSN : undefined,
    normalizeDepth: 50,
  });
}
