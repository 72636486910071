import { Message } from "@cartographerio/types";
import { Box, Flex, HStack, IconButton } from "@chakra-ui/react";
import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from "react";
import { IoCloseSharp } from "react-icons/io5";

import { indexedErrors } from "../../../schema/rule/errors";
import Button from "../Button";
import Card from "../Card";
import CardBody from "../CardBody";
import Spaced from "../Spaced";

export interface ItemEditorProps<A> {
  value: A;
  onChange: (value: A) => void;
  messages: Message[];
  disabled: boolean;
}

export interface ListEditorProps<A> {
  value: A[];
  onChange: (value: A[]) => void;
  messages: Message[];
  disabled: boolean;
  minLength?: number;
  maxLength?: number;
  defaultItem: A;
  ItemEditor: FunctionComponent<ItemEditorProps<A>>;
  addButtonLabel?: string;
  emptyText?: string;
}

export function ListEditor<A>(props: ListEditorProps<A>) {
  const {
    value,
    onChange,
    messages,
    disabled,
    minLength = 0,
    maxLength,
    defaultItem,
    ItemEditor,
    addButtonLabel = "Add",
    emptyText = "This list is empty. Click 'Add' to add an item.",
  } = props;

  const errors = useMemo(
    () => indexedErrors(messages, value.length),
    [messages, value.length]
  );

  const handleChange = useCallback(
    (item: A, index: number) => {
      const newValue = [...value];
      newValue.splice(index, 1, item);
      onChange(newValue);
    },
    [onChange, value]
  );

  const handleRemove = useCallback(
    (i: number) => {
      const newValue = [...value];
      newValue.splice(i, 1);
      onChange(newValue);
    },
    [onChange, value]
  );

  const handleAdd = useCallback(() => {
    onChange([...value, defaultItem]);
  }, [defaultItem, onChange, value]);

  return (
    <Spaced>
      {value.length === 0 && !disabled && <EmptyList>{emptyText}</EmptyList>}
      {value.map((namedEmail, index) => (
        <HStack
          key={index}
          w="100%"
          justifyContent="stretch"
          alignItems="start"
        >
          <Flex grow={1} shrink={1}>
            <ItemEditor
              value={namedEmail}
              messages={errors[index]}
              onChange={named => handleChange(named, index)}
              disabled={disabled}
            />
          </Flex>
          {index < minLength ? (
            // Mimic the width of an IconButton
            <Box width="1.25rem" px="5" />
          ) : (
            <IconButton
              icon={<IoCloseSharp size="1.25rem" />}
              aria-label="Remove"
              onClick={() => handleRemove(index)}
              isDisabled={disabled || index < minLength}
            />
          )}
        </HStack>
      ))}
      {!disabled && (
        <Button
          variant="outline"
          label={addButtonLabel}
          onClick={handleAdd}
          disabled={maxLength != null && value.length >= maxLength}
        />
      )}
    </Spaced>
  );
}

function EmptyList({ children }: PropsWithChildren) {
  return (
    <Card bg="gray.100" color="gray.500" fontSize="sm">
      <CardBody>{children}</CardBody>
    </Card>
  );
}
