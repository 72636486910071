import { checks } from "@cartographerio/permission";
import { unsafeUserId } from "@cartographerio/types";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../queries";
import { RouteProps } from "../../routes";
import { useIOErrorAlert } from "../components/Alert";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import PageContainer from "../components/PageContainer";
import RequirePermission from "../components/RequirePermission";
import {
  changeIdentityAction,
  useApiParams,
  useAuthContext,
} from "../contexts/auth";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { usePageTitle } from "../hooks/usePageTitle";
import useRequirePermissionRedirect from "../hooks/useRequirePermissionRedirect";
import { routes } from "../routes";

export default function ChangeIdentityPage(
  props: RouteProps<typeof routes.identity.change>
): ReactElement {
  const {
    path: { userId },
    query: { go = "/" },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  useRequirePermissionRedirect(checks.auth.changeIdentity(), () =>
    routes.home.url([])
  );

  usePageTitle("Change Identity");

  const { dispatch: authDispatch } = useAuthContext();

  useEffectOnce(() => {
    if (userId != null) {
      queries.auth.v2
        .changeIdentity(queryClient, apiParams, unsafeUserId(userId))
        .tap(res => {
          if (res.type === "SigninSuccess") {
            authDispatch(changeIdentityAction(res.credentials.token));
            navigate(go, { replace: true });
          } else {
            errorAlert(res);
          }
        })
        .unsafeRun();
    }
  });

  return (
    <RequirePermission check={checks.auth.changeIdentity()}>
      <PageContainer>
        <LoadingPlaceholder label="Loading user" />
      </PageContainer>
    </RequirePermission>
  );
}
