import { endpoints } from "@cartographerio/client";
import { commonLocations } from "@cartographerio/geometry";
import {
  ApiParams,
  ProjectId,
  ProjectMapSettings,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type ProjectMapSettingsKey = [
  "project",
  "map-settings",
  "v1",
  ProjectId
];

export const defaultMapSettings: ProjectMapSettings = {
  defaultBounds: commonLocations.greatBritain.bounds,
  fallbackCenter: commonLocations.greatBritain.center,
  fallbackZoom: commonLocations.greatBritain.zoom,
};

export function readOrDefault(
  apiParams: ApiParams,
  projectId: ProjectId
): UseQueryOpts<ProjectMapSettings, ProjectMapSettingsKey> {
  return {
    queryKey: ["project", "map-settings", "v1", projectId],
    queryFn: () =>
      endpoints.project.mapSettings.v1
        .readOption(apiParams, projectId)
        .map(opt => opt.getOrElse(() => defaultMapSettings))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  projectId: ProjectId,
  settings: ProjectMapSettings
) {
  return endpoints.project.mapSettings.v1
    .save(apiParams, projectId, settings)
    .tap(_ => queryClient.invalidateQueries(["project", "map-settings"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  projectId: ProjectId
) {
  return endpoints.project.mapSettings.v1
    .remove(apiParams, projectId)
    .tap(_ => queryClient.invalidateQueries(["project", "map-settings"]));
}
