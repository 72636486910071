import { errorMessage } from "@cartographerio/atlas-core";
import {
  InvitationCodeSignupRequest,
  InvitationSignupRequest,
} from "@cartographerio/types";

import { rule } from "./rule";

export const { rule: signupRule, keys: signupErrorKeys } = rule
  .build<InvitationCodeSignupRequest>()
  .field(
    "firstName",
    rule.nonEmpty([errorMessage("You must enter your first name")])
  )
  .field(
    "lastName",
    rule.nonEmpty([errorMessage("You must enter your last name")])
  )
  .field("email", rule.email([errorMessage("Must be an email")]))
  .field(
    "screenName",
    rule.nonEmpty([errorMessage("You must enter a screen name")])
  )
  .field(
    "acceptTerms",
    rule.eq<boolean>(true, [
      errorMessage("You must accept the terms to signup to Cartographer."),
    ])
  )
  .finish();

export const { rule: signupWithPassRule, keys: signupWithPassErrorKeys } = rule
  .build<InvitationSignupRequest>()
  .field(
    "firstName",
    rule.nonEmpty([errorMessage("You must enter your first name")])
  )
  .field(
    "lastName",
    rule.nonEmpty([errorMessage("You must enter your last name")])
  )
  .field("email", rule.email([errorMessage("Must be an email")]))
  .field(
    "screenName",
    rule.nonEmpty([errorMessage("You must enter a screen name")])
  )
  .field(
    "password",
    rule.password([
      errorMessage(
        "Passwords must be at least six characters and contain upper and lower case letters, numbers, and punctuation."
      ),
    ])
  )
  .field(
    "acceptTerms",
    rule.eq<boolean>(true, [
      errorMessage("You must accept the terms to signup to Cartographer."),
    ])
  )
  .finish();
