import { Option } from "@cartographerio/fp";
import {
  ChangeEvent,
  FocusEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";

type HTMLTextField = HTMLTextAreaElement | HTMLInputElement;

interface UseDebouncedTextValueParams<A> {
  value?: A | undefined;
  defaultValue?: A | undefined;
  format: (a: A) => string;
  validate: (s: string) => Option<A>;
  onChange?: (a: A) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  debounce?: number;
}

interface UseDebouncedTextValueResult<E extends HTMLTextField> {
  textValue: string;
  localError: boolean;
  handleBlur: (evt: FocusEvent<E>) => void;
  handleFocus: (evt: FocusEvent<E>) => void;
  handleTextChange: (evt: ChangeEvent<E>) => void;
  handleTextValueChange: (value: string) => void;
}

function formatValueProps<A>(
  defaultValue: A | undefined,
  value: A | undefined,
  format: (a: A) => string
): string {
  return value !== undefined
    ? format(value)
    : defaultValue !== undefined
    ? format(defaultValue)
    : "";
}

export default function useDebouncedTextValue<A, E extends HTMLTextField>(
  params: UseDebouncedTextValueParams<A>
): UseDebouncedTextValueResult<E> {
  const {
    value,
    defaultValue,
    format,
    validate,
    onChange,
    onFocus,
    onBlur,
    debounce,
  } = params;

  const [focused, setFocused] = useState(false);
  const [textValue, setTextValue] = useState(
    formatValueProps(defaultValue, value, format)
  );
  const [localError, setLocalError] = useState(false);

  // If the `value` prop changes, update `textValue` to reflect `value`:
  useEffect(() => {
    if (value !== undefined) {
      setTextValue(formatValueProps(undefined, value, format));
    }
  }, [value, format]);

  // When blurred, if the `defaultValue` prop changes, update `textValue` to reflect `value`:
  useEffect(() => {
    if (!focused) {
      setTextValue(formatValueProps(defaultValue, value, format));
    }
  }, [defaultValue, focused, format, value]);

  const syncHandleChange = useCallback(
    (value: string) => {
      validate(value).fold(
        () => {
          setLocalError(true);
        },
        a => {
          setLocalError(false);
          onChange?.(a);
        }
      );
    },
    [onChange, validate]
  );

  const handleChange = useDebouncedCallback(syncHandleChange, debounce);

  const handleBlur = useCallback(
    (_evt: ChangeEvent<E>) => {
      setFocused(false);
      handleChange.flush();
      onBlur?.();
    },
    [handleChange, onBlur]
  );

  const handleFocus = useCallback(
    (_evt: ChangeEvent<E>) => {
      setFocused(true);
      onFocus?.();
    },
    [onFocus]
  );

  const handleTextValueChange = useCallback(
    (value: string) => {
      setTextValue(value);
      handleChange.callback(value);
    },
    [handleChange]
  );

  const handleTextChange = useCallback(
    (evt: ChangeEvent<E>) => {
      handleTextValueChange(evt.target.value);
    },
    [handleTextValueChange]
  );

  return {
    textValue,
    localError,
    handleBlur,
    handleFocus,
    handleTextChange,
    handleTextValueChange,
  };
}
