import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface PanelRowProps extends Omit<FlexProps, "right" | "left"> {
  left?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
}
export default function PanelRow(props: PanelRowProps): ReactElement {
  const {
    direction = ["column", "column", "row"],
    align = ["stretch", "stretch", "center"],
    left,
    right,
    children,
    ...rest
  } = props;
  return (
    // Grid needed for justify-self
    <Flex direction={direction} align={align} {...rest}>
      {left != null && (
        <Flex h="100%" alignItems="center" justifySelf="start" flex={0}>
          {left}
        </Flex>
      )}
      <Flex h="100%" alignItems="center" justifySelf="center" flex={1}>
        {children}
      </Flex>
      {right != null && (
        <Flex h="100%" alignItems="center" justifySelf="end" flex={0}>
          {right}
        </Flex>
      )}
    </Flex>
  );
}
