import { SelectValue } from "@cartographerio/atlas-form";
import { useCallback, useMemo } from "react";

interface UseSubsetMethods<T> {
  has: (item: T) => boolean;
  toggle: (item: T, include?: boolean) => void;
}

export function useControlledSubset<T extends SelectValue>(
  value: T[],
  onChange: (value: T[]) => void
): UseSubsetMethods<T>;
export function useControlledSubset<T>(
  value: T[],
  onChange: (value: T[]) => void,
  _toId: (item: T) => SelectValue
): UseSubsetMethods<T>;
export function useControlledSubset<T>(
  value: T[],
  onChange: (value: T[]) => void,
  _toId?: (item: T) => SelectValue
): UseSubsetMethods<T> {
  const toId = useMemo<(item: T) => SelectValue>(
    () => _toId ?? (item => item as SelectValue),
    [_toId]
  );
  const valueSet = useMemo(() => new Set(value.map(toId)), [toId, value]);

  const has = useCallback(
    (item: T) => valueSet.has(toId(item)),
    [toId, valueSet]
  );

  const toggle = useCallback(
    (item: T, include?: boolean) =>
      include ?? !has(item)
        ? onChange([...value, item])
        : onChange(value.filter(v => toId(v) !== toId(item))),
    [has, onChange, toId, value]
  );

  return { has, toggle };
}
