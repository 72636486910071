import { HStack, Heading } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaChevronLeft } from "react-icons/fa";

import InspectorButton from "./InspectorButton";

export interface InspectorTitleProps {
  title: string;
  backLabel?: string;
  onBackClick?: () => void;
}

export default function InspectorTitle(
  props: InspectorTitleProps
): ReactElement {
  const { title, backLabel, onBackClick } = props;

  const backButton =
    onBackClick == null ? null : (
      <InspectorButton
        as={FaChevronLeft}
        ariaLabel={backLabel ?? "Back"}
        onClick={evt => {
          evt.stopPropagation();
          onBackClick();
        }}
      />
    );

  return (
    <HStack px="2" pt="4">
      <HStack w="100%">
        {backButton}
        <Heading
          size="sm"
          flexGrow={1}
          flexShrink={1}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          title={title}
        >
          {title}
        </Heading>
      </HStack>
    </HStack>
  );
}
