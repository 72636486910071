import {
  GlobalMemberSearchOptions,
  PartialParams,
  ProjectMemberSearchOptions,
  TeamMemberSearchOptions,
  WorkspaceMemberSearchOptions,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  MemberV2,
  ProjectRef,
  SearchResults,
  TeamRef,
  WorkspaceRef,
} from "@cartographerio/types";

import { UseQueryOpts } from "../base";

export type MemberKey =
  | ["person", "member"]
  | [
      "person",
      "member",
      "v1",
      "globalSearch",
      PartialParams<GlobalMemberSearchOptions>
    ]
  | [
      "person",
      "member",
      "v1",
      "workspaceSearch",
      WorkspaceRef,
      PartialParams<WorkspaceMemberSearchOptions>
    ]
  | [
      "person",
      "member",
      "v1",
      "projectSearch",
      ProjectRef,
      WorkspaceRef | null,
      PartialParams<ProjectMemberSearchOptions>
    ]
  | [
      "person",
      "member",
      "v1",
      "teamSearch",
      TeamRef,
      WorkspaceRef | null,
      PartialParams<TeamMemberSearchOptions>
    ];

export function globalSearch(
  apiParams: ApiParams,
  options: PartialParams<GlobalMemberSearchOptions>
): UseQueryOpts<SearchResults<MemberV2>, MemberKey> {
  return {
    queryKey: ["person", "member", "v1", "globalSearch", options],
    queryFn: () =>
      endpoints.member.v1.globalSearch(apiParams, options).unsafeRun(),
  };
}

export function workspaceSearch(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  options: PartialParams<WorkspaceMemberSearchOptions>
): UseQueryOpts<SearchResults<MemberV2>, MemberKey> {
  return {
    queryKey: [
      "person",
      "member",
      "v1",
      "workspaceSearch",
      workspaceRef,
      options,
    ],
    queryFn: () =>
      endpoints.member.v1
        .workspaceSearch(apiParams, workspaceRef, options)
        .unsafeRun(),
  };
}

export function projectSearch(
  apiParams: ApiParams,
  projectRef: ProjectRef,
  workspaceRef: WorkspaceRef | null,
  options: PartialParams<ProjectMemberSearchOptions>
): UseQueryOpts<SearchResults<MemberV2>, MemberKey> {
  return {
    queryKey: [
      "person",
      "member",
      "v1",
      "projectSearch",
      projectRef,
      workspaceRef,
      options,
    ],
    queryFn: () =>
      endpoints.member.v1
        .projectSearch(apiParams, projectRef, workspaceRef, options)
        .unsafeRun(),
  };
}

export function teamSearch(
  apiParams: ApiParams,
  teamRef: TeamRef,
  workspaceRef: WorkspaceRef | null,
  options: PartialParams<TeamMemberSearchOptions>
): UseQueryOpts<SearchResults<MemberV2>, MemberKey> {
  return {
    queryKey: [
      "person",
      "member",
      "v1",
      "teamSearch",
      teamRef,
      workspaceRef,
      options,
    ],
    queryFn: () =>
      endpoints.member.v1
        .teamSearch(apiParams, teamRef, workspaceRef, options)
        .unsafeRun(),
  };
}
