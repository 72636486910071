import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { ForwardedRef, ReactElement, ReactNode, forwardRef } from "react";

export interface PlaceholderProps extends FlexProps {
  text?: string;
  children?: ReactNode;
}

const Placeholder = forwardRef(function Placeholder(
  props: PlaceholderProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const { text, py = "8", children, ...rest } = props;

  return (
    <Flex
      ref={ref}
      rounded="md"
      w="100%"
      h="100%"
      bg="gray.100"
      color="gray.400"
      fontSize="sm"
      textAlign="center"
      alignItems="center"
      py={py}
      {...rest}
    >
      {children != null ? children : text && <Text w="100%">{text}</Text>}
    </Flex>
  );
});

export default Placeholder;
