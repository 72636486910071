import { check } from "@cartographerio/permission";
import {
  InvitationV2,
  nowTimestamp,
  randomInvitationId,
  unsafeEmail,
  userRef,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { invitationRule } from "../../../schema/invitation";
import { useIOErrorAlert } from "../../components/Alert";
import InvitationEditor from "../../components/InvitationEditor";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import PageTopBar from "../../components/PageTopBar";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermissionRedirect from "../../hooks/useRequirePermissionRedirect";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { useWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";

function defaultInvitation(): InvitationV2 {
  const now = nowTimestamp();
  return {
    firstName: "",
    lastName: "",
    email: unsafeEmail(""),
    roles: [],
    qualificationRoles: [],
    created: now,
    createdBy: userRef(),
    updated: now,
    id: randomInvitationId(),
  };
}

export default function AdminInvitationCreatePage(
  _props: RouteProps<typeof routes.admin.invitation.create>
): ReactElement {
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  useRequirePermissionRedirect(check.superuser, () =>
    routes.admin.home.url([])
  );

  usePageTitle("New Invitation - Admin");

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );
  const projects = useSuspenseSearchResults(queries.project.v2.all(apiParams));
  const teams = useSuspenseSearchResults(queries.team.v2.all(apiParams));
  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const workspaceGraph = useWorkspaceGraph(workspaces, projects, teams);

  const [invitation, setInvitation] = useState(defaultInvitation());
  const messages = useMemo(() => invitationRule(invitation), [invitation]);

  const handleSave = useCallback(() => {
    if (messages.length === 0) {
      queries.invitation.v3
        .create(queryClient, apiParams, invitation)
        .tap(() =>
          toast({
            title: "Invitation sent",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tap(() => navigate(routes.admin.invitation.list.url([])))
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    apiParams,
    errorAlert,
    invitation,
    messages.length,
    navigate,
    queryClient,
    toast,
  ]);

  return (
    <>
      <PageTopBar admin={true} basePage="invitation-single" invitation="new" />
      <PageContainer width="narrow">
        <Spaced>
          <PageHeader title="New Invitation" />
          <InvitationEditor
            value={invitation}
            onChange={setInvitation}
            messages={messages}
            workspaceGraph={workspaceGraph}
            qualifications={qualifications}
            roleEditorSingleWorkspace={false}
          />
          <SaveButton
            label="Send Invitation"
            onClick={handleSave}
            messages={messages}
          />
        </Spaced>
      </PageContainer>
    </>
  );
}
