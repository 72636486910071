import { IO } from "@cartographerio/io";
import { check, checks } from "@cartographerio/permission";
import {
  InvitationCodeV2,
  canCancelInvitationCode,
} from "@cartographerio/types";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import {
  ButtonGroup,
  Flex,
  HStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { ReactElement, useCallback, useMemo, useState } from "react";
import {
  IoCloseSharp,
  IoShareOutline,
  IoStopCircleOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { invitationCodeRule } from "../../../schema/invitationCode";
import {
  useAlert,
  useIOConfirm,
  useIOErrorAlert,
} from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import Button from "../../components/Button";
import InvitationCodeEditor from "../../components/InvitationCodeEditor";
import InvitationCodeShareModal from "../../components/InvitationCodeShareModal";
import { InvitationCodeStatusBadge } from "../../components/InvitationStatusBadge";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import RequirePermission from "../../components/RequirePermission";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import WithPermission from "../../components/WithPermission";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";

type BaseInvitationCodeViewPageProps = {
  defaultCode: InvitationCodeV2;
  workspaceGraph: WorkspaceGraphV2;
  onDeleteGo?: string;
} & ({ asTitle: true } | { asTitle: false; backRedirect: string });

export default function BaseInvitationCodeViewPage(
  props: BaseInvitationCodeViewPageProps
): ReactElement {
  const {
    asTitle,
    defaultCode: _defaultCode,
    workspaceGraph,
    onDeleteGo,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const alert = useAlert();
  const errorAlert = useIOErrorAlert();

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const [defaultCode, setDefaultCode] = useState(_defaultCode);
  const [code, setCode] = useState(defaultCode);

  const messages = useMemo(() => invitationCodeRule(code), [code]);

  const onSave = useCallback(
    () =>
      queries.invitation.code.v3
        .save(queryClient, apiParams, defaultCode.id, code)
        .flatMap(IO.fromResult)
        .tap(setCode)
        .tap(setDefaultCode)
        .tap(() =>
          toast({
            title: "Changes saved",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, defaultCode.id, code, queryClient, toast]
  );

  const {
    isOpen: isShareModalOpen,
    onClose: handleCloseShareModal,
    onOpen: handleOpenShareModal,
  } = useDisclosure();

  const handleShareClick = useCallback(() => {
    if (isEqual(code, defaultCode)) {
      handleOpenShareModal();
    } else {
      alert({
        title: "Unsaved Changes",
        message:
          "Please save your changes before sharing this invitation code.",
      });
    }
  }, [alert, code, defaultCode, handleOpenShareModal]);

  return (
    <PageContainer width="narrow">
      <Spaced>
        {asTitle ? (
          <PageHeader title={defaultCode.name} />
        ) : (
          <BackButtonHeading to={props.backRedirect}>
            {defaultCode.name}
          </BackButtonHeading>
        )}
        <Flex justify="flex-end">
          <ButtonGroup size="sm">
            <InvitationCodeStatusBadge code={defaultCode} />
            <InvitationCodeButtons
              invitation={defaultCode}
              onShare={handleShareClick}
            />
          </ButtonGroup>
        </Flex>
        <InvitationCodeShareModal
          code={defaultCode}
          isOpen={isShareModalOpen}
          onClose={handleCloseShareModal}
        />
        <WithPermission check={checks.invitationCode.update(defaultCode)}>
          {canEdit => (
            <>
              <InvitationCodeEditor
                value={code}
                messages={messages}
                workspaceGraph={workspaceGraph}
                qualifications={qualifications}
                disabled={!canEdit}
                onChange={update => setCode({ ...code, ...update })}
              />
              <HStack justifyContent="space-between" gap="2">
                {canEdit && (
                  <SaveButton
                    label="Save"
                    onClick={onSave}
                    messages={messages}
                  />
                )}
                {asTitle && (
                  <ButtonGroup>
                    <InvitationCodeButtons
                      invitation={defaultCode}
                      onDeleteGo={onDeleteGo}
                    />
                  </ButtonGroup>
                )}
              </HStack>
            </>
          )}
        </WithPermission>
      </Spaced>
    </PageContainer>
  );
}

interface InvitationCodeButtonProps {
  invitation: InvitationCodeV2;
  onShare?: () => void;
  onDeleteGo?: string;
}

function InvitationCodeButtons(props: InvitationCodeButtonProps): ReactElement {
  const { invitation, onShare, onDeleteGo } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();
  const confirm = useIOConfirm();
  const navigate = useNavigate();

  const handleCancel = useCallback(
    () =>
      queries.invitation.code.v3
        .cancel(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation cancelled",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, invitation.id, queryClient, toast]
  );

  const handleDelete = useCallback(
    () =>
      confirm({
        title: "Delete Invitation Code",
        message: "Are you sure?",
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.invitation.code.v3
                .remove(queryClient, apiParams, invitation.id)
                .tap(() =>
                  toast({
                    title: "Invitation Deleted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tap(() => (onDeleteGo != null ? navigate(onDeleteGo) : null))
            : IO.noop()
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [
      apiParams,
      confirm,
      errorAlert,
      invitation.id,
      navigate,
      onDeleteGo,
      queryClient,
      toast,
    ]
  );

  return (
    <>
      {onShare != null && (
        <Button
          label="Share"
          leftIcon={<IoShareOutline size="1.1rem" />}
          variant="outline"
          onClick={onShare}
        />
      )}
      {canCancelInvitationCode(invitation) && (
        <Button
          label="Cancel"
          leftIcon={<IoStopCircleOutline size="1.25rem" />}
          variant="outline"
          onClick={handleCancel}
        />
      )}
      <RequirePermission check={check.superuser}>
        <Button
          label="Delete"
          leftIcon={<IoCloseSharp size="1.25rem" />}
          variant="outline"
          onClick={handleDelete}
        />
      </RequirePermission>
    </>
  );
}
