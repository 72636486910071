import { MemberSortKey } from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import {
  CurrentMemberV2,
  InvitationCodeSignupApprovalRequest,
  MemberV2,
  PendingMemberV2,
  ProjectId,
  ProjectRoleNameEnum,
  ProjectV2,
  Qualification,
  QualificationId,
  QualificationRoleName,
  QualificationRoleNameEnum,
  RoleV2,
  TeamId,
  TeamRoleNameEnum,
  TeamV2,
  UserId,
  WorkspaceId,
  WorkspaceRoleNameEnum,
  WorkspaceV2,
  findProjectRoleName,
  findTeamRoleName,
  findWorkspaceRoleName,
  internalError,
  projectRoleNameV2,
  projectRoleV2,
  qualificationRoleName,
  roleProjectIdV2,
  roleQualificationId,
  roleTeamIdV2,
  roleWorkspaceIdV2,
  teamRoleNameV2,
  teamRoleV2,
  workspaceRoleNameV2,
  workspaceRoleV2,
} from "@cartographerio/types";
import { checkExhausted, raise } from "@cartographerio/util";
import { Flex, IconButton, VStack, chakra } from "@chakra-ui/react";
import { FaMask } from "react-icons/fa";
import {
  IoCheckmarkSharp,
  IoCloseSharp,
  IoStopCircleOutline,
} from "react-icons/io5";

import Link from "../Link";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import SecondaryLabel from "../SecondaryLabel";
import Select from "../Select";
import { workspaceRoleOptions } from "../UserList/role";

export type ApproveRejectCallback = (
  request: InvitationCodeSignupApprovalRequest
) => void;

export type OnMemberAction<M = MemberV2> = (member: M) => void;

export type OnMemberRoleChange = (
  member: CurrentMemberV2,
  role: RoleV2,
  include?: boolean
) => void;
export type OnMemberQualificationChange = (
  member: CurrentMemberV2,
  qualificationId: QualificationId,
  roleName: QualificationRoleName | null
) => void;

export function col(params: Column<MemberV2, MemberSortKey>) {
  return params;
}

export const nameColumn = col({
  title: "Name",
  orderBy: "name",
  width: "auto",
  render: member => {
    const screenName =
      member.type !== "InvitedMember" ? member.screenName : undefined;
    const name = `${member.firstName} ${member.lastName}`;
    return name === screenName ? (
      name
    ) : (
      <SecondaryLabel text={name} secondaryText={screenName} />
    );
  },
});

export const emailColumn = col({
  title: "Email",
  orderBy: "email",
  width: "13ch",
  render: member => (
    <Link.External to={`mailto:${member.email}`}>{member.email}</Link.External>
  ),
});

export const memberTypeColumn = col({
  title: "Type",
  orderBy: "membertype",
  width: "13ch",
  render: member => {
    switch (member.type) {
      case "CurrentMember":
        return "Current";
      case "PendingMember":
        return "Pending";
      case "InvitedMember":
        return "Invited";
      default:
        return checkExhausted(member);
    }
  },
});

interface WorkspaceRoleColumnProps {
  workspace: WorkspaceId;
  onRoleChange?: OnMemberRoleChange | null;
}

export function workspaceRoleColumn(props: WorkspaceRoleColumnProps) {
  const { workspace, onRoleChange } = props;
  return col({
    title: "Workspace Role",
    render: member => {
      const roleName = findWorkspaceRoleName(member.roles, workspace);

      switch (member.type) {
        case "CurrentMember":
          return roleName == null ? (
            <span>No role</span>
          ) : (
            <Select.Standard
              w="20ch"
              options={workspaceRoleOptions}
              value={roleName}
              onChange={roleName =>
                onRoleChange?.(
                  member,
                  workspaceRoleV2(roleName, workspace),
                  true
                )
              }
              size="sm"
            />
          );

        case "PendingMember":
          return (
            <PendingRoleChange
              from={roleName}
              to={findWorkspaceRoleName(member.invitationCodeRoles, workspace)}
              format={WorkspaceRoleNameEnum.labelOf}
            />
          );

        case "InvitedMember":
          return <span>{roleName ?? "No role"}</span>;

        default:
          return checkExhausted(member);
      }
    },
  });
}

interface ProjectRoleColumnProps {
  project: ProjectId;
  title?: string;
  onRoleChange?: OnMemberRoleChange | null;
  toggle?: boolean;
}

export function projectRoleColumn(props: ProjectRoleColumnProps) {
  const {
    project,
    title = "Project Role",
    onRoleChange,
    toggle = false,
  } = props;
  return col({
    title,
    render: member => {
      const role = Option.wrap(
        member.roles.find(role => roleProjectIdV2(role) === project)
      );

      const roleName = findProjectRoleName(member.roles, project);

      switch (member.type) {
        case "CurrentMember":
          return toggle ? (
            <Select.Nullable
              w="20ch"
              options={ProjectRoleNameEnum.entries}
              value={roleName}
              onChange={newRoleName =>
                newRoleName != null || role != null
                  ? onRoleChange?.(
                      member,
                      newRoleName != null
                        ? projectRoleV2(newRoleName, project)
                        : role.getOrThrow(() =>
                            internalError("Tried unsetting a non existent role")
                          ),
                      newRoleName != null
                    )
                  : undefined
              }
              placeholder="No Role"
              size="sm"
              disabled={onRoleChange == null}
            />
          ) : (
            <Select.Standard
              w="20ch"
              options={ProjectRoleNameEnum.entries}
              value={
                roleName ??
                raise(
                  internalError(
                    "No project role name found in project member list"
                  )
                )
              }
              onChange={roleName =>
                onRoleChange?.(member, projectRoleV2(roleName, project), true)
              }
              size="sm"
              disabled={onRoleChange == null}
            />
          );

        case "PendingMember":
          return (
            <PendingRoleChange
              from={roleName}
              to={findProjectRoleName(member.invitationCodeRoles, project)}
              format={ProjectRoleNameEnum.labelOf}
            />
          );

        case "InvitedMember":
          return <span>{roleName ?? "No role"}</span>;

        default:
          return checkExhausted(member);
      }
    },
  });
}

interface TeamRoleColumnProps {
  team: TeamId;
  title?: string;
  onRoleChange?: OnMemberRoleChange | null;
  toggle?: boolean;
}

export function teamRoleColumn(props: TeamRoleColumnProps) {
  const { team, title = "Team Role", onRoleChange, toggle = false } = props;

  return col({
    title,
    render: member => {
      const role = Option.wrap(
        member.roles.find(role => roleTeamIdV2(role) === team)
      );

      const roleName = findTeamRoleName(member.roles, team);

      switch (member.type) {
        case "CurrentMember":
          return toggle ? (
            <Select.Nullable
              w="20ch"
              options={TeamRoleNameEnum.entries}
              value={roleName}
              onChange={newRoleName =>
                newRoleName != null || role != null
                  ? onRoleChange?.(
                      member,
                      newRoleName != null
                        ? teamRoleV2(newRoleName, team)
                        : role.getOrThrow(() =>
                            internalError("Tried unsetting a non existent role")
                          ),
                      newRoleName != null
                    )
                  : undefined
              }
              placeholder="No Role"
              size="sm"
              disabled={onRoleChange == null}
            />
          ) : (
            <Select.Standard
              w="20ch"
              options={TeamRoleNameEnum.entries}
              value={
                roleName ??
                raise(
                  internalError("No team role name found in team member list")
                )
              }
              onChange={roleName =>
                onRoleChange?.(member, teamRoleV2(roleName, team), true)
              }
              size="sm"
              disabled={onRoleChange == null}
            />
          );

        case "PendingMember":
          return (
            <PendingRoleChange
              from={roleName}
              to={findTeamRoleName(member.invitationCodeRoles, team)}
              format={TeamRoleNameEnum.labelOf}
            />
          );

        case "InvitedMember":
          return <span>{roleName ?? "No role"}</span>;

        default:
          return checkExhausted(member);
      }
    },
  });
}

function PendingRoleChange<A>(props: {
  from: A | null;
  to: A | null;
  format: (roleName: A) => string;
}) {
  const { from, to, format } = props;
  return (
    <VStack align="flex-start" gap="1">
      <Flex>{to == null ? "No role" : format(to)}</Flex>
      <Flex fontSize="xs" color="gray.500">
        Currently {from == null ? "no role" : format(from)}
      </Flex>
    </VStack>
  );
}

interface QualificationColumnProps {
  qualification: Qualification;
  onQualificationChange?: OnMemberQualificationChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function qualificationRoleColumn(props: QualificationColumnProps) {
  const { qualification, onQualificationChange, permissionCheckPasses } = props;
  return col({
    title: qualification.name,
    render: member => {
      const role = Option.wrap(
        member.qualificationRoles.find(
          role => roleQualificationId(role) === qualification.id
        )
      );
      const roleName = role.nullMap(qualificationRoleName);

      return member.type !== "CurrentMember" ? (
        <chakra.span>{roleName.getOrElse(() => "-")}</chakra.span>
      ) : (
        <Select.Nullable<QualificationRoleName>
          w="16ch"
          options={QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(
              checks.qualification.grant(value, qualification.id)
            )
          )}
          value={roleName.getOrNull()}
          onChange={newRoleName =>
            onQualificationChange?.(member, qualification.id, newRoleName)
          }
          placeholder="Not granted"
          size="sm"
        />
      );
    },
  });
}

interface SelectedWorkspaceColumnsProps {
  workspaces: WorkspaceV2[];
  selectedWorkspace?: WorkspaceId | null;
  onRoleChange: OnMemberRoleChange | null;
}

export function selectedWorkspaceColumns(props: SelectedWorkspaceColumnsProps) {
  const { workspaces, selectedWorkspace, onRoleChange } = props;

  const workspacesLookup: Record<
    WorkspaceId,
    { workspace: WorkspaceV2; sortIndex: number }
  > = workspaces.reduce(
    (acc, workspace) => ({
      ...acc,
      [workspace.id]: {
        workspace,
        sortIndex: workspaces.reduce(
          (acc, other) => acc + Number(workspace.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantWorkspaces = (roles: RoleV2[]) =>
    roles
      .map(roleWorkspaceIdV2)
      .filter(
        (id): id is WorkspaceId => id != null && workspacesLookup[id] != null
      )
      .sort((a, b) =>
        b === selectedWorkspace ||
        (a !== selectedWorkspace &&
          workspacesLookup[a].sortIndex > workspacesLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => workspacesLookup[id].workspace);

  return workspaces.length > 0
    ? [
        col({
          title: "Workspace",
          render: user => {
            const relevantWorkspaces = getRelevantWorkspaces(user.roles);
            return relevantWorkspaces.length === 0 ? (
              "-"
            ) : relevantWorkspaces.length === 1 ? (
              <span>{relevantWorkspaces[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantWorkspaces[0].name ?? "-"}
                secondaryText={
                  relevantWorkspaces.length === 2
                    ? "+1 other workspace"
                    : `+${relevantWorkspaces.length - 1} other workspaces`
                }
              />
            );
          },
        }),
        col({
          title: "Workspace Role",
          render: member => {
            const relevantWorkspace = getRelevantWorkspaces(member.roles)[0] as
              | WorkspaceV2
              | undefined;
            const role = Option.wrap(
              member.roles.find(
                role => roleWorkspaceIdV2(role) === relevantWorkspace?.id
              )
            );
            const roleName = role.nullMap(workspaceRoleNameV2);
            return role.isNone() || relevantWorkspace == null ? (
              "-"
            ) : member.type !== "CurrentMember" ? (
              <chakra.span>{roleName.getOrElse(() => "-")}</chakra.span>
            ) : (
              <Select.Nullable
                w="20ch"
                options={workspaceRoleOptions}
                value={roleName.getOrNull()}
                placeholder="Remove Role"
                onChange={roleName =>
                  roleName != null || role != null
                    ? onRoleChange?.(
                        member,
                        roleName != null
                          ? workspaceRoleV2(roleName, relevantWorkspace.id)
                          : role.getOrThrow(() =>
                              internalError(
                                "Tried unsetting a non existent role"
                              )
                            ),
                        roleName != null
                      )
                    : undefined
                }
                size="sm"
              />
            );
          },
        }),
      ]
    : [];
}

interface SelectedProjectColumnsProps {
  projects: ProjectV2[];
  selectedProject?: ProjectId | null;
  onRoleChange?: OnMemberRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedProjectColumns(props: SelectedProjectColumnsProps) {
  const { projects, selectedProject, onRoleChange, permissionCheckPasses } =
    props;

  const projectsLookup: Record<
    ProjectId,
    { project: ProjectV2; sortIndex: number }
  > = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: {
        project,
        sortIndex: projects.reduce(
          (acc, other) => acc + Number(project.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantProjects = (roles: RoleV2[]) =>
    roles
      .map(roleProjectIdV2)
      .filter((id): id is ProjectId => id != null && projectsLookup[id] != null)
      .sort((a, b) =>
        b === selectedProject ||
        (a !== selectedProject &&
          projectsLookup[a].sortIndex > projectsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => projectsLookup[id].project);

  return projects.length > 0
    ? [
        col({
          title: "Project",
          render: member => {
            const relevantProjects = getRelevantProjects(member.roles);
            return relevantProjects.length === 0 ? (
              "-"
            ) : relevantProjects.length === 1 ? (
              <span>{relevantProjects[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantProjects[0].name ?? "-"}
                secondaryText={
                  relevantProjects.length === 2
                    ? "+1 other project"
                    : `+${relevantProjects.length - 1} other projects`
                }
              />
            );
          },
        }),
        col({
          title: "Project Role",
          render: member => {
            const relevantProject: ProjectV2 | undefined = getRelevantProjects(
              member.roles
            )[0];
            const role = Option.wrap(
              member.roles.find(
                role => roleProjectIdV2(role) === relevantProject?.id
              )
            );
            const roleName = role.nullMap(projectRoleNameV2);
            const canGrantRole =
              relevantProject != null
                ? permissionCheckPasses(
                    checks.project.grantAccess(relevantProject)
                  )
                : false;
            return role.isNone() || relevantProject == null ? (
              "-"
            ) : member.type !== "CurrentMember" ? (
              <chakra.span>{roleName.getOrElse(() => "-")}</chakra.span>
            ) : (
              <Select.Nullable
                w="20ch"
                options={ProjectRoleNameEnum.entries}
                value={roleName.getOrNull()}
                placeholder="Remove Role"
                onChange={roleName =>
                  canGrantRole
                    ? onRoleChange?.(
                        member,
                        roleName != null
                          ? projectRoleV2(roleName, relevantProject.id)
                          : role.getOrThrow(() =>
                              internalError(
                                "Tried unsetting a non existent role"
                              )
                            ),
                        roleName != null
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            );
          },
        }),
      ]
    : [];
}

interface SelectedTeamColumnsProps {
  teams: TeamV2[];
  selectedTeam?: TeamId | null;
  onRoleChange?: OnMemberRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedTeamColumns(props: SelectedTeamColumnsProps) {
  const { teams, selectedTeam, onRoleChange, permissionCheckPasses } = props;

  const teamsLookup: Record<TeamId, { team: TeamV2; sortIndex: number }> =
    teams.reduce(
      (acc, team) => ({
        ...acc,
        [team.id]: {
          team,
          sortIndex: teams.reduce(
            (acc, other) => acc + Number(team.name > other.name),
            0
          ),
        },
      }),
      {}
    );

  const getRelevantTeams = (roles: RoleV2[]) =>
    roles
      .map(roleTeamIdV2)
      .filter((id): id is TeamId => id != null && teamsLookup[id] != null)
      .sort((a, b) =>
        b === selectedTeam ||
        (a !== selectedTeam &&
          teamsLookup[a].sortIndex > teamsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => teamsLookup[id].team);

  return teams.length > 0
    ? [
        col({
          title: "Team",
          render: member => {
            const relevantTeams = getRelevantTeams(member.roles);
            return relevantTeams.length === 0 ? (
              "-"
            ) : relevantTeams.length === 1 ? (
              <span>{relevantTeams[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantTeams[0].name ?? "-"}
                secondaryText={
                  relevantTeams.length === 2
                    ? "+1 other team"
                    : `+${relevantTeams.length - 1} other teams`
                }
              />
            );
          },
        }),
        col({
          title: "Team Role",
          render: member => {
            const relevantTeam = getRelevantTeams(member.roles)[0] as
              | TeamV2
              | undefined;
            const role = Option.wrap(
              member.roles.find(role => roleTeamIdV2(role) === relevantTeam?.id)
            );
            const roleName = role.nullMap(teamRoleNameV2);
            const canGrantRole =
              relevantTeam != null
                ? permissionCheckPasses(checks.team.grantAccess(relevantTeam))
                : false;
            return role.isNone() || relevantTeam == null ? (
              "-"
            ) : member.type !== "CurrentMember" ? (
              <chakra.span>{roleName.getOrElse(() => "-")}</chakra.span>
            ) : (
              <Select.Nullable
                w="20ch"
                options={TeamRoleNameEnum.entries}
                value={roleName.getOrNull()}
                placeholder="Remove Role"
                onChange={roleName =>
                  roleName != null || role != null
                    ? onRoleChange?.(
                        member,
                        roleName != null
                          ? teamRoleV2(roleName, relevantTeam.id)
                          : role.getOrThrow(() =>
                              internalError(
                                "Tried unsetting a non existent role"
                              )
                            ),
                        roleName != null
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            );
          },
        }),
      ]
    : [];
}

export function actionsColumn(
  currentUserId: UserId,
  canApproveOrReject: (member: PendingMemberV2) => boolean,
  onImpersonate?: OnMemberAction<CurrentMemberV2 | PendingMemberV2> | null,
  onRemove?: OnMemberAction | null,
  onApprove?: ApproveRejectCallback,
  onReject?: ApproveRejectCallback
): ActionsColumn<MemberV2> {
  return {
    renderButtons: member => [
      member.type === "PendingMember" &&
        onApprove != null &&
        canApproveOrReject(member) && (
          <IconButton
            key="approve"
            variant="outline"
            title="Approve Signup"
            aria-label="Approve Signup"
            icon={<IoCheckmarkSharp size="1.25rem" />}
            onClick={evt => {
              evt.stopPropagation();
              onApprove({
                invitationCode: member.invitationCodeId,
                user: member.userId,
              });
            }}
          />
        ),
      member.type === "PendingMember" &&
        onReject != null &&
        canApproveOrReject(member) && (
          <IconButton
            key="reject"
            variant="outline"
            title="Reject Signup"
            aria-label="Reject Signup"
            icon={<IoStopCircleOutline size="1.25rem" />}
            onClick={evt => {
              evt.stopPropagation();
              onReject({
                invitationCode: member.invitationCodeId,
                user: member.userId,
              });
            }}
          />
        ),
      (member.type === "CurrentMember" || member.type === "PendingMember") &&
        member.userId !== currentUserId &&
        onImpersonate != null && (
          <IconButton
            key="impersonate"
            variant="outline"
            title="Impersonate"
            aria-label="Impersonate"
            icon={<FaMask />}
            onClick={evt => {
              evt.stopPropagation();
              onImpersonate(member);
            }}
          />
        ),
      onRemove != null && (
        <IconButton
          key="remove"
          variant="outline"
          title="Remove User"
          aria-label="Remove User"
          icon={<IoCloseSharp size="1.25rem" />}
          onClick={evt => {
            evt.stopPropagation();
            onRemove(member);
          }}
        />
      ),
    ],
  };
}
