import { ApiConfig } from "@cartographerio/types";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";
import { useSearchParams } from "react-router-dom";

import { useCookie } from "../hooks/useCookie";
import { ApiServerId, apiServerConfig } from "./server";

function guessServerId(): ApiServerId {
  const host = window.location.host;
  if (host.includes("localhost")) {
    return "development";
  } else if (host.includes("beta.cartographer.io")) {
    return "beta";
  } else if (host.includes("next.cartographer.io")) {
    return "next";
  } else {
    return "production";
  }
}

type SetApiServerId = (serverId: ApiServerId) => void;

interface ApiConfigContextValue {
  apiConfig: ApiConfig;
  serverId: ApiServerId;
  setServerId?: SetApiServerId;
}

export const ApiConfigContext = createContext<ApiConfigContextValue>({
  apiConfig: apiServerConfig(guessServerId()),
  serverId: guessServerId(),
});

export function useApiConfig(): ApiConfig {
  return useContext(ApiConfigContext).apiConfig;
}

export function useApiServerId(): [ApiServerId, SetApiServerId | undefined] {
  const { serverId, setServerId } = useContext(ApiConfigContext);
  return [serverId, setServerId];
}

export interface ApiConfigProviderProps {
  children: ReactNode;
}

function parseServerParam(param: string | undefined): ApiServerId | undefined {
  switch (param) {
    case "prod":
    case "production":
      return "production";

    case "beta":
      return "beta";

    case "next":
      return "next";

    case "dev":
    case "development":
      return "development";

    default:
      return undefined;
  }
}

export function ApiConfigProvider(props: ApiConfigProviderProps): ReactElement {
  const { children } = props;

  const [params] = useSearchParams();
  const serverParam = params.get("server")?.toLowerCase();

  const [serverCookie, setServerCookie, _clearServerCookie] = useCookie<
    ApiServerId | undefined
  >("Server", undefined);

  const serverId = useMemo(
    () => parseServerParam(serverParam) ?? serverCookie ?? guessServerId(),
    [serverCookie, serverParam]
  );

  const value = useMemo(
    () => ({
      apiConfig: apiServerConfig(serverId),
      serverId,
      setServerId: setServerCookie,
    }),
    [serverId, setServerCookie]
  );

  return (
    <ApiConfigContext.Provider value={value}>
      {children}
    </ApiConfigContext.Provider>
  );
}
