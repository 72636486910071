import { errorMessage } from "@cartographerio/atlas-core";
import {
  ArcgisIntegrationCreate,
  ArcgisIntegrationUpdate,
} from "@cartographerio/types";

import { namedEmailRule } from "./emailSettings";
import { SomeNullable, rule } from "./rule";

export type PartialArcgisIntegrationCreate = SomeNullable<
  ArcgisIntegrationCreate,
  "layerId" | "arcgisLayerUrl"
>;

export const { rule: arcgisCreateRule, keys: arcgisCreateKeys } = rule
  .build<PartialArcgisIntegrationCreate>()
  .field("name", rule.nonEmpty([errorMessage("You must specify a name")]))
  .field(
    "layerId",
    rule.required(
      [errorMessage("You must specify a source layer")],
      rule.nonEmpty([errorMessage("You must specify a source layer")])
    )
  )
  .field(
    "arcgisLayerUrl",
    rule.required(
      [errorMessage("You must specify a target layer url on ArcGIS")],
      rule.url([errorMessage("You must specify a target layer url on ArcGIS")])
    )
  )
  .optionalField("notificationEmail", rule.nullable(namedEmailRule))
  .finish();

export const { rule: arcgisUpdateRule, keys: arcgisUpdateKeys } = rule
  .build<ArcgisIntegrationUpdate>()
  .field("name", rule.nonEmpty([errorMessage("You must specify a name")]))
  .optionalField("notificationEmail", rule.nullable(namedEmailRule))
  .finish();
