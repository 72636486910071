import { PermissionCheck } from "@cartographerio/types";
import { useNavigate } from "react-router-dom";

import useRequirePermission from "./useRequirePermission";

export default function useRequirePermissionRedirect(
  check: PermissionCheck,
  onError: () => string
): void {
  const navigate = useNavigate();

  useRequirePermission(check, () => navigate(onError(), { replace: true }));
}
