import {
  PartialParams,
  RcaWorkspaceStatusSearchOptions,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  ProjectId,
  RcaWorkspaceStatus,
  SearchResults,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type RcaKey =
  | ["rca"]
  | ["rca", "workspaceStatus", "v1", "read", ProjectId]
  | [
      "rca",
      "workspaceStatus",
      "v1",
      "search",
      PartialParams<RcaWorkspaceStatusSearchOptions>
    ];

export function readOrNull(
  apiParams: ApiParams,
  projectId: ProjectId
): UseQueryOpts<RcaWorkspaceStatus | null, RcaKey> {
  return {
    queryKey: ["rca", "workspaceStatus", "v1", "read", projectId],
    queryFn: () =>
      endpoints.rca.workspaceStatus.v1
        .readOption(apiParams, projectId)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  projectId: ProjectId
): UseQueryOpts<RcaWorkspaceStatus, RcaKey> {
  return {
    queryKey: ["rca", "workspaceStatus", "v1", "read", projectId],
    queryFn: () =>
      endpoints.rca.workspaceStatus.v1
        .readOrFail(apiParams, projectId)
        .unsafeRun(),
  };
}

export function search(
  apiParams: ApiParams,
  options: PartialParams<RcaWorkspaceStatusSearchOptions> = {}
): UseQueryOpts<SearchResults<RcaWorkspaceStatus>, RcaKey> {
  return {
    queryKey: ["rca", "workspaceStatus", "v1", "search", options],
    queryFn: () =>
      endpoints.rca.workspaceStatus.v1.search(apiParams, options).unsafeRun(),
  };
}
