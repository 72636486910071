import { SelectOption } from "@cartographerio/atlas-form";
import { Message, WorkspaceV2 } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  IconButton,
} from "@chakra-ui/react";
import { outdent } from "outdent";
import { ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { splitMessages } from "../../../../schema/rule/errors";
import Fieldset from "../../../components/Fieldset";
import Heading from "../../../components/Heading";
import MessageFormControl from "../../../components/MessageFormControl";
import Select from "../../../components/Select";
import Spaced from "../../../components/Spaced";
import { blankPerson, personName } from "./helpers";
import { PersonSection } from "./PersonSection";
import {
  ExistingRcaWorkspace,
  LocalId,
  NewRcaWorkspace,
  RcaWorkspace,
  billingContactErrorKeys,
  existingRcaWorkspaceErrorKeys,
  newRcaWorkspaceErrorKeys,
} from "./schema";
import { TraineesSection } from "./TraineesSection";
import {
  ExistingWorkspaceSection,
  NewWorkspaceSection,
} from "./WorkspaceDetails";

export interface RcaWorkspaceSectionProps {
  value: RcaWorkspace;
  messages: Message[];
  workspaces: WorkspaceV2[];
  onChange: (ws: RcaWorkspace) => void;
  onRemove: () => void;
}

export function RcaWorkspaceSection(
  props: RcaWorkspaceSectionProps
): ReactElement {
  const { value, messages, workspaces, onChange, onRemove } = props;

  switch (value.type) {
    case "NewRcaWorkspace":
      return (
        <NewRcaWorkspaceSection
          value={value}
          messages={messages}
          onChange={onChange}
          onRemove={onRemove}
        />
      );

    case "ExistingRcaWorkspace":
      return (
        <ExistingRcaWorkspaceSection
          value={value}
          workspaces={workspaces}
          messages={messages}
          onChange={onChange}
          onRemove={onRemove}
        />
      );
  }
}

interface ExistingRcaWorkspaceSectionProps {
  value: ExistingRcaWorkspace;
  workspaces: WorkspaceV2[];
  messages: Message[];
  onChange: (value: ExistingRcaWorkspace) => void;
  onRemove: () => void;
}

export function ExistingRcaWorkspaceSection(
  props: ExistingRcaWorkspaceSectionProps
): ReactElement {
  const { value, workspaces, messages, onChange, onRemove } = props;

  const errors = useMemo(
    () => splitMessages(messages, existingRcaWorkspaceErrorKeys),
    [messages]
  );

  return (
    <Card>
      <CardHeader>
        <HStack w="100%">
          <Heading
            level="section"
            maxW="100%"
            mt="0"
            flexShrink={1}
            flexGrow={1}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {value.workspaceDetails.workspace?.name} (Existing Workspace)
          </Heading>
          <IconButton
            flexShrink={0}
            flexGrow={0}
            icon={<IoCloseSharp size="1.25rem" />}
            variant="ghost"
            aria-label="Remove"
            onClick={onRemove}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        <Spaced spacing="8">
          <ExistingWorkspaceSection
            value={value.workspaceDetails}
            workspaces={workspaces}
            messages={errors.workspaceDetails}
            onChange={workspaceDetails =>
              onChange({ ...value, workspaceDetails })
            }
          />
          <TraineesSection
            value={value.trainees}
            messages={errors.trainees}
            onChange={trainees => onChange({ ...value, trainees })}
          />
        </Spaced>
      </CardBody>
    </Card>
  );
}

interface NewRcaWorkspaceSectionProps {
  value: NewRcaWorkspace;
  messages: Message[];
  onChange: (value: NewRcaWorkspace) => void;
  onRemove: () => void;
}

export function NewRcaWorkspaceSection(
  props: NewRcaWorkspaceSectionProps
): ReactElement {
  const { value, messages, onChange, onRemove } = props;

  const title = !value.workspaceDetails.workspace?.name
    ? "New Workspace"
    : `${value.workspaceDetails.workspace.name} (New Workspace)`;

  const errors = useMemo(
    () => splitMessages(messages, newRcaWorkspaceErrorKeys),
    [messages]
  );

  const billingContactErrors = useMemo(
    () => splitMessages(errors.billingContact, billingContactErrorKeys),
    [errors.billingContact]
  );

  const separateBillingContact = useMemo(() => blankPerson(), []);

  const billingContactOptions = useMemo((): SelectOption<LocalId | null>[] => {
    return [
      { value: null, label: "No Workspace Owner" },
      {
        value: separateBillingContact.localId,
        label: "New Workspace Owner",
      },
      ...value.trainees.map(trainee => ({
        value: trainee.localId,
        label: personName(trainee),
      })),
    ];
  }, [separateBillingContact.localId, value.trainees]);

  const billingContactLocalId = useMemo(() => {
    switch (value.billingContact?.type) {
      case "Separate":
        return value.billingContact.contact.localId;

      case "Trainee":
        return value.billingContact.traineeId;

      case null:
      case undefined:
        return null;

      default:
        return checkExhausted(value.billingContact);
    }
  }, [value.billingContact]);

  return (
    <Card>
      <CardHeader>
        <HStack w="100%">
          <Heading
            level="section"
            maxW="100%"
            mt="0"
            flexShrink={1}
            flexGrow={1}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {title}
          </Heading>
          <IconButton
            flexShrink={0}
            flexGrow={0}
            icon={<IoCloseSharp size="1.25rem" />}
            variant="ghost"
            aria-label="Remove"
            onClick={onRemove}
          />
        </HStack>
      </CardHeader>
      <CardBody>
        <Spaced spacing="8">
          <NewWorkspaceSection
            value={value.workspaceDetails}
            messages={errors.workspaceDetails}
            onChange={workspaceDetails =>
              onChange({ ...value, workspaceDetails })
            }
          />

          <TraineesSection
            value={value.trainees}
            messages={errors.trainees}
            onChange={trainees => onChange({ ...value, trainees })}
          />

          <Fieldset
            legend="Workspace Owner (Billing Contact)"
            help={WORKSPACE_OWNER_HELP}
          >
            <MessageFormControl messages={billingContactErrors._rest_}>
              <Select.Nullable
                value={billingContactLocalId}
                placeholder="Select a billing contact..."
                options={billingContactOptions}
                onChange={localId =>
                  onChange({
                    ...value,
                    billingContact:
                      localId === separateBillingContact.localId
                        ? { type: "Separate", contact: separateBillingContact }
                        : localId != null
                        ? { type: "Trainee", traineeId: localId }
                        : null,
                  })
                }
              />
              {value.billingContact?.type === "Separate" && (
                <PersonSection
                  value={value.billingContact.contact}
                  messages={billingContactErrors.contact}
                  onChange={contact =>
                    onChange({
                      ...value,
                      billingContact: { type: "Separate", contact },
                    })
                  }
                  mt="2"
                />
              )}
            </MessageFormControl>
          </Fieldset>
        </Spaced>
      </CardBody>
    </Card>
  );
}

const WORKSPACE_OWNER_HELP = outdent`
  Workspace owners are given the *Workspace Owner* role in this workspace.
  This effectively grants them full access to every config screen in the workspace.
  However, they are *not* granted an RCA qualification
  (which they need to contribute RCA data) unless they are also a trainee.
`;
