import { SelectOption } from "@cartographerio/atlas-form";
import { checks } from "@cartographerio/permission";
import { ProjectV2, TeamV2, WorkspaceV2 } from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import Link from "../components/Link";
import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import { routes } from "../routes";

interface ProjectTeamSettingsButtonProps {
  workspace: WorkspaceV2;
  project: ProjectV2;
  teams: TeamV2[];
  ButtonElement: (props: Partial<SelectOption<string>>) => ReactElement;
}

export default function ProjectTeamSettingsButton(
  props: ProjectTeamSettingsButtonProps
): ReactElement {
  const { workspace, project, teams, ButtonElement } = props;

  const permissionCheckPasses = usePermissionCheckRunner();

  const settingsOptions = useMemo(
    (): SelectOption<string>[] =>
      permissionCheckPasses(checks.project.viewSettings(project))
        ? [
            {
              label: "Project",
              value: routes.workspace.project.settings.url([
                workspace.alias,
                project.alias,
              ]),
            },
          ]
        : filterAndMap(teams, team =>
            permissionCheckPasses(checks.team.viewSettings(team))
              ? {
                  label: team.name,
                  value: routes.workspace.team.settings.url([
                    workspace.alias,
                    team.alias,
                  ]),
                }
              : null
          ),
    [permissionCheckPasses, project, teams, workspace.alias]
  );

  return (
    <>
      {settingsOptions.length === 1 ? (
        <ButtonElement {...settingsOptions[0]} />
      ) : (
        settingsOptions.length > 1 && (
          <Menu placement="bottom-end">
            <MenuButton>
              <ButtonElement />
            </MenuButton>
            <MenuList
              zIndex="popover"
              fontSize="sm"
              maxH="30em"
              overflowY="auto"
              fontWeight="normal"
            >
              <MenuGroup title="Choose a Team" fontSize="sm" mx="3">
                {settingsOptions.map(({ label, value }) => (
                  <MenuItem
                    _hover={{ bg: "gray.100", textDecoration: "none" }}
                    key={value}
                    as={Link.Internal}
                    to={value}
                  >
                    {label}
                  </MenuItem>
                ))}
              </MenuGroup>
            </MenuList>
          </Menu>
        )
      )}
    </>
  );
}
