import { FormDispatch } from "@cartographerio/atlas-form";
import {
  AttachmentId,
  AttachmentUpdate,
  DataLicense,
  ProjectV2,
  SurveyId,
  SurveyModuleId,
  internalError,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { UserWorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useMemo,
} from "react";

// TODO: Replace this with the implementation from mobile...
// ...or maybe factor the two out into a library!
export interface FormContext {
  formDispatch: FormDispatch;
  onAttachmentMetadataUpdate?: (
    id: AttachmentId,
    update: AttachmentUpdate
  ) => void;
  creating: boolean;
  editable: boolean;
  debugInfoVisible: boolean;
  setDebugInfoVisible: (value: boolean) => void;
  helpVisible: boolean;
  setHelpVisible: (value: boolean) => void;
  project: ProjectV2;
  workspaceGraph: UserWorkspaceGraphV2;
  module: SurveyModuleId;
  survey: SurveyId;
  defaultDataLicense: DataLicense | null;
}

export const FormContext = createContext<FormContext | undefined>(undefined);

export function useFormContext(): FormContext {
  return (
    useContext(FormContext) ?? raise(internalError("FormContext not available"))
  );
}

interface FormContextProviderProps extends FormContext {
  children: ReactNode;
}

export function FormContextProvider(
  props: FormContextProviderProps
): ReactElement {
  const {
    formDispatch,
    onAttachmentMetadataUpdate,
    creating,
    editable,
    debugInfoVisible,
    setDebugInfoVisible,
    helpVisible,
    setHelpVisible,
    project,
    workspaceGraph,
    module,
    survey,
    defaultDataLicense,
    children,
  } = props;

  const context: FormContext = useMemo(
    () => ({
      formDispatch,
      onAttachmentMetadataUpdate,
      creating,
      editable,
      debugInfoVisible,
      setDebugInfoVisible,
      helpVisible,
      setHelpVisible,
      project,
      workspaceGraph,
      module,
      survey,
      defaultDataLicense,
    }),
    [
      formDispatch,
      onAttachmentMetadataUpdate,
      creating,
      editable,
      debugInfoVisible,
      setDebugInfoVisible,
      helpVisible,
      setHelpVisible,
      project,
      workspaceGraph,
      module,
      survey,
      defaultDataLicense,
    ]
  );

  return (
    <FormContext.Provider value={context}>{children}</FormContext.Provider>
  );
}
