import {
  Form,
  FormDispatch,
  FormState,
  formReducer,
  initialFormState,
  resetAction,
} from "@cartographerio/atlas-form";
import { Schema } from "@cartographerio/atlas-survey";
import { SurveyV2 } from "@cartographerio/types";
import { useEffect, useReducer } from "react";

type Triple = [Form, Schema, SurveyV2];

export function useFormReducer(
  form: Form,
  schema: Schema,
  defaultSurvey: SurveyV2
): [FormState, FormDispatch] {
  const [state, dispatch] = useReducer<typeof formReducer, Triple>(
    formReducer,
    [form, schema, defaultSurvey],
    ([form, schema, survey]) => initialFormState(form, schema, survey)
  );

  useEffect(() => {
    dispatch(resetAction({ document: defaultSurvey, form, schema }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSurvey]);

  return [state, dispatch];
}
