import { Heading, Link, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Link as ReactLink } from "react-router-dom";

export interface SidebarHeaderProps {
  title: string;
  linkTo?: string;
}

export default function SidebarHeader(props: SidebarHeaderProps): ReactElement {
  const { title, linkTo } = props;

  const titleDiv = (
    <chakra.div
      maxW="100%"
      px="3"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      title={title}
    >
      {title}
    </chakra.div>
  );

  return (
    <Heading
      display="flex"
      alignItems="center"
      maxW="100%"
      h="12"
      borderWidth={3}
      borderColor="transparent"
      as="h1"
      fontSize="2xl"
    >
      {linkTo == null ? (
        titleDiv
      ) : (
        <Link maxW="100%" display="block" as={ReactLink} to={linkTo}>
          {titleDiv}
        </Link>
      )}
    </Heading>
  );
}
