import { SelectOption } from "@cartographerio/atlas-form";
import { IO } from "@cartographerio/io";
import {
  ProjectAlias,
  ProjectV2,
  TeamV2,
  WorkspaceV2,
} from "@cartographerio/types";
import { chakra, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../queries";
import {
  DeriveOutgoingQueryProps,
  DeriveQueryUpdate,
} from "../../../routes/derive";
import { useIOErrorAlert, useIOPrompt } from "../../components/Alert";
import Container from "../../components/Container";
import Pre from "../../components/Pre";
import Spaced from "../../components/Spaced";
import TeamList from "../../components/TeamList";
import { useApiParams } from "../../contexts/auth";
import { routes } from "../../routes";
import { teamList, workspaceTeamList } from "../../routes/queryParams";

type BaseTeamListPageProps = {
  workspace: WorkspaceV2;
  enableAddButton: boolean;
} & (
  | {
      project?: ProjectAlias;
      enableProjectFilter?: false;
      query: DeriveOutgoingQueryProps<typeof teamList>;
      updateQuery: DeriveQueryUpdate<typeof teamList>;
    }
  | {
      enableProjectFilter: true;
      projects: ProjectV2[];
      query: DeriveOutgoingQueryProps<typeof workspaceTeamList>;
      updateQuery: DeriveQueryUpdate<typeof workspaceTeamList>;
    }
);

export default function BaseTeamListPage(
  props: BaseTeamListPageProps
): ReactElement {
  const { workspace, enableAddButton, query, updateQuery } = props;

  const route =
    props.enableProjectFilter === true
      ? routes.workspace.team.list
      : routes.workspace.project.teams;

  const project =
    props.enableProjectFilter === true ? props.query.project : props.project;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const promptAlert = useIOPrompt();
  const errorAlert = useIOErrorAlert();

  const { order = "name-asc", page, count, q: searchTerm } = query;

  const handleDeleteClick = useCallback(
    (team: TeamV2) => {
      promptAlert({
        title: `Are you sure you want to delete team ${team.name}`,
        message: (
          <Spaced spacing="2">
            <chakra.p>
              Surveys and photographs belonging to this team will be moved to
              the project as a whole.
            </chakra.p>
            <chakra.p>
              User will remain in the project and will continue to have the same
              level of access to other teams.
            </chakra.p>
            <chakra.p>
              To confirm your choice, enter{" "}
              <Pre text={team.name} display="inline" py="0" px="1" /> in the
              text field below:
            </chakra.p>
          </Spaced>
        ),
      })
        .chain(({ confirmed, text }) =>
          confirmed && text === team.name
            ? queries.team.v2
                .remove(queryClient, apiParams, team.id)
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() => toast({ status: "success", description: "Team deleted" }))
        .recover(() =>
          toast({ status: "error", description: "Delete cancelled" })
        )
        .unsafeRun();
    },
    [promptAlert, queryClient, apiParams, errorAlert, toast]
  );

  const projectOptions = useMemo(
    (): SelectOption<ProjectAlias>[] | null =>
      props.enableProjectFilter
        ? props.projects.map(({ name, alias }) => ({
            label: name,
            value: alias,
          }))
        : null,
    [props]
  );
  const onProjectChange = useMemo(
    () =>
      props.enableProjectFilter
        ? (project: ProjectAlias | null) =>
            updateQuery({ ...query, project: project ?? undefined })
        : undefined,
    [props.enableProjectFilter, query, updateQuery]
  );

  return (
    <Container>
      <TeamList
        page={page}
        count={count}
        order={order}
        searchTerm={searchTerm}
        workspace={workspace.alias}
        project={project}
        projectOptions={projectOptions}
        route={route}
        query={query}
        updateQuery={updateQuery}
        addLink={
          enableAddButton
            ? routes.workspace.team.create.url([workspace.alias], {
                project,
              })
            : undefined
        }
        itemLink={team =>
          routes.workspace.team.settings.url([workspace.alias, team.alias])
        }
        onSearchTermChange={q =>
          updateQuery({ ...query, q: q ?? undefined, page: 0 })
        }
        onPageChange={page => updateQuery({ ...query, page })}
        onOrderChange={order => updateQuery({ ...query, order })}
        onDeleteClick={handleDeleteClick}
        onProjectChange={onProjectChange}
      />
    </Container>
  );
}
