import { isLabeled } from "@cartographerio/guard";
import {
  ApiError,
  isExpiredAuthorizationError,
  isForbiddenError,
  isNotFoundError,
} from "@cartographerio/types";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";

import { QUERY_RETRY_COUNT } from "./config";
import { AlertProvider } from "./ui/components/Alert";
import FathomAnalyticsProvider from "./ui/components/FathomAnalytics";
import { ReactQueryDevtoolsProvider } from "./ui/components/ReactQueryDevtools";
import ResetScrollOnPathChange from "./ui/components/ResetScrollOnPathChange";
import SuspenseBoundary from "./ui/components/SuspenseBoundary";
import { appTheme } from "./ui/config/theme";
import { ApiConfigProvider } from "./ui/contexts/apiConfig";
import { AttachmentViewProvider } from "./ui/contexts/attachmentView";
import { AccessTokenProvider } from "./ui/contexts/auth";
import BreakpointProvider from "./ui/contexts/breakpoint";
import { LocationModeProvider } from "./ui/contexts/location";
import { SentryProvider } from "./ui/hooks/useSentry";
import AppRoutes from "./ui/routes/AppRoutes";

type IsApiError = (err: unknown) => err is ApiError;

const doNotRetryErrors: IsApiError[] = [
  isLabeled("ForbiddenError", isForbiddenError),
  isLabeled("NotFoundError", isNotFoundError),
  isLabeled("ExpiredAuthorizationError", isExpiredAuthorizationError),
];

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) =>
        !doNotRetryErrors.some(isError => isError(error)) &&
        failureCount <= QUERY_RETRY_COUNT,
    },
  },
});

export default function App(): ReactElement {
  return (
    <ChakraProvider theme={appTheme}>
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <ReactQueryDevtoolsProvider>
            <SuspenseBoundary showSignout={true}>
              <FathomAnalyticsProvider>
                <ApiConfigProvider>
                  <AccessTokenProvider>
                    <SentryProvider>
                      <BreakpointProvider>
                        <AlertProvider>
                          <LocationModeProvider>
                            <AttachmentViewProvider>
                              <ResetScrollOnPathChange>
                                <AppRoutes />
                              </ResetScrollOnPathChange>
                            </AttachmentViewProvider>
                          </LocationModeProvider>
                        </AlertProvider>
                      </BreakpointProvider>
                    </SentryProvider>
                  </AccessTokenProvider>
                </ApiConfigProvider>
              </FathomAnalyticsProvider>
            </SuspenseBoundary>
          </ReactQueryDevtoolsProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  );
}
