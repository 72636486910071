import {
  PartialParams,
  UserSearchOptionsV2,
  endpoints,
} from "@cartographerio/client";
import { Option } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  AnonymisedUserV2,
  ApiParams,
  Email,
  ProjectId,
  SearchResults,
  TeamId,
  UserId,
  UserUpdateV2,
  UserV2,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";
import { uniqBy } from "lodash";

import { UseQueryOpts } from "../base";

interface UserSearchOptionsV2WithTeams extends UserSearchOptionsV2 {
  teamIds?: TeamId[];
}

export type UserKey =
  | ["person", "user"]
  | [
      "person",
      "user",
      "v2",
      "search",
      PartialParams<UserSearchOptionsV2WithTeams>
    ]
  | [
      "person",
      "user",
      "v2",
      "searchAnonymised",
      PartialParams<UserSearchOptionsV2WithTeams>
    ]
  | ["person", "user", "v2", "read", UserId | Email]
  | ["person", "user", "v2", "readAnonymised", UserId | Email];

export function search(
  apiParams: ApiParams,
  options: PartialParams<UserSearchOptionsV2>
): UseQueryOpts<SearchResults<UserV2>, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "search", options],
    queryFn: () => endpoints.user.v2.search(apiParams, options).unsafeRun(),
  };
}

export function searchAnonymised(
  apiParams: ApiParams,
  options: PartialParams<UserSearchOptionsV2>
): UseQueryOpts<SearchResults<AnonymisedUserV2>, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "searchAnonymised", options],
    queryFn: () =>
      endpoints.user.v2.searchAnonymised(apiParams, options).unsafeRun(),
  };
}

export function anonymisedProjectMembers(
  apiParams: ApiParams,
  projectId: ProjectId,
  teamIds?: TeamId[]
): UseQueryOpts<AnonymisedUserV2[], UserKey> {
  const options: PartialParams<UserSearchOptionsV2> = { project: projectId };
  return {
    queryKey: [
      "person",
      "user",
      "v2",
      "searchAnonymised",
      { ...options, teamIds },
    ],
    queryFn: () => {
      const projectUsers = endpoints.user.v2
        .searchAnonymised(apiParams, { project: projectId })
        .map(({ results }) => results);

      const teamUsers = IO.parallel(
        (teamIds ?? []).map(teamId =>
          endpoints.user.v2
            .searchAnonymised(apiParams, { team: teamId })
            .map(({ results }) => results)
        )
      ).map(array => array.flatMap(x => x));

      return IO.parWhen(
        projectUsers,
        teamUsers
      )((projectUsers, teamUsers) =>
        uniqBy([...projectUsers, ...teamUsers], user => user.id)
      ).unsafeRun();
    },
  };
}

export function readOrFail(
  apiParams: ApiParams,
  userIdOrEmail: UserId | Email
): UseQueryOpts<UserV2, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "read", userIdOrEmail],
    queryFn: () =>
      endpoints.user.v2.readOrFail(apiParams, userIdOrEmail).unsafeRun(),
  };
}

export function readOrNull(
  apiParams: ApiParams,
  key: UserId | Email
): UseQueryOpts<UserV2 | null, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "read", key],
    queryFn: () =>
      endpoints.user.v2
        .readOption(apiParams, key)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

export function readAnonymised(
  apiParams: ApiParams,
  userIdOrEmail: UserId | Email
): UseQueryOpts<Option<AnonymisedUserV2>, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "readAnonymised", userIdOrEmail],
    queryFn: () =>
      endpoints.user.v2.readAnonymised(apiParams, userIdOrEmail).unsafeRun(),
  };
}

export function readAnonymisedOrFail(
  apiParams: ApiParams,
  userIdOrEmail: UserId | Email
): UseQueryOpts<AnonymisedUserV2, UserKey> {
  return {
    queryKey: ["person", "user", "v2", "read", userIdOrEmail],
    queryFn: () =>
      endpoints.user.v2
        .readAnonymisedOrFail(apiParams, userIdOrEmail)
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  userId: UserId,
  user: UserUpdateV2,
  workspaceRef?: WorkspaceRef,
  welcome?: boolean
) {
  return endpoints.user.v2
    .save(apiParams, userId, user, workspaceRef, welcome)
    .tap(() => {
      queryClient.invalidateQueries(["person"]);
      queryClient.invalidateQueries(["qualification", "history"]);
    });
}
