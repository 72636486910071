import {
  Box,
  Flex,
  HStack,
  IconButton,
  StyleProps,
  SystemProps,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useState } from "react";
import { IoChevronDownSharp, IoChevronForwardSharp } from "react-icons/io5";

import Spaced from "./Spaced";

export interface DisclosureProps extends StyleProps {
  defaultOpen?: boolean;
  toggle: ReactNode;
  spacing?: SystemProps["margin"];
  children: ReactNode;
}

export default function Disclosure(props: DisclosureProps): ReactElement {
  const { defaultOpen = false, toggle, spacing, children, ...rest } = props;

  const [open, setOpen] = useState(defaultOpen);

  return (
    <Spaced spacing={spacing} {...rest}>
      <HStack
        onClick={() => setOpen(!open)}
        alignItems="center"
        cursor="pointer"
      >
        <Flex w="6" grow={0} shrink={0} justify="center" align="center">
          <IconButton
            size="xs"
            variant="ghost"
            icon={open ? <IoChevronDownSharp /> : <IoChevronForwardSharp />}
            aria-label="Toggle"
          />
        </Flex>
        <Flex grow={1} shrink={1} alignItems="center">
          {toggle}
        </Flex>
      </HStack>
      {open && <Box ml="8">{children}</Box>}
    </Spaced>
  );
}
