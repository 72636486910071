import { SelectOption } from "@cartographerio/atlas-form";
import { checks } from "@cartographerio/permission";
import { ProjectAlias, TeamAlias } from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import { RouteProps } from "../../../routes";
import ButtonLink from "../../components/ButtonLink";
import PageTopBar from "../../components/PageTopBar";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationCodeListPage from "../base/BaseInvitationCodeListPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceInvitationCodeListPage(
  props: RouteProps<typeof routes.workspace.invitation.code.list>
): ReactElement {
  const {
    path: { workspaceRef },
    query,
    updateQuery,
  } = props;

  const graph = useCurrentWorkspaceGraph(workspaceRef);

  const [workspace] = useMemo(() => graph.allWorkspaces(), [graph]);

  useRedirectWhen(!useCodesEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  const multiTeam = useTeamsEnabled(workspace);

  const selectedProject = useMemo(
    () =>
      query.project != null
        ? graph.optFindProjectByRef(query.project, workspace.id)
        : null,
    [query.project, graph, workspace.id]
  );

  const projectOptions = useMemo<SelectOption<ProjectAlias>[]>(
    () =>
      graph
        .allProjects()
        .map(({ name, alias }) => ({ label: name, value: alias })),
    [graph]
  );

  const selectedTeam = useMemo(
    () =>
      multiTeam && query.team != null
        ? graph.optFindTeamByRef(query.team, workspace.id)
        : null,
    [graph, multiTeam, query.team, workspace.id]
  );

  const teamOptions = useMemo<SelectOption<TeamAlias>[]>(
    () =>
      graph
        .allTeams()
        .map(({ name, alias }) => ({ label: name, value: alias })),
    [graph]
  );

  useRequirePermission(checks.workspace.admin(workspace));

  usePageTitle(`Invitation Codes - ${workspace.name}`);

  return (
    <>
      <PageTopBar workspace={workspace} workspacePage="invitation-code" />
      <WorkspacePageHeader workspace={workspace} selected="invitation-code" />
      <BaseInvitationCodeListPage
        route={routes.workspace.invitation.code.list}
        query={query}
        updateQuery={updateQuery}
        workspace={workspace}
        project={selectedProject}
        projectOptions={projectOptions}
        onProjectChange={project =>
          updateQuery({ ...query, project: project ?? undefined })
        }
        team={selectedTeam}
        teamOptions={teamOptions}
        onTeamChange={team =>
          updateQuery({ ...query, team: team ?? undefined })
        }
        addButton={
          <ButtonLink.Internal
            colorScheme="blue"
            to={routes.workspace.invitation.code.create.url([workspace.alias])}
          >
            Add
          </ButtonLink.Internal>
        }
        itemLink={invitation =>
          routes.workspace.invitation.code.view.url([
            workspace.alias,
            invitation.alias,
          ])
        }
      />
    </>
  );
}
