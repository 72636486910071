import { SurveyStatus } from "@cartographerio/types";
import { ReactNode } from "react";

export interface SaveOption {
  value: SurveyStatus;
  label: string;
  helpText: ReactNode;
}

function saveOption(
  value: SurveyStatus,
  label: string,
  helpText: string
): SaveOption {
  return { value, label, helpText };
}

export const draft = saveOption(
  "draft",
  "Draft",
  "Mark the survey as draft while you're working on it."
);

export const complete = saveOption(
  "complete",
  "Complete",
  "Submit the survey for approval by an administrator."
);

export const approved = saveOption(
  "approved",
  "Approved",
  "Approve the survey and prevent further changes."
);

export const review = saveOption(
  "review",
  "In Review",
  "Mark the survey as requiring review before it can be approved."
);

export const rejected = saveOption(
  "rejected",
  "Rejected",
  "Reject the survey, hiding it and preventing further changes."
);

export const allSaveOptions = [draft, complete, approved, review, rejected];
