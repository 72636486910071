import { MapLayerId } from "@cartographerio/atlas-map";
import { raise } from "@cartographerio/util";
import { chain } from "lodash";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { Setter } from "../../hooks/useRecord";
import { useVolatileRecord } from "../../hooks/useVolatileRecord";
import { useMapLayers } from "./MapSchemaContext";

interface LayerVisibilityContextValue {
  layerVisibility: Record<MapLayerId, boolean>;
  setLayerVisibility: Setter<MapLayerId, boolean>;
}

const LayerVisibilityContext =
  createContext<LayerVisibilityContextValue | undefined>(undefined);

export function useLayerVisibilityContext(): LayerVisibilityContextValue {
  return (
    useContext(LayerVisibilityContext) ??
    raise(new Error("No current atlas map context"))
  );
}

export function useLayerVisibility(
  layerId: MapLayerId | undefined
): [boolean, (visibility: boolean) => void] {
  const { layerVisibility, setLayerVisibility } = useLayerVisibilityContext();
  const value = layerId == null ? false : layerVisibility[layerId];
  const setter = useCallback(
    (visibility: boolean) => {
      layerId != null && setLayerVisibility(layerId, visibility);
    },
    [layerId, setLayerVisibility]
  );
  return [value, setter];
}

interface LayerVisibilityContextProviderProps {
  children: ReactNode;
}

export function LayerVisibilityContextProvider(
  props: LayerVisibilityContextProviderProps
) {
  const { children } = props;

  const layers = useMapLayers();

  const [layerVisibility, setLayerVisibility] = useVolatileRecord(
    useCallback(
      () =>
        chain(layers)
          .map(layer => [layer.layerId, layer.defaultVisibility ?? true])
          .fromPairs()
          .value(),
      [layers]
    )
  );

  const value = useMemo<LayerVisibilityContextValue>(
    () => ({
      layerVisibility,
      setLayerVisibility,
    }),
    [layerVisibility, setLayerVisibility]
  );

  return (
    <LayerVisibilityContext.Provider value={value}>
      {children}
    </LayerVisibilityContext.Provider>
  );
}
