import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocalStorage } from "usehooks-ts";

export type AttachmentView = "grid" | "list";

type AttachmentViewContextValue = [
  AttachmentView,
  (mode: AttachmentView) => void
];

const AttachmentViewContext =
  createContext<AttachmentViewContextValue | undefined>(undefined);

export function useAttachmentView(): AttachmentViewContextValue {
  return (
    useContext(AttachmentViewContext) ??
    raise(new Error("No attachment view context found"))
  );
}

interface AttachmentViewProviderProps {
  defaultValue?: AttachmentView;
  onChange?: (mode: AttachmentView) => void;
  children: ReactNode;
}

export function AttachmentViewProvider(props: AttachmentViewProviderProps) {
  const { defaultValue = "grid", onChange, children } = props;

  const [value, setValue] = useLocalStorage<AttachmentView>(
    "AttachmentView",
    defaultValue
  );

  const contextValue = useMemo<AttachmentViewContextValue>(
    () => [value, setValue],
    [setValue, value]
  );

  useEffect(() => {
    onChange?.(value);
  }, [value, onChange]);

  return (
    <AttachmentViewContext.Provider value={contextValue}>
      {children}
    </AttachmentViewContext.Provider>
  );
}
