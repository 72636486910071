import { PermissionCheck } from "@cartographerio/types";
import { useMemo } from "react";

import usePermissionCheckRunner from "./usePermissionCheckRunner";

export type PermissionCheckRunner = (check: PermissionCheck) => boolean;

export default function usePermissionCheck(check: PermissionCheck): boolean {
  const runner = usePermissionCheckRunner();
  return useMemo(() => runner(check), [check, runner]);
}
