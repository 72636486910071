import {
  KnownWorkspaceFeatureEnum,
  randomUuid,
  unsafeEmail,
} from "@cartographerio/types";
import { checkExhausted, unsafeTag } from "@cartographerio/util";

import { initialWorkspace } from "../AdminWorkspaceCreatePage";
import {
  ExistingRcaWorkspace,
  LocalId,
  NewRcaWorkspace,
  Person,
  RcaWorkspace,
} from "./schema";

export function setItem<T>(arr: T[], i: number, item?: T | null): T[] {
  const newItems = [...arr];
  if (item != null) {
    newItems.splice(i, 1, item);
  } else {
    newItems.splice(i, 1);
  }
  return newItems;
}

export function blankNewRcaWorkspace(): NewRcaWorkspace {
  const templateWorkspace = initialWorkspace();

  return {
    type: "NewRcaWorkspace",
    workspaceDetails: {
      workspace: {
        ...templateWorkspace,
        features: [
          ...templateWorkspace.features,
          KnownWorkspaceFeatureEnum.OfflineMaps,
          KnownWorkspaceFeatureEnum.EnableArcgisIntegration,
        ],
      },
      projectVisibility: "Workspace",
    },
    trainees: [],
  };
}

export function blankExistingRcaWorkspace(): ExistingRcaWorkspace {
  return {
    type: "ExistingRcaWorkspace",
    workspaceDetails: {
      workspace: null,
      project: "new",
      projectVisibility: "Workspace",
    },
    trainees: [],
  };
}

export function personName(person: Person) {
  return person.firstName || person.lastName
    ? `${person.firstName} ${person.lastName}`
    : "Unnamed Trainee";
}

export function randomLocalId(): LocalId {
  return unsafeTag(randomUuid());
}

export function blankPerson() {
  return {
    localId: randomLocalId(),
    firstName: "",
    lastName: "",
    email: unsafeEmail(""),
  };
}

export function rcaWorkspaceBillingContactTraineeId(
  rcaWorkspace: RcaWorkspace
): LocalId | null {
  switch (rcaWorkspace.type) {
    case "NewRcaWorkspace":
      switch (rcaWorkspace.billingContact?.type) {
        case "Separate":
          return null;

        case "Trainee":
          return rcaWorkspace.billingContact.traineeId;

        case null:
        case undefined:
          return null;

        default:
          return checkExhausted(rcaWorkspace.billingContact);
      }
    case "ExistingRcaWorkspace":
      return null;
  }
}

export function rcaWorkspaceSeparateBillingContact(
  rcaWorkspace: RcaWorkspace
): Person | null {
  switch (rcaWorkspace.type) {
    case "NewRcaWorkspace":
      switch (rcaWorkspace.billingContact?.type) {
        case "Separate":
          return rcaWorkspace.billingContact.contact;

        case "Trainee":
          return null;

        case null:
        case undefined:
          return null;

        default:
          return checkExhausted(rcaWorkspace.billingContact);
      }
    case "ExistingRcaWorkspace":
      return null;
  }
}
