import {
  AnonymisedUserV2,
  ProjectRef,
  UserRef,
  WorkspaceRef,
} from "@cartographerio/types";
import { ReactElement, useCallback, useMemo, useState } from "react";

import { UserRefField } from "../components/UserRefField";
import { Highlight } from "../hooks/highlight";
import { useFormContext } from "./context/FormContext";

export interface SurveyorFieldProps {
  value?: UserRef | null;
  defaultValue: UserRef | null;
  onChange: (ref: UserRef | null) => void;
  workspace?: WorkspaceRef;
  project?: ProjectRef;
  highlight?: Highlight;
  disabled?: boolean;
}

export function SurveyorField(props: SurveyorFieldProps): ReactElement {
  const {
    value: _value,
    defaultValue = null,
    onChange,
    project: projectRef,
    highlight,
    disabled,
  } = props;

  const { workspaceGraph } = useFormContext();

  const teams = useMemo(
    () =>
      projectRef != null
        ? workspaceGraph.findTeamsByProjectRef(projectRef)
        : workspaceGraph.allTeams(),
    [projectRef, workspaceGraph]
  );

  const userOptions = useMemo((): AnonymisedUserV2[] => {
    const project =
      projectRef != null
        ? workspaceGraph.optFindProjectByRef(projectRef)
        : null;
    return project != null
      ? workspaceGraph.findUsersByProjectId(project.id)
      : workspaceGraph.allUsers();
  }, [projectRef, workspaceGraph]);

  const [stateValue, setValue] = useState(defaultValue);
  const value = _value ?? stateValue;

  const handleChange = useCallback(
    (option: UserRef | null) => {
      onChange(option);
      setValue(option);
    },
    [onChange]
  );

  return (
    <UserRefField
      value={value ?? null}
      onChange={handleChange}
      userOptions={userOptions}
      teams={teams}
      highlight={highlight}
      disabled={disabled}
    />
  );
}
