import { Timestamp } from "@cartographerio/types";
import {
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { useVolatileState } from "../../hooks/useVolatileState";
import Button from "../Button";
import Calendar from "../Calendar";
import Spaced from "../Spaced";

export interface TimestampFieldModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  value: Timestamp | null;
  onChange: (value: Timestamp | null) => void;
  minValue?: Timestamp | null;
  maxValue?: Timestamp | null;
  showTime?: boolean;
  validateTime?: boolean;
}
export default function TimestampFieldModal(
  props: TimestampFieldModalProps
): ReactElement {
  const {
    isOpen,
    onClose,
    title,
    value,
    onChange,
    minValue,
    maxValue,
    showTime = true,
    validateTime = showTime,
  } = props;

  const [localValue, setLocalValue] = useVolatileState(
    useCallback(() => value, [value])
  );

  const handleConfirm = useCallback(() => {
    onChange(localValue);
    onClose();
  }, [onChange, localValue, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title ?? "Select a Date"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Spaced spacing="2">
            <Calendar.Timestamp
              value={value}
              minValue={minValue}
              maxValue={maxValue}
              onChange={setLocalValue}
              width="100%"
              showTime={showTime}
              validateTime={validateTime}
            />
          </Spaced>
        </ModalBody>
        <ModalFooter>
          <HStack spacing="2">
            <Button label="Cancel" onClick={onClose} />
            <Button colorScheme="blue" label="Select" onClick={handleConfirm} />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
