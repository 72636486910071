import {
  InvitationCodeCreateV2,
  namedEmail,
  unsafeEmail,
} from "@cartographerio/types";
import { FormControl } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";

import { invitationCodeErrorKeys } from "../../schema/invitationCode";
import { SubErrors } from "../../schema/rule/errors";
import MessageFormControl from "../components/MessageFormControl";
import TextField from "../components/TextField";
import CardRadioGroup from "./CardRadioGroup";
import CardRadioOption from "./CardRadioOption";
import Checkbox from "./Checkbox";
import Fieldset from "./Fieldset";
import Heading from "./Heading";
import HelpText from "./HelpText";
import ListEditor from "./ListEditor";
import Para from "./Para";
import Spaced from "./Spaced";
import TimestampField from "./TimestampField";

export interface InvitationCodeSettingsEditorProps {
  value: InvitationCodeCreateV2;
  onChange: (value: InvitationCodeCreateV2) => void;
  errors: SubErrors<typeof invitationCodeErrorKeys[0]>;
  disabled: boolean;
}

export default function InvitationCodeSettingsEditor(
  props: InvitationCodeSettingsEditorProps
) {
  const { value, onChange, errors, disabled } = props;

  const approvalType = useMemo(
    () => (value.requireApproval ? "public" : "private"),
    [value.requireApproval]
  );

  const handleApprovalTypeChange = useCallback(
    (type: string) => {
      switch (type) {
        case "public":
          onChange({
            ...value,
            requireApproval: true,
            approvalEmails: value.approvalEmails ?? [
              namedEmail("", unsafeEmail("")),
            ],
          });
          break;

        case "private":
          onChange({ ...value, requireApproval: false });
          break;

        default:
      }
    },
    [onChange, value]
  );

  return (
    <Spaced spacing="8">
      <Fieldset legend="Name">
        <MessageFormControl messages={errors.name}>
          <TextField.String
            value={value.name}
            placeholder="My Invitation Code"
            disabled={disabled}
            onChange={name => onChange?.({ ...value, name })}
          />
        </MessageFormControl>
      </Fieldset>

      <Fieldset legend="Invitation Code Type">
        <CardRadioGroup
          value={approvalType}
          onChange={handleApprovalTypeChange}
        >
          <CardRadioOption value="public">
            <Heading level="subsubsection" m="0">
              Public
            </Heading>
            <Para fontSize="lg">
              Signups must be approved by an administrator
            </Para>
            <Para color="gray.500">
              Select this if you want to share this code on the Internet
            </Para>
          </CardRadioOption>
          <CardRadioOption value="private">
            <Heading level="subsubsection" m="0">
              Private
            </Heading>
            <Para fontSize="lg">
              Signups do <em>not</em> have to be approved
            </Para>
            <Para color="gray.500">
              Select this if you are using the code in private (e.g. workplace
              intranet or training course)
            </Para>
          </CardRadioOption>
        </CardRadioGroup>
      </Fieldset>

      <Fieldset legend="Approval Settings">
        {value.requireApproval && (
          <>
            <MessageFormControl
              label="Approval Emails"
              messages={errors.approvalEmails}
              matchExactPathOnly={true}
            >
              <Para>
                Send approval requests to the following email addresses
              </Para>
            </MessageFormControl>

            <ListEditor.NamedEmail
              value={value.approvalEmails ?? []}
              onChange={approvalEmails =>
                onChange?.({ ...value, approvalEmails })
              }
              messages={errors.approvalEmails}
              disabled={disabled || !value.requireApproval}
              minLength={1}
            />

            <MessageFormControl
              label="Pre-Approved Domains"
              messages={errors.approvalDomainAllowList}
              matchExactPathOnly={true}
            >
              <Para>Skip approval for the following email domains</Para>
            </MessageFormControl>

            <ListEditor.Domain
              value={value.approvalDomainAllowList ?? []}
              onChange={approvalDomainAllowList =>
                onChange?.({ ...value, approvalDomainAllowList })
              }
              messages={errors.approvalDomainAllowList}
              disabled={disabled || !value.requireApproval}
            />
          </>
        )}

        {!value.requireApproval && (
          <Checkbox
            checkboxLabel="Allow the users to log in once without verifying their email address"
            value={value.delayEmailVerification ?? false}
            disabled={disabled}
            onChange={delayEmailVerification =>
              onChange?.({ ...value, delayEmailVerification })
            }
          />
        )}
      </Fieldset>

      <Fieldset legend="Expiry Settings">
        <FormControl>
          <Spaced spacing="2">
            <TimestampField
              label="Expiry Date"
              value={value.expires ?? null}
              onChange={expires => onChange?.({ ...value, expires })}
              disabled={disabled}
              nullable={true}
              placeholder="Select a date/time (or leave blank)"
            />
            <HelpText text={EXPIRY_DATE_HELP} />
          </Spaced>
        </FormControl>
      </Fieldset>
    </Spaced>
  );
}

const EXPIRY_DATE_HELP =
  "Set a date and time after which the code should automatically stop working. Leave blank if the code should never expire.";
