import { Message } from "@cartographerio/types";
import { HStack, IconButton, SystemProps } from "@chakra-ui/react";
import { outdent } from "outdent";
import { ReactElement, useMemo } from "react";
import { IoCloseSharp } from "react-icons/io5";

import { indexedErrors } from "../../../../schema/rule/errors";
import Button from "../../../components/Button";
import Fieldset from "../../../components/Fieldset";
import { blankPerson, setItem } from "./helpers";
import { PersonSection } from "./PersonSection";
import { Person } from "./schema";

interface TraineesSectionProps extends SystemProps {
  value: Person[];
  messages: Message[];
  onChange: (value: Person[]) => void;
}

export function TraineesSection(props: TraineesSectionProps): ReactElement {
  const { value, messages, onChange, ...rest } = props;

  const errors = useMemo(
    () => indexedErrors(messages, value.length),
    [messages, value.length]
  );

  return (
    <Fieldset legend="Trainees To Invite" help={TRAINEES_HELP} {...rest}>
      {value.map((trainee, i) => (
        <HStack key={i} justify="stretch">
          <PersonSection
            value={trainee}
            messages={errors[i]}
            onChange={newTrainee =>
              onChange(setItem(value, i, { ...trainee, ...newTrainee }))
            }
            flexGrow="1"
          />
          <IconButton
            icon={<IoCloseSharp size="1.25rem" />}
            mt="8"
            aria-label="Remove"
            flexGrow="0"
            alignSelf="start"
            onClick={() => onChange(setItem(value, i))}
          />
        </HStack>
      ))}
      <Button
        label="Add Trainee"
        variant="outline"
        w="fit-content"
        onClick={() => onChange([...value, blankPerson()])}
      />
    </Fieldset>
  );
}

const TRAINEES_HELP = outdent`
  Trainees are given *Project Surveyor* role in the new RCA project,
  and *Project Member* role in the MRS Training Examples project.
  They are also given a *Trainee*-level RCA qualification.
`;
