import { SelectOption, SelectValue } from "@cartographerio/atlas-form";
import { checkExhausted } from "@cartographerio/util";
import {
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  SimpleGrid,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";

import { useIsNarrow } from "../contexts/breakpoint";
import { Highlight, useCheckboxHighlight } from "../hooks/highlight";

export interface RadioGroupProps<A extends SelectValue> {
  value: A;
  options: SelectOption<A>[];
  hiddenOptions?: A[];
  noneOption?: A;
  onChange?: (value: A) => void;
  highlight?: Highlight;
  disabled?: boolean;
  appearance?: "standard" | "buttons";
  columns?: 1 | 2 | 3;
}

function format(value: SelectValue): string {
  if (typeof value === "string") {
    return `s:${value}`;
  } else if (typeof value === "number") {
    return `n:${value}`;
  } else if (typeof value === "boolean") {
    return `b:${value}`;
  } else if (value === null) {
    return "null";
  } else {
    return checkExhausted(value);
  }
}

function validate(value: string): SelectValue {
  if (value.startsWith("s:")) {
    return value.substring(2);
  } else if (value.startsWith("n:")) {
    return parseFloat(value.substring(2));
  } else if (value === "b:true") {
    return true;
  } else if (value === "b:false") {
    return false;
  } else {
    return null;
  }
}

export default function RadioGroup<A extends SelectValue>(
  props: RadioGroupProps<A>
): ReactElement {
  const {
    disabled,
    value,
    options,
    hiddenOptions,
    onChange,
    highlight,
    columns,
  } = props;

  const isNarrow = useIsNarrow();

  const handleChange = useCallback(
    (value: string) => {
      onChange?.(validate(value) as A);
    },
    [onChange]
  );

  const visibleOptions = useMemo(
    () =>
      options.filter(
        option =>
          hiddenOptions == null ||
          !hiddenOptions.includes(option.value) ||
          option.value === value
      ),
    [hiddenOptions, options, value]
  );

  const { colorScheme, labelColor } = useCheckboxHighlight(highlight);

  return (
    <ChakraRadioGroup onChange={handleChange} value={format(value)}>
      <SimpleGrid w="100%" rowGap="2" columns={isNarrow ? 1 : columns}>
        {visibleOptions.map(({ label, value }, index) => (
          <ChakraRadio
            key={index}
            isDisabled={disabled}
            value={format(value)}
            colorScheme={colorScheme}
          >
            <chakra.span color={labelColor} fontSize="sm">
              {label}
            </chakra.span>
          </ChakraRadio>
        ))}
      </SimpleGrid>
    </ChakraRadioGroup>
  );
}
