import { Polygon } from "@cartographerio/geometry";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ReactElement, useMemo } from "react";

import BaseGeometryField, { BaseGeometryFieldProps } from "./BaseGeometryField";

type PolygonFieldProps = Omit<BaseGeometryFieldProps<Polygon>, "mapboxDraw">;

export default function PolygonField(props: PolygonFieldProps): ReactElement {
  const mapboxDraw = useMemo(
    () =>
      new MapboxDraw({
        defaultMode: "draw_polygon",
        displayControlsDefault: false,
        touchEnabled: false,
        controls: { polygon: true, trash: true },
      }),
    []
  );

  return <BaseGeometryField {...props} mapboxDraw={mapboxDraw} />;
}
