import { ReactElement, ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useOptAccessToken } from "../contexts/auth";
import { useWindowLocation } from "../hooks/useWindowLocation";
import { routes } from "../routes";

export interface RequireSigninProps {
  go?: string;
  children: ReactNode;
}

function currentLocation(location: Location) {
  return `${location.pathname}${location.search}${location.hash}`;
}

export default function RequireSignin(props: RequireSigninProps): ReactElement {
  const { go: _go, children } = props;

  const accessToken = useOptAccessToken();

  const location = useWindowLocation();
  const go = currentLocation(location);

  return (
    <>
      {accessToken == null ? (
        <Navigate to={routes.signin.url([], { go })} replace={true} />
      ) : (
        <>{children}</>
      )}
    </>
  );
}
