import { RefObject, useEffect, useRef } from "react";

// Code adapted from https://blog.sethcorker.com/resize-observer-api/
export default function useResizeObserver(
  element: RefObject<HTMLElement>,
  callback: (width: number, height: number) => void
): void {
  const observer = useRef(
    new ResizeObserver(entries => {
      // Only care about the first element, we expect one element ot be watched:
      const { width, height } = entries[0].contentRect;
      callback(width, height);
    })
  );

  useEffect(() => {
    const elem = element.current;
    const obs = observer.current;

    if (elem != null && obs != null) {
      obs.observe(elem);
    }

    return () => {
      if (elem != null && obs != null) {
        obs.unobserve(elem);
      }
    };
  }, [element, observer]);
}
