import { SelectOption, SelectValue } from "@cartographerio/atlas-form";
import { Option } from "@cartographerio/fp";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Input,
  InputElementProps,
  InputGroup,
  InputGroupProps,
  InputRightElement,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverContent,
} from "@chakra-ui/react";
import { useCombobox } from "downshift";
import { chain } from "lodash";
import { ReactElement, useCallback, useMemo } from "react";

import { useInputHighlight } from "../../hooks/highlight";
import useDebouncedTextValue from "../../hooks/useDebouncedTextValue";
import useFuse, { FuseOptions } from "../../hooks/useFuse";
import { useInputFocus } from "../../hooks/useInputFocus";
import { Highlighted } from "../Autocomplete/Default";
import { BaseTextFieldProps } from "../TextField/base";

export interface SearchableProps<A extends SelectValue>
  extends BaseTextFieldProps<A>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  options: SelectOption<A>[];
  hiddenOptions?: A[];
  minMatchCharLength?: number | null;
  size?: InputGroupProps["size"];
}

const fuseOptions: FuseOptions = {
  includeMatches: true,
  minMatchCharLength: 1,
};

export default function SearchableSelect<A extends SelectValue>(
  props: SearchableProps<A>
): ReactElement {
  const {
    value,
    defaultValue,
    options,
    hiddenOptions,
    minMatchCharLength,
    size,
    onChange,
    ariaLabel,
    placeholder,
    disabled = false,
    maxLength,
    highlight,
    debounce,
    width,
    ...rest
  } = props;

  const visibleOptions = useMemo(
    () =>
      options.filter(
        option =>
          hiddenOptions == null ||
          !hiddenOptions.includes(option.value) ||
          option.value === value
      ),
    [hiddenOptions, options, value]
  );

  const optionsLookup: Record<string, string> = useMemo(
    () =>
      chain(visibleOptions)
        .map(({ label, value }) => [`${value}`, label])
        .fromPairs()
        .value(),
    [visibleOptions]
  );

  const reverseOptionsLookup: Record<string, A> = useMemo(
    () =>
      chain(visibleOptions)
        .map(({ label, value }) => [label, value])
        .fromPairs()
        .value(),
    [visibleOptions]
  );

  const format = useCallback(
    (value: A) => optionsLookup[`${value}`],
    [optionsLookup]
  );

  const validate = useCallback(
    (label: string) => Option.wrap(reverseOptionsLookup[label]),
    [reverseOptionsLookup]
  );

  const fuseItems = useMemo(
    () =>
      chain(visibleOptions)
        .map(({ label }) => label)
        .value(),
    [visibleOptions]
  );

  const [fuseResults, fuseOnChange] = useFuse(
    fuseItems,
    {
      ...fuseOptions,
      minMatchCharLength: minMatchCharLength ?? fuseOptions.minMatchCharLength,
    },
    maxLength ?? 1000,
    false
  );

  const _handleChange = useCallback(
    (value: string) => {
      validate(value).tap(value => onChange?.(value));
      fuseOnChange(value);
    },
    [fuseOnChange, onChange, validate]
  );

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const {
    textValue,
    localError,
    handleBlur,
    handleFocus,
    handleTextValueChange,
    handleTextChange,
  } = useDebouncedTextValue<string, HTMLInputElement>({
    defaultValue:
      value != null
        ? format(value)
        : defaultValue != null
        ? format(defaultValue)
        : undefined,
    format: value => value,
    validate: value => Option.wrap(value),
    onFocus: _handleFocus,
    onBlur: _handleBlur,
    onChange: _handleChange,
    debounce,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    inputValue: textValue,
    items: fuseResults,
    itemToString: result => result?.item ?? "",
    onInputValueChange: ({ inputValue }) => {
      handleTextValueChange(inputValue ?? "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem != null) {
        _handleChange?.(selectedItem.item);
      }
    },
  });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  const inputProps = getInputProps({
    onBlur: handleBlur,
    onFocus: handleFocus,
    onChange: handleTextChange,
    "aria-label": ariaLabel,
    placeholder,
    disabled,
    maxLength,
  });

  return (
    <Popover isOpen={isOpen} autoFocus={false} placement="bottom-start">
      <PopoverAnchor>
        <InputGroup {...getComboboxProps()} size={size} width={width}>
          <Input
            {...inputProps}
            borderColor={borderColor}
            borderWidth={borderWidth}
            {...rest}
          />
          <InputRightElement alignItems="center" {...getToggleButtonProps()}>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </InputRightElement>
        </InputGroup>
      </PopoverAnchor>
      <PopoverContent boxShadow="lg" maxHeight="40vh" overflowY="auto">
        <List {...getMenuProps()}>
          {fuseResults.length === 0 && (
            <ListItem py="2" px="4">
              No results
            </ListItem>
          )}
          {fuseResults.map((result, index) => (
            <ListItem
              key={index}
              py="1"
              px="4"
              bg={index === highlightedIndex ? "gray.100" : undefined}
              cursor="pointer"
              {...getItemProps({
                item: result,
                index,
              })}
              fontSize={size}
            >
              <Highlighted result={result} />
            </ListItem>
          ))}
        </List>
      </PopoverContent>
    </Popover>
  );
}
