import {
  CartographerSource,
  LocalGeojsonSource,
  MapLayer,
  MapLayerId,
  RemoteGeojsonSource,
  RemoteTileSource,
} from "@cartographerio/atlas-map";
import { Option } from "@cartographerio/fp";
import { namedToOpenInterval, plainDateToDate } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { identity } from "lodash";
import { Map } from "mapbox-gl";
import { MD5 } from "object-hash";
import { ReactElement, useCallback, useMemo } from "react";
import { Source as ReactMapGLSource } from "react-map-gl";
import { useDebouncedCallback } from "use-debounce";

import { MapboxExpr, expr } from "../../mapbox";
import {
  useFilterContext,
  useLayerBuckets,
  useLayerVisibility,
  useNamedInterval,
  useSelectedAttribute,
  useSelectedFeatures,
  useVisibleFeatures,
} from "./AtlasMapContext";
import { TileUrlFunction } from "./helpers";
import { layerPrimaryKeyExpr } from "./layerHelpers";
import LayerView from "./LayerView";
import { useMapEvent } from "./mapboxHelpers";

const UPDATE_VISIBLE_FEATURES_DELAY = 250;

function allFilters(
  ...filters: (MapboxExpr | undefined)[]
): MapboxExpr | undefined {
  const exprs = filters.filter(identity) as MapboxExpr[];
  return exprs.length === 0
    ? undefined
    : exprs.length === 1
    ? exprs[0]
    : expr.all(...exprs);
}

interface ZoomOptions {
  minZoom?: number | null;
  maxZoom?: number | null;
}

interface ZoomProps {
  minzoom?: number;
  maxzoom?: number;
}

function zoomProps(options: ZoomOptions): ZoomProps {
  const ans: ZoomProps = {};

  if (options.minZoom != null) {
    ans.minzoom = options.minZoom;
  }

  if (options.maxZoom != null) {
    ans.maxzoom = options.maxZoom;
  }

  return ans;
}

function useWatchVisibleFeatures(
  layerId: MapLayerId,
  sourceId: string,
  hasSimplified: boolean = false
) {
  const [, setVisibleFeatures] = useVisibleFeatures(layerId);

  const syncUpdateFeatures = useCallback(
    (map: Map, _reason: string) => {
      if (map.isStyleLoaded()) {
        const targetLayers: string[] = hasSimplified
          ? [layerId, `${layerId}/simplified`]
          : [layerId];

        const presentLayers: string[] = map
          .getStyle()
          .layers.map(layer => layer.id);

        const searchableLayers: string[] = targetLayers.filter(l =>
          presentLayers.includes(l)
        );

        const features = map.queryRenderedFeatures(undefined, {
          layers: searchableLayers,
        });

        setVisibleFeatures(features);
      }
    },
    [hasSimplified, layerId, setVisibleFeatures]
  );

  const asyncUpdateFeatures = useDebouncedCallback(
    syncUpdateFeatures,
    UPDATE_VISIBLE_FEATURES_DELAY,
    { leading: true }
  );

  useMapEvent(
    "data",
    useCallback(
      evt => {
        if (evt.sourceId === sourceId && evt.isSourceLoaded) {
          asyncUpdateFeatures.callback(evt.target, "data");
        }
      },
      [asyncUpdateFeatures, sourceId]
    )
  );

  useMapEvent(
    "moveend",
    useCallback(
      evt => {
        asyncUpdateFeatures.callback(evt.target, "moveend");
      },
      [asyncUpdateFeatures]
    )
  );
}

function useNamedIntervalExpr(): MapboxExpr | undefined {
  const [namedInterval] = useNamedInterval();

  return useMemo(() => {
    const openInterval =
      namedInterval != null ? namedToOpenInterval(namedInterval) : null;
    if (openInterval?.from == null && openInterval?.to == null) {
      return undefined;
    } else {
      const from: MapboxExpr | undefined = Option.wrap(openInterval.from)
        .nullMap(plainDateToDate)
        .map(date => expr.gte(expr.get("timestamp"), date.getTime()))
        .getOrUndefined();

      const to: MapboxExpr | undefined = Option.wrap(openInterval.to)
        .nullMap(plainDateToDate)
        .map(date => expr.lte(expr.get("timestamp"), date.getTime()))
        .getOrUndefined();

      return allFilters(from, to);
    }
  }, [namedInterval]);
}

export interface SourceViewProps {
  layer: MapLayer;
  cartographerTileUrl: TileUrlFunction;
}

export default function SourceView(props: SourceViewProps): ReactElement {
  const { layer, cartographerTileUrl } = props;

  const { source } = layer;

  const [visible] = useLayerVisibility(layer.layerId);

  const simplify = useMemo(
    () =>
      layer.type !== "PointLayer" &&
      source.type === "CartographerSource" &&
      source.simplifyZoom != null,
    [layer, source]
  );

  switch (source.type) {
    case "CartographerSource":
      return (
        <>
          <CartographerSourceView
            layer={layer}
            source={source}
            tileUrl={cartographerTileUrl}
            visible={visible}
            simplify={false}
          />
          {simplify && (
            <CartographerSourceView
              layer={layer}
              source={source}
              tileUrl={cartographerTileUrl}
              visible={visible}
              simplify={true}
            />
          )}
        </>
      );

    case "RemoteTileSource":
      return (
        <RemoteTileSourceView layer={layer} source={source} visible={visible} />
      );

    case "RemoteGeojsonSource":
      return (
        <RemoteGeojsonSourceView
          layer={layer}
          source={source}
          visible={visible}
        />
      );

    case "LocalGeojsonSource":
      return (
        <LocalGeojsonSourceView
          layer={layer}
          source={source}
          visible={visible}
        />
      );

    default:
      return checkExhausted(source);
  }
}

interface CartographerSourceViewProps {
  layer: MapLayer;
  source: CartographerSource;
  tileUrl: TileUrlFunction;
  visible: boolean;
  simplify: boolean;
}

function CartographerSourceView(
  props: CartographerSourceViewProps
): ReactElement {
  const { layer, source, tileUrl, visible, simplify } = props;

  const [attribute] = useSelectedAttribute(layer.layerId);
  const buckets = useLayerBuckets(layer.layerId);

  const selectedLayerId = simplify
    ? `${layer.layerId}/selected/simplified`
    : `${layer.layerId}/selected`;

  const everythingLayerId = simplify
    ? `${layer.layerId}/simplified`
    : layer.layerId;

  const sourceId = useMemo(
    () =>
      [
        "cartographer",
        "tile",
        source.layerId,
        source.projects?.join(",") ?? "-",
        source.workspace ?? "-",
        ...(simplify ? ["simplified"] : []),
      ].join("/"),
    [simplify, source.layerId, source.projects, source.workspace]
  );

  const sourceLayerId = "default";

  const simplifyZoom = source.simplifyZoom;

  const sourceTileUrls = useMemo(
    () => [tileUrl(source, simplify ?? false)],
    [simplify, source, tileUrl]
  );

  const namedInterval = useNamedIntervalExpr();

  const { team, hasTeams } = useFilterContext(layer);

  const teamFilter = useMemo(() => {
    if (!hasTeams || team == null) {
      return undefined;
    } else {
      return expr.eql(expr.get("teamId"), team);
    }
  }, [hasTeams, team]);

  const { selectedKeys } = useSelectedFeatures(layer.layerId);

  const selectionFilter = useMemo<MapboxExpr | undefined>(
    () =>
      selectedKeys.length === 0
        ? expr.boolean(false)
        : expr.isIn(layerPrimaryKeyExpr(layer), expr.literal(selectedKeys)),
    [layer, selectedKeys]
  );

  const simplifyFilter = useMemo(
    () =>
      simplifyZoom == null
        ? undefined
        : simplify
        ? expr.lt(expr.zoom(), simplifyZoom)
        : expr.gte(expr.zoom(), simplifyZoom),
    [simplify, simplifyZoom]
  );

  const everythingFilter = useMemo(
    () => allFilters(teamFilter, namedInterval, simplifyFilter),
    [namedInterval, simplifyFilter, teamFilter]
  );

  const selectedFilter = useMemo(
    () => allFilters(everythingFilter, selectionFilter),
    [everythingFilter, selectionFilter]
  );

  // When a feature is selected, we make it invisible in the "everything layer".
  // We don't use the filter property on the layer,
  // because that makes us unable to search for the features using queryFeaturesInRect.
  const invisibilityExpr = useMemo(
    () =>
      selectedKeys.length > 0
        ? expr.isIn(layerPrimaryKeyExpr(layer), expr.literal(selectedKeys))
        : undefined,
    [layer, selectedKeys]
  );

  useWatchVisibleFeatures(layer.layerId, sourceId, layer.type !== "PointLayer");

  return (
    <ReactMapGLSource id={sourceId} type="vector" tiles={sourceTileUrls}>
      <LayerView
        id={selectedLayerId}
        type={layer.type}
        attribute={attribute}
        buckets={buckets}
        sourceId={sourceId}
        sourceLayerId={sourceLayerId}
        visible={visible}
        simplify={simplify}
        defaultZOrder={layer.defaultZOrder}
        filter={selectedFilter}
        markerMode="selected"
        {...zoomProps(source)}
      />
      <LayerView
        id={everythingLayerId}
        beforeId={selectedLayerId}
        type={layer.type}
        attribute={attribute}
        buckets={buckets}
        sourceId={sourceId}
        sourceLayerId={sourceLayerId}
        visible={visible}
        simplify={simplify}
        defaultZOrder={layer.defaultZOrder}
        filter={everythingFilter}
        invisibilityExpr={invisibilityExpr}
      />
    </ReactMapGLSource>
  );
}

interface RemoteTileSourceViewProps {
  layer: MapLayer;
  source: RemoteTileSource;
  visible: boolean;
}

function RemoteTileSourceView(props: RemoteTileSourceViewProps): ReactElement {
  const { layer, source, visible } = props;

  const sourceId = `remote/tile/${source.url}`;

  const sourceLayerId = source.sourceLayer;

  const namedInterval = useNamedIntervalExpr();
  const [attribute] = useSelectedAttribute(layer.layerId);
  const buckets = useLayerBuckets(layer.layerId);

  useWatchVisibleFeatures(layer.layerId, sourceId);

  return (
    <ReactMapGLSource
      id={sourceId}
      type="vector"
      url={source.url}
      {...zoomProps(source)}
    >
      <LayerView
        id={layer.layerId}
        type={layer.type}
        attribute={attribute}
        buckets={buckets}
        sourceId={sourceId}
        sourceLayerId={sourceLayerId}
        defaultZOrder={layer.defaultZOrder}
        filter={namedInterval}
        visible={visible}
        simplify={false}
      />
    </ReactMapGLSource>
  );
}

interface RemoteGeojsonSourceViewProps {
  layer: MapLayer;
  source: RemoteGeojsonSource;
  visible: boolean;
}

function RemoteGeojsonSourceView(
  props: RemoteGeojsonSourceViewProps
): ReactElement {
  const { layer, source, visible } = props;

  const sourceId = `remote/geojson/${source.url}`;

  const namedInterval = useNamedIntervalExpr();
  const [attribute] = useSelectedAttribute(layer.layerId);
  const buckets = useLayerBuckets(layer.layerId);

  return (
    <ReactMapGLSource
      id={sourceId}
      type="geojson"
      data={source.url}
      filter={namedInterval}
      {...zoomProps(source)}
    >
      <LayerView
        id={layer.layerId}
        type={layer.type}
        attribute={attribute}
        buckets={buckets}
        sourceId={sourceId}
        visible={visible}
        simplify={false}
      />
    </ReactMapGLSource>
  );
}

interface LocalGeojsonSourceViewProps {
  layer: MapLayer;
  source: LocalGeojsonSource;
  visible: boolean;
}

function LocalGeojsonSourceView(
  props: LocalGeojsonSourceViewProps
): ReactElement {
  const { layer, source, visible } = props;

  const sourceId = useMemo(
    () => `local/geojson/${MD5(source.data)}`,
    [source.data]
  );

  const namedInterval = useNamedIntervalExpr();
  const [attribute] = useSelectedAttribute(layer.layerId);
  const buckets = useLayerBuckets(layer.layerId);

  useWatchVisibleFeatures(layer.layerId, sourceId);

  return (
    <ReactMapGLSource
      id={sourceId}
      type="geojson"
      data={source.data}
      filter={namedInterval}
      {...zoomProps(source)}
    >
      <LayerView
        id={layer.layerId}
        type={layer.type}
        attribute={attribute}
        buckets={buckets}
        sourceId={sourceId}
        visible={visible}
        simplify={false}
      />
    </ReactMapGLSource>
  );
}
