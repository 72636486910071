import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Collapse,
  Flex,
  Heading,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface SidebarSectionProps {
  title: string;
  collapsible?: boolean;
  defaultIsOpen?: boolean;
  children: ReactNode;
}

export default function SidebarSection(
  props: SidebarSectionProps
): ReactElement {
  const { title, collapsible = false, defaultIsOpen, children } = props;

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });

  return collapsible ? (
    <Box>
      <Button
        variant="unstyled"
        w="100%"
        display="flex"
        justifyContent="space-between"
        onClick={onToggle}
      >
        <Heading
          as="h2"
          ms="3"
          fontSize="sm"
          fontWeight="medium"
          textTransform="uppercase"
          color="gray.400"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          title={title}
        >
          {title}
        </Heading>
        <Icon as={isOpen ? ChevronUpIcon : ChevronDownIcon} me="2" />
      </Button>
      <Collapse in={isOpen} animateOpacity={true}>
        {children}
      </Collapse>
    </Box>
  ) : (
    <Box>
      <Flex h="10" align="center">
        <Heading
          as="h2"
          ms="3"
          fontSize="sm"
          fontWeight="medium"
          textTransform="uppercase"
          color="gray.400"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          title={title}
        >
          {title}
        </Heading>
      </Flex>
      <Box>{children}</Box>
    </Box>
  );
}
