import { Box, Flex, SystemProps, chakra } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import HelpPopover from "./HelpPopover";
import HelpText from "./HelpText";
import Spaced from "./Spaced";

export interface FieldsetProps extends SystemProps {
  legend?: ReactNode;
  help?: string;
  helpAppearance?: "inline" | "popover";
  spacing?: SystemProps["gap"];
  children: ReactNode;
}

export default function Fieldset(props: FieldsetProps): ReactElement {
  const {
    legend,
    help,
    helpAppearance = "inline",
    spacing = "4",
    children,
    ...rest
  } = props;

  return (
    <chakra.fieldset {...rest}>
      <Spaced spacing={spacing}>
        <Spaced spacing="2">
          {legend != null && (
            <chakra.legend fontSize="xl" fontWeight="bold">
              <Flex>
                <Box>{legend}</Box>
                <Spaced spacing={spacing}>
                  {help != null && helpAppearance === "popover" && (
                    <HelpPopover text={help} />
                  )}
                </Spaced>
              </Flex>
            </chakra.legend>
          )}

          {help != null && helpAppearance === "inline" && (
            <HelpText text={help} />
          )}
        </Spaced>

        {children}
      </Spaced>
    </chakra.fieldset>
  );
}
