import { MapLayer } from "@cartographerio/atlas-map";
import { Box, Checkbox, Flex, chakra } from "@chakra-ui/react";
import { MouseEvent, ReactElement, useCallback } from "react";
import { FaChevronRight } from "react-icons/fa";

import Ellipsise from "../../components/Ellipsise";
import {
  useLayerVisibility,
  useLayerVisibilityContext,
  useMapSchema,
  useSelectedAttribute,
  useSelectedLayer,
} from "../AtlasMapContext";
import InspectorButton from "./InspectorButton";
import InspectorMenu from "./InspectorMenu";
import InspectorMenuItem from "./InspectorMenuItem";
import InspectorPanel, { InspectorPanelProps } from "./InspectorPanel";
import InspectorSection from "./InspectorSection";
import InspectorTitle from "./InspectorTitle";

type OnCheckboxClick = (layer: MapLayer, altKey: boolean) => void;
type OnLayerClick = (layer: MapLayer) => void;

export interface LayerMenuPanelProps extends InspectorPanelProps {
  onLayerClick: OnLayerClick;
}

export default function LayerMenuPanel(
  props: LayerMenuPanelProps
): ReactElement {
  const { onLayerClick, ...rest } = props;

  const { title, layers } = useMapSchema();
  const { layerVisibility, setLayerVisibility } = useLayerVisibilityContext();
  const [, setSelectedLayer] = useSelectedLayer();

  const handleCheckboxClick = useCallback<OnCheckboxClick>(
    (layer, altKey) => {
      const visibility = !layerVisibility[layer.layerId];

      setLayerVisibility(layer.layerId, visibility);

      if (altKey) {
        layers
          .filter(l => l.layerId !== layer.layerId)
          .forEach(l => setLayerVisibility(l.layerId, !visibility));
      }
    },
    [layerVisibility, layers, setLayerVisibility]
  );

  const handleLayerClick = useCallback<OnLayerClick>(
    layer => {
      setSelectedLayer(layer);
      onLayerClick?.(layer);
    },
    [onLayerClick, setSelectedLayer]
  );

  return (
    <InspectorPanel {...rest}>
      <InspectorTitle title={title ?? "Map Layers"} />

      <InspectorSection title="Layers">
        <InspectorMenu>
          {layers.map(layer => (
            <LayerItem
              key={layer.layerId}
              layer={layer}
              onCheckboxClick={handleCheckboxClick}
              onLayerClick={handleLayerClick}
            />
          ))}
        </InspectorMenu>
      </InspectorSection>
    </InspectorPanel>
  );
}

interface LayerItemProps {
  layer: MapLayer;
  onCheckboxClick: OnCheckboxClick;
  onLayerClick: OnLayerClick;
}

function LayerItem(props: LayerItemProps): ReactElement {
  const { layer, onCheckboxClick, onLayerClick } = props;

  const [visible] = useLayerVisibility(layer.layerId);
  const [attribute] = useSelectedAttribute(layer.layerId);

  const subtitle =
    attribute != null && attribute.label !== layer.title
      ? attribute.label
      : undefined;

  // We use the click event instead of the change event here because
  // we need to access `evt.altKey` (which isn't available on change events).
  const handleCheckboxClick = useCallback(
    (evt: MouseEvent<HTMLInputElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      onCheckboxClick(layer, false);
    },
    [layer, onCheckboxClick]
  );

  const handleLayerClick = useCallback(
    (evt: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      evt.preventDefault();
      evt.stopPropagation();
      onLayerClick(layer);
    },
    [layer, onLayerClick]
  );

  const checkbox = (
    <Box onClick={handleCheckboxClick}>
      <Checkbox pl="1" isChecked={visible} />
    </Box>
  );

  const button = (
    <InspectorButton
      as={FaChevronRight}
      ariaLabel="Layer Details"
      onClick={handleLayerClick}
    />
  );

  return (
    <InspectorMenuItem
      left={checkbox}
      right={button}
      selectable={true}
      onClick={handleLayerClick}
    >
      <Flex direction="column" minW="0">
        <chakra.div>{layer.title}</chakra.div>
        <Ellipsise fontSize="xx-small" color="grey.500" text={subtitle} />
      </Flex>
    </InspectorMenuItem>
  );
}
