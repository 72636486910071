import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

export interface MapButtonProps extends Omit<IconButtonProps, "aria-label"> {
  icon: ReactElement;
  label: string;
  onClick: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export default function MapButton(props: MapButtonProps): ReactElement {
  const { icon, label, onClick, ...rest } = props;

  const handleClick = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      evt.stopPropagation();
      onClick(evt);
    },
    [onClick]
  );

  return (
    <IconButton
      pointerEvents="all"
      size="sm"
      variant="map"
      className="mapbox-ctrl"
      icon={icon}
      aria-label={label}
      title={label}
      onClick={handleClick}
      {...rest}
    />
  );
}
