import { AnyPathPart, PathParam, QueryParam } from "./base";
import {
  Route,
  RoutePathParams,
  RouteProps,
  RouteQueryProps,
  RouteQueryUpdate,
} from "./Route";

export { pathParam, queryParam } from "./base";
export { Redirect } from "./Redirect";
export type {
  PathParam,
  QueryParam,
  RouteProps,
  RoutePathParams,
  RouteQueryProps,
  RouteQueryUpdate,
};

export function route<P extends AnyPathPart[]>(...parts: P): Route<P> {
  return new Route(parts, {});
}
