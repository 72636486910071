import { CartographerSource } from "@cartographerio/atlas-map";
import { FeatureFormat, endpoints } from "@cartographerio/client";
import {
  BBox,
  Point,
  bboxNe,
  bboxSw,
  commonLocations,
  createPoint,
  formatLatLng,
  point,
} from "@cartographerio/geometry";
import { IO } from "@cartographerio/io";
import {
  BearerAuth,
  MapId,
  MapVisibility,
  PlainDate,
  ProjectRef,
  ProjectV2,
  TeamId,
  WorkspaceRef,
  bearerAuth,
  missingAuthorizationError,
  namedToOpenInterval,
  unsafeProjectAlias,
} from "@cartographerio/types";
import { filterAndMap, raise } from "@cartographerio/util";
import { Box } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";
import { ViewStateChangeEvent } from "react-map-gl";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import BrowserGate from "../../components/BrowserGate";
import { useApiConfig } from "../../contexts/apiConfig";
import { MapboxTokenProvider } from "../../contexts/MapboxToken";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import useMapInventory from "../../hooks/useMapInventory";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { MapEmbedAtlasMapContextProvider } from "../../map";
import { OnNamedIntervalChange } from "../../map/AtlasMapContext";
import { OnBaseChange } from "../../map/AtlasMapContext/BaseStyleContext";
import { OnAttributeSelected } from "../../map/AtlasMapContext/SelectedAttributesContext";
import AtlasMapWithInspector, {
  OnShowInspectorChange,
} from "../../map/AtlasMapWithInspector";
import { DEFAULT_PROMOTE_ID } from "../../map/layerHelpers";
import {
  calcViewState,
  fromCenter,
  fromMapSettings,
  fromMultiLayerSelection,
  useTransformRequestFunction,
} from "../../map/mapHelpers";
import { routes } from "../../routes";
import {
  isCartographerSource,
  layerSupportsDefaultSurveySearch,
} from "../project/ProjectMapPage";

/** Some customers haven't updated their old map embed codes to include project refs.
 *  This lookup function catches a couple of cases where the customer isn't able to easily update their code.
 */
function legacyProjectRef(
  workspaceRef: WorkspaceRef | undefined,
  mapId: MapId
): ProjectRef | null {
  switch (workspaceRef) {
    case "thames21":
      switch (mapId) {
        case "thames21WaterQuality":
        case "thames21ThamesWaterQuality":
          return unsafeProjectAlias("waterquality");
        case "thames21Litter":
          return unsafeProjectAlias("litter");
        default:
          return null;
      }
    case "windermere":
      switch (mapId) {
        case "FbaWindermere":
          return unsafeProjectAlias("windermere");
        default:
          return null;
      }
    case "mrs":
      switch (mapId) {
        case "mrsMorph":
          return unsafeProjectAlias("morphrivers");
        default:
          return null;
      }
    default:
      return null;
  }
}

export default function EmbedMapPage(
  props: RouteProps<typeof routes.embed.map>
): ReactElement {
  const { query, updateQuery } = props;

  const {
    map: mapId = raise<MapId>(new Error("You must specify a map")),
    base: defaultBaseStyleId,
    inspector: defaultShowInspector,
    attribute: defaultSelectedAttribute,
    workspace: workspaceRef,
    project: projectRef = legacyProjectRef(workspaceRef, mapId),
    team: defaultTeamRef,
    scrollwheel: scrollZoom = true,
    center: paramsCenter,
    zoom: paramsZoom,
    when: defaultNamedInterval,
    survey: defaultSurveySelection,
    authorization: accessTokenParam,
    uiembed: paramsUiEmbed,
  } = query;

  const schema = useMapInventory(mapId);

  const apiConfig = useApiConfig();

  const temporaryAccessToken = useSuspenseQueryData(
    queries.when(accessTokenParam == null, () =>
      queries.map.embed.v1.authorize(apiConfig)
    )
  );

  const [auth, mapVisibilityFlag] = useMemo<
    [BearerAuth, MapVisibility | undefined]
  >(
    () =>
      accessTokenParam != null
        ? [bearerAuth(accessTokenParam), undefined]
        : temporaryAccessToken != null
        ? [bearerAuth(temporaryAccessToken), "Public"]
        : raise(missingAuthorizationError()),
    [accessTokenParam, temporaryAccessToken]
  );

  const apiParams = useMemo(() => ({ apiConfig, auth }), [apiConfig, auth]);

  const project =
    useSuspenseQueryData(
      queries.optional(projectRef, projectRef =>
        queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
      )
    ) ?? raise<ProjectV2>(new Error("Please specify a project in the URL"));

  const defaultTeam = useSuspenseQueryData(
    queries.optional(defaultTeamRef, teamRef =>
      queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
    )
  );

  const { token: mapboxToken } = useSuspenseQueryData(
    queries.mapbox.v1.token(apiParams)
  );

  const projectMapSettings = useSuspenseQueryData(
    queries.project.mapSettings.v1.readOrDefault(apiParams, project.id)
  );

  const teamMapSettings = useSuspenseQueryData(
    queries.optional(defaultTeam, ({ id }) =>
      queries.team.mapSettings.v1.readOrNull(apiParams, id)
    )
  );

  const fetchMapSettings = useCallback(
    (teamId: TeamId | null) =>
      teamId == null
        ? IO.pure(projectMapSettings)
        : endpoints.team.mapSettings.v1.readOrNull(apiParams, teamId),
    [apiParams, projectMapSettings]
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const projects = useSuspenseSearchResults(
    queries.project.v2.forWorkspace(apiParams, workspace.id, mapVisibilityFlag)
  );

  const teams = useSuspenseSearchResults(
    queries.team.v2.forWorkspace(apiParams, workspace.id, mapVisibilityFlag)
  );

  const defaultExternalSelection = useSuspenseQueryData({
    ...queries.optional(defaultSurveySelection, survey =>
      queries.map.feature.v2.searchAll(
        apiParams,
        filterAndMap(schema.layers, layer =>
          layerSupportsDefaultSurveySearch(layer) &&
          isCartographerSource(layer.source)
            ? [layer.source.layerId, layer.layerId]
            : null
        ),
        {
          project: [project.id],
          survey,
          promoteId: DEFAULT_PROMOTE_ID,
        }
      )
    ),
    // Prevent this refetching data after it's fetched it the first time.
    // Otherwise we keep resetting the user's selection to this feature set.
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });

  const defaultViewport = useMemo(
    () =>
      calcViewState(
        () =>
          paramsCenter != null &&
          fromCenter(
            paramsCenter,
            paramsZoom ?? commonLocations.greatBritain.zoom
          ),
        () =>
          defaultExternalSelection != null &&
          fromMultiLayerSelection(defaultExternalSelection),
        () =>
          teamMapSettings != null && //
          fromMapSettings(teamMapSettings),
        () => fromMapSettings(projectMapSettings)
      ),
    [
      paramsCenter,
      paramsZoom,
      defaultExternalSelection,
      teamMapSettings,
      projectMapSettings,
    ]
  );

  const transformRequest = useTransformRequestFunction(apiConfig, auth);

  const formatApiUrl = useApiUrlFormatter();

  const cartographerDownloadUrl = useCallback(
    (
      { layerId }: CartographerSource,
      format: FeatureFormat,
      bounds: BBox | null = null,
      from: PlainDate | null = null,
      to: PlainDate | null = null,
      simplify: boolean = false
    ) =>
      formatApiUrl(
        endpoints.map.feature.v2.searchUrl(layerId, {
          project: [project.id],
          workspace: workspaceRef,
          sw: bounds == null ? null : createPoint(bboxSw(bounds)),
          ne: bounds == null ? null : createPoint(bboxNe(bounds)),
          from,
          to,
          format,
          simplify,
        })
      ),
    [formatApiUrl, project.id, workspaceRef]
  );

  const cartographerTileUrl = useCallback(
    ({ layerId }: CartographerSource, simplify: boolean) => {
      return formatApiUrl(
        endpoints.map.feature.v2.tileUrl(
          layerId,
          [project.id],
          workspaceRef,
          simplify
        )
      );
    },
    [formatApiUrl, project.id, workspaceRef]
  );

  const handleBaseChange = useCallback<OnBaseChange>(
    base => {
      updateQuery({ ...query, base });

      // Tell Angular about the change:
      window.parent.postMessage(
        ["QueryParamChanged", "base", encodeURIComponent(base)].join(":"),
        "*"
      );
    },
    [query, updateQuery]
  );

  const handleShowInspectorChange = useCallback<OnShowInspectorChange>(
    inspector => {
      updateQuery({ ...query, inspector });

      postQueryParamChange("inspector", inspector ? "yes" : "no");
    },
    [query, updateQuery]
  );

  const handleAttributeSelected = useCallback<OnAttributeSelected>(
    (_layer, attribute) => {
      updateQuery({
        ...query,
        attribute:
          attribute == null ? undefined : [null, attribute.attributeId],
      });

      postQueryParamChange(
        "attribute",
        encodeURIComponent(attribute == null ? "" : attribute.attributeId)
      );
    },
    [query, updateQuery]
  );

  const handleNamedIntervalChange = useCallback<OnNamedIntervalChange>(
    namedInterval => {
      updateQuery({ ...query, when: namedInterval ?? undefined });

      const { from, to } =
        namedInterval != null
          ? namedToOpenInterval(namedInterval)
          : { from: null, to: null };

      postQueryParamChange("from", from ?? "");
      postQueryParamChange("to", to ?? "");
    },
    [query, updateQuery]
  );

  const handleMoveEnd = useCallback((evt: ViewStateChangeEvent) => {
    postViewportUpdate(
      point(evt.viewState.longitude, evt.viewState.latitude),
      evt.viewState.zoom
    );
  }, []);

  return (
    <Box w="100%" h="100vh">
      <BrowserGate>
        <MapboxTokenProvider token={mapboxToken}>
          <MapEmbedAtlasMapContextProvider
            schema={schema}
            defaultBase={defaultBaseStyleId}
            defaultExternalSelection={defaultExternalSelection ?? undefined}
            defaultSelectedAttribute={defaultSelectedAttribute}
            workspace={workspace}
            project={project}
            projects={projects}
            teams={teams}
            defaultTeam={defaultTeam?.id}
            defaultNamedInterval={defaultNamedInterval}
            fetchMapSettings={fetchMapSettings}
            uiEmbed={paramsUiEmbed}
            onBaseChange={handleBaseChange}
            onAttributeSelected={handleAttributeSelected}
            onNamedIntervalChange={handleNamedIntervalChange}
          >
            <AtlasMapWithInspector
              defaultViewport={defaultViewport}
              defaultShowInspector={defaultShowInspector}
              transformRequest={transformRequest}
              cartographerDownloadUrl={cartographerDownloadUrl}
              cartographerTileUrl={cartographerTileUrl}
              scrollZoom={scrollZoom ?? true}
              onMoveEnd={handleMoveEnd}
              onShowInspectorChange={handleShowInspectorChange}
            />
          </MapEmbedAtlasMapContextProvider>
        </MapboxTokenProvider>
      </BrowserGate>
    </Box>
  );
}

function postViewportUpdate(center: Point, zoom: number): void {
  postQueryParamChange("center", formatLatLng(center, 5, ","));
  postQueryParamChange("zoom", zoom.toFixed(2));
}

function postQueryParamChange(name: string, value: string): void {
  window.parent.postMessage(["QueryParamChanged", name, value].join(":"), "*");
}
