import { MapLayer } from "@cartographerio/atlas-map";
import { NamedInterval, TeamId, internalError } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";

import { useVolatileState } from "../../hooks/useVolatileState";
import { useMapLayers } from "./MapSchemaContext";

export type OnNamedIntervalChange = (filter: NamedInterval | null) => void;

export interface FilterContext {
  hasTeams: boolean;
  team: TeamId | null;
  setTeam: (team: TeamId | null) => void;
  namedInterval: NamedInterval | null;
  setNamedInterval: (filter: NamedInterval | null) => void;
}

export const FilterContext =
  createContext<FilterContext | undefined>(undefined);

export function useFilterContext(layer?: MapLayer): FilterContext {
  const context = useContext(FilterContext);

  const hasTeams = useMemo(
    () =>
      // If all layers don't have teams, one of them definitely will not.
      layer == null || !context?.hasTeams
        ? (context?.hasTeams as boolean)
        : hasLayerGotTeams(layer),
    [context?.hasTeams, layer]
  );

  if (context != null) {
    return { ...context, hasTeams };
  } else {
    return raise(internalError("FilterContext not available"));
  }
}

export function useNamedInterval(): [
  NamedInterval | null,
  (filter: NamedInterval | null) => void
] {
  const context = useContext(FilterContext);

  if (context != null) {
    const { namedInterval, setNamedInterval } = context;
    return [namedInterval, setNamedInterval];
  } else {
    return raise(internalError("FilterContext not available"));
  }
}

interface FilterContextProviderProps {
  defaultTeam?: TeamId;
  defaultNamedInterval?: NamedInterval;
  onNamedIntervalChange?: OnNamedIntervalChange;
  children: ReactNode;
}

export function FilterContextProvider(props: FilterContextProviderProps) {
  const {
    defaultTeam = null,
    defaultNamedInterval = null,
    onNamedIntervalChange,
    children,
  } = props;

  const layers = useMapLayers();

  const hasTeams = useMemo(() => layers.some(hasLayerGotTeams), [layers]);

  const [team, setTeam] = useVolatileState<TeamId | null>(
    useCallback(() => defaultTeam, [defaultTeam])
  );

  const [namedInterval, setNamedInterval] =
    useVolatileState<NamedInterval | null>(
      useCallback(() => defaultNamedInterval, [defaultNamedInterval])
    );

  const context: FilterContext = useMemo(
    () => ({
      team,
      setTeam,
      hasTeams,
      namedInterval,
      setNamedInterval,
    }),
    [namedInterval, hasTeams, setNamedInterval, setTeam, team]
  );

  useEffect(() => {
    onNamedIntervalChange?.(namedInterval ?? null);
  }, [namedInterval, onNamedIntervalChange]);

  return (
    <FilterContext.Provider value={context}>{children}</FilterContext.Provider>
  );
}

function hasLayerGotTeams(layer: MapLayer): boolean {
  return layer.attributes.some(group =>
    group.attributes.some(attr => attr.type === "TeamAttribute")
  );
}
