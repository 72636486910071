import { NamedEmail, namedEmail, unsafeEmail } from "@cartographerio/types";
import { ReactElement } from "react";

import NamedEmailField from "../NamedEmailField";
import { ListEditor, ListEditorProps } from "./ListEditor";

export type NamedEmailListEditorProps = Omit<
  ListEditorProps<NamedEmail>,
  "defaultItem" | "ItemEditor" | "addButtonLabel" | "emptyText"
>;

export function NamedEmailListEditor(
  props: NamedEmailListEditorProps
): ReactElement {
  return (
    <ListEditor
      {...props}
      defaultItem={blankNamedEmail}
      ItemEditor={NamedEmailField}
      addButtonLabel="Add Email"
      emptyText="No email addresses. Click 'Add Email' to add one."
    />
  );
}

export const blankNamedEmail = namedEmail("", unsafeEmail(""));
