import { DynamicValueState } from "@cartographerio/atlas-form";
import { formatTimestamp, isTimestamp } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { Box, FormControl, HStack, VStack } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import FormLabels from "../components/FormLabels";
import HelpText from "../components/HelpText";
import MessageLabel from "../components/MessageLabel";
import { useFormContext } from "./context/FormContext";

export interface DynamicValueProps {
  state: DynamicValueState;
}

export default function DynamicValue(props: DynamicValueProps): ReactElement {
  const {
    state: {
      block: { label, secondaryLabel, help },
      attribute: { valueType, value, error, units, decimalPlaces },
    },
  } = props;

  const { helpVisible } = useFormContext();

  return (
    <FormControl isInvalid={error != null}>
      <VStack align="stretch" spacing="2">
        <FormLabels primary={label} secondary={secondaryLabel} />

        <HStack spacing="2" height="10" px="4" bg="gray.100" rounded="md">
          <Box flexGrow={1}>
            {formatDynamicValue(valueType, value, decimalPlaces)}
          </Box>
          {units && <Box flexGrow={0}>{units}</Box>}
        </HStack>

        {error != null && <MessageLabel level="error" text={error} />}

        {helpVisible && help != null && <HelpText text={help} />}
      </VStack>
    </FormControl>
  );
}

export function formatDynamicValue(
  type: "string" | "number" | "boolean" | "timestamp",
  value: string | number | boolean | null,
  decimalPlaces: number = 2
): ReactNode {
  switch (type) {
    case "string":
      return typeof value === "string" ? value : "-";
    case "number":
      return typeof value === "number" ? value.toFixed(decimalPlaces) : "-";
    case "boolean":
      return value === true ? "Yes" : value === false ? "No" : "-";
    case "timestamp":
      return isTimestamp(value) ? formatTimestamp(value) : "-";
    default:
      return checkExhausted(type);
  }
}
