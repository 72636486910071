import { ProjectSortKey } from "@cartographerio/client";
import {
  MapVisibilityEnum,
  ProjectV2,
  ProjectVisibilityEnum,
  TeamId,
  TeamV2,
  WorkspaceRef,
} from "@cartographerio/types";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { IconButton } from "@chakra-ui/react";
import { IoHomeSharp, IoSettingsSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

import { routes } from "../../routes";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import SecondaryLabel from "../SecondaryLabel";

function col(params: Column<ProjectV2, ProjectSortKey>) {
  return params;
}

const nameColumn = col({
  title: "Name",
  orderBy: "name",
  width: "auto",
  render: project => project.name,
});

const projectVisibilityColumn = col({
  title: "Visibility",
  width: "auto",
  render: project => ProjectVisibilityEnum.labelOf(project.projectVisibility),
});

const mapVisibilityColumn = col({
  title: "Maps",
  width: "auto",
  render: project => MapVisibilityEnum.labelOf(project.mapVisibility),
});

function teamAssociationColumn(
  graph: WorkspaceGraphV2,
  primaryTeamId?: TeamId
) {
  const projectTeams = (project: ProjectV2): TeamV2[] =>
    graph.findTeamsByProjectId(project.id);

  return col({
    title: "Team(s)",
    render: project => {
      const teams = projectTeams(project);

      switch (teams.length) {
        case 0:
          return <span>-</span>;
        case 1:
          return <SecondaryLabel text={teams[0].name} />;
        default: {
          const primary = (
            teams.find(team => team.id === primaryTeamId) ?? teams[0]
          ).name;

          const secondary =
            teams.length === 2
              ? "+1 other team"
              : `+${teams.length - 1} other teams`;

          return <SecondaryLabel text={primary} secondaryText={secondary} />;
        }
      }
    },
  });
}

export function projectColumns(
  graph: WorkspaceGraphV2,
  primaryTeamId: TeamId | undefined
) {
  return [
    nameColumn,
    teamAssociationColumn(graph, primaryTeamId),
    projectVisibilityColumn,
    mapVisibilityColumn,
  ];
}

interface ProjectActionsProps {
  workspace?: WorkspaceRef | null;
}

export function projectActions(
  props: ProjectActionsProps
): ActionsColumn<ProjectV2> | undefined {
  const { workspace } = props;
  return workspace != null
    ? {
        renderButtons: project => [
          <IconButton
            key="home"
            as={Link}
            to={routes.workspace.project.home.url([workspace, project.alias])}
            variant="outline"
            aria-label="Home"
            icon={<IoHomeSharp />}
            onClick={evt => evt.stopPropagation()}
          />,
          <IconButton
            key="settings"
            as={Link}
            to={routes.workspace.project.settings.url([
              workspace,
              project.alias,
            ])}
            variant="outline"
            aria-label="Settings"
            icon={<IoSettingsSharp />}
            onClick={evt => evt.stopPropagation()}
          />,
        ],
      }
    : undefined;
}
