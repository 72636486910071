import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";

import Link, { ExternalLinkProps, InternalLinkProps } from "./Link";

interface IconButtonLinkProps {
  icon?: IconButtonProps["icon"];
  colorScheme?: IconButtonProps["colorScheme"];
  variant?: IconButtonProps["variant"];
  size?: IconButtonProps["size"];
  label: string;
  disabled?: boolean;
}

function ExternalIconButtonLink(
  props: IconButtonLinkProps & Omit<ExternalLinkProps, "children">
): ReactElement {
  const { icon, colorScheme, variant, size, label, disabled, to, ...rest } =
    props;

  return (
    <Link.External
      {...rest}
      to={disabled ? undefined : to}
      color={colorScheme == null ? rest.color : "white"}
    >
      <IconButton
        icon={icon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        aria-label={label}
        title={label}
        isDisabled={disabled}
      />
    </Link.External>
  );
}

/** A link that looks like a button! */
function InternalIconButtonLink(
  props: IconButtonLinkProps & Omit<InternalLinkProps, "children">
): ReactElement {
  const { icon, colorScheme, variant, size, label, disabled, to, ...rest } =
    props;

  return (
    <Link.Internal
      {...rest}
      to={disabled ? undefined : to}
      color={colorScheme == null ? rest.color : "white"}
    >
      <IconButton
        icon={icon}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        aria-label={label}
        title={label}
        isDisabled={disabled}
      />
    </Link.Internal>
  );
}

export default {
  External: ExternalIconButtonLink,
  Internal: InternalIconButtonLink,
};
