import { UserSortKey } from "@cartographerio/client";
import { PermissionCheckRunner, checks } from "@cartographerio/permission";
import {
  ProjectId,
  ProjectRoleName,
  ProjectRoleNameEnum,
  ProjectV2,
  Qualification,
  QualificationId,
  QualificationRoleName,
  QualificationRoleNameEnum,
  RoleV2,
  TeamId,
  TeamRoleName,
  TeamRoleNameEnum,
  TeamV2,
  UserId,
  UserV2,
  WorkspaceId,
  WorkspaceRoleName,
  WorkspaceV2,
  projectRoleNameV2,
  projectRoleV2,
  qualificationRoleName,
  roleProjectIdV2,
  roleQualificationId,
  roleTeamIdV2,
  roleWorkspaceIdV2,
  teamRoleNameV2,
  teamRoleV2,
  workspaceRoleNameV2,
  workspaceRoleV2,
} from "@cartographerio/types";
import { findAndMap, raise } from "@cartographerio/util";
import { IconButton } from "@chakra-ui/react";
import { FaMask } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";

import Link from "../Link";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import SecondaryLabel from "../SecondaryLabel";
import Select from "../Select";
import { workspaceRoleOptions } from "./role";

export type OnUserAction = (user: UserV2) => void;
export type OnUserRoleChange = (
  user: UserV2,
  role: RoleV2,
  include?: boolean
) => void;
export type OnUserQualificationChange = (
  user: UserV2,
  qualificationId: QualificationId,
  roleName: QualificationRoleName | null
) => void;

export function col(params: Column<UserV2, UserSortKey>) {
  return params;
}

export const nameColumn = col({
  title: "Name",
  orderBy: "name",
  width: "auto",
  render: user => {
    const name = `${user.firstName} ${user.lastName}`;
    return name === user.screenName ? (
      name
    ) : (
      <SecondaryLabel text={name} secondaryText={user.screenName} />
    );
  },
});

export const emailColumn = col({
  title: "Email",
  orderBy: "email",
  width: "13ch",
  render: user => (
    <Link.External to={`mailto:${user.email}`}>{user.email}</Link.External>
  ),
});

interface WorkspaceRoleColumnProps {
  workspace: WorkspaceId;
  onRoleChange?: OnUserRoleChange | null;
}

export function workspaceRoleColumn(props: WorkspaceRoleColumnProps) {
  const { workspace, onRoleChange } = props;
  return col({
    title: "Workspace Role",
    render: user => {
      const roleName =
        findAndMap(user.roles, role =>
          roleWorkspaceIdV2(role) === workspace
            ? workspaceRoleNameV2(role)
            : null
        ) ?? raise<WorkspaceRoleName>("Workspace role not found");
      return (
        <Select.Standard
          w="20ch"
          options={workspaceRoleOptions}
          value={roleName}
          onChange={roleName =>
            onRoleChange?.(user, workspaceRoleV2(roleName, workspace), true)
          }
          size="sm"
        />
      );
    },
  });
}

interface ProjectRoleColumnProps {
  project: ProjectId;
  title?: string;
  onRoleChange?: OnUserRoleChange | null;
  toggle?: boolean;
}

export function projectRoleColumn(props: ProjectRoleColumnProps) {
  const {
    project,
    title = "Project Role",
    onRoleChange,
    toggle = false,
  } = props;
  return col({
    title,
    render: user => {
      const role = user.roles.find(role => roleProjectIdV2(role) === project);
      return toggle ? (
        <Select.Nullable
          w="20ch"
          options={ProjectRoleNameEnum.entries}
          value={role != null ? projectRoleNameV2(role) : null}
          onChange={roleName =>
            roleName != null || role != null
              ? onRoleChange?.(
                  user,
                  roleName != null
                    ? projectRoleV2(roleName, project)
                    : (role as RoleV2),
                  roleName != null
                )
              : undefined
          }
          placeholder="No Role"
          size="sm"
          disabled={onRoleChange == null}
        />
      ) : (
        <Select.Standard
          w="20ch"
          options={ProjectRoleNameEnum.entries}
          value={projectRoleNameV2(role as RoleV2) as ProjectRoleName}
          onChange={roleName =>
            onRoleChange?.(user, projectRoleV2(roleName, project), true)
          }
          size="sm"
          disabled={onRoleChange == null}
        />
      );
    },
  });
}

interface TeamRoleColumnProps {
  team: TeamId;
  title?: string;
  onRoleChange?: OnUserRoleChange | null;
  toggle?: boolean;
}

export function teamRoleColumn(props: TeamRoleColumnProps) {
  const { team, title = "Team Role", onRoleChange, toggle = false } = props;

  return col({
    title,
    render: user => {
      const role = user.roles.find(role => roleTeamIdV2(role) === team);

      return toggle ? (
        <Select.Nullable
          w="20ch"
          options={TeamRoleNameEnum.entries}
          value={role != null ? teamRoleNameV2(role) : null}
          onChange={roleName =>
            roleName != null || role != null
              ? onRoleChange?.(
                  user,
                  roleName != null
                    ? teamRoleV2(roleName, team)
                    : (role as RoleV2),
                  roleName != null
                )
              : undefined
          }
          placeholder="No Role"
          size="sm"
          disabled={onRoleChange == null}
        />
      ) : (
        <Select.Standard
          w="20ch"
          options={TeamRoleNameEnum.entries}
          value={teamRoleNameV2(role as RoleV2) as TeamRoleName}
          onChange={roleName =>
            onRoleChange?.(user, teamRoleV2(roleName, team), true)
          }
          size="sm"
          disabled={onRoleChange == null}
        />
      );
    },
  });
}

interface QualificationColumnProps {
  qualification: Qualification;
  onQualificationChange?: OnUserQualificationChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function qualificationRoleColumn(props: QualificationColumnProps) {
  const { qualification, onQualificationChange, permissionCheckPasses } = props;
  return col({
    title: qualification.name,
    render: user => {
      const role = user.qualificationRoles.find(
        role => roleQualificationId(role) === qualification.id
      );
      return (
        <Select.Nullable<QualificationRoleName>
          w="16ch"
          options={QualificationRoleNameEnum.entries.filter(({ value }) =>
            permissionCheckPasses(
              checks.qualification.grant(value, qualification.id)
            )
          )}
          value={role != null ? qualificationRoleName(role) : null}
          onChange={roleName =>
            onQualificationChange?.(user, qualification.id, roleName)
          }
          placeholder="Not granted"
          size="sm"
        />
      );
    },
  });
}

interface SelectedWorkspaceColumnsProps {
  workspaces: WorkspaceV2[];
  selectedWorkspace?: WorkspaceId | null;
  onRoleChange: OnUserRoleChange;
}

export function selectedWorkspaceColumns(props: SelectedWorkspaceColumnsProps) {
  const { workspaces, selectedWorkspace, onRoleChange } = props;

  const workspacesLookup: Record<
    WorkspaceId,
    { workspace: WorkspaceV2; sortIndex: number }
  > = workspaces.reduce(
    (acc, workspace) => ({
      ...acc,
      [workspace.id]: {
        workspace,
        sortIndex: workspaces.reduce(
          (acc, other) => acc + Number(workspace.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantWorkspaces = (roles: RoleV2[]) =>
    roles
      .map(roleWorkspaceIdV2)
      .filter(
        (id): id is WorkspaceId => id != null && workspacesLookup[id] != null
      )
      .sort((a, b) =>
        b === selectedWorkspace ||
        (a !== selectedWorkspace &&
          workspacesLookup[a].sortIndex > workspacesLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => workspacesLookup[id].workspace);

  return workspaces.length > 0
    ? [
        col({
          title: "Workspace",
          render: user => {
            const relevantWorkspaces = getRelevantWorkspaces(user.roles);
            return relevantWorkspaces.length === 0 ? (
              "-"
            ) : relevantWorkspaces.length === 1 ? (
              <span>{relevantWorkspaces[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantWorkspaces[0].name ?? "-"}
                secondaryText={
                  relevantWorkspaces.length === 2
                    ? "+1 other workspace"
                    : `+${relevantWorkspaces.length - 1} other workspaces`
                }
              />
            );
          },
        }),
        col({
          title: "Workspace Role",
          render: user => {
            const relevantWorkspace = getRelevantWorkspaces(user.roles)[0] as
              | WorkspaceV2
              | undefined;
            const role = user.roles.find(
              role => roleWorkspaceIdV2(role) === relevantWorkspace?.id
            );
            return role != null && relevantWorkspace != null ? (
              <Select.Nullable
                w="20ch"
                options={workspaceRoleOptions}
                value={role != null ? workspaceRoleNameV2(role) : null}
                onChange={roleName =>
                  roleName != null || role != null
                    ? onRoleChange?.(
                        user,
                        roleName != null
                          ? workspaceRoleV2(roleName, relevantWorkspace.id)
                          : (role as RoleV2),
                        roleName != null
                      )
                    : undefined
                }
                size="sm"
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

interface SelectedProjectColumnsProps {
  projects: ProjectV2[];
  selectedProject?: ProjectId | null;
  onRoleChange?: OnUserRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedProjectColumns(props: SelectedProjectColumnsProps) {
  const { projects, selectedProject, onRoleChange, permissionCheckPasses } =
    props;

  const projectsLookup: Record<
    ProjectId,
    { project: ProjectV2; sortIndex: number }
  > = projects.reduce(
    (acc, project) => ({
      ...acc,
      [project.id]: {
        project,
        sortIndex: projects.reduce(
          (acc, other) => acc + Number(project.name > other.name),
          0
        ),
      },
    }),
    {}
  );

  const getRelevantProjects = (roles: RoleV2[]) =>
    roles
      .map(roleProjectIdV2)
      .filter((id): id is ProjectId => id != null && projectsLookup[id] != null)
      .sort((a, b) =>
        b === selectedProject ||
        (a !== selectedProject &&
          projectsLookup[a].sortIndex > projectsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => projectsLookup[id].project);

  return projects.length > 0
    ? [
        col({
          title: "Project",
          render: user => {
            const relevantProjects = getRelevantProjects(user.roles);
            return relevantProjects.length === 0 ? (
              "-"
            ) : relevantProjects.length === 1 ? (
              <span>{relevantProjects[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantProjects[0].name ?? "-"}
                secondaryText={
                  relevantProjects.length === 2
                    ? "+1 other project"
                    : `+${relevantProjects.length - 1} other projects`
                }
              />
            );
          },
        }),
        col({
          title: "Project Role",
          render: user => {
            const relevantProject: ProjectV2 | undefined = getRelevantProjects(
              user.roles
            )[0];
            const role = findAndMap(user.roles, role =>
              roleProjectIdV2(role) === relevantProject?.id
                ? projectRoleNameV2(role)
                : null
            );
            const canGrantRole =
              relevantProject != null
                ? permissionCheckPasses(
                    checks.project.grantAccess(relevantProject)
                  )
                : false;
            return role != null && relevantProject != null ? (
              <Select.Standard
                w="20ch"
                options={ProjectRoleNameEnum.entries}
                value={role}
                onChange={roleName =>
                  canGrantRole
                    ? onRoleChange?.(
                        user,
                        projectRoleV2(roleName, relevantProject.id),
                        true
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

interface SelectedTeamColumnsProps {
  teams: TeamV2[];
  selectedTeam?: TeamId | null;
  onRoleChange?: OnUserRoleChange | null;
  permissionCheckPasses: PermissionCheckRunner;
}

export function selectedTeamColumns(props: SelectedTeamColumnsProps) {
  const { teams, selectedTeam, onRoleChange, permissionCheckPasses } = props;

  const teamsLookup: Record<TeamId, { team: TeamV2; sortIndex: number }> =
    teams.reduce(
      (acc, team) => ({
        ...acc,
        [team.id]: {
          team,
          sortIndex: teams.reduce(
            (acc, other) => acc + Number(team.name > other.name),
            0
          ),
        },
      }),
      {}
    );

  const getRelevantTeams = (roles: RoleV2[]) =>
    roles
      .map(roleTeamIdV2)
      .filter((id): id is TeamId => id != null && teamsLookup[id] != null)
      .sort((a, b) =>
        b === selectedTeam ||
        (a !== selectedTeam &&
          teamsLookup[a].sortIndex > teamsLookup[b].sortIndex)
          ? 1
          : -1
      )
      .map(id => teamsLookup[id].team);

  return teams.length > 0
    ? [
        col({
          title: "Team",
          render: user => {
            const relevantTeams = getRelevantTeams(user.roles);
            return relevantTeams.length === 0 ? (
              "-"
            ) : relevantTeams.length === 1 ? (
              <span>{relevantTeams[0].name ?? "-"}</span>
            ) : (
              <SecondaryLabel
                text={relevantTeams[0].name ?? "-"}
                secondaryText={
                  relevantTeams.length === 2
                    ? "+1 other team"
                    : `+${relevantTeams.length - 1} other teams`
                }
              />
            );
          },
        }),
        col({
          title: "Team Role",
          render: user => {
            const relevantTeam = getRelevantTeams(user.roles)[0] as
              | TeamV2
              | undefined;
            const role = user.roles.find(
              role => roleTeamIdV2(role) === relevantTeam?.id
            );
            const canGrantRole =
              relevantTeam != null
                ? permissionCheckPasses(checks.team.grantAccess(relevantTeam))
                : false;
            return role != null && relevantTeam != null ? (
              <Select.Nullable
                w="20ch"
                options={TeamRoleNameEnum.entries}
                value={role != null ? teamRoleNameV2(role) : null}
                onChange={roleName =>
                  roleName != null || role != null
                    ? onRoleChange?.(
                        user,
                        roleName != null
                          ? teamRoleV2(roleName, relevantTeam.id)
                          : (role as RoleV2),
                        roleName != null
                      )
                    : undefined
                }
                size="sm"
                disabled={!canGrantRole}
              />
            ) : (
              "-"
            );
          },
        }),
      ]
    : [];
}

export function actionsColumn(
  currentUserId: UserId,
  onImpersonate?: OnUserAction | null,
  onRemove?: OnUserAction | null
): ActionsColumn<UserV2> {
  return {
    renderButtons: user => [
      user.id !== currentUserId && onImpersonate != null && (
        <IconButton
          key="impersonate"
          variant="outline"
          title="Impersonate"
          aria-label="Impersonate"
          icon={<FaMask />}
          onClick={evt => {
            evt.stopPropagation();
            onImpersonate(user);
          }}
        />
      ),
      onRemove != null && (
        <IconButton
          key="remove user"
          variant="outline"
          title="Remove user"
          aria-label="Remove user"
          icon={<IoCloseSharp size="1.25rem" />}
          onClick={evt => {
            evt.stopPropagation();
            onRemove(user);
          }}
        />
      ),
    ],
  };
}
