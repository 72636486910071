import "mapbox-gl/dist/mapbox-gl.css";

import { Point } from "@cartographerio/geometry";
import {
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import * as React from "react";
import { ReactElement, useCallback, useState } from "react";
import { FaMapMarker, FaMinus, FaPlus, FaSearch } from "react-icons/fa";
import { GiEarthAfricaEurope } from "react-icons/gi";
import { GeolocateControl } from "react-map-gl";

import { Highlight } from "../hooks/highlight";
import { useInputFocus } from "../hooks/useInputFocus";
import { useVolatileState } from "../hooks/useVolatileState";
import CustomControlGroup from "../map/control/CustomControlGroup";
import MapButton from "../map/control/MapButton";
import Button from "./Button";
import PointTextField from "./PointTextField";

export interface FieldMapControlsProps {
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onFindMarker?: () => void;
  showGeolocate?: boolean;
  satelliteView?: boolean;
  onSearchPoint?: (value: Point) => void;
  defaultSearchPoint?: Point | null;
  highlight?: Highlight;
  onSatelliteViewChange?: (value: boolean) => void;
}

export default function FieldMapControls(
  props: FieldMapControlsProps
): ReactElement {
  const {
    onZoomIn,
    onZoomOut,
    onFindMarker,
    showGeolocate = true,
    satelliteView,
    onSearchPoint,
    highlight,
    defaultSearchPoint,
    onSatelliteViewChange,
  } = props;

  const handleZoomInClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onZoomIn?.();
    },
    [onZoomIn]
  );

  const handleZoomOutClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onZoomOut?.();
    },
    [onZoomOut]
  );

  const handleFindMarkerClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onFindMarker?.();
    },
    [onFindMarker]
  );

  const [_focused, _handleFocus, handleBlur] = useInputFocus();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchPoint, setSearchPoint] = useVolatileState<Point | null>(
    useCallback(() => defaultSearchPoint ?? null, [defaultSearchPoint])
  );

  const onGoClick = useCallback(() => {
    if (searchPoint != null) {
      onSearchPoint?.(searchPoint);
    }
    setIsSearching(false);
  }, [onSearchPoint, searchPoint]);

  const handleSatelliteViewClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      onSatelliteViewChange?.(!satelliteView);
    },
    [onSatelliteViewChange, satelliteView]
  );

  return (
    <>
      {showGeolocate && <GeolocateControl style={{ position: "relative" }} />}

      {(onZoomIn != null || onZoomOut != null) && (
        <CustomControlGroup position="top-right">
          {onZoomIn && (
            <MapButton
              icon={<Icon as={FaPlus} />}
              label="Zoom In"
              onClick={handleZoomInClick}
            />
          )}
          {onZoomOut && (
            <MapButton
              icon={<Icon as={FaMinus} />}
              label="Zoom Out"
              onClick={handleZoomOutClick}
            />
          )}
        </CustomControlGroup>
      )}

      {onFindMarker != null && (
        <CustomControlGroup position="top-right">
          <MapButton
            icon={<Icon as={FaMapMarker} />}
            label="Find Marker"
            onClick={handleFindMarkerClick}
          />
        </CustomControlGroup>
      )}

      {onSearchPoint != null && (
        <CustomControlGroup position="top-right">
          <Popover
            isOpen={isSearching}
            onClose={() => setIsSearching(false)}
            onOpen={() => setIsSearching(true)}
            placement="left"
          >
            <PopoverTrigger>
              <IconButton
                size="sm"
                variant="map"
                className="mapbox-ctrl"
                icon={<Icon as={FaSearch} />}
                aria-label="Open Coordinate Search"
                _hover={{ boxShadow: "none", background: "#F2F2F2" }}
              />
            </PopoverTrigger>
            <PopoverContent minWidth={40}>
              <PopoverBody>
                <HStack>
                  <PointTextField
                    value={searchPoint ?? null}
                    size="sm"
                    onChange={setSearchPoint}
                    onBlur={handleBlur}
                    highlight={highlight}
                  />
                  <Button label="Go" onClick={onGoClick} size="sm" />
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </CustomControlGroup>
      )}

      {onSatelliteViewChange != null && (
        <CustomControlGroup position="top-right">
          <MapButton
            icon={<Icon as={GiEarthAfricaEurope} />}
            label={
              satelliteView
                ? "Switch to Terrain View"
                : "Switch to Satellite view"
            }
            onClick={handleSatelliteViewClick}
          />
        </CustomControlGroup>
      )}
    </>
  );
}
