import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import useCodesEnabled from "../../hooks/useCodesEnabled";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRedirectWhen from "../../hooks/useRedirectWhen";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useTeamsEnabled } from "../../hooks/useTeamsEnabled";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationCodeViewPage from "../base/BaseInvitationCodeViewPage";
import TeamPageHeader from "./TeamPageHeader";

export default function TeamInvitationCodeViewPage(
  props: RouteProps<typeof routes.workspace.team.invitation.code.view>
): ReactElement {
  const {
    path: { workspaceRef, teamRef, invitationAlias },
  } = props;

  const apiParams = useApiParams();

  const team = useSuspenseQueryData(
    queries.team.v2.readOrFail(apiParams, teamRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, team.workspaceId)
  );

  useRedirectWhen(!useTeamsEnabled(workspace), () =>
    routes.workspace.home.url([workspace.alias])
  );

  useRedirectWhen(!useCodesEnabled(workspace), () =>
    routes.workspace.team.settings.url([workspace.alias, team.alias])
  );

  useRequirePermission(checks.team.grantAccess(team));

  const defaultCode = useSuspenseQueryData(
    queries.invitation.code.v3.readOrFail(apiParams, invitationAlias)
  );

  usePageTitle(
    useMemo(
      () =>
        [defaultCode.name || "Invitation Code", team.name, workspace.name].join(
          " - "
        ),
      [defaultCode, team.name, workspace.name]
    )
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="teams"
        team={team}
        teamPage="invitation-code"
        invitationCode={defaultCode}
      />
      <TeamPageHeader
        workspace={workspace}
        team={team}
        selected="invitation-code"
      />
      <BaseInvitationCodeViewPage
        asTitle={false}
        backRedirect={routes.workspace.team.invitation.code.list.url([
          workspace.alias,
          team.alias,
        ])}
        defaultCode={defaultCode}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.workspace.team.invitation.code.list.url([
          workspace.alias,
          team.alias,
        ])}
      />
    </>
  );
}
