import { checkExhausted } from "@cartographerio/util";

import { AuthAction } from "./action";
import { AuthState } from "./state";

export function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "StartInitialise":
      return {
        ...state,
        initialised: false,
        loading: true,
        accessTokenData: {
          ...state.accessTokenData,
          effective: null,
        },
        errorMessage: null,
      };

    case "CompleteInitialise":
    case "CompleteSignin":
      return {
        ...state,
        initialised: true,
        loading: false,
        accessTokenData: {
          ...state.accessTokenData,
          effective: action.result.getOrNull(),
        },
        errorMessage: action.result.swap().getOrNull(),
      };

    case "CompleteSignout":
      return {
        ...state,
        initialised: true,
        loading: false,
        accessTokenData: null,
        errorMessage: null,
      };

    case "ChangeIdentity":
      return {
        ...state,
        accessTokenData: {
          effective: action.accessToken,
          real: state.accessTokenData?.effective,
        },
      };

    case "RestoreIdentity":
      return {
        ...state,
        accessTokenData: {
          effective: state.accessTokenData?.real,
          real: null,
        },
      };

    default:
      return checkExhausted(action);
  }
}
