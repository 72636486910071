import { SearchResults } from "@cartographerio/types";
import { QueryKey } from "@tanstack/react-query";

import { UseQueryOpts } from "../../queries";
import { useSuspenseQueryData } from "./useSuspenseQueryData";

export function useSuspenseSearchResults<A, K extends QueryKey = QueryKey>(
  options: UseQueryOpts<SearchResults<A>, K>
): A[];
export function useSuspenseSearchResults<
  A,
  K extends QueryKey = QueryKey,
  B = unknown
>(options: UseQueryOpts<SearchResults<A>, K>, mapper: (value: A[]) => B): B;
export function useSuspenseSearchResults<A, K extends QueryKey = QueryKey>(
  options: UseQueryOpts<SearchResults<A> | null, K>
): A[] | null;
export function useSuspenseSearchResults<
  A,
  K extends QueryKey = QueryKey,
  B = unknown
>(
  options: UseQueryOpts<SearchResults<A> | null, K>,
  mapper: (value: A[]) => B
): B | null;
export function useSuspenseSearchResults<
  A,
  K extends QueryKey = QueryKey,
  B = unknown
>(
  options:
    | UseQueryOpts<SearchResults<A>, K>
    | UseQueryOpts<SearchResults<A> | null, K>,
  mapper?: (value: A[]) => B
): unknown {
  return useSuspenseQueryData(
    options as UseQueryOpts<SearchResults<A> | null, K>,
    data =>
      mapper != null && data?.results != null
        ? mapper(data.results)
        : data?.results ?? null
  );
}
