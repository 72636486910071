import { ReactElement, ReactNode } from "react";

interface TestTargetProps {
  testId: string;
  children?: ReactNode;
}

export default function TestTarget(props: TestTargetProps): ReactElement {
  const { testId, children } = props;
  return <div data-testid={testId}>{children}</div>;
}
