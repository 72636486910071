import { Center, HStack, ThemeTypings } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface MessageBadgeProps {
  errorCount: number;
  infoCount: number;
}

export const errorBackgroundColor: ThemeTypings["colors"] = "red.500";
export const infoBackgroundColor: ThemeTypings["colors"] = "cyan.500";

export default function MessageBadge(props: MessageBadgeProps): ReactElement {
  const { errorCount, infoCount } = props;

  return (
    <HStack>
      {errorCount > 0 && (
        <Center
          background="red.100"
          padding="1"
          fontSize="x-small"
          width="5"
          height="5"
          textColor="white"
          backgroundColor={errorBackgroundColor}
          borderRadius="100%"
        >
          {errorCount}
        </Center>
      )}
      {errorCount === 0 && infoCount > 0 && (
        <Center
          background="red.100"
          padding="1"
          fontSize="x-small"
          width="5"
          height="5"
          textColor="white"
          backgroundColor={infoBackgroundColor}
          borderRadius="100%"
        >
          {infoCount}
        </Center>
      )}
    </HStack>
  );
}
