import {
  CloseButton,
  Icon,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import {
  ForwardedRef,
  ReactElement,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { IoSearch } from "react-icons/io5";
import { useDebouncedCallback } from "use-debounce";

import { useVolatileState } from "../hooks/useVolatileState";

const defaultDebounce = 500;

export interface SearchFieldMethods {
  clear: () => void;
}

export interface SearchFieldProps
  extends Omit<InputGroupProps, "defaultValue" | "onChange" | "placeholder"> {
  defaultValue?: string | null;
  onChange?: (value: string | null) => void;
  placeholder?: string | null;
  debounce?: number;
  inputProps?: InputProps;
}

const SearchField = forwardRef(function SearchField(
  props: SearchFieldProps,
  ref: ForwardedRef<SearchFieldMethods>
): ReactElement {
  const {
    defaultValue,
    onChange,
    placeholder,
    debounce = defaultDebounce,
    inputProps = {},
    ...rest
  } = props;

  const { callback: handleChange, flush: flushChange } = useDebouncedCallback(
    useCallback(
      (value: string) => onChange?.(value === "" ? null : value),
      [onChange]
    ),
    debounce
  );

  const [textValue, setTextValue] = useVolatileState(
    useCallback(() => defaultValue ?? "", [defaultValue])
  );

  const handleTextChange = useCallback(
    (value: string, when: "now" | "later") => {
      setTextValue(value);
      handleChange(value);
      if (when === "now") {
        flushChange();
      }
    },
    [flushChange, handleChange, setTextValue]
  );

  const handleClear = useCallback(() => {
    handleTextChange("", "now");
  }, [handleTextChange]);

  useImperativeHandle(ref, () => ({
    clear: handleClear,
  }));

  return (
    <InputGroup {...rest}>
      <Input
        placeholder={placeholder ?? "Enter search term"}
        background="white"
        value={textValue}
        onChange={evt => handleTextChange(evt.currentTarget.value, "later")}
        onBlur={flushChange}
        {...inputProps}
      />
      {textValue === "" ? (
        <InputRightElement pointerEvents="none" zIndex={0}>
          <Icon as={IoSearch} />
        </InputRightElement>
      ) : (
        <InputRightElement>
          <CloseButton onClick={handleClear} />
        </InputRightElement>
      )}
    </InputGroup>
  );
});

export default SearchField;
