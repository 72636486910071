import { checks } from "@cartographerio/permission";
import {
  ProjectV2,
  SurveyModuleId,
  TeamV2,
  WorkspaceV2,
  emptySearchResults,
} from "@cartographerio/types";
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Portal,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import queries from "../../queries";
import { useApiParams, useCredentialsV2 } from "../contexts/auth";
import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import { useProjectHasTeams } from "../hooks/useProjectHasTeams";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { routes } from "../routes";
import Link from "./Link";

interface CreateSurveyButtonProps {
  workspace: WorkspaceV2;
  project: ProjectV2;
  moduleId: SurveyModuleId;
  disabled?: boolean;
  placement?: PlacementWithLogical;
  children?: ReactNode;
}

export default function CreateSurveyButton(
  props: CreateSurveyButtonProps
): ReactElement {
  const { workspace, project, moduleId, disabled, placement, children } = props;

  const apiParams = useApiParams();
  const { identity } = useCredentialsV2();

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useSuspenseSearchResults(
    queries.cond(
      multiTeam,
      () =>
        queries.team.v2.search(apiParams, {
          project: project.id,
          user: identity.userId,
          role: "Surveyor",
        }),
      () => emptySearchResults<TeamV2>()
    )
  );

  const canCreateWithNoTeam = usePermissionCheckPasses(
    checks.survey.createWithTeam(project, null)
  );

  const numOptions = (canCreateWithNoTeam ? 1 : 0) + teams.length;

  return numOptions === 1 ? (
    <Link.Internal
      disabled={disabled}
      to={routes.workspace.project.survey.create.url(
        [workspace.alias, project.alias, moduleId],
        { team: teams[0]?.alias }
      )}
    >
      {children}
    </Link.Internal>
  ) : (
    <Menu placement={placement}>
      <MenuButton disabled={disabled}>{children}</MenuButton>
      <Portal>
        <MenuList zIndex="popover" fontSize="sm" maxH="30em" overflowY="auto">
          <MenuGroup title="Choose a Team" fontSize="sm" mx="3">
            {canCreateWithNoTeam && (
              <MenuItem
                as={Link.Internal}
                to={routes.workspace.project.survey.create.url([
                  workspace.alias,
                  project.alias,
                  moduleId,
                ])}
              >
                No Team
              </MenuItem>
            )}
            {teams.map(team => (
              <MenuItem
                _hover={{ bg: "gray.100", textDecoration: "none" }}
                key={team.id}
                as={Link.Internal}
                to={routes.workspace.project.survey.create.url(
                  [workspace.alias, project.alias, moduleId],
                  { team: team.alias }
                )}
              >
                {team.name}
              </MenuItem>
            ))}
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
}
