import {
  PartialParams,
  WorkspaceSearchOptionsV2,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  SearchResults,
  UserId,
  WorkspaceRef,
  WorkspaceRoleNameEnum,
  WorkspaceV2,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../base";

export type WorkspaceKey =
  | ["workspace"]
  | ["workspace", "v2", "read", WorkspaceRef]
  | ["workspace", "v2", "search", PartialParams<WorkspaceSearchOptionsV2>];

export function workspaceKey(key: WorkspaceKey): WorkspaceKey {
  return key;
}

export function readOrNull(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceV2 | null, WorkspaceKey> {
  return {
    queryKey: ["workspace", "v2", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.v2
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceV2, WorkspaceKey> {
  return {
    queryKey: ["workspace", "v2", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.v2.readOrFail(apiParams, workspaceRef).unsafeRun(),
  };
}

export function search(
  apiParams: ApiParams,
  options: PartialParams<WorkspaceSearchOptionsV2> = {}
): UseQueryOpts<SearchResults<WorkspaceV2>, WorkspaceKey> {
  return {
    queryKey: ["workspace", "v2", "search", options],
    queryFn: () =>
      endpoints.workspace.v2.search(apiParams, options).unsafeRun(),
  };
}

export function registered(
  apiParams: ApiParams,
  user: UserId,
  options: { includePendingSignups?: boolean } = {}
): UseQueryOpts<SearchResults<WorkspaceV2>, WorkspaceKey> {
  return search(apiParams, {
    user,
    role: WorkspaceRoleNameEnum.Disabled,
    ...options,
  });
}

export function all(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<WorkspaceV2>, WorkspaceKey> {
  return search(apiParams, {});
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspace: WorkspaceV2
) {
  return endpoints.workspace.v2
    .save(apiParams, workspace)
    .tap(_ => queryClient.invalidateQueries(["workspace"]));
}
