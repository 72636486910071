import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  Tab,
  TabProps,
  chakra,
} from "@chakra-ui/react";
import { ReactElement } from "react";

import MenuItemLink from "./MenuItemLink";
import { tabOptionId } from "./PageTabs";
import TabLink from "./TabLink";

interface TabOption<I extends string> {
  id: I;
  label: string;
  to: string;
}

interface PageTabSelectProps<I extends string> extends TabProps {
  id: string;
  label: string;
  options: TabOption<I>[];
  selected?: I;
}

export default function PageTabSelect<T extends string = string>(
  props: PageTabSelectProps<T>
): ReactElement {
  const { id: tabId, label, options, ...rest } = props;

  return options.length === 1 ? (
    <TabLink id={tabId} to={options[0].to} mb={[2, "-2px", "-2px"]}>
      {label}
    </TabLink>
  ) : (
    <Tab as={chakra.div} p="0" mb={[2, "-2px", "-2px"]} {...rest}>
      <Menu>
        <MenuButton
          py="2"
          px="4"
          color="inherit"
          fontWeight="inherit"
          onClick={evt => evt.stopPropagation()}
        >
          {label} <ChevronDownIcon />
        </MenuButton>
        <MenuList p="0" overflow="hidden">
          {options.map(({ id: optionId, to, label }) => (
            <MenuItemLink.Internal
              className={tabOptionId([tabId, optionId])}
              key={optionId}
              to={to}
              whiteSpace="nowrap"
            >
              {label}
            </MenuItemLink.Internal>
          ))}
        </MenuList>
      </Menu>
    </Tab>
  );
}

export function pageTabOption<I extends string>(
  option: TabOption<I>
): TabOption<I> {
  return option;
}
