import {
  SearchResultsFormat,
  SortOrder,
  WorkspaceSortKey,
  endpoints,
} from "@cartographerio/client";
import {
  UserId,
  WorkspaceId,
  WorkspaceRoleName,
  WorkspaceV2,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo } from "react";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import { useApiParams } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import DownloadMenu from "../DownloadMenu";
import SearchField from "../SearchField";
import SearchResultsList from "../SearchResultsList";
import { workspaceActions, workspaceColumns } from "./column";

interface WorkspaceListProps {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<WorkspaceSortKey>;
  user?: UserId | null;
  role?: WorkspaceRoleName | null;
  showFeaturesColumn?: boolean;
  itemLink?: (item: WorkspaceV2) => string;
  onItemClick?: (item: WorkspaceV2) => void;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<WorkspaceSortKey>) => void;
}

export default function WorkspaceList(props: WorkspaceListProps): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order,
    user,
    role,
    showFeaturesColumn = false,
    itemLink,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
  } = props;

  const apiParams = useApiParams();

  const { data, error } = useQuery(
    queries.workspace.v2.search(apiParams, {
      user,
      role,
      q: searchTerm,
      order,
      skip: page * count,
      limit: count,
    })
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.workspace.v2.searchUrl({
          user,
          role,
          q: searchTerm,
          order,
          format,
        })
      ),
    [formatApiUrl, order, role, searchTerm, user]
  );

  const columns = useMemo(
    () => workspaceColumns(showFeaturesColumn),
    [showFeaturesColumn]
  );

  return (
    <>
      <HStack w="100%" justifyContent="stretch">
        <SearchField
          defaultValue={searchTerm ?? ""}
          onChange={onSearchTermChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        <DownloadMenu downloadUrl={downloadUrl} />
      </HStack>
      <SearchResultsList<WorkspaceV2, WorkspaceSortKey, WorkspaceId>
        results={data}
        error={error}
        columns={columns}
        actions={workspaceActions}
        page={page}
        count={count}
        itemLink={itemLink}
        onPageChange={onPageChange}
        order={order}
        onOrderChange={onOrderChange}
        itemKey={recordWithId}
      />
    </>
  );
}
