import { useEffect, useMemo, useState } from "react";

import { GalleryItem } from "../../../galleryItem";

export interface UseGallerySelectionResult {
  loading: boolean;
  index: number | null;
  setIndex: (index: number | null) => void;
  item: GalleryItem | null;
  handleNextClick?: () => void;
  handlePrevClick?: () => void;
}

export function useGallerySelection(
  items: GalleryItem[] | undefined
): UseGallerySelectionResult {
  const [index, setIndex] = useState<number | null>(0);

  useEffect(() => {
    if (items == null) {
      setIndex(null);
    } else {
      setIndex(Math.max(Math.min(index ?? 0, items.length - 1), 0));
    }
  }, [index, items]);

  const item = useMemo(
    () => (index == null ? null : items?.[index] ?? null),
    [index, items]
  );

  const handleNextClick = useMemo(
    () =>
      items != null && items.length > 1
        ? () => {
            const newIndex =
              index == null ? 0 : index >= items.length - 1 ? 0 : index + 1;

            setIndex(newIndex);
          }
        : undefined,
    [index, items]
  );

  const handlePrevClick = useMemo(
    () =>
      items != null && items.length > 1
        ? () => {
            const newIndex =
              index == null ? 0 : index <= 0 ? items.length - 1 : index - 1;

            setIndex(newIndex);
          }
        : undefined,
    [index, items]
  );

  return {
    loading: items == null,
    index,
    setIndex,
    item,
    handleNextClick,
    handlePrevClick,
  };
}
