import { PermissionCheck } from "@cartographerio/types";
import {
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

import { usePermissionCheckPasses } from "../hooks/usePermissionCheckPasses";
import RequirePermission from "./RequirePermission";

interface RequirePermissionPopoverProps {
  check: PermissionCheck;
  failMessage?: ReactNode;
  children: ReactNode | ((passes: boolean) => ReactNode);
}

export default function RequirePermissionPopover(
  props: RequirePermissionPopoverProps
): ReactElement {
  const {
    check,
    failMessage = "You don't have the required permissions",
    children,
  } = props;

  const passes = usePermissionCheckPasses(check);

  const rendered = useMemo(
    () => (typeof children === "function" ? children(passes) : children),
    [children, passes]
  );

  return (
    <RequirePermission
      check={check}
      fallback={
        <Popover autoFocus={false} trigger="hover">
          <PopoverTrigger>
            <Box>{rendered}</Box>
          </PopoverTrigger>
          <PopoverContent w="fit-content" zIndex="popover">
            <PopoverArrow />
            <PopoverBody fontSize="xs" color="gray.500" zIndex="inherit">
              {failMessage}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
    >
      {rendered}
    </RequirePermission>
  );
}
