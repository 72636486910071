import { Dispatch, SetStateAction } from "react";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

import { InitialViewState } from "../map/helpers";

const LOCAL_STORAGE_KEY = "map-viewport";

export function useLocalMapViewport(): InitialViewState | null {
  return useReadLocalStorage<InitialViewState | null>(LOCAL_STORAGE_KEY);
}

export function useLocalMapViewportState(): [
  InitialViewState | null,
  Dispatch<SetStateAction<InitialViewState | null>>
] {
  return useLocalStorage<InitialViewState | null>(LOCAL_STORAGE_KEY, null);
}
