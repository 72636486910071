import {
  Feature,
  Geometry,
  LineString,
  Point,
  Polygon,
  feature,
  lineString,
  polygon,
} from "@cartographerio/geometry";
import { checkExhausted } from "@cartographerio/util";
import * as turf from "@turf/turf";
import { min, minBy } from "lodash";

function distanceToLine(point: Point, geom: LineString): number {
  return turf.pointToLineDistance(point, geom);
}

function distanceToPolygon(point: Point, geom: Polygon): number {
  return (
    (turf.booleanPointInPolygon(point, geom) ? -1 : 1) *
    Math.min(
      ...geom.coordinates.map(line =>
        turf.pointToLineDistance(point, lineString(line))
      )
    )
  );
}

export function distance(point: Point, geom: Geometry): number | undefined {
  switch (geom.type) {
    case "Point":
      return turf.distance(point, geom);
    case "MultiPoint":
      return min(geom.coordinates.map(c => turf.distance(point, c)));
    case "LineString":
      return distanceToLine(point, geom);
    case "MultiLineString":
      return min(
        geom.coordinates.map(l => distanceToLine(point, lineString(l)))
      );
    case "Polygon":
      return distanceToPolygon(point, geom);
    case "MultiPolygon":
      return min(
        geom.coordinates.map(p => distanceToPolygon(point, polygon(p)))
      );
    case "GeometryCollection":
      return min(geom.geometries.map(g => distance(point, g)));
    default:
      return checkExhausted(geom);
  }
}

export function nearestFeature(
  point: Point,
  features: Feature[]
): Feature | undefined {
  return minBy(features, f => distance(point, f.geometry));
}

export function copyFeature({
  id,
  bbox,
  geometry,
  properties,
}: Feature): Feature {
  return feature({ id, bbox, geometry, properties });
}
