import {
  KnownWorkspaceFeatureEnum,
  ProjectV2,
  WorkspaceV2,
} from "@cartographerio/types";
import { useMemo } from "react";

export function useProjectHasTeams(
  workspace: WorkspaceV2,
  project: ProjectV2
): boolean {
  return useMemo(
    () =>
      workspace.features.includes(KnownWorkspaceFeatureEnum.EnableTeams) &&
      project.teamIds.length > 0,
    [project, workspace.features]
  );
}
