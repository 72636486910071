import { SearchResultsFormat } from "@cartographerio/client";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { ReactElement } from "react";
import { BsDownload } from "react-icons/bs";

import Link from "./Link";

export interface DownloadMenuProps {
  icon?: ReactElement;
  label?: string;
  downloadUrl: (format: SearchResultsFormat) => string;
}

export default function DownloadMenu(props: DownloadMenuProps): ReactElement {
  const { icon = <BsDownload />, label = "Download", downloadUrl } = props;

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline"
        leftIcon={icon}
        flexGrow={0}
        flexShrink={0}
      >
        {label}
      </MenuButton>
      <MenuList top="100%">
        <MenuItem>
          <Link.Internal to={downloadUrl("xlsx")}>Excel (XLSX)</Link.Internal>
        </MenuItem>
        <MenuItem>
          <Link.Internal to={downloadUrl("csv")}>
            Comma Separated Values (CSV)
          </Link.Internal>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
