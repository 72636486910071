import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

import Spaced from "../components/Spaced";

interface FrontInfoListProps {
  children: ReactNode;
}

export function FrontInfoList(props: FrontInfoListProps) {
  const { children } = props;

  return <Spaced spacing="4">{children}</Spaced>;
}

interface FrontInfoItemProps {
  label: string;
  children: ReactNode;
}

export function FrontInfoItem(props: FrontInfoItemProps) {
  const { label, children } = props;

  return (
    <Spaced spacing="2">
      <Box
        fontSize="sm"
        fontWeight="bold"
        textTransform="uppercase"
        color="gray.500"
      >
        {label}
      </Box>

      {children}
    </Spaced>
  );
}
