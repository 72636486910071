import { ScreenName, unsafeScreenName } from "@cartographerio/types";
import { checkExhausted, raise } from "@cartographerio/util";

import { Action } from "./action";
import { blankSignup } from "./helpers";
import {
  BaseState,
  SignupDetails,
  State,
  enterCode,
  enterEmail,
  enterPassword,
  needsEmailVerification,
  signup,
} from "./state";

export function reducer(state: State, action: Action): State {
  const baseState: BaseState = {
    email: state.email,
    signin: state.signin,
    loading: state.loading,
  };
  switch (action.type) {
    case "Loading":
      return { ...state, loading: action.loading };

    case "SigninSuccessAction":
      return state.type === "EnterPassword"
        ? {
            ...state,
            credentials: action.credentials,
            codeChallenge: action.codeChallenge,
          }
        : state.type === "Signup"
        ? { ...state, credentials: action.credentials }
        : raise<State>(`Cannot sign in, in ${state.type} state`);

    case "EditSignin":
      return { ...state, signin: { ...state.signin, ...action.signin } };

    case "EditSignup":
      return state.type === "Signup"
        ? { ...state, signup: mergeSignups(state.signup, action.signup) }
        : raise<State>(`Cannot edit signup in ${state.type} state`);

    case "EditEmail":
      return { ...state, email: action.email };

    case "ReenterEmail":
      return enterEmail({
        ...baseState,
        loading: false,
        signin: { ...state.signin, acceptTerms: false, messages: [] },
      });

    case "NextState":
      return action.needsEmailVerification
        ? state.type === "Signup"
          ? needsEmailVerification({
              ...baseState,
              invitation: state.code,
            })
          : raise<State>(`Email verification can only happen after signup`)
        : action.userExists
        ? enterPassword(baseState)
        : action.invitation == null
        ? enterCode(baseState)
        : signup({
            ...baseState,
            signup: blankSignup(),
            code: action.invitation,
          });

    default:
      return checkExhausted(action);
  }
}

function mergeSignups(original: SignupDetails, update: Partial<SignupDetails>) {
  const merged = { ...original, ...update };
  const screenNameMatched =
    original.screenName.trim() ===
    defaultScreenName(original.firstName, original.lastName);
  const screenNameUpdated = original.screenName !== merged.screenName;
  const result =
    screenNameMatched && !screenNameUpdated
      ? {
          ...merged,
          screenName: defaultScreenName(merged.firstName, merged.lastName),
        }
      : merged;

  console.log({
    original,
    update,
    merged,
    screenNameMatched,
    screenNameUpdated,
    result,
  });

  return result;
}

function defaultScreenName(firstName: string, lastName: string): ScreenName {
  return unsafeScreenName(`${firstName} ${lastName}`.trim());
}
