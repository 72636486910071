import { SelectOption } from "@cartographerio/atlas-form";
import { SurveySearchKeyV3 } from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import {
  ProjectV2,
  SurveyStatus,
  SurveySummaryV2,
  SurveyV2,
  TeamV2,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from "@chakra-ui/react";
import { IoDuplicateOutline } from "react-icons/io5";

import RequirePermissionPopover from "../../../components/RequirePermissionPopover";
import {
  ActionsColumn,
  Column,
} from "../../../components/SearchResultsList/column";
import { statusColor } from "../../../components/SurveyStatusBadge";
import ViewOnMap from "../../../components/ViewOnMap";
import {
  SaveOption,
  allSaveOptions,
} from "../../../form/FormTopBar/saveOption";
import SurveyDescriptionCell from "./SurveyDescriptionCell";

function col(params: Column<SurveySummaryV2, SurveySearchKeyV3>) {
  return params;
}

const timestampColumn = col({
  title: "Date",
  orderBy: "timestamp",
  defaultOrder: "desc",
  width: "13ch",
  mobile: false,
  render: survey =>
    survey.data.timestamp == null
      ? null
      : formatTimestamp(survey.data.timestamp, { format: ddmmyyyy }),
});

const descriptionColumn = col({
  title: "Description",
  ellipsis: false,
  orderBy: "description",
  width: "auto",
  render: survey => <SurveyDescriptionCell survey={survey} />,
});

const surveyorColumn = col({
  title: "Surveyor",
  orderBy: "surveyor",
  width: "14ch",
  render: survey =>
    survey.surveyor.screenName ?? survey.surveyor.userId ?? undefined,
});

function teamColumn(teams: TeamV2[]) {
  const teamNames = new Map(teams.map(team => [team.id, team.name]));
  return col({
    title: "Team",
    ellipsis: false,
    orderBy: "team",
    width: "20ch",
    mobile: false,
    render: survey => {
      const name = survey.teamId == null ? null : teamNames.get(survey.teamId);
      return name ?? "(No team)";
    },
  });
}

function statusColumn(
  surveySaveOptions: (survey: SurveyV2) => SelectOption<SurveyStatus>[],
  onStatusChange: (survey: SurveyV2, status: SurveyStatus) => void,
  canUpdateStatus: (survey: SurveyV2) => boolean
) {
  return col({
    title: "Status",
    orderBy: "status",
    width: "20ch",
    mobile: true,
    render: survey => {
      const currentOption = allSaveOptions.find(
        opt => opt.value === survey.status
      ) as SaveOption;
      const _saveOptions = surveySaveOptions(survey);
      const saveOptions = _saveOptions.find(
        opt => opt.value === currentOption.value
      )
        ? _saveOptions
        : [currentOption, ..._saveOptions];
      const disabledOptions = allSaveOptions.filter(({ value }) =>
        saveOptions.every(({ value: otherValue }) => value !== otherValue)
      );
      return canUpdateStatus(survey) && saveOptions.length > 1 ? (
        <Menu placement="bottom-end" matchWidth={true} gutter={4}>
          {({ isOpen }) => (
            <>
              <MenuButton
                w="100%"
                justifyContent="space-between"
                as={Button}
                colorScheme={statusColor(currentOption.value)}
                onClick={evt => evt.stopPropagation()}
                border="1px solid black"
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                textAlign="left"
                variant="outline"
                size="sm"
              >
                {currentOption.label}
              </MenuButton>
              <Portal>
                <MenuList py="0" minW="unset">
                  <MenuOptionGroup
                    defaultValue={survey.status}
                    onChange={status =>
                      onStatusChange(survey, status as SurveyStatus)
                    }
                    type="radio"
                  >
                    {saveOptions.map(({ label, value }) => (
                      <MenuItemOption
                        key={value}
                        value={value}
                        fontSize="sm"
                        onClick={evt => evt.stopPropagation()}
                      >
                        {label}
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                  {disabledOptions.map(({ label, value }, i) => (
                    <MenuItemOption
                      key={value}
                      onClick={evt => evt.stopPropagation()}
                      disabled={true}
                      color="gray.400"
                      fontSize="sm"
                      roundedBottom={
                        i === disabledOptions.length - 1 ? "md" : undefined
                      }
                      cursor="not-allowed"
                      _hover={{ bg: "unset" }}
                    >
                      {label}
                    </MenuItemOption>
                  ))}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      ) : (
        <Box>{currentOption.label}</Box>
      );
    },
  });
}

interface ColumnsOptions {
  teams: TeamV2[];
  surveySaveOptions: (survey: SurveyV2) => SelectOption<SurveyStatus>[];
  onStatusChange: (survey: SurveyV2, status: SurveyStatus) => void;
  canUpdateStatus: (survey: SurveyV2) => boolean;
}

export function surveyListColumns(options: ColumnsOptions) {
  const { teams, surveySaveOptions, onStatusChange, canUpdateStatus } = options;
  return [
    timestampColumn,
    descriptionColumn,
    surveyorColumn,
    ...(teams.length > 0 ? [teamColumn(teams)] : []),
    statusColumn(surveySaveOptions, onStatusChange, canUpdateStatus),
  ];
}

interface SurveyListActionsOptions {
  copySurvey: (survey: SurveyV2) => void;
  viewOnMapOptions: (survey: SurveyV2) => SelectOption<string>[];
  project: ProjectV2;
}

export function surveyListActions(
  options: SurveyListActionsOptions
): ActionsColumn<SurveySummaryV2> {
  const { copySurvey, viewOnMapOptions, project } = options;

  return {
    renderButtons: survey => [
      <RequirePermissionPopover
        key="duplicate"
        check={checks.survey.copy(
          project,
          project,
          survey.teamId ?? null,
          survey.teamId ?? null
        )}
        failMessage={
          <>
            You need to be a <em>surveyor</em> and have access to the survey to
            copy surveys.
          </>
        }
      >
        {passes =>
          passes ? (
            <IconButton
              variant="outline"
              aria-label="Duplicate"
              title="Duplicate Survey"
              icon={<IoDuplicateOutline />}
              onClick={evt => {
                evt.preventDefault();
                evt.stopPropagation();
                copySurvey(survey);
              }}
              isDisabled={!passes}
            />
          ) : null
        }
      </RequirePermissionPopover>,
      <ViewOnMap
        key="view-on-map"
        viewOnMapOptions={viewOnMapOptions(survey)}
        size="sm"
      />,
    ],
  };
}
