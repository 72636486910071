import { isArray } from "@cartographerio/guard";
import {
  Children,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  isValidElement,
  useMemo,
} from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FindableComponent = string | JSXElementConstructor<any>;

export default function useFindNestedChildren(
  children: ReactNode,
  Component: FindableComponent | FindableComponent[]
): ReactElement[] {
  return useMemo(() => {
    const foundChildren: ReactElement[] = [];
    let childStack = Children.toArray(children).reverse();
    const Components = isArray(Component) ? Component : [Component];

    while (childStack.length > 0) {
      const child = childStack.pop();
      if (isValidElement(child)) {
        if (Components.includes(child.type)) {
          foundChildren.push(child);
        } else if ("children" in child.props) {
          childStack = childStack.concat(
            Children.toArray(child.props.children).reverse()
          );
        }
      }
    }

    return foundChildren;
  }, [Component, children]);
}
