import { ListEnabledCheckbox } from "./ListEnabledCheckbox";

export interface DomainListEnabledCheckboxProps {
  checkboxLabel: string;
  value: string[];
  onChange: (value: string[]) => void;
  disabled: boolean;
}

export function DomainListEnabledCheckbox(
  props: DomainListEnabledCheckboxProps
) {
  return <ListEnabledCheckbox {...props} defaultItem="" />;
}
