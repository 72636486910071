import { endpoints } from "@cartographerio/client";
import { ProjectBreadcrumbSearchOptions } from "@cartographerio/client/dist/endpoints/project";
import {
  ApiParams,
  ProjectBreadcrumb,
  ProjectBreadcrumbId,
  SearchResults,
  UserId,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type BreadcrumbKey = [
  "project",
  "breadcrumb",
  "v1",
  "search",
  Partial<ProjectBreadcrumbSearchOptions>
];

export function search(
  apiParams: ApiParams,
  options: Partial<ProjectBreadcrumbSearchOptions>
): UseQueryOpts<SearchResults<ProjectBreadcrumb>, BreadcrumbKey> {
  return {
    queryKey: ["project", "breadcrumb", "v1", "search", options],
    queryFn: () =>
      endpoints.project.breadcrumb.v1.search(apiParams, options).unsafeRun(),
  };
}

export function visible(
  apiParams: ApiParams,
  user: UserId,
  workspace?: WorkspaceRef
): UseQueryOpts<SearchResults<ProjectBreadcrumb>, BreadcrumbKey> {
  return search(apiParams, { user, workspace });
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  id: ProjectBreadcrumbId
) {
  return endpoints.project.breadcrumb.v1
    .remove(apiParams, id)
    .tap(_ => queryClient.invalidateQueries(["project", "breadcrumb"]));
}
