import { Box, Spinner, SystemProps, VStack } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface LoadingPlaceholderProps extends SystemProps {
  label?: string;
}

export default function LoadingPlaceholder(
  props: LoadingPlaceholderProps
): ReactElement {
  const {
    label = "Loading...",
    display = "flex",
    w = "100%",
    h = "100vh",
    justifyContent = "center",
    alignItems = "center",
    ...rest
  } = props;

  return (
    <Box
      display={display}
      w={w}
      h={h}
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...rest}
    >
      <VStack>
        <Spinner color="gray.400" />
        {label && (
          <Box m="2" fontSize="sm" color="gray.400">
            {label}
          </Box>
        )}
      </VStack>
    </Box>
  );
}
