import { Option } from "@cartographerio/fp";
import { ReactElement } from "react";

import { BaseTextAreaProps } from "./base";
import DefaultTextArea from "./Default";

export interface StringTextAreaProps extends BaseTextAreaProps<string> {}

function format(str: string): string {
  return str;
}

function validate(str: string): Option<string> {
  return Option.some(str);
}

export default function StringTextArea(
  props: StringTextAreaProps
): ReactElement {
  return <DefaultTextArea format={format} validate={validate} {...props} />;
}
