import { Box, ChakraProps, chakra } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface SecondaryLabelProps extends ChakraProps {
  text: string;
  secondaryText?: string;
}

export default function SecondaryLabel(
  props: SecondaryLabelProps
): ReactElement {
  const { text, secondaryText, ...rest } = props;
  return (
    <Box>
      {text}
      {secondaryText && (
        <>
          <br />
          <chakra.span
            lineHeight="2em"
            fontSize="xs"
            color="gray.500"
            {...rest}
          >
            {secondaryText}
          </chakra.span>
        </>
      )}
    </Box>
  );
}
