import { permissionCheckPasses } from "@cartographerio/permission";
import {
  PermissionCheck,
  QualificationRole,
  RoleV2,
  UserId,
  createSearchResults,
} from "@cartographerio/types";
import {
  WorkspaceGraphV2,
  workspaceGraphV2,
} from "@cartographerio/workspace-graph";
import { useCallback, useMemo } from "react";

import queries from "../../queries";
import { useApiParams, useOptCredentialsV2 } from "../contexts/auth";
import { useSuspenseQueryData } from "./useSuspenseQueryData";

export type PermissionCheckRunner = (
  check: PermissionCheck,
  userId?: UserId | null,
  roles?: RoleV2[],
  qualificationRoles?: QualificationRole[]
) => boolean;

export default function usePermissionCheckRunner(
  _graph?: WorkspaceGraphV2
): PermissionCheckRunner {
  const apiParams = useApiParams();
  const credentials = useOptCredentialsV2();

  // Defaults to the passed in graph, then tries to fetch the registered workspaces, otherwise null
  const workspaces = useSuspenseQueryData(
    queries.optional(
      _graph?.allWorkspaces(),
      workspaces => queries.constant(createSearchResults(workspaces)),
      () =>
        queries.optional(credentials?.identity.userId, userId =>
          queries.workspace.v2.registered(apiParams, userId)
        )
    )
  )?.results;

  const projects = useSuspenseQueryData(
    queries.optional(
      _graph?.allProjects(),
      projects => queries.constant(createSearchResults(projects)),
      () =>
        queries.optional(credentials?.identity.userId, userId =>
          queries.project.v2.visible(apiParams, userId)
        )
    )
  )?.results;

  const teams = useSuspenseQueryData(
    queries.optional(
      _graph?.allTeams(),
      teams => queries.constant(createSearchResults(teams)),
      () =>
        queries.optional(credentials?.identity.userId, userId =>
          queries.team.v2.visible(apiParams, userId)
        )
    )
  )?.results;

  const graph = useMemo(
    () =>
      workspaces != null && projects != null && teams != null
        ? workspaceGraphV2(workspaces, projects, teams)
        : undefined,
    [projects, teams, workspaces]
  );

  return useCallback(
    (
      check,
      id = credentials?.identity.userId ?? null,
      roles = credentials?.identity.roles ?? [],
      qualificationRoles = credentials?.identity.qualificationRoles ?? []
    ) => permissionCheckPasses(check, { id, roles, qualificationRoles }, graph),
    [credentials?.identity, graph]
  );
}
