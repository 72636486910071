import {
  AttachmentAttribute,
  Attribute,
  Bucket,
  MapLayer,
  MapSource,
  MarkerMode,
  MarkerModeName,
  MarkerPropName,
  PropertyAttribute,
  marker,
} from "@cartographerio/atlas-map";
import { Feature, featureProperty } from "@cartographerio/geometry";
import { MapLayerId, unsafeMapLayerId } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";

import { MapboxArg, MapboxExpr, expr } from "../../mapbox";

type FeatureKey = string | number | null;

export const DEFAULT_PROMOTE_ID = "featureId";

/** Find some kind of react key to use when listing features:
 * @param feature the feature
 * @param primaryKey the primary key attribute specified on the layer
 * @param index the index of the feature in an array of features
 */
export function featureKey(
  feature: Feature,
  primaryKey: string | null | undefined,
  index?: number
): FeatureKey {
  const ans =
    featureProperty(feature, primaryKey ?? DEFAULT_PROMOTE_ID) ??
    feature.id ??
    index ??
    null;

  return ans == null || typeof ans === "string" || typeof ans === "number"
    ? ans
    : null;
}

export function layerPrimaryKey(layer: MapLayer) {
  return (feature: Feature): FeatureKey => {
    return layer.primaryKey == null
      ? defaultPrimaryKey(layer.source)(feature)
      : feature.properties?.[layer.primaryKey];
  };
}

export function layerInteractiveIds(layer: MapLayer): MapLayerId[] {
  return layer.type !== "PointLayer" &&
    layer.source.type === "CartographerSource"
    ? [layer.layerId, unsafeMapLayerId(`${layer.layerId}/simplified`)]
    : [layer.layerId];
}

function defaultPrimaryKey(source: MapSource) {
  return (feature: Feature): FeatureKey => {
    switch (source.type) {
      case "CartographerSource":
        return feature.properties?.[DEFAULT_PROMOTE_ID] ?? null;
      case "RemoteTileSource":
      case "RemoteGeojsonSource":
      case "LocalGeojsonSource":
        return feature.id ?? null;
      default:
        return checkExhausted(source);
    }
  };
}

export function layerPrimaryKeyExpr(layer: MapLayer): MapboxExpr {
  return layer.primaryKey == null
    ? defaultPrimaryKeyExpr(layer.source)
    : expr.get(layer.primaryKey);
}

function defaultPrimaryKeyExpr(source: MapSource) {
  switch (source.type) {
    case "CartographerSource":
      return expr.get(DEFAULT_PROMOTE_ID);
    case "RemoteTileSource":
    case "RemoteGeojsonSource":
    case "LocalGeojsonSource":
      return expr.id();
    default:
      return checkExhausted(source);
  }
}

export function propertyExpr(
  attr: PropertyAttribute | AttachmentAttribute
): MapboxExpr {
  switch (attr?.type) {
    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "SurveyAttribute":
    case "TeamAttribute":
      return expr.get(attr.propertyName);

    case "AttachmentAttribute":
      return expr.concat(
        expr.get(attr.surveyPropertyName),
        expr.string("/"),
        expr.get(attr.folderPropertyName)
      );

    default:
      checkExhausted(attr);
  }
}

export function bucketFilterExpr(
  bucket: Bucket,
  valueExpr: MapboxArg
): MapboxExpr {
  switch (bucket.type) {
    case "LookupBucket": {
      if ((bucket.value ?? null) == null) {
        return expr.any(
          expr.eql(valueExpr, null),
          expr.eql(valueExpr, "null"),
          expr.eql(valueExpr, 0)
        );
      } else {
        return expr.eql(valueExpr, bucket.value ?? null);
      }
    }

    case "RangeBucket": {
      // Guard against using expr.{lt,lte,gt,gte} with a null argument (they all fail with an error).
      const isNumber: MapboxArg = expr.any(
        expr.eql(expr.typeOf(valueExpr), "string"),
        expr.eql(expr.typeOf(valueExpr), "number")
      );

      const minBound: MapboxArg =
        bucket.minValue == null ? true : expr.gte(valueExpr, bucket.minValue);

      const maxBound: MapboxArg =
        bucket.maxValue == null
          ? true
          : bucket.maxInclusive
          ? expr.lte(valueExpr, bucket.maxValue)
          : expr.lt(valueExpr, bucket.maxValue);

      return expr.all(isNumber, minBound, maxBound);
    }

    default:
      return checkExhausted(bucket);
  }
}

// propExpr is undefined if we're looking at a layer whose styling isn't dependent on a property
export function styleExpr<A extends MarkerPropName>(
  attribute: Attribute | undefined,
  buckets: Bucket[],
  styleKey: A,
  mode: MarkerModeName,
  invisibilityExpr?: MapboxExpr
): MapboxExpr | MarkerMode[A] {
  switch (attribute?.type) {
    case "FeatureAttribute":
    case "SurveyAttribute":
    case undefined:
      return buckets.length > 0
        ? buckets[0].marker[mode][styleKey]
        : marker.standard[mode][styleKey];

    case "StringAttribute":
    case "NumberAttribute":
    case "BooleanAttribute":
    case "TimestampAttribute":
    case "TeamAttribute":
    case "AttachmentAttribute": {
      const propExpr = propertyExpr(attribute);

      if (buckets.length === 0) {
        return marker.standard[mode][styleKey];
      } else {
        // Mapbox "match" and "step" expressions don't allow you to match on `null`
        // so we use a "case" expression here (expr.choose() in the code).
        // The type definitions still disallow nulls (hence the cast) but this seems to work.
        const hidePaint: [MapboxExpr, MarkerMode[A]][] =
          invisibilityExpr != null
            ? [[invisibilityExpr, marker.invisible[mode][styleKey]]]
            : [];
        return expr.choose({
          cases: [
            ...hidePaint,
            ...buckets.map<[MapboxExpr, MarkerMode[A]]>(bucket => [
              bucketFilterExpr(bucket, propExpr),
              bucket.marker[mode][styleKey],
            ]),
          ],
          fallback: marker.empty[mode][styleKey],
        });
      }
    }

    default:
      return checkExhausted(attribute);
  }
}
