import { CheckWorkspaceAccessResult } from "@cartographerio/types";
import { Flex } from "@chakra-ui/react";
import { chain } from "lodash";
import { ReactElement, memo, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import recordWithId from "../../util/recordWithId";
import WorkspaceAvatar from "../components/WorkspaceAvatar";
import { useRecentWorkspaces } from "../hooks/useRecentWorkspaces";
import { routes } from "../routes";
import SearchableList, { SearchableListItemProps } from "./SearchableList";
import SecondaryLabel from "./SecondaryLabel";

interface WorkspaceSelectorProps {
  options: CheckWorkspaceAccessResult[];
  showOptionsByDefault?: boolean;
  onSelect?: (access: CheckWorkspaceAccessResult) => void;
  onCancel?: () => void;
}

export default function WorkspaceSelector(
  props: WorkspaceSelectorProps
): ReactElement {
  const { options, showOptionsByDefault, onSelect, onCancel } = props;

  const recentWorkspaces = useRecentWorkspaces();
  const navigate = useNavigate();

  const sortedAccess = useMemo(
    () =>
      chain(options)
        .sortBy(ws =>
          recentWorkspaces.includes(ws.id)
            ? recentWorkspaces.indexOf(ws.id)
            : recentWorkspaces.length
        )
        .value(),
    [options, recentWorkspaces]
  );

  const handleSelect = useCallback(
    (access: CheckWorkspaceAccessResult) => {
      navigate(routes.workspace.home.url([access.alias]));
      onSelect?.(access);
    },
    [navigate, onSelect]
  );

  return (
    <SearchableList
      options={sortedAccess}
      optionMatches={optionMatches}
      formatOption={formatOption}
      ListItem={WorkspaceListItem}
      itemKey={recordWithId}
      onSelect={handleSelect}
      onCancel={onCancel}
      showOptionsByDefault={showOptionsByDefault}
      placeholder="Go to a workspace..."
    />
  );
}

function optionMatches(
  access: CheckWorkspaceAccessResult,
  filter: string
): boolean {
  return (
    access.alias.indexOf(filter) >= 0 ||
    access.name.toLowerCase().indexOf(filter) >= 0
  );
}

function formatOption(access: CheckWorkspaceAccessResult | null): string {
  return access?.name ?? "";
}

const WorkspaceListItem = memo(function WorkspaceListItem(
  props: SearchableListItemProps<CheckWorkspaceAccessResult>
) {
  const { option } = props;

  return (
    <Flex p="2" columnGap="2">
      <WorkspaceAvatar
        workspaceRef={option.alias}
        name={option.name}
        logo={option.logo ?? undefined}
        active={false}
        disabled={option.type !== "WorkspaceAccessGranted"}
      />
      <Flex
        align="center"
        color={
          option.type === "WorkspaceAccessGranted" ? undefined : "gray.400"
        }
      >
        <SecondaryLabel
          text={option.name}
          secondaryText={
            option.type === "WorkspaceAccessGranted"
              ? undefined
              : option.type === "WorkspaceAccessUnapproved"
              ? "Awaiting Approval"
              : option.type === "WorkspaceAccessDisabled"
              ? "Access Disabled"
              : "Access Denied"
          }
          color="inherit"
        />
      </Flex>
    </Flex>
  );
});
