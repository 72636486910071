import { toBBox4 } from "@cartographerio/geometry";
import {
  ProjectMapSettings as MapSettings,
  Message,
} from "@cartographerio/types";
import { Box, FormControl } from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useEffect, useRef, useState } from "react";
import { MapRef } from "react-map-gl";

import BBoxField from "./BBoxField";
import CheckboxPanel from "./CheckboxPanel";
import FormLabel from "./FormLabel";
import HelpText from "./HelpText";
import Spaced from "./Spaced";

type MapSettingsState = MapSettings & {
  override: boolean;
};

interface NullableMapSettingsProps {
  nullable: true;
  onChange: (mapSettings: MapSettings | null) => void;
  messages: Message[];
  initial?: MapSettings | null;
  defaultValue: MapSettings;
  disabled?: boolean;
}

interface NonNullableMapSettingsProps {
  nullable: false;
  onChange: (mapSettings: MapSettings) => void;
  messages: Message[];
  initial: MapSettings;
  defaultValue?: null;
  disabled?: boolean;
}

type MapSettingsProps = NullableMapSettingsProps | NonNullableMapSettingsProps;

export default function MapSettingsEditor(
  props: MapSettingsProps
): ReactElement {
  const { nullable, onChange, initial, defaultValue, disabled } = props;

  function initialState(): MapSettingsState {
    return {
      override: initial != null,
      ...(nullable ? initial ?? defaultValue : initial),
    };
  }

  const [state, setState] = useState<MapSettingsState>(initialState);

  const mapRef = useRef<MapRef | null>(null);

  useEffect(
    () => {
      if (nullable) {
        onChange(state.override ? state : null);
      } else {
        onChange(state);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  return (
    <CheckboxPanel
      checkboxLabel="Override project map settings"
      value={state.override}
      onChange={override => setState({ ...state, override })}
      disabled={disabled}
    >
      <FormControl>
        <FormLabel text="Default Map Area" />
        <Spaced spacing="4">
          <HelpText text={DEFAULT_MAP_BOUNDS_HELP} />
          <Box w="100%">
            <BBoxField
              mapRef={mapRef}
              value={toBBox4(state.defaultBounds)}
              onChange={defaultBounds =>
                defaultBounds != null
                  ? setState({ ...state, defaultBounds })
                  : null
              }
            />
          </Box>
        </Spaced>
      </FormControl>
    </CheckboxPanel>
  );
}

const DEFAULT_MAP_BOUNDS_HELP = outdent`
  Default visible area on maps and survey forms. Click once to start
  drawing the visible area. Click again to anchor the opposite corner of
  the rectangle. Click the trash can icon to clear the selection:
`;
