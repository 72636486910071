import {
  BoxProps,
  Input,
  InputElementProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { ForwardedRef, ReactElement, ReactNode, forwardRef } from "react";

import { useInputHighlight } from "../../hooks/highlight";
import useDebouncedTextValue from "../../hooks/useDebouncedTextValue";
import { useInputFocus } from "../../hooks/useInputFocus";
import { BaseTextFieldProps, MappedTextFieldProps } from "./base";

export interface DefaultTextFieldProps<A>
  extends BaseTextFieldProps<A>,
    MappedTextFieldProps<A>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
}

const DefaultTextField = forwardRef(function DefaultTextField<A>(
  props: DefaultTextFieldProps<A>,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement {
  const {
    defaultValue,
    value,
    onChange,
    ariaLabel,
    placeholder,
    format,
    validate,
    disabled,
    maxLength,
    highlight,
    debounce,
    leftAddon,
    rightAddon,
    width,
    ...rest
  } = props;

  const [focused, _handleFocus, _handleBlur] = useInputFocus();

  const { textValue, localError, handleBlur, handleFocus, handleTextChange } =
    useDebouncedTextValue<A, HTMLInputElement>({
      value,
      defaultValue,
      format,
      validate,
      onFocus: _handleFocus,
      onBlur: _handleBlur,
      onChange,
      debounce,
    });

  const { borderColor, borderWidth } = useInputHighlight(
    localError ? "error" : highlight,
    focused
  );

  return (
    <Addons left={leftAddon} right={rightAddon} width={width}>
      <Input
        ref={ref}
        background="white"
        value={textValue}
        onChange={handleTextChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        aria-label={ariaLabel}
        placeholder={placeholder}
        borderColor={borderColor}
        borderWidth={borderWidth}
        disabled={disabled}
        maxLength={maxLength}
        _hover={{}}
        _focus={{}}
        width={width}
        {...rest}
      />
    </Addons>
  );
});

export default DefaultTextField as <A>(
  props: DefaultTextFieldProps<A> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReactElement;

export interface AddonsProps {
  left?: ReactNode;
  right?: ReactNode;
  width?: BoxProps["width"];
  children: ReactNode;
}

export function Addons(props: AddonsProps): ReactElement {
  const { left, right, width, children } = props;

  return left == null && right == null ? (
    <>{children}</>
  ) : (
    <InputGroup width={width}>
      {left == null ? null : (
        <InputLeftElement w="auto">{left}</InputLeftElement>
      )}
      {children}
      {right == null ? null : (
        <InputRightElement w="auto">{right}</InputRightElement>
      )}
    </InputGroup>
  );
}
