import { Box, SystemProps, VStack } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface InspectorPanelProps extends SystemProps {
  className?: string;
  children?: ReactNode;
}

export default function InspectorPanel(
  props: InspectorPanelProps
): ReactElement {
  const {
    pb = "2",
    bg = "rgba(255, 255, 255, .95)",
    rounded = "4px",
    boxShadow = "0 0 0 2px rgba(0,0,0,.1)",
    overflow = "auto",
    children,
    ...rest
  } = props;

  return (
    <Box
      bg={bg}
      pb={pb}
      rounded={rounded}
      overflow={overflow}
      boxShadow={boxShadow}
      {...rest}
    >
      <VStack spacing="3" w="100%" alignItems="stretch">
        {children}
      </VStack>
    </Box>
  );
}
