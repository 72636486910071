import { PartialParams, endpoints } from "@cartographerio/client";
import { QualificationHistorySearchOptions } from "@cartographerio/client/dist/endpoints/qualification";
import {
  ApiParams,
  CompleteQualificationHistoryEvent,
  Email,
  QualificationAlias,
  QualificationHistoryEvent,
  QualificationId,
  SearchResults,
  UserId,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type QualificationHistoryKey =
  | ["qualification", "history"]
  | [
      "qualification",
      "history",
      "v1",
      "search",
      PartialParams<QualificationHistorySearchOptions> | undefined
    ]
  | [
      "qualification",
      "history",
      "v1",
      "read",
      QualificationId | QualificationAlias,
      UserId | Email
    ]
  | ["qualification", "history", "v1", "readAll", UserId | Email];

export function qualificationHistoryKey(
  key: QualificationHistoryKey
): QualificationHistoryKey {
  return key;
}

export function search(
  apiParams: ApiParams,
  options?: PartialParams<QualificationHistorySearchOptions>
): UseQueryOpts<
  SearchResults<CompleteQualificationHistoryEvent>,
  QualificationHistoryKey
> {
  return {
    queryKey: ["qualification", "history", "v1", "search", options],
    queryFn: () =>
      endpoints.qualification.history.v1.search(apiParams, options).unsafeRun(),
  };
}

export function readOrFail(
  apiParams: ApiParams,
  user: UserId | Email,
  qualification: QualificationId | QualificationAlias
): UseQueryOpts<QualificationHistoryEvent[], QualificationHistoryKey> {
  return {
    queryKey: ["qualification", "history", "v1", "read", qualification, user],
    queryFn: () =>
      endpoints.qualification.history.v1
        .readOrFail(apiParams, user, qualification)
        .unsafeRun(),
  };
}

export function readAll(
  apiParams: ApiParams,
  user: UserId | Email
): UseQueryOpts<
  Record<QualificationId, QualificationHistoryEvent[]>,
  QualificationHistoryKey
> {
  return {
    queryKey: ["qualification", "history", "v1", "readAll", user],
    queryFn: () =>
      endpoints.qualification.history.v1.readAll(apiParams, user).unsafeRun(),
  };
}

export function saveAll(
  queryClient: QueryClient,
  apiParams: ApiParams,
  user: UserId | Email,
  history: Record<QualificationId, QualificationHistoryEvent[]>
) {
  return endpoints.qualification.history.v1
    .saveAll(apiParams, user, history)
    .tap(() => {
      queryClient.invalidateQueries(["qualification", "history"]);
      queryClient.invalidateQueries(["user"]);
    });
}
