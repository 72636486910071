import { Portal } from "@chakra-ui/react";
import { ReactQueryDevtoolsPanel } from "@tanstack/react-query-devtools/build/lib/index.prod.js";
import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";

type Side = "bottom" | "right" | "top" | "left";

interface ReactQueryDevtoolsContext {
  open: boolean;
  position: Side;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setPosition: Dispatch<SetStateAction<Side>>;
}

const ReactQueryDevtoolsContext = createContext<ReactQueryDevtoolsContext>({
  open: false,
  position: "bottom",
  setOpen: () => undefined,
  setPosition: () => undefined,
});

export interface ReactQueryDevtoolsProvider {
  children: ReactNode;
}

export function useReactQueryDevtoolsOpen(): [
  boolean,
  Dispatch<SetStateAction<boolean>>
] {
  const context = useContext(ReactQueryDevtoolsContext);
  return [context.open, context.setOpen];
}

export function useReactQueryDevtoolsPosition(): [
  Side,
  Dispatch<SetStateAction<Side>>
] {
  const context = useContext(ReactQueryDevtoolsContext);
  return [context.position, context.setPosition];
}

const isTrueString = (str: string | null) =>
  str != null && ["y", "yes", "t", "true", "1"].includes(str.toLowerCase());

const MIN_PANEL_SIZE = 70;
export const DEFAULT_PANEL_SIZE = 500;

export function ReactQueryDevtoolsProvider(
  props: ReactQueryDevtoolsProvider
): ReactElement {
  const { children } = props;

  const [searchParams] = useSearchParams();
  const panelRef = useRef<HTMLDivElement>(null);
  const [open, _setOpen] = useState(
    isTrueString(searchParams.get("rqdevtools"))
  );
  const [position, setPosition] = useState<Side>("bottom");
  const [resizing, setResizing] = useState(false);
  const [width, setWidth] = useState(DEFAULT_PANEL_SIZE);
  const [height, setHeight] = useState(DEFAULT_PANEL_SIZE);

  const setOpen = useCallback((open: SetStateAction<boolean>) => {
    _setOpen(open);
  }, []);

  const value = useMemo<ReactQueryDevtoolsContext>(
    () => ({ open, position, setOpen, setPosition }),
    [open, position, setOpen]
  );

  useEffect(() => {
    function toggleReactQueryDevtools() {
      setOpen(open => !open);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).toggleReactQueryDevtools = toggleReactQueryDevtools;
  }, [setOpen]);

  const style = useMemo(
    () =>
      getSidePanelStyle({
        position,
        width,
        height,
        isOpen: open,
        isResizing: resizing,
      }),
    [height, open, position, resizing, width]
  );

  const handleDragStart = (
    panelElement: HTMLDivElement | null,
    startEvent: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (!panelElement) return;
    if (startEvent.button !== 0) return; // Only allow left click for drag
    const isVertical = isVerticalSide(position);
    setResizing(true);

    const { height, width } = panelElement.getBoundingClientRect();
    const startX = startEvent.clientX;
    const startY = startEvent.clientY;
    let newSize = 0;

    const run = (moveEvent: MouseEvent) => {
      // prevent mouse selecting stuff with mouse drag
      moveEvent.preventDefault();

      // calculate the correct size based on mouse position and current panel position
      // hint: it is different formula for the opposite sides
      if (isVertical) {
        newSize =
          width +
          (position === "right"
            ? startX - moveEvent.clientX
            : moveEvent.clientX - startX);
        setWidth(newSize);
      } else {
        newSize =
          height +
          (position === "bottom"
            ? startY - moveEvent.clientY
            : moveEvent.clientY - startY);
        setHeight(newSize);
      }

      if (newSize < MIN_PANEL_SIZE) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    };

    const unsub = () => {
      if (resizing) {
        setResizing(false);
      }

      document.removeEventListener("mousemove", run, false);
      document.removeEventListener("mouseUp", unsub, false);
    };

    document.addEventListener("mousemove", run, false);
    document.addEventListener("mouseup", unsub, false);
  };

  return (
    <ReactQueryDevtoolsContext.Provider value={value}>
      {children}
      <Portal>
        <ReactQueryDevtoolsPanel
          ref={panelRef}
          style={style}
          position={position}
          onPositionChange={setPosition}
          showCloseButton
          isOpen={open}
          setIsOpen={setOpen}
          onDragStart={evt => handleDragStart(panelRef.current, evt)}
        />
      </Portal>
    </ReactQueryDevtoolsContext.Provider>
  );
}

function isVerticalSide(side: Side) {
  return ["left", "right"].includes(side);
}

function getOppositeSide(side: Side): Side {
  switch (side) {
    case "bottom":
      return "top";
    case "right":
      return "left";
    case "top":
      return "bottom";
    case "left":
      return "right";
  }
}

// Lifted from react-query-devtools/src/utils.ts,
// because for some reason relying on that file directly causes a webpack error

export interface SidePanelStyleOptions {
  /**
   * Position of the panel
   * Defaults to 'bottom'
   */
  position?: Side;
  /**
   * Staring height for the panel, it is set if the position is horizontal eg 'top' or 'bottom'
   * Defaults to 500
   */
  height?: number;
  /**
   * Staring width for the panel, it is set if the position is vertical eg 'left' or 'right'
   * Defaults to 500
   */
  width?: number;
  /**
   * Sets the correct transition and visibility styles
   */
  isOpen?: boolean;
  /**
   * If the panel is resizing set to true to apply the correct transition styles
   */
  isResizing?: boolean;
  /**
   * Extra panel style passed by the user
   */
  panelStyle?: React.CSSProperties;
}

function getSidePanelStyle({
  position = "bottom",
  height,
  width,
  isOpen,
  isResizing,
  panelStyle,
}: SidePanelStyleOptions): React.CSSProperties {
  const oppositeSide = getOppositeSide(position);
  const borderSide = getSidedProp("border", oppositeSide);
  const isVertical = isVerticalSide(position);

  return {
    ...panelStyle,
    direction: "ltr",
    position: "fixed",
    [position]: 0,
    [borderSide]: `1px solid #333`,
    transformOrigin: oppositeSide,
    boxShadow: "0 0 20px rgba(0,0,0,.3)",
    zIndex: 99999,
    // visibility will be toggled after transitions, but set initial state here
    visibility: isOpen ? "visible" : "hidden",
    ...(isResizing
      ? {
          transition: `none`,
        }
      : { transition: `all .2s ease` }),
    ...(isOpen
      ? {
          opacity: 1,
          pointerEvents: "all",
          transform: isVertical
            ? `translateX(0) scale(1)`
            : `translateY(0) scale(1)`,
        }
      : {
          opacity: 0,
          pointerEvents: "none",
          transform: isVertical
            ? `translateX(15px) scale(1.02)`
            : `translateY(15px) scale(1.02)`,
        }),
    ...(isVertical
      ? {
          top: 0,
          height: "100vh",
          maxWidth: "90%",
          width:
            typeof width === "number" && width >= MIN_PANEL_SIZE
              ? width
              : DEFAULT_PANEL_SIZE,
        }
      : {
          left: 0,
          width: "100%",
          maxHeight: "90%",
          height:
            typeof height === "number" && height >= MIN_PANEL_SIZE
              ? height
              : DEFAULT_PANEL_SIZE,
        }),
  };
}

function getSidedProp<T extends string>(prop: T, side: Side) {
  return `${prop}${
    side.charAt(0).toUpperCase() + side.slice(1)
  }` as `${T}${Capitalize<Side>}`;
}
