import { endpoints } from "@cartographerio/client";
import { Result } from "@cartographerio/fp";
import { IO } from "@cartographerio/io";
import {
  AccessToken,
  ApiConfig,
  ApiParams,
  AuthError,
  CheckResetPasswordTokenResponse,
  CheckSigninEmailResponse,
  CheckWorkspaceAccessResult,
  CredentialsV2,
  Email,
  ForgotPasswordRequest,
  Message,
  ResetPasswordRequest,
  ResetPasswordResult,
  ResetPasswordToken,
  SearchResults,
  SigninRequestV2,
  SigninResponseV2,
  UserId,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { Dispatch } from "react";

import { AuthAction, restoreIdentityAction } from "../../ui/contexts/auth";
import { UseQueryOpts } from "../base";
import { MINUTES } from "../helpers";

export type AuthKey =
  | ["auth"]
  | ["auth", "v2", "readAttempt", AccessToken]
  | ["auth", "v2", "checkSigninEmail", Email]
  | ["auth", "v2", "listWorkspaceAccess"]
  | ["auth", "v2", "readWorkspaceAccess", WorkspaceRef];

export function readAttempt(
  apiConfig: ApiConfig,
  accessToken: AccessToken
): UseQueryOpts<Result<AuthError, CredentialsV2>, AuthKey> {
  return {
    queryKey: ["auth", "v2", "readAttempt", accessToken],
    queryFn: () =>
      endpoints.auth.v2.readAttempt(apiConfig, accessToken).unsafeRun(),
    // We aggressively ask the server for new credentials...
    staleTime: 1 * MINUTES,
    // ...and we never drop credentials from the cache...
    cacheTime: Infinity,
    // ...which allows us to aggressively cache the old credentials object whenever the result from the server is unchanged
    structuralSharing: (oldData, newData) =>
      oldData != null && isEqual(oldData.union(), newData.union())
        ? oldData
        : newData,
  };
}

export function changeIdentity(
  queryClient: QueryClient,
  apiParams: ApiParams,
  userId: UserId
): IO<SigninResponseV2> {
  return endpoints.auth.v2
    .changeIdentity(apiParams, userId)
    .tap(() => queryClient.invalidateQueries([]));
}

export function restoreIdentity(
  queryClient: QueryClient,
  authDispatch: Dispatch<AuthAction>
): IO<void> {
  return IO.pure(authDispatch(restoreIdentityAction())).tap(() =>
    queryClient.invalidateQueries([])
  );
}

export function checkSigninEmail(
  apiConfig: ApiConfig,
  email: Email
): UseQueryOpts<CheckSigninEmailResponse, AuthKey> {
  return {
    queryKey: ["auth", "v2", "checkSigninEmail", email],
    queryFn: () =>
      endpoints.auth.v2.checkSigninEmail(apiConfig, email).unsafeRun(),
  };
}

export function listWorkspaceAccess(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<CheckWorkspaceAccessResult>, AuthKey> {
  return {
    queryKey: ["auth", "v2", "listWorkspaceAccess"],
    queryFn: () => endpoints.auth.v2.listWorkspaceAccess(apiParams).unsafeRun(),
  };
}

export function readWorkspaceAccess(
  apiParams: ApiParams,
  workspace: WorkspaceRef
): UseQueryOpts<CheckWorkspaceAccessResult, AuthKey> {
  return {
    queryKey: ["auth", "v2", "readWorkspaceAccess", workspace],
    queryFn: () =>
      endpoints.auth.v2.readWorkspaceAccess(apiParams, workspace).unsafeRun(),
  };
}

export function checkPasswordToken(
  apiConfig: ApiConfig,
  token: ResetPasswordToken
): IO<CheckResetPasswordTokenResponse> {
  return endpoints.auth.v2.checkPasswordToken(apiConfig, token);
}

export function signin(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  body: SigninRequestV2
): IO<Result<Message[], SigninResponseV2>> {
  return endpoints.auth.v2
    .signin(apiConfig, body)
    .tap(() => queryClient.invalidateQueries([]));
}

export function forgotPassword(
  apiConfig: ApiConfig,
  request: ForgotPasswordRequest
): IO<void> {
  return endpoints.auth.v2.forgotPassword(apiConfig, request);
}

export function resetPassword(
  queryClient: QueryClient,
  apiConfig: ApiConfig,
  request: ResetPasswordRequest
): IO<ResetPasswordResult> {
  return endpoints.auth.v2
    .resetPassword(apiConfig, request)
    .tap(() => queryClient.invalidateQueries(["auth"]));
}
