import { Box, Flex, Spinner } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface LoadingSpinnerProps {
  //
}

export default function LoadingSpinner(
  _props: LoadingSpinnerProps
): ReactElement {
  return (
    <Flex
      position="relative"
      direction="column"
      justify="center"
      align="center"
      width="40"
      height="40"
      borderWidth="1px"
      borderStyle="dashed"
      borderColor="gray.300"
      rounded="md"
      shadow="sm"
    >
      <Spinner mt="2" color="gray.500" />
      <Box mt="2">Loading</Box>
    </Flex>
  );
}
