import { MapboxAccessToken, bearerAuth } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { ReactElement, ReactNode, createContext, useContext } from "react";

import queries from "../../queries";
import { useSuspenseQuery } from "../hooks/useSuspenseQuery";
import { useApiConfig } from "./apiConfig";
import { useOptAccessToken } from "./auth";

/* This file provides a mechanism for issuing Mapbox tokens to maps in the UI.
 *
 * It can work in either of two ways:
 *
 * - EITHER create a <MapboxTokenProvider>, pass in a token explicitly,
 *   and call the useMapBoxToken() within its logical extent.
 *
 * - OR don't use <MapboxTokenProvider>, in which case useMapboxToken()
 *   will retrieve the user's credentials from AuthContext
 *   and ask the Cartographer API to issue a temporary token.
 *
 * We use the former of these approaches on <EmbedMapPage>
 * and the latter more widely in the UI.
 */

const MapboxTokenContext = createContext<MapboxAccessToken | null>(null);

export function useMapboxToken(): MapboxAccessToken {
  const apiConfig = useApiConfig();
  const accessToken = useOptAccessToken();
  const auth = accessToken != null ? bearerAuth(accessToken) : null;

  // Get a token from <MapboxTokenProvider>:
  const token = useContext(MapboxTokenContext);

  // Get a temporary token from the Cartographer API:
  const { data, error } = useSuspenseQuery(
    queries.optional(
      // Skip this query if we have a token from <MapboxTokenProvider>...
      token == null &&
        // Skip this query if we're not authorised...
        auth != null
        ? auth
        : null,
      auth => queries.mapbox.v1.token({ apiConfig, auth })
    )
  );

  return (
    token ??
    data?.token ??
    raise(error ?? new Error("Mapbox access token not initialised"))
  );
}

interface MapboxTokenProviderProps {
  token: MapboxAccessToken;
  children: ReactNode;
}

export function MapboxTokenProvider(
  props: MapboxTokenProviderProps
): ReactElement {
  const { token, children } = props;
  return (
    <MapboxTokenContext.Provider value={token}>
      {children}
    </MapboxTokenContext.Provider>
  );
}
