import { IO } from "@cartographerio/io";
import { check, checks } from "@cartographerio/permission";
import {
  InvitationV2,
  WorkspaceRef,
  canCancelInvitation,
  canResendInvitation,
} from "@cartographerio/types";
import { WorkspaceGraphV2 } from "@cartographerio/workspace-graph";
import { ButtonGroup, Flex, useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useState } from "react";
import {
  IoCloseSharp,
  IoSendSharp,
  IoStopCircleOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { useIOConfirm, useIOErrorAlert } from "../../components/Alert";
import BackButtonHeading from "../../components/BackButtonHeading";
import Button from "../../components/Button";
import InvitationEditor from "../../components/InvitationEditor";
import { InvitationStatusBadge } from "../../components/InvitationStatusBadge";
import PageContainer from "../../components/PageContainer";
import PageHeader from "../../components/PageHeader";
import RequirePermission from "../../components/RequirePermission";
import SaveButton from "../../components/SaveButton";
import Spaced from "../../components/Spaced";
import WithPermission from "../../components/WithPermission";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";

type BaseInvitationViewPageProps = {
  defaultInvitation: InvitationV2;
  defaultWorkspace?: WorkspaceRef;
  workspaceGraph: WorkspaceGraphV2;
  onSaveGo?: string;
  onDeleteGo?: string;
} & ({ asTitle: true } | { asTitle: false; backRedirect: string });

export default function BaseInvitationViewPage(
  props: BaseInvitationViewPageProps
): ReactElement {
  const {
    asTitle,
    defaultInvitation,
    defaultWorkspace,
    workspaceGraph,
    onSaveGo,
    onDeleteGo,
  } = props;

  const navigate = useNavigate();
  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const errorAlert = useIOErrorAlert();
  const toast = useToast();

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const [invitation, setInvitation] = useState(defaultInvitation);

  const handleSave = useCallback(() => {
    queries.invitation.v3
      .save(queryClient, apiParams, invitation.id, invitation)
      .flatMap(IO.fromResult)
      .tap(_ => (onSaveGo == null ? null : navigate(onSaveGo)))
      .tap(_ => toast({ status: "success", description: "Saved" }))
      .tapError(errorAlert)
      .unsafeRun();
  }, [
    apiParams,
    errorAlert,
    invitation,
    navigate,
    onSaveGo,
    queryClient,
    toast,
  ]);

  return (
    <PageContainer width="narrow">
      <Spaced spacing="8">
        <Spaced spacing="4">
          {asTitle ? (
            <PageHeader
              title={`Invitation for ${defaultInvitation.firstName} ${defaultInvitation.lastName}`}
            />
          ) : (
            <BackButtonHeading to={props.backRedirect}>
              Invitation for {defaultInvitation.firstName}{" "}
              {defaultInvitation.lastName}
            </BackButtonHeading>
          )}

          <Flex justify="flex-end" align="center" gap="2">
            <InvitationStatusBadge invitation={defaultInvitation} />
            <InvitationButtons
              invitation={defaultInvitation}
              onDeleteGo={onDeleteGo}
            />
          </Flex>
        </Spaced>

        <WithPermission check={checks.invitation.update(defaultInvitation)}>
          {canEdit => (
            <>
              <InvitationEditor
                value={invitation}
                onChange={setInvitation}
                defaultWorkspace={defaultWorkspace}
                workspaceGraph={workspaceGraph}
                qualifications={qualifications}
                disabled={!canEdit}
              />
              <Flex justifyContent="space-between">
                <SaveButton
                  onClick={handleSave}
                  messages={[]}
                  disabled={!canEdit}
                />
              </Flex>
            </>
          )}
        </WithPermission>
      </Spaced>
    </PageContainer>
  );
}

interface InvitationButtonProps {
  invitation: InvitationV2;
  onDeleteGo?: string;
}

function InvitationButtons(props: InvitationButtonProps): ReactElement {
  const { invitation, onDeleteGo } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const errorAlert = useIOErrorAlert();
  const confirm = useIOConfirm();
  const navigate = useNavigate();

  const onResend = useCallback(
    () =>
      queries.invitation.v3
        .resend(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation resent",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, invitation.id, queryClient, toast]
  );

  const onCancel = useCallback(
    () =>
      queries.invitation.v3
        .cancel(queryClient, apiParams, invitation.id)
        .tap(() =>
          toast({
            title: "Invitation cancelled",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [apiParams, errorAlert, invitation.id, queryClient, toast]
  );

  const onRemove = useCallback(
    () =>
      confirm({
        title: "Delete Invitation",
        message: "Are you sure?",
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.invitation.v3
                .remove(queryClient, apiParams, invitation.id)
                .tap(() =>
                  toast({
                    title: "Invitation Deleted",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                )
                .tap(() => (onDeleteGo != null ? navigate(onDeleteGo) : null))
            : IO.noop()
        )
        .tapError(errorAlert)
        .unsafeRun(),
    [
      apiParams,
      confirm,
      errorAlert,
      invitation.id,
      navigate,
      onDeleteGo,
      queryClient,
      toast,
    ]
  );

  return (
    <ButtonGroup>
      {canResendInvitation(invitation) && (
        <Button
          label="Resend"
          leftIcon={<IoSendSharp />}
          variant="outline"
          onClick={onResend}
        />
      )}
      {canCancelInvitation(invitation) && (
        <Button
          label="Cancel"
          leftIcon={<IoStopCircleOutline size="1.25rem" />}
          variant="outline"
          onClick={onCancel}
        />
      )}
      <RequirePermission check={check.superuser}>
        <Button
          label="Delete"
          leftIcon={<IoCloseSharp size="1.25rem" />}
          variant="outline"
          onClick={onRemove}
        />
      </RequirePermission>
    </ButtonGroup>
  );
}
