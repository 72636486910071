import { unsafeMapLayerId } from "@cartographerio/types";
import { TransformRequestFunction } from "mapbox-gl";
import {
  CSSProperties,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";
import ReactMapGL, {
  MapLayerMouseEvent,
  MapProps,
  MapRef,
  ViewState,
} from "react-map-gl";

import { useMapboxToken } from "../contexts/MapboxToken";
import {
  useBaseStyleUrl,
  useMapLayers,
  useSelectedFeaturesContext,
} from "./AtlasMapContext";
import { TileUrlFunction } from "./helpers";
import { layerInteractiveIds, layerPrimaryKey } from "./layerHelpers";
import { useMapResizer } from "./mapHelpers";
import SourceView from "./SourceView";

const MAP_CSS_STYLE: CSSProperties = {
  width: "100%",
  height: "100%",
};

export interface AtlasMapProps extends MapProps {
  defaultViewport?: Partial<ViewState>;
  transformRequest?: TransformRequestFunction;
  cartographerTileUrl: TileUrlFunction;
  scrollZoom?: boolean;
  children: ReactNode;
}

export default function AtlasMap(props: AtlasMapProps): ReactElement {
  const {
    defaultViewport,
    transformRequest,
    cartographerTileUrl,
    scrollZoom,
    children,
    ...rest
  } = props;

  const layers = useMapLayers();
  const baseStyleUrl = useBaseStyleUrl();
  const { setSelectedKeys } = useSelectedFeaturesContext();

  const map = useRef<MapRef>(null);

  useMapResizer(map.current);

  const interactiveLayerIds = useMemo(
    () => layers.flatMap(layerInteractiveIds),
    [layers]
  );

  const handleClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      layers.forEach(layer => {
        setSelectedKeys(
          layer.layerId,
          evt.features
            ?.filter(feature =>
              layerInteractiveIds(layer).includes(
                unsafeMapLayerId(feature.layer.id)
              )
            )
            .map(layerPrimaryKey(layer)) ?? []
        );
      });
    },
    [layers, setSelectedKeys]
  );

  const mapboxToken = useMapboxToken();

  return (
    <ReactMapGL
      ref={map}
      mapboxAccessToken={mapboxToken}
      style={MAP_CSS_STYLE}
      mapStyle={baseStyleUrl}
      transformRequest={transformRequest}
      initialViewState={defaultViewport}
      scrollZoom={scrollZoom}
      onClick={handleClick}
      interactiveLayerIds={interactiveLayerIds}
      cursor="crosshair"
      attributionControl={false}
      {...rest}
    >
      {layers.map(layer => (
        <SourceView
          key={layer.layerId}
          layer={layer}
          cartographerTileUrl={cartographerTileUrl}
        />
      ))}

      {children}
    </ReactMapGL>
  );
}
