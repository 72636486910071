import { Email, UserV2, roleWorkspaceIdV2 } from "@cartographerio/types";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  List,
  ListItem,
  chakra,
} from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";
import { IoChevronForward } from "react-icons/io5";

import queries from "../../queries";
import Card from "../components/Card";
import CardBody from "../components/CardBody";
import ClickToCopy from "../components/ClickToCopy";
import Heading from "../components/Heading";
import Spaced from "../components/Spaced";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { routes } from "../routes";
import { FrontContext } from "./context";
import { FrontInfoItem, FrontInfoList } from "./FrontInfoList";
import FrontLink from "./FrontLink";
import { webAppUrl } from "./webApp";

export interface FrontUserItemByEmailProps {
  context: FrontContext;
  email: Email;
  onClick: (user: UserV2) => void;
}

export default function FrontUserItemByEmail(
  props: FrontUserItemByEmailProps
): ReactElement {
  const { context, email, onClick } = props;

  const apiParams = useApiParams();

  const user = useSuspenseQueryData(
    queries.user.v2.readOrNull(apiParams, email)
  );

  return user == null ? (
    <Box>
      <Heading level="subsection" mt="0" mb="4">
        {email}
      </Heading>
      <chakra.p>No user account</chakra.p>
    </Box>
  ) : (
    <FrontUserItem context={context} user={user} onClick={onClick} />
  );
}

export interface FrontUserItemProps {
  context: FrontContext;
  user: UserV2;
  onClick: (user: UserV2) => void;
}

export function FrontUserItem(props: FrontUserItemProps): ReactElement {
  const { context, user, onClick } = props;

  const apiParams = useApiParams();

  const allWorkspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const activeWorkspaces = useMemo(
    () =>
      allWorkspaces.filter(
        ws =>
          user.roles.some(role => roleWorkspaceIdV2(role) === ws.id) ?? false
      ),
    [user.roles, allWorkspaces]
  );

  return (
    <Card>
      <CardBody>
        <Spaced>
          <HStack>
            <Heading flexGrow={1} flexShrink={1} level="subsubsection" my="0">
              {user.firstName} {user.lastName}
            </Heading>

            <IconButton
              size="sm"
              variant="outline"
              aria-label="Edit"
              icon={<IoChevronForward />}
              onClick={() => onClick(user)}
            />
          </HStack>

          <FrontInfoList>
            <FrontInfoItem label="User ID">
              <ClickToCopy size="sm" value={user.id} />
            </FrontInfoItem>

            <FrontInfoItem label="Email">
              <ClickToCopy size="sm" value={user.email} />
            </FrontInfoItem>

            <FrontInfoItem label="Screen Name">
              <ClickToCopy size="sm" value={user.screenName} />
            </FrontInfoItem>

            <FrontInfoItem label="Workspaces">
              <List spacing="4">
                {activeWorkspaces.length === 0 && <ListItem>None</ListItem>}
                {activeWorkspaces.map(ws => (
                  <ListItem key={ws.id}>
                    <HStack
                      spacing="2"
                      justifyContent="flex-start"
                      fontSize="sm"
                    >
                      <Flex grow={1} shrink={1}>
                        {ws.name}
                      </Flex>
                      <FrontLink
                        flexGrow={0}
                        flexShrink={0}
                        context={context}
                        to={webAppUrl(
                          apiParams,
                          routes.workspace.home.url([ws.alias])
                        )}
                      >
                        Home
                      </FrontLink>
                      <FrontLink
                        flexGrow={0}
                        flexShrink={0}
                        context={context}
                        to={webAppUrl(
                          apiParams,
                          routes.workspace.settings.url([ws.alias])
                        )}
                      >
                        Settings
                      </FrontLink>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </FrontInfoItem>
          </FrontInfoList>
        </Spaced>
      </CardBody>
    </Card>
  );
}
