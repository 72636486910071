import { RefObject, useEffect } from "react";

import { HEADWAY_ACCOUNT } from "../components/Headway";

export interface UseHeadwayBadgeParams {
  selector: string;
  trigger?: string;
  target: RefObject<HTMLElement | null>;
}

export default function useHeadwayBadge(params: UseHeadwayBadgeParams) {
  const { selector, trigger, target } = params;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headway: any = (window as any).Headway;

    if (target.current != null && typeof headway?.init === "function") {
      headway.init({ selector, trigger, account: HEADWAY_ACCOUNT });
    }
  }, [selector, target, trigger]);
}
