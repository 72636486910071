import { Message } from "@cartographerio/atlas-core";
import { IO } from "@cartographerio/io";
import { check } from "@cartographerio/permission";
import {
  DataLicenseEnum,
  MapVisibilityEnum,
  ProjectPermissionModelEnum,
  ProjectV2,
  ProjectVisibilityEnum,
  WorkspaceId,
  randomProjectId,
  unsafeProjectAlias,
} from "@cartographerio/types";
import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { projectEmailSettingsRule } from "../../../../schema/emailSettings";
import { projectRule } from "../../../../schema/project";
import { useIOErrorAlert } from "../../../components/Alert";
import PageContainer from "../../../components/PageContainer";
import PageHeader from "../../../components/PageHeader";
import PageTopBar from "../../../components/PageTopBar";
import ProjectEditor from "../../../components/ProjectEditor";
import SaveButton from "../../../components/SaveButton";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermission from "../../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";

function initialProject(workspaceId: WorkspaceId): ProjectV2 {
  return {
    id: randomProjectId(),
    workspaceId,
    name: "",
    alias: unsafeProjectAlias(""),
    dataLicense: DataLicenseEnum.Default,
    permissionModel: ProjectPermissionModelEnum.Default,
    mapVisibility: MapVisibilityEnum.Project,
    projectVisibility: ProjectVisibilityEnum.Workspace,
    features: [],
    teamIds: [],
    qualificationIds: [],
    moduleIds: [],
    mapIds: [],
  };
}

export default function ProjectCreatePage(
  props: RouteProps<typeof routes.workspace.project.create>
): ReactElement {
  const {
    path: { workspaceRef },
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const errorAlert = useIOErrorAlert();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  useRequirePermission(check.superuser);

  usePageTitle(`New Project - ${workspace.name}`);

  const qualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams)
  );

  const mapSchemas = useSuspenseSearchResults(
    queries.map.schema.v1.search(apiParams)
  );

  const modules = useSuspenseSearchResults(
    queries.survey.module.v1.search(apiParams)
  );

  const projectTemplates = useSuspenseSearchResults(
    queries.project.template.v1.search(apiParams)
  );

  const [project, setProject] = useState(() => initialProject(workspace.id));

  const projectMessages = useMemo(() => projectRule(project), [project]);

  const [emailSettings, setEmailSettings] = useState(
    queries.project.emailSettings.v1.defaultEmailSettings
  );

  const emailSettingsMessages = useMemo(
    () => projectEmailSettingsRule(emailSettings),
    [emailSettings]
  );

  const [mapSettings, setMapSettings] = useState(
    queries.project.mapSettings.v1.defaultMapSettings
  );

  const mapSettingsMessages = useMemo<Message[]>(() => [], []);

  const allMessages = useMemo(
    () => [
      ...projectMessages,
      ...emailSettingsMessages,
      ...mapSettingsMessages,
    ],
    [emailSettingsMessages, mapSettingsMessages, projectMessages]
  );

  const handleSave = useCallback(() => {
    if (project != null && projectMessages.length === 0) {
      queries.project.v2
        .save(queryClient, apiParams, project)
        .flatMap(IO.fromResult)
        .tap(project =>
          IO.parForEach([
            queries.project.emailSettings.v1.save(
              queryClient,
              apiParams,
              project.id,
              emailSettings
            ),
            queries.project.mapSettings.v1.save(
              queryClient,
              apiParams,
              project.id,
              mapSettings
            ),
          ])
        )
        .tap(() =>
          toast({
            title: "Project created",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tap(() =>
          navigate(routes.workspace.project.list.url([workspace.alias]))
        )
        .tapError(errorAlert)
        .unsafeRun();
    }
  }, [
    project,
    projectMessages.length,
    queryClient,
    apiParams,
    errorAlert,
    emailSettings,
    mapSettings,
    toast,
    navigate,
    workspace.alias,
  ]);

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project="new"
      />
      <PageContainer width="wide">
        <Spaced>
          <PageHeader title="New Project" />
          <ProjectEditor
            project={project}
            onProjectChange={setProject}
            projectMessages={projectMessages}
            emailSettings={emailSettings}
            onEmailSettingsChange={setEmailSettings}
            emailSettingsMessages={emailSettingsMessages}
            mapSettings={mapSettings}
            onMapSettingsChange={setMapSettings}
            mapSettingsMessages={mapSettingsMessages}
            qualifications={qualifications}
            mapSchemas={mapSchemas}
            modules={modules}
            projectTemplates={projectTemplates}
          />
          <SaveButton onClick={handleSave} messages={allMessages} />
        </Spaced>
      </PageContainer>
    </>
  );
}
