import { Box, FormControl } from "@chakra-ui/react";
import { ReactNode } from "react";

import Checkbox, { CheckboxProps } from "./Checkbox";
import Spaced from "./Spaced";

export interface CheckboxPanelProps extends CheckboxProps {
  children: ReactNode;
}

export default function CheckboxPanel(props: CheckboxPanelProps) {
  const { value, children, ...rest } = props;
  return (
    <Spaced spacing="2">
      <FormControl>
        <Checkbox value={value} {...rest} />
      </FormControl>
      <Box
        borderRadius="md"
        borderWidth="1px"
        borderStyle="solid"
        borderColor="gray.300"
        p="2"
        position="relative"
      >
        {!value && <CheckboxPanelOverlay />}
        {children}
      </Box>
    </Spaced>
  );
}

function CheckboxPanelOverlay() {
  return (
    <Box
      cursor="not-allowed"
      bg="whiteAlpha.700"
      rounded="md"
      position="absolute"
      top="0"
      left="0"
      w="100%"
      h="100%"
      zIndex="10"
    />
  );
}
