import { UrlParts, formatUrl } from "@cartographerio/client";

import { useApiConfig } from "../contexts/apiConfig";
import { useOptAccessToken } from "../contexts/auth";

/** Creates a URL to call API endpoints:
 *
 * - adds the base URL from the current apiConfig;
 * - optionally adds an `authorization` parameter.
 */
export function useApiUrl(
  parts: UrlParts,
  addAccessToken: boolean = true
): string {
  return useApiUrlFormatter()(parts, addAccessToken);
}

/** Creates a reusable function to format URLs to call API endpoints:
 *
 * - adds the base URL from the current apiConfig;
 * - optionally adds an `authorization` parameter.
 */
export function useApiUrlFormatter() {
  const accessToken = useOptAccessToken();
  const { baseUrl } = useApiConfig();

  return function (parts: UrlParts, addAccessToken: boolean = true): string {
    return formatUrl(
      baseUrl,
      addAccessToken
        ? {
            ...parts,
            query: { ...parts.query, authorization: accessToken ?? undefined },
          }
        : parts
    );
  };
}
