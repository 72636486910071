import { Box, StyleProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface MenuHelpProps extends StyleProps {
  children: ReactNode;
}

export default function MenuHelp(props: MenuHelpProps): ReactElement {
  const {
    children,
    py = "1.5",
    px = "3",
    borderBottomWidth = 1,
    borderBottomColor = "gray.100",
    color = "gray.500",
    ...rest
  } = props;

  return (
    <Box
      py={py}
      px={px}
      borderBottomWidth={borderBottomWidth}
      borderBottomColor={borderBottomColor}
      color={color}
      {...rest}
    >
      {children}
    </Box>
  );
}
