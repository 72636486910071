import { Icon, IconButton } from "@chakra-ui/react";
import { MouseEvent, ReactElement } from "react";
import { IconType } from "react-icons";

export interface InspectorButtonProps {
  as: IconType;
  ariaLabel: string;
  onClick: (evt: MouseEvent<HTMLButtonElement>) => void;
}

export default function InspectorButton(
  props: InspectorButtonProps
): ReactElement {
  const { as, ariaLabel, onClick } = props;

  return (
    <IconButton
      icon={<Icon as={as} color="gray.400" />}
      aria-label={ariaLabel}
      title={ariaLabel}
      size="sm"
      w="5"
      h="5"
      minW="5"
      rounded="full"
      background="transparent"
      onClick={onClick}
    />
  );
}
