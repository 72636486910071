import {
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  HTMLChakraProps,
  ThemingProps,
} from "@chakra-ui/react";
import { MouseEventHandler, ReactElement } from "react";

import { useBreakpointValue } from "../contexts/breakpoint";

export interface ButtonProps
  extends HTMLChakraProps<"button">,
    ThemingProps<"Button"> {
  label: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
  responsive?: boolean;
}

export default function Button(props: ButtonProps): ReactElement {
  const {
    label,
    leftIcon,
    rightIcon,
    onClick,
    disabled,
    isLoading,
    loadingText,
    responsive = false,
    ...rest
  } = props;

  const showLabel = useBreakpointValue({
    base: !responsive,
    md: true,
  });

  return showLabel ? (
    <ChakraButton
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      aria-label={label}
      onClick={onClick}
      isDisabled={disabled}
      isLoading={isLoading}
      loadingText={loadingText}
      {...rest}
    >
      {label}
    </ChakraButton>
  ) : (
    <ChakraIconButton
      icon={leftIcon ?? rightIcon}
      aria-label={label}
      onClick={onClick}
      isDisabled={disabled}
      {...rest}
    />
  );
}
