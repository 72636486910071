import { useCallback, useMemo } from "react";
import { useCookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";

export type CookieValue<A> = A;
export type CookieSetter<A> = (value: A) => void;
export type CookieClearer = () => void;
export type CookieApi<A> = [CookieValue<A>, CookieSetter<A>, CookieClearer];

const COOKIE_PREFIX = "crt";

const COOKIE_OPTIONS: CookieSetOptions =
  process.env.NODE_ENV === "development"
    ? { secure: false, sameSite: "lax" }
    : { secure: true, sameSite: "none" };

export function useCookie<A>(
  name: string,
  fallback: A,
  path: string = "/",
  guard?: (v: unknown) => v is A
): CookieApi<A> {
  const prefixedName: string = `${COOKIE_PREFIX}${name}`;

  const [cookies, setCookie, removeCookie] = useCookies([prefixedName]);

  const value: A =
    guard == null || guard(cookies[prefixedName])
      ? cookies[prefixedName] ?? fallback
      : fallback;

  const set = useCallback(
    (value: A) => {
      setCookie(prefixedName, value, { ...COOKIE_OPTIONS, path });
    },
    [path, prefixedName, setCookie]
  );

  const remove = useCallback(() => {
    removeCookie(prefixedName, { ...COOKIE_OPTIONS, path });
  }, [path, prefixedName, removeCookie]);

  return [value, set, remove];
}

export function useMappedCookie<B>(
  name: string,
  fallback: B,
  format: (value: B) => string,
  parse: (value: string) => B,
  path: string = "/"
): CookieApi<B> {
  const [underlyingValue, underlyingSetter, clearer] = useCookie(
    name,
    format(fallback),
    path
  );

  const value = useMemo(() => parse(underlyingValue), [parse, underlyingValue]);

  const setter = useCallback(
    (value: B) => {
      underlyingSetter(format(value));
    },
    [format, underlyingSetter]
  );

  return [value, setter, clearer];
}

const booleanToString = (bool: boolean) => `${bool}`;
const stringToBoolean = (str: string) => str === "true";
export function useBooleanCookie(
  name: string,
  fallback: boolean = false,
  path: string = "/"
): CookieApi<boolean> {
  return useMappedCookie(
    name,
    fallback,
    booleanToString,
    stringToBoolean,
    path
  );
}
