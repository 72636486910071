import { Box } from "@chakra-ui/react";
import { ReactElement } from "react";

export interface UnitsProps {
  disabled?: boolean;
  units: string;
}

export default function Units(props: UnitsProps): ReactElement {
  const { disabled, units } = props;
  return (
    <Box
      px="4"
      opacity={disabled ? 0.4 : undefined}
      cursor={disabled ? "not-allowed" : undefined}
    >
      {units}
    </Box>
  );
}
