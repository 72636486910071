import { check } from "@cartographerio/permission";
import { Box } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import Card, { CardProps } from "./Card";
import CardBody from "./CardBody";
import RequirePermission from "./RequirePermission";
import Spaced from "./Spaced";

interface SuperuserContentProps extends CardProps {
  fallback?: ReactNode;
  children: ReactNode;
}

export const SUPERUSER_CONTENT_BACKGROUND = "orange.100";

export default function SuperuserContent(
  props: SuperuserContentProps
): ReactElement {
  const { fallback, children, ...rest } = props;

  return (
    <RequirePermission check={check.superuser} fallback={fallback}>
      <Card bg="orange.100" position="relative" {...rest}>
        <CardBody>
          <Spaced spacing="1">
            {children}
            <Box
              fontSize="sm"
              color="orange.500"
              fontStyle="italic"
              textAlign="right"
            >
              Superuser only
            </Box>
          </Spaced>
        </CardBody>
      </Card>
    </RequirePermission>
  );
}
