import { RequireRoleState } from "@cartographerio/atlas-form";
import { useSurveyorTeamContext } from "@cartographerio/atlas-form-context";
import { check } from "@cartographerio/permission";
import {
  projectRoleV2,
  teamRoleV2,
  workspaceAdminV2,
} from "@cartographerio/types";
import { ReactElement, useMemo } from "react";

import usePermissionCheckRunner from "../hooks/usePermissionCheckRunner";
import BlockView from "./BlockView";
import { useFormContext } from "./context/FormContext";

interface RequireRoleViewProps {
  blockKey: string;
  state: RequireRoleState;
  disabled?: boolean;
}

export default function RequireRoleView(
  props: RequireRoleViewProps
): ReactElement {
  const {
    blockKey,
    state: {
      block: { role: roleName },
      childState,
    },
    disabled: _disabled,
  } = props;

  const { primaryTeamId } = useSurveyorTeamContext();
  const { project } = useFormContext();
  const hasPermission = usePermissionCheckRunner();

  const disabled = useMemo(
    () =>
      _disabled ||
      !hasPermission(
        check.or(
          check.hasRole(workspaceAdminV2(project.workspaceId)),
          check.hasRole(projectRoleV2(roleName, project.id)),
          primaryTeamId == null
            ? check.never()
            : check.hasRole(teamRoleV2(roleName, primaryTeamId))
        )
      ),
    [
      _disabled,
      hasPermission,
      primaryTeamId,
      project.id,
      project.workspaceId,
      roleName,
    ]
  );

  return (
    <BlockView
      blockKey={blockKey}
      blockState={childState}
      disabled={disabled}
    />
  );
}
