import { QueryKey, UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { UseQueryOpts } from "../../queries";

interface UseSuspenseQueryResult<A>
  extends Omit<UseQueryResult<A, unknown>, "data"> {
  data: A;
  suspense: true;
}

export function useSuspenseQuery<A, K extends QueryKey = QueryKey>(
  options: UseQueryOpts<A, K>
): UseSuspenseQueryResult<A>;
export function useSuspenseQuery<A, B = unknown, K extends QueryKey = QueryKey>(
  options: UseQueryOpts<A, K>,
  mapper: (value: A) => B
): UseSuspenseQueryResult<B>;
export function useSuspenseQuery<K extends QueryKey = QueryKey>(
  options: UseQueryOpts<unknown, K>,
  mapper?: (value: unknown) => unknown
): UseSuspenseQueryResult<unknown> {
  const result = useQuery({ ...options, suspense: true });
  return useMemo(
    () => ({
      ...result,
      data: mapper != null ? mapper(result.data) : result.data,
      suspense: true,
    }),
    [mapper, result]
  );
}
