import { MapSchema } from "@cartographerio/atlas-map";
import { checks } from "@cartographerio/permission";
import { ProjectV2 } from "@cartographerio/types";
import { chain } from "lodash";
import { useMemo } from "react";

import useMapInventory from "./useMapInventory";
import usePermissionCheckRunner from "./usePermissionCheckRunner";

export function useVisibleMaps(project: ProjectV2): MapSchema[] {
  const inventory = useMapInventory();
  const hasPermission = usePermissionCheckRunner();

  return useMemo(
    () =>
      hasPermission(checks.map.view(project))
        ? chain(inventory.maps())
            .filter(map => project.mapIds.includes(map.mapId))
            .sortBy(map => map.title)
            .value()
        : [],
    [hasPermission, inventory, project]
  );
}
