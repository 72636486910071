import { authHeaders } from "@cartographerio/client/dist/headers";
import {
  BBox4,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Point,
  commonLocations,
  featureCollection,
  toBBox4,
} from "@cartographerio/geometry";
import {
  ApiAuth,
  ApiConfig,
  MapLayerId,
  ProjectMapSettings,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { AllGeoJSON, centerOfMass, centroid } from "@turf/turf";
import { debounce } from "lodash";
import { TransformRequestFunction } from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { MapRef } from "react-map-gl";
import { PaddingOptions } from "react-map-gl/dist/esm/types";

import { InitialViewState } from "./helpers";

const MAP_RESIZER_DELAY = 50;

const DEFAULT_MAP_PADDING = 20;

interface FromBounds {
  type: "FromBounds";
  bounds: BBox4;
  padding: number;
}

interface FromCenter {
  type: "FromCenter";
  center: Point;
  zoom: number;
  padding: number;
}

interface FromViewState {
  type: "FromViewState";
  viewState: InitialViewState;
}

type CalcViewStateParam = FromBounds | FromCenter | FromViewState;

type CalcViewStateThunk = () => CalcViewStateParam | false | null;

export function fromBounds(
  bounds: BBox4,
  padding: number = DEFAULT_MAP_PADDING
): CalcViewStateParam {
  return { type: "FromBounds", bounds, padding };
}

export function fromCenter(
  center: Point,
  zoom: number,
  padding: number = DEFAULT_MAP_PADDING
): CalcViewStateParam {
  return { type: "FromCenter", center, zoom, padding };
}

export function fromViewState(viewState: InitialViewState): CalcViewStateParam {
  return { type: "FromViewState", viewState };
}

export function fromMapSettings(
  settings: ProjectMapSettings
): CalcViewStateParam {
  return fromBounds(toBBox4(settings.defaultBounds));
}

export function fromMultiLayerSelection(
  selection: Record<MapLayerId, FeatureCollection<Geometry, GeoJsonProperties>>
): CalcViewStateParam | null {
  const features = Object.values(selection).flatMap(
    coll => coll?.features ?? []
  );

  const centroidFeature =
    features.length === 0
      ? undefined
      : centroid(featureCollection({ features }) as AllGeoJSON);

  return centroidFeature == null
    ? null
    : fromCenter(centroidFeature.geometry, 12);
}

export function fromGeometry(geometry: Geometry, zoom: number) {
  return fromCenter(centerOfMass(geometry).geometry, zoom);
}

export function calcViewState(
  ...funcs: CalcViewStateThunk[]
): InitialViewState {
  const padding = (n: number): PaddingOptions => ({
    top: n,
    right: n,
    bottom: n,
    left: n,
  });

  const transform = (param: CalcViewStateParam): InitialViewState => {
    switch (param.type) {
      case "FromBounds":
        return {
          bounds: param.bounds,
          padding: padding(param.padding),
        };

      case "FromCenter":
        return {
          longitude: param.center.coordinates[0],
          latitude: param.center.coordinates[1],
          zoom: param.zoom,
          padding: padding(param.padding),
        };

      case "FromViewState":
        return param.viewState;

      default:
        return checkExhausted(param);
    }
  };

  return (
    funcs.reduce<InitialViewState | null>((memo, func) => {
      if (memo != null) {
        return memo;
      } else {
        const curr = func();
        return !curr ? null : transform(curr);
      }
    }, null) ??
    transform(fromBounds(toBBox4(commonLocations.greatBritain.bounds)))
  );
}

export function useTransformRequestFunction(
  apiConfig: ApiConfig,
  auth: ApiAuth | undefined
): TransformRequestFunction | undefined {
  return useMemo(
    () =>
      auth == null
        ? undefined
        : (url, _resourceType) => ({
            url,
            headers: url.startsWith(apiConfig.baseUrl)
              ? authHeaders(apiConfig, auth)
              : {},
          }),
    [apiConfig, auth]
  );
}

export function useMapResizer(map: MapRef | null) {
  useEffect(() => {
    if (map != null) {
      const resizer = new ResizeObserver(
        debounce(() => {
          if (map.getCanvas() != null) {
            map.resize();
          }
        }, MAP_RESIZER_DELAY)
      );

      resizer.observe(map.getContainer());

      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);
}
