import { ProjectBreadcrumb } from "@cartographerio/types";
import { Text } from "@chakra-ui/react";
import { ReactElement } from "react";

import queries from "../../queries";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import { routes } from "../routes";
import Card from "./Card";
import CardBody from "./CardBody";
import CardTitle from "./CardTitle";
import Link from "./Link";

interface ProjectBreadcrumbCardProps {
  breadcrumb: ProjectBreadcrumb;
}

export default function ProjectBreadcrumbCard(
  props: ProjectBreadcrumbCardProps
): ReactElement {
  const { breadcrumb } = props;
  const { title, desWorkspaceId } = breadcrumb;

  const apiParams = useApiParams();

  const desWorkspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, desWorkspaceId)
  );

  // const desProject = useSuspenseQueryData(
  //   queries.project.v2.readOrFail(apiParams, desProjectId)
  // );

  // const desTeam = useSuspenseQueryData(
  //   queries.optional(desTeamId, desTeamId =>
  //     queries.team.v2.readOrFail(apiParams, desTeamId)
  //   )
  // );

  return (
    <Card>
      <CardTitle h="14">{title}</CardTitle>
      <CardBody>
        <Text fontSize="sm" lineHeight="8">
          This project has moved to the{" "}
          <Link.Internal to={routes.workspace.home.url([desWorkspace.alias])}>
            {desWorkspace.name}
          </Link.Internal>{" "}
          workspace.{" "}
          <Link.External to="https://help.cartographer.io/where-did-my-data-go">
            Read why this changed
          </Link.External>
        </Text>
      </CardBody>
    </Card>
  );
}
