import { Box, BoxProps } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

interface TreeContainerProps extends BoxProps {
  children: ReactNode;
}

export default function TreeContainer(props: TreeContainerProps): ReactElement {
  const { children, ...rest } = props;

  return (
    <Box
      spacing="0"
      p="0"
      rounded="md"
      bg="white"
      borderColor="gray.100"
      borderWidth="1px"
      borderStyle="solid"
      {...rest}
    >
      {children}
    </Box>
  );
}
