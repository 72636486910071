import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import PageTopBar from "../../../components/PageTopBar";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import useRequirePermission from "../../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../../hooks/useWorkspaceGraph";
import { routes } from "../../../routes";
import BaseInvitationViewPage from "../../base/BaseInvitationViewPage";
import ProjectPageHeader from "./ProjectPageHeader";

export default function ProjectInvitationViewPage(
  props: RouteProps<typeof routes.workspace.project.invitation.view>
): ReactElement {
  const {
    path: { workspaceRef, projectRef, invitationId },
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const invitation = useSuspenseQueryData(
    queries.invitation.v3.readOrFail(apiParams, invitationId)
  );

  useRequirePermission(checks.project.grantAccess(project));

  usePageTitle(
    useMemo(() => {
      const name = [invitation.firstName, invitation.lastName]
        .filter(x => x)
        .join(" ");

      return [
        name === "" ? "Invitation" : `Inviting ${name}`,
        project.name,
        workspace.name,
      ].join(" - ");
    }, [invitation, project.name, workspace.name])
  );

  const workspaceGraph = useCurrentWorkspaceGraph();

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="invitation-single"
        invitation={invitation}
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="invitation-single"
      />
      <BaseInvitationViewPage
        asTitle={false}
        backRedirect={routes.workspace.project.invitation.list.url([
          workspace.alias,
          project.alias,
        ])}
        defaultInvitation={invitation}
        defaultWorkspace={workspace.id}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.workspace.project.invitation.list.url([
          workspace.alias,
          project.alias,
        ])}
      />
    </>
  );
}
