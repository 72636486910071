import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Key, MouseEventHandler, ReactElement } from "react";

import ButtonLink from "./ButtonLink";
import MenuItemLink from "./MenuItemLink";

export interface AddButtonOption {
  key: Key;
  label?: string;
  to?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}
interface AddButtonProps extends ButtonProps {
  label?: string;
  options: AddButtonOption[];
}

export default function AddButton(props: AddButtonProps): ReactElement {
  const { label = "Add", options, disabled, ...rest } = props;

  return options.length > 1 ? (
    <Menu placement="bottom-end">
      <MenuButton as={Button} colorScheme="blue" disabled={disabled} {...rest}>
        {label}
      </MenuButton>
      <MenuList zIndex="popover">
        {options.map(({ key, label, onClick, to }) =>
          to != null ? (
            <MenuItemLink.Internal key={key} to={to}>
              {label}
            </MenuItemLink.Internal>
          ) : (
            <MenuItem key={key} onClick={onClick}>
              {label}
            </MenuItem>
          )
        )}
      </MenuList>
    </Menu>
  ) : options[0]?.to != null ? (
    <ButtonLink.Internal
      colorScheme="blue"
      to={options[0].to}
      disabled={disabled}
    >
      {label}
    </ButtonLink.Internal>
  ) : (
    <Button
      colorScheme="blue"
      onClick={options[0]?.onClick}
      disabled={options.length === 0 || disabled}
      {...rest}
    >
      {label}
    </Button>
  );
}
