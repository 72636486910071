import { Option } from "@cartographerio/fp";
import { InputElementProps } from "@chakra-ui/react";
import { ReactElement, useCallback } from "react";

import { BaseTextFieldProps } from "./base";
import DefaultTextField from "./Default";
import Units from "./Units";

export interface NumberTextFieldProps
  extends BaseTextFieldProps<number>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {
  decimalPlaces?: number;
  units?: string;
}

// @TODO: This is the same as in the mobile app. Factor it out:
export function formatDecimalPlaces(
  num: number,
  decimalPlaces?: number
): string {
  if (decimalPlaces == null) {
    return String(num);
  } else if (Number.isInteger(num * Math.pow(10, decimalPlaces))) {
    // If the number has less than N decimal places, don't *add* any decimal places to it:
    return String(num);
  } else {
    // If the number has more than N decimal places, truncate it:
    return num.toFixed(decimalPlaces);
  }
}

// @TODO: This is the same as in the mobile app. Factor it out:
export function validateDecimalPlaces(
  str: string,
  decimalPlaces?: number
): Option<number> {
  const trimmed = str.trim();
  const answer = Option.some(Number(trimmed)).filter(isFinite);

  if (decimalPlaces == null) {
    return answer;
  } else {
    const power = Math.pow(10, decimalPlaces);
    return answer.map(num => Math.round(num * power) / power);
  }
}

export default function NumberTextField(
  props: NumberTextFieldProps
): ReactElement {
  const { decimalPlaces, units, highlight, disabled, ...rest } = props;

  const format = useCallback(
    (number: number) => formatDecimalPlaces(number, decimalPlaces),
    [decimalPlaces]
  );

  const validate = useCallback(
    (text: string) => validateDecimalPlaces(text, decimalPlaces),
    [decimalPlaces]
  );

  const rightAddon =
    units == null ? undefined : <Units units={units} disabled={disabled} />;

  return (
    <DefaultTextField
      format={format}
      validate={validate}
      highlight={highlight}
      rightAddon={rightAddon}
      disabled={disabled}
      {...rest}
    />
  );
}
