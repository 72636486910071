import { Email, unsafeEmail } from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import {
  ApplicationContext,
  ApplicationConversation,
  ApplicationMessage,
  ApplicationRecipient,
  ApplicationSingleConversation,
  SingleConversationContext,
  SingleConversationPopoverContext,
} from "@frontapp/ui-bridge";
import { uniq } from "lodash";

export type FrontContext = ApplicationContext;

export async function contextEmails(context: FrontContext): Promise<Email[]> {
  switch (context.type) {
    case "noConversation":
    case "noConversationPopover":
      return [];

    case "singleConversation":
    case "singleConversationPopover": {
      const emails = await conversationEmails(context, context.conversation);
      return uniq(emails.sort());
    }

    case "multiConversations": {
      const emails = uniq(multiConversationEmails(context.conversations));
      return uniq(emails.sort());
    }

    case "message":
      return [];

    default:
      return checkExhausted(context);
  }
}

function multiConversationEmails(
  convs: readonly ApplicationConversation[]
): Email[] {
  return uniq(
    convs.flatMap(conv =>
      conv.recipient == null ? [] : recipientEmails(conv.recipient)
    )
  );
}

async function conversationEmails(
  context: SingleConversationContext | SingleConversationPopoverContext,
  conv: ApplicationSingleConversation
): Promise<Email[]> {
  const messages = await context.listMessages();

  const emails1 = messages.results.flatMap(messageEmails);

  const emails2 = conv.recipient == null ? [] : recipientEmails(conv.recipient);

  return uniq([...emails1, ...emails2]);
}

function recipientEmails(rcpt: ApplicationRecipient): Email[] {
  const x: Email = unsafeEmail(rcpt.handle.toLowerCase());
  const y: Email[] = (rcpt.contact?.handles ?? [])
    .flatMap(handle => (handle.type === "email" ? [handle.handle] : []))
    .map(handle => handle.toLowerCase())
    .map(unsafeEmail);

  return [x, ...y];
}

function messageEmails(message: ApplicationMessage): Email[] {
  const { content, to = [], cc = [], bcc = [] } = message;

  const recipients = [...to, ...cc, ...bcc];

  const emails1 = recipients
    .filter(rcpt => rcpt.type === "email")
    .map(rcpt => rcpt.handle)
    .map(handle => handle.toLowerCase())
    .map(unsafeEmail);

  const emails2 = content == null ? [] : messageBodyEmails(content.body);

  return [...emails1, ...emails2];
}

const emailRegex = /[^@ :"=<>\\]+@[^@ :"=<>\\]+[.][a-zA-Z]{2,4}/g;

function messageBodyEmails(body: string): Email[] {
  return [...body.replace(/\n/g, " ").matchAll(emailRegex)].map(arr =>
    unsafeEmail(arr[0].toLowerCase())
  );
}
