import {
  Container as ChakraContainer,
  StyleProps,
  SystemProps,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export type ContainerWidth = "full" | "wide" | "default" | "narrow";

export const containerWidths: Record<ContainerWidth, SystemProps["maxW"]> = {
  wide: "120ch",
  default: "90ch",
  narrow: "60ch",
  full: "full",
};

export interface ContainerProps {
  sticky?: boolean;
  top?: StyleProps["top"];
  width?: ContainerWidth;
  children: ReactNode;
}

export default function Container(props: ContainerProps): ReactElement {
  const {
    sticky,
    top = sticky ? "0" : undefined,
    width = "default",
    children,
  } = props;

  return (
    <ChakraContainer
      position={sticky ? "sticky" : "relative"}
      zIndex={sticky ? "sticky" : undefined}
      top={top}
      mx="auto"
      maxW={containerWidths[width]}
    >
      {children}
    </ChakraContainer>
  );
}
