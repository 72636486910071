import { useFormPermissionsContext } from "@cartographerio/atlas-form-context";
import { IO } from "@cartographerio/io";
import { SurveyStatus } from "@cartographerio/types";
import { checkExhausted, tuple } from "@cartographerio/util";
import {
  Box,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import outdent from "outdent";
import { ReactElement, useCallback, useMemo } from "react";

import { useAlert } from "../components/Alert";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import Spaced from "../components/Spaced";
import { CookieSetter, CookieValue } from "../hooks/useCookie";

export interface FormOptionsModalProps {
  isOpen: boolean;
  onClose: () => void;
  status: SurveyStatus;
  helpVisible: boolean;
  onHelpVisibleChange: (value: boolean) => void;
  debugInfoVisible: boolean;
  onDebugInfoVisibleChange: (value: boolean) => void;
  autoSaving: CookieValue<boolean>;
  setAutoSaving: CookieSetter<boolean>;
  deleteSurvey: () => IO<void>;
  onSurveyDeleted?: () => void;
}

export default function FormOptionsModal(
  props: FormOptionsModalProps
): ReactElement {
  const {
    isOpen,
    onClose,
    status,
    helpVisible,
    onHelpVisibleChange,
    debugInfoVisible,
    onDebugInfoVisibleChange,
    autoSaving,
    setAutoSaving,
    deleteSurvey,
    onSurveyDeleted,
  } = props;

  const { canDelete } = useFormPermissionsContext();
  const alert = useAlert();

  const [deleteDisabled, deleteMessage] = useMemo(() => {
    switch (status) {
      case "draft":
      case "complete":
      case "review":
        return tuple(false, null);
      case "approved":
        return tuple(
          true,
          "You have to unapprove this survey before you can delete it!"
        );
      case "rejected":
        return tuple(
          true,
          "You have to unreject this survey before you can delete it!"
        );
      default:
        return checkExhausted(status);
    }
  }, [status]);

  const handleDeleteClick = useCallback(() => {
    if (deleteDisabled) {
      alert({
        title: "Unable to delete!",
        message: deleteMessage,
      });
    } else {
      IO.wrap(() =>
        confirm(
          outdent`
          Are you sure you want to delete this survey?
          This cannot be undone!
          `
        )
      )
        .flatMap(confirmed =>
          confirmed ? deleteSurvey().tap(_ => onSurveyDeleted?.()) : IO.noop()
        )
        .unsafeRun();
    }
  }, [alert, deleteDisabled, deleteMessage, deleteSurvey, onSurveyDeleted]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Form Options</ModalHeader>

        <ModalBody>
          <Spaced spacing={2}>
            <FormControl>
              <Checkbox
                value={helpVisible}
                onChange={onHelpVisibleChange}
                checkboxLabel="Show help text and images"
              />
            </FormControl>
            <FormControl>
              <Checkbox
                value={debugInfoVisible}
                onChange={onDebugInfoVisibleChange}
                checkboxLabel="Show debugging information"
              />
            </FormControl>
            <FormControl>
              <Checkbox
                value={autoSaving ?? false}
                onChange={setAutoSaving}
                checkboxLabel="Auto-save when changing pages in multi-page forms"
              />
            </FormControl>
          </Spaced>
        </ModalBody>

        <ModalFooter>
          <Flex justify="space-between" width="100%">
            <Box>
              {canDelete && (
                <Button
                  colorScheme="red"
                  onClick={handleDeleteClick}
                  label="Delete Survey"
                  opacity={deleteDisabled ? 0.4 : undefined}
                  boxShadow={deleteDisabled ? 0 : undefined}
                />
              )}
            </Box>
            <Button colorScheme="blue" onClick={onClose} label="OK" />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
