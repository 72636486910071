import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  SearchResults,
  SurveyModuleSummary,
} from "@cartographerio/types";

import { UseQueryOpts } from "../../base";

export type ModuleKey = ["survey"] | ["survey", "module", "v1", "search"];

export function search(
  apiParams: ApiParams
): UseQueryOpts<SearchResults<SurveyModuleSummary>, ModuleKey> {
  return {
    queryKey: ["survey", "module", "v1", "search"],
    queryFn: () => endpoints.survey.module.v1.search(apiParams).unsafeRun(),
  };
}
