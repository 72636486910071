import { Box } from "@chakra-ui/react";

import { SIDEBAR_ANIMATION_DURATION, SIDEBAR_OVERLAY_ZINDEX } from "./base";

export interface SidebarOverlayProps {
  toggleSidebar: () => void;
}

export default function SidebarOverlay(props: SidebarOverlayProps) {
  const { toggleSidebar } = props;

  return (
    <Box
      bg="blackAlpha.300"
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      zIndex={SIDEBAR_OVERLAY_ZINDEX}
      transitionProperty="all"
      transitionDuration={SIDEBAR_ANIMATION_DURATION}
      onClick={toggleSidebar}
      className="overlay"
    />
  );
}
