import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import { useApiParams } from "../../contexts/auth";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { routes } from "../../routes";

export default function ShortProjectUrlPage(
  props: RouteProps<typeof routes.short.project>
): ReactElement {
  const {
    path: { projectId },
    query,
  } = props;

  const apiParams = useApiParams();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectId)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  return (
    <Navigate
      to={routes.workspace.project.home.url(
        [workspace.alias, project.alias],
        query
      )}
      replace={true}
    />
  );
}
