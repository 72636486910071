import { SystemProps, TableCellProps } from "@chakra-ui/react";
import { ReactNode, useMemo } from "react";

export type MobileArea = "left" | "center" | "right";

export interface Column<A, O extends string> {
  title?: string;
  help?: string;
  orderBy?: O;
  defaultOrder?: "asc" | "desc";
  render: (item: A, index: number) => ReactNode;
  cellProps?: TableCellProps | null;
  width?: `${number}ch` | `${number}fr` | "auto" | "0";
  ellipsis?: boolean;
  mobile?: boolean;
  align?: "left" | "center" | "right";
}

export interface ActionsColumn<A> {
  renderButtons: (item: A) => ReactNode[];
  cellProps?: TableCellProps | null;
}

export type ColumnPlacement<A, O extends string> = Record<
  MobileArea,
  Column<A, O>[]
>;

export function column<A, O extends string>(props: Column<A, O>): Column<A, O> {
  return props;
}

export function useContainerProps<A, O extends string>(
  _columns: Column<A, O>[]
): SystemProps {
  return useMemo(
    () => ({
      bg: [null, null, "white"],
      rounded: "md",
      boxShadow: "sm",
    }),
    []
  );
}

export function useTableProps<A, O extends string>(
  _columns: Column<A, O>[]
): SystemProps {
  return useMemo(
    () => ({
      display: ["block", "block", "table"],
      size: "sm",
      borderColor: "gray.100",
      borderWidth: 1,
      borderRadius: "md",
      overflow: "hidden",
    }),
    []
  );
}

export function useRowProps<A, O extends string>(
  _columns: Column<A, O>[]
): SystemProps {
  return useMemo(
    () => ({
      display: ["block", "block", "table-row"],
      position: ["relative", "relative", "static"],
      bg: "white",
      my: ["2", "2", null],
      rounded: ["md", "md", null],
      flexDirection: ["column", "column", null],
    }),
    []
  );
}

export function useHeaderRowProps<A, O extends string>(
  columns: Column<A, O>[]
): SystemProps {
  const rowProps = useRowProps(columns);

  return useMemo(
    () => ({
      ...rowProps,
      display: ["none", "none", "table-row"],
    }),
    [rowProps]
  );
}

export function useCellProps<A, O extends string>(
  columns: Column<A, O>[]
): SystemProps[] {
  return useMemo(() => {
    return columns.map((column, _index) => {
      const {
        width = "auto",
        align = "left",
        ellipsis,
        mobile = true,
      } = column;

      const mobileDisplay = mobile ? "block" : "none";
      const desktopDisplay = "table-cell";
      const display: SystemProps["display"] = [
        mobileDisplay,
        mobileDisplay,
        desktopDisplay,
      ];

      const mobileBefore = `'${column.title ?? ""}'`;
      const desktopBefore = null;
      const _before: SystemProps["_before"] = {
        display: ["block", "block", "none"],
        pb: "1",
        fontSize: "xs",
        fontWeight: "bold",
        textTransform: "uppercase",
        content: [mobileBefore, mobileBefore, desktopBefore],
      };

      return {
        display,
        width: ["100%", "100%", width],
        height: [null, null, "12"],
        minH: ["12", "12", null],
        textAlign: ["left", "left", align],
        whiteSpace: ellipsis ? "nowrap" : undefined,
        bg: "transparent",
        _before,
      };
    });
  }, [columns]);
}

export const defaultActionsCellProps: SystemProps = {
  position: ["absolute", "absolute", "static"],
  right: ["2", "2", "unset"],
  top: ["2", "2", "unset"],
  px: ["0", "0", "4"],
  py: ["0", "0", "2"],
  width: ["fit-content", "fit-content", "0"],
  borderBottom: ["none", "none", "1px"],
  borderColor: ["none", "none", "gray.100"],
};
