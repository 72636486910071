import { Point } from "@cartographerio/geometry";
import { PointLike } from "mapbox-gl";
import { ReactElement, useMemo } from "react";
import { Marker } from "react-map-gl";

interface MapMarkerProps {
  position: Point;
  fillColor?: string;
  strokeColor?: string;
  strokeWidth?: number;
  shadowColor?: string;
  shadowWidth?: number;
  size?: number;
}

export default function MapMarker(props: MapMarkerProps): ReactElement {
  const {
    position,
    fillColor = "red",
    strokeColor = "white",
    strokeWidth = 2,
    size = 32,
  } = props;

  const {
    shadowColor = "rgba(0, 0, 0, .1)",
    shadowWidth = strokeWidth + 2,
  } = props;

  const offset = useMemo<PointLike>(() => [0, -size / 2 + strokeWidth], [
    size,
    strokeWidth,
  ]);

  return (
    <Marker
      latitude={position.coordinates[1]}
      longitude={position.coordinates[0]}
      offset={offset}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 ${20 + shadowWidth}  ${20 + 2 * shadowWidth}`}
        width={size}
      >
        <defs>
          <path
            id="shape"
            fillRule="evenodd"
            d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
            clipRule="evenodd"
          />
        </defs>

        <g transform={`translate(2, 2)`}>
          <use
            xlinkHref="#shape"
            strokeWidth={shadowWidth * 2}
            stroke={shadowColor}
          />

          <use
            xlinkHref="#shape"
            strokeWidth={strokeWidth * 2}
            stroke={strokeColor}
          />

          <use xlinkHref="#shape" fill={fillColor} />
        </g>
      </svg>
    </Marker>
  );
}
