import { ApiClientInfo, ApiConfig, parseSemver } from "@cartographerio/types";
import { envvar } from "@cartographerio/util";

export const clientInfo: ApiClientInfo = {
  name: "CartographerWebApp",
  version: parseSemver(envvar("REACT_APP_VERSION")).getOrThrow(
    () => new Error("Bad version in package.json")
  ),
};

export type ApiServerId = "production" | "beta" | "next" | "development";

interface ApiServerOption {
  id: ApiServerId;
  label: string;
  config: ApiConfig;
}

export const productionApi: ApiServerOption = {
  id: "production",
  label: "Production",
  config: { baseUrl: "https://api.cartographer.io", client: clientInfo },
};

export const betaApi: ApiServerOption = {
  id: "beta",
  label: "Beta",
  config: {
    baseUrl: "https://api.beta.cartographer.io",
    client: clientInfo,
  },
};

export const nextApi: ApiServerOption = {
  id: "next",
  label: "Next",
  config: {
    baseUrl: "https://api.next.cartographer.io",
    client: clientInfo,
  },
};

export const developmentApi: ApiServerOption = {
  id: "development",
  label: "Development",
  config: {
    baseUrl: "http://localhost:9000",
    client: clientInfo,
  },
};

export function parseApiServerId(str: unknown): ApiServerId | undefined {
  switch (str) {
    case "beta":
      return "beta";
    case "next":
      return "next";
    case "development":
      return "development";
    case "production":
      return "production";
    default:
      return undefined;
  }
}

export function apiServerLabel(id: ApiServerId): string {
  switch (id) {
    case "beta":
      return betaApi.label;
    case "next":
      return nextApi.label;
    case "development":
      return developmentApi.label;
    case "production":
    default:
      return productionApi.label;
  }
}

export function apiServerConfig(id: ApiServerId): ApiConfig {
  switch (id) {
    case "beta":
      return betaApi.config;
    case "next":
      return nextApi.config;
    case "development":
      return developmentApi.config;
    case "production":
    default:
      return productionApi.config;
  }
}

export const apiServerOptions: ApiServerOption[] = [
  productionApi,
  betaApi,
  nextApi,
  developmentApi,
];
