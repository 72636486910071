import {
  BoxProps,
  Link as ChakraLink,
  HTMLChakraProps,
  ThemingProps,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

import { FrontContext } from "./context";

export interface LinkProps extends HTMLChakraProps<"a">, ThemingProps<"Link"> {
  context: FrontContext;
  to: string;
  title?: string;
  isExternal?: boolean;
  pointerEvents?: BoxProps["pointerEvents"];
  children: ReactNode;
}

export default function Link(props: LinkProps): ReactElement {
  const {
    context,
    to,
    title,
    isExternal,
    pointerEvents,
    children,
    ...rest
  } = props;
  return (
    <ChakraLink
      as="span"
      title={title}
      isExternal={isExternal}
      pointerEvents={pointerEvents}
      onClick={() => context.openUrl(to)}
      {...rest}
    >
      {children}
    </ChakraLink>
  );
}
