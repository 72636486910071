import { checks } from "@cartographerio/permission";
import { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseInvitationViewPage from "../base/BaseInvitationViewPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceInvitationViewPage(
  props: RouteProps<typeof routes.workspace.invitation.view>
): ReactElement {
  const {
    path: { workspaceRef, invitationId },
  } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  const workspaceGraph = useCurrentWorkspaceGraph();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const invitation = useSuspenseQueryData(
    queries.invitation.v3.readOrFail(apiParams, invitationId)
  );

  useEffect(() => {
    if (
      invitation.workspaceId != null &&
      invitation?.workspaceId !== workspace.id
    ) {
      navigate(routes.short.workspace.url([invitation.workspaceId]));
    }
  }, [invitation.workspaceId, navigate, workspace.id]);

  useRequirePermission(checks.workspace.admin(workspace));

  usePageTitle(
    useMemo(() => {
      const name = [invitation.firstName, invitation.lastName]
        .filter(x => x)
        .join(" ");

      return name === ""
        ? `Invitation - ${workspace.name}`
        : `Inviting ${name} - ${workspace.name}`;
    }, [invitation, workspace.name])
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="invitation-single"
        invitation={invitation}
      />
      <WorkspacePageHeader workspace={workspace} selected="invitation-single" />
      <BaseInvitationViewPage
        asTitle={false}
        backRedirect={routes.workspace.invitation.list.url([workspace.alias])}
        defaultInvitation={invitation}
        defaultWorkspace={workspace.id}
        workspaceGraph={workspaceGraph}
        onDeleteGo={routes.workspace.invitation.list.url([workspace.alias])}
      />
    </>
  );
}
