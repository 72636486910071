import { InvitationEmailSettings, Message } from "@cartographerio/types";
import { Box, chakra } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";

import { invitationEmailSettingsErrorKeys } from "../../../schema/emailSettings";
import { splitMessages } from "../../../schema/rule/errors";
import Card from "../Card";
import CardBody from "../CardBody";
import CardTitle from "../CardTitle";
import ListEditor from "../ListEditor";
import MessageFormControl from "../MessageFormControl";
import NamedEmailField from "../NamedEmailField";
import RadioGroup from "../RadioGroup";
import Spaced from "../Spaced";

export interface InvitationEmailSettingsEditorProps {
  group: "workspace" | "project" | "team";
  value: InvitationEmailSettings;
  onChange: (value: InvitationEmailSettings) => void;
  messages: Message[];
  disabled?: boolean;
}

export function InvitationEmailSettingsEditor(
  props: InvitationEmailSettingsEditorProps
): ReactElement {
  const { group, value, onChange, messages, disabled = false } = props;

  const errors = useMemo(
    () => splitMessages(messages, invitationEmailSettingsErrorKeys),
    [messages]
  );

  const handleChange = useCallback(
    (value: InvitationEmailSettings) =>
      onChange({
        ...value,
        fromAddress: value.fromCoordinator ? null : value.fromAddress,
      }),
    [onChange]
  );

  return (
    <Card>
      <CardTitle>Invitation Emails</CardTitle>
      <CardBody>
        <Spaced maxW="72ch">
          <chakra.p>
            When an administrator or coordinator invites someone to the {group}{" "}
            by email...
          </chakra.p>
          <chakra.p>
            Copy the invitation email to the following email addresses:
          </chakra.p>
          <Spaced spacing="4" ml="6">
            <ListEditor.NamedEmail
              value={value.toAddresses}
              onChange={toAddresses => handleChange({ ...value, toAddresses })}
              messages={errors.toAddresses}
              disabled={disabled}
            />
          </Spaced>
          <chakra.p>The invitation email should be sent from:</chakra.p>{" "}
          <MessageFormControl messages={[]}>
            <RadioGroup
              value={value.fromCoordinator}
              onChange={fromCoordinator =>
                handleChange({ ...value, fromCoordinator })
              }
              options={[
                {
                  label:
                    "the email address of the coordinator or administrator",
                  value: true,
                },
                {
                  label: "the following email address:",
                  value: false,
                },
              ]}
              disabled={disabled}
            />
          </MessageFormControl>
          <Box ml="6">
            <NamedEmailField
              value={value.fromAddress ?? undefined}
              onChange={fromAddress => handleChange({ ...value, fromAddress })}
              messages={errors.fromAddress}
              disabled={disabled || value.fromCoordinator}
            />
          </Box>
        </Spaced>
      </CardBody>
    </Card>
  );
}
