import { check, checks } from "@cartographerio/permission";
import { ProjectV2, qualificationTrainee } from "@cartographerio/types";
import { ReactElement, ReactNode, useMemo } from "react";

import usePermissionCheck from "../hooks/usePermissionCheck";
import RequirePermissionPopover from "./RequirePermissionPopover";

export interface CreateSurveyPermissionPopoverProps {
  project: ProjectV2;
  children: (passes: boolean) => ReactNode;
}

export default function CreateSurveyPermissionPopover(
  props: CreateSurveyPermissionPopoverProps
): ReactElement {
  const { project, children } = props;

  const canCreateSurveys = useMemo(
    () => checks.survey.createWithAnyTeam(project),
    [project]
  );

  const isQualified = usePermissionCheck(
    check.and(
      ...project.qualificationIds.map(qualId =>
        check.hasQualification(qualificationTrainee(qualId))
      )
    )
  );

  return (
    <RequirePermissionPopover
      check={canCreateSurveys}
      failMessage={
        isQualified ? (
          <>
            You don&apos;t have the necessary permissions to contribute surveys
            to this project.
          </>
        ) : (
          <>
            You don&apos;t have the required active qualifications to contribute
            surveys to this project.
          </>
        )
      }
    >
      {projectPasses => children(projectPasses)}
    </RequirePermissionPopover>
  );
}
