import { selectOption } from "@cartographerio/atlas-form";
import {
  PartialParams,
  ProjectMemberSearchOptions,
  SearchResultsFormat,
  endpoints,
} from "@cartographerio/client";
import { isNonNullable } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import { checks } from "@cartographerio/permission";
import {
  ProjectRoleNameEnum,
  addRoleV2,
  qualificationRole,
  roleProjectIdV2,
  roleQualificationId,
} from "@cartographerio/types";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";
import { ReactElement, useCallback, useEffect, useMemo } from "react";

import queries from "../../../../../queries";
import { RouteProps } from "../../../../../routes";
import AddButton from "../../../../components/AddButton";
import { useIOConfirm, useIOErrorAlert } from "../../../../components/Alert";
import Container from "../../../../components/Container";
import InvitationModal from "../../../../components/InvitationModal";
import MemberList, {
  useApproveCallback,
  useRejectCallback,
  useRemoveCallback,
} from "../../../../components/MemberList";
import {
  OnMemberQualificationChange,
  OnMemberRoleChange,
} from "../../../../components/MemberList/column";
import MemberListToolbar from "../../../../components/MemberListToolbar";
import PageTopBar from "../../../../components/PageTopBar";
import ResetFiltersAlert from "../../../../components/ResetFiltersAlert";
import Select from "../../../../components/Select";
import Spaced from "../../../../components/Spaced";
import UserAddModal from "../../../../components/UserAddModal";
import { useApiParams } from "../../../../contexts/auth";
import { useApiUrlFormatter } from "../../../../hooks/useApiUrl";
import { usePageTitle } from "../../../../hooks/usePageTitle";
import { usePermissionCheckPasses } from "../../../../hooks/usePermissionCheckPasses";
import usePermissionCheckRunner from "../../../../hooks/usePermissionCheckRunner";
import { useProjectHasTeams } from "../../../../hooks/useProjectHasTeams";
import useRequirePermissionRedirect from "../../../../hooks/useRequirePermissionRedirect";
import { useSuspenseQueryData } from "../../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../../routes";
import ProjectPageHeader from "../ProjectPageHeader";
import { projectMemberListColumns } from "./columns";

export default function ProjectMemberListPage(
  props: RouteProps<typeof routes.workspace.project.member.list>
): ReactElement {
  const {
    path: { workspaceRef, projectRef },
    query,
    updateQuery,
  } = props;

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const toast = useToast();
  const confirmAlert = useIOConfirm();
  const errorAlert = useIOErrorAlert();
  const permissionCheckPasses = usePermissionCheckRunner();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const {
    page,
    count,
    order,
    role,
    q: searchTerm,
    team: teamFilter,
    type: memberType,
  } = query;

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  const multiTeam = useProjectHasTeams(workspace, project);

  const teams = useSuspenseSearchResults(
    queries.when(multiTeam, () =>
      queries.team.v2.forProject(apiParams, project.id)
    )
  );

  const selectedTeam = useMemo(
    () => teams?.find(({ alias }) => alias === teamFilter),
    [teamFilter, teams]
  );

  useEffect(() => {
    if (
      selectedTeam != null &&
      (!multiTeam || !project.teamIds.includes(selectedTeam.id))
    ) {
      updateQuery({ ...query, team: undefined });
    }
  }, [multiTeam, project.teamIds, query, selectedTeam, updateQuery]);

  const projectQualifications = useSuspenseSearchResults(
    queries.qualification.v1.search(apiParams),
    qualifications =>
      qualifications.filter(
        ({ id, alias }) =>
          project.qualificationIds.includes(id) ||
          (project.name === "River Condition Assessment" && alias === "rca")
      )
  );

  useRequirePermissionRedirect(checks.project.viewMembers(project), () =>
    routes.workspace.project.settings.url([workspace.alias, project.alias])
  );

  usePageTitle(`Members - ${project.name} - ${workspace.name}`);

  const handleRoleChange = useCallback<OnMemberRoleChange>(
    (member, role) =>
      (multiTeam
        ? confirmAlert({
            title: "Role change confirmation",
            message: (
              <>
                This will change the member&apos;s level of access across all
                teams within the project. Are you sure you want to continue?
              </>
            ),
          })
        : IO.pure(true)
      )
        .flatMap(confirmed =>
          confirmed
            ? queries.user.v2
                .save(queryClient, apiParams, member.userId, {
                  ...member,
                  roles: addRoleV2(member.roles, role, "replace"),
                })
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() =>
          toast({ status: "success", title: "Role updated successfully" })
        )
        .recover(() =>
          toast({ status: "error", description: "Role change cancelled" })
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, multiTeam, queryClient, toast]
  );

  const handleQualificationChange = useCallback<OnMemberQualificationChange>(
    (member, qualificationId, roleName) =>
      confirmAlert({
        title: "Role change confirmation",
        message: (
          <>
            This will change the user&apos;s level of access across all projects
            that include this qualification. Are you sure you want to continue?
          </>
        ),
      })
        .flatMap(confirmed =>
          confirmed
            ? queries.user.v2
                .save(queryClient, apiParams, member.userId, {
                  ...member,
                  qualificationRoles: [
                    ...member.qualificationRoles.filter(
                      role => roleQualificationId(role) !== qualificationId
                    ),
                    ...(roleName != null
                      ? [qualificationRole(roleName, qualificationId)]
                      : []),
                  ],
                })
                .tapError(errorAlert)
            : IO.fail("Cancelled")
        )
        .tap(() =>
          toast({ status: "success", title: "Role updated successfully" })
        )
        .recover(() =>
          toast({ status: "error", description: "Role change cancelled" })
        )
        .unsafeRun(),
    [apiParams, confirmAlert, errorAlert, queryClient, toast]
  );

  const handleRemove = useRemoveCallback(
    "Removed from Project",
    useCallback(
      roles => roles.filter(role => roleProjectIdV2(role) !== project.id),
      [project.id]
    )
  );

  const handleApprove = useApproveCallback();
  const handleReject = useRejectCallback();

  const {
    isOpen: isAddOpen,
    onOpen: onAddOpen,
    onClose: onAddClose,
  } = useDisclosure();

  const {
    isOpen: isInviteOpen,
    onOpen: onInviteOpen,
    onClose: onInviteClose,
  } = useDisclosure();

  const canAddFromWorkspace = usePermissionCheckPasses(
    checks.workspace.admin(workspace)
  );
  const canInvite = usePermissionCheckPasses(
    checks.project.grantAccess(project)
  );

  const canViewMember = canAddFromWorkspace;

  const columns = useMemo(
    () =>
      projectMemberListColumns({
        project,
        teams: multiTeam ? teams : undefined,
        selectedTeam: selectedTeam?.id,
        qualifications: projectQualifications,
        permissionCheckPasses,
        onRoleChange: handleRoleChange,
        onQualificationChange: handleQualificationChange,
      }),
    [
      handleQualificationChange,
      handleRoleChange,
      multiTeam,
      permissionCheckPasses,
      project,
      projectQualifications,
      selectedTeam?.id,
      teams,
    ]
  );

  const teamOptions = useMemo(
    () => teams?.map(({ name, alias }) => selectOption(alias, name)),
    [teams]
  );

  const addButton = useMemo(
    () =>
      canAddFromWorkspace || canInvite ? (
        <AddButton
          label="Add"
          options={[
            canAddFromWorkspace
              ? {
                  key: "add",
                  label: "Add from Workspace",
                  onClick: onAddOpen,
                }
              : null,
            canInvite
              ? {
                  key: "invite",
                  label: "Invite by Email",
                  onClick: onInviteOpen,
                }
              : null,
          ].filter(isNonNullable)}
        />
      ) : undefined,
    [canAddFromWorkspace, canInvite, onAddOpen, onInviteOpen]
  );

  const searchOpts = useMemo(
    (): PartialParams<ProjectMemberSearchOptions> => ({
      memberType,
      q: query.q,
      team: selectedTeam?.id,
      role: query.role,
      skip: query.page * query.count,
      limit: query.count,
    }),
    [memberType, query.count, query.page, query.q, query.role, selectedTeam?.id]
  );

  const { data, error } = useQuery(
    queries.member.v1.projectSearch(apiParams, project.id, null, searchOpts)
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.member.v1.projectSearchUrl(project.id, null, {
          ...omit(searchOpts, "skip", "limit"),
          format,
        })
      ),
    [formatApiUrl, project.id, searchOpts]
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="projects"
        project={project}
        projectPage="members"
      />
      <ProjectPageHeader
        workspace={workspace}
        project={project}
        selected="members"
      />
      <Container width="wide">
        <Spaced>
          <MemberListToolbar
            searchTerm={searchTerm}
            workspace={workspace}
            memberType={memberType}
            enableOptionsModal={false}
            roleSelect={
              <Select.Nullable
                value={role}
                options={ProjectRoleNameEnum.entries}
                onChange={role => updateQuery({ ...query, role, page: 0 })}
                placeholder="All Roles"
                background="transparent"
                w="fit-content"
                maxW="52"
              />
            }
            inGroup={teamFilter}
            defaultGroupLabel="All Teams"
            groupOptions={multiTeam ? teamOptions : undefined}
            onGroupChange={team =>
              updateQuery({ ...query, team: team ?? undefined, page: 0 })
            }
            onSearchTermChange={q =>
              updateQuery({
                ...query,
                q: q ?? undefined,
                page: 0,
              })
            }
            onMemberTypeChange={type => updateQuery({ ...query, type })}
            downloadUrl={downloadUrl}
            addButton={addButton}
          />
          <ResetFiltersAlert
            route={routes.workspace.project.member.list}
            query={query}
            updateQuery={updateQuery}
          />
          <MemberList
            data={data ?? null}
            error={error}
            page={page}
            count={count}
            order={order}
            workspace={workspace}
            columns={columns}
            enableOptionsModal={false}
            changeIdentityRedirect={routes.workspace.home.url([
              workspace.alias,
            ])}
            onRemove={handleRemove}
            onApprove={handleApprove}
            onReject={handleReject}
            itemLink={
              canViewMember
                ? member =>
                    member.type === "InvitedMember"
                      ? routes.workspace.project.invitation.view.url([
                          workspace.alias,
                          project.alias,
                          member.invitationId,
                        ])
                      : routes.workspace.project.member.update.url([
                          workspace.alias,
                          project.alias,
                          member.userId,
                        ])
                : undefined
            }
            onSearchTermChange={q =>
              updateQuery({
                ...query,
                q: q ?? undefined,
                page: 0,
              })
            }
            onPageChange={page => updateQuery({ ...query, page })}
            onOrderChange={order => updateQuery({ ...query, order })}
          />
        </Spaced>

        {canAddFromWorkspace && (
          <UserAddModal.Project
            title={`Add to ${selectedTeam?.name ?? "Project"}`}
            isOpen={isAddOpen}
            onClose={onAddClose}
            workspace={workspace}
            project={project}
            teams={teams}
            defaultTeam={selectedTeam?.id}
          />
        )}

        {canInvite && (
          <InvitationModal.Project
            title={`Invite to ${selectedTeam?.name ?? "Project"}`}
            isOpen={isInviteOpen}
            onClose={onInviteClose}
            workspace={workspace}
            project={project}
            teams={teams}
            defaultTeam={selectedTeam?.id}
          />
        )}
      </Container>
    </>
  );
}
