import { selectOption } from "@cartographerio/atlas-form";
import { IO } from "@cartographerio/io";
import {
  ProjectTemplate,
  ProjectV2,
  QualificationRoleNameEnum,
  UserId,
  UserV2,
  WorkspaceV2,
  projectApproverV2,
  projectMemberV2,
  projectSurveyorV2,
  qualificationTrainee,
  randomProjectId,
  uniqRolesV2,
  unsafeProjectAlias,
  unsafeQualificationAlias,
  unsafeWorkspaceAlias,
  workspaceActiveV2,
  workspaceOwnerV2,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  Card,
  CardBody,
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { outdent } from "outdent";
import { ReactElement, useCallback, useMemo, useState } from "react";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { indexedErrors } from "../../../../schema/rule/errors";
import recordWithId from "../../../../util/recordWithId";
import { useIOErrorAlert } from "../../../components/Alert";
import CheckboxGroup from "../../../components/CheckboxGroup";
import Heading from "../../../components/Heading";
import HelpText from "../../../components/HelpText";
import PageContainer from "../../../components/PageContainer";
import PageTopBar from "../../../components/PageTopBar";
import SaveButton from "../../../components/SaveButton";
import Spaced from "../../../components/Spaced";
import { useApiParams } from "../../../contexts/auth";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { useSuspenseSearchResults } from "../../../hooks/useSuspenseSearchResults";
import { routes } from "../../../routes";
import AdminPageHeader from "../AdminPageHeader";
import {
  blankExistingRcaWorkspace,
  blankNewRcaWorkspace,
  rcaWorkspaceBillingContactTraineeId,
  rcaWorkspaceSeparateBillingContact,
  setItem,
} from "./helpers";
import { RcaWorkspaceSection } from "./RcaWorkspace";
import {
  LocalId,
  Person,
  RcaSetupData,
  RcaWorkspace,
  rcaSetupDataRule,
} from "./schema";

export default function AdminRcaSetupPage(
  _props: RouteProps<typeof routes.admin.rcaSetup>
): ReactElement {
  const [value, setValue] = useState<RcaSetupData>([]);

  const messages = useMemo(() => rcaSetupDataRule(value), [value]);

  const errors = useMemo(
    () => indexedErrors(messages, value.length),
    [messages, value.length]
  );

  const apiParams = useApiParams();
  const queryClient = useQueryClient();
  const errorAlert = useIOErrorAlert();
  const toast = useToast();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.all(apiParams)
  );

  const rcaQualificationId = useSuspenseQueryData(
    queries.qualification.v1.readOrFail(
      apiParams,
      unsafeQualificationAlias("rca")
    ),
    recordWithId
  );

  const allTrainers = useSuspenseSearchResults(
    queries.user.v2.search(apiParams, {
      qualification: rcaQualificationId,
      qualificationRole: QualificationRoleNameEnum.Trainer,
    })
  );

  const allTrainerLookup = useMemo(
    () =>
      allTrainers.reduce<Record<UserId, UserV2>>(
        (acc, user) => ({ ...acc, [user.id]: user }),
        {}
      ),
    [allTrainers]
  );

  const allTrainerOptions = useMemo(
    () => allTrainers.map(({ id, screenName }) => selectOption(id, screenName)),
    [allTrainers]
  );

  const [trainers, setTrainers] = useState<UserV2[]>([]);
  const trainersIds = useMemo(() => trainers.map(({ id }) => id), [trainers]);

  const rcaTemplate =
    useSuspenseSearchResults(
      queries.project.template.v1.search(apiParams),
      templates => templates.find(({ alias }) => alias === "rca")
    ) ?? raise<ProjectTemplate>("RCA template not found");

  const mrsTrainingProject = useSuspenseQueryData(
    queries.project.v2.readOrFail(
      apiParams,
      unsafeProjectAlias("rca"),
      unsafeWorkspaceAlias("mrstraining")
    )
  );

  const rcaCalibrationSubmissionProject = useSuspenseQueryData(
    queries.project.v2.readOrFail(
      apiParams,
      unsafeProjectAlias("component4submissions"),
      unsafeWorkspaceAlias("rcaassessment")
    )
  );

  const [saving, setSaving] = useState(false);

  const onSave = useCallback(() => {
    if (messages.length === 0) {
      const createRcaProject = (rcaWorkspace: RcaWorkspace): ProjectV2 => ({
        ...rcaTemplate,
        id: randomProjectId(),
        workspaceId: (rcaWorkspace.workspaceDetails.workspace as WorkspaceV2)
          .id,
        qualificationIds: [rcaQualificationId],
        features: [],
        teamIds: [],
        projectVisibility: rcaWorkspace.workspaceDetails.projectVisibility,
      });

      const saveTrainer =
        (results: (readonly [WorkspaceV2, ProjectV2])[]) =>
        (trainer: UserV2): IO<UserV2> =>
          queries.user.v2
            .save(queryClient, apiParams, trainer.id, {
              ...trainer,
              roles: uniqRolesV2(
                trainer.roles.concat(
                  results.flatMap(([workspace, project]) => [
                    workspaceActiveV2(workspace.id),
                    projectMemberV2(project.id),
                  ])
                )
              ),
            })
            .flatMap(IO.fromResult);

      const saveRcaWorkspace = (workspace: WorkspaceV2): IO<WorkspaceV2> =>
        queries.workspace.v2
          .save(queryClient, apiParams, workspace)
          .flatMap(IO.fromResult);

      const saveRcaProject = (project: ProjectV2): IO<ProjectV2> =>
        queries.project.v2
          .save(queryClient, apiParams, project)
          .flatMap(IO.fromResult)
          .tap(_ =>
            queries.project.emailSettings.v1.save(
              queryClient,
              apiParams,
              project.id,
              {
                invitation: {
                  fromCoordinator: true,
                  fromAddress: null,
                  toAddresses: [],
                },
                complete: { toApprovers: false, toAddresses: [] },
                approved: {
                  toSurveyors: false,
                  toAddresses: [],
                  fromApprover: false,
                  fromAddress: null,
                },
              }
            )
          );

      const saveRcaProjectIfNecessary = (
        rcaWorkspace: RcaWorkspace
      ): IO<ProjectV2> =>
        rcaWorkspace.type === "NewRcaWorkspace" ||
        rcaWorkspace.workspaceDetails.project === "new"
          ? saveRcaProject(createRcaProject(rcaWorkspace))
          : IO.pure(rcaWorkspace.workspaceDetails.project);

      const inviteRcaPerson = (
        person: Person,
        project: ProjectV2,
        isTrainee: boolean,
        isOwner: boolean
      ) =>
        queries.invitation.v3
          .create(queryClient, apiParams, {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email,
            workspaceId: project.workspaceId,
            // *Trainee* role for the RCA qualification:
            qualificationRoles: isTrainee
              ? [qualificationTrainee(rcaQualificationId)]
              : [],
            roles: [
              ...(isTrainee
                ? [
                    // *Approver* role in the new RCA project:
                    workspaceActiveV2(project.workspaceId),
                    projectApproverV2(project.id),
                    // *Project Member* role in the MRS Training Examples project:
                    workspaceActiveV2(mrsTrainingProject.workspaceId),
                    projectMemberV2(mrsTrainingProject.id),
                    // *Project Surveyor* role in the RCA Calibration Submissions project:
                    workspaceActiveV2(
                      rcaCalibrationSubmissionProject.workspaceId
                    ),
                    projectSurveyorV2(rcaCalibrationSubmissionProject.id),
                  ]
                : []),
              ...(isOwner ? [workspaceOwnerV2(project.workspaceId)] : []),
            ],
          })
          .flatMap(IO.fromResult)
          .void();

      const inviteTrainees = (
        trainees: Person[],
        project: ProjectV2,
        ownerLocalId: LocalId | null
      ): IO<void> =>
        IO.forEach(
          trainees.map(trainee =>
            inviteRcaPerson(
              trainee,
              project,
              true,
              trainee.localId === ownerLocalId
            )
          )
        );

      return IO.wrap(() => setSaving(true))
        .andThen(
          IO.parallel(
            value.map(rcaWorkspace => {
              const billingContactTraineeId =
                rcaWorkspaceBillingContactTraineeId(rcaWorkspace);

              const separateOwner =
                rcaWorkspaceSeparateBillingContact(rcaWorkspace);

              return IO.tupled([
                saveRcaWorkspace(rcaWorkspace.workspaceDetails.workspace!),
                saveRcaProjectIfNecessary(rcaWorkspace),
              ]).tap(([_, project]) =>
                inviteTrainees(
                  rcaWorkspace.trainees,
                  project,
                  billingContactTraineeId
                ).andThen(
                  separateOwner == null
                    ? IO.void()
                    : inviteRcaPerson(separateOwner, project, false, true)
                )
              );
            })
          )
        )
        .flatMap(results => IO.parallel(trainers.map(saveTrainer(results))))
        .tap(() =>
          toast({
            title: "Setup Successful",
            status: "success",
            isClosable: true,
          })
        )
        .tapError(errorAlert)
        .cleanup(() => setSaving(false))
        .unsafeRun();
    }
  }, [
    apiParams,
    errorAlert,
    messages.length,
    mrsTrainingProject.id,
    mrsTrainingProject.workspaceId,
    queryClient,
    rcaCalibrationSubmissionProject.id,
    rcaCalibrationSubmissionProject.workspaceId,
    rcaQualificationId,
    rcaTemplate,
    toast,
    trainers,
    value,
  ]);

  return (
    <>
      <PageTopBar admin={true} basePage="rca-setup" />
      <AdminPageHeader title="RCA Trainees Setup" width="default" />
      <PageContainer>
        <Spaced>
          <Spaced spacing="8">
            <Card>
              <CardBody>
                <Heading level="subsection" mt="0" mb="4">
                  Trainers
                </Heading>
                <CheckboxGroup
                  value={trainersIds}
                  options={allTrainerOptions}
                  onChange={trainerIds =>
                    setTrainers(trainerIds.map(id => allTrainerLookup[id]))
                  }
                  columns={2}
                />
              </CardBody>
            </Card>

            {value.map((rcaWorkspace, i) => (
              <RcaWorkspaceSection
                key={i}
                value={rcaWorkspace}
                messages={errors[i]}
                workspaces={workspaces}
                onChange={rcaWorkspace =>
                  setValue(setItem(value, i, rcaWorkspace))
                }
                onRemove={() => setValue(setItem(value, i))}
              />
            ))}
            <Menu placement="bottom-start">
              <MenuButton as={ChakraButton} variant="outline">
                Add Workspace
              </MenuButton>
              <MenuList
                zIndex="popover"
                fontSize="sm"
                maxH="30em"
                overflowY="auto"
              >
                <MenuItem
                  _hover={{ bg: "gray.100", textDecoration: "none" }}
                  onClick={() => setValue([...value, blankNewRcaWorkspace()])}
                >
                  New Workspace
                </MenuItem>
                <MenuItem
                  _hover={{ bg: "gray.100", textDecoration: "none" }}
                  onClick={() =>
                    setValue([...value, blankExistingRcaWorkspace()])
                  }
                >
                  Existing Workspace
                </MenuItem>
              </MenuList>
            </Menu>
            {value.length > 0 && (
              <Spaced spacing="4">
                <SaveButton
                  messages={messages}
                  isLoading={saving}
                  loadingText="Saving"
                  disabled={saving || messages.length > 0 || value.length === 0}
                  onClick={onSave}
                />
                <HelpText text={SAVE_BUTTON_HELP} />
              </Spaced>
            )}
          </Spaced>
        </Spaced>
      </PageContainer>
    </>
  );
}

const SAVE_BUTTON_HELP = outdent`
  Clicking **Save** will immediately send invitation emails to all trainees and workspace owners on the page.
`;
