import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useLocalStorage } from "usehooks-ts";

export type LocationMode = "NGR" | "GPS";

type LocationModeContextValue = [LocationMode, (mode: LocationMode) => void];

const LocationModeContext =
  createContext<LocationModeContextValue | undefined>(undefined);

export function useLocationMode(): LocationModeContextValue {
  return (
    useContext(LocationModeContext) ??
    raise(new Error("No location mode context found"))
  );
}

export interface LocationModeProviderProps {
  defaultValue?: LocationMode;
  onChange?: (mode: LocationMode) => void;
  children: ReactNode;
}

export function LocationModeProvider(props: LocationModeProviderProps) {
  const { defaultValue = "NGR", onChange, children } = props;

  const [value, setValue] = useLocalStorage<LocationMode>(
    "LocationMode",
    defaultValue
  );

  const contextValue = useMemo<LocationModeContextValue>(
    () => [value, setValue],
    [setValue, value]
  );

  useEffect(() => {
    onChange?.(value);
  }, [value, onChange]);

  return (
    <LocationModeContext.Provider value={contextValue}>
      {children}
    </LocationModeContext.Provider>
  );
}
