import Front from "@frontapp/plugin-sdk";
import { ReactElement, useEffect, useState } from "react";

import { FrontContext, FrontPlugin } from "../frontapp";

export default function FrontPluginPage(): ReactElement | null {
  const [context, setContext] = useState<FrontContext | null>(null);

  useEffect(() => {
    const subscription = Front.contextUpdates.subscribe(ctx =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setContext(ctx as any)
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return context == null ? null : <FrontPlugin context={context} />;
}
