import { errorMessage } from "@cartographerio/atlas-core";
import { TeamV2 } from "@cartographerio/types";

import { aliasRule } from "./helpers";
import { rule } from "./rule";

export const { rule: teamRule, keys: teamErrorKeys } = rule
  .build<TeamV2>()
  .field("name", rule.nonEmpty([errorMessage("You must specify a team name")]))
  .field("alias", rule.nonEmpty([errorMessage("You must specify an alias")]))
  .field("alias", rule.nullable(aliasRule))
  .finish();
