import { InvitationCodeSortKey } from "@cartographerio/client";
import {
  InvitationCodeV2,
  canCancelInvitationCode,
  ddmmyyyy,
  formatTimestamp,
} from "@cartographerio/types";
import { IconButton, LinkOverlay } from "@chakra-ui/react";
import {
  IoCloseSharp,
  IoShareOutline,
  IoStopCircleOutline,
} from "react-icons/io5";
import { Link } from "react-router-dom";

import { InvitationCodeLabel } from "../InvitationCodeLabel";
import { InvitationCodeStatusBadge } from "../InvitationStatusBadge";
import { ActionsColumn, Column } from "../SearchResultsList/column";

export function col(params: Column<InvitationCodeV2, InvitationCodeSortKey>) {
  return params;
}

export interface InvitationCodeListColumnsProps {
  link?: (invite: InvitationCodeV2) => string;
}

function nameColumn({ link }: InvitationCodeListColumnsProps) {
  return col({
    title: "Name",
    orderBy: "name",
    width: "auto",
    render: invitation => {
      const label = invitation.name;
      return link == null ? (
        label
      ) : (
        <LinkOverlay as={Link} to={link(invitation)}>
          {label}
        </LinkOverlay>
      );
    },
  });
}

const codeColumn = col({
  title: "Code",
  width: "13ch",
  render: invitation => <InvitationCodeLabel alias={invitation.alias} />,
});

const createdColumn = col({
  title: "Created",
  orderBy: "created",
  defaultOrder: "desc",
  width: "10ch",
  render: invitation =>
    formatTimestamp(invitation.created, { format: ddmmyyyy }),
});

const statusColumn = col({
  title: "Status",
  orderBy: "created",
  defaultOrder: "desc",
  width: "13ch",
  render: invitation => (
    <InvitationCodeStatusBadge code={invitation} w="100%" />
  ),
});

export function invitationCodeListColumns(
  props: InvitationCodeListColumnsProps
) {
  return [nameColumn(props), codeColumn, createdColumn, statusColumn];
}

interface InvitationCodeListActionsOptions {
  onShare?: (invitation: InvitationCodeV2) => void;
  onCancel: (invitation: InvitationCodeV2) => void;
  onRemove?: (invitation: InvitationCodeV2) => void;
}

export function invitationListActions(
  options: InvitationCodeListActionsOptions
): ActionsColumn<InvitationCodeV2> {
  const { onCancel, onShare, onRemove } = options;

  return {
    renderButtons: invitation => [
      onShare != null && (
        <IconButton
          key="use-code"
          variant="outline"
          icon={<IoShareOutline size="1.25rem" />}
          title="Share Code"
          aria-label="Share Code"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onShare(invitation);
          }}
        />
      ),
      canCancelInvitationCode(invitation) && (
        <IconButton
          key="cancel"
          variant="outline"
          icon={<IoStopCircleOutline size="1.25rem" />}
          title="Cancel"
          aria-label="Cancel"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onCancel(invitation);
          }}
        />
      ),
      onRemove != null && (
        <IconButton
          key="delete"
          variant="outline"
          icon={<IoCloseSharp size="1.25rem" />}
          title="Delete"
          aria-label="Delete"
          onClick={evt => {
            evt.preventDefault();
            evt.stopPropagation();
            onRemove(invitation);
          }}
        />
      ),
    ],
  };
}
