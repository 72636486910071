import { errorMessage } from "@cartographerio/atlas-core";
import { UserV2 as User } from "@cartographerio/types";

import { rule } from "./rule";

export const { rule: userRule, keys: userErrorKeys } = rule
  .build<User>()
  .field(
    "screenName",
    rule.nonEmpty([errorMessage("You must specify a screen name")])
  )
  .field(
    "firstName",
    rule.nonEmpty([errorMessage("You must specify a first name")])
  )
  .field(
    "lastName",
    rule.nonEmpty([errorMessage("You must specify a last name")])
  )
  .field(
    "email",
    rule.email([errorMessage("You must specify an email adddress")])
  )
  .field(
    "roles",
    rule.nonEmpty([errorMessage("You must select at least one role")])
  )
  .finish();
