import { CredentialsV2 } from "@cartographerio/types";
import * as Sentry from "@sentry/react";
import { ReactElement, ReactNode, useEffect } from "react";

import { useOptCredentialsV2 } from "../contexts/auth";

export default function useSentry(
  credentials: CredentialsV2 | null | undefined
) {
  useEffect(() => {
    Sentry.configureScope(scope =>
      scope.setUser(
        credentials?.identity == null
          ? null
          : {
              id: credentials.identity.userId ?? undefined,
              name: credentials.identity.screenName ?? undefined,
              email: credentials.identity.email ?? undefined,
              accessToken: credentials.token,
            }
      )
    );
  }, [
    credentials?.token,
    credentials?.identity.screenName,
    credentials?.identity.userId,
    credentials?.identity,
  ]);
}

export interface SentryProviderProps {
  children: ReactNode;
}

export function SentryProvider(props: SentryProviderProps): ReactElement {
  const { children } = props;
  const credentials = useOptCredentialsV2();
  useSentry(credentials);
  return <>{children}</>;
}
