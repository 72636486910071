import { prettyPrintJson } from "@cartographerio/util";
import { Box, Flex, SystemProps, chakra } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { Highlight } from "../hooks/highlight";
import Pre from "./Pre";

export interface JsonViewProps extends SystemProps {
  label?: string;
  value: unknown;
  highlight?: Highlight;
  copyToClipboard?: boolean;
}

export default function JsonView(props: JsonViewProps): ReactElement {
  const {
    label,
    value,
    highlight,
    copyToClipboard,
    overflow = "auto",
    maxH,
    ...rest
  } = props;

  const text = useMemo(() => prettyPrintJson(value), [value]);

  return (
    <Box>
      {label && (
        <chakra.p fontWeight="semibold" mb="2">
          {label}
        </chakra.p>
      )}
      <Flex
        direction="column"
        justify="stretch"
        position="relative"
        maxH={maxH}
      >
        <Box position="relative" overflowY="auto">
          <Pre
            text={text}
            highlight={highlight}
            copyToClipboard={copyToClipboard}
            overflow={overflow}
            // This vertically centers the "Copy" button when there's one line of text:
            py="3"
            bg="gray.100"
            tabIndex={-1}
            {...rest}
          ></Pre>
        </Box>
      </Flex>
    </Box>
  );
}
