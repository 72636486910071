import { checkExhausted } from "@cartographerio/util";
import {
  Heading as ChakraHeading,
  HeadingProps as ChakraHeadingProps,
} from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";

export interface HeadingProps extends ChakraHeadingProps {
  level: "section" | "subsection" | "subsubsection";
  children: ReactNode;
}

export default function Heading(props: HeadingProps): ReactElement {
  const { level, children, size, mt, mb, ...rest } = props;

  switch (level) {
    case "section":
      return (
        <ChakraHeading
          size={size ?? "xl"}
          mt={mt ?? "4"}
          mb={mb ?? "2"}
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    case "subsection":
      return (
        <ChakraHeading
          size={size ?? "lg"}
          mt={mt ?? "4"}
          mb={mb ?? "2"}
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    case "subsubsection":
      return (
        <ChakraHeading
          size={size ?? "md"}
          mt={mt ?? "4"}
          mb={mb ?? "2"}
          {...rest}
        >
          {children}
        </ChakraHeading>
      );

    default:
      return checkExhausted(level);
  }
}
