import { Tab, TabProps } from "@chakra-ui/react";
import { ReactElement } from "react";

import Link from "./Link";

interface TabLinkProps extends TabProps {
  to: string;
}

export default function TabLink(props: TabLinkProps): ReactElement {
  const { to, children, ...rest } = props;

  return (
    <Tab {...rest} p="0">
      <Link.Internal
        py="2"
        px="4"
        to={to}
        color="inherit"
        _hover={{ textDecoration: "inherit" }}
        _focus={{ textDecoration: "inherit" }}
        _active={{ textDecoration: "inherit" }}
      >
        {children}
      </Link.Internal>
    </Tab>
  );
}
