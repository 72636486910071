import { Optional } from "@cartographerio/guard";
import { QueryKey } from "@tanstack/react-query";

import { UseQueryOpts } from "./base";

export type EmptyKey = ["empty"];
export type ConstantKey = ["constant", unknown];

export const empty: UseQueryOpts<null, EmptyKey> = {
  queryKey: ["empty"],
  queryFn: () => Promise.resolve(null),
};

export function constant<A>(value: A): UseQueryOpts<A, ConstantKey> {
  return {
    queryKey: ["constant", value],
    queryFn: () => Promise.resolve(value),
  };
}

export function optional<A, B, K extends QueryKey>(
  a: Optional<A>,
  func: (a: A) => UseQueryOpts<B, K>
): UseQueryOpts<B | null, K | EmptyKey>;
export function optional<
  A,
  B,
  K extends QueryKey,
  C = never,
  L extends QueryKey = never
>(
  a: Optional<A>,
  func: (a: A) => UseQueryOpts<B, K>,
  fallback: () => UseQueryOpts<C, L>
): UseQueryOpts<B | C, K | L>;
export function optional<
  A,
  B,
  K extends QueryKey,
  C = never,
  L extends QueryKey = never
>(
  a: Optional<A>,
  func: (a: A) => UseQueryOpts<B, K>,
  fallback?: () => UseQueryOpts<C, L>
): unknown {
  type Ans = UseQueryOpts<B | null, K | EmptyKey>;
  const ans = a != null ? func(a) : fallback?.() ?? empty;
  return ans as Ans;
}

export function when<A, K extends QueryKey>(
  test: boolean,
  func: () => UseQueryOpts<A, K>
): UseQueryOpts<A | null, K | EmptyKey> {
  type Ans = UseQueryOpts<A | null, K | EmptyKey>;
  const ans = test ? func() : empty;
  return ans as Ans;
}

export function unless<A, K extends QueryKey>(
  test: boolean,
  func: () => UseQueryOpts<A, K>
): UseQueryOpts<A | null, K | EmptyKey> {
  type Ans = UseQueryOpts<A | null, K | EmptyKey>;
  const ans = test ? empty : func();
  return ans as Ans;
}

export function cond<A, B, K extends QueryKey>(
  test: boolean,
  func: () => UseQueryOpts<A, K>,
  orElse: () => B
): UseQueryOpts<A | B, K | EmptyKey> {
  type Ans = UseQueryOpts<A | B, K | EmptyKey>;
  const ans = test ? func() : constant(orElse());
  return ans as Ans;
}
