import { Box, BoxProps } from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo } from "react";

import Markdown from "./Markdown";

interface HelpTextProps extends BoxProps {
  text?: string;
  children?: ReactNode;
}

export default function HelpText(props: HelpTextProps): ReactElement {
  const { text: markdown, children, ...rest } = props;

  const rendered = useMemo(
    () => (markdown == null ? null : <Markdown text={markdown} />),
    [markdown]
  );

  return (
    <Box color="gray.500" w="full" fontSize="sm" {...rest}>
      {rendered}
      {children}
    </Box>
  );
}
