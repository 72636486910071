import { SelectOption } from "@cartographerio/atlas-form";
import {
  PartialParams,
  SearchResultsFormat,
  SortOrder,
  UserSearchOptionsV2,
  UserSortKey,
  endpoints,
} from "@cartographerio/client";
import { checks } from "@cartographerio/permission";
import {
  GlobalRoleName,
  ProjectId,
  ProjectRoleName,
  QualificationId,
  QualificationRoleName,
  TeamId,
  TeamRoleName,
  UserId,
  UserV2,
  WorkspaceId,
  WorkspaceRoleName,
} from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../../queries";
import recordWithId from "../../../util/recordWithId";
import { useApiParams, useCredentialsV2 } from "../../contexts/auth";
import { useApiUrlFormatter } from "../../hooks/useApiUrl";
import usePermissionCheckRunner from "../../hooks/usePermissionCheckRunner";
import { routes } from "../../routes";
import DownloadMenu from "../DownloadMenu";
import SearchField from "../SearchField";
import SearchResultsList from "../SearchResultsList";
import { ActionsColumn, Column } from "../SearchResultsList/column";
import Select from "../Select";
import Spaced from "../Spaced";
import { OnUserAction, actionsColumn } from "./column";

export * from "./role";

export interface UserListProps<G extends string = string> {
  searchTerm?: string;
  page: number;
  count: number;
  order?: SortOrder<UserSortKey>;
  columns: Column<UserV2, UserSortKey>[];
  actions?: ActionsColumn<UserV2>;
  workspace?: WorkspaceId;
  project?: ProjectId;
  team?: TeamId;
  qualification?: QualificationId;
  globalRole?: GlobalRoleName | null;
  workspaceRole?: WorkspaceRoleName | null;
  projectRole?: ProjectRoleName | null;
  teamRole?: TeamRoleName | null;
  qualificationRole?: QualificationRoleName | null;
  enableOptionsModal?: boolean;
  roleSelect?: ReactNode;
  inGroup?: G;
  defaultGroupLabel?: string;
  groupOptions?: SelectOption<G>[];
  onGroupChange?: (group: G | null) => void;
  changeIdentityRedirect?: string;
  addButton?: ReactNode;
  onRemove?: OnUserAction;
  onSearchTermChange?: (q: string | null) => void;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<UserSortKey>) => void;
  itemLink?: (user: UserV2) => string;
  selection?: UserV2[];
  onSelectionChange?: (selection: UserV2[]) => void;
}

export default function UserList<G extends string = string>(
  props: UserListProps<G>
): ReactElement {
  const {
    searchTerm,
    page,
    count,
    order = "name-asc",
    columns,
    workspace,
    project,
    team,
    qualification,
    globalRole,
    workspaceRole,
    projectRole,
    teamRole,
    qualificationRole,
    roleSelect,
    inGroup,
    defaultGroupLabel = "All Members",
    groupOptions,
    onGroupChange,
    changeIdentityRedirect,
    addButton,
    onRemove,
    onSearchTermChange,
    onPageChange,
    onOrderChange,
    itemLink,
    selection = [],
    onSelectionChange,
  } = props;

  const apiParams = useApiParams();
  const permissionCheckPasses = usePermissionCheckRunner();
  const navigate = useNavigate();
  const { identity } = useCredentialsV2();

  const handleImpersonate = useCallback<OnUserAction>(
    user => {
      navigate(
        routes.identity.change.url([user.id], {
          go: changeIdentityRedirect,
        })
      );
    },
    [changeIdentityRedirect, navigate]
  );

  const actions = useMemo(
    () =>
      actionsColumn(
        identity.userId,
        permissionCheckPasses(checks.auth.changeIdentity()) &&
          changeIdentityRedirect != null
          ? handleImpersonate
          : null,
        onRemove
      ),
    [
      identity.userId,
      permissionCheckPasses,
      changeIdentityRedirect,
      handleImpersonate,
      onRemove,
    ]
  );

  const searchFilters = useMemo(
    (): PartialParams<UserSearchOptionsV2> => ({
      workspace,
      project,
      team,
      qualification,
      q: searchTerm !== "" ? searchTerm : undefined,
      globalRole,
      workspaceRole,
      projectRole,
      teamRole,
      qualificationRole,
      order,
    }),
    [
      globalRole,
      order,
      project,
      projectRole,
      qualification,
      qualificationRole,
      searchTerm,
      team,
      teamRole,
      workspace,
      workspaceRole,
    ]
  );

  const { data, error } = useQuery(
    queries.user.v2.search(apiParams, {
      ...searchFilters,
      skip: page * count,
      limit: count,
    })
  );

  const formatApiUrl = useApiUrlFormatter();

  const downloadUrl = useCallback(
    (format: SearchResultsFormat): string =>
      formatApiUrl(
        endpoints.user.v2.searchUrl({
          ...searchFilters,
          format,
        })
      ),
    [formatApiUrl, searchFilters]
  );

  const [allSelected, setAllSelected] = useState(false);
  const [allResults, setAllResults] = useState<UserV2[] | null>(null);

  useEffect(() => {
    setAllSelected(false);
    setAllResults(null);
  }, [searchFilters]);

  const handleSearchChange = useCallback(
    (search: string | null) => onSearchTermChange?.(search),
    [onSearchTermChange]
  );

  const onSelectItem = useCallback(
    (user: UserV2) => {
      if (onSelectionChange != null) {
        const filtered = selection.filter(({ id }) => id !== user.id) ?? [];
        onSelectionChange(
          selection?.length === filtered.length
            ? [...selection, user]
            : filtered
        );
        setAllSelected(false);
      }
    },
    [onSelectionChange, selection]
  );

  const onSelectAll = useCallback(() => {
    if (onSelectionChange != null) {
      if (allSelected) {
        onSelectionChange([]);
        setAllSelected(false);
      } else if (allResults != null) {
        onSelectionChange(allResults);
        setAllSelected(true);
      } else {
        endpoints.user.v2
          .search(apiParams, searchFilters)
          .tap(({ results }) => {
            onSelectionChange(results);
            setAllResults(results);
            setAllSelected(true);
          })
          .unsafeRun();
      }
    }
  }, [allResults, allSelected, apiParams, onSelectionChange, searchFilters]);

  return (
    <Spaced>
      <HStack w="100%" justifyContent="stretch" wrap="wrap">
        <SearchField
          defaultValue={searchTerm ?? ""}
          onChange={handleSearchChange}
          w="auto"
          flexShrink={1}
          flexGrow={1}
        />
        {groupOptions != null && (
          <Select.Nullable
            value={inGroup ?? null}
            options={groupOptions}
            onChange={onGroupChange}
            placeholder={defaultGroupLabel}
            background="transparent"
            w="fit-content"
            maxW="52"
          />
        )}
        {roleSelect}
        <DownloadMenu downloadUrl={downloadUrl} />
        {addButton}
      </HStack>

      <SearchResultsList<UserV2, UserSortKey, UserId>
        results={data}
        error={error}
        columns={columns}
        actions={actions}
        page={page}
        count={count}
        itemLink={itemLink}
        onPageChange={onPageChange}
        order={order}
        onOrderChange={onOrderChange}
        itemKey={recordWithId}
        selection={onSelectionChange != null ? selection : undefined}
        onSelectItem={onSelectionChange != null ? onSelectItem : undefined}
        allSelected={onSelectionChange != null ? allSelected : undefined}
        onSelectAll={onSelectionChange != null ? onSelectAll : undefined}
      />
    </Spaced>
  );
}
