import { KnownWorkspaceFeatureEnum, WorkspaceV2 } from "@cartographerio/types";
import { useMemo } from "react";

export default function useCodesEnabled(workspace: WorkspaceV2): boolean {
  return useMemo(
    () =>
      workspace.features.includes(
        KnownWorkspaceFeatureEnum.EnableInvitationCodes
      ),
    [workspace]
  );
}
