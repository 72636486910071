import { permissionCheckPasses } from "@cartographerio/permission";
import {
  PermissionCheck,
  forbiddenError,
  identityToCheckIdentity,
  nowTimestamp,
} from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import { useEffect, useMemo } from "react";

import { useCredentialsV2 } from "../contexts/auth";

export default function useRequirePermission(
  check: PermissionCheck,
  onError?: () => void
): void {
  const { identity } = useCredentialsV2();

  const passes = useMemo(
    () => permissionCheckPasses(check, identityToCheckIdentity(identity)),
    [check, identity]
  );

  useEffect(() => {
    if (!passes) {
      onError == null
        ? raise(
            forbiddenError(
              check,
              identityToCheckIdentity(identity),
              nowTimestamp()
            )
          )
        : onError();
    }
  }, [check, onError, passes, identity]);
}
