import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import { Email, WorkspaceRef } from "@cartographerio/types";
import { noop } from "lodash";
import { ReactElement, useCallback } from "react";

import { useApiParams } from "../contexts/auth";
import { useIOConfirm } from "./Alert";
import Button, { ButtonProps } from "./Button";
import Spaced from "./Spaced";

interface WelcomeEmailButtonProps extends Omit<ButtonProps, "label"> {
  email: Email;
  workspace?: WorkspaceRef;
  label?: string;
  onSend?: (error?: unknown) => void;
}

export default function WelcomeEmailButton(
  props: WelcomeEmailButtonProps
): ReactElement {
  const {
    email,
    workspace,
    label = "Resend Welcome Email",
    onSend = noop,
    variant = "outline",
    ...rest
  } = props;

  const confirm = useIOConfirm();
  const apiParams = useApiParams();

  const onClick = useCallback(
    () =>
      confirm({
        title: "Send Welcome Email?",
        message: (
          <Spaced>
            <p>
              Click &quot;Send&quot; to send the user a welcome email containing
              instructions on how to (re)set their password and log in to
              Cartographer.
            </p>

            <p>
              Before you do, save any changes on this screen to ensure
              you&apos;re using the correct email address.
            </p>
          </Spaced>
        ),
        okLabel: "Send",
      })
        .flatMap(confirmed =>
          confirmed
            ? endpoints.auth.v2
                .sendWelcomeEmail(apiParams, email, workspace)
                .tap(() => onSend())
                .tapError(onSend)
            : IO.noop()
        )
        .unsafeRun(),
    [apiParams, confirm, email, onSend, workspace]
  );

  return <Button label={label} variant={variant} {...rest} onClick={onClick} />;
}
