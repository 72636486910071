import {
  Timestamp,
  ddmmyyyy,
  formatTimestamp,
  parseAnyTimestamp,
} from "@cartographerio/types";
import { InputElementProps } from "@chakra-ui/input";
import {
  FocusEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";

import { BaseTextFieldProps } from "./base";
import NullableString from "./NullableString";

export interface NullableTimestampTextFieldProps
  extends BaseTextFieldProps<Timestamp | null>,
    Omit<InputElementProps, "defaultValue" | "onChange"> {}

export default function NullableTimestampTextField(
  props: NullableTimestampTextFieldProps
): ReactElement {
  const { defaultValue, value, onChange, onBlur, disabled, ...rest } = props;

  const [stringTimestamp, setStringTimestamp] = useState<string | null>(() =>
    defaultValue != null
      ? formatTimestamp(defaultValue, { format: ddmmyyyy })
      : null
  );

  useEffect(() => {
    setStringTimestamp(
      value != null ? formatTimestamp(value, { format: ddmmyyyy }) : null
    );
  }, [value]);

  const handleBlur = useCallback<FocusEventHandler<HTMLDivElement>>(
    evt => {
      onBlur?.(evt);
      onChange?.(
        stringTimestamp != null
          ? parseAnyTimestamp(stringTimestamp) ?? null
          : null
      );
    },
    [onBlur, onChange, stringTimestamp]
  );

  return (
    <NullableString
      value={stringTimestamp}
      onChange={setStringTimestamp}
      disabled={disabled}
      onBlur={handleBlur}
      {...rest}
    />
  );
}
