import {
  UnencryptedPassword,
  unsafeUnencryptedPassword,
} from "@cartographerio/types";
import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useControllableState,
} from "@chakra-ui/react";
import { IoEye, IoEyeOff } from "react-icons/io5";

export interface PasswordFieldProps {
  value: UnencryptedPassword | null | undefined;
  onChange: (value: UnencryptedPassword) => void;
  autoComplete?: "current-password";
  showPassword?: boolean;
  defaultShowPassword?: boolean;
  autoFocus?: boolean;
  onShowPasswordChange?: (show: boolean) => void;
  tabIndex?: number;
}

export default function PasswordField(props: PasswordFieldProps) {
  const {
    value,
    onChange,
    autoComplete,
    showPassword,
    defaultShowPassword,
    onShowPasswordChange,
    tabIndex,
  } = props;

  const [visibleAsText, setVisibleAsText] = useControllableState({
    value: showPassword,
    defaultValue: value == null ? defaultShowPassword ?? false : undefined,
    onChange: onShowPasswordChange,
  });

  return (
    <InputGroup>
      <Input
        type={visibleAsText ? "text" : "password"}
        value={value ?? ""}
        onChange={evt =>
          onChange(unsafeUnencryptedPassword(evt.currentTarget.value))
        }
        autoComplete={autoComplete}
        tabIndex={tabIndex}
      />
      <InputRightElement>
        <IconButton
          onClick={() => setVisibleAsText(visibleAsText => !visibleAsText)}
          borderLeftRadius={0}
          variant="ghost"
          aria-label={visibleAsText ? "Hide" : "Show"}
          _hover={{ outline: 0 }}
          tabIndex={-1}
          icon={
            visibleAsText ? (
              <Icon as={IoEyeOff} color="gray.400" />
            ) : (
              <Icon as={IoEye} color="gray.400" />
            )
          }
        />
      </InputRightElement>
    </InputGroup>
  );
}
