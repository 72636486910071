import { PathLike, errorMessage, prefixPath } from "@cartographerio/atlas-core";
import { Message } from "@cartographerio/types";
import { get, isUndefined } from "lodash";

import { and, pass, prefix } from "./rules";

export type Rule<A> = (a: A) => Message[];

export interface FinishedRule<A, S extends string | number = never> {
  rule: Rule<A>;
  keys: S[];
}

export class RuleBuilder<A, S extends string | number = never> {
  rule: Rule<A>;
  keys: S[];

  constructor(rule: Rule<A> = pass(), keys: S[] = []) {
    this.rule = rule;
    this.keys = keys;
  }

  field<K extends Exclude<keyof A, symbol>>(
    key: K,
    rule: Rule<A[K]>
  ): RuleBuilder<A, S | K> {
    if (typeof key === "symbol") throw Error("Cannot handle symbol keys");
    const prefixed = prefix(rule, key);
    return new RuleBuilder(
      (actual: A) => {
        const path = prefixPath(key, []);
        const field = get(actual, path);
        if (isUndefined(field)) {
          return [
            errorMessage(`Property not found: ${JSON.stringify(key)}`, path),
          ];
        } else {
          const a = this.rule(actual);
          const b = prefixed(field);
          return a.concat(b);
        }
      },
      (this.keys as (string | number)[]).includes(key)
        ? this.keys
        : [...this.keys, key]
    );
  }

  conditionalField<K extends Exclude<keyof A, symbol>>(
    cond: (actual: A) => boolean,
    key: K,
    rule: Rule<A[K]>
  ): RuleBuilder<A, S | K> {
    if (typeof key === "symbol") throw Error("Cannot handle symbol keys");
    return new RuleBuilder(
      (actual: A) => {
        if (cond(actual)) {
          const prefixed = prefix(rule, key);
          const path = prefixPath(key, []);
          const field = get(actual, path);
          const a = this.rule(actual);
          const b = prefixed(field);
          return a.concat(b);
        } else {
          return this.rule(actual);
        }
      },
      (this.keys as (string | number)[]).includes(key)
        ? this.keys
        : [...this.keys, key]
    );
  }

  optionalField<K extends Exclude<keyof A, symbol>>(
    key: K,
    rule: Rule<A[K]>
  ): RuleBuilder<A, S | K> {
    if (typeof key === "symbol") throw Error("Cannot handle symbol keys");
    const prefixed = prefix(rule, key);
    return new RuleBuilder(
      (actual: A) => {
        const path = prefixPath(key, []);
        const field = get(actual, path);
        const a = this.rule(actual);
        const b = field == null ? [] : prefixed(field);
        return a.concat(b);
      },
      (this.keys as (string | number)[]).includes(key)
        ? this.keys
        : [...this.keys, key]
    );
  }

  /** run rules when we pass the test */
  union<B extends A>(
    narrowFunc: (a: A) => a is B,
    rule: Rule<B>
  ): RuleBuilder<A, S>;
  union<B extends A, O extends Exclude<keyof B, symbol> = never>(
    narrowFunc: (a: A) => a is B,
    rule: FinishedRule<B, O>
  ): RuleBuilder<A, S | O>;
  union<B extends A, O extends Exclude<keyof B, symbol> = never>(
    narrowFunc: (a: A) => a is B,
    _rule: Rule<B> | FinishedRule<B, O>
  ): RuleBuilder<A, S | O> {
    if (typeof _rule === "function") {
      const rule = _rule;
      return new RuleBuilder<A, S | O>((actual: A) => {
        return narrowFunc(actual)
          ? this.rule(actual).concat(rule(actual))
          : this.rule(actual);
      }, this.keys);
    } else {
      const { rule, keys } = _rule;
      return new RuleBuilder<A, S | O>(
        (actual: A) => {
          return narrowFunc(actual)
            ? this.rule(actual).concat(rule(actual))
            : this.rule(actual);
        },
        [...this.keys, ...keys]
      );
    }
  }

  fieldWith<B>(
    key: PathLike,
    createRule: (a: A) => Rule<B>
  ): RuleBuilder<A, S> {
    return new RuleBuilder((actual: A) => {
      const path = prefixPath(key, []);
      const field = get(actual, path);
      if (isUndefined(field)) {
        return [
          errorMessage(`Property not found: ${JSON.stringify(key)}`, path),
        ];
      } else {
        const prefixed = prefix(createRule(actual), key);
        const a = this.rule(actual);
        const b = prefixed(field);
        return a.concat(b);
      }
    });
  }

  and(rule: Rule<A>): RuleBuilder<A, S> {
    return new RuleBuilder(and(this.rule, rule), this.keys);
  }

  contramap<B>(func: (b: B) => A): RuleBuilder<A & B, S> {
    return new RuleBuilder<A & B, S>(
      (actual: B) => this.rule(func(actual)),
      this.keys
    );
  }

  finish(): FinishedRule<A, S> {
    return { rule: this.rule, keys: this.keys };
  }
}
