import "./PageTopBar.css";

import { Box, ButtonProps, HStack, theme } from "@chakra-ui/react";
import { ReactElement, useRef } from "react";
import { IoNotificationsOutline } from "react-icons/io5";

import useHeadwayBadge from "../hooks/useHeadwayBadge";
import FeedbackButton from "./FeedbackButton";
import LinkButton from "./LinkButton";
import PageBreadcrumb, { PageBreadcrumbProps } from "./PageBreadcrumb";

interface PageTopBarProps extends PageBreadcrumbProps {
  variant?: "standard" | "transparent";
}

export default function PageTopBar(props: PageTopBarProps): ReactElement {
  const { variant = "standard" } = props;

  const position = variant === "standard" ? "sticky" : "absolute";

  const bg =
    variant === "standard"
      ? "white"
      : "linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 100%)";

  const textShadow =
    variant === "standard" ? undefined : "0 1px 2px rgba(0, 0, 0, 0.5)";

  return (
    <HStack
      top="0"
      w="100%"
      position={position}
      bg={bg}
      borderBottomColor={variant === "standard" ? "gray.200" : undefined}
      borderBottomWidth={variant === "standard" ? "1px" : undefined}
      borderBottomStyle={variant === "standard" ? "solid" : undefined}
      color={variant === "standard" ? undefined : "white"}
      fontWeight={variant === "standard" ? undefined : "bold"}
      textShadow={textShadow}
      zIndex={theme.zIndices.sticky + 1}
      fontSize="sm"
    >
      <HStack flexGrow={1}>
        <Box flexGrow="1">
          <PageBreadcrumb {...props} />
        </Box>
      </HStack>
      <HStack flexGrow={0} mr="2" gap="0">
        <UpdatesButton
          __css={
            textShadow != null
              ? { ["svg"]: { filter: `drop-shadow(${textShadow})` } }
              : {}
          }
          color={variant === "standard" ? "inherit" : "white"}
        />
        <FeedbackButton
          display="flex"
          alignItems="center"
          buttonProps={{
            __css:
              textShadow != null
                ? { ["svg"]: { filter: `drop-shadow(${textShadow})` } }
                : {},
            color: variant === "standard" ? "inherit" : "white",
          }}
        />
      </HStack>
    </HStack>
  );
}

interface UpdatesButtonProps extends ButtonProps {}

function UpdatesButton(props: UpdatesButtonProps) {
  const { ...rest } = props;
  const headwayTarget = useRef<HTMLDivElement>(null);

  useHeadwayBadge({
    target: headwayTarget,
    selector: "[data-headway-target]",
    trigger: "[data-headway-trigger]",
  });

  return (
    <LinkButton
      className="updates-button"
      variant="ghost"
      aria-label="Updates"
      title="Updates"
      data-headway-target
      data-headway-trigger
      onClick={() => undefined}
      fontSize="sm"
      color="gray.600"
      pl="2"
      alignItems="center"
      {...rest}
    >
      <Box ref={headwayTarget}>
        <IoNotificationsOutline />
      </Box>
    </LinkButton>
  );
}
