import {
  PartialParams,
  WorkspaceSearchOptionsV2,
  endpoints,
} from "@cartographerio/client";
import {
  ApiParams,
  WorkspaceEmailSettings,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type WorkspaceEmailSettingsKey =
  | ["workspace", "email-settings"]
  | ["workspace", "email-settings", "v2", "read", WorkspaceRef]
  | [
      "workspace",
      "email-settings",
      "v2",
      "search",
      PartialParams<WorkspaceSearchOptionsV2>
    ];

export function workspaceBannerKey(
  key: WorkspaceEmailSettingsKey
): WorkspaceEmailSettingsKey {
  return key;
}

export const defaultEmailSettings: WorkspaceEmailSettings = {
  invitation: {
    fromCoordinator: true,
    fromAddress: null,
    toAddresses: [],
  },
  joiner: {
    toAdmins: false,
    toAddresses: [],
  },
};

export function readOrDefault(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceEmailSettings, WorkspaceEmailSettingsKey> {
  return {
    queryKey: ["workspace", "email-settings", "v2", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.emailSettings.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrElse(() => defaultEmailSettings))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  settings: WorkspaceEmailSettings
) {
  return endpoints.workspace.emailSettings.v1
    .save(apiParams, workspaceRef, settings)
    .tap(_ => queryClient.invalidateQueries(["workspace", "email-settings"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
) {
  return endpoints.workspace.emailSettings.v1
    .remove(apiParams, workspaceRef)
    .tap(_ => queryClient.invalidateQueries(["workspace", "email-settings"]));
}
