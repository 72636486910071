import { Box, Flex, Icon } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import Button from "../components/Button";

export interface PageTurnButtonsProps {
  prevPage?: number | null;
  nextPage?: number | null;
  onPrevClick?: () => void;
  onNextClick?: () => void;
  // onDoneClick?: () => void;
}

export default function PageTurnButtons(
  props: PageTurnButtonsProps
): ReactElement | null {
  const {
    prevPage,
    nextPage,
    onPrevClick,
    onNextClick,
    // onDoneClick
  } = props;

  const showPrev = prevPage != null && prevPage !== nextPage;
  const showNext = nextPage != null && prevPage !== nextPage;
  const showButtons = showPrev || showNext;

  return showButtons ? (
    <Flex justify="space-between">
      <Box>
        {showPrev && (
          <Button
            variant="outline"
            leftIcon={<Icon as={FaChevronLeft} />}
            onClick={onPrevClick}
            label={`Page ${prevPage + 1}`}
            responsive={true}
          />
        )}
      </Box>
      <Box>
        {showNext && (
          <Button
            variant="outline"
            rightIcon={<Icon as={FaChevronRight} />}
            onClick={onNextClick}
            label={`Page ${nextPage + 1}`}
            responsive={true}
          />
        )}
      </Box>
    </Flex>
  ) : null;
}
