import { UserId } from "@cartographerio/types";
import { HStack } from "@chakra-ui/react";
import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import ButtonLink from "../../components/ButtonLink";
import Para from "../../components/Para";
import { useOptCredentialsV2 } from "../../contexts/auth";
import { routes } from "../../routes";

interface InvitationAcceptedProps {
  userId: UserId;
}

export default function InvitationAccepted(
  props: InvitationAcceptedProps
): ReactElement {
  const { userId } = props;
  const credentials = useOptCredentialsV2();

  return credentials == null ? (
    <Navigate to={routes.signin.url([])} />
  ) : userId === credentials.identity.userId ? (
    <Navigate to={routes.home.url([])} />
  ) : (
    <>
      <Para textAlign="center" fontSize="lg">
        You have accepted the invitation but you are signed in as another user.
      </Para>
      <HStack justify="center" spacing="4">
        <ButtonLink.Internal variant="outline" to={routes.signout.url([])}>
          Sign out
        </ButtonLink.Internal>
        <ButtonLink.Internal variant="outline" to={routes.home.url([])}>
          Return to home page
        </ButtonLink.Internal>
      </HStack>
    </>
  );
}
