import { IO } from "@cartographerio/io";
import { useCallback, useState } from "react";

export function useIOReducer<S, A>(
  reducer: (state: S, action: A) => S,
  initialState: S
): [S, (action: A | IO<unknown>) => void] {
  const [state, setState] = useState(initialState);

  const dispatchIO = useCallback(
    (action: A | IO<unknown>) => {
      if (action instanceof IO) {
        let tempState = state;
        action.unsafeRun({
          getState: () => tempState,
          dispatch: (action: A) => {
            tempState = reducer(tempState, action);
            setState(tempState);
          },
        });
      } else {
        setState(reducer(state, action));
      }
    },
    [reducer, state]
  );

  return [state, dispatchIO];
}
