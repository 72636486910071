import { LineString } from "@cartographerio/geometry";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { ReactElement, useMemo } from "react";

import BaseGeometryField, { BaseGeometryFieldProps } from "./BaseGeometryField";

type LineStringFieldProps = Omit<
  BaseGeometryFieldProps<LineString>,
  "mapboxDraw"
>;

export default function LineStringField(
  props: LineStringFieldProps
): ReactElement {
  const mapboxDraw = useMemo(
    () =>
      new MapboxDraw({
        defaultMode: "draw_line_string",
        displayControlsDefault: false,
        touchEnabled: false,
        controls: { line_string: true, trash: true },
      }),
    []
  );

  return <BaseGeometryField {...props} mapboxDraw={mapboxDraw} />;
}
