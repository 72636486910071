import { BBox, toBBox4 } from "@cartographerio/geometry";

export interface DrawProperties {
  id?: string | number;
  meta?: string;
  parent?: string | number;
  coord_path?: string;
  active?: "true" | "false";
}

export function bboxToCoords(
  _bbox: BBox
): [[number, number], [number, number], [number, number], [number, number]] {
  const bbox = toBBox4(_bbox);
  return [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[1]],
    [bbox[2], bbox[3]],
    [bbox[0], bbox[3]],
  ];
}

export const DEFAULT_BBOX_PADDING = 20;
