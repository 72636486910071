import {
  BBox4,
  Point,
  commonLocations,
  point,
  toBBox4,
} from "@cartographerio/geometry";
import { Box, VStack } from "@chakra-ui/react";
import {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactMapGL, {
  MapLayerMouseEvent,
  MapRef,
  ViewStateChangeEvent,
} from "react-map-gl";

import {
  DEFAULT_SATELLITE_MAP_STYLE,
  DEFAULT_TERRAIN_MAP_STYLE,
} from "../../config";
import { useMapboxToken } from "../contexts/MapboxToken";
import { Highlight } from "../hooks/highlight";
import { useInputFocus } from "../hooks/useInputFocus";
import useResizeObserver from "../hooks/useResizeObserver";
import { calcViewState, fromBounds, fromCenter } from "../map/mapHelpers";
import FieldMapControls from "./FieldMapControls";
import { DEFAULT_MAP_CSS, pointToCoordinate } from "./mapFieldHelpers";
import MapMarker from "./MapMarker";
import PointTextField from "./PointTextField";

export interface PointFieldProps {
  mapRef?: RefObject<MapRef>;
  value: Point | null;
  highlight?: Highlight;
  disabled?: boolean;
  enableHelp?: boolean;
  // defaultMapCenter?: Point;
  // defaultMapZoom?: number;
  defaultBounds?: BBox4;
  maxZoomOnChange?: number;
  minZoomOnChange?: number;
  easeTo?: (...args: Parameters<MapRef["easeTo"]>) => void;
  onChange: (newValue: Point | null) => void;
  onMove?: (evt: ViewStateChangeEvent) => void;
  onMoveEnd?: (evt: ViewStateChangeEvent) => void;
  children?: ReactNode;
}

export default function PointField(props: PointFieldProps): ReactElement {
  const {
    mapRef: passedMapRef,
    value,
    highlight,
    disabled,
    enableHelp,
    defaultBounds = toBBox4(commonLocations.greatBritain.bounds),
    // defaultMapCenter,
    // defaultMapZoom,
    maxZoomOnChange = 22,
    minZoomOnChange = 10,
    easeTo: _easeTo,
    onChange,
    onMove,
    onMoveEnd,
    children,
  } = props;

  const localMapRef = useRef<MapRef>(null);

  const mapRef = passedMapRef ?? localMapRef;

  const easeTo = useMemo(
    () => _easeTo ?? mapRef.current?.easeTo,
    [_easeTo, mapRef]
  );

  const [_focused, _handleFocus, handleBlur] = useInputFocus();

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      onChange?.(point(event.lngLat.lng, event.lngLat.lat));
    },
    [onChange]
  );

  const initialViewState = useMemo(
    () =>
      calcViewState(() =>
        value == null
          ? fromBounds(defaultBounds)
          : fromCenter(value, minZoomOnChange)
      ),
    [defaultBounds, minZoomOnChange, value]
  );

  const [satelliteView, setSatelliteView] = useState(false);

  const [dragging, setDragging] = useState(false);

  const handleFindMarker = useCallback(() => {
    if (mapRef.current != null && value != null) {
      easeTo?.({
        center: value.coordinates as [number, number],
      });
    }
  }, [easeTo, mapRef, value]);

  useEffect(() => {
    value != null &&
      easeTo?.({
        center: pointToCoordinate(value),
        zoom: Math.max(
          Math.min(mapRef.current?.getZoom() ?? 22, maxZoomOnChange),
          minZoomOnChange ?? 0
        ),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  useResizeObserver(mapContainerRef, () => mapRef.current?.resize());

  const mapboxToken = useMapboxToken();

  return (
    <VStack spacing="2" alignItems="stretch">
      <Box ref={mapContainerRef} borderRadius="md" overflow="hidden">
        <ReactMapGL
          ref={mapRef}
          mapboxAccessToken={mapboxToken}
          style={DEFAULT_MAP_CSS}
          initialViewState={initialViewState}
          onMove={onMove}
          onClick={disabled ? undefined : handleMapClick}
          cursor={dragging ? "grabbing" : "crosshair"}
          onDragStart={() => setDragging(true)}
          onDragEnd={() => setDragging(false)}
          scrollZoom={false}
          mapStyle={
            satelliteView
              ? DEFAULT_SATELLITE_MAP_STYLE
              : DEFAULT_TERRAIN_MAP_STYLE
          }
          onMoveEnd={onMoveEnd}
        >
          {children}

          {value && (
            <MapMarker
              position={value}
              fillColor={satelliteView ? "white" : "red"}
              strokeColor={satelliteView ? "rgba(0, 0, 0, .5)" : "white"}
              strokeWidth={1.5}
              size={40}
            />
          )}

          <FieldMapControls
            onZoomIn={() => mapRef?.current?.zoomIn()}
            onZoomOut={() => mapRef?.current?.zoomOut()}
            defaultSearchPoint={value}
            onFindMarker={handleFindMarker}
            highlight={highlight}
            showGeolocate={true}
            satelliteView={satelliteView}
            onSatelliteViewChange={setSatelliteView}
          />
        </ReactMapGL>
      </Box>

      <PointTextField
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        highlight={highlight}
        disabled={disabled}
        enableHelp={enableHelp}
      />
    </VStack>
  );
}
