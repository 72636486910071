import { Optional } from "@cartographerio/guard";
import { Message } from "@cartographerio/types";
import { range } from "lodash";

type Messages = Message[];

export type SubErrorFields<K extends string> = {
  [S in K]: Messages;
};

type SubErrorRest = {
  _rest_: Messages;
};

export type SubErrorKeys = { [name: string]: boolean };
export type SubErrors<K extends string> = SubErrorFields<K> & SubErrorRest;

export function splitMessages<K extends string>(
  errors0: Optional<Messages>,
  keys: readonly K[]
): SubErrors<K> {
  const errors = errors0 || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: any = {};

  for (const key of keys) {
    output[key] = errors
      .filter(({ path: [first] }) => first === key)
      .map(e => ({ ...e, path: e.path.slice(1) }));
  }

  output._rest_ = errors.filter(
    e =>
      e.path.length === 0 ||
      (typeof e.path[0] === "string" &&
        (keys as readonly string[]).indexOf(e.path[0]) === -1)
  );

  return output;
}

export function indexedErrors(
  errors0: Optional<Messages>,
  maxIndex: number
): Messages[] {
  const errors = errors0 || [];

  return range(0, maxIndex).map(key => {
    const keyString = String(key);

    const subErrors: Messages = errors
      .filter(({ path: [first] }) =>
        typeof first === "string" ? first === keyString : first === key
      )
      .map(e => ({ ...e, path: e.path.slice(1) }));

    return subErrors;
  });
}

export function getUniqueMessages(messages: Message[]): string {
  return [...new Set(messages.map(message => message.text))].join(", ");
}
