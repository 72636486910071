import {
  Attachment,
  AttachmentCreateV2,
  randomUuid,
} from "@cartographerio/types";

import {
  AttachmentGalleryItem,
  InProgressGalleryItem,
  UploadFailureGalleryItem,
  UploadSuccessGalleryItem,
} from "./type";

export function attachmentGalleryItem(
  attachment: Attachment,
  thumbnailUrl: string | null,
  previewUrl: string | null,
  originalUrl: string
): AttachmentGalleryItem {
  return {
    type: "AttachmentItem",
    id: randomUuid(),
    attachment,
    thumbnailUrl,
    previewUrl,
    originalUrl,
  };
}

export function inProgressGalleryItem(
  file: File,
  attachment: AttachmentCreateV2
): InProgressGalleryItem {
  return {
    type: "InProgressItem",
    id: randomUuid(),
    attachment,
    file,
    dataUrl: URL.createObjectURL(file),
  };
}

export function uploadSuccessGalleryItem(
  item: InProgressGalleryItem,
  attachment: Attachment
): UploadSuccessGalleryItem {
  return {
    ...item,
    type: "UploadSuccessItem",
    attachment,
  };
}

export function uploadFailureGalleryItem(
  item: InProgressGalleryItem,
  error: unknown
): UploadFailureGalleryItem {
  return { ...item, type: "UploadFailureItem", error };
}
