import { Box, BoxProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export interface CardProps extends BoxProps {
  children: ReactNode;
}

export default function Card(props: CardProps) {
  const { children, ...rest } = props;

  return (
    <Box bg="white" rounded="md" boxShadow="sm" {...rest}>
      {children}
    </Box>
  );
}
