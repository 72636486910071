import { SortOrder } from "@cartographerio/client";
import { SearchResults } from "@cartographerio/types";
import {
  BoxProps,
  Table,
  TableContainer,
  Tbody,
  Thead,
} from "@chakra-ui/react";
import { Key, ReactElement, useCallback, useMemo } from "react";

import { DEFAULT_PAGE_SIZE } from "../../../config";
import ErrorPlaceholder from "../ErrorPlaceholder";
import Pagination from "../Pagination";
import Spaced from "../Spaced";
import {
  ActionsColumn,
  Column,
  column,
  useCellProps,
  useContainerProps,
  useHeaderRowProps,
  useRowProps,
  useTableProps,
} from "./column";
import { EmptyRows } from "./EmptyRows";
import HeaderRow from "./HeaderRow";
import Row from "./Row";
import SkeletonRows from "./SkeletonRows";
import { rev } from "./sort";

export interface SearchResultsListProps<
  A, // Item
  O extends string, // Sort order
  I extends Key = string // Item ID
> extends Omit<BoxProps, "results"> {
  page: number;
  count: number;
  order?: SortOrder<O>;
  // Nullable in case theres a queries.optional for the results
  results: SearchResults<A> | null | undefined;
  error?: unknown;
  columns: Column<A, O>[];
  actions?: ActionsColumn<A>;
  topPagination?: boolean;
  itemKey: (item: A) => I;
  onPageChange?: (page: number) => void;
  onOrderChange?: (order: SortOrder<O>) => void;
  itemLink?: (item: A) => string | null;
  allSelected?: boolean;
  selection?: A[];
  onSelectItem?: (item: A, selected: boolean) => void;
  onSelectAll?: () => void;
}

export default function SearchResultsList<
  A,
  O extends string,
  I extends Key = string
>(props: SearchResultsListProps<A, O, I>): ReactElement {
  const {
    page,
    count,
    order,
    results,
    error,
    columns,
    actions,
    topPagination,
    itemKey,
    onPageChange,
    onOrderChange,
    itemLink,
    allSelected,
    selection,
    onSelectItem,
    onSelectAll,
    w = "100%",
    minW = "0", // by default, allow lists to be embedded in hstacks
    ...rest
  } = props;

  const selectionLookup = useMemo(
    () => new Set(selection?.map(itemKey)),
    [itemKey, selection]
  );

  const handleSort = useCallback(
    (col: Column<A, O>) => {
      if (col.orderBy != null) {
        onOrderChange?.(rev(col.orderBy, col.defaultOrder, order));
      }
    },
    [onOrderChange, order]
  );

  const pagination = (
    <Pagination
      page={page}
      pageSize={count}
      total={results?.total ?? DEFAULT_PAGE_SIZE}
      showNearby={5}
      showShowing={true}
      onClick={({ pageIndex }) => onPageChange?.(pageIndex)}
    />
  );

  const containerProps = useContainerProps(columns);
  const tableProps = useTableProps(columns);
  const headerRowProps = useHeaderRowProps(columns);
  const bodyRowProps = useRowProps(columns);
  const cellProps = useCellProps(columns);

  return (
    <>
      {topPagination && pagination}
      <Spaced spacing="8" w={w} minW={minW} {...rest}>
        {error != null ? (
          <ErrorPlaceholder w="100%" h="auto" error={error} />
        ) : (
          <TableContainer
            marginTop={topPagination ? "0" : undefined}
            {...containerProps}
          >
            <Table {...tableProps}>
              <Thead display={["block", "block", "table-row-group"]}>
                <HeaderRow
                  selectable={selection != null}
                  allSelected={allSelected}
                  toggleAllSelected={onSelectAll}
                  columns={columns}
                  hasActions={actions != null}
                  rowProps={headerRowProps}
                  cellProps={cellProps}
                  sortOrder={order}
                  onSort={handleSort}
                />
              </Thead>
              <Tbody display={["block", "block", "table-row-group"]}>
                {results != null ? (
                  results.results.length === 0 ? (
                    <EmptyRows
                      rowProps={bodyRowProps}
                      columns={columns}
                      actions={actions}
                      selectable={selection != null}
                    />
                  ) : (
                    results.results.map((item: A, index: number) => {
                      const id = itemKey(item);
                      return (
                        <Row
                          key={id}
                          item={item}
                          selected={id != null && selectionLookup.has(id)}
                          onSelectItem={onSelectItem}
                          selectable={selection != null}
                          columns={columns}
                          actions={actions}
                          rowProps={bodyRowProps}
                          cellProps={cellProps}
                          rowIndex={index + 1}
                          itemLink={itemLink}
                        />
                      );
                    })
                  )
                ) : (
                  <SkeletonRows
                    number={count ?? 12}
                    columns={columns}
                    rowProps={bodyRowProps}
                    cellProps={cellProps}
                  />
                )}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {pagination}
      </Spaced>
    </>
  );
}

SearchResultsList.column = column;
