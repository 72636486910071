import { QueryKey, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { UseQueryOpts } from "../../queries";

export function useSuspenseQueryData<A, K extends QueryKey = QueryKey>(
  options: UseQueryOpts<A, K>
): A;
export function useSuspenseQueryData<
  A,
  B = unknown,
  K extends QueryKey = QueryKey
>(options: UseQueryOpts<A, K>, mapper: (value: A) => B): B;
export function useSuspenseQueryData<
  A,
  B = unknown,
  K extends QueryKey = QueryKey
>(options: UseQueryOpts<A, K>, mapper?: (value: A) => B): unknown {
  const { data } = useQuery({ ...options, suspense: true });
  return useMemo(
    () => (mapper != null ? mapper(data as A) : (data as A)),
    [data, mapper]
  );
}
