import { isString } from "@cartographerio/guard";
import { IO } from "@cartographerio/io";
import { Box, BoxProps, useToast } from "@chakra-ui/react";
import { ReactElement, useCallback, useMemo } from "react";
import { IoCopyOutline } from "react-icons/io5";

import { Highlight, useBackgroundHighlight } from "../hooks/highlight";
import Button from "./Button";

export interface PreProps extends BoxProps {
  text: string | ReactElement;
  copyText?: string;
  copyButtonText?: string;
  highlight?: Highlight;
  copyToClipboard?: boolean;
  containerProps?: BoxProps;
}

export default function Pre(props: PreProps): ReactElement {
  const {
    text,
    copyText: _copyText,
    copyButtonText = "Copy",
    highlight,
    copyToClipboard,
    containerProps = {},
    py = copyToClipboard ? 3 : 2,
    px = 4,
    bg,
    rounded = "md",
    overflow = "auto",
    maxH,
    maxW,
    ...rest
  } = props;

  const copyText = useMemo(
    () => (isString(text) ? text : _copyText),
    [_copyText, text]
  );

  const { bg: highlightBg } = useBackgroundHighlight(highlight);

  const toast = useToast();

  const handleCopyClick = useCallback(
    (text: string) =>
      IO.wrap(() => navigator.clipboard.writeText(text))
        .tap(() =>
          toast({
            title: "Copied",
            status: "success",
            duration: 3000,
            isClosable: true,
          })
        )
        .tapError(() =>
          toast({
            title: "Unable to copy",
            status: "error",
            duration: 3000,
            isClosable: true,
          })
        )
        .unsafeRun(),
    [toast]
  );

  return (
    <Box position="relative" maxH={maxH} maxW={maxW} {...containerProps}>
      {copyToClipboard && (
        <Button
          bg="gray.50"
          position="absolute"
          top="2"
          right="2"
          variant="outline"
          title="Copy to clipboard"
          aria-label="Copy to clipboard"
          leftIcon={<IoCopyOutline />}
          onClick={() => copyText != null && handleCopyClick(copyText)}
          size="sm"
          label={copyButtonText}
          tabIndex={-1}
          zIndex={1}
        />
      )}
      <Box position="relative" overflowY="auto">
        <Box
          as="pre"
          w="100%"
          py={py}
          px={px}
          bg={bg ?? highlightBg}
          rounded={rounded}
          overflow={overflow}
          borderWidth={1}
          borderColor="gray.200"
          {...rest}
        >
          {text}
        </Box>
      </Box>
    </Box>
  );
}
