import { Flex, FlexProps, Heading, Link } from "@chakra-ui/react";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { Link as ReactLink } from "react-router-dom";

import reactNodeText from "../../../util/reactNodeText";
import { useWindowLocation } from "../../hooks/useWindowLocation";

export interface SidebarMenuHeaderProps extends Omit<FlexProps, "right"> {
  title?: string;
  text: ReactNode;
  linkTo?: string;
  right?: ReactNode;
  isHighlighted?: boolean;
  onClick?: () => void;
}

export default function SidebarMenuHeader(
  props: SidebarMenuHeaderProps
): ReactElement {
  const {
    title: _title,
    text,
    linkTo,
    right,
    isHighlighted: _isHighlighted,
    onClick,
    ...rest
  } = props;

  const title = useMemo(() => _title ?? reactNodeText(text), [_title, text]);

  const [showRight, setShowRight] = useState(false);

  const location = useWindowLocation();

  const isHighlighted = useMemo(
    () =>
      _isHighlighted ??
      (linkTo != null && location.pathname.startsWith(linkTo)),
    [_isHighlighted, linkTo, location.pathname]
  );

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      alignItems="end"
      direction="row"
      py="2"
      px="3"
      onMouseEnter={() => setShowRight(true)}
      onMouseLeave={() => setShowRight(false)}
      bg={isHighlighted ? "gray.100" : undefined}
      onClick={onClick}
      cursor={onClick == null ? undefined : "pointer"}
      {...rest}
    >
      <Heading
        as="h2"
        fontSize="sm"
        fontWeight="bold"
        color="gray.400"
        _hover={{ color: "gray.500" }}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        title={title}
      >
        {linkTo != null ? (
          <Link
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap="1"
            color="unset"
            _hover={{ textDecoration: "unset" }}
            as={ReactLink}
            to={linkTo}
          >
            {text}
          </Link>
        ) : (
          text
        )}
      </Heading>
      {showRight && right}
    </Flex>
  );
}
