import {
  FeatureSearchParamsV2,
  PartialParams,
  endpoints,
} from "@cartographerio/client";
import { FeatureCollection } from "@cartographerio/geometry";
import { IO } from "@cartographerio/io";
import { ApiParams, MapLayerId } from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type SchemaKey =
  | ["map"]
  | [
      "map",
      "feature",
      "v2",
      "search",
      MapLayerId,
      PartialParams<FeatureSearchParamsV2>
    ]
  | [
      "map",
      "feature",
      "v2",
      "searchAll",
      [MapLayerId, MapLayerId][],
      PartialParams<FeatureSearchParamsV2>
    ];

export function search(
  apiParams: ApiParams,
  layerId: MapLayerId,
  params: PartialParams<FeatureSearchParamsV2> = {}
): UseQueryOpts<FeatureCollection, SchemaKey> {
  return {
    queryKey: ["map", "feature", "v2", "search", layerId, params],
    queryFn: () =>
      endpoints.map.feature.v2.search(apiParams, layerId, params).unsafeRun(),
  };
}

export function searchAll(
  apiParams: ApiParams,
  extToIntLayerIds: [MapLayerId, MapLayerId][],
  params: PartialParams<FeatureSearchParamsV2> = {}
): UseQueryOpts<Record<MapLayerId, FeatureCollection>, SchemaKey> {
  return {
    queryKey: ["map", "feature", "v2", "searchAll", extToIntLayerIds, params],
    queryFn: () =>
      IO.sequence(
        extToIntLayerIds.map(([extLayerId, intLayerId]) =>
          endpoints.map.feature.v2
            .search(apiParams, extLayerId, params)
            .map(coll => [intLayerId, coll])
        )
      )
        .map(Object.fromEntries)
        .unsafeRun(),
  };
}

export function reset(
  queryClient: QueryClient,
  apiParams: ApiParams,
  layer?: MapLayerId
) {
  return endpoints.map.feature.v2
    .reset(apiParams, layer)
    .tap(_ => queryClient.invalidateQueries(["map"]));
}
