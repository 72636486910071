import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import queries from "../../../../queries";
import { RouteProps } from "../../../../routes";
import { useApiParams } from "../../../contexts/auth";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { useSuspenseQueryData } from "../../../hooks/useSuspenseQueryData";
import { routes } from "../../../routes";
import { redirectUrl } from "./ArcgisIntegrationPage";

export default function ArcgisIntegrationAuthorizePage(
  props: RouteProps<
    typeof routes.workspace.project.integrations.arcgis.authorize
  >
): ReactElement {
  const {
    path: { workspaceRef, projectRef, arcgisIntegrationId },
    query: { code },
  } = props;

  const queryClient = useQueryClient();
  const apiParams = useApiParams();
  const navigate = useNavigate();
  const toast = useToast();

  const project = useSuspenseQueryData(
    queries.project.v2.readOrFail(apiParams, projectRef, workspaceRef)
  );

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, project.workspaceId)
  );

  usePageTitle(
    `Authorize ArcGIS Integration - ${project.name} - ${workspace.name}`
  );

  useEffectOnce(() => {
    if (code == null) {
      toast({ status: "error", description: "No authorisation code found" });
    } else {
      queries.integration.arcgis.v1
        .authorize(
          queryClient,
          apiParams,
          arcgisIntegrationId,
          code,
          redirectUrl(workspaceRef, projectRef, arcgisIntegrationId)
        )
        .tap(() =>
          toast({ status: "success", description: "Authorisation Successful" })
        )
        .tapError(message =>
          toast({ status: "error", description: `${message}` })
        )
        .cleanup(() =>
          navigate(
            routes.workspace.project.integrations.arcgis.update.url([
              workspaceRef,
              projectRef,
              arcgisIntegrationId,
            ]),
            { replace: true }
          )
        )
        .unsafeRun();
    }
  });

  return <></>;
}
