import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
} from "@chakra-ui/react";
import mapboxgl from "mapbox-gl";
import { ReactElement, ReactNode } from "react";

export interface BrowserGateProps {
  children: ReactNode;
}

const isIE11 =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  !!(window as any).MSInputMethodContext && !!(document as any).documentMode;

const supported = !isIE11 && mapboxgl.supported();

export default function BrowserGate(props: BrowserGateProps): ReactElement {
  const { children } = props;

  return supported ? (
    <>{children}</>
  ) : (
    <Alert status="warning">
      <AlertIcon />
      <AlertTitle mr={2}>Your browser is outdated!</AlertTitle>
      <AlertDescription>
        Sorry! You are using an older web browser that cannot display this map.
        Please consider updating.
        <Button
          as="a"
          href="https://help.cartographer.io/internet-explorer"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find out more
        </Button>
      </AlertDescription>
    </Alert>
  );
}
