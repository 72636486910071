import { checks } from "@cartographerio/permission";
import { ReactElement, useMemo } from "react";

import queries from "../../../queries";
import { RouteProps } from "../../../routes";
import PageTopBar from "../../components/PageTopBar";
import { useApiParams } from "../../contexts/auth";
import { usePageTitle } from "../../hooks/usePageTitle";
import useRequirePermission from "../../hooks/useRequirePermission";
import { useSuspenseQueryData } from "../../hooks/useSuspenseQueryData";
import { useCurrentWorkspaceGraph } from "../../hooks/useWorkspaceGraph";
import { routes } from "../../routes";
import BaseUserPage from "../base/BaseUserPage";
import WorkspacePageHeader from "./WorkspacePageHeader";

export default function WorkspaceUserUpdatePage(
  props: RouteProps<typeof routes.workspace.member.update>
): ReactElement {
  const {
    path: { workspaceRef, userId },
  } = props;

  const apiParams = useApiParams();

  const workspace = useSuspenseQueryData(
    queries.workspace.v2.readOrFail(apiParams, workspaceRef)
  );

  const defaultUser = useSuspenseQueryData(
    queries.user.v2.readOrFail(apiParams, userId)
  );

  useRequirePermission(checks.user.edit(defaultUser, workspace.id));

  const workspaceGraph = useCurrentWorkspaceGraph();

  usePageTitle(
    useMemo(() => {
      const name = [defaultUser.firstName, defaultUser.lastName]
        .filter(x => x)
        .join(" ");

      return [name || "User", workspace.name].join(" - ");
    }, [defaultUser.firstName, defaultUser.lastName, workspace.name])
  );

  return (
    <>
      <PageTopBar
        workspace={workspace}
        workspacePage="members"
        user={defaultUser}
      />
      <WorkspacePageHeader workspace={workspace} selected="members" />
      <BaseUserPage
        defaultUser={defaultUser}
        nextRedirect={routes.workspace.member.list.url([workspace.alias])}
        workspace={workspace}
        workspaceGraph={workspaceGraph}
      />
    </>
  );
}
