import { IO, createMutex } from "@cartographerio/io";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { omit } from "lodash";
import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import { ButtonProps } from "./Button";
import JsonView from "./JsonView";
import TextField from "./TextField";

type ColorScheme = ButtonProps["colorScheme"];

interface AlertOptions {
  title?: string;
  message?: ReactNode;
  okLabel?: string;
  okColorScheme?: ColorScheme;
  cancelLabel?: string;
}

export type AlertFunc<R, O = unknown> = (opts: AlertOptions & O) => R;

export type ErrorAlertFunc<R> = (error: unknown) => R;

type Thunk = () => void;

const defaultCallback: Thunk = () => undefined;

const alertMutex = createMutex();

const AlertContext = createContext((_opts: AlertOptions) =>
  IO.fail<boolean>(new Error("Please wrap your app in an AlertProvider"))
);

export interface AlertProviderProps {
  children: ReactNode;
}

export function AlertProvider(props: AlertProviderProps) {
  const { children } = props;

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [message, setMessage] = useState<ReactNode>("Are you sure?");
  const [okLabel, setOkLabel] = useState("OK");
  const [okColorScheme, setOkColorScheme] = useState<ColorScheme>("blue");
  const [cancelLabel, setCancelLabel] = useState<string | undefined>(undefined);

  const [okCallback, setOkCallback] = useState<Thunk>(() => defaultCallback);
  const [cancelCallback, setCancelCallback] = useState<Thunk>(
    () => defaultCallback
  );

  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleShow = useCallback((opts: AlertOptions) => {
    const {
      title = "Alert",
      message = "Are you sure?",
      okLabel = "OK",
      okColorScheme = "blue",
      cancelLabel,
    } = opts;

    return IO.limit(alertMutex, () =>
      IO.callback<boolean>(resolve => {
        setTitle(title);
        setMessage(message);
        setOkLabel(okLabel);
        setOkColorScheme(okColorScheme);
        setCancelLabel(cancelLabel);
        setOkCallback(() => () => resolve(true));
        setCancelCallback(() => () => resolve(false));
        setOpen(true);
      }).cleanup(() => setOpen(false))
    );
  }, []);

  const onClose = useCallback(() => {
    cancelCallback();
    setOpen(false);
  }, [cancelCallback]);

  return (
    <AlertContext.Provider value={handleShow}>
      {children}
      <AlertDialog
        isOpen={open}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>{message}</AlertDialogBody>

            <AlertDialogFooter>
              {cancelLabel != null && (
                <Button ref={cancelRef} onClick={cancelCallback}>
                  {cancelLabel}
                </Button>
              )}
              <Button colorScheme={okColorScheme} onClick={okCallback} ml={3}>
                {okLabel}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </AlertContext.Provider>
  );
}

export function useIOAlert(): AlertFunc<IO<boolean>> {
  return useContext(AlertContext);
}

export function useAlert(): AlertFunc<void> {
  const alert = useIOAlert();

  return useCallback(options => alert(options).unsafeRun(), [alert]);
}

export function useIOErrorAlert(): ErrorAlertFunc<IO<void>> {
  const alert = useContext(AlertContext);

  return useCallback(
    (error: unknown) =>
      alert({
        title: "Oops! Something Went Wrong!",
        message: (
          <>
            <p>An unexpected error occurred. The message was as follows:</p>
            <JsonView value={error} copyToClipboard={true} />
          </>
        ),
      }).void(),
    [alert]
  );
}

export function useErrorAlert(): AlertFunc<void> {
  const alert = useIOErrorAlert();

  return useCallback(error => alert(error).unsafeRun(), [alert]);
}

export interface PromptResult {
  confirmed: boolean;
  text: string | null;
}

export function useIOPrompt(): AlertFunc<
  IO<PromptResult>,
  { placeholder?: string }
> {
  const alert = useIOAlert();

  return useCallback(
    opts => {
      let text: string | null = null;

      return alert({
        cancelLabel: "Cancel",
        ...omit(opts, "message", "placeholder"),
        message: (
          <>
            {opts.message}
            <PromptInput
              placeholder={opts.placeholder}
              onChange={newText => (text = newText)}
            />
          </>
        ),
      }).map(confirmed => ({ confirmed, text }));
    },
    [alert]
  );
}

interface PromptInputProps {
  placeholder?: string;
  onChange: (value: string | null) => void;
}
function PromptInput(props: PromptInputProps): ReactElement {
  const { placeholder, onChange } = props;
  const [value, setValue] = useState<string | null>(null);

  const handleChange = useCallback(
    (value: string | null) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <TextField.NullableString
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
}

export function useIOConfirm(): AlertFunc<IO<boolean>> {
  const alert = useIOAlert();

  return useCallback(
    opts =>
      alert({
        ...opts,
        cancelLabel: "Cancel",
      }),
    [alert]
  );
}
