import {
  Email,
  UserId,
  isInvitationCodeId,
  unsafeArcgisIntegrationId,
  unsafeAttachmentFolder,
  unsafeAttachmentId,
  unsafeInvitationCodeAlias,
  unsafeInvitationCodeId,
  unsafeInvitationId,
  unsafeMapId,
  unsafeProjectId,
  unsafeProjectRef,
  unsafeQualificationAlias,
  unsafeSurveyId,
  unsafeSurveyModuleId,
  unsafeTeamId,
  unsafeTeamRef,
  unsafeUserId,
  unsafeWorkspaceRef,
} from "@cartographerio/types";
import { identity as id } from "lodash";

import { pathParam } from "../../routes";
import { restParam } from "../../routes/base";

const unsafeInvitationCodeRef = (ref: string) =>
  isInvitationCodeId(ref)
    ? unsafeInvitationCodeId(ref)
    : unsafeInvitationCodeAlias(ref);

export const workspaceRef = pathParam("workspaceRef", unsafeWorkspaceRef, id);
export const projectRef = pathParam("projectRef", unsafeProjectRef, id);
export const projectId = pathParam("projectId", unsafeProjectId, id);
export const teamRef = pathParam("teamRef", unsafeTeamRef, id);
export const teamId = pathParam("teamId", unsafeTeamId, id);
export const userId = pathParam("userId", unsafeUserId, id);
export const invitationId = pathParam("invitationId", unsafeInvitationId, id);
export const invitationAlias = pathParam(
  "invitationAlias",
  unsafeInvitationCodeAlias,
  id
);
export const invitationCodeRef = pathParam(
  "invitationCodeRef",
  unsafeInvitationCodeRef,
  id
);
export const surveyId = pathParam("surveyId", unsafeSurveyId, id);
export const attachmentId = pathParam("attachmentId", unsafeAttachmentId, id);
export const attachmentFolder = pathParam(
  "attachmentFolder",
  unsafeAttachmentFolder,
  value => value.folder
);
export const moduleId = pathParam("moduleId", unsafeSurveyModuleId, id);
export const mapId = pathParam("mapId", unsafeMapId, id);
export const arcgisIntegrationId = pathParam(
  "arcgisIntegrationId",
  unsafeArcgisIntegrationId,
  id
);
export const qualificationAlias = pathParam(
  "qualificationAlias",
  unsafeQualificationAlias,
  id
);
export const invitationCodeAlias = pathParam(
  "invitationCodeAlias",
  unsafeInvitationCodeAlias,
  id
);
export const userIdOrEmail = pathParam(
  "userIdOrEmail",
  (str): UserId | Email => str as UserId | Email,
  id
);
export const registerEntryId = pathParam<string, string>(
  "registerEntryId",
  id,
  id
);

export const rest = restParam;
