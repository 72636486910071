import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  ProjectEmailSettings,
  ProjectId,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type ProjectEmailSettingsKey = [
  "project",
  "email-settings",
  "v1",
  ProjectId
];

export const defaultEmailSettings: ProjectEmailSettings = {
  invitation: {
    fromCoordinator: true,
    fromAddress: null,
    toAddresses: [],
  },
  complete: {
    toApprovers: true,
    toAddresses: [],
  },
  approved: {
    fromApprover: true,
    fromAddress: null,
    toSurveyors: true,
    toAddresses: [],
  },
};

export function readOrDefault(
  apiParams: ApiParams,
  projectId: ProjectId
): UseQueryOpts<ProjectEmailSettings, ProjectEmailSettingsKey> {
  return {
    queryKey: ["project", "email-settings", "v1", projectId],
    queryFn: () =>
      endpoints.project.emailSettings.v1
        .readOption(apiParams, projectId)
        .map(opt => opt.getOrElse(() => defaultEmailSettings))
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  projectId: ProjectId,
  settings: ProjectEmailSettings
) {
  return endpoints.project.emailSettings.v1
    .save(apiParams, projectId, settings)
    .tap(_ => queryClient.invalidateQueries(["project", "email-settings"]));
}

export function remove(
  queryClient: QueryClient,
  apiParams: ApiParams,
  projectId: ProjectId
) {
  return endpoints.project.emailSettings.v1
    .remove(apiParams, projectId)
    .tap(_ => queryClient.invalidateQueries(["project", "email-settings"]));
}
