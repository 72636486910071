import { Skeleton, SystemProps, Td, Tr } from "@chakra-ui/react";
import { ReactElement, useMemo } from "react";

import { Column } from "./column";

interface SkeletonRowsProps<A, O extends string> {
  number?: number;
  columns: Column<A, O>[];
  rowProps: SystemProps;
  cellProps: SystemProps[];
}

export default function SkeletonRows<A, O extends string>(
  props: SkeletonRowsProps<A, O>
): ReactElement {
  const { number = 12, columns, rowProps, cellProps } = props;

  const arr = useMemo(() => new Array(number).fill(null), [number]);

  return (
    <>
      {arr.map((_, y) => (
        <Tr key={y} {...rowProps}>
          {columns.map((column, x) => (
            <Td key={x} height="8" px="2" {...cellProps[x]}>
              <Skeleton h="1em" mb="1" my="2" rounded="full"></Skeleton>
            </Td>
          ))}
        </Tr>
      ))}
    </>
  );
}
