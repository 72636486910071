import { BannerMessage } from "@cartographerio/types";
import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { MouseEventHandler, ReactElement, useCallback, useState } from "react";
import { MdCheck, MdClose, MdDelete, MdEdit } from "react-icons/md";

import { useVolatileState } from "../hooks/useVolatileState";
import Button from "./Button";
import Markdown from "./Markdown";

interface WorkspaceBannerProps {
  banner: BannerMessage;
  onSave?: (banner: BannerMessage) => void;
  onDelete?: () => void;
  editable?: boolean;
}

export default function WorkspaceBanner(
  props: WorkspaceBannerProps
): ReactElement {
  const { banner: initialBanner, onSave, onDelete, editable } = props;

  const [editing, setEditing] = useState(false);
  const [banner, setBanner] = useVolatileState(
    useCallback(() => initialBanner, [initialBanner])
  );

  const {
    isOpen: isHovering,
    onOpen: onStartHovering,
    onClose: onStopHovering,
  } = useDisclosure();
  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const handleSave = useCallback<MouseEventHandler<HTMLButtonElement>>(
    evt => {
      evt.stopPropagation();
      setEditing(false);
      const text = banner?.text.trim();
      if (text == null || text.length === 0) {
        onDelete?.();
      } else {
        onSave?.({ ...banner, text });
      }
    },
    [banner, onDelete, onSave]
  );

  const handleDelete = useCallback(() => {
    onConfirmClose();
    onDelete?.();
  }, [onConfirmClose, onDelete]);

  const handleCancelChanges = useCallback<MouseEventHandler<HTMLButtonElement>>(
    evt => {
      evt.stopPropagation();
      setBanner(initialBanner);
      setEditing(false);
    },
    [initialBanner, setBanner]
  );

  const onStartEditing = useCallback(() => {
    if (editable) {
      setEditing(true);
      if (banner == null) {
        setBanner({ text: "" });
      }
    }
  }, [banner, editable, setBanner]);

  return (
    <Flex
      position="relative"
      px="4"
      py="3"
      bg="gray.100"
      color="GrayText"
      onMouseEnter={onStartHovering}
      onMouseLeave={onStopHovering}
      rounded="md"
      minH="14"
      align="center"
    >
      {editable && (
        <ButtonGroup size="sm" position="absolute" top="2" right="2">
          {!editing && isHovering && (
            <>
              <IconButton
                variant="unstyled"
                bg="whiteAlpha.600"
                lineHeight="0"
                aria-label="Edit banner"
                icon={<Icon as={MdEdit} width="1.25em" height="1.25em" />}
                onClick={onStartEditing}
              />
              <IconButton
                variant="unstyled"
                bg="whiteAlpha.600"
                lineHeight="0"
                aria-label="Delete banner"
                icon={<Icon as={MdDelete} width="1.25em" height="1.25em" />}
                onClick={onConfirmOpen}
              />
            </>
          )}
        </ButtonGroup>
      )}

      {editable && editing ? (
        <Box position="relative" flexGrow="1">
          <Textarea
            size="sm"
            value={banner.text}
            bg="whiteAlpha.700"
            onChange={evt => setBanner({ ...banner, text: evt.target.value })}
            rows={6}
          />
          <ButtonGroup
            size="sm"
            position="absolute"
            top="2"
            right="2"
            zIndex="popover"
          >
            <IconButton
              variant="outline"
              bg="whiteAlpha.600"
              lineHeight="0"
              aria-label="Save changes"
              icon={<Icon as={MdCheck} width="1.25em" height="1.25em" />}
              onClick={handleSave}
            />
            <IconButton
              variant="outline"
              bg="whiteAlpha.600"
              lineHeight="0"
              aria-label="Cancel changes"
              icon={<Icon as={MdClose} width="1.25em" height="1.25em" />}
              onClick={handleCancelChanges}
            />
          </ButtonGroup>
        </Box>
      ) : (
        <Markdown text={banner.text} />
      )}
      <Modal isOpen={isConfirmOpen} onClose={onConfirmClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody overflowWrap="break-word">
            Are you sure you want to delete this workspace banner?
          </ModalBody>
          <ModalFooter as={ButtonGroup}>
            <Button label="Cancel" onClick={onConfirmClose} />
            <Button label="Delete" colorScheme="red" onClick={handleDelete} />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
