/* eslint-disable @typescript-eslint/ban-types */

import { identity } from "lodash";

export interface PathParam<K extends string, A> {
  key: K;
  decode: (part: string) => A;
  encode: (value: A) => string;
}

export function pathParam<K extends string, A>(
  key: K,
  decode: (seg: string) => A,
  encode: (value: A) => string
): PathParam<K, A> {
  return { key, decode, encode };
}

export const REST_PARAM_KEY = "_rest_";

export const restParam: PathParam<typeof REST_PARAM_KEY, string> = {
  key: "_rest_",
  decode: identity,
  encode: identity,
};

export type PathPart<K extends string, A> = string | PathParam<K, A>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyPathPart = PathPart<any, any>;

export interface QueryParam<A> {
  decode: (part: string | undefined) => A;
  encode: (value: A) => string | undefined;
}

export function queryParam<A>(
  decode: (param: string | undefined) => A,
  encode: (value: A) => string | undefined
): QueryParam<A> {
  return { decode, encode };
}

export function simpleQueryParam<A>(
  decode: (param: string) => A | undefined,
  encode: (value: A) => string
): QueryParam<A | undefined> {
  return {
    decode: opt => (opt == null ? undefined : decode(opt)),
    encode: opt => (opt == null ? undefined : encode(opt)),
  };
}

export type QueryParams<Q> = {
  [K in keyof Q]: QueryParam<Q[K]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQueryParams = QueryParams<any>;

type NavigateOptions = {
  replace?: boolean | undefined;
  state?: object | null | undefined;
};

export type QueryUpdate<A> = (
  query: A,
  navigateOptions?: NavigateOptions
) => void;

export type BaseQueryUpdate = QueryUpdate<URLSearchParams>;

export interface QueryOptions {
  cache?: boolean;
  cacheVersion?: string;
}
