import { ApiParams } from "@cartographerio/types";

export function webAppUrl(apiParams: ApiParams, path?: string): string {
  const apiUrl = apiParams.apiConfig.baseUrl;
  if (apiUrl.includes("beta.cartographer.io")) {
    return `https://app.beta.cartographer.io${path ?? ""}`;
  } else {
    return `https://app.cartographer.io${path ?? ""}`;
  }
}
