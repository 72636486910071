import { MapLayer } from "@cartographerio/atlas-map";
import { MapLayerId } from "@cartographerio/types";
import { raise } from "@cartographerio/util";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from "react";

import { useVolatileState } from "../../hooks/useVolatileState";
import { useMapSchema } from "./MapSchemaContext";

type SelectedLayerContextValue = [MapLayer, (layer: MapLayer) => void];

const SelectedLayerContext =
  createContext<SelectedLayerContextValue | undefined>(undefined);

export function useSelectedLayer(): SelectedLayerContextValue {
  return (
    useContext(SelectedLayerContext) ??
    raise(new Error("No current atlas map context"))
  );
}

interface SelectedLayerContextProviderProps {
  defaultSelectedLayer?: MapLayerId;
  children: ReactNode;
}

export function SelectedLayerContextProvider(
  props: SelectedLayerContextProviderProps
) {
  const { defaultSelectedLayer, children } = props;

  const schema = useMapSchema();

  const [selectedLayer, setSelectedLayer] = useVolatileState(
    useCallback(() => {
      if (defaultSelectedLayer != null) {
        const layer = schema.layers.find(
          layer => layer.layerId === defaultSelectedLayer
        );

        return layer ?? schema.layers[0];
      } else {
        return schema.layers[0];
      }
    }, [defaultSelectedLayer, schema.layers])
  );

  const value = useMemo<SelectedLayerContextValue>(
    () => [selectedLayer, setSelectedLayer],
    [selectedLayer, setSelectedLayer]
  );

  return (
    <SelectedLayerContext.Provider value={value}>
      {children}
    </SelectedLayerContext.Provider>
  );
}
